import React from "react";
import DataTable from "react-data-table-component";
import {
  customStylesTable,
  formatTransactionId,
} from "../Transactions/helpers";
import moment from 'moment';

const TransactionTable = (props) => {
  const sortingValues = {
    MerchantId: "MerchantId",
    masked: "maskedPan",
    createdAt: "createdAt",
  };
  const onChangePage = (page) => {
    props.setPage(page);
  };
  const customSort = (column, sortDirection) => {
    props.changeOrder(sortingValues[column?.name?.props?.children]);
  };
  const columns = [
    {
      name: <p>Merchant Id</p>,
      selector: (row) => row?.merchantId || "N/A",
      width: "auto",
      cell: (row) => (
        <div data-tag="allowRowEvents">{row?.merchantId || "N/A"}</div>
      ),
    },
    {
      name: <p>Masked Pan</p>,
      selector: (row) => row?.maskedPan || "N/A",
      width: "auto",
      cell: (row) => (
        <div data-tag="allowRowEvents">{row?.maskedPan || "N/A"}</div>
      ),
    },
    {
      name: <p>Created Date</p>,
      selector: (row) => row?.createdAt || "N/A",
      sortable: true,
      width: "auto",
      cell: (row) => (
        <div data-tag="allowRowEvents">{moment(row?.createdAt).format('MMMM Do YYYY, h:mm:ss a') || "N/A"}</div>
      ),
    },
  ];
  return (
    <div>
      <DataTable
        onChangePage={onChangePage}
        paginationTotalRows={props.totalCount}
        paginationPerPage={10}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        sortServer={true}
        paginationServer
        pagination
        columns={columns}
        data={props.data}
        customStyles={customStylesTable}
        onSort={customSort}
        highlightOnHover={true}
        pointerOnHover={true}
        noDataComponent={
          <div className="MerchantWhiteListCardsTableData">
            There are no data to display
          </div>
        }
      />
    </div>
  );
};
export default TransactionTable;
