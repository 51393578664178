import { withStyles } from 'tss-react/mui';
import Alert from '@mui/material/Alert';
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ExportToCsv } from "export-to-csv-fix-source-map";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../../Redux/actions';
import { useParams } from "react-router-dom";
import { Table } from 'reactstrap';
import { call, logoutAfterError } from "../../../../config/axios";
import { ADMIN_DEPOSIT_WITHDRAW, CREATE_UPDATE_MERCHANT_WALLET_AUTOMATION, GET_MERCHANT_WALLET_AND_BALANCES } from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";
import SavingAlert from "../CommonPages/SavingAlert";
import CommonConfirmationModel from "./CommonConfirmationModel";
import ExchangeModel from "./ExchangeModel";
import MerchantTransactions from "./MerchantTransactions";
import WithdrawalModel from "./WithdrawalModel";
import BalanceHistoryDialog from "./BalanceHistoryDialog";
import ManageSearchTwoToneIcon from '@mui/icons-material/ManageSearchTwoTone';
import { isViewPermissionValid } from '../../../common/CommonUtils';

const role = localDb.getVal("adminRole") === "SuperAdmin" ? true : false


const searchicon = "/cryptonpay/crytoicons/searchicon1.png";
const filterIcon = "/cryptonpay/filter_icon.svg";
const tick_green = '/cryptonpay/crytoicons/tick_green.png';

let interval1 = null;

const styles = {
  tooltip: {
    width: "108px",
    height: "32px",
    backgroundColor: "black",
    textAlign: "center",
    fontSize: "14px",
  },
};
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  marginLeft: 30,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
const CustomTooltip = withStyles(Tooltip, styles);

const BalancesTab = () => {
  const dispatch = useDispatch();
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const { id } = useParams();
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const [transactionFilters, setTransactionFilters] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [commonIndex, setCommonIndex] = useState(0);


  const [openModel, setOpenModel] = useState(false);

  const handleCloseModel = () => {
    setOpenModel(false);
  };

  const [selectedCoin, setSelectedCoin] = useState();
  const [blockchaninAddress, setBlockchainAddress] = useState("");

  const [commonConfirmationModel, setCommonConfirmationModel] = useState(false);

  const [amount, setAmount] = useState(0);
  const [newBalance, setNewBalance] = useState(0);
  const [note, setNote] = useState("")

  const handleOpenModel = (index, modal, coin) => {
    setBlockchainAddress("");
    const address = walletList.find(e => e.blockchain === coin.cryptoName);
    setBlockchainAddress(address);
    setSelectedCoin(coin);
    setCommonIndex(index);
    setOpenModel(true);
  };


  const getMerchantBalances = async () => {
    if (id) {
      await call(
        {
          ...GET_MERCHANT_WALLET_AND_BALANCES,
          url: base_url + GET_MERCHANT_WALLET_AND_BALANCES.url,
        },
        { email: email, merchantId: id }
      )
        .then((res) => {
          if (res.status === 200) {
            setData(res.data.merchantBalanceList);
            setWalletList(res.data.merchantWalletList);
            localDb.setVal("merchantName", "merchantName")
            dispatch(actions.setMerchantNameForHeader(res?.data?.merchantName))
          }
        })
        .catch((err) => {
          console.log(err.message, "err");
          logoutAfterError(err)
        });
    }
  };

  const commonModelObj = [
    {
      index: 1,
      Component: DownloadBalances,
      data: {
        data: data,
      },
    },
    {
      index: 2,
      Component: ExchangeModel,
      data: {
        cryptoBalanceList: data,
      },
    },
    {
      index: 3,
      Component: WithdrawalModel,
      data: {
        cryptoBalanceList: data,
      },
    },
    {
      index: 4,
      Component: WithdrawalModel,
      data: {
        cryptoBalanceList: data,
      },
    },
  ];

  useEffect(() => {
    if (!interval1) {
      interval1 = setInterval(() => { setIntervalCalls() }, 30000);
    }
    return () => {
      if (interval1) {
        clearInterval(interval1);
        interval1 = null;
      }
    };
  });


  const setIntervalCalls = () => {
    if (pageMode === "balance") {
      getMerchantBalances();
    }
  }

  useEffect(() => {
    getMerchantBalances();
  }, [])

  const [success, setSuccess] = useState(false);
  const handleAdminDepositWithdraw = async () => {
    setLoading(true);
    await call(
      {
        ...ADMIN_DEPOSIT_WITHDRAW,
        url: base_url + ADMIN_DEPOSIT_WITHDRAW.url,
      },
      {
        email: email,
        merchantId: id,
        amount: amount,
        merchantBalanceUpdateNote: note,
        cryptoCurrency: selectedCoin.coin,
        currency: "USD",
        type: commonIndex === 3 ? "Deposit" : "Withdraw"
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setSuccess(true);
          getMerchantBalances();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const [pageMode, setPageMode] = useState("balance");
  const [search, setSearch] = useState('');
  const [filterBalances, setFilterBalances] = useState([]);

  const [orderBy, setOrderBy] = useState('desc');
  const [sortBy, setSortBy] = useState('crypName');

  const sortList = (sortBy, type) => {
    let cpy = [...data]
    if (type == 'asc') {
      if (sortBy == "crypName") {
        cpy.sort()
      } else {
        cpy.sort((a, b) => {
          return a[sortBy] - b[sortBy]
        })
      }

    } else {
      if (sortBy == "crypName") {
        cpy.sort().reverse()
      } else {
        cpy.sort((a, b) => {
          return b[sortBy] - a[sortBy]
        })
      }
    }
    setData(cpy)
  };
  const [successAlert, setSuccessAlert] = useState(false);
  if (successAlert) {
    setInterval(() => {
      setSuccessAlert(false);
    }, 3000);
  }

  return (
    <div>
      {successAlert &&
        <Stack className="position-absolute successAlert" style={{ right: '5px', top: "55px" }} spacing={2}>
          <Alert icon={<img src={`${image_base_url}${tick_green}`} alt="tick_green" style={{ width: '32px' }} />}
            onClose={() => { setSuccessAlert(false) }} className='MuiAlert-root'>{`Autotrade to USDT updated successfully.`}</Alert>
        </Stack>}
      <Dialog
        open={openModel}
        onClose={handleCloseModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {commonModelObj.map(({ Component, ...rest }, index) => {
          return (
            <div key={index}>
              {rest.index === commonIndex && (
                <Component
                  key={index}
                  data={rest.data}
                  image_base_url={image_base_url}
                  onClose={handleCloseModel}
                  email={email}
                  base_url={base_url}
                  setLoading={setLoading}
                  selectedCoin={selectedCoin}
                  blockchaninAddress={blockchaninAddress}
                  merchantId={id}
                  walletList={walletList}
                  commonIndex={commonIndex}
                  setNewBalance={setNewBalance}
                  setNewAmount={setAmount}
                  setCommonConfirmationModel={setCommonConfirmationModel}
                  handleCloseModel={handleCloseModel}
                  getMerchantBalances={getMerchantBalances}
                  setNote={setNote}
                  note={note}
                />
              )}
            </div>
          );
        })}
      </Dialog>

      <div className="balances__actions">
        <div className='switcharoo'>
          <div data-e2e="balaces-tab"
            onClick={() => {
              setPageMode("balance");
            }}
            className={`normal-switcharoo ${pageMode == 'balance' ? 'selected-switcharoo' : ''}`}>
            Balances
          </div>

          <div
            onClick={() => {
              setPageMode("transaction");
            }}
            data-e2e="transaction-tab"
            className={`normal-switcharoo ${pageMode == 'transaction' ? 'selected-switcharoo' : ''}`}>
            Transactions
          </div>
        </div>
        {pageMode == 'balance' ? (
          <div className='search-ctr'>
            <div><input value={search} onChange={(e) => {
              let search = e.target.value;
              setSearch(search)
              let found = data.filter((item) => {
                return item.crypName.toLowerCase().includes(search.toLowerCase()) || item.coin.toLowerCase().includes(search.toLowerCase())
              })
              setFilterBalances(found);
            }} placeholder='Search CryptoCurrency' /></div>
            <img src={`${image_base_url}${searchicon}`} width={18} height={18} />
          </div>
        ) : (
          <button type="button" className="transactionFilter__btn" data-e2e="filterBtn"onClick={() => {setTransactionFilters(true);}}>
            <img
              data-e2e="filterIcon"
              src={`${image_base_url}${filterIcon}`}
              alt="filterIcon"
              className=""
            />
          </button>
        )
        }

      </div>
			
			<div className="">
				<div className="fees">
					<div className="fees_table">
						{pageMode == "balance" ?
							<BalancesTable
								email={email}
								merchantId={id}
								base_url={base_url}
								successAlert={successAlert}
								setSuccessAlert={setSuccessAlert}
								data={search.length == 0 ? data : filterBalances}
								walletList={walletList}
								handleOpenModel={handleOpenModel}
								sortBy={sortBy}
								orderBy={orderBy}
								changeOrder={(sort) => {
									if (sortBy !== sort) {
										setOrderBy('desc');
										setSortBy(sort);
									} else {
										setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
									}
								}}
								sortList={sortList}
							/> :
							<MerchantTransactions
								setLoading={setLoading}
								merchantId={id}
								email={email}
								base_url={base_url}
								transactionFilters={transactionFilters}
								setTransactionFilters={setTransactionFilters}
								pageMode={pageMode}
								interval1={interval1}
							/>
						}
					</div>
				</div>
			</div>
      


      {commonConfirmationModel && (
        <CommonConfirmationModel
          open={commonConfirmationModel}
          onClose={() => setCommonConfirmationModel(false)}
          image_base_url={image_base_url}
          crypto={selectedCoin}
          haddleSuccess={() => handleAdminDepositWithdraw()}
          header={commonIndex === 3 ? "Deposit" : "Withdraw"}
          btnName={commonIndex === 3 ? "Deposit" : "Withdraw"}
          amount={amount}
          newBalance={newBalance}
          email={email}
          base_url={base_url}
          setLoading={setLoading}
        />
      )}
      {success && (
        <SavingAlert
          onCloseClickHandler={() => {
            setSuccess((prev) => !prev);
            setCommonConfirmationModel(false);
          }}
          showModal={true}
          header={`${commonIndex === 3 ? "Deposit" : "Withdraw"} successful`}
          msg={`You have successfully ${commonIndex === 3 ? "Deposit" : "Withdraw"} crypto to selected wallet address.`}
          btnName="Continue"
        />
      )}
      {loading && <Loading />}
    </div>
  );
};


export default BalancesTab;

const DownloadBalances = (props) => {
  const { image_base_url, onClose } = props;
  const { walletList } = props.data;

  const downloadAlertIcon = "/cryptonpay/crytoicons/downloadalerticon.png";

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "Merchant Wallets",
    filename: "walletBalances",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(options);

  const handleDownloadCsv = async () => {
    let wallets = [];
    walletList &&
      walletList.length > 0 &&
      walletList.map((wallet) => {
        let walletBalancesList = {
          address:
            (wallet.address && wallet.address.key && wallet.address.key) ||
            "N/A",
          blockchain: wallet.blockchain || "N/A",
          network: wallet.network || "N/A",
          cryptoId:
            (wallet &&
              wallet.balance[0] &&
              wallet.balance[0].cryptoId &&
              wallet.balance[0].cryptoId) ||
            "N/A",
          cryptoName:
            (wallet &&
              wallet.balance[0] &&
              wallet.balance[0].cryptoName &&
              wallet.balance[0].cryptoName) ||
            "N/A",
          balanceAmount:
            (wallet &&
              wallet.balance[0] &&
              wallet.balance[0].balanceAmount &&
              wallet.balance[0].balanceAmount) ||
            "N/A",
        };
        wallets.push(walletBalancesList);
      });
    await csvExporter.generateCsv(wallets);
    onClose();
  };
  return (
    <div className="ClearAlertModal">
      <div className="ClearAlertModalBody">
        <div
          className="ClearAlertModalIconWrap"
          data-e2e="ClearAlertModalIconWrap"
        >
          <img
            src={`${image_base_url}${downloadAlertIcon}`}
            alt="downloadAlertIcon"
            className="ClearAlertModalIcon"
            data-e2e="ClearAlertModalIcon"
          />
        </div>
        {walletList && walletList.length > 0 ? (
          <div>
            <div className="ClearAlertModalTitle">Download in CSV</div>
            <div className="ClearAlertModalDescription">
              Are you sure you want to download CSV file with all the
              information?{" "}
            </div>
            <div
              className="ClearAlertModalClearBtn"
              data-e2e="ClearAlertModalClearBtn"
              onClick={() => handleDownloadCsv()}
            >
              Download
            </div>
          </div>
        ) : (
          <div className="ClearAlertModalTitle no-download-data">
            No Data Found
          </div>
        )}
        <div
          className="ClearAlertModalGoBackBtn"
          onClick={() => {
            onClose();
          }}
        >
          Go Back
        </div>
      </div>
    </div>
  );
};

const BalancesTable = ({ data, handleOpenModel, changeOrder, sortList, orderBy, sortBy, email, merchantId, base_url, successAlert, setSuccessAlert }) => {
	const [isHistoryDialog, setisHistoryDialog] = useState(false);
	const [rowData, setRowData] = useState([]);
  const [balanceHistory, setBalanceHistory] = useState([]);
	const [balanceHistoryTitle, setBalanceHistoryTitle] = useState("");
	function SortIconElement (props) {
		const isActive = props.isActive; 
		return (
			<i className={`fa fa-chevron-${isActive && orderBy === "desc" ? 'up' : 'down' } ${isActive ? 'active' : ''}`} />
		);
	};
  const tableColumn = [ 
    {
        header: "Asset",
        sortKey: "crypName"
    },
    {
        header: "Holding (%)",
        sortKey: "holding"
    },
    {
        header: "Amount",
        sortKey: "amount"
    },
    {
        header: "Pending amount",
        sortKey: "pendingAmount"
    },
    {
        header: "Price",
        sortKey: "exchangeRate"
    },
	]
	
	function openBalanceHistory(data, balanceHistory, title) {
		setBalanceHistory(balanceHistory);
		setRowData(data);
		setBalanceHistoryTitle(title);
		setisHistoryDialog(true);
	}
	
	
	return (
		<div className="theme-table">
			<BalanceHistoryDialog data={rowData} title={balanceHistoryTitle} balanceHistory={balanceHistory} open={isHistoryDialog} onClose={() => setisHistoryDialog(false)}/>
			<Table responsive>
				<tbody>
					<tr>
            {
              tableColumn.map(({header, sortKey})=>{
                return (
                <th key={sortKey} className="theme-table-hover" 
                  onClick={() => {
                    changeOrder(sortKey)
                    sortList(sortKey, orderBy)
                  }}>
                  <p className="theme-table-text">
                    {header}
                    <SortIconElement isActive={sortBy === sortKey} />
                  </p>
                </th>

                )
              })
            }
						<th width="160px">
							<p className="theme-table-text">AutoTrade Wallet <br/>Deposits to USDT</p>
						</th>
            {role && 	<th><p className="theme-table-text">Actions</p></th>}
					</tr>
					{data && data.length > 0 ? (
						data.map((ele, i) => {
							return (
								<SingleRowData
									openBalanceHistory={openBalanceHistory}
									data={ele}
									key={i}
									index={i}
									handleOpenModel={handleOpenModel}
									email={email}
									merchantId={merchantId}
									base_url={base_url}
									successAlert={successAlert}
									setSuccessAlert={setSuccessAlert}
								/>
							);
						})
					) : (
						<tr data-e2e="No_Transactions_Found">
							<td colSpan="7">
								<p className="theme-table-empty">No Data Found</p>
							</td>
						</tr>
					)}

				</tbody>
			</Table>
		</div>
  );
};

const SingleRowData = ({ openBalanceHistory, data, handleOpenModel, index, email, merchantId, base_url, successAlert, setSuccessAlert }) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [hoveredElement, setHoveredElement] = useState({
    type: "",
    index: null,
  });

  const images = [
    {
      icon: "/cryptonpay/crytoicons/merchant-exchange-icon.svg",
      iconHover: "/cryptonpay/crytoicons/merchant-exchange-icon-fill.svg",
      iconAt: 0,
      title: "Exchange",
      commonIndex: 2,
      onClick: handleOpenModel,
    },
    {
      icon: "/cryptonpay/crytoicons/merchant-deposit-icon.svg",
      iconHover: "/cryptonpay/crytoicons/merchant-deposit-icon-fill.svg",
      iconAt: 1,
      commonIndex: 3,
      title: "Deposit",
      onClick: handleOpenModel
    },
    {
      icon: "/cryptonpay/crytoicons/merchant-withdraw-icon.svg",
      iconHover: "/cryptonpay/crytoicons/merchant-withdraw-icon-fill.svg",
      iconAt: 2,
      title: "Withdraw",
      commonIndex: 4,
      onClick: handleOpenModel
    },
  ];

  const [trading, setTrading] = useState(data.autotradeToUSDT ? data.autotradeToUSDT : false);
  const toggleAutoTrade = (data) => {
    setTrading(!trading);
    handleAutotrade(data, !trading)
  };

  const handleAutotrade = async (data, trading) => {
    let payload = {
      email: email,
      merchantId: merchantId,
      trading,
      cryptoId: data.id
    }
    await call(
      {
        ...CREATE_UPDATE_MERCHANT_WALLET_AUTOMATION,
        url: base_url + CREATE_UPDATE_MERCHANT_WALLET_AUTOMATION.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setSuccessAlert(true);
        }
      })
      .catch((err) => {
        console.log(err.message, "err");
        logoutAfterError(err)
      });


  };


  return (
    <tr className="theme-table-row">
			<td>
				<div className="theme-table-row-td theme-table-asset">
					<img src={`${image_base_url}${data.icon}`} width="30" height="30" />
					<p className="theme-table-text">{data.coin} / {data.cryptoName}</p>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td column">
					<p className="theme-table-text">{parseFloat(data.holding).toFixed(2)}%</p>
					<div className="barParant">
						<div className="barChild" style={{ width: `${data.holding}%` }}></div>
					</div>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td balance-history-amount">
					<p className="theme-table-text" data-e2e={`${data.amount || data.amount === 0 ? parseFloat(data.amount).toFixed(6) : "-"}`} data-e2e-currency={`${data.coin}`}>
						{`${data.amount || data.amount === 0
							? parseFloat(data.amount).toFixed(6)
							: "-"
							}`}{" "}
						{data.coin}
					</p>
					{data.availableBalanceHistory && data.availableBalanceHistory.length > 0 &&
						<button onClick={() => openBalanceHistory(data, data.availableBalanceHistory, "Available balance history")}>
							<ManageSearchTwoToneIcon />
						</button>
					}
				</div>
			</td>
			<td>
				<div className="theme-table-row-td balance-history-amount">
					<p className="theme-table-text">
						{`${data.pendingAmount || data.pendingAmount === 0
							? parseFloat(data.pendingAmount).toFixed(6)
							: "-"
						}`}
					</p>
					{data.pendingBalanceHistory && data.pendingBalanceHistory.length > 0 &&
						<button onClick={() => openBalanceHistory(data, data.pendingBalanceHistory, "Pending balance history")}>
							<ManageSearchTwoToneIcon />
						</button>
					}
				</div>
			</td>
			<td>
				<div className="theme-table-row-td">
					<p className="theme-table-text">${Number(parseFloat(data.exchangeRate).toFixed(2)).toLocaleString()}</p>
				</div>
			</td>
			<td>
				<div className={`theme-table-row-td ${isViewPermissionValid? "disabled": ""}`}>
					<AntSwitch
						inputProps={{ "aria-label": "ant design" }}
						onChange={() => isViewPermissionValid? null:( role && toggleAutoTrade(data) )}
						checked={trading}
					/>
				</div>
			</td>
      {
        role && <td>
				<div className="theme-table-actions">
					{images.map(({ title, icon, iconHover, onClick, commonIndex }, index) => {
						return (
							<CustomTooltip title={title} placement="top" key={index}>
								<button data-e2e={`${title.toLowerCase().replaceAll(' ', '-')}-${data.coin}`} onClick={() => isViewPermissionValid? null: onClick(commonIndex, title, data)} type="button" className={`theme-table-action-icon ${isViewPermissionValid? "disabled": ""}`}>
									<img src={`${image_base_url}${index === hoveredElement.index ? iconHover : icon}`} />
								</button>
							</CustomTooltip>
						);
					})}
				</div>
			</td>

      }
			      
    </tr>
  );
};
