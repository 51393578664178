import React from 'react';

const CurrencyListItem = ({ name, toggleFunction, imageBaseUrl, selected, selectedCheckBoxIcon, emptyCheckBoxIcon }) => {
  const handleItemClick = () => {
    toggleFunction(name);
  };

  return (
    <div className="currencyListItemWrap" data-e2e="currencyListItemWrap" onClick={handleItemClick}>
      <div className="currencyListIconNameWrap" data-e2e="currencyListIconNameWrap">
        <div className="currencyList_Name" data-e2e={name.toLowerCase().replaceAll(' ', '-')}>
          {name}
        </div>
      </div>
      <img
        src={`${imageBaseUrl}${selected.includes(name) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
        alt="checkbox"
        className="currencyListCheckBox"
      />
    </div>
  );
};

export default CurrencyListItem;
