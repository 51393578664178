import React, { useState } from "react";
import { Paper } from "@mui/material";
import DataTable from "react-data-table-component";
import { customStylesTable } from "../../Transactions/helpers";

const RoutingServiceContent = ({ data, updateQueryParams, tabType }) => {
  const [page, setPage] = useState(1);

  const goToTransactionPage = async (service, status) => {
    const tabTypeMappings = {
      "Routing Service": "routingService",
      Merchant: "merchantName",
      Acquirer: "acquirer",
      CardScheme: "cardScheme",
      Country: "issuerCountry",
      Traffic: "trafficType",
    };

    const params = {};
    const paramKey = tabTypeMappings[tabType];
    if (paramKey) {
      let paramValue;
      if (tabType === "Merchant") {
        paramValue = JSON.stringify(service?.merchantId);
        params[paramKey] = `[{"merchantId":${paramValue}}]`;
      } else if (tabType === "Country") {
        paramValue = JSON.stringify(service?.uniqueId);
        params[paramKey] = `[{"name":${paramValue}}]`;
      } else {
        paramValue = JSON.stringify(service?.uniqueId);
        params[paramKey] = `[${paramValue}]`;
      }
    }
  
    if (status) {
      params[status === "Processing" ? "exceptList" : "statusgroup"] =
        status === "Processing" ? ["Success", "Rejected"] : status;
    }

    const navigateLink = await updateQueryParams(params);
    window.open(navigateLink, "_blank");
  };
  
  const sortingValues = {
    Name: "name",
    Total: "total",
    Processing: "statusCategories.Processing.total",
    Rejected: "statusCategories.Rejected.total",
    Success: "statusCategories.Success.total",
  };
  const getStatusColumns = (data) => {
    const statuses = new Set();
    data?.forEach((row) => {
      row?.statusCategories?.forEach((statusCategory) => {
        statuses.add(statusCategory?.status);
        row.totalAdd = row?.total
      });
    });

    return Array.from(statuses).map((status) => ({
      name: <p>{status}</p>,
      selector: (row) =>
        row?.statusCategories?.find((cat) => cat?.status === status)?.total ||
        "N/A",
      cell: (row) => {
        const category = row?.statusCategories.find(
          (cat) => cat?.status === status,
        );
        if (!category) {
          return <div data-tag="allowRowEvents">N/A</div>;
        }
        const { total, totalSum } = category;
        return (
          <div data-tag="allowRowEvents" className="clr-primary cursor-pointer" onClick={()=>goToTransactionPage(row, status)}>
            {totalSum && (
              <span>
                (${Math.round(totalSum)?.toLocaleString()}) ({Math.round((total / row?.totalAdd) * 100)}
                %)
              </span>
            )}{" "}
            {total}
          </div>
        );
      },
    }));
  };

  const columns = [
    {
      name: <p>{Object.keys(sortingValues)[0]}</p>,
      selector: (row) => row?.name || "N/A",
      sortable: true,
      width: "300px",
      cell: (row) => (
        <div data-tag="allowRowEvents" className="text-capitalize">
          {row?.name || row?.uniqueId || "N/A"}
        </div>
      ),
    },
    {
      name: <p>{Object.keys(sortingValues)[1]}</p>,
      selector: (row) => row?.total || "N/A",
      cell: (row) => (
        <div data-tag="allowRowEvents" className="clr-primary cursor-pointer" onClick={()=>goToTransactionPage(row, "")}>
          {row?.totalSum && (
            <span>
              {" "}
              (${Math.round(row?.totalSum)?.toLocaleString()}) (
              {typeof row?.percentage === "number" &&
                Math.round(row?.percentage)}
              %)
            </span>
          )}{" "}
          {row?.total?.toLocaleString()}
        </div>
      ),
    },
    ...getStatusColumns(data),
  ];

  const onChangePage = (page) => {
    setPage(page);
  };

  return (
    <Paper className="p-1">
      <DataTable
        onChangePage={onChangePage}
        paginationTotalRows={Math.round((data && data?.length) || 0)}
        paginationPerPage={10}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        paginationServer
        pagination
        sortServer={true}
        columns={columns}
        data={data}
        customStyles={customStylesTable}
        highlightOnHover={true}
        pointerOnHover={true}
        noDataComponent={
          <div style={{ padding: "24px", fontSize: "14px" }}>
            There are no data to display
          </div>
        }
      />
    </Paper>
  );
};

export default RoutingServiceContent;
