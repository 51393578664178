import Dialog from "@mui/material/Dialog";
import { withStyles } from 'tss-react/mui';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import WarningIcon from "@mui/icons-material/Warning";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Table } from "reactstrap";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  GET_CENTRALISED_WALLET,
  GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
  GET_TRANSACTION_CURRENT_STATUS,
  GET_TRANSFER_WALLETS,
  GET_WALLETS,
  GET_WALLET_BY_ADDRESS,
  SAVE_TRANSACTION_FROM_ADMIN,
  UPDATE_WALLET_STATUS,
  VIEW_SINGLE_WALLET,
} from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";
import { isViewPermissionValid } from "../../../common/CommonUtils";
const closeImage = "/cryptonpay/crytoicons/close.png";
const successIcon = "/cryptonpay/crytoicons/success_changes.png";
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";
const email = localDb.getVal("email");

const WalletTable = (props) => {
  const [loading, setLoading] = useState(false);
  const { orderBy, sortBy } = props;

  function SortIconElement(props) {
    const isActive = props.isActive;
    return (
      <i className={`fa fa-chevron-${isActive && orderBy === "desc" ? 'up' : 'down'} ${isActive ? 'active' : ''}`} />
    );
  };

  return (
    <div>
      <br />
      <br />
      <div className="theme-table">
        <Table responsive>
          <tbody>
            <tr>
              <th className="theme-table-hover" onClick={() => { props.changeOrder('blockchain') }} data-e2e="blockchain">
                <p className="theme-table-text">
                  Blockchain
                  <SortIconElement data-e2e="blockchain" isActive={sortBy === 'blockchain'} />
                </p>
              </th>
              <th className="theme-table-hover" onClick={() => { props.changeOrder('cryptoId') }} data-e2e="blockchain">
                <p className="theme-table-text">
                  Crypto
                  <SortIconElement data-e2e="blockchain" isActive={sortBy === 'cryptoId'} />
                </p>
              </th>
              <th className="theme-table-hover" onClick={() => { props.changeOrder('network') }} data-e2e="network">
                <p className="theme-table-text">
                  Network
                  <SortIconElement data-e2e="network" isActive={sortBy === 'network'} />
                </p>
              </th>
              <th className="theme-table-hover" onClick={() => { props.changeOrder('address.key') }} data-e2e="address.key">
                <p className="theme-table-text">
                  Wallet Address
                  <SortIconElement data-e2e="address.key" isActive={sortBy === 'address.key'} />
                </p>
              </th>
              <th className="theme-table-hover"  >
                <p className="theme-table-text">
                  Current/Last transaction
                </p>
              </th>
              <th className="theme-table-hover" onClick={() => { props.changeOrder('address.seedIndex') }} data-e2e="address.seedIndex">
                <p className="theme-table-text">
                  Seed Index
                  <SortIconElement data-e2e="address.seedIndex" isActive={sortBy === 'address.seedIndex'} />
                </p>
              </th>
              <th className="theme-table-hover" onClick={() => { props.changeOrder('paymentProvider.firstName') }} >
                <p className="theme-table-text">
                  Payment Provider
                  <SortIconElement isActive={sortBy === 'paymentProvider.firstName'} />
                </p>
              </th>
              <th className="theme-table-hover" onClick={() => { props.changeOrder('status.updated') }} data-e2e="status.updated">
                <p className="theme-table-text">
                  Last Used
                  <SortIconElement data-e2e="status.updated" isActive={sortBy === 'status.updated'} />
                </p>
              </th>
              <th className="theme-table-hover" onClick={() => { props.changeOrder('balanceAmountUSDApprox') }} data-e2e="balance.balanceAmountUSDApprox">
                <p className="theme-table-text">
                  Approx USD Balance
                  <SortIconElement data-e2e="balance.balanceAmountUSDApprox" isActive={sortBy === 'balanceAmountUSDApprox'} />
                </p>
              </th>
              <th className="theme-table-hover" onClick={() => { props.changeOrder('status.code') }} data-e2e="status.code">
                <p className="theme-table-text">
                  Status
                  <SortIconElement data-e2e="status[0].code" isActive={sortBy === 'status.code'} />
                </p>
              </th>
              <th><p className="theme-table-text">Actions</p></th>
            </tr>
            {props && props.data && props.data.length > 0 ?
              props.data.map((TData, i) => {
                return <SingleTransactionTableRow
                  data={TData}
                  key={i}
                  setLoading={setLoading}
                  getWallets={() => {
                    props.getWallets();
                  }}
                  view={"wallets"}
                />
              }) : (
                <tr data-e2e="No_Transactions_Found">
                  <td colSpan="10">
                    <p className="theme-table-empty">No Data Found</p>
                  </td>
                </tr>
              )
            }
          </tbody>
        </Table>

      </div>
      {props.showMoreButton &&
        <div className="theme-btn-block center show-btn">
          <div className="theme-btn" data-e2e="showMore" onClick={() => { props.handleShowMore(); }}>
            <p>Show more</p>
          </div>
        </div>
      }
    </div>
  );
};

export default WalletTable;



const SingleTransactionTableRow = (props) => {
  let { data } = props;
  let txnId = getTxnId(data);
  if (txnId === "N/A") {
    txnId = "N/A";
  } else {
    if (txnId && txnId.message) {
      txnId = txnId?.message?.includes("transferTransactionID=") || txnId?.message?.includes("tranferTrasactionID=") ? txnId?.message?.split("=")[1] : txnId?.message;
  } else {
      txnId = "defaultTransactionID";
  }
  status = getTxnId(data).code
  }

  const image_base_url = useSelector((state) => state.config.image_base_url);
  const api_url = useSelector((state) => state.config.api_url);
  const [selectedTransferWallet, setSelectedTransferWallet] = useState("");
  const [walletIdForTransferFunds, setWalletIdForTransferFunds] =
    useState(null);
  const [showModal, toggleShowModal] = useState(false);
  const [alertModal, toggleAlertModal] = useState(false);
  const [loadingExchange, setLodingExchange] = useState(false);
  const [showSuccess, toggleShowSuccess] = useState(false);
  const [selectedWalletForTransfer, setSelectedWalletForTransfer] = useState(
    []
  );
  const [selectedWalletTransfer, setSelectedWalletTransfer] = useState([]);
  const [selectedToken, setSelectedToken] = useState("");
  const [selectedTokenDetails, setSelectedTokenDetails] = useState(null);
  const [cryptoAmount, setCryptoAmount] = useState(0);
  const [cryptoEquUSDAmount, setCryptoEquUSDAmount] = useState(0);
  const [showError, toggleShowError] = useState(false);
  const [limitError, setLimitError] = useState("");
  const [transferWallets, setTransferWallets] = useState([]);
  const [singleTransferWallets, setSingleTransferWallets] = useState(null);
  const view_transaction_url = useSelector((state) => state.config.api_url);
  const payment_api_url = useSelector((state) => state.config.payment_api_url);
  let adminEmail = localDb.getVal("email");
  const [choosenWallet, setChoosenWallet] = useState("Transfer");
  const [allWalletsData, setAllWalletsData] = useState([]);
  const [allCentralisedWalletsData, setAllCentralisedWalletsData] = useState([])
  const [selectedWalletType, setSelectedWalletType] = useState("transferWallet");
  const [selectedNewtwork, setSelectedNetwork] = useState('');
  const [selectedBlockchain, setSelectedBlockchain] = useState('')
  const [transactionStatus, settransactionStatus] = useState('')

  const getStatusComponent = (status, blockchain) => {
    switch (status) {
      case "DepositMonitor:DepositPendingManualApproval":
        return (
          <div className="theme-table-status witheld" data-e2e={`WITH HELD-${blockchain}`}>
            WITHHELD
          </div>
        );
      case "DepositMonitor:MonitoringMemPool":
      case "DepositFlow:AwaitingDeposit":
      case "DepositFlow:Launched":
      case "CreatePayment":
      case "DepositMonitor:WalletStatusAvailable":
      case "DepositMonitor:WalletStatusLocked":
      case "DepositMonitor:UpdateWalletStatus":
      case "DepositMonitor:NotfiyMerchant":
      case "DepositMonitor:DepositManualApprove":
      case "DepositMonitor:KYTCheckFail":
      case "DepositMonitor:KYTCheckPass":
      case "DepositMonitor:KYTCheck":
      case "DepositMonitor:DepositConfirmed":
      case "DepositMonitor:GasTooLow":
      case "DepositMonitor:ExcessTransactionExecuted":
      case "DepositMonitor:TransactionExecuted":
      case "DepositMonitor:TransactionExecuting":
      case "DepositMonitor:DepositReceivedToMemPool":
      case "DepositMonitor:TransactionComplete":
        return (
          <div className="theme-table-status pending" data-e2e={`pending-${blockchain}`}>
            PENDING
          </div>
        );
      case "DepositMonitor:DepositManuallyCompleted":
      case "DepositMonitor:DepositCompleted":
      case "MerchantWallet:BalanceUpdated":
      case "WalletManagementService:WithdrawalCompleted":
      case "WalletManagementService:TransferCompleted":
      case "DepositFlowViaWallet:DepositCompleted":
        return (
          <div className="theme-table-status active" data-e2e={`success-${blockchain}`}>
            SUCCESS
          </div>
        );
      case "Active":
        return (
          <div className="theme-table-status active" data-e2e={`active-${blockchain}`}>
            ACTIVE
          </div>
        );
      case "Available":
        return (
          <div
            className="theme-table-status active"
            data-e2e={`Available-${blockchain}`}
          >
            AVAILABLE
          </div>
        );
      case "Full":
        return (
          <div
            className="theme-table-status active"
            data-e2e={`Available-${blockchain}`}
          >
            FULL
          </div>
        );
      case "Inactive":
        return (
          <div className="theme-table-status inactive" data-e2e={`Inactive-${blockchain}`}>
            INACTIVE
          </div>
        );
      case "Pending":
        return (
          <div className="theme-table-status pending" data-e2e={`pending-${blockchain}`}>
            PENDING
          </div>
        );
      case "Locked":
        return (
          <div
            className="theme-table-status witheld"
            data-e2e={`locked-${blockchain}`}
          >
            LOCKED
          </div>
        );
      case "Transfer Requested":
        return (
          <div className="theme-table-status witheld" data-e2e="TRANSFER RQUESTED">
            TRANSFER RQUESTED
          </div>
        );
      case "In Use":
        return (
          <div
            className="theme-table-status inactive"
            data-e2e={`in-use-${blockchain}`}
          >
            IN USE
          </div>
        );
      case "Archived":
        return (
          <div
            className="theme-table-status inactive"
            data-e2e={`in-use-${blockchain}`}
          >
            ARCHIVED
          </div>
        );
      case "Failed":
        return (
          <div
            className="theme-table-status inactive"
            data-e2e={`failed-${blockchain}`}
          >
            FAILED
          </div>
        );
      case "Inuse":
        return (
          <div
            className="theme-table-status failed"
            data-e2e={`in-use-${blockchain}`}
          >
            INUSE
          </div>
        );
      default:
        return (
          <div
            className="theme-table-status inactive"
            data-e2e={`in-use-${blockchain}`}
          >
            IN USE
          </div>
        );
    }
  };

  const getTransferWallets = async (blockchain, network) => {
    // props.setLoading(true);
    await call(
      {
        ...GET_TRANSFER_WALLETS,
        url: api_url + GET_TRANSFER_WALLETS.url,
      },
      { email: adminEmail, blockchain, network }
    )
      .then((res) => {
        // props.setLoading(false);
        if (res.status === 200) {
          let results =
            res &&
            res.data &&
            res.data.transferWallets &&
            res.data.transferWallets;
          setTransferWallets(results);
        }
      })
      .catch((err) => {
        // props.setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };

  const createTransaction = async (data) => {
    let payload = {
      email: adminEmail,
      type: "Transfer",
      network: data.network,
      createdBy: adminEmail,
      fromWalletId: data._id,
      fromWalletaddress: data.address.key,
      toWalletId: singleTransferWallets._id,
      toWalletAddress: singleTransferWallets.address,
      toWalletName: singleTransferWallets.name,
      cryptoId: selectedToken,
      cryptoAmount: cryptoAmount,
      usdAmount: cryptoEquUSDAmount,
      fromWalletTypeForTransfer: "merchantUserWallet",
      transferTransactionType: "merchantUserTransaction",
      status: "WalletTransferFlow:TransferRequested",
      message: "Transfer created by: " + adminEmail,
      walletTypeForTransfer: selectedWalletType,
    };
    props.setLoading(true);
    await call(
      {
        ...SAVE_TRANSACTION_FROM_ADMIN,
        url: api_url + SAVE_TRANSACTION_FROM_ADMIN.url,
      },
      payload
    )
      .then((res) => {
        props.setLoading(false);
        if (res.status === 200) {
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };

  const transferFunds = async () => {
    if (walletIdForTransferFunds && walletIdForTransferFunds !== "") {
      props.setLoading(true);

      await call(
        {
          ...UPDATE_WALLET_STATUS,
          url: api_url + UPDATE_WALLET_STATUS.url,
        },
        {
          email: adminEmail,
          status: "Transfer Requested",
          message: `${adminEmail}`,
          walletId: walletIdForTransferFunds,
        }
      )
        .then((res) => {
          props.setLoading(false);
          if (res.status === 200) {
            toggleShowSuccess(true);
            setTimeout(() => {
              toggleShowSuccess(false);
            }, 2000);
            props.getWallets();
          }
        })
        .catch((err) => {
          toggleShowError(true);
          setTimeout(() => {
            toggleShowError(false);
          }, 2000);
          props.setLoading(false);
          console.log(err.message, "err");
          logoutAfterError(err);
        });
    } else {
      toggleShowError(true);
      setTimeout(() => {
        toggleShowError(false);
      }, 2000);
    }
  };

  const getSingleTransferWallet = async (wallet) => {
    setLimitError('')
    let data = transferWallets.filter((item) => item._id === wallet.value);
    setSingleTransferWallets(data[0]);
    setSelectedTransferWallet(wallet.label);
    setSelectedWalletType("transferWallet");
  };
  const getSingleCentralisedWallet = async (wallet) => {
    setLimitError('')
    let results = allCentralisedWalletsData.filter((item) => item._id === wallet.value);
    setSingleTransferWallets({
      address: results[0].address.key,
      blockchain: results[0].blockchain,
      name: "",
      network: results[0].network,
      _id: results[0]._id,
    });
    setSelectedTransferWallet(wallet.label);
    setSelectedWalletType("centralisedWallet");
  };
  const getSingleTransferMerchantWallet = async (wallet) => {
    setLimitError('')
    let results = allWalletsData.filter((item) => item._id === wallet.value);
    setSingleTransferWallets({
      address: results[0].address.key,
      blockchain: results[0].blockchain,
      name: "",
      network: results[0].network,
      _id: results[0]._id,
    });
    setSelectedTransferWallet(wallet.label);
    setSelectedWalletType("merchantUserWallet");
  };

  const viewSingleWalletDetails = async (id) => {
    await call(
      {
        ...VIEW_SINGLE_WALLET,
        url: view_transaction_url + VIEW_SINGLE_WALLET.url,
      },
      { email: email, walletId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          let results = res && res.data && res.data.wallets && res.data.wallets;
          setSelectedWalletTransfer(results);
          let temp =
            results &&
            results.balance &&
            results.balance.length > 0 &&
            results.balance.map(({ cryptoId }) => ({
              value: cryptoId,
              label: cryptoId,
            }));
          setSelectedWalletForTransfer(temp);
          props.setLoading(false);
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };
  const getSingleCryptoExchangedRate = (
    amount,
    cryptoCurrency,
    fiatCurrency
  ) => {
    return new Promise(async (resolve, reject) => {
      const baseUrl = window.location.hostname.includes("localhost")
        ? payment_api_url
        : view_transaction_url;
      setLodingExchange(true);
      await call(
        {
          ...GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
          url: baseUrl + GET_CRYPTO_AMOUNT_EXCHANGE_RATE.url,
        },
        {
          email: email,
          currencyAmount: amount,
          fromCurrency: cryptoCurrency,
          toCurrency: fiatCurrency,
        }
      )
        .then((res) => {
          if (res.status === 200) {
            props.setLoading(false);
            setLodingExchange(false);
            resolve(res.data);
          }
        })
        .catch((err) => {
          props.setLoading(false);
          setLodingExchange(false);
          console.log(err.message, "err");
          logoutAfterError(err);
        });
    });
  };

  useEffect(() => {
    if (selectedTokenDetails) {
      if (selectedToken === "XRP") {
        let newValue = selectedTokenDetails.balanceAmount - 10;
        newValue = newValue > 0 ? newValue : 0;
        onCryptoAmountChange(newValue);
      } else {
        onCryptoAmountChange(selectedTokenDetails.balanceAmount);
      }
    }
  }, [selectedTokenDetails]);

  const handleTransfer = async (id, blockchain, network) => {
    setSelectedNetwork(network)
    setSelectedBlockchain(blockchain)
    toggleShowModal(true);
    setWalletIdForTransferFunds(id);
    await getTransferWallets(blockchain, network);
    await viewSingleWalletDetails(id);
    await getWallets(blockchain, network, true);
    await getCentralisedWallets(blockchain, network);
  };
  const getWallets = async (blockchain, network, transferFlag) => {
    // props.setLoading(true);

    await call(
      {
        ...GET_WALLETS,
        url: view_transaction_url + GET_WALLETS.url,
      },
      {
        email: email,
        filterParams: {
          adminRole: localDb.getVal("adminRole"),
          allWallets: "true",
          blockchain,
          network,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          const walletsData = (res && res.data && res.data.wallets) || [];
          if (transferFlag) {
            const filteredAllWalletsData = walletsData.filter(item => item.address && item.address.key !== (data.address && data.address.key));
            setAllWalletsData(filteredAllWalletsData);
          } else {
            setAllWalletsData(walletsData);
          }
          // props.setLoading(false);
        }
      })
      .catch((err) => {
        // props.setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };
  const getCentralisedWallets = async (blockchain, network) => {
    // props.setLoading(true);

    await call(
      {
        ...GET_CENTRALISED_WALLET,
        url: view_transaction_url + GET_CENTRALISED_WALLET.url,
      },
      {
        email: email,
        filterParams: {
          adminRole: localDb.getVal("adminRole"),
          allWallets: "true",
          blockchain,
          network,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setAllCentralisedWalletsData(
            res && res.data && res.data.wallets && res.data.wallets
          );
          // props.setLoading(false);
        }
      })
      .catch((err) => {
        // props.setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };
  const validateAmount = (value) => {
    if (value <= 0) {
      setLimitError("Amount must be greater than zero");
    } else if (
      selectedToken === "XRP" &&
      value > selectedTokenDetails.balanceAmount - 10
    ) {
      setLimitError(
        "Amount must be less than equal to " +
        (selectedTokenDetails.balanceAmount - 10) +
        " " +
        selectedToken
      );
    } else if (value > selectedTokenDetails.balanceAmount) {
      setLimitError(
        "Amount must be less than equal to " +
        selectedTokenDetails.balanceAmount +
        " " +
        selectedToken
      );
    } else {
      setLimitError("");
    }
  };

  const onCryptoAmountChange = async (value) => {
    if (value) {
      setCryptoAmount(value);
    } else if (value === 0) {
      setCryptoAmount(0);
    } else {
      setCryptoAmount(null);
    }
    const result = await getSingleCryptoExchangedRate(
      value,
      selectedToken,
      "USD"
    );
    if (value && ReadableStreamDefaultController) {
      // const usdEquAmt = parseFloat(value / result.cryptoAmounts[selectedToken]).toFixed(8)
      const usdEquAmt = parseFloat(result).toFixed(8);
      validateAmount(value);
      setCryptoEquUSDAmount(usdEquAmt);
    } else {
      validateAmount(0);
      setCryptoEquUSDAmount(0);
    }
  };
  const onCryptoEquUSDAmountChange = async (value) => {
    if (value) {
      setCryptoEquUSDAmount(value);
    } else if (value === 0) {
      setCryptoEquUSDAmount(0);
    } else {
      setCryptoEquUSDAmount(undefined);
    }
    const result = await getSingleCryptoExchangedRate(
      value,
      "USD",
      selectedToken
    );
    if (value && result) {
      setCryptoAmount(parseFloat(result).toFixed(8));
      // validateAmount(result.cryptoAmounts[selectedToken])
    } else {
      setCryptoAmount(0);
      // validateAmount(0)
    }
  };
  const handleChangeCryptoCurrency = (event) => {
    setSelectedToken(event.value);
    let cryptoObj = {};
    selectedWalletTransfer.balance.map((data) => {
      if (event.value === data.cryptoId) {
        cryptoObj = data;
      }
      return data
    });
    setSelectedTokenDetails(cryptoObj);
    setLimitError("");
  };
  const handleChoosenWallet = (value) => {
    setSingleTransferWallets(null);
    setChoosenWallet(value);
  };

  const handleCreate = async (inputValue) => {
    setLimitError("");
    const addressKey = data.address ? data.address.key : "";
    if (inputValue === addressKey) {
      setLimitError("Wallet address not found");
    } else {
      try {
        const res = await call(
          {
            ...GET_WALLET_BY_ADDRESS,
            url: view_transaction_url + GET_WALLET_BY_ADDRESS.url,
          },
          {
            email,
            adminRole: localDb.getVal("adminRole"),
            address: inputValue,
            status: "Locked",
            blockchain: selectedBlockchain,
            network: selectedNewtwork,
          }
        );
        if (res.data) {
          const { address, blockchain, network, _id } = res.data;
          setSingleTransferWallets({
            address: address.key,
            blockchain,
            name: "",
            network,
            _id,
          });
          setSelectedTransferWallet(inputValue);
          setSelectedWalletType("merchantUserWallet");
        } else {
          setLimitError("Wallet address not found");
        }
      } catch (err) {
        setLimitError("Something went wrong");
        // Handle the error or log it as needed
        console.error(err);
      }
    }
  };
  

  const getTransactionStatusCodes = async (txnId) => {
    await call({
      ...GET_TRANSACTION_CURRENT_STATUS,
      url: view_transaction_url + GET_TRANSACTION_CURRENT_STATUS.url
    },
      {
        email: email,
        transactionId: txnId
      }
    ).then((res) => settransactionStatus(res?.data?.status?.code))
  }
    return (
    <tr className="theme-table-row">
      <td>
        <div className="theme-table-row-td">
          <p className="theme-table-text">{(data.blockchain && data.blockchain) || "N/A"}</p>
        </div>
      </td>
      <td>
        <div className="theme-table-row-td">
          <p className="theme-table-text">{(data && data.cryptoId && data.cryptoId[0] && data.cryptoId[0].cryptoId && data.cryptoId[0].cryptoId) || "N/A"}</p>
        </div>
      </td>
      <td>
        <div className="theme-table-row-td">
          <p className="theme-table-text">{(data.network && data.network) || "N/A"}</p>
        </div>
      </td>
      <td>
        <div className="theme-table-row-td">
          {data.housekeeper ? (
            data.housekeeper.filter((housekeeperobj) =>
              data.balance.some(
                (balanceobj) =>
                  housekeeperobj.crypto === balanceobj.cryptoId &&
                  housekeeperobj.status === "Unmatched" &&
                  (Math.abs(balanceobj.balanceAmount - housekeeperobj.value) /
                    balanceobj.balanceAmount) *
                  100 <=
                  5
              )
            ).length > 0 ? (
              <HtmlTooltip
                title={<ToolTipBalance data={data} />}
                placement="top"
              >
                <WarningIcon
                  style={{
                    color: "orange",
                    fontSize: "18px",
                    marginRight: "6px",
                  }}
                />
              </HtmlTooltip>
            ) : data.housekeeper.filter((obj) => obj.status === "Unmatched")
              .length > 0 ? (
              <HtmlTooltip
                title={<ToolTipBalance data={data} />}
                placement="top"
              >
                <WarningIcon
                  style={{ color: "red", fontSize: "18px", marginRight: "6px" }}
                />
              </HtmlTooltip>
            ) : (
              <HtmlTooltip
                title={<ToolTipBalance data={data} />}
                placement="top"
              >
                <CheckBoxIcon
                  style={{ color: "green", fontSize: "18px", marginRight: "6px" }}
                />
              </HtmlTooltip>
            )
          ) : (
            " "
          )}
          <Tooltip title={data.address.key && data.address.key} placement="top">
            <Link className="theme-table-text"
              to={`/wallets/${data._id}`}
              data-e2e-blockchain={data.blockchain
                .toLowerCase()
                .replaceAll(" ", "-")}
              data-e2e={`${data.status.code}-${data.blockchain}`}
              data-e2e-full-addres={data.address.key && data.address.key}
              data-e2e-yes={`${data.status.code}-${(data.blockchain && data.blockchain) || "N/A"
                }-${(data.balanceAmountUSDApprox &&
                  parseFloat(data.balanceAmountUSDApprox).toFixed(4)) > 0
                  ? "yes"
                  : "no"
                }`}
            >
              {data.address.key
                ? data.address.key.substring(0, 5) +
                "....." +
                data.address.key.substring(
                  data.address.key.length - 6,
                  data.address.key.length
                )
                : "N/A"}
            </Link>
          </Tooltip>
        </div>
      </td>


      <td>
        <Tooltip title={<p>
          {txnId} <br />
          Status: {transactionStatus}
        </p>} placement="top">
          <div className="text-black" onMouseEnter={() => getTransactionStatusCodes(txnId)}>
            {txnId === "N/A" ? "N/A" : <Link to={`/transaction/${txnId}`} target={"_blank"} className="  underline hover:underline  hover:text-[#2373ff]">
              {txnId.substring(0, 5) + "..." + txnId.substring(txnId.length - 6, txnId.length)}
            </Link>}
          </div>
        </Tooltip>
      </td>
      <td>
        <div className="theme-table-row-td">
          <p className="theme-table-text">{(data.address && data.address.seedIndex) || "0"}</p>
        </div>
      </td>
      <td>
        <div className="theme-table-row-td">
        <p className="theme-table-text">{(data && data.paymentProvider[0] && data.paymentProvider[0].firstName && data.paymentProvider[0].firstName) + " " + (data && data.paymentProvider[0] && data.paymentProvider[0].lastName && data.paymentProvider[0].lastName) || "N/A"}</p>
        </div>
      </td>
      <td>
        <div className="theme-table-row-td">
          <p className="theme-table-text">{data.status && data.status.updated
            ? moment(data.status.updated).format("DD/MM/YYYY - HH:mm")
            : "N/A"}
          </p>
        </div>
      </td>

      <td>
        <div className="theme-table-row-td">
          <p className="theme-table-text" data-e2e="amount" data-e2e-amount={
            (data.balanceAmountUSDApprox &&
              parseFloat(data.balanceAmountUSDApprox).toFixed(4)) ||
            "0.0000"
          }>{(data.balanceAmountUSDApprox &&
            parseFloat(data.balanceAmountUSDApprox).toFixed(4)) ||
            "0.0000"}
          </p>
        </div>
      </td>

      <td>
        <div className="theme-table-row-td">
          {data.status && data.status.code
            ? getStatusComponent(data.status.code, data.blockchain)
            : "N/A"}
        </div>
      </td>


      <td className="transactionsTable_item">
        <div>
          {data.status && data.status.code === "Locked" ? (
            <button type="button"
              className={`${isViewPermissionValid? "disabled": ""} theme-table-action`}
              onClick={() =>
                isViewPermissionValid? null: handleTransfer(data?._id, data?.blockchain, data?.network)
              }
              data-e2e={`${data.blockchain.toLowerCase().replaceAll(" ", "-")}`}
              data-e2e-wallet-address={
                (data && data.address && data.address.key) || null
              }
            >
              TRANSFER
            </button>
          ) : null}
          {showModal ? (
            <div className="merchant_dailog_screen">
              <div>
                <Modal
                  show={true}
                  className="ClearAlertModal transferModal transferFundsModal walletTableTransferModel"
                  onHide={() => {
                    toggleShowModal(false);
                  }}
                >
                  <Modal.Body className="ClearAlertModalBody transferModalBody transaferModalBodyOverflow">
                    <div className="transferModalTitleWrap">
                      <div
                        className="transferModalTitle"
                        data-e2e="TRANSFER FUNDS"
                      >
                        Transfer Funds
                      </div>
                      <img
                        className="closeIcon"
                        alt="closeIcon"
                        src={`${image_base_url}${closeImage}`}
                        onClick={() => {
                          toggleShowModal(false);
                        }}
                      />
                    </div>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Choose Wallets
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              checked={choosenWallet === "Transfer"}
                              onChange={() => {
                                handleChoosenWallet("Transfer");
                              }}
                            />
                          }
                          label="Transfer Wallets"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={choosenWallet === "Available"}
                              onChange={() => {
                                handleChoosenWallet("Available");
                              }}
                            />
                          }
                          label="Available Wallets"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={choosenWallet === "Centeralised"}
                              onChange={() => {
                                handleChoosenWallet("Centeralised");
                              }}
                            />
                          }
                          label="Centeralised Wallets"
                        />
                      </RadioGroup>
                    </FormControl>
                    <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                      <label
                        className="myAccoutOptModalLable"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Saved Transfer Wallets
                      </label>
                      <Select
                        options={transferWallets.map(({ name, _id }) => ({
                          label: name,
                          value: _id,
                        }))}
                        isDisabled={choosenWallet !== "Transfer"}
                        onChange={(event) => {
                          getSingleTransferWallet(event);
                        }}
                      />
                    </div>
                    <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                      <label
                        className="myAccoutOptModalLable"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Merchant User Wallets
                      </label>
                      <CreatableSelect
                        isDisabled={choosenWallet !== "Available"}
                        onCreateOption={handleCreate}
                        isClearable
                        options={allWalletsData.map((item) => ({
                          label: item.address.key,
                          value: item._id,
                        }))}
                        onChange={(event) => {
                          getSingleTransferMerchantWallet(event);
                        }}
                      />
                    </div>
                    <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                      <label
                        className="myAccoutOptModalLable"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Centeralised Transfer Wallets
                      </label>
                      <Select
                        options={allCentralisedWalletsData.map((item) => ({
                          label: item.address.key,
                          value: item._id,
                        }))}
                        isDisabled={choosenWallet !== "Centeralised"}
                        onChange={(event) => {
                          getSingleCentralisedWallet(event);
                        }}
                      />
                    </div>
                    {singleTransferWallets && (
                      <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Destination Wallet Address:
                        </label>
                        <div className="WalletAddress">
                          {singleTransferWallets.address}
                        </div>
                      </div>
                    )}
                    {singleTransferWallets && (
                      <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect1"
                        >
                          CryptoCurrency
                        </label>
                        <Select
                          options={selectedWalletForTransfer}
                          onChange={(event) => {
                            handleChangeCryptoCurrency(event);
                          }}
                        />
                      </div>
                    )}

                    {selectedToken && (
                      <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect1"
                        >
                          <span>Amount</span>
                          {loadingExchange ? (
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                marginLeft: "20px",
                              }}
                            >
                              Loading Exchange Value...
                            </span>
                          ) : null}
                        </label>
                        <div className="amountExchageWrap">
                          <input
                            type="number"
                            value={cryptoAmount}
                            className="form-control inputdropdown"
                            id="cryptoAmount"
                            data-e2e="crypto-amount"
                            placeholder="amount in crypto"
                            name="cryptoAmount"
                            onChange={(e) => {
                              onCryptoAmountChange(e.target.value);
                            }}
                          />
                          <div className="exchangeIcon">
                            <i className="fa fa-exchange"></i>
                          </div>
                          <input
                            type="number"
                            value={cryptoEquUSDAmount}
                            className="form-control inputdropdown"
                            id="cryptoEquUSDAmount"
                            data-e2e="cryptoEquUSDAmount"
                            data-e2e-covert={cryptoEquUSDAmount}
                            placeholder="amount in USD"
                            name="cryptoEquUSDAmount"
                            onChange={(e) => {
                              onCryptoEquUSDAmountChange(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {limitError !== "" && (
                      <div className="limitErrorText">{limitError}</div>
                    )}
                    <div
                      className={`ClearAlertModalClearBtn transferFundsBtn ${selectedTransferWallet !== "" &&
                        limitError === "" &&
                        selectedToken !== ""
                        ? ""
                        : "transferFundsBtnDisable"
                        }`}
                      onClick={() => {
                        if (
                          selectedTransferWallet !== "" &&
                          limitError === "" &&
                          selectedToken !== ""
                        ) {
                          toggleAlertModal(true);
                        }
                      }}
                      data-e2e="transfer-fund-btn"
                    >
                      TRANSFER FUNDS
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          ) : (
            <style>{`\
              body {
                overflow: auto !important;
              }
          `}</style>
          )}
          {
            <div className="merchant_dailog_screen">
              <Dialog
                open={alertModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"lg"}
              >
                <div>
                  <Modal
                    show={true}
                    className="ClearAlertModal transferModal"
                    onHide={() => {
                      toggleAlertModal(false);
                    }}
                  >
                    <Modal.Body className="ClearAlertModalBody transferModalBody">
                      <div className="ClearAlertModalTitle">Are you sure?</div>
                      <div className="ClearAlertModalDescription">
                        Are you sure you want to transfer funds to selected
                        wallet?
                      </div>
                      <div
                        className={`ClearAlertModalClearBtn transferFundsBtn`}
                        onClick={() => {
                          if (selectedTransferWallet) {
                            transferFunds();
                            createTransaction(data);
                            toggleAlertModal(false);
                            toggleShowModal(false);
                          }
                        }}
                        data-e2e="transfer-btn"
                      >
                        Transfer
                      </div>
                      <div
                        className="ClearAlertModalGoBackBtn"
                        onClick={() => {
                          toggleAlertModal(false);
                        }}
                        data-e2e="go-back-btn"
                      >
                        Go Back
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </Dialog>
            </div>
          }
          {showSuccess && (
            <div className="successModal">
              <div className="successModalWrap">
                <img
                  className="successModalIcon"
                  alt="successIcon"
                  src={`${image_base_url}${successIcon}`}
                />
                <div className="successModalMsg">
                  Transfer request has been raised successfully!
                </div>
                <img
                  data-e2e="close-icon"
                  className="closeIcon"
                  alt="closeIcon"
                  src={`${image_base_url}${closeImage}`}
                  onClick={() => {
                    toggleShowSuccess(false);
                  }}
                />
              </div>
            </div>
          )}
          {showError && (
            <div className="successModal">
              <div className="successModalWrap">
                <img
                  className="successModalIcon"
                  alt="errorIcon"
                  src={`${image_base_url}${deleteIcon}`}
                />
                <div className="successModalMsg">
                  Something when wrong, transfer failed!{" "}
                </div>
                <img
                  className="closeIcon"
                  alt="closeIcon"
                  src={`${image_base_url}${closeImage}`}
                  onClick={() => {
                    toggleShowError(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {props.loading && <Loading />}
      </td>
    </tr>
  );
};

// HTMl Tooltip
const HtmlTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    backgroundColor: "#fafafa",
    color: "#000000",
    maxWidth: 800,
    border: "1px solid #dadde9",
    boxShadow: "10px",
  },
}));

//content of tooltip
const ToolTipBalance = ({ data }) => {
  let date;
  if (data && data.housekeeper && data.housekeeper.length>0 && data.housekeeper.some(coinObj => coinObj.cypto === "ETH")) {
    date = data.housekeeper.find(coinObj => coinObj.cypto === "ETH").updated;
  } else {
    date = data && data.housekeeper && data.housekeeper.length>0 && data.housekeeper[0].updated
  }
  return (
    <div className="wallettooltipcontainer">
      <div className="wallettooltipheading">Wallet Balance </div>
      {data.balance.map((balance, i) => {
        return (
          <div className="wallettooltiptabletext" key={i}>
            <div className="wallettooltippadding">{balance.cryptoId}</div>
            <div className="wallettooltippadding">
              {balance.balanceAmount.toFixed(6)} ($
              {balance.balanceAmountUSDApprox} USD){" "}
            </div>
          </div>
        );
      })}

      <div className="wallettooltipheading"> Blockchain balance </div>
      {data.housekeeper.map((balance, i) => {
        return (
          <div className="wallettooltiptabletext" key={i}>
            <div className="wallettooltippadding">{balance.crypto}</div>
            <div className="wallettooltippadding">
              {balance.value.toFixed(6)}{" "}
            </div>
          </div>
        );
      })}
       <div className="wallettooltiptabletext" key={"Last Updated-Date-to-diplay"}>
            <div className="last-updated-date wallettooltippadding">{"Last Updated"}</div>
            <div className="wallettooltippadding">
            {date && date
            ? moment(date).format("DD/MM/YYYY - HH:mm")
            : "N/A"}
            </div>
          </div>
    </div>
  );
};

function getTxnId(data) {
  if (data && data.status && data.status.code === "In Use") {
    const txnId = data?.status?.message && data?.status?.message?.includes("transferTransactionID=") || data?.status?.message?.includes("tranferTrasactionID=")? data?.status : data?.status;
    return txnId || "N/A";
  } else {
    let inUseHistory = [];
    inUseHistory = (data && data.status && data.status.history) ? data?.status?.history
      .filter((item) => item.code === "In Use")
      .reverse() : [];

    if (inUseHistory && inUseHistory.length > 0) {
      if (inUseHistory[0]?.message && typeof inUseHistory[0]?.message === "string") {
        const txnId = inUseHistory[0]?.message?.includes("transferTransactionID=") || inUseHistory[0]?.message?.includes("tranferTrasactionID=")? inUseHistory[0] : inUseHistory[0];
        return txnId || "N/A";
      }
      return "N/A";
    } else {
      return "N/A";
    }
  }
}
