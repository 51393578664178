import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { call, logoutAfterError } from '../../../../config/axios';
import { GET_ALL_PAYMENT_PROVIDERS, GET_CRYPTOS, GET_MERCHANT_WALLETS, GET_MERCHANT_WALLETS_SUMMARY } from '../../../../config/endpoints';
import localDb from '../../../../localDb';
import Loading from '../../../common/Loading';
import { setHeader } from '../../../Redux/actions/commonAction';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import DownloadCsv from '../CommonPages/DownloadCsv';
import MobileViewSummary from '../MobileViewCommonComponent/MobileViewSummary';
import TransactionFilterMobile from '../MobileViewCommonComponent/TransactionFilterMobile';
import TransactionFilters from '../Transactions/TransactionFilters';
import WalletTable from './WalletTable';
import useDebounce from '../../global/useDebounce';

const searchicon = '/cryptonpay/crytoicons/searchicon1.png';
const filterIcon = "/cryptonpay/filter_icon.svg";
const downloadIcon = "/cryptonpay/download_icon.svg";

function Wallets() {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal('email');
  const base_url = useSelector((state) => state.config.api_url);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearhText] = useState('');
  const [orderBy, setOrderBy] = useState('desc');
  const [sortBy, setSortBy] = useState('status.updated');
  const [fromDate, setFromDate] = useState(
    moment().subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  );
  const [toDate, setToDate] = useState(
    moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  );
  const [dateSelected, setDateSelected] = useState(false);
  const [transactionFitlers, setTransactionFilters] = useState(false);

  const [statusList, setStatusList] = useState([
    'In Use',
    'Available',
    'Locked',
  ]);
  const [selectedStatusList, setSelectedStatusList] = useState([
    'In Use',
    'Available',
    'Locked',
  ]);
  const [blockchainList, setBlockchainList] = useState([
    'Ethereum',
    'Bitcoin',
  ]);

  const [selectedBlockchainList, SetSelectedBlockchainList] = useState([
    'Ethereum',
    'Bitcoin',
  ]);

  const [networkList, setNetworkList] = useState([
    'mainnet',
    'testnet',
  ]);

  const [selectedNetworkList, setSelectedNetworkList] = useState([
    'mainnet',
    'testnet',
  ]);

  const [cryptos, setCryptos] = useState([]);
  const [selectedCryptos, setSelectedCryptos] = useState([]);
  const [save, setSave] = useState(false);

  const handleTransactionFilters = () => {
    setTransactionFilters(false);
  };
  const [downloadCsv, setDownloadCsv] = useState(false);

  const handleCloseDownloadCsv = () => {
    setDownloadCsv(false);
    getWallets();
  };
  const handleDownloadCsvIconClick=async()=>{
    await getWallets("all");
    setDownloadCsv(true);
  }

  const handleSave = () => {
    setSave(!save);
  };
  const resetFilters = () => {
    setSearhText('');
    setOrderBy('desc');
    setSortBy('status.updated');
    setPage(1);
    setDateSelected(false);
    setTotalCount(0);
    setSelectedStatusList(['In Use', 'Available', 'Locked']);
    setBlockchainList([
      'Ethereum',
      'Bitcoin',
    ]);
    setNetworkList([
      'mainnet',
      'testnet',
    ]);
    setFromDate([
      moment().subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    ]);
    setToDate([moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')]);
    setSelectedCryptos([]);
  };
  const getCryptos = async () => {
    await call(
      {
        ...GET_CRYPTOS,
        url: base_url + GET_CRYPTOS.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setCryptos(res.data.cryptos);

          let cryptos = res.data.cryptos.map((crypto) => {
            return crypto._id;
          });
          setSelectedCryptos(cryptos);
        }
      })
      .catch((err) => {
        console.log(err.message, 'err')
        logoutAfterError(err)
    });
  };

  const adminRole = localStorage.getItem("adminRole");
  useEffect(() => {
    getCryptos();
    if (adminRole == "SuperAdmin") {
      getProviders();
    }
  }, []);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  });
  const getWallets = async (page) => {
    let pageNo = 1;
    if (page) {
      pageNo = page;
    }
    let filterParams = {
      email: email,
      wallet_id: searchText.toString(),
      paymentProvider: localStorage.getItem("paymentProviderId"),
      orderBy: orderBy,
      sortBy: sortBy,
      page: pageNo,
      blockchain: selectedBlockchainList.length === 0 ||
        selectedBlockchainList.length === blockchainList.length
        ? 'All'
        : selectedBlockchainList,
      network: selectedNetworkList.length === 0 ||
        selectedNetworkList.length === networkList.length
        ? 'All'
        : selectedNetworkList,
      status:
        selectedStatusList.length === 0 ||
          selectedStatusList.length === statusList.length
          ? 'All'
          : selectedStatusList,
      CryptoCurrency:
        selectedCryptos.length === 0 ||
          selectedCryptos.length === cryptos.length
          ? 'All'
          : selectedCryptos,
      ...(adminRole == "SuperAdmin" && {
        providers:
          selectedPaymentProvider.length === 0 ||
            selectedPaymentProvider.length === paymentProviderList.length
            ? 'All'
            : selectedPaymentProvider
      })
    };

    if (dateSelected) {
      filterParams = {
        ...filterParams,
        fromDate: fromDate,
        toDate: toDate,
      };
    }
    let payload = {
      email: email,
      filterParams: filterParams,
    };
    setLoading(true);
    await call(
      {
        ...GET_MERCHANT_WALLETS,
        url: base_url + GET_MERCHANT_WALLETS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          if (isMobile) {
            setData(res.data.wallets);
            setTotalCount(res.data.totalCount);
          } else {
            if (pageNo === 1) {
              setData(res.data.wallets);
              setTotalCount(res.data.totalCount);
            } else {
              let list = data.concat(res.data.wallets);
              setData(list);
              setTotalCount(res.data.totalCount);
            }
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };
  const debouncedSearchText = useDebounce(searchText);
  useEffect(() => {
    dispatch(setHeader('Merchant Wallets'));
    getWallets()
  }, [debouncedSearchText, orderBy, sortBy, save]);

  useEffect(() => {
    getWalletsSummary()
  }, [save]);

  const [totalUSDAmount, setTotalUSDAmount] = useState(0);
  const [numberOfWallets, setNumberOfWallets] = useState([]);
  const [sumOfCryptoAmount, setSumOfCryptoAmount] = useState([]);
  const getWalletsSummary = async () => {
    let filterParams = {
      email: email,
      wallet_id: searchText.toString(),
      paymentProvider: localStorage.getItem("paymentProviderId"),
      blockchain: selectedBlockchainList.length === 0 ||
        selectedBlockchainList.length === blockchainList.length
        ? 'All'
        : selectedBlockchainList,
      network: selectedNetworkList.length === 0 ||
        selectedNetworkList.length === networkList.length
        ? 'All'
        : selectedNetworkList,
      status:
        selectedStatusList.length === 0 ||
          selectedStatusList.length === statusList.length
          ? 'All'
          : selectedStatusList,
      CryptoCurrency:
        selectedCryptos.length === 0 ||
          selectedCryptos.length === cryptos.length
          ? 'All'
          : selectedCryptos,
      ...(adminRole == "SuperAdmin" && {
        providers:
          selectedPaymentProvider.length === 0 ||
            selectedPaymentProvider.length === paymentProviderList.length
            ? 'All'
            : selectedPaymentProvider
      })
    };

    if (dateSelected) {
      filterParams = {
        ...filterParams,
        fromDate: fromDate,
        toDate: toDate,
      };
    }
    let payload = {
      email: email,
      filterParams: filterParams,
    };
    setLoading(true);
    await call(
      {
        ...GET_MERCHANT_WALLETS_SUMMARY,
        url: base_url + GET_MERCHANT_WALLETS_SUMMARY.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalUSDAmount(res.data.totalUSDAmount.length > 0 ? res.data.totalUSDAmount[0].totalUSD : 0);
          setNumberOfWallets(res.data.numberOfWallets);
          setSumOfCryptoAmount(res.data.sumOfCryptoAmount)
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false); console.log(err.message, 'err')
        logoutAfterError(err)
      });
  };

  const [paymentProviderList, setPaymentProviderList] = useState([]);
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState([]);
  const getProviders = async () => {
    let payload = {
      email,
      firstName: searchText,
    };
    setLoading(true);
    await call(
      {
        ...GET_ALL_PAYMENT_PROVIDERS,
        url: base_url + GET_ALL_PAYMENT_PROVIDERS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setPaymentProviderList(res.data.providerList);
          let providerId = [];
          if (res.data.providerList.length > 0) {
            providerId = res.data.providerList.map(e => e._id);
          }
          setSelectedPaymentProvider(providerId);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };
  const name = 'Merchant Wallets';
  const [openMobileSearch, setOpenMobileSearch] = useState(false);
  const [transactionFitlerMobile, setTransactionFilterMobile] = useState(false);

  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
				<div className="">
					<CommonHeader name={name} />
					<div className="transactionFilter__allMerchants">
						<div className="transactionFilter">
							<input
								type="text"
								className="transactionFilter__input"
								value={searchText}
								name="searchText"
								data-e2e="search-textbox"
								onChange={(e) => {
									setSearhText(e.target.value);
									setPage(1);
								}}
								placeholder="Search Wallet Address.."
							/>
							<img
								src={`${image_base_url}${searchicon}`}
								alt="searchicon"
								className="transactionFilter__icon"
								data-e2e="searchIcon"
								onClick={() => {
									getWallets();
								}}
							/>
						</div>
						<button type="button" data-e2e="filterBtn" className="transactionFilter__btn" onClick={() => {setTransactionFilters(true);}}>
							<img data-e2e="filterIcon" src={`${image_base_url}${filterIcon}`} alt="filterIcon" />
						</button>
						<button type="button" data-e2e="filterBtn" className="transactionFilter__btn" onClick={ handleDownloadCsvIconClick}>
							<img data-e2e="downloadIcon" src={`${image_base_url}${downloadIcon}`} alt="downloadIcon" />
						</button>

						<Dialog
							open={transactionFitlers}
							onClose={handleTransactionFilters}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<TransactionFilters
								filter_name="wallet"
								handleTransactionFilters={handleTransactionFilters}
								statusList={statusList}
								setStatusList={setStatusList}
								selectedStatusList={selectedStatusList}
								statusGroupList={[]}
								selectedStatusGroupList={[]}
								setSelectedStatusList={setSelectedStatusList}
								blockchainList={blockchainList}
								setBlockchainList={setBlockchainList}
								selectedBlockchainList={selectedBlockchainList}
								SetSelectedBlockchainList={SetSelectedBlockchainList}
								networkList={networkList}
								setNetworkList={setNetworkList}
								selectedNetworkList={selectedNetworkList}
								setSelectedNetworkList={setSelectedNetworkList}
								fromDate={moment(fromDate)}
								toDate={moment(toDate)}
								setFromDate={setFromDate}
								setToDate={setToDate}
								handleSave={handleSave}
								setDateSelected={setDateSelected}
								dateSelected={dateSelected}
								resetFilters={resetFilters}
								cryptos={cryptos}
								selectedCryptos={selectedCryptos}
								setSelectedCryptos={setSelectedCryptos}
								paymentProviderList={paymentProviderList}
								setPaymentProviderList={setPaymentProviderList}
								selectedPaymentProvider={selectedPaymentProvider}
								setSelectedPaymentProvider={setSelectedPaymentProvider}
								adminRole={adminRole}
							/>
						</Dialog>
						<Dialog
							open={downloadCsv}
							onClose={handleCloseDownloadCsv}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DownloadCsv
								handleCloseDownloadCsv={handleCloseDownloadCsv}
								data={data}
								resetFilters={resetFilters}
								name="wallets"
								data-e2e="wallets"
							/>
						</Dialog>
					</div>
					<div className="transactionSummary">
						<p className="transactionSummary__title">Summary:</p>
						<p className="transactionSummary__text mb">
							Number of wallets:&nbsp;<span>{
								numberOfWallets.length > 0 && numberOfWallets.map((ele, index) => (
									ele._id !== null &&
									<span key={index}>{ele._id + ": " + ele.count + "| "}</span>
								))
							}</span>
						</p>
						<p className="transactionSummary__text mb">Net USD Equivalent:&nbsp;<span>{parseFloat(totalUSDAmount).toFixed(2)}</span></p>
						<p className="transactionSummary__text">
							Sum by Crypto Amount:&nbsp;
							<span>
								{
									sumOfCryptoAmount.length > 0 && sumOfCryptoAmount.map((ele, index) => (
										ele.cryptoId !== null &&
										<span key={index}>{ele.cryptoId + ": " + parseFloat(ele.totalCryptoAmount).toFixed(4) + "| "}</span>
									))
								}
							</span>
						</p>
					</div>
					<div>
						<br/><br/>
						<div>
							<WalletTable
								table_name="Induvisual_transactions"
								data={data}
								sortBy={sortBy}
								orderBy={orderBy}
								getWallets={() => {
									getWallets(1)
								}}
								changeOrder={(sort) => {
									if (sortBy !== sort) {
										setOrderBy('desc');
										setSortBy(sort);
									} else {
										setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
									}
								}}
								handleShowMore={() => {
									let newPage = page + 1;
									setPage(newPage);
									getWallets(newPage);
								}}
								showMoreButton={totalCount && data && data.length < totalCount}
								page={page}
								handleShowLess={() => {
									let newPage = page - 1;
									setPage(newPage);
									getWallets(newPage);
								}}
								totalCount={totalCount}
							/>
						</div>
					</div>
				</div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
}

export default Wallets;
