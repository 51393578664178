import React from "react";
import { Modal } from "react-bootstrap";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
const saveIcon = "/cryptonpay/crytoicons/approve.png";

const SavingAlert = ({
  onCloseClickHandler,
  showModal,
  header,
  msg,
  btnName,
}) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);

  return (
    <div className="merchant_dailog_screen">
      <Dialog
        disableScrollLock
        open={showModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
        onClose={() => onCloseClickHandler()}
      >
        <div>
          <Modal
            show={true}
            className="ClearAlertModal deleteAlertModal ClearAlertModals"
            onHide={() => onCloseClickHandler()}
          >
            <Modal.Body className="ClearAlertModalBody">
              <div className="ClearAlertModalIconWrap">
                <img
                  src={`${image_base_url}${saveIcon}`}
                  alt="resetIcon"
                  className="ClearAlertModalIcon"
                />
              </div>
              <div className="ClearAlertModalTitle">{header}</div>
              <div className="ClearAlertModalDescription">{msg}</div>
              <div
                className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
                style={{ background: "#77AB37" }}
                onClick={() => onCloseClickHandler()}
                data-e2e="clear"
              >
                {btnName}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Dialog>
    </div>
  );
};

export default SavingAlert;
