
export const SET_HEADER = 'SET_HEADER';
export const SET_LOADING = 'SET_LOADING';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_ORDER_BY = 'SET_ORDER_BY';
export const SET_SORT_BY = 'SET_SORT_BY';
export const SET_FROM_DATE = 'SET_FROM_DATE';
export const SET_TO_DATE = 'SET_TO_DATE';
export const SET_DATE_SELECTED = 'SET_DATE_SELECTED';
export const SET_SELECTED_STATUS_LIST = 'SET_SELECTED_STATUS_LIST';
export const SET_SELECTED_STATUS_GROUP_LIST = 'SET_SELECTED_STATUS_GROUP_LIST';
export const SET_STATUS_LIST = 'SET_STATUS_LIST';
export const SET_TRANSACTION_STATUS_CODES = 'SET_TRANSACTION_STATUS_CODES';
export const SET_SELECTED_METHOD = 'SET_SELECTED_METHOD';
export const SET_SELECTED_TYPE = 'SET_SELECTED_TYPE';
export const SET_ACQUIRER_NAME_DATA = 'SET_ACQUIRER_NAME_DATA';
export const SET_SELECTED_ACQUIRER = 'SET_SELECTED_ACQUIRER';
export const SET_CRYPTOS = 'SET_CRYPTOS';
export const SET_SELECTED_CRYPTOS = 'SET_SELECTED_CRYPTOS';
export const SET_SELECTED_FIAT_CURRENCY = 'SET_SELECTED_FIAT_CURRENCY';
export const SET_SELECTED_NETWORK_LIST = 'SET_SELECTED_NETWORK_LIST';
export const SET_MIN_FIAT_AMOUNT = 'SET_MIN_FIAT_AMOUNT';
export const SET_MAX_FIAT_AMOUNT = 'SET_MAX_FIAT_AMOUNT';
export const SET_MERCHANT_NAME = 'SET_MERCHANT_NAME';
export const SET_MERCHANT_NAME_FOR_HEADER = 'SET_MERCHANT_NAME_FOR_HEADER';
export const SET_SELECTED_MERCHANT_NAME = 'SET_SELECTED_MERCHANT_NAME';
export const SET_IS_CONTAINS_NOTE = 'SET_IS_CONTAINS_NOTE';
export const SET_PAYMENT_PROVIDER_LIST = 'SET_PAYMENT_PROVIDER_LIST';
export const SET_SELECTED_PAYMENT_PROVIDER_LIST = 'SET_SELECTED_PAYMENT_PROVIDER_LIST';

export const SET_SEARCH_TEXT_WALLET = 'SET_SEARCH_TEXT_WALLET';
export const SET_MIN_CRYPTO_AMOUNT = 'SET_MIN_CRYPTO_AMOUNT';
export const SET_MAX_CRYPTO_AMOUNT = 'SET_MAX_CRYPTO_AMOUNT';
export const SET_RANGE_ERROR = 'SET_RANGE_ERROR';
export const SET_ORDER_BY_WALLET = 'SET_ORDER_BY_WALLET';
export const SET_SORT_BY_WALLET = 'SET_SORT_BY_WALLET';
export const SET_FROM_DATE_WALLET = 'SET_FROM_DATE_WALLET';
export const SET_TO_DATE_WALLET = 'SET_TO_DATE_WALLET';
export const SET_DATE_SELECTED_WALLET = 'SET_DATE_SELECTED_WALLET';
export const SET_SELECTED_STATUS_LIST_WALLET = 'SET_SELECTED_STATUS_LIST_WALLET';
export const SET_SELECTED_STATUS_ICON_LIST = 'SET_SELECTED_STATUS_ICON_LIST';
export const SET_SELECTED_BLOCKCHAIN_LIST = 'SET_SELECTED_BLOCKCHAIN_LIST';
export const SET_SELECTED_NETWORK_LIST_WALLET = 'SET_SELECTED_NETWORK_LIST_WALLET';
export const SET_SELECTED_CRYPTOS_WALLET = 'SET_SELECTED_CRYPTOS_WALLET';
export const SET_SELECTED_PAYMENT_PROVIDER_WALLET = 'SET_SELECTED_PAYMENT_PROVIDER_WALLET';
export const SET_CRYPTO_AMOUNT = "SET_CRYPTO_AMOUNT";
export const SET_ISSUER_COUNTRY_LIST = 'SET_ISSUER_COUNTRY_LIST';
export const SET_SELECTED_ISSUER_COUNTRY_LIST = 'SET_SELECTED_ISSUER_COUNTRY_LIST';