import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import IOSSwitch from "../IOSSwitch";
import {
  GET_RULES_BY_ACQUIRER_FOR_SPLITTRAFFIC,
  UPDATE_SPLITTRAFFIC_BY_ACQUIRER
} from "../../../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../../../config/axios";
import localDb from "../../../../../../localDb";
import CustomTextFields from "../../../../../common/CustomTextFields";
import TabButton from "../../../../../common/TabButton";
import Loading from "../../../../../common/Loading";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";

const SplitTrafficTab = ({ acquireService }) => {
  const [tabs, setTabs] = useState(0);
  const [loading, setLoading] = useState(false);
  const baseURL = useSelector((state) => state.config.api_url);
  const adminRole = localStorage.getItem("adminRole");
  const emailFromLocal = localDb.getVal("email");
  const [acceptFTDValue, setAcceptFTDValue] = useState(false);

  useEffect(() => {
    if (acquireService) {
      getSplitTraffic();
    }
  }, [tabs, acquireService]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const payload = {
        email: emailFromLocal,
        adminRole: adminRole,
        acquirerName:
          acquireService?.routingService?.[tabs]?.acquirerName || "",
        uniqueId: acquireService?.uniqueId,
        acceptFtd: acceptFTDValue,
      };
      const res = await call(
        {
          ...UPDATE_SPLITTRAFFIC_BY_ACQUIRER,
          url: baseURL + UPDATE_SPLITTRAFFIC_BY_ACQUIRER.url,
        },
        payload
      );
    } catch (err) {
      console.error(err.message);
      logoutAfterError(err);
    } finally {
      setLoading(false);
    }
  };

  const getSplitTraffic = async () => {
    setLoading(true);
    try {
      const payload = {
        email: emailFromLocal,
        adminRole: adminRole,
        acquirerName:
          acquireService?.routingService?.[tabs]?.acquirerName || "",
        uniqueId: acquireService?.uniqueId,
      };
      const response = await call(
        {
          ...GET_RULES_BY_ACQUIRER_FOR_SPLITTRAFFIC,
          url: baseURL + GET_RULES_BY_ACQUIRER_FOR_SPLITTRAFFIC.url,
        },
        payload
      );
      if (response.status === 200) {
        const data = response.data || initialValues;
        setAcceptFTDValue(data.acceptFtd || false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabSwitch = (index) => {
    setTabs(index);
  };

  const onClickFTD = (e) => {
    setAcceptFTDValue(e.target.checked);
  };

  if (!acquireService) {
    return <div className="w-full text-center">No Data</div>;
  }

  return (
    <div className="TabContainer">
      <div className="acquireServiceScroll">
        {acquireService.routingService.map((tab, index) => (
          <TabButton
            key={index}
            activeTab={tabs}
            handleTabSwitch={handleTabSwitch}
            tab={tab}
            index={index}
          />
        ))}
      </div>
      <div className="ml-3 flex pt-10">
        <Grid container spacing={2}>
          {acquireService.routingService.map((items, index) => (
            <div key={index}>
              {tabs === index && (
                  <div className="mx-2 my-2 flex py-2">
                    <div className="justify-start mr-16">Accept FTD</div>
                    <IOSSwitch
                      data-e2e="accept-ftd"
                      checked={acceptFTDValue}
                      onClick={isViewPermissionValid? null: onClickFTD}
                      className={`ml-20 ${isViewPermissionValid? "disabled": ""}`}
                    />
                  </div>
              )}
            </div>
          ))}
        </Grid>
      </div>
      <div className="mt-4">
        <button
          className={`rounded bg-blue-500 px-4 py-2 text-base font-bold text-white hover:bg-blue-700 ${isViewPermissionValid? "disabled": ""}`}
          onClick={isViewPermissionValid? null: handleSave}
          data-e2e="save-btn"
        >
          Save
        </button>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default SplitTrafficTab;
