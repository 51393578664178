import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
import { call } from "../../../../config/axios";
import Select from "react-select";
import {
  UPDATE_CARD_COUNTRY_FEES,
  CREATE_CARD_COUNTRY_FEES,
  GET_CARD_COUNTRY_FEES,
} from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import { useParams } from "react-router-dom";
import Loading from "../../../common/Loading";
import EditIcon from '@mui/icons-material/Edit';
import { getCountryCodesFromDb } from "../../global/helpers";
const closeImage = "/cryptonpay/crytoicons/close.png";
let countryCodes = []
const PaymentProviderCountries = () => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [data, setData] = useState([]);
  const [fixedFee,setFixedFee]=useState("");
  const [percentFee,setPercentFee] =useState("");
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState('');
  const [currentIndex, setCurrentIndex] = useState('')
  const [err, setErr] = useState("")
  const onClose = () => {
    setOpen(false);
  };

  const getPaymentProviderCountryData = async () => {
    setLoading(true);
    const res = await call(
      {
        ...GET_CARD_COUNTRY_FEES,
        url: base_url + GET_CARD_COUNTRY_FEES.url,
      },
      { paymentProviderId: id, email: email },
    );
    try {
      setLoading(false);
      setData(res && res.data && res.data.countryData);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleCountryAdd = async () => {
    if (
      percentFee === "" ||
      fixedFee === "" ||
      (selectedCountry === "" && !editData)
    ) {
      setErr("please enter all fields");
      return;
    }
    setLoading(true);
    const payload = {
      paymentProviderId: id,
      email: email,
      countryName: selectedCountry.label,
      depositByCardFixedFee: fixedFee,
      depositByCardPercentFee: percentFee,
    };

    const res = await call(
      {
        ...CREATE_CARD_COUNTRY_FEES,
        url: base_url + CREATE_CARD_COUNTRY_FEES.url,
      },
      payload,
    );
    try {
      setLoading(false);
      if (res.status == 200) {
        setData((data) => [...data, res.data.createdObject]);
        resetDefaultState();
      }
    } catch (err) {
        setData("");
        resetDefaultState();
        setLoading(false);
        console.error(err.message);
      }
  };

  const editChanges = async () => {
    if (percentFee === "" || fixedFee === "") {
      setErr("please enter all fields");
      return;
    }
    setLoading(true);
    const payload = {
      paymentProviderId: id,
      email: email,
      countryName: selectedCountry,
      depositByCardFixedFee: fixedFee,
      depositByCardPercentFee: percentFee,
    };

    const testArray = [...data];

    const res = await call(
      { 
        ...UPDATE_CARD_COUNTRY_FEES,
        url: base_url + UPDATE_CARD_COUNTRY_FEES.url,
      },
      payload,
    );
    try {
      setLoading(false);
      if (res.status == 200) {
        testArray.splice(currentIndex, 1, res.data.updatedObject);
        setData(testArray);
        resetDefaultState();
      }
    } catch (err) {
        setData("");
        resetDefaultState();
        setLoading(false);
        console.error(err.message);
      }
  };
  const resetDefaultState = () => {
    setEditData("");
    setFixedFee("");
    setPercentFee("");
    setSelectedCountry("");
    onClose();
  };
  const saveOrEdit = () => {
    editData?editChanges():handleCountryAdd()
  };

  useEffect(() => {
    async function fetchData() {
    await getPaymentProviderCountryData();
      if(data){
        countryCodes = await getCountryCodesFromDb(base_url)
      }
    }
    fetchData()
  }, []);

  return (
    <div>
      <div className="payment-provider-add-btn">
        <button
          className="theme-table-action add-country-btn"
          onClick={() => {
            setEditData("")
            setOpen(true)
          }}
        >
          Add Country
        </button>
      </div>
      <div
        className="TransactionTableMain transaction_table_link table-full-width table_padding transaction-h"
        style={{ flex: 1, marginTop: "10.5px" }}
      >
        <div className="theme-table">
          <Table responsive style={{ maxHeight: "400px" }}>
            <tbody>
              <tr>
                <th className="theme-table-hover">
                  <p className="theme-table-text">COUNTRY NAME</p>
                </th>

                <th className="theme-table-hover">
                  <p className="theme-table-text">Deposit By Card Fixed Fee</p>
                </th>

                <th className="theme-table-hover">
                  <p className="theme-table-text">Deposit By Card % Fee</p>
                </th>
                <th className="theme-table-hover">
                  <p className="theme-table-text"></p>
                </th>

              </tr>
              {data &&
                data.map((country, index) => {

                  return (
                    <React.Fragment key={index}>
                      <tr className="theme-table-row theme-table-hover">
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {country.countryName}
                            </p>
                          </div>
                        </td>
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {country.depositByCardFixedFee}
                            </p>
                          </div>
                        </td>


                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {country.depositByCardPercentFee}
                            </p>
                          </div>
                        </td>



                        <td className="theme-table-row">
                          <EditIcon
                            fontSize="large"
                            color="action"
                            className="cursor-pointer pr-3"
                            
                            onClick={() => {
                              setCurrentIndex(index)
                              setSelectedCountry(country.countryName)
                              setFixedFee(country.depositByCardFixedFee)
                              setPercentFee(country.depositByCardPercentFee)
                              setEditData(country)
                              setOpen(true)
                            }}
                        />
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
        disableEnforceFocus={true}
        onClose={onClose}
      >
        <div className="filter_model_container">
          <div className="filter_model">
            <div className="filter_model_heading_wrap">
              <div className="filter_heading">{editData ? "EDIT" : "ADD"} SPREAD RATE</div>
            </div>
            <div className="filter_close_button" onClick={onClose}>
              <img
                src={`${image_base_url}${closeImage}`}
                alt="closeImage"
                data-e2e="close-icon"
              />
            </div>
          </div>
          <div className="form-group myAccoutOptModal_form_group transferModalGroup mt-40">
            <div className="FilterModalInputLabel f-bold">Countries</div>
            <Select
              placeholder={editData?selectedCountry:"Select..."}
              isDisabled={editData}
              options={countryCodes.filter(
                (obj) => data && !data.some((o) => o.countryCode === obj.code)
              ).map(({ name, code }) => ({
                label: name,
                value: code,
              }))}
              onChange={(event) => {
                setSelectedCountry(event);
                setErr("")
              }}
            />
          </div>
          <div className="FilterModalInputGroup">
            <div className="FilterModalInputLabel">Deposit By Card Fixed Fee</div>
            <div className="MinMaxCryptoRangeWrap">
              <input
                className="rangeInput"
                type="number"
                placeholder="Enter Fixed Fee"
                name="depositSpread"
                defaultValue={editData?fixedFee || editData.depositByCardFixedFee:""}
                onChange={(e) => {
                  setFixedFee(e.target.value)
                  setErr("")
                }}
                />
            </div>
          </div>

          <div className="FilterModalInputGroup">
            <div className="FilterModalInputLabel">Deposit By Card % Fee</div>
            <div className="MinMaxCryptoRangeWrap">
              <input
                className="rangeInput"
                type="number"
                placeholder="Enter % Fee"
                name="depositSpread"
                defaultValue={editData?percentFee || editData.depositByCardPercentFee:""}
                onChange={(e) => {
                  setPercentFee(e.target.value)
                  setErr("")
                }}
              />
            </div>
          </div>
          
          {err && err !=="" && (
            <div className="clr-red">Please fill the mandatory fields</div>
          )}
          <div className="FilterModalSave">
            <button onClick={saveOrEdit}>
              SAVE
            </button>
          </div>
        </div>
      </Dialog>
      {loading && <Loading />}
    </div>
  );
};

export default PaymentProviderCountries;
