import React, {  useState } from 'react';
import { Modal } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import {  useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { CREATE_VIP_LINK } from '../../../../config/endpoints';
import { call, logoutAfterError } from '../../../../config/axios';
import { ExportToCsv } from 'export-to-csv-fix-source-map';

const UploadCSV = (props) => {
  const api_url = useSelector((state) => state.config.api_url);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [fileName, setFileName] = useState('Select file ...');
  const [csvFile, setCsvFile] = useState();

  const disabled = merchantId !== '' && fileName !== 'Select file ...' && error ==='';
    const processCSV = (str, delim=',') => {
      const headers = str.slice(0,str.indexOf('\n')).split(delim);  
      const rows = str.slice(str.indexOf('\n')+1).split('\n');
      const rowList = rows.reduce((acc,curr) =>{
        if(curr != ''){
          acc.push(curr.replace("\r",""));
        }
        return acc;
      },[]);
        const merchantUserList = rowList.map( row => {
            const values = row.split(delim);
            const eachObject = headers.reduce((obj, header, i) => {
                obj[header.replace("\r","")] = values[i];
                return obj;
            }, {})
            return eachObject;
        })
      createVipLink(merchantUserList);
  }

  const submit = () => {
    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function(e) {
        const text = e.target.result;
        processCSV(text);
    }
    reader.readAsText(file); 
  }

  const createVipLink= async (merchantUserList) =>{
    if(merchantUserList.length >0 && merchantId!=''){
      let id= merchantId.trim();
      setLoading(true);
        await call(
          {
            ...CREATE_VIP_LINK,
            url: api_url + CREATE_VIP_LINK.url,
          },
          {merchantUserList,id,email:localStorage.getItem("email")}
        )
          .then((res) => {
            setLoading(false); 
            if (res.status === 200) {
              props.onCloseClickHandler();
              setCsvFile();  
              setMerchantId('');
              setFileName('Select file ...');
              csvExporter.generateCsv(res.data);            
            }
          })
          .catch((err) => {
            setLoading(false);
            setError(err.response.data.msg);              
            console.log(err.message, 'err');
            logoutAfterError(err)
          });
    }else{
      setError("Please Enter data.")
    }
  }

  const handleChange =(e) =>{
    setMerchantId(e.target.value);
    setError('');
  }

  const handleCloseModal =() =>{
    setMerchantId('');
    setCsvFile([]);
    setFileName('Select file ...');
    setError('');
    props.onCloseClickHandler();
  }

  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title:"vipMerchantLink",
    filename:"vipMerchantLink",      
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    headers: ['Column 1', 'Column 2', 'col 3', 'col 4', 'col 5'],
  };

  const csvExporter = new ExportToCsv(options);

    return (<div className="merchant_dailog_screen">
    <Dialog
      open={props.showModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'lg'}
      disableEnforceFocus={true}
    >
      <div className='upload__csv'>
        <Modal style={{width:'500px', left:'30%', top:'27%'}} show={true} className="ClearAlertModal deleteAlertModal ClearAlertModals"   
          onHide={() => {props.onCloseClickHandler()}}
          >   
           <div className='d-flex justify-content-between pl-4 pr-4 pt-3 pb-4'>
              <div style={{fontSize:'22px'}} className='font-weight-bold'>Upload CSV</div>
              <div className='cursor-pointer' data-e2e="close" onClick={handleCloseModal }><CloseIcon /></div>
            </div>   
          <Modal.Body className="ClearAlertModalBody" style={{minHeight:"0px"}}>
          <div>
            <div className='upload__merchantId'>
              <div className='merchant__lable'>Merchant I'd </div>
               <input type="text" data-e2e="enterMerchantId" placeholder='Enter Merchant Id' name='merchantId' defaultValue={merchantId} onChange={handleChange} /> 
            </div>
            
            <form id='csv-form'>
            <input
            className='select__file'
            style={{fontSize:'20px'}}
                type='file'
                accept='.csv'
                id='csvFile'
                onChange={(e) => {
                    setError('');
                    setFileName(e.target.files[0].name);
                    setCsvFile(e.target.files[0]);                    
                }}
            >
            </input>
            <label className='input__lable' for= 'csvFile' data-e2e="csvFile">
              <div data-e2e="fileUpload">{fileName}</div>
              <div><i className="fa fa-upload" aria-hidden="true"></i></div>
            </label>
            {error !='' && <div className='uploadCSV_error'>{error}</div>}
            <button data-e2e={!disabled ? "opacity_sumbit upload_submit": 'upload_submit'} disabled={!disabled} className={!disabled ? "opacity_sumbit upload_submit": 'upload_submit'}
                onClick={(e) => {
                    e.preventDefault()
                    if(csvFile){
                      submit();
                    }
                }}>
                Submit
            </button>
         </form>

          </div>        
          </Modal.Body>
        </Modal>
      </div>
    </Dialog>
  </div>)
};

export default UploadCSV;