import React, { useState } from "react";
import { useSelector } from "react-redux";
import ResetModal from "../CommonPages/ResetModal";
const closeImage = "/cryptonpay/crytoicons/close.png";
const selectedCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_filled.png";
const emptyCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_empty.png";

function DepositHashFilters(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [showNetworkDropdown, setShowNetworkDropdown] = useState(false);
  const [showSymbolDropdown, setShowSymbolDropdown] = useState(false);
  const [openPopup, resetOpenPopup] = useState(false);
  const closeReset = () => resetOpenPopup(false);

  const toggleSymbolSelection = (symbol) => {
    if (props?.selectedSymbolList?.includes(symbol)) {
      const newList = props?.selectedSymbolList?.filter(
        (symbolObj) => symbolObj !== symbol,
      );
      props.setSelectedSymbolList(newList);
    } else {
      const newList = [...props?.selectedSymbolList];
      newList.push(symbol);
      props?.setSelectedSymbolList(newList);
    }
  };
  const toggleNetworkSelection = (network) => {
    if (props?.selectedNetworkList?.includes(network)) {
      const newList = props?.selectedNetworkList?.filter(
        (networkObj) => networkObj !== network,
      );
      props?.setSelectedNetworkList(newList);
    } else {
      const newList = [...props?.selectedNetworkList];
      newList.push(network);
      props?.setSelectedNetworkList(newList);
    }
  };

  const handleSaveDetails = () => {
    props?.handleDepositHashFilters();
    props?.handleSave();
  };

  const sortDropDownData = (data, field) => {
    if (field) {
      const filteredData = data?.filter(
        (item) => item[field] !== undefined && item[field] !== null,
      );
      return filteredData.sort((a, b) => {
        if (!a[field] && !b[field]) {
          return 0;
        }
        if (!a[field]) {
          return -1;
        }
        if (!b[field]) {
          return 1;
        }
        return a[field]?.localeCompare(b[field], { sensitivity: "accent" });
      });
    } else {
      return data?.sort((a, b) =>
        a?.localeCompare(b, { sensitivity: "accent" }),
      );
    }
  };

  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <ResetModal
          open={openPopup}
          close={closeReset}
          reset={props.resetFilters}
        />
        <div
          className="filter_clear"
          data-e2e="filter-clear-btn"
          onClick={() => {
            resetOpenPopup(true);
            setShowSymbolDropdown(false);
            setShowNetworkDropdown(false);
          }}
        >
          Clear
        </div>
        <div className="filter_heading">Filters</div>
        <div
          className="filter_close_button"
          data-e2e="filter_close_button"
          onClick={() => {
            props.handleDepositHashFilters();
          }}
        >
          <img
            src={`${image_base_url}${closeImage}`}
            alt="closeImage"
            data-e2e="close-icon"
          />
        </div>
      </div>
      <div className="FilterModalInputGroup">
        <div className="FilterModalInputLabel">Crypto</div>
        <div
          className="FilterModalInputDropdown"
          onClick={() => {
            setShowSymbolDropdown(true);
            setShowNetworkDropdown(false);
          }}
        >
          <div className="FilterModalInputText">
            {props?.selectedSymbolList?.length === props?.symbolList?.length ||
            props?.selectedSymbolList?.length === 0
              ? "All"
              : `${props?.selectedSymbolList?.length} Selected`}
          </div>
          <i
            className="fa fa-chevron-down FilterModalInputDropIcon"
            data-e2e="symbol-drop-down"
          />
        </div>
        {showSymbolDropdown && (
          <div className="FilterModalCurrencyDropdownlist">
            <div
              className="FM_currencyDrop_All"
              onClick={() => {
                setShowSymbolDropdown(false);
              }}
            >
              <span className="FM_currencyDrop_AllSelector">
                {props?.selectedSymbolList?.length ===
                  props?.symbolList?.length ||
                props?.selectedSymbolList?.length === 0
                  ? "All"
                  : `${props?.selectedSymbolList?.length} Selected`}
              </span>
              <span className="FilterModalInputDropUpIconWrap">
                <i
                  className="fa fa-chevron-up FilterModalInputDropUpIcon"
                  data-e2e="symbol-up"
                />
              </span>
            </div>
            {sortDropDownData(props?.symbolList)?.map((symbol, index) => {
              return (
                <div
                  key={index}
                  className="currencyListItemWrap"
                  onClick={() => {
                    toggleSymbolSelection(symbol);
                  }}
                >
                  <div className="currencyListIconNameWrap">
                    <div className="currencyList_Name" data-e2e={symbol}>
                      {symbol}
                    </div>
                  </div>
                  <img
                    src={`${image_base_url}${
                      props?.selectedSymbolList.includes(symbol)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                    }`}
                    alt="checkbox"
                    className="currencyListCheckBox"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="FilterModalInputGroup">
        <div className="FilterModalInputLabel">Network</div>
        <div
          className="FilterModalInputDropdown"
          onClick={() => {
            setShowSymbolDropdown(false);
            setShowNetworkDropdown(true);
          }}
        >
          <div className="FilterModalInputText">
            {props?.selectedNetworkList?.length ===
              props?.networkList?.length ||
            props?.selectedNetworkList?.length === 0
              ? "All"
              : `${props?.selectedNetworkList?.length} Selected`}
          </div>
          <i
            className="fa fa-chevron-down FilterModalInputDropIcon"
            data-e2e="network-drop-down"
          />
        </div>
        {showNetworkDropdown && (
          <div className="FilterModalCurrencyDropdownlist">
            <div
              className="FM_currencyDrop_All"
              onClick={() => {
                setShowNetworkDropdown(false);
              }}
            >
              <span className="FM_currencyDrop_AllSelector">
                {props?.selectedNetworkList?.length ===
                  props?.networkList?.length ||
                props?.selectedNetworkList?.length === 0
                  ? "All"
                  : `${props?.selectedNetworkList?.length} Selected`}
              </span>
              <span className="FilterModalInputDropUpIconWrap">
                <i
                  className="fa fa-chevron-up FilterModalInputDropUpIcon"
                  data-e2e="network-up"
                />
              </span>
            </div>
            {sortDropDownData(props?.networkList).map((network, index) => {
              return (
                <div
                  key={index}
                  className="currencyListItemWrap"
                  data-e2e="currencyListItemWrap"
                  onClick={() => {
                    toggleNetworkSelection(network);
                  }}
                >
                  <div className="currencyListIconNameWrap">
                    <div
                      className="currencyList_Name"
                      data-e2e={`${network}.toLowerCase().replaceAll(' ', '-')`}
                    >
                      {network}
                    </div>
                  </div>
                  <img
                    src={`${image_base_url}${
                      props?.selectedNetworkList?.includes(network)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                    }`}
                    alt="checkbox"
                    className="currencyListCheckBox"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div
        className="FilterModalSave"
        data-e2e="save-btn"
        onClick={() => {
          handleSaveDetails();
        }}
      >
        SAVE
      </div>
    </div>
  );
}

export default DepositHashFilters;
