import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import {
  GET_SINGLE_TRANSFER_WALLET,
  GET_TRANSFER_WALLETS,
  SAVE_TRANSACTION_FROM_ADMIN,
  UPDATE_WALLET_STATUS,
  VIEW_SINGLE_WALLET,
  GET_SINGLE_CRYPTO_EXCHANGE_RATE,
} from '../../../../config/endpoints';
import Dialog from '@mui/material/Dialog';
import { Modal } from 'react-bootstrap';
import { call, logoutAfterError } from '../../../../config/axios';
import Loading from '../../../common/Loading';
import localDb from '../../../../localDb';
import { SortByIcon } from '../../../common/CommonUtils';
const email = localDb.getVal('email');
const closeImage = '/cryptonpay/crytoicons/close.png';
const successIcon = '/cryptonpay/crytoicons/success_changes.png';
const deleteIcon = '/cryptonpay/crytoicons/deleteicon.png';

const UserWalletTable = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="TransactionTableMain">
      {props && props.data && props.data.length > 0 ? (
        <Table responsive style={{ border: 'none' }}>
          <tbody className="TransactionTable_BODY">
            <tr className="TransactionTable_BODY_Header">
              <th
                className="TransactionTable_heading"
                width="10%"
                onClick={() => {
                  props.changeOrder('walletType');
                }}
                data-e2e="walletType"
              >
                WALLET TYPE{' '}
                {props.sortBy === 'walletType' && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="walletType" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="10%"
                onClick={() => {
                  props.changeOrder('userId');
                }}
                data-e2e="userId"
              >
                USER{' '}
                {props.sortBy === 'userId' && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="userId" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="10%"
                onClick={() => {
                  props.changeOrder('blockchain');
                }}
                data-e2e="blockchain"
              >
                BLOCKCHAIN{' '}
                {props.sortBy === 'blockchain' && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="blockchain" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="16%"
                onClick={() => {
                  props.changeOrder('address.key');
                }}
                data-e2e="address.key"
              >
                WALLET ADDRESS{' '}
                {props.sortBy === 'address.key' && (
                  <SortByIcon data-e2e="address.key" orderBy={props.orderBy} />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="10%"
                onClick={() => {
                  props.changeOrder('address.seedIndex');
                }}
                data-e2e="address.seedIndex"
              >
                Seed Index{' '}
                {props.sortBy === 'address.seedIndex' && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="address.seedIndex"
                  />
                )}
              </th>

              <th
                className="TransactionTable_heading"
                width="12%"
                onClick={() => {
                  props.changeOrder('creationDate');
                }}
                data-e2e="creationDate"
              >
                TIMESTAMP{' '}
                {props.sortBy === 'creationDate' && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="creationDate"
                  />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="8%"
                onClick={() => {
                  props.changeOrder('balance.balanceAmount');
                }}
                data-e2e="balanceAmount"
              >
                Balance{' '}
                {/* {props.sortBy === 'balanceAmount' && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="balanceAmount"
                  />
                )} */}
              </th>
              <th
                className="TransactionTable_heading"
                width="10%"
                onClick={() => {
                  props.changeOrder('status[0].code');
                }}
                data-e2e="status[0].code"
              >
                Status{' '}
                {props.sortBy === 'status[0].code' && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="status[0].code"
                  />
                )}
              </th>
              <th className="TransactionTable_heading" width="10%"></th>
            </tr>
            {props.data.map((TData, i) => {
              return (
                <SingleTransactionTableRow
                  data={TData}
                  key={i}
                  loading={loading}
                  setLoading={setLoading}
                  getWallets={() => {
                    props.getWallets();
                  }}
                />
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="No_Transactions_Found">No Data Found</div>
      )}
      {props.showMoreButton ? (
        <div className="Show_more_wrap">
          <div
            className="Show_more"
            onClick={() => {
              props.handleShowMore();
            }}
          >
            Show More
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UserWalletTable;

const SingleTransactionTableRow = (props) => {
  let { data } = props;
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const api_url = useSelector((state) => state.config.api_url);
  const [selectedTransferWallet, setSelectedTransferWallet] =
    useState('Select');
  const [walletIdForTransferFunds, setWalletIdForTransferFunds] =
    useState(null);
  const [showModal, toggleShowModal] = useState(false);
  const [alertModal, toggleAlertModal] = useState(false);
  const [loadingExchange, setLodingExchange] = useState(false);
  const [showSuccess, toggleShowSuccess] = useState(false);
  const [selectedWalletForTransfer, setSelectedWallet] = useState(null);
  const [selectedToken, setSelectedToken] = useState("Select");
  const [selectedTokenDetails, setSelectedTokenDetails] = useState(null);
  const [cryptoAmount, setCryptoAmount] = useState(0);
  const [cryptoEquUSDAmount, setCryptoEquUSDAmount] = useState(0);
  const [showError, toggleShowError] = useState(false);
  const [limitError, setLimitError] = useState("");
  const [transferWallets, setTransferWallets] = useState([]);
  const [singleTransferWallets, setSingleTransferWallets] = useState(null);
  const view_transaction_url = useSelector((state) => state.config.api_url);

  const getStatusComponent = (status) => {
    switch (status) {      
      case 'DepositMonitor:DepositPendingManualApproval':
        return <div className="Mstatus_witheld" data-e2e="withHeld">WITHELD</div>;
      case 'DepositMonitor:MonitoringMemPool':
      case 'DepositFlow:AwaitingDeposit':
      case 'DepositFlow:Launched':
      case 'CreatePayment':
      case 'DepositMonitor:WalletStatusAvailable':
      case 'DepositMonitor:WalletStatusLocked':
      case 'DepositMonitor:UpdateWalletStatus':
      case 'DepositMonitor:NotfiyMerchant':
      case 'DepositMonitor:DepositManualApprove':
      case 'DepositMonitor:KYTCheckFail':
      case 'DepositMonitor:KYTCheckPass':
      case 'DepositMonitor:KYTCheck':
      case 'DepositMonitor:DepositConfirmed':
      case 'DepositMonitor:GasTooLow':
      case 'DepositMonitor:ExcessTransactionExecuted':
      case 'DepositMonitor:TransactionExecuted':
      case 'DepositMonitor:TransactionExecuting':
      case 'DepositMonitor:DepositReceivedToMemPool':
      case 'DepositMonitor:TransactionComplete':
        return <div className="Mstatus_pending" data-e2e="pending">PENDING</div>;
      case 'DepositMonitor:DepositManuallyCompleted':
      case 'DepositMonitor:DepositCompleted':
      case 'MerchantWallet:BalanceUpdated':
      case 'WalletManagementService:WithdrawalCompleted':
      case 'WalletManagementService:TransferCompleted':
      case "DepositFlowViaWallet:DepositCompleted":
        return <div className="Mstatus_active" data-e2e="success">SUCCESS</div>;
      case 'Active':
        return <div className="Mstatus_active" data-e2e="active">ACTIVE</div>;
      case 'Available':
        return (
          <div className="Mstatus_active" style={{ width: '120px' }} data-e2e="available">
            AVAILABLE
          </div>
        );
      case 'Inactive':
        return <div className="Mstatus_inactive" data-e2e="inActive">INACTIVE</div>;
      case 'Pending':
        return <div className="Mstatus_pending" data-e2e="pending">PENDING</div>;
      case 'Inactive':
        return <div className="Mstatus_active" data-e2e="success">SUCCESS</div>;
      case 'Locked':
        return (
          <div className="Mstatus_witheld" style={{ width: '120px' }} data-e2e="locked">
            LOCKED
          </div>
        );
      case 'Transfer Requested':
        return <div className="Mstatus_witheld" data-e2e="TRANSFER RQUESTED">TRANSFER RQUESTED</div>;
      case 'In Use':
        return (
          <div className="Mstatus_inactive" style={{ width: '120px' }} data-e2e="IN USE">
            IN USE
          </div>
        );
      case 'Failed':
        return (
          <div className="Mstatus_inactive" style={{ width: '120px' }} data-e2e="Failed">
            FAILED
          </div>
        );
      case 'Inuse':
        return (
          <div className="Tstatus_failed" style={{ width: '120px' }} data-e2e="inUse">
            INUSE
          </div>
        );
      default:
        return (
          <div className="Mstatus_inactive" style={{ width: '120px' }} data-e2e="inUse">
            IN USE
          </div>
        );
    }
  };

  const getTransferWallets = async (blockchain, network) => {
    props.setLoading(true);
    await call(
      {
        ...GET_TRANSFER_WALLETS,
        url: api_url + GET_TRANSFER_WALLETS.url,
      },
      { email: localStorage.getItem('email'), blockchain, network}
    )
      .then((res) => {
        props.setLoading(false);
        if (res.status === 200) {
          setTransferWallets(res.data.transferWallets);
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };

  const createTransaction = async (data) => {
    let payload = {
      email: localStorage.getItem('email'),
      type: 'Transfer',
      network: data.network,
      createdBy: localStorage.getItem('email'),
      fromWalletId: data._id,
      fromWalletaddress: data.address.key,
      toWalletId: singleTransferWallets._id,
      toWalletAddress: singleTransferWallets.address,
      toWalletName: singleTransferWallets.name,
      cryptoId: selectedToken,
      cryptoAmount: cryptoAmount, 
      usdAmount: cryptoEquUSDAmount,
      status: 'WalletTransferFlow:TransferRequested',
      message: "Transfer created by: " + localStorage.getItem('email'),
    };
    props.setLoading(true);
    await call(
      {
        ...SAVE_TRANSACTION_FROM_ADMIN,
        url: api_url + SAVE_TRANSACTION_FROM_ADMIN.url,
      },
      payload
    )
      .then((res) => {
        props.setLoading(false);
        if (res.status === 200) {
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };

  const transferFunds = async () => {
    if (walletIdForTransferFunds && walletIdForTransferFunds !== '') {
      props.setLoading(true);

      await call(
        {
          ...UPDATE_WALLET_STATUS,
          url: api_url + UPDATE_WALLET_STATUS.url,
        },
        {
          email: localStorage.getItem('email'),
          status: 'Transfer Requested',
          message: `${localStorage.getItem('email')}`,
          walletId: walletIdForTransferFunds,
        }
      )
        .then((res) => {
          props.setLoading(false);
          if (res.status === 200) {
            toggleShowSuccess(true);
            setTimeout(()=>{
              toggleShowSuccess(false);
            }, 2000)
            props.getWallets();
          }
        })
        .catch((err) => {
          toggleShowError(true);
          setTimeout(()=>{
            toggleShowError(false);
          }, 2000)
          props.setLoading(false);
          console.log(err.message, 'err');
          logoutAfterError(err)
        });
    } else {
      toggleShowError(true);
      setTimeout(()=>{
        toggleShowError(false);
      }, 2000)
    }
  };

  const getSingleTransferWallet = async (id) => {
    if (id === "Select") {
      setSelectedTransferWallet(id)
      setSingleTransferWallets(null)
    } else {
      await call(
        {
          ...GET_SINGLE_TRANSFER_WALLET,
          url: api_url + GET_SINGLE_TRANSFER_WALLET.url,
        },
        {
          email: localStorage.getItem('email'),
          id: id,
        }
      )
        .then((res) => {
          props.setLoading(false);
          if (res.status === 200) {
            setSingleTransferWallets(res.data);
            setSelectedTransferWallet(res.data._id);
          }
        })
        .catch((err) => {
          props.setLoading(false);
          console.log(err.message, 'err');
          logoutAfterError(err)
        });
    }
  };

  const viewSingleWalletDetails = async (id) => {
    await call(
      {
        ...VIEW_SINGLE_WALLET,
        url: view_transaction_url + VIEW_SINGLE_WALLET.url,
      },
      { email: email, walletId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          setSelectedWallet(res.data.wallets);
          props.setLoading(false);
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };
  const getSingleCryptoExchangedRate = (amount, cryptoCurrency, fiatCurrency) => {
    return new Promise(async (resolve, reject) => {
      const baseUrl = window.location.hostname.includes("localhost") ? api_url : view_transaction_url
      setLodingExchange(true)
      await call(
        {
          ...GET_SINGLE_CRYPTO_EXCHANGE_RATE,
          url: baseUrl + GET_SINGLE_CRYPTO_EXCHANGE_RATE.url,
        },
        { email: email, amount, cryptoCurrency, fiatCurrency }
      )
        .then((res) => {
          if (res.status === 200) {
            props.setLoading(false);
            setLodingExchange(false)
            resolve(res.data);
          }
        })
        .catch((err) => {
          props.setLoading(false);
          setLodingExchange(false)
          console.log(err.message, 'err');
          logoutAfterError(err)
        });
    })
  };

  useEffect(() => {
    if (selectedTokenDetails) {
      onCryptoAmountChange(selectedTokenDetails.balanceAmount);
    }
  }, [selectedTokenDetails])

  const handleTransfer = async (id, blockchain, network) => {
    await getTransferWallets(blockchain, network);
    await viewSingleWalletDetails(id);
    toggleShowModal(true);
    setWalletIdForTransferFunds(id);
  };

  const validateAmount = (value) => {
    if (value > selectedTokenDetails.balanceAmount) {
      setLimitError("Amount must be less than equal to " + selectedTokenDetails.balanceAmount + " " + selectedToken);
    } else if (value <= 0) {
      setLimitError("Amount must be greater than zero");
    } else {
      setLimitError("");
    }
  }

  const onCryptoAmountChange = async (value) => {
    if (value) {
      setCryptoAmount(value);
    } else if (value === 0) {
      setCryptoAmount(0);
    } else {
      setCryptoAmount(null);
    }
    const result = await getSingleCryptoExchangedRate(1, selectedToken, "USD");
    if (value && result && result.cryptoAmounts) {
      const usdEquAmt = parseFloat(value / result.cryptoAmounts[selectedToken]).toFixed(8)
      validateAmount(value)
      setCryptoEquUSDAmount(usdEquAmt);
    } else {
      validateAmount(0)
      setCryptoEquUSDAmount(0);
    }
  };
  const onCryptoEquUSDAmountChange = async (value) => {
    if (value) {
      setCryptoEquUSDAmount(value);
    } else if (value === 0) {
      setCryptoEquUSDAmount(0);
    } else {
      setCryptoEquUSDAmount(undefined);
    }
    const result = await getSingleCryptoExchangedRate(value, selectedToken, "USD");
    if (value && result && result.cryptoAmounts) {
      setCryptoAmount(result.cryptoAmounts[selectedToken])
      validateAmount(result.cryptoAmounts[selectedToken])
    } else {
      setCryptoAmount(0);
      validateAmount(0)
    }
  };
  return (
    <tr className="CP_TableRow">
      <td className="transactionsTable_item" style={{textTransform:'capitalize'}}>
        {(data.walletType && data.walletType) || 'N/A'}
      </td>
      <td className="transactionsTable_item">
        {(data.userId && `${data.userId.firstName} ${data.userId.lastName}`) || 'N/A'}
      </td>
      <td className="transactionsTable_item">
        {(data.blockchain && data.blockchain) || 'N/A'}
      </td>
      <td className="transactionsTable_item wallet_address_link">
        <Tooltip title={data.address.key && data.address.key} placement="top" >
          <Link to={`/b2c-user-wallet/${data._id}`}>
            {data.address.key
              ? data.address.key.substring(0, 5) +
              '.....' +
              data.address.key.substring(
                data?.address?.key?.length - 6,
                data?.address?.key?.length
              )
              : 'N/A'}
          </Link>
        </Tooltip>
      </td>
      <td className="transactionsTable_item">
        {(data.address && data.address.seedIndex) || '0'}
      </td>
      <td className="transactionsTable_item">
        {data.creationDate 
          ? moment(data.creationDate).format('DD/MM/YYYY - HH:mm')
          : 'N/A'}
      </td>
      <td className="transactionsTable_item">
        {data?.balance?.length > 0 ? data?.balance[0]?.balanceAmount:'N/A'}
        
      </td>
      <td className="transactionsTable_item">
        {data.status && data.status.code
          ? getStatusComponent(data.status.code)
          : 'N/A'}
      </td>
      <td className="transactionsTable_item">
        <div>
          {data.status && data.status.code === 'Locked' ? (
            <div
              className="transactionsTable_transferButton"
              onClick={() => handleTransfer(data._id, data.walletType, data.network)}
              data-e2e="TRANSFER"
            >
              TRANSFER
            </div>
          ) : data.status && data.status.code === 'In Use' ? <div>ID345x6…021</div> : null}
          {showModal ? (
            <div className="merchant_dailog_screen">
              <div>
                <Modal
                  show={true}
                  className="ClearAlertModal transferModal transferFundsModal"
                  onHide={() => {
                    toggleShowModal(false);
                  }}
                >
                  <Modal.Body className="ClearAlertModalBody transferModalBody">
                    <div className="transferModalTitleWrap">
                      <div className="transferModalTitle" data-e2e="TRANSFER FUNDS">Transfer Funds</div>
                      <img
                        className="closeIcon"
                        alt="closeIcon"
                        src={`${image_base_url}${closeImage}`}
                        onClick={() => {
                          toggleShowModal(false);
                        }}
                      />
                    </div>
                    <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                      <label
                        className="myAccoutOptModalLable"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Please select an approved destination wallet
                      </label>
                      <select
                        value={selectedTransferWallet}
                        className="form-control inputdropdown"
                        id="exampleFormControlSelect1"
                        name="selectedTransferWallet"
                        onChange={(e) => {
                          e.preventDefault();
                          getSingleTransferWallet(e.target.value);
                        }}
                      >
                        <option data-e2e="Choose Transfer Wallet" value={'Select'}>
                          Choose Transfer Wallet
                        </option>
                        {transferWallets &&
                          transferWallets.length > 0 &&
                          transferWallets.map((data, index) => {
                            return (
                              <option key={index} value={data._id} data-e2e={data.name}>
                                {data.name}
                              </option>
                            );
                          })}
                      </select>
                      <div className="dropdownIcon">
                        <i className="fa fa-chevron-down" />
                      </div>
                    </div>
                    {singleTransferWallets && <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                      <label
                        className="myAccoutOptModalLable"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Destination Wallet Address:
                      </label>
                      <div className="WalletAddress">{singleTransferWallets.address}</div>
                    </div>}
                    <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                      <label
                        className="myAccoutOptModalLable"
                        htmlFor="exampleFormControlSelect1"
                      >
                        CryptoCurrency
                      </label>
                      <select
                        value={selectedToken}
                        className="form-control inputdropdown"
                        id="exampleFormControlSelect1"
                        name="selectedTransferWallet"
                        data-e2e="selectedTransferWallet"
                        onChange={(e) => {
                          e.preventDefault();
                          setSelectedToken(e.target.value);
                          if (e.target.value !== "Select") {
                            let cryptoObj = {}
                            selectedWalletForTransfer.balance.map((data) => {
                              if (e.target.value === data.cryptoId) {
                                cryptoObj = data;
                              }
                            })
                            setSelectedTokenDetails(cryptoObj);
                            setLimitError("")
                          } else {
                            setCryptoAmount(0)
                            setCryptoEquUSDAmount(0)
                          }
                        }}
                      >
                        <option value={'Select'}>
                          Select CryptoCurrency
                        </option>
                        {selectedWalletForTransfer && selectedWalletForTransfer.balance &&
                          selectedWalletForTransfer?.balance?.length > 0 &&
                          selectedWalletForTransfer.balance.map((data, index) => {
                            return (
                              <option key={index} value={data.cryptoId}>
                                {data.cryptoId}
                              </option>
                            );
                          })}
                      </select>
                      <div className="dropdownIcon">
                        <i className="fa fa-chevron-down" />
                      </div>
                    </div>
                    {selectedToken && selectedToken !== "Select" && <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                      <label
                        className="myAccoutOptModalLable"
                        htmlFor="exampleFormControlSelect1"
                      >
                        <span>Amount</span>
                        {loadingExchange ? <span style={{ fontSize: "12px", fontWeight: "500", marginLeft: "20px" }}>Loading Exchange Value...</span> : null}
                      </label>
                      <div className="amountExchageWrap">
                        <input
                          type="number"
                          value={cryptoEquUSDAmount >= 0 ? cryptoAmount : ""}
                          className="form-control inputdropdown"
                          id="cryptoAmount"
                          placeholder="amount in crypto"
                          name="cryptoAmount"
                          onChange={(e) => {
                            onCryptoAmountChange(e.target.value);
                          }}
                        />
                        <div className="exchangeIcon"><i className="fa fa-exchange"></i></div>
                        <input
                          type="number"
                          value={cryptoAmount >= 0 ? cryptoEquUSDAmount : null}
                          className="form-control inputdropdown"
                          id="cryptoEquUSDAmount"
                          placeholder="amount in USD"
                          name="cryptoEquUSDAmount"
                          onChange={(e) => {
                            onCryptoEquUSDAmountChange(e.target.value);
                          }}
                        />
                      </div>
                    </div>}

                    {limitError !== "" && <div className="limitErrorText">{limitError}</div>}
                    <div
                      className={`ClearAlertModalClearBtn transferFundsBtn ${selectedTransferWallet &&
                        selectedTransferWallet !== 'Select' &&
                        selectedToken !== 'Select' &&
                        limitError === ""
                        ? ''
                        : 'transferFundsBtnDisable'
                        }`}
                      onClick={() => {
                        if (
                          selectedTransferWallet &&
                          selectedTransferWallet !== 'Select' &&
                          selectedToken !== 'Select' &&
                          limitError === ""
                        ) {
                          toggleAlertModal(true);
                        }
                      }}
                      data-e2e="TRANSFER FUNDS"
                    >
                      TRANSFER FUNDS
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          ) : (
            <style>{`\
              body {
                overflow: auto !important;
              }
          `}</style>
          )}
          {
            <div className="merchant_dailog_screen">
              <Dialog
                open={alertModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'lg'}
              >
                <div>
                  <Modal
                    show={true}
                    className="ClearAlertModal transferModal"
                    onHide={() => {
                      toggleAlertModal(false);
                    }}
                  >
                    <Modal.Body className="ClearAlertModalBody transferModalBody">
                      <div className="ClearAlertModalTitle">Are you sure?</div>
                      <div className="ClearAlertModalDescription">
                        Are you sure you want to transfer funds to selected
                        wallet?
                      </div>
                      <div
                        className={`ClearAlertModalClearBtn transferFundsBtn`}
                        onClick={() => {
                          if (
                            selectedTransferWallet &&
                            selectedTransferWallet !== 'Select'
                          ) {
                            transferFunds();
                            createTransaction(data);
                            toggleAlertModal(false);
                            toggleShowModal(false);
                          }
                        }}
                      >
                        Transfer
                      </div>
                      <div
                        className="ClearAlertModalGoBackBtn"
                        onClick={() => {
                          toggleAlertModal(false);
                        }}
                      >
                        Go Back
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </Dialog>
            </div>
          }
          {showSuccess && (
            <div className="successModal">
              <div className="successModalWrap">
                <img
                  className="successModalIcon"
                  alt="successIcon"
                  src={`${image_base_url}${successIcon}`}
                />
                <div className="successModalMsg">
                  Transfer request has been raised successfully!
                </div>
                <img
                data-e2e="closeIcon"
                  className="closeIcon"
                  alt="closeIcon"
                  src={`${image_base_url}${closeImage}`}
                  onClick={() => {
                    toggleShowSuccess(false);
                  }}
                />
              </div>
            </div>
          )}
          {showError && (
            <div className="successModal">
              <div className="successModalWrap">
                <img
                  className="successModalIcon"
                  alt="errorIcon"
                  src={`${image_base_url}${deleteIcon}`}
                />
                <div className="successModalMsg">
                  Something when wrong, transfer failed!{' '}
                </div>
                <img
                  className="closeIcon"
                  alt="closeIcon"
                  src={`${image_base_url}${closeImage}`}
                  onClick={() => {
                    toggleShowError(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {props.loading && <Loading />}
      </td>
    </tr>
  );
};
