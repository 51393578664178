import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { useSelector } from "react-redux";
import {
    GET_B2C_USER_TRANSACTIONS,
    GET_CRYPTOS,
} from "../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../config/axios";
import moment from "moment";
import UserTransactionFilter from "../B2C/UserTransactionFilter";
import Dialog from "@mui/material/Dialog";
import localDb from "../../../../localDb";

const filterIcon = "/cryptonpay/crytoicons/filters1.png";

const transactionsHeader = [
    {
        name: "TRANSACTION ID",
        width: "12%",
    },
    {
        name: "Crypto Amount",
        width: "12%",
    },
    {
        name: "FIAT AMOUNT",
        width: "12%",
    },
    {
        name: "type",
        width: "10%",
    },
    {
        name: "Date & Time",
        width: "15%",
    },
    {
        name: "Fee",
        width: "10%",
    },
    {
        name: "STATUS",
        width: "6%",
    },
];

const SingleUserTransactions = ({ setLoading }) => {

    useEffect(() => {
        getUserTransactions();
        getCryptos();
    }, []);

    const email = localDb.getVal("email");
    const [transactionFitlers, setTransactionFilters] = useState(false);
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const base_url = useSelector((state) => state.config.api_url);
    const [save, setSave] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [orderBy, setOrderBy] = useState("desc");
    const [sortBy, setSortBy] = useState("status.updated");
    const [fromDate, setFromDate] = useState(
        moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
    );
    const [toDate, setToDate] = useState(
        moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
    );
    const [dateSelected, setDateSelected] = useState(false);
    const [statusList, setStatusList] = useState([
        "Success",
        "Pending",
        "Rejected",
    ]);
    const [selectedStatusList, setSelectedStatusList] = useState([
        "Success",
        "Pending",
        "Rejected",
    ]);
    const [type, setType] = useState(["receive", "send"]);
    const [selectedType, setSelectedType] = useState(["receive", "send"]);
    const [cryptos, setCryptos] = useState([]);
    const [selectedCryptos, setSelectedCryptos] = useState([]);
    const [minFiatAmount, setMinFiatAmount] = useState(-1);
    const [maxFiatAmount, setMaxFiatAmount] = useState(-1);

    const getCryptos = async () => {
        await call(
            {
                ...GET_CRYPTOS,
                url: base_url + GET_CRYPTOS.url,
            },
            { email: email }
        )
            .then((res) => {
                if (res.status === 200) {
                    setCryptos(res.data.cryptos);
                }
            })
            .catch((err) => {
                console.log(err.message, "err")
                logoutAfterError(err)
            });
    };

    const getUserTransactions = async (page) => {
        let pageNo = 1;
        if (page) {
            pageNo = page;
        }
        let filterParams = {
            b2cUserId: window.location.pathname.split("/")[2],
            email: email,
            orderBy: orderBy,
            sortBy: sortBy,
            page: pageNo,
            status:
                selectedStatusList.length === 0 ||
                    selectedStatusList.length === statusList.length
                    ? "All"
                    : selectedStatusList,
            CryptoCurrency:
                selectedCryptos.length === 0 ||
                    selectedCryptos.length === cryptos.length
                    ? "All"
                    : selectedCryptos,
            type:
                selectedType.length === 0 || selectedType.length === type.length
                    ? "All"
                    : selectedType,
            minFiatAmount: minFiatAmount === -1 ? "All" : minFiatAmount,
            maxFiatAmount: maxFiatAmount === -1 ? "All" : maxFiatAmount,
            adminMode: true,
        };
        if (dateSelected) {
            filterParams = {
                ...filterParams,
                fromDate: fromDate,
                toDate: toDate,
            };
        }
        let payload = filterParams;
        setLoading(true);
        await call(
            {
                ...GET_B2C_USER_TRANSACTIONS,
                url: base_url + GET_B2C_USER_TRANSACTIONS.url,
            },
            payload
        )
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    if (pageNo === 1) {
                        setData(res.data.b2cUserTransactionsList);
                        setTotalCount(res.data.total_transactions);
                    } else {
                        let list = data.concat(res.data.b2cUserTransactionsList);
                        setData(list);
                        setTotalCount(res.data.total_transactions);
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.message, "err");
                logoutAfterError(err)
            });
    };

    const handleSave = async () => {
        setSave(!save);
        await getUserTransactions();
    };
    const handleTransactionFilters = (closeMode) => {
        setTransactionFilters(!transactionFitlers);
    };

    const resetFilters = () => {
        setOrderBy("desc");
        setSortBy("status.updated");
        setPage(1);
        setDateSelected(false);
        setTotalCount(0);
        setSelectedStatusList(["Success", "Pending", "Rejected"]);
        setFromDate([
            moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        ]);
        setToDate([moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")]);
        setSelectedCryptos([]);
        setSelectedType([]);
        setMinFiatAmount(-1);
        setMaxFiatAmount(-1);
    };

    const getStatusComponent = (status) => {
        switch (status) {
            case "B2CWalletMonitor:ReceiveCompleted":
            case "B2CSendFlow:SendCompleted":
            case 'CardBuyFlow:DepositCompleted':    
            case "B2CWalletMonitor:ReceiveManuallyCompleted":
                return (
                    <div className="Mstatus_active" data-e2e="success">{`SUCCESS`}</div>
                );
            case "B2CWalletMonitor:ErrorEncountered":
            case "B2CSendFlow:ErrorEncountered":
            case "CardBuyFlow:DepositFailed":
                return <div className="Mstatus_inactive">{`REJECTED`}</div>;
            default:
                return (
                    <div className="Mstatus_pending" data-e2e="pending">{`PENDING`}</div>
                );
        }
    };

    return (
        <div>
            <Dialog
                open={transactionFitlers}
                onClose={() => handleTransactionFilters("close")}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <UserTransactionFilter
                    handleTransactionFilters={handleTransactionFilters}
                    statusList={statusList}
                    setStatusList={setStatusList}
                    selectedStatusList={selectedStatusList}
                    setSelectedStatusList={setSelectedStatusList}
                    fromDate={moment(fromDate)}
                    toDate={moment(toDate)}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    handleSave={handleSave}
                    setDateSelected={setDateSelected}
                    dateSelected={dateSelected}
                    resetFilters={resetFilters}
                    cryptos={cryptos}
                    selectedCryptos={selectedCryptos}
                    setSelectedCryptos={setSelectedCryptos}
                    type={type}
                    setMinFiatAmount={setMinFiatAmount}
                    setMaxFiatAmount={setMaxFiatAmount}
                    minFiatAmount={minFiatAmount}
                    maxFiatAmount={maxFiatAmount}
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                />
            </Dialog>
            {totalCount > 0 &&
                <div className="filterBtn filter_image" data-e2e="filterBtn">
                    <img
                        style={{ width: "40px", height: "40px" }}
                        data-e2e="filterIcon"
                        src={`${image_base_url}${filterIcon}`}
                        alt="filterIcon"
                        className="filterBtnIcon"
                        onClick={() => {
                            handleTransactionFilters();
                        }}
                    />
                </div>
            }

            <Table responsive style={{ border: "none" }} className="single_user_transaction">
                <tbody className="TransactionTable_BODY">
                    <tr>
                        {transactionsHeader.map((item, index) => (
                            <th
                                className="user_transaction_heading"
                                style={{ width: item.width }}
                                key={index}
                                data-e2e={item.name}
                            >
                                {item.name}
                            </th>
                        ))}
                    </tr>

                    {data.length > 0 ? (
                        data.map((data, i) => (
                            <tr key={i} className="user_transaction_row">
                                <td className="user_transaction_items" >
                                    <a href={`https://${window.location.host}/b2c-user-transactions/${data.transactionId}`}>
                                        {data.transactionId
                                            ? data.transactionId.substring(0, 5) +
                                            "....." +
                                            data.transactionId.substring(
                                                data.transactionId.length - 6,
                                                data.transactionId.length
                                            )
                                            : "N/A"}
                                    </a>
                                </td>
                                <td className="user_transaction_items">
                                    {`${data.cryptoId} `}
                                    {data.amount ? parseFloat(data.amount).toFixed(8) : "0"}
                                </td>
                                <td className="user_transaction_items">
                                    {data.USDAmount !== "N/A"
                                        ? `$ ${parseFloat(data.USDAmount).toFixed(8)}`
                                        : "N/A"}
                                </td>
                                <td className="user_transaction_items text-capiltalize">
                                    {data.type ? data.type : "N/A"}
                                </td>
                                <td className="user_transaction_items">
                                    {data.createdDate
                                        ? moment(data.createdDate).format(
                                            "DD.MM.YYYY (HH:mm) "
                                        )
                                        : "N/A"}
                                </td>
                                <td className="user_transaction_items">{`N/A`}</td>
                                <td className="user_transaction_items transactionsTable_item">
                                    {data.status && data.status.code
                                        ? getStatusComponent(data.status.code)
                                        : "N/A"}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <div className="No_Transactions_Found">No Data Found</div>
                    )}
                </tbody>
            </Table>
            {totalCount && data && data.length < totalCount ? (
                <div className="Show_more_wrap pb-0">
                    <div
                        className="Show_more"
                        onClick={() => {
                            let newPage = page + 1;
                            setPage(newPage);
                            getUserTransactions(newPage);
                        }}
                    >
                        Show More
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default SingleUserTransactions;