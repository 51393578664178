import React, { useEffect, useState } from "react";
import {
  CREATE_PAYMENT_SPREAD,
  GET_ALL_PAYMENT_SPREAD,
  GET_ALL_SPREAD_VALUE_USING_SPREAD_SETTING,
  GET_B2C_SPREAD_LEVEL,
} from "../../../../config/endpoints";
import { useSelector } from "react-redux";
import localDb from "../../../../localDb";
import { call, logoutAfterError } from "../../../../config/axios";
import { GET_CRYPTOS } from "../../../../config/endpoints";
import Loading from "../../../common/Loading";
import { Menu, MenuItem, styled } from "@mui/material";
import { ADD_UPDATE_USER_SPREAD_VALUE } from "../../../../config/endpoints";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import { useSearchParams } from "react-router-dom";

let headers = [];
const StyledMenu = styled((props) => (
  <Menu
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          width: 10,
          height: 10,
          right: "50%",
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
    }}
    transformOrigin={{ horizontal: "center", vertical: "top" }}
    anchorOrigin={{ horizontal: "center", vertical: "top" }}
    {...props}
  />
))(({ theme }) => ({}));
const PaymentSpreadRate = ({ componentName }) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const { id } = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [spreadLevelData, setSpreadLevelData] = useState([]);

  useEffect(() => {
    headers = [
      {
        name: "CURRENCY",
      },
      {
        name: "ERU",
      },
      {
        name: "USD",
      },
    ];
    getCryptos();
    if (componentName === "merchant") {
        getPaymentSpreadLevel();
    } else {
        getAllSpreadLevels();
    }
  }, []);

  const getPaymentSpreadLevel = async () => {
    setLoading(true);
    await call(
      {
        ...GET_ALL_PAYMENT_SPREAD,
        url: base_url + GET_ALL_PAYMENT_SPREAD.url,
      },
      { email: email, paymentId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setData(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };

  const getCryptos = async () => {
    await call(
      {
        ...GET_CRYPTOS,
        url: base_url + GET_CRYPTOS.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          res.data.cryptos &&
            res.data.cryptos.map((ele) => {
              let headerObj = {
                name: ele.symbol,
              };
              headers.push(headerObj);
            });
        }
      })
      .catch((err) => {
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };
  const getAllSpreadLevels = async () => {
    setLoading(true);
    await call(
      {
        ...GET_B2C_SPREAD_LEVEL,
        url: base_url + GET_B2C_SPREAD_LEVEL.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setSpreadLevelData(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };
  const name = "Spread Levels";

  return (
    <div>
      {componentName === "merchant" ? (
        <div className="fees">
          <div className="fees_table">
            <div className="table_content">
              <div className="table">
                <div className="table_head">
                  {headers.map(({ name }, i) => {
                    return (
                      <div className="title text-uppercase" key={i}>
                        {name}
                      </div>
                    );
                  })}
                </div>
                {data &&
                  data.length > 0 &&
                  data.map((ele, i) => {
                    return (
                      <SingleRowData
                        data={ele}
                        key={i}
                        merchantId={id}
                        getPaymentSpreadLevel={getPaymentSpreadLevel}
                        base_url={base_url}
                        email={email}
                        image_base_url={image_base_url}
                        componentName={componentName}
                      />
                    );
                  })}
              </div>
              {loading && <Loading />}
            </div>
          </div>
        </div>
      ) : (
        <div className="fees">
          <HeaderAndFooterLayout>
            <CommonHeader name={name} />
            <div className="margin_bottom">
              {spreadLevelData.length > 0 &&
                spreadLevelData.map((ele, i) => {
                  return (
                    <div className="fees_table" key={i}>
                      <FeesTable
                        heading={ele.name}
                        spreadId={ele._id}
                        setLoading={setLoading}
                        base_url={base_url}
                        email={email}
                        image_base_url={image_base_url}
                      />
                    </div>
                  );
                })}
            </div>
          </HeaderAndFooterLayout>
          {loading && <Loading />}
        </div>
      )}
    </div>
  );
};

export default PaymentSpreadRate;

const FeesTable = (props) => {
  const [SpreadSettingData, setSpreadSettingData] = useState([]);

  useEffect(() => {
    getAllSpreadLevelDataSettingWise(props.spreadId);
  }, []);

  const getAllSpreadLevelDataSettingWise = async (spreadId) => {
    props.setLoading(true);
    await call(
      {
        ...GET_ALL_SPREAD_VALUE_USING_SPREAD_SETTING,
        url: props.base_url + GET_ALL_SPREAD_VALUE_USING_SPREAD_SETTING.url,
      },
      { email: props.email, spreadSetting: spreadId }
    )
      .then((res) => {
        if (res.status === 200) {
          props.setLoading(false);
          setSpreadSettingData(res.data);
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };

  return (
    <div className="table_content">
      <div className="table_heading mb-3">
        {props.heading}
        {` %`}
      </div>
      <div className="table">
        <div className="table_head mt-0">
          {headers.map(({ name }, i) => {
            return (
              <div className="title text-uppercase" key={i}>
                {name}
              </div>
            );
          })}
        </div>
        {SpreadSettingData &&
          SpreadSettingData.length > 0 &&
          SpreadSettingData.map((ele, i) => {
            return (
              <SingleRowData
                spreadId={props.spreadId}
                data={ele}
                key={i}
                index={i}
                image_base_url={props.image_base_url}
                base_url={props.base_url}
                getAllSpreadLevelData={getAllSpreadLevelDataSettingWise}
                email={props.email}
              />
            );
          })}
      </div>
    </div>
  );
};

const SingleRowData = (props) => {
  const [anchorEle, setAnchorEle] = useState(null);
  const [open, setOpen] = useState(false);
  const [updateSpreadValue, setUpdateSpreadValue] = useState(0);
  const [cryptoId, setCryptoId] = useState("");
  const [currency, setCurrency] = useState("");

  const handleOnSumbitSpreadValue = async () => {
    if (updateSpreadValue != undefined && currency !== "" && cryptoId !== "") {
      let payload = {
        eamil: props.email,
        spreadSetting: props.spreadId,
        cryptoId: cryptoId,
        currencySymbol: currency,
        currencyValue: updateSpreadValue === "" ? 0 : updateSpreadValue,
      };
      await call(
        {
          ...ADD_UPDATE_USER_SPREAD_VALUE,
          url: props.base_url + ADD_UPDATE_USER_SPREAD_VALUE.url,
        },
        { payload, email: props.email }
      )
        .then((res) => {
          if (res.status === 200) {
            setUpdateSpreadValue(0);
            props.getAllSpreadLevelData(props.spreadId);
            setCryptoId("");
            setCurrency("");
            handleClose();
          }
        })
        .catch((err) => {
          console.log(err.message, "err");
          logoutAfterError(err);
        });
    }
  };

  const handlePaymentOnSumbit = async () => {
    if (updateSpreadValue != undefined && currency !== "" && cryptoId !== "") {
      let payload = {
        eamil: props.email,
        paymentId: props.merchantId,
        cryptoId: cryptoId,
        fiatCurrency: currency,
        fiatValue: updateSpreadValue === "" ? 0 : updateSpreadValue,
      };
      await call(
        {
          ...CREATE_PAYMENT_SPREAD,
          url: props.base_url + CREATE_PAYMENT_SPREAD.url,
        },
        { payload, email: props.email }
      )
        .then((res) => {
          if (res.status === 200) {
            setUpdateSpreadValue(0);
            props.getPaymentSpreadLevel();
            setCryptoId("");
            setCurrency("");
            handleClose();
          }
        })
        .catch((err) => {
          console.log(err.message, "err");
          logoutAfterError(err);
        });
    }
  };

  const faitVal = (currency) => {
    if (props.data.fiat.length === 0) {
      return 0;
    } else {
      let val =
        props.data &&
        props.data.fiat &&
        props.data.fiat.find((e) => e.currency === currency);
      if (val) {
        return val.spreadValue;
      } else {
        return 0;
      }
    }
  };

  const handleClose = () => {
    setAnchorEle(null);
    setOpen(false);
  };

  const handleClick = (e, currency, val) => {
    setUpdateSpreadValue(val);
    setCurrency(currency);
    setAnchorEle(e.currentTarget);
    setOpen(true);
  };

  const handleOnChange = (e, crypto) => {
    if (e < 0) {
        setUpdateSpreadValue(0);
    } else {
        setUpdateSpreadValue(e);
    }
    setCryptoId(crypto);
}
  return (
    <div className="single_Row" key={props.index}>
      <div className="table_data d-flex align-items-center">
        <div>
          <img
            src={`${props.image_base_url}${props.data.crypto.icon}`}
            width={20}
            height={20}
            alt={props.data.crypto.name}
          />
        </div>{" "}
        <div className="p-1">{props.data.crypto.name}</div>
      </div>

      {headers
        .filter((ele) => ele.name !== "CURRENCY")
        .map((head, index) => {
          return (
            <div
              key={index}
              className="table_data text-decoration-underline cursor-pointer text-center"
            >
              <div
                id={head.name}
                aria-haspopup="true"
                onClick={(e) => handleClick(e, head.name, faitVal(head.name))}
              >
                {" "}
                {faitVal(head.name)}
              </div>
              <StyledMenu
                id={head.name}
                anchorEl={anchorEle}
                open={open}
                onClose={handleClose}
                keepMounted
                aria-labelledby="demo-positioned-button"
              >
                <MenuItem className="p-0 m-0 spread_menuItem">
                  <input
                    type="number"
                    onChange={(e) =>
                      handleOnChange(e.target.value, props.data.crypto._id)
                    }
                    min="0"
                    value={updateSpreadValue}
                  />
                  <span className="spreadPopUp_button">
                    <i
                      onClick={handleClose}
                      className="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <span
                    className="spreadPopUp_button mr-3"
                    onClick={() =>
                      props.componentName == "merchant"
                        ? handlePaymentOnSumbit()
                        : handleOnSumbitSpreadValue()
                    }
                  >
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </span>
                </MenuItem>
              </StyledMenu>
            </div>
          );
        })}
    </div>
  );
};
