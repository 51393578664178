import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { call, logoutAfterError } from "../../../../config/axios";
import localDb from "../../../../localDb";
import { Table } from 'reactstrap';
import {
  GET_ALL_SEND_RECEIVE,
  UPDATE_SEND_RECEIVE,
} from "../../../../config/endpoints";
import { isViewPermissionValid } from "../../../common/CommonUtils";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    open={props.open}
    placement="top"
    arrow
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    width: 200,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const MerchantSendAndReceiveTable = () => {
  let [feesData, setFeesData] = useState([]);
  const base_url = useSelector((state) => state.config.api_url);
  let [currVal, setCurrVal] = useState({
    index: null,
    row: null,
  });

  
  const fetchALlList = async () => {
    const email = localDb.getVal("email");
    await call(
      {
        ...GET_ALL_SEND_RECEIVE,
        url: base_url + GET_ALL_SEND_RECEIVE.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setFeesData(res.data);
        }
      })
      .catch((err) => {
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  useEffect(() => {
    fetchALlList();
  }, []);

  return (
    <div className="fees">
			<div className="theme-table">
				<Table responsive>
					<tbody>
						<tr>
							<th><p className="theme-table-text">Crypto currency</p></th>
							<th><p className="theme-table-text">Receive fee (units)</p></th>
							<th><p className="theme-table-text">Send fee (units)</p></th>
							<th><p className="theme-table-text">Withdrawal min amount (units)</p></th>
						</tr>
						{feesData && feesData.length > 0 && feesData.map((ele, index) => {
							return (
								<SingleRowData
									currVal={currVal}
									setCurrVal={setCurrVal}
									index={index}
									data={ele}
									key={index}
									feesData={feesData}
									fetchALlList={fetchALlList}
								/>
							);
						})}
					</tbody>
				</Table>
			</div>
    </div>
  );
};

const ToolTipHtml = ({
  image_base_url,
  setCurrVal,
  feesData,
  currVal,
  mode,
  nowVal,
  fetchALlList,
}) => {
  let [val, setVal] = useState(0);
  const base_url = useSelector((state) => state.config.api_url);

  useEffect(() => {
    setVal(nowVal);
  }, []);

  async function setValues(index) {
    let cpy = [...feesData];
    cpy[index][mode] = val;

    await updateOrCreateRecord(cpy[index]);
    fetchALlList();

    setCurrVal({ index: null, row: null });
  }
  
  const updateOrCreateRecord = async (payload) => {
    const email = localDb.getVal("email");
    await call(
      {
        ...UPDATE_SEND_RECEIVE,
        url: base_url + UPDATE_SEND_RECEIVE.url,
      },
      { ...payload, email }
    )
      .then((res) => {
      })
      .catch((err) => {
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };
  return (
    <div className="tooltipHTMLTab">
      <div className="number-input">
        <input
          type="number"
          onChange={(e) => setVal(e.target.value)}
          value={val}
          min={0}
        />
        <div className="up-down-container">
          <img
            onClick={() => setVal(val + 1)}
            src={`${image_base_url}/cryptonpay/crytoicons/input-up.svg`}
            className="up-down"
          />
          <img
            onClick={() => setVal(val - 1)}
            src={`${image_base_url}/cryptonpay/crytoicons/input-down.svg`}
            className="up-down"
          />
        </div>
      </div>

      <div>
        <img
          onClick={() => isViewPermissionValid? null:(
            feesData.forEach((item, index) => {
              if (index == currVal.index) {
                setValues(index)
              }
            })
          )}
          src={`${image_base_url}/cryptonpay/crytoicons/modal-click.svg`}
          width={20}
          height={20}
          className=""
        />
        <img
          onClick={() => isViewPermissionValid? null: setCurrVal({ index: null, row: null })}
          src={`${image_base_url}/cryptonpay/crytoicons/modal-close.svg`}
          width={20}
          height={20}
          className=""
        />
      </div>
    </div>
  );
};

const SingleRowData = ({
  currVal,
  setCurrVal,
  index,
  data,
  feesData,
  fetchALlList,
}) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);

  return (
		<tr className="theme-table-row">
			<td>
				<div className="theme-table-row-td theme-table-asset">
					<img src={`${image_base_url}${data.crypto.icon}`} width="30" height="30" alt={data.crypto.name} />
					<p className="theme-table-text">{data.crypto && data.crypto.name ? data.crypto.name : "N/A"}</p>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td justify-center">
					<HtmlTooltip
						open={currVal.index == index && currVal.row == 0 ? true : false}
						title={
							<ToolTipHtml
								currVal={currVal}
								feesData={feesData}
								setCurrVal={setCurrVal}
								image_base_url={image_base_url}
								mode="receiveFee"
								nowVal={data.receiveFee}
								fetchALlList={fetchALlList}
							/>
						}
						sx={{
							"& .MuiTooltip-arrow": {
								color: "white",
							},
						}}
					>
						<p
							onClick={isViewPermissionValid? null: () =>
								setCurrVal({
									index,
									row: 0,
								})}className="theme-table-text t-center t-underline">
							{data.receiveFee}</p>
					</HtmlTooltip>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td justify-center">
					<HtmlTooltip
						open={currVal.index == index && currVal.row == 1 ? true : false}
						title={
							<ToolTipHtml
								currVal={currVal}
								feesData={feesData}
								setCurrVal={setCurrVal}
								image_base_url={image_base_url}
								mode="sendFee"
								nowVal={data.sendFee}
								fetchALlList={fetchALlList}
							/>
						}
						sx={{
							"& .MuiTooltip-arrow": {
								color: "white",
							},
						}}
					>
						<p
							onClick={isViewPermissionValid? null: () =>
								setCurrVal({
									index,
									row: 1,
								})
							}
							className="theme-table-text t-center t-underline"
						>
							{data.sendFee}
						</p>
					</HtmlTooltip>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td justify-center">
					<HtmlTooltip
						open={currVal.index == index && currVal.row == 2 ? true : false}
						title={
							<ToolTipHtml
								currVal={currVal}
								feesData={feesData}
								setCurrVal={setCurrVal}
								image_base_url={image_base_url}
								mode="withdrawalMinAmount"
								nowVal={data.withdrawalMinAmount}
								fetchALlList={fetchALlList}
							/>
						}
						sx={{
							"& .MuiTooltip-arrow": {
								color: "white",
							},
						}}
					>
						<p
							onClick={isViewPermissionValid? null: () =>
								setCurrVal({
									index,
									row: 2,
								})
							}
							className="theme-table-text t-center t-underline"
						>
							{data.withdrawalMinAmount}
						</p>
					</HtmlTooltip>
				</div>
			</td>
		</tr>
  );
};

export default MerchantSendAndReceiveTable;
