exports.checkImageUrl =  (url) => {
  return new Promise((resolve) => {
		const img = new Image();

		img.onload = (data) => {
			resolve(true);
		};

		img.onerror = () => {
			resolve(false);
		};

		img.src = url;
	});
}