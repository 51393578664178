import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { call, logoutAfterError } from "../../../../../config/axios";
import LocalDBObj from "../../../../../localDb";
import { VIEW_SINGLE_TRANSACTION } from "../../../../../config/endpoints";
import { useSelector } from "react-redux";

const ErrorDetailsComponent = (props) => {
  const baseUrl = useSelector((state) => state?.config?.api_url);
  const email = LocalDBObj?.getVal("email");
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [expandedType, setExpandedType] = useState(null);
  const [transactionData, setTransactionData] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [groupedData, setGroupedData] = useState({});
  const [groupCounts, setGroupCounts] = useState({});

  const toggleGroup = (group) => {
    if (expandedGroup === group) {
      setExpandedGroup(null);
    } else {
      setExpandedGroup(group);
    }
    // Collapse type when toggling group
    setExpandedType(null);
  };

  const toggleType = (type) => {
    if (expandedType === type) {
      setExpandedType(null);
    } else {
      setExpandedType(type);
    }
  };

  const toggleDropdownIcon = (group, type) => {
    if (expandedGroup === group && expandedType === type) {
      return "up";
    } else {
      return "down";
    }
  };

  const openModal = (transactionId) => {
    setTransactionId(transactionId);
  };

  const closeModal = () => {
    setTransactionId(null);
    setTransactionData(null);
  };

  const formatTransactionId = (transactionId) => {
    if (transactionId?.length <= 10) return transactionId;

    const prefix = transactionId?.slice(0, 10);
    const suffix = transactionId?.slice(-10);
    return `${prefix}...${suffix}`;
  };

  const getTransactionDetails = async () => {
    setLoading(true);
    await call(
      {
        ...VIEW_SINGLE_TRANSACTION,
        url: baseUrl + VIEW_SINGLE_TRANSACTION?.url,
      },
      { email, transactionId },
    )
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setTransactionData(res?.data?.transaction);
        }
      })
      .catch((err) => {
        setLoading(false);
        logoutAfterError(err);
      });
  };

  useEffect(() => {
    const data = props?.data || [];
    const newGroupedData = {};
    const newGroupCounts = {};

    data?.forEach((item) => {
      const group = item?.group || "N/A";
      const type = item?.type || "N/A";
      const subtype = item?.subtype || "N/A";

      if (!newGroupedData[group]) {
        newGroupedData[group] = {};
        newGroupCounts[group] = 0;
      }

      if (!newGroupedData[group][type]) {
        newGroupedData[group][type] = {
          count: 0,
          subtypes: {},
        };
      }

      if (!newGroupedData[group][type]?.subtypes[subtype]) {
        newGroupedData[group][type].subtypes[subtype] = [];
      }

      newGroupedData[group][type].subtypes[subtype].push(item);
      newGroupedData[group][type].count++;
      newGroupCounts[group]++;
    });

    setGroupedData(newGroupedData);
    setGroupCounts(newGroupCounts);
  }, [props.data]);

  useEffect(() => {
    if (transactionId) {
      getTransactionDetails();
    }
  }, [transactionId]);
  return (
    <div className="rejection-list-container">
      {Object.keys(groupedData).length > 0 &&
        Object.entries(groupedData)?.map(([group, types]) => (
          <div key={group}>
            <div
              className="rejection-list-header"
              onClick={() => toggleGroup(group)}
            >
              <strong className="rejection-list-main-text">
                {group} ({groupCounts[group]})
              </strong>
              <i
                className={`rejection-list-dropdwon-icon fa cursor-pointer fa-chevron-${toggleDropdownIcon(group, null)} ml-2`}
              ></i>
            </div>
            {expandedGroup === group && (
              <div>
                {Object.keys(types).length > 0 &&
                  Object.entries(types)?.map(([type, typeData]) => (
                    <div key={type} className="pl-2">
                      <div
                        className="rejection-list-header"
                        onClick={() => toggleType(type)}
                      >
                        <strong className="rejection-list-sub-text">
                          {type} ({typeData?.count})
                        </strong>
                        <i
                          className={`rejection-list-dropdwon-icon fa fa-chevron-${toggleDropdownIcon(group, type)} ml-2`}
                        ></i>
                      </div>
                      {expandedType === type && (
                        <div>
                          {Object.entries(typeData?.subtypes)?.map(
                            ([subtype, subtypeItems]) => (
                              <div key={subtype} className="pl-2">
                                <ul>
                                  {subtypeItems?.map((item, index) => (
                                    <li key={index} id="test-id">
                                      <button
                                        onClick={() =>
                                          openModal(item?.transactionId)
                                        }
                                      >
                                        {formatTransactionId(
                                          item?.transactionId,
                                        )}
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ),
                          )}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
      <Modal
        show={!!transactionId}
        onHide={closeModal}
        id="rejection-list-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Transaction Details</Modal.Title>
        </Modal.Header>
        <Modal.Body id="rejection-list-modal">
          {loading ? (
            <div>Loading....</div>
          ) : (
            transactionData && (
              <>
                {transactionData && (
                  <pre>{JSON.stringify(transactionData, null, 2)}</pre>
                )}
              </>
            )
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ErrorDetailsComponent;
