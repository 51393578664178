import React, { useState } from "react";
import { useSelector } from "react-redux";
import { maskCardNumber } from "../../../../../common/CommonUtils";
import Loading from "../../../../../common/Loading";
const closeImage = "/cryptonpay/crytoicons/close.png";
import { ADD_BLOCKED_CARD } from "../../../../../../config/endpoints";
import { call } from "../../../../../../config/axios";
import localDb from "../../../../../../localDb";

function AddBlockedCard({
  onClose,
  onSuccessful
}) {
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const baseUrl = useSelector((state) => state.config.api_url);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [firstSixDigits, setFirstSixDigits] = useState("");
  const [lastFourDigits, setLastFourDigits] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const handleAddNewBlockedCard = async () => {
    const isNumeric = /^[0-9]+$/.test(firstSixDigits); // Ensure that it contains only numbers
    const isNumericLast4 = /^[0-9]+$/.test(lastFourDigits); // Ensure that it contains only numbers
    const maskedNumber = maskCardNumber(firstSixDigits + lastFourDigits);
    if (!isNumeric) {
      setErrorMessage(true);
      setError("Card Number should be numbers only");
      return;
    }
    if (!isNumericLast4) {
      setErrorMessage(true);
      setError("Card Number Last4 digits should be numbers only");
      return;
    }
    const isValid = /^\d{6}$/.test(firstSixDigits);
    if (!isValid) {
      setErrorMessage(true);
      setError("Invalid first 6 digits");
      return;
    }
    const isValidLast4 = /^\d{4}$/.test(lastFourDigits);
    if (!isValidLast4) {
      setErrorMessage(true);
      setError("Invalid last 4 digits");
      return;
    }
    const payload = {
      email: localDb.getVal("email"),
      cardNumber: maskedNumber,
      paymentProviderId: localDb.getVal("adminRole") === "SuperAdmin" ? localDb?.getVal("userId") : localDb?.getVal('paymentProviderId'),
    };
    try {
      setLoading(true);
      const response = await call(
        {
          ...ADD_BLOCKED_CARD,
          url: baseUrl + ADD_BLOCKED_CARD.url,
        },
        payload,
      );
      if (response?.status == 200) {
        setLoading(false);
        setErrorMessage(false);
        onClose();
        onSuccessful()
      }
    } catch (error) {
      setLoading(false);
      setError(error?.response?.data?.error);
      setErrorMessage(true);
      console.error("Error in catch:", error);
    }
  };
  const sixDigitsFunc = (e) => {
    setErrorMessage(false);
    setError("");
    setFirstSixDigits(e.target.value);
  };
  const fourDigitsFunc = (e) => {
    setErrorMessage(false);
    setError("");
    setLastFourDigits(e.target.value);
  };
  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <div className="font-large font-weight-bold text-lg">{`Add BlockedCard`}</div>
        <div
          className="filter_close_button"
          onClick={() => {
            onClose();
          }}
        >
          <img src={`${imageBaseUrl}${closeImage}`} alt="closeImage" />
        </div>
      </div>
      <div className="upload__merchantId">
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">First Six Digits: </div>
          <input
            type="text"
            maxLength={6}
            placeholder="Enter First Six Digits"
            value={firstSixDigits}
            pattern="((?<!\d)(0\d{5})(?!\d))"
            onChange={(e) => {
              sixDigitsFunc(e);
            }}
          />
        </div>
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Last Four Digits: </div>
          <input
            type="text"
            placeholder="Enter Last Four Digits"
            maxLength={4}
            value={lastFourDigits}
            pattern="/[1-4]/g"
            onChange={(e) => fourDigitsFunc(e)}
          />
        </div>
      </div>
      {errorMessage && (
        <div className="my-2 w-full text-left text-red-600">
          {error || "Please fill all details"}
        </div>
      )}

      <div className="d-flex justify-content-center pt-4">
        <button
          type="button"
          onClick={() => {
            handleAddNewBlockedCard();
          }}
          className="btn btn-primary btn-md rounded-pill add-btn w-100"
        >
          Add
        </button>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default AddBlockedCard;
