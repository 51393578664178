import { createStyles, styled } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CPMultiSelect } from '../../../common/CPMultiSelect';
const dateImage = '/cryptonpay/crytoicons/date.png';
const closeImage = '/cryptonpay/crytoicons/close.png';
const selectedCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_filled.png';
const emptyCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_empty.png';

const useStyles = styled(StaticDateRangePicker)(() =>
    createStyles({
        noBorder: {
            outline: 'none',
            border: 'none',
            color: '#fff',
        },
    }),
);

const TransactionFilterMobile = (props) => {
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const classes = useStyles;

    const [showStatusDropdown, setShowStatusDropdown] = useState(false);
    const [showStatusGroupDropdown, setShowStatusGroupDropdown] = useState(false);
    const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
    const [showFiatCurrencyDropdown, setShowFiatCurrencyDropdown] =
        useState(false);
    const [showType, setShowType] = useState(false);
    const [showAcquirer, setShowAcquirer] = useState(false);
    const [blockchain, setBlockchain] = useState(false);
    const [network, setNetwork] = useState(false);
    const [showDaterange, setDateRange] = useState(false);
    const [showProviderDropdown, setShowProviderDropdown] = useState(false);


    const [merchantName, setMerchantName] = useState(false);
    const [value, setValue] = useState([props.minFiatAmount, props.maxFiatAmount]);
    const [openPopup, resetOpenPopup] = useState(false)
    const closeReset = () => resetOpenPopup(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.setMinFiatAmount(newValue[0]);
        props.setMaxFiatAmount(newValue[1]);
    };

    const toggleMerchantNameSelection = (name, merchantId, id) => {
        if (props.selectedMerchantName.map(list => list.name).includes(name)) {
            let newList = props.selectedMerchantName.filter(
                (symbolObj) => symbolObj.name !== name
            );
            props.setSelectedMerchantName(newList);
        } else {
            let newList = [...props.selectedMerchantName];
            newList.push({ '_id': id, 'name': name, 'merchantId': merchantId });
            props.setSelectedMerchantName(newList);
        }
    };

    const togglePaymentProviderSelection = (symbol) => {
        if (props.selectedPaymentProvider.includes(symbol)) {
          let newList = props.selectedPaymentProvider.filter(
            (symbolObj) => symbolObj !== symbol
          );
          props.setSelectedPaymentProvider(newList);
        } else {
          let newList = [...props.selectedPaymentProvider];
          newList.push(symbol);
          props.setSelectedPaymentProvider(newList);
        }
      };
    
    const toggleStatusSelection = (symbol) => {
        if (props.selectedStatusList.includes(symbol)) {
            let newList = props.selectedStatusList.filter(
                (symbolObj) => symbolObj !== symbol
            );
            props.setSelectedStatusList(newList);
        } else {
            let newList = [...props.selectedStatusList];
            newList.push(symbol);
            props.setSelectedStatusList(newList);
        }
    };
    const toggleStatusGroupSelection = (symbol) => {
        if (props.selectedStatusGroupList.includes(symbol)) {
            let newList = props.selectedStatusGroupList.filter(
                (symbolObj) => symbolObj !== symbol
            );
            props.setSelectedStatusGroupList(newList);
        } else {
            let newList = [...props.selectedStatusGroupList];
            newList.push(symbol);
            props.setSelectedStatusGroupList(newList);
        }
    };
    const toggleTypeSelection = (symbol) => {
        if (props.selectedType.includes(symbol)) {
            let newList = props.selectedType.filter(
                (symbolObj) => symbolObj !== symbol
            );
            props.setSelectedType(newList);
            props.resetSelectedStatus(newList)
        } else {
            let newList = [...props.selectedType];
            newList.push(symbol);
            props.setSelectedType(newList);
            props.resetSelectedStatus(newList)
        }
    };
    const toggleAcquirerSelection = (name) => {
        if (props.selectedAcquirer.includes(name)) {
            let newList = props.selectedAcquirer.filter(
                (Obj) => Obj !== name
            );
            props.setSelectedAcquirer(newList);
        } else {
            let newList = [...props.selectedAcquirer];
            newList.push(name);
            props.setSelectedAcquirer(newList);
        }
    };
    const toggleBlockchainSelection = (symbol) => {
        if (props.selectedBlockchainList.includes(symbol)) {
            let newList = props.selectedBlockchainList.filter(
                (symbolObj) => symbolObj !== symbol
            );
            props.SetSelectedBlockchainList(newList);
        } else {
            let newList = [...props.selectedBlockchainList];
            newList.push(symbol);
            props.SetSelectedBlockchainList(newList);
        }
    };

    const toggleNetworkSelection = (symbol) => {
        if (props.selectedNetworkList.includes(symbol)) {
            let newList = props.selectedNetworkList.filter(
                (symbolObj) => symbolObj !== symbol
            );
            props.setSelectedNetworkList(newList);
        } else {
            let newList = [...props.selectedNetworkList];
            newList.push(symbol);
            props.setSelectedNetworkList(newList);
        }
    };

    const handleCallback = async (start, end, label) => {
        props.setFromDate(moment(start).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
        props.setToDate(moment(end).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
        props.setDateSelected(true);
    };

    const toggleSelection = (symbol) => {
        if (props.selectedCryptos.includes(symbol)) {
            let newList = props.selectedCryptos.filter(
                (symbolObj) => symbolObj !== symbol
            );
            props.setSelectedCryptos(newList);
        } else {
            let newList = [...props.selectedCryptos];
            newList.push(symbol);
            props.setSelectedCryptos(newList);
        }
    };

    const toggleFiatSelection = (symbol) => {
        if (props.selectedFiatCurrency.includes(symbol)) {
            let newList = props.selectedFiatCurrency.filter(
                (symbolObj) => symbolObj !== symbol
            );
            props.setSelectedFiatCurrency(newList);
        } else {
            let newList = [...props.selectedFiatCurrency];
            newList.push(symbol);
            props.setSelectedFiatCurrency(newList);
        }
    };

    const handleSaveDetails = () => {
        props.handleTransactionFilters();
        props.handleSave();
    };
    // useEffect(() => {
    //     props.setSelectedFiatCurrency && props.setSelectedFiatCurrency([]);
    //     props.setSelectedCryptos && props.setSelectedCryptos([]);
    //     props.setSelectedMerchantName && props.setSelectedMerchantName([]);
    //     props.setSelectedType && props.setSelectedType([]);
    //     props.setSelectedNetworkList && props.setSelectedNetworkList([]);
    //     props.SetSelectedBlockchainList && props.SetSelectedBlockchainList([]);
    // }, []);
    return (
        <div className="filter_model_container_mobile">
            <div className="filter_model">
                <div
                    className="filter_close_button"
                    onClick={() => {
                        props.handleTransactionFilters();
                    }}
                >
                    <img
                        src={`${image_base_url}${closeImage}`}
                        alt="closeImage"
                        data-e2e="close-icon"
                    />
                </div>
            </div>

            <div className="filter_model_icon_container">
                <div className="filter_model_icon">
                    <div>
                        <img
                            src={`${image_base_url}/homeb2c/transaction_filter_icon.svg`}
                            alt="filter"
                            className="currencyListCheckBox"
                            width={50} height={50}
                        />
                    </div>

                    <div className="filter_model_heading">{`Filter`}</div>
                </div>
            </div>
            <div className="filter_modal_Wrap">
                <div className="FilterModalInputGroup">
                    <div className="FilterModalInputLabel">
                        Date
                    </div>
                    <div className="mobile_date_filter">
                        <div className="coins__filter">
                            <div className="heading___icon"
                                style={{ padding: '10px' }}
                            >
                                {` ${props.fromDate &&
                                    moment(props.fromDate).format("DD.MM.YY")}`}
                            </div>
                        </div>
                        <div className="calendar_img"
                            onClick={() => {
                                setDateRange(!showDaterange);
                                setShowStatusDropdown(false);
                                setShowStatusGroupDropdown(false);
                                setShowCurrencyDropdown(false);
                                setShowFiatCurrencyDropdown(false);
                                setBlockchain(false);
                                setMerchantName(false);
                                setNetwork(false);
                                setShowType(false);
                                setShowAcquirer(false);
                                setShowProviderDropdown(false);
                            }}>
                            <i className="fa fa-calendar" aria-hidden="true"></i>
                        </div>
                        <div className="coins__filter" style={{ height: '41px', borderRadius: '8px', marginLeft: '20px' }}>
                            <div className="heading___icon"
                                style={{ padding: '10px' }}
                            >
                                {`${props.toDate &&
                                    moment(props.toDate).format("DD.MM.YY")}`}
                            </div>
                        </div>

                        {showDaterange && (
                            <div className='wallets-history-date-select mobile_date_select' >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <StaticDateRangePicker
                                        displayStaticWrapperAs="mobile"
                                        inputFormat='YYYY-MM-DDTHH:mm:ss.SSSZ'
                                        value={[props.fromDate, props.toDate]}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                            props.setFromDate(moment(newValue[0]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
                                            if (newValue[1]) {
                                                props.setToDate(moment(newValue[1]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
                                            }
                                            props.setDateSelected(true);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        InputProps={{
                                            classes: { notchedOutline: classes.noBorder },
                                        }}
                                        classes={{
                                            root: classes.root,
                                            checked: classes.checked,
                                        }}

                                    />
                                </LocalizationProvider>
                            </div>
                        )}
                    </div>
                </div>

                <div className="FilterModalInputGroup">
                    <div className="FilterModalInputLabel">
                        Crypto Currency
                    </div>
                    <div className="FilterModalInputDropdown" onClick={() => {
                        setShowCurrencyDropdown(true);
                        setShowStatusDropdown(false);
                        setShowStatusGroupDropdown(false);
                        setShowFiatCurrencyDropdown(false);
                        setBlockchain(false);
                        setMerchantName(false);
                        setShowType(false);
                        setShowAcquirer(false);
                        setNetwork(false);
                        setDateRange(false);
                        setShowProviderDropdown(false);

                    }}>
                        <div className="FilterModalInputText" data-e2e="crypto-currency-expand">
                            {props.selectedCryptos.length === props.cryptos.length ||
                                props.selectedCryptos.length === 0
                                ? 'All'
                                : `${props.selectedCryptos.length} Selected`}
                        </div>
                        <i
                            className="fa fa-chevron-down FilterModalInputDropIcon"
                        />
                    </div>
                    {showCurrencyDropdown && <div className="FilterModalCurrencyDropdownlist">
                        <div className="FM_currencyDrop_All" onClick={() => {
                            setShowCurrencyDropdown(false);
                        }}>
                            <span className="FM_currencyDrop_AllSelector">{props.selectedCryptos.length === props.cryptos.length || props.selectedCryptos.length === 0
                                ? "All" : `${props.selectedCryptos.length} Seletcted`}</span>
                            <span className="FilterModalInputDropUpIconWrap">
                                <i data-e2e="allowableCryptoDropDownIcon" className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                            </span>
                        </div>
                        <div className="currency_container">
                            {props.cryptos.map((crypto, index) => {
                                let { name, symbol, icon, _id } = crypto;
                                return (
                                    <div key={index} className="currencyListItemWrap" onClick={() => {
                                        props.filter_name === 'wallet'
                                            ? toggleSelection(_id)
                                            : toggleSelection(symbol);
                                    }}>
                                        <div className="currencyListIconNameWrap">
                                            <img src={`${image_base_url}${icon}`} alt={symbol} className="currencyList_Icon" />
                                            <div className="currencyList_Name" data-e2e={name}>
                                                {name}
                                            </div>
                                        </div>
                                        <img src={`${image_base_url}${props.filter_name === 'wallet'
                                            ? props.selectedCryptos.includes(_id)
                                                ? selectedCheckBoxIcon
                                                : emptyCheckBoxIcon
                                            : props.selectedCryptos.includes(symbol)
                                                ? selectedCheckBoxIcon
                                                : emptyCheckBoxIcon
                                            }`}
                                            alt="checkbox" className="currencyListCheckBox" />
                                    </div>
                                )
                            })}
                        </div>
                    </div>}
                </div>
                {props.adminRole == "SuperAdmin" && 
                    <div className="FilterModalInputGroup">
                    <div className="FilterModalInputLabel">{`Payment Providers`}</div>
                    <div
                      className="FilterModalInputDropdown"
                      onClick={() => {
                        setShowProviderDropdown(true);
                        setShowStatusDropdown(false);
                        setShowStatusGroupDropdown(false);
                        setShowCurrencyDropdown(false);
                        setShowFiatCurrencyDropdown(false);
                        setMerchantName(false);
                        setBlockchain(false);
                        setNetwork(false);
                        setShowType(false);
                        setShowAcquirer(false);
                        setDateRange(false);
                      }}
                    >
                      <div className="FilterModalInputText">
                        {props.selectedPaymentProvider.length === props.paymentProviderList.length ||
                          props.selectedPaymentProvider.length === 0
                          ? 'All'
                          : `${props.selectedPaymentProvider.length} Selected`}
                      </div>
                      <i
                        className="fa fa-chevron-down FilterModalInputDropIcon"
                      />
                    </div>
                    {showProviderDropdown && (
                      <CPMultiSelect
                        dataE2eLabel="provider-"
                        setShowDropDown={setShowProviderDropdown}
                        items={props.paymentProviderList}
                        selectedItems={
                          props.selectedPaymentProvider
                        }
                        setSelectedItems={(items) =>
                          props.setSelectedPaymentProvider(items.map(a => a._id) || [])}
          
          
                        ItemComponent={({ item: provider }) => {
          
                          return (
                            <div
          
                              className="currencyListItemWrap"
                              onClick={() => {
                                togglePaymentProviderSelection(provider._id);
                              }}
                            >
                              <div
                                className="currencyListIconNameWrap"
                              >
                                <div className="currencyList_Name" id="available-test">{provider.firstName + " " + provider.lastName}</div>
                              </div>
                              <img
                                src={`${image_base_url}${props.selectedPaymentProvider.includes(provider._id)
                                  ? selectedCheckBoxIcon
                                  : emptyCheckBoxIcon
                                  }`}
                                alt="checkbox"
                                className="currencyListCheckBox"
                              />
                            </div>
                          );
                        }}
                      />)
                    }
                  </div>
                }         

                {props.filter_name === 'wallet' ? null :
                    <div className="FilterModalInputGroup">
                        <div className="FilterModalInputLabel">Fiat Currency</div>
                        <div className="FilterModalInputDropdown" onClick={() => {
                            setShowFiatCurrencyDropdown(true);
                            setShowStatusDropdown(false);
                            setShowStatusGroupDropdown(false);
                            setShowCurrencyDropdown(false);
                            setMerchantName(false);
                            setBlockchain(false);
                            setNetwork(false);
                            setMerchantName(false);
                            setShowType(false);
                            setShowAcquirer(false);
                            setDateRange(false);
                            setShowProviderDropdown(false);

                        }}>
                            <div className="FilterModalInputText" data-e2e="crypto-currency-expand">
                                {props.selectedFiatCurrency.length === props.fiatCurrency.length ||
                                    props.selectedFiatCurrency.length === 0
                                    ? 'All'
                                    : `${props.selectedFiatCurrency.length} Selected`}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {showFiatCurrencyDropdown && <div className="FilterModalCurrencyDropdownlist">
                            <div className="FM_currencyDrop_All" onClick={() => {
                                setShowFiatCurrencyDropdown(false);
                            }}>
                                <span className="FM_currencyDrop_AllSelector">{props.selectedFiatCurrency.length === props.fiatCurrency.length || props.selectedFiatCurrency.length === 0
                                    ? "All" : `${props.selectedFiatCurrency.length} Seletcted`}</span>
                                <span className="FilterModalInputDropUpIconWrap">
                                    <i data-e2e="allowableCryptoDropDownIcon" className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                                </span>
                            </div>
                            <div className="currency_container">
                                {props.fiatCurrency.map((fiat, index) => {
                                    let { name, symbol, icon } = fiat;
                                    return (
                                        <div key={index} className="currencyListItemWrap" onClick={() => {
                                            toggleFiatSelection(symbol)
                                        }}>
                                            <div className="currencyListIconNameWrap">
                                                <img src={`${image_base_url}${icon}`} alt={symbol} className="currencyList_Icon" />
                                                <div className="currencyList_Name" data-e2e={name}>
                                                    {name}
                                                </div>
                                            </div>
                                            <img src={`${image_base_url}${props.selectedFiatCurrency.includes(symbol) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                                                alt="checkbox" className="currencyListCheckBox" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                    </div>
                }

                {props.filter_name === 'wallet' ? null : (
                    <div className="FilterModalInputGroup">
                        <div className="FilterModalInputLabel">Type</div>
                        <div className="FilterModalInputDropdown" onClick={() => {
                            setShowType(true);
                            setShowAcquirer(false);
                            setShowStatusDropdown(false);
                            setShowStatusGroupDropdown(false);
                            setBlockchain(false);
                            setShowCurrencyDropdown(false);
                            setShowFiatCurrencyDropdown(false);
                            setNetwork(false);
                            setMerchantName(false);
                            setShowProviderDropdown(false);

                        }}>
                            <div className="FilterModalInputText" data-e2e="crypto-currency-expand">
                                {props.selectedType.length === props.type.length ||
                                    props.selectedType.length === 0
                                    ? 'All'
                                    : `${props.selectedType.length} Selected`}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {showType && <div className="FilterModalCurrencyDropdownlist">
                            <div className="FM_currencyDrop_All" onClick={() => {
                                setShowType(false);
                            }}>
                                <span className="FM_currencyDrop_AllSelector">{props.selectedType.length === props.type.length || props.selectedType.length === 0
                                    ? "All" : `${props.selectedType.length} Seletcted`}</span>
                                <span className="FilterModalInputDropUpIconWrap">
                                    <i data-e2e="allowableCryptoDropDownIcon" className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                                </span>
                            </div>
                            <div className="currency_container">
                                {props.type.map((type, index) => {
                                    return (
                                        <div key={index} className="currencyListItemWrap" onClick={() => {
                                            toggleTypeSelection(type)
                                        }}>
                                            <div className="currencyListIconNameWrap">
                                                <div className="currencyList_Name" data-e2e={type}>
                                                    {type}
                                                </div>
                                            </div>
                                            <img src={`${image_base_url}${props.selectedType.includes(type) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                                                alt="checkbox" className="currencyListCheckBox" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                    </div>
                )}
                {props.filter_name === 'wallet' || !props.showAcquirerDropD ? null : (
                    <div className="FilterModalInputGroup">
                        <div className="FilterModalInputLabel">Acquirer</div>
                        <div className="FilterModalInputDropdown" onClick={() => {
                            setShowAcquirer(true);
                            setShowType(false);
                            setShowStatusDropdown(false);
                            setShowStatusGroupDropdown(false);
                            setBlockchain(false);
                            setShowCurrencyDropdown(false);
                            setShowFiatCurrencyDropdown(false);
                            setNetwork(false);
                            setMerchantName(false);
                            setShowProviderDropdown(false);

                        }}>
                            <div className="FilterModalInputText" data-e2e="crypto-currency-expand">
                                {props.selectedAcquirer.length === props.aquireNameData.length ||
                                    props.selectedAcquirer.length === 0
                                    ? 'All'
                                    : `${props.selectedAcquirer.length} Selected`}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {showAcquirer && <div className="FilterModalCurrencyDropdownlist">
                            <div className="FM_currencyDrop_All" onClick={() => {
                                setShowAcquirer(false);
                            }}>
                                <span className="FM_currencyDrop_AllSelector">{props.selectedAcquirer.length === props.aquireNameData.length || props.selectedAcquirer.length === 0
                                    ? "All" : `${props.selectedAcquirer.length} Seletcted`}</span>
                                <span className="FilterModalInputDropUpIconWrap">
                                    <i data-e2e="allowableCryptoDropDownIcon" className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                                </span>
                            </div>
                            <div className="currency_container">
                                {props.aquireNameData.map((name, index) => {
                                    return (
                                        <div key={index} className="currencyListItemWrap" onClick={() => {
                                            toggleAcquirerSelection(name)
                                        }}>
                                            <div className="currencyListIconNameWrap">
                                                <div className="currencyList_Name">
                                                    {name}
                                                </div>
                                            </div>
                                            <img src={`${image_base_url}${props.selectedAcquirer.includes(name) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                                                alt="checkbox" className="currencyListCheckBox" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                    </div>
                )}

                {props.filter_name === 'wallet' || props.statusGroupList.length === 0 ? null : (<div className="FilterModalInputGroup">
                    <div className="FilterModalInputLabel">Status Group</div>
                    <div className="FilterModalInputDropdown" onClick={() => {
                        setShowStatusGroupDropdown(true);
                        setShowStatusDropdown(false);
                        setShowCurrencyDropdown(false);
                        setShowFiatCurrencyDropdown(false);
                        setBlockchain(false);
                        setNetwork(false);
                        setMerchantName(false);
                        setShowType(false);
                        setShowAcquirer(false);
                        setShowProviderDropdown(false);

                    }}>
                        <div className="FilterModalInputText" data-e2e="crypto-currency-expand">
                            {props.selectedStatusGroupList.length === props.statusGroupList.length ||
                                props.selectedStatusGroupList.length === 0
                                ? 'All'
                                : `${props.selectedStatusGroupList.length} Selected`}
                        </div>
                        <i
                            className="fa fa-chevron-down FilterModalInputDropIcon"
                        />
                    </div>
                    {showStatusGroupDropdown && <div className="FilterModalCurrencyDropdownlist">
                        <div className="FM_currencyDrop_All" onClick={() => {
                            setShowStatusGroupDropdown(false);
                        }}>
                            <span className="FM_currencyDrop_AllSelector">{props.selectedStatusGroupList.length === props.statusGroupList.length || props.selectedStatusGroupList.length === 0
                                ? "All" : `${props.selectedStatusGroupList.length} Seletcted`}</span>
                            <span className="FilterModalInputDropUpIconWrap">
                                <i data-e2e="allowableCryptoDropDownIcon" className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                            </span>
                        </div>
                        <div className="currency_container">
                            {props.statusGroupList.map((status, index) => {
                                return (
                                    <div key={index} className="currencyListItemWrap" onClick={() => {
                                        toggleStatusGroupSelection(status)
                                    }}>
                                        <div className="currencyListIconNameWrap">
                                            <div className="currencyList_Name currencyList_Name_small" data-e2e={status}>
                                                {status}
                                            </div>
                                        </div>
                                        <img src={`${image_base_url}${props.selectedStatusGroupList.includes(status) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                                            alt="checkbox" className="currencyListCheckBox" />
                                    </div>
                                )
                            })}
                        </div>
                    </div>}
                </div>)}

                <div className="FilterModalInputGroup">
                    <div className="FilterModalInputLabel">Status</div>
                    <div className="FilterModalInputDropdown" onClick={() => {
                        setShowStatusDropdown(true);
                        setShowStatusGroupDropdown(false);
                        setShowCurrencyDropdown(false);
                        setShowFiatCurrencyDropdown(false);
                        setBlockchain(false);
                        setNetwork(false);
                        setShowType(false);
                        setShowAcquirer(false);
                        setMerchantName(false);
                        setShowProviderDropdown(false);

                    }}>
                        <div className="FilterModalInputText" data-e2e="crypto-currency-expand">
                            {props.selectedStatusList.length === props.statusList.length ||
                                props.selectedStatusList.length === 0
                                ? 'All'
                                : `${props.selectedStatusList.length} Selected`}
                        </div>
                        <i
                            className="fa fa-chevron-down FilterModalInputDropIcon"
                        />
                    </div>
                    {showStatusDropdown && <div className="FilterModalCurrencyDropdownlist">
                        <div className="FM_currencyDrop_All" onClick={() => {
                            setShowStatusDropdown(false);
                        }}>
                            <span className="FM_currencyDrop_AllSelector">{props.selectedStatusList.length === props.statusList.length || props.selectedStatusList.length === 0
                                ? "All" : `${props.selectedStatusList.length} Seletcted`}</span>
                            <span className="FilterModalInputDropUpIconWrap">
                                <i data-e2e="allowableCryptoDropDownIcon" className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                            </span>
                        </div>
                        <div className="currency_container">
                            {props.statusList.map((status, index) => {
                                return (
                                    <div key={index} className="currencyListItemWrap" onClick={() => {
                                        toggleStatusSelection(status)
                                    }}>
                                        <div className="currencyListIconNameWrap">
                                            <div className="currencyList_Name currencyList_Name_small" data-e2e={status}>
                                                {status}
                                            </div>
                                        </div>
                                        <img src={`${image_base_url}${props.selectedStatusList.includes(status) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                                            alt="checkbox" className="currencyListCheckBox" />
                                    </div>
                                )
                            })}
                        </div>
                    </div>}
                </div>

                {props.filter_name === 'wallet' && (
                    <div className="FilterModalInputGroup">
                        <div className="FilterModalInputLabel">Blockchain</div>
                        <div className="FilterModalInputDropdown" onClick={() => {
                            setBlockchain(true)
                            setShowType(false);
                            setShowAcquirer(false);
                            setShowStatusDropdown(false);
                            setShowStatusGroupDropdown(false);
                            setShowCurrencyDropdown(false);
                            setShowFiatCurrencyDropdown(false);
                            setNetwork(false);
                            setShowProviderDropdown(false);

                        }}>
                            <div className="FilterModalInputText" data-e2e="crypto-currency-expand">
                                {props.selectedBlockchainList.length === props.statusList.length ||
                                    props.selectedBlockchainList.length === 0
                                    ? 'All'
                                    : `${props.selectedBlockchainList.length} Selected`}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {blockchain && <div className="FilterModalCurrencyDropdownlist">
                            <div className="FM_currencyDrop_All" onClick={() => {
                                setBlockchain(false);
                            }}>
                                <span className="FM_currencyDrop_AllSelector">{props.selectedBlockchainList.length === props.blockchainList.length || props.selectedBlockchainList.length === 0
                                    ? "All" : `${props.selectedBlockchainList.length} Seletcted`}</span>
                                <span className="FilterModalInputDropUpIconWrap">
                                    <i data-e2e="allowableCryptoDropDownIcon" className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                                </span>
                            </div>
                            <div className="currency_container">
                                {props.blockchainList.map((blockchain, index) => {
                                    return (
                                        <div key={index} className="currencyListItemWrap" onClick={() => {
                                            toggleBlockchainSelection(blockchain)
                                        }}>
                                            <div className="currencyListIconNameWrap">
                                                <div className="currencyList_Name" data-e2e={blockchain}>
                                                    {blockchain}
                                                </div>
                                            </div>
                                            <img src={`${image_base_url}${props.selectedStatusList.includes(blockchain) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                                                alt="checkbox" className="currencyListCheckBox" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                    </div>
                )}

                {!["merchantTransactions"].includes(props.filter_merchant_name) &&
                    <div className="FilterModalInputGroup">
                        <div className="FilterModalInputLabel">Network</div>
                        <div className="FilterModalInputDropdown" onClick={() => {
                            setNetwork(true);
                            setBlockchain(false);
                            setShowType(false);
                            setShowAcquirer(false);
                            setShowStatusDropdown(false);
                            setShowStatusGroupDropdown(false);
                            setShowCurrencyDropdown(false);
                            setMerchantName(false);
                            setShowFiatCurrencyDropdown(false);
                            setShowProviderDropdown(false);
                        }}>
                            <div className="FilterModalInputText" data-e2e="crypto-currency-expand">
                                {props.selectedNetworkList.length === props.networkList.length ||
                                    props.selectedNetworkList.length === 0
                                    ? 'All'
                                    : `${props.selectedNetworkList.length} Selected`}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {network && <div className="FilterModalCurrencyDropdownlist">
                            <div className="FM_currencyDrop_All" onClick={() => {
                                setNetwork(false);
                            }}>
                                <span className="FM_currencyDrop_AllSelector">{props.selectedNetworkList.length === props.networkList.length || props.selectedNetworkList.length === 0
                                    ? "All" : `${props.selectedNetworkList.length} Seletcted`}</span>
                                <span className="FilterModalInputDropUpIconWrap">
                                    <i data-e2e="allowableCryptoDropDownIcon" className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                                </span>
                            </div>
                            <div className="currency_container">
                                {props.networkList.map((network, index) => {
                                    return (
                                        <div key={index} className="currencyListItemWrap" onClick={() => {
                                            toggleNetworkSelection(network)
                                        }}>
                                            <div className="currencyListIconNameWrap">
                                                <div className="currencyList_Name" data-e2e={network}>
                                                    {network}
                                                </div>
                                            </div>
                                            <img src={`${image_base_url}${props.selectedNetworkList.includes(network) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                                                alt="checkbox" className="currencyListCheckBox" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                    </div>
                }

                {["induvisual_transactions", "merchantTransactions"].includes(props.filter_merchant_name) ? null : props.filter_name !== 'wallet' &&
                    <div className="FilterModalInputGroup">
                        <div className="FilterModalInputLabel">Merchant Name</div>
                        <div className="FilterModalInputDropdown" onClick={() => {
                            setMerchantName(true);
                            setBlockchain(false)
                            setShowType(false);
                            setShowAcquirer(false);
                            setShowStatusDropdown(false);
                            setShowStatusGroupDropdown(false);
                            setShowCurrencyDropdown(false);
                            setShowFiatCurrencyDropdown(false);
                            setNetwork(false);
                            setShowProviderDropdown(false);
                        }}>
                            <div className="FilterModalInputText" data-e2e="crypto-currency-expand">
                                {props.selectedMerchantName.length === props.selectedMerchantName.length ||
                                    props.selectedNetworkList.length === 0
                                    ? 'All'
                                    : `${props.selectedMerchantName.length} Selected`}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {merchantName && <div className="FilterModalCurrencyDropdownlist">
                            <div className="FM_currencyDrop_All" onClick={() => {
                                setMerchantName(false);
                            }}>
                                <span className="FM_currencyDrop_AllSelector">{props.selectedMerchantName.length === props.merchantNameList.length || props.selectedMerchantName.length === 0
                                    ? "All" : `${props.selectedMerchantName.length} Seletcted`}</span>
                                <span className="FilterModalInputDropUpIconWrap">
                                    <i data-e2e="allowableCryptoDropDownIcon" className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                                </span>
                            </div>
                            <div className="currency_container">
                                {props.merchantNameList.map((merchant, index) => {

                                    return (
                                        <div key={index} className="currencyListItemWrap" onClick={() => {
                                            toggleMerchantNameSelection(merchant.name, merchant.merchantId, merchant._id)
                                        }}>
                                            <div className="currencyListIconNameWrap">
                                                <div className="currencyList_Name">
                                                    {merchant.name}
                                                </div>
                                            </div>
                                            <img src={`${image_base_url}${props.selectedMerchantName.map(list => list.name).includes(merchant.name) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                                                alt="checkbox" className="currencyListCheckBox" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                    </div>
                }

                {props.filter_name === 'wallet' || props.filter_merchant_name === "merchantTransactions" ? null : (
                    <div className="FilterModalInputGroup">
                        <div className="FilterModalInputLabel">Fiat Amount Range</div>
                        <div>
                            <Box sx={{ width: 280 }}>
                                <Slider
                                    getAriaLabel={() => 'Fiat Amount Range'}
                                    value={value}
                                    min={1}
                                    max={1000}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                />
                            </Box>
                        </div>
                    </div>
                )}
                {props.filter_name === 'wallet' && (props.minCryptoAmount || props.minCryptoAmount == "") ? (
                    <div className="FilterModalInputGroup">
                        <div className="FilterModalInputLabel">Crypto Currency Amount Range</div>
                        <div className='MinMaxCryptoRangeWrap'>
                            <input className="rangeInput rangeInputLeft" type="text" placeholder='Min Amount'
                                name="minCryptoAmount"
                                value={props.minCryptoAmount}
                                onChange={(e) => { props.setMinCryptoAmount(e.target.value) }} />
                            <input className="rangeInput rangeInputRight" type="text" placeholder='Max Amount'
                                name="maxCryptoAmount"
                                value={props.maxCryptoAmount}
                                onChange={(e) => { props.setMaxCryptoAmount(e.target.value) }} />
                        </div>
                        {props.rangeError !== "" && <div style={{ color: "red", marginTop: "10px" }} >{props.rangeError}</div>}
                    </div>
                ) : null}
                {["merchantTransactions"].includes(props.filter_merchant_name) ? null :
                    <div className="ContainsNoteFilterWrap" onClick={() => {
                        props.changeIsContainsNote();
                    }}>
                        <img
                            src={`${image_base_url}${props.isContainsNote
                                ? selectedCheckBoxIcon
                                : emptyCheckBoxIcon
                                }`}
                            alt="checkbox"
                            className="currencyListCheckBox"
                        />
                        <div className="ContainsNoteFilterName" >{"Contains a Note"}</div>
                    </div>
                }
            </div>
            <div className="filter_modal_btn_wrap">
                <button type="button" onClick={() => props.resetFilters()} className="btn btn-light">{`Clear`}</button>
                <button data-e2e="save-btn"
                    onClick={() => {
                        if (!props.rangeError || props.rangeError === "") { 
                            handleSaveDetails();
                        }
                    }} type="button" className={`btn ${!props.rangeError || props.rangeError == "" ? "btn-primary" :"btn-secondary"}`}>{`Save`}</button>
            </div>
        </div>
    )
}

export default TransactionFilterMobile