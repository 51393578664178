// TransactionSummary.js
import React from "react";
import { Tabs, Tab, Box, Button } from "@mui/material";
import ErrorsContent from "./ErrorTabContent";
import TransactionsContent from "./TransactionTabContent";
import AggregateContent from "./TransactionSummery/Aggregate";
import RoutingServiceContent from "./TransactionSummery/RoutingContent";

function TransactionSummary({
  mainTab,
  setMainTab,
  summaryTab,
  setSummaryTab,
  aggregateSummaryData,
  setAggrehateSummaryData,
  routingServicesData,
  setRoutingServicesData,
  merchantSummaryData,
  setMerchantSummaryData,
  cardSchemeSummaryData,
  setCardSchemeSummaryData,
  acquirerSummaryData,
  setAcquirerSummaryData,
  countrySummaryData,
  errorDetails,
  dataTransaction,
  generateFilterURL,
  trafficSummaryData,
  // 
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalCount,
  setTotalCount,
  updateQueryParams,
}) {
  const handleMainTabChange = (event, newValue) => {
    setMainTab(newValue);
  };

  const handleSummaryTabChange = (index) => {
    setSummaryTab(index);
  };

  return (
    <Box sx={{ width: "100%" }} className="rounded-lg shadow-md">
      <Tabs
        value={mainTab}
        onChange={handleMainTabChange}
        aria-label="main tabs"
      >
        <Tab label="SUMMARY" />
        <Tab label="ERRORS" />
        <Tab label="TRANSACTIONS" />
      </Tabs>

      {/* Summary Tab Content */}
      {mainTab === 0 && (
        <Box p={3}>
          <Box
            sx={{
              display: "flex",
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: 2,
              backgroundColor: "#E0E0E0",
              width: "fit-content",
            }}
          >
            {[
              "Aggregate",
              "Routing service",
              "Merchants",
              "Acquirer",
              "Card scheme",
              "Country",
              "Traffic",
            ]?.map((label, index) => (
              <Button
                key={label}
                variant={summaryTab === index ? "contained" : "text"}
                onClick={() => handleSummaryTabChange(index)}
                sx={{
                  marginRight: 1,
                  backgroundColor: summaryTab === index ? "white" : "#E0E0E0",
                  color: summaryTab === index ? "black" : "#475467",
                  "&amp;:focus": {
                    backgroundColor: summaryTab === index ? "white" : "#E0E0E0",
                  },
                }}
              >
                {label}
              </Button>
            ))}
          </Box>

          {summaryTab === 0 && <AggregateContent data={aggregateSummaryData} generateFilterURL={generateFilterURL} />}
          {summaryTab === 1 && (
            <RoutingServiceContent data={routingServicesData} tabType={"Routing Service"} generateFilterURL={generateFilterURL} updateQueryParams={updateQueryParams} />
          )}
          {summaryTab === 2 && <RoutingServiceContent data={merchantSummaryData} tabType={"Merchant"} generateFilterURL={generateFilterURL} updateQueryParams={updateQueryParams} />}
          {summaryTab === 3 && <RoutingServiceContent data={acquirerSummaryData} tabType={"Acquirer"} generateFilterURL={generateFilterURL} updateQueryParams={updateQueryParams} />} 
          {summaryTab === 4 && <RoutingServiceContent data={cardSchemeSummaryData} tabType={"CardScheme"} generateFilterURL={generateFilterURL} updateQueryParams={updateQueryParams} />}
          {summaryTab === 5 && <RoutingServiceContent data={countrySummaryData} tabType={"Country"} generateFilterURL={generateFilterURL} updateQueryParams={updateQueryParams} />}
          {summaryTab === 6 && <RoutingServiceContent data={trafficSummaryData} tabType={"Traffic"} generateFilterURL={generateFilterURL} updateQueryParams={updateQueryParams} />}

          {/* Add content for other nested tabs here as needed */}
        </Box>
      )}

      {/* Errors Tab Content */}
      {mainTab === 1 && <ErrorsContent data={errorDetails} updateQueryParams={updateQueryParams} />}

      {/* Transactions Tab Content */}
      {mainTab === 2 && 
      <TransactionsContent 
        data={dataTransaction}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
        setTotalCount={setTotalCount}
         />}
    </Box>
  );
}

export default TransactionSummary;
