import React from "react";

function LabelInput(props) {
  const { label, placeholder, value, setterValue } = props;
  return (
    <div className="upload__merchantId">
      <div className="FilterModalInputGroup">
        <div className="FilterModalInputLabel">{label}</div>  
        <input
          type="text"
          placeholder={placeholder}
          defaultValue={value}
          onChange = {(e) => setterValue(e.target.value)}
        />
      </div>
    </div>
  );
}

export default LabelInput;
