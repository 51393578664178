import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  GET_MAX_TRAFFIC_STATUS,
  RESET_MAX_TRAFFIC_STATUS,
  GET_PERIOD_LIST_BY_ACQUIRER,
} from "../../../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../../../config/axios";
import localDb from "../../../../../../localDb";
import Loading from "../../../../../common/Loading";
import TabButton from "../../../../../common/TabButton";
import { Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const StatusTab = ({ acquireService }) => {
  const [tabs, setTabs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statusData, setStatusData] = useState();
  const [periodList, setPeriodList] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState();
  const baseURL = useSelector((state) => state.config.api_url);
  const adminRole = localStorage.getItem("adminRole");
  const emailFromLocal = localDb.getVal("email");

  useEffect(() => {
    getPeriodOptionList();
    setSelectedPeriod();
  }, [tabs]);

  useEffect(() => {
    if(selectedPeriod) {
      getMaxtrafficStatus();
    }
  }, [selectedPeriod]);

  const getMaxtrafficStatus = async () => {
    try {
      setLoading(true);
      const payload = {
        email: emailFromLocal,
        adminRole: adminRole,
        period: selectedPeriod,
        acquirerName: acquireService?.routingService[tabs]?.acquirerName,
        routingId: acquireService && acquireService.uniqueId,
      };
      const response = await call(
        {
          ...GET_MAX_TRAFFIC_STATUS,
          url: baseURL + GET_MAX_TRAFFIC_STATUS.url,
        },
        payload,
      );

      if (response.status === 200) {
        setStatusData(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getPeriodOptionList = async () => {
    try {
      setLoading(true);
      const payload = {
        email: emailFromLocal,
        adminRole: adminRole,
        acquirerName: acquireService?.routingService[tabs]?.acquirerName,
        routingId: acquireService && acquireService.uniqueId,
      };
      const response = await call(
        {
          ...GET_PERIOD_LIST_BY_ACQUIRER,
          url: baseURL + GET_PERIOD_LIST_BY_ACQUIRER.url,
        },
        payload,
      );

      if (response.status === 200) {
        setPeriodList(response?.data);
        setSelectedPeriod(response?.data[0]?.value);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleTabSwitch = (index) => {
    setTabs(index);
  };

  const handleChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  return (
    <div className="TabContainer">
      {acquireService ? (
        <div>
          <div className="acquireServiceScroll">
            {acquireService.routingService.map((tab, index) => (
              <TabButton
                key={index}
                activeTab={tabs}
                handleTabSwitch={handleTabSwitch}
                tab={tab}
                index={index}
              />
            ))}
          </div>
          <div className="relative flex pt-5">
            <Grid container spacing={2}>
              {loading && <Loading />}
              {acquireService.routingService.map((items, index) => {
                return (
                  <div key={index}>
                    {tabs === index &&
                      typeof statusData === "object" &&
                      Object.keys(statusData).map((fieldName, index) => {
                        if (fieldName === "Period") {
                          return (
                            <Grid item xs={12} key={index}>
                              <div className="flex">
                                <p className="mb-4 flex w-52 items-center">
                                  {fieldName}
                                </p>
                                <Box sx={{ minWidth: 210 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Period
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedPeriod}
                                      onChange={handleChange}
                                      label="Period"
                                    >
                                      {periodList?.length > 0 && periodList?.map((period, index) => (
                                        <MenuItem
                                          key={index}
                                          value={period.value}
                                        >
                                          {period.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </div>
                            </Grid>
                          );
                        } else {
                          const { value, leftValue } = statusData[fieldName];
                          return (
                            <Grid item xs={12} key={index}>
                              <div className="flex">
                                <p className="flex w-52 items-center">
                                  {fieldName}
                                </p>
                                <div className="flex items-center pt-2">
                                  <TextField
                                    id={fieldName}
                                    value={value}
                                    variant="outlined"
                                    disabled
                                  />
                                  <p className="ml-2 text-lg font-medium">
                                    / {leftValue.toFixed(2) || "NA"}
                                  </p>
                                </div>
                              </div>
                            </Grid>
                          );
                        }
                      })}
                  </div>
                );
              })}
            </Grid>
          </div>
        </div>
      ) : (
        <div className="w-full text-center">No Data</div>
      )}
    </div>
  );
};

export default StatusTab;
