import React, { useEffect, useState } from "react";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import {
  GET_ALL_B2C_USERS,
  DELETE_B2C_USER, GET_ALL_PAYMENT_PROVIDERS,
} from "../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../config/axios";
import CommonHeader from "../CommonPages/CommonHeader";
import SettingsIcon from "@mui/icons-material/Settings";
import { useSelector } from "react-redux";
import Loading from "../../../common/Loading";
import localDb from "../../../../localDb";
import Dialog from "@mui/material/Dialog";
import AllUsersTable from "./AllUsersTable";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserTransactionFilter from "./UserTransactionFilter";
import moment from "moment/moment";
import DownloadCsv from "../CommonPages/DownloadCsv";
import { isViewPermissionValid } from "../../../common/CommonUtils";
import useDebounce from "../../global/useDebounce";
const filterIcon = "/cryptonpay/filter_icon.svg";
const downloadIcon = "/cryptonpay/download_icon.svg";
const searchicon = "/cryptonpay/crytoicons/searchicon1.png";
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";

const Users = () => {
  const navigate = useNavigate();
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal("email");
  const base_url = useSelector((state) => state.config.api_url);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [orderBy, setOrderBy] = useState("asc");
  const [sortBy, setSortBy] = useState("firstName");
  const [dateSelected, setDateSelected] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [save, setSave] = useState(false);
  const [userFilters, setUserFilters] = useState(false);
  const [paymentProviderList, setPaymentProviderList] = useState([]);
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState([]);
  const adminRole = localStorage.getItem("adminRole");
  const [downloadCsv, setDownloadCsv] = useState(false);
  const [allData, setAllData] = useState([]);

  const handleSave = () => {
    setSave(!save);
  };
  const debouncedSearchText = useDebounce(searchText);
  useEffect(() => {
    getAllUsers()
    if (adminRole == "SuperAdmin" && paymentProviderList.length === 0) {
      getProviders();
    }
  }, [debouncedSearchText, orderBy, sortBy, save]);

  const getAllUsers = async (page) => {
    let pageNo = 1;
    let allMode = false

    if (page) {
      pageNo = page;
    }

    if (page === "all") {
      allMode = true;
    }
    let filterParams = {
      firstName: searchText.toString(),
      email: searchText.toString(),
      mobile: searchText.toString(),
      page: pageNo,
      sortBy: sortBy,
      orderBy: orderBy,
      paymentProviderId: localDb.getVal("adminRole") === "PaymentProviderAdmin" ? localDb.getVal("paymentProviderId") : "",
      providers:
        selectedPaymentProvider?.length === 0 || selectedPaymentProvider?.length === paymentProviderList?.length
          ? "All"
          : selectedPaymentProvider,
    };

    if (dateSelected) {
      filterParams = {
        ...filterParams,
      };
    }

    if (allMode) {
      delete filterParams.page;
      filterParams = {
        ...filterParams,
        findAll: true,
      };
    }
    let payload = {
      email: email,
      filterParams: filterParams,
    };

    setLoading(true);
    await call(
      {
        ...GET_ALL_B2C_USERS,
        url: base_url + GET_ALL_B2C_USERS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          // console.log("result: ", res.data);
          if (allMode) {
            setAllData(res.data.b2cUsers);
            setLoading(false);
            return;
          }
          if (pageNo === 1) {
            setData(res.data.b2cUsers);
            setTotalCount(res.data.totalCount);
          } else {
            let list = data.concat(res.data.b2cUsers);
            setData(list);
            setTotalCount(res.data.totalCount);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const handleDownloadUsers = async () => {
    await getAllUsers("all");
    setDownloadCsv(true);
  };

  const handleCloseDownloadCsv = () => {
    setDownloadCsv(false);
    getAllUsers();
  };

  const resetFilters = () => {
    setSelectedPaymentProvider([])
    setSave(!save)
  };

  const handleGlobalSettings = () => {
    navigate("/user-settings");
  };

  const handleUserFilters = () => {
    setUserFilters(false);
  };

  const getProviders = async () => {
    let payload = {
      email,
      firstName: searchText,
    };
    setLoading(true);
    await call(
      {
        ...GET_ALL_PAYMENT_PROVIDERS,
        url: base_url + GET_ALL_PAYMENT_PROVIDERS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setPaymentProviderList(res.data.providerList);
          let providerId = [];
          if (res.data.providerList.length > 0) {
            providerId = res.data.providerList.map((e) => e._id);
          }
          setSelectedPaymentProvider(providerId);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };



  const deleteUser = async () => {
    setLoading(true);
    let payload = {
      email: localDb.getVal("email"),
      id: deletingId,
    };
    // console.log("payload",payload);
    await call(
      {
        ...DELETE_B2C_USER,
        url: base_url + DELETE_B2C_USER.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setDeletingId(null);
          // getAllUsers();
          window.location.reload();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const name = "All B2C Users";
  const buttons = [
    {
      name: "Settings",
      onClick: handleGlobalSettings,
      icon: <SettingsIcon />,
    },
  ];
  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
        <CommonHeader name={name} buttons={buttons} />
        <div>
          <div className="transaction_filter">
            <div className="transactionsFiltersWrap">
              <div className="transactionsFilters">
                <div className="transactionsFiltersItem transactionsSearchFilter">
                  <div className="transactionsFilterTextWrap">
                    <input
                      type="text"
                      className="transactionsFilterInput"
                      name="searchText"
                      data-e2e="search-textbox"
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      placeholder="Search Users by name, email, phone"
                    />
                  </div>
                  <div className="transactionsFilterSingleIconWrap">
                    <img
                      src={`${image_base_url}${searchicon}`}
                      alt="searchicon"
                      className="transactionsFilterSingleIcon"
                      data-e2e="searchIcon"
                    />
                  </div>
                </div>

                <button type="button" data-e2e="filterBtn" className="transactionFilter__btn" onClick={() => {setUserFilters(true);}}>
                  <img data-e2e="filterIcon" src={`${image_base_url}${filterIcon}`} alt="filterIcon" />
                </button>
              </div>
            </div>
            <button
              type="button"
              data-e2e="filterBtn"
              className={`transactionFilter__btn`}
              onClick={() => {
                    handleDownloadUsers();
                  }
              }
            >
              <img
                data-e2e="downloadIcon"
                src={`${image_base_url}${downloadIcon}`}
                alt="downloadIcon"
              />
            </button>
          </div>
          <div style={{ maxWidth: "95%", margin: "auto" }}>
            <AllUsersTable
              data={data}
              sortBy={sortBy}
              orderBy={orderBy}
              changeOrder={(sort) => {
                if (sortBy !== sort) {
                  setOrderBy("desc");
                  setSortBy(sort);
                } else {
                  setOrderBy(orderBy === "desc" ? "asc" : "desc");
                }
              }}
              handleShowMore={() => {
                let newPage = page + 1;
                setPage(newPage);
                getAllUsers(newPage);
              }}
              setDeletingId={setDeletingId}
              showMoreButton={totalCount && data && data.length < totalCount}
            />
          </div>
        </div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
      <DeletingAlert
        deletingId={deletingId ? deletingId : null}
        onHide={() => {
          setDeletingId(null);
        }}
        image_base_url={image_base_url}
        msg="Are you sure you want to delete selected user? This action cannot be undone."
        onDeleteClickHandler={() => {
          deleteUser();
        }}
      />
      <Dialog
        open={userFilters}
        onClose={handleUserFilters}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <UserTransactionFilter
          handleTransactionFilters={handleUserFilters}
          adminRole={adminRole}
          handleSave={handleSave}
          setDateSelected={setDateSelected}
          dateSelected={dateSelected}
          paymentProviderList={paymentProviderList}
          setPaymentProviderList={setPaymentProviderList}
          selectedPaymentProvider={selectedPaymentProvider}
          setSelectedPaymentProvider={setSelectedPaymentProvider}
          resetFilters={resetFilters}
        />
      </Dialog>
      <Dialog
        open={downloadCsv}
        onClose={handleCloseDownloadCsv}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DownloadCsv
          handleCloseDownloadCsv={handleCloseDownloadCsv}
          data={allData}
          resetFilters={resetFilters}
          name="users"
          data-e2e="transactions"
        />
      </Dialog>
    </div>
  );
};

export default Users;

const DeletingAlert = (props) => {
  return (
    <div className="merchant_dailog_screen">
      <Dialog
        open={props.deletingId ? true : false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
      >
        <div>
          <Modal
            show={true}
            className="ClearAlertModal deleteAlertModal ClearAlertModals"
            onHide={() => {
              props.onHide();
            }}
          >
            <Modal.Body className="ClearAlertModalBody">
              <div className="ClearAlertModalIconWrap">
                <img
                  src={`${props.image_base_url}${deleteIcon}`}
                  onClick={() => {
                    props.onHide();
                  }}
                  alt="resetIcon"
                  className="ClearAlertModalIcon"
                />
              </div>
              <div className="ClearAlertModalTitle">Are you sure?</div>
              <div className="ClearAlertModalDescription">{props.msg}</div>
              <div
                className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
                style={{ background: "#E02020" }}
                onClick={() => {
                  props.onDeleteClickHandler();
                }}
                data-e2e="clear"
              >
                Delete
              </div>
              <div
                className="ClearAlertModalGoBackBtn"
                onClick={() => {
                  props.onHide();
                }}
              >
                Go Back
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Dialog>
    </div>
  );
};
