import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { call } from '../../../../../config/axios';
import { UPDATE_SERVICE_NAME } from '../../../../../config/endpoints';
import localDb from '../../../../../localDb';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


const EditCardAquireService = (props) => {
    const { paymentProviderList } = props
    let timeout = null;
    const [fraudServiceName, setFraudServiceName] = useState(props?.editAntiFraudServiceData?.acquireRoutingServiceName)
    const [loading, setLoading] = useState(false)
    const [errorField, setErrorField] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const base_url = useSelector((state) => state.config.api_url);
    const [selectedPaymentProvider, setSelectedPaymentProvider] = useState(props?.editAntiFraudServiceData?.paymentProvider)
    const [ftdToTrustedCount, setFtdToTrustedCount] = useState(props?.editAntiFraudServiceData?.ftdToTrustedCount)

    const validateFrom = () => {
        if (!fraudServiceName || fraudServiceName === "") {
            setErrorField("fraudServiceName")
            setErrorMessage("Service name is Required")
        }
    }
    const handleChange = (e) => {
        setFraudServiceName(e.target.value)
        if (e.target.value === '') {
            setErrorField("fraudServiceName")
            setErrorMessage("Service name is required")
        } else {
            setErrorField(null)
            setErrorMessage(null)
        }

    };

    const handleEditFraudService = () => {
        validateFrom()
        if (errorField === null && errorMessage === null) {
            timeout = setTimeout(async () => {
                if (errorField === null && errorMessage === null) {
                    let payload = {
                        email: localDb.getVal('email'),
                        _id: props.editAntiFraudServiceData._id,
                        fraudServiceName,
                        ftdToTrustedCount,
                        paymentProvider: selectedPaymentProvider ? selectedPaymentProvider : ""
                    }
                    setLoading(true)
                    await call(
                        {
                            ...UPDATE_SERVICE_NAME,
                            url: base_url + UPDATE_SERVICE_NAME.url,
                        },
                        payload
                    ).then((res) => {
                        setLoading(false)
                        if (res.status === 200) {
                            props.setEditAntiFraudServiceData({})
                            props.handleClose()
                            props.getAntiFraud()
                        }
                    }).catch((err) => {
                        setLoading(false)
                        if (err.response.data.err.code === 11000) {
                            setErrorMessage("Service name should be unique!")
                            setErrorField("fraudServiceName")
                        }
                        else {
                            setErrorMessage("Some thing went wrong..Please check")
                            setErrorField("fraudServiceName")
                        }
                        console.log(err)
                    })
                }
            })
        } else (
            console.log(errorField, errorMessage)
        )
    }


    return (
        <div>
            <div className="myAccoutDetailsMain">
                <div className="myAccoutDetailsCard">
                    <div className="d-flex justify-content-between">
                        <h5 className="myAccoutOptModalHeading card-title text-center">
                            Edit {' '}
                        </h5>
                        <div onClick={props.handleClose}>
                            <ArrowBackIcon size={24} />
                        </div>
                    </div>
                    <form>
                        <div className="form-group myAccoutOptModal_form_group">
                            <label
                                className="myAccoutOptModalLable"
                                htmlFor="acquirerPaymentProvider"
                            >
                                Payment Provider Name
                            </label>
                            <Select
                                displayEmpty
                                labelId="demo-simple-select-helper-label"
                                style={{ width: "370px", boxSizing: "border-box", height: "40px", borderRadius: "20px", cursor: "pointer", backgroundColor: "#FFFFFF" }}
                                className="from-control inputdropdown"
                                value={selectedPaymentProvider}
                                id="acquirerPaymentProvider"
                                name="transactionType"
                                onChange={(e) => setSelectedPaymentProvider(e.target.value)}
                                data-e2e="Select transaction type"
                            >
                                {selectedPaymentProvider === "" && (
                                    <MenuItem value="" disabled>
                                        Select Payment Provider
                                    </MenuItem>
                                )}
                                {paymentProviderList && paymentProviderList
                                    .map((item,index) => (
                                        <MenuItem value={item._id} key={index} data-e2e={item._id}>{item.firstName} {item.lastName}</MenuItem>
                                    ))}
                            </Select>
                        </div>
                        <div className="form-group myAccoutOptModal_form_group">
                            <label
                                className="myAccoutOptModalLable"
                                htmlFor="exampleFormControlSelect3"
                            >
                                FTD To Trusted Count
                            </label>
                            <input
                                type="number"
                                value={ftdToTrustedCount}
                                className="form-control inputdropdown"
                                id="exampleFormControlSelect3"
                                placeholder="FTD To Trusted Count"
                                name="ftdToTrustedCount"
                                onChange={(e) => setFtdToTrustedCount(e.target.value)}
                            />
                        </div>
                        <div className="form-group myAccoutOptModal_form_group">
                            <label
                                className="myAccoutOptModalLable"
                                htmlFor="exampleFormControlSelect3"
                            >
                                Service Name
                            </label>
                            <input
                                type="text"
                                value={fraudServiceName}
                                className="form-control inputdropdown"
                                id="exampleFormControlSelect3"
                                placeholder="Service Name"
                                name="fraudServiceName"
                                onChange={(e) => {
                                    e.preventDefault();
                                    handleChange(e)
                                }}
                            />
                        </div>
                        {errorField !== null && errorMessage !== null ? <div className="text-red-700 pt-8">{"Error :  " + errorMessage}</div> : null}
                        <div className="d-flex justify-content-center">
                            <div
                                className={`submit ${errorMessage !== null ? 'inactive' : ''
                                    }`}
                                onClick={() => {
                                    handleEditFraudService()
                                }}
                            >
                                Edit
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditCardAquireService;