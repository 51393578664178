import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  CONFIRM_MERCHANT_SWAP_TRANSACTION,
  CREATE_MERCHANT_SWAP_TRANSACTION,
  CRYPTO_AMOUNT_SWAP
} from "../../../../config/endpoints";
const closeImage = "/cryptonpay/crytoicons/close.png";
const exchangeIcon = "/cryptonpay/crytoicons/exchange-balance.svg";

function ExchangeModel(props) {
  const { image_base_url, onClose, base_url, email, merchantEmail, merchantId, selectedCoin, setLoading, getMerchantBalances } = props;
  const { cryptoBalanceList } = props.data;
  const { id } = useParams();
  const [exchangeFromDropDown, setExchangeFromDropDown] = useState(false);
  const [exchangeToDropDown, setExchangeToDropDown] = useState(false);
  const [fromCrytpo, setFromCrypto] = useState(selectedCoin);
  const [toCryto, setToCrypto] = useState(null);
  const [fromAmount, setFromAmout] = useState(selectedCoin.amount);
  const [toAmount, setToAmount] = useState("");
  const [exchangeRate, setExchangeRate] = useState("");
  const [conversitionRateObj, setConversionRateObj] = useState([]);
  const [error, setError] = useState("");


  const confirmSwapTransaction = async (transactionId, merchantEmail) => {
    setLoading(true);
    await call(
      {
        ...CONFIRM_MERCHANT_SWAP_TRANSACTION,
        url: base_url + CONFIRM_MERCHANT_SWAP_TRANSACTION.url,
      },
      {
        email: email,
        transactionId: transactionId,
        merchantEmail: merchantEmail,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          onClose();
          getMerchantBalances();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const handleExchange = async () => {
    setLoading(true);
    await call(
      {
        ...CREATE_MERCHANT_SWAP_TRANSACTION,
        url: base_url + CREATE_MERCHANT_SWAP_TRANSACTION.url,
      },
      {
        email: email,
        merchantId: merchantId,
        blockChain: fromCrytpo.cryptoName,
        fromAmount: fromAmount,
        toAmount: toAmount,
        fromCrypto: fromCrytpo.coin,
        toCrypto: toCryto.coin,
        exchangeRate: exchangeRate,
        exchangeSpread: conversitionRateObj.exchangeSpread,
      }
    )
      .then((res) => {
        if (res && res.data && res.data.status && res.data.status.code === "MerchantSwapFlow:CreateSwapRequest") {
          confirmSwapTransaction(res.data.transactionId, merchantEmail);
        } else if (res && res.data && res.data.msg && res.data.msg !== "") {
          setError(res.data.msg)
          setLoading(false);
        } else {
          setError("Create Exchange Transaction Failed!")
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const getCryptoSwap = async () => {
    setLoading(true);

    await call(
      {
        ...CRYPTO_AMOUNT_SWAP,
        url: base_url + CRYPTO_AMOUNT_SWAP.url,
      },
      {
        fromCurrency: fromCrytpo.coin || cryptoBalanceList[0].coin,
        toCurrency: toCryto.coin,
        email: email,
        merchantId: id,
        fromMerchant: true,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setExchangeRate(res.data.amount);
          let amount = res.data.amount * (fromAmount);
          if (fromAmount !== 0) {
            setToAmount(amount);
          } else {
            setToAmount(0);
          }
          setConversionRateObj(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        logoutAfterError(err)
      });
  };


  useEffect(() => {
    if (toCryto !== null) {
      getCryptoSwap();
    }
  }, [fromCrytpo, toCryto, fromAmount]);

  let disabled = toCryto === null ? true : false || error !== "" || fromAmount == 0;

  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <div></div>
        <div className="filter_heading">Exchange</div>
        <div
          className="filter_close_button"
          data-e2e="filter_close_button"
          onClick={() => {
            onClose();
          }}
        >
          <img
            src={`${image_base_url}${closeImage}`}
            alt="closeImage"
            data-e2e="close-icon"
          />
        </div>
      </div>
      <div className="exchange-model-flex">
        <div className="FilterModalInputGroup exchange-mr-20">
          <div className="FilterModalInputLabel">CryptoCurrency</div>
          <div
            className="FilterModalInputDropdown"
            data-e2e="FilterModalInputDropdown"
            onClick={() => {
              setExchangeFromDropDown(true);
              setExchangeToDropDown(false);
            }}
          >
            <div
              className="FilterModalInputText"
              data-e2e="FilterModalInputText"
            >
              {fromCrytpo.coin}
            </div>
            <i
              className="fa fa-chevron-down FilterModalInputDropIcon"
              data-e2e="FilterModalInputDropIcon"
            />
          </div>
          {exchangeFromDropDown && (
            <div
              className="FilterModalCurrencyDropdownlist exchange-rate-options"
              data-e2e="FilterModalCurrencyDropdownlist"
            >
              <div
                className="FM_currencyDrop_All"
                data-e2e="FM_currencyDrop_All"
                onClick={() => {
                  setExchangeFromDropDown(false);
                }}
              >
                <span
                  className="FM_currencyDrop_AllSelector"
                  data-e2e="FM_currencyDrop_AllSelector"
                >
                  {fromCrytpo.coin}
                </span>
                <span className="FilterModalInputDropUpIconWrap">
                  <img
                    src={`${image_base_url}${fromCrytpo.icon}`}
                    alt="checkbox"
                    width={20}
                  />
                </span>
              </div>
              {cryptoBalanceList.map((crypto, index) => {
                return (
                  <div
                    key={index}
                    className="currencyListItemWrap"
                    data-e2e="currencyListItemWrap"
                    onClick={() => {
                      setFromCrypto(crypto);
                      setFromAmout(crypto.amount);
                      setExchangeFromDropDown(false);
                      setError("");

                    }}
                  >
                    <div className="currencyListIconNameWrap">
                      <div className="currencyList_Name">{crypto.coin}</div>
                    </div>
                    <img
                      src={`${image_base_url}${crypto.icon}`}
                      alt="checkbox"
                      width={20}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel update-merchant-label">
            Amount
          </div>
          <div className="FilterModalInputDropdown merchant-update-input">
            <input
              className="bg-white"
              placeholder="Enter amount"
              name="fromCrypto"
              min={0}
              type="number"
              value={fromAmount}
              onChange={(e) => {
                if ((fromCrytpo.amount < Number(e.target.value))) {
                  setFromAmout(e.target.value);
                  setError(`Insufficient funds in account.`);
                }else if(Number(e.target.value) == 0){
                  setFromAmout(0);
                  setError(`Amount must be greater than zero.`);
                } else {
                  setFromAmout(e.target.value);
                  setError("");
                }

              }}
            />
          </div>
        </div>
      </div>
      <div className="exchange-horizontal-line">
        <div className="exchange-rate-img">
          <img src={`${image_base_url}${exchangeIcon}`} alt="exchange" />
        </div>
      </div>
      <div className="exchange-model-flex">
        <div className="FilterModalInputGroup exchange-mr-20">
          <div className="FilterModalInputLabel">CryptoCurrency</div>
          <div
            className="FilterModalInputDropdown"
            data-e2e="FilterModalInputDropdown"
            onClick={() => {
              setExchangeToDropDown(true);
              setExchangeFromDropDown(false);
            }}
          >
            <div
              className="FilterModalInputText"
              data-e2e="FilterModalInputText"
            >
              {toCryto !== null ? toCryto.coin : "Select Coin"}
            </div>
            <i
              className="fa fa-chevron-down FilterModalInputDropIcon"
              data-e2e="FilterModalInputDropIcon"
            />
          </div>
          {exchangeToDropDown && (
            <div
              className="FilterModalCurrencyDropdownlist exchange-rate-options"
              data-e2e="FilterModalCurrencyDropdownlist"
            >
              <div
                className="FM_currencyDrop_All"
                data-e2e="FM_currencyDrop_All"
                onClick={() => {
                  setExchangeToDropDown(false);
                }}
              >
                <span
                  className="FM_currencyDrop_AllSelector"
                  data-e2e="FM_currencyDrop_AllSelector"
                >
                  {toCryto !== null ? toCryto.coin : "Select Crypto"}
                </span>
                {toCryto !== null ? (<span className="FilterModalInputDropUpIconWrap">
                  <img
                    src={`${image_base_url}${toCryto.icon}`}
                    alt="checkbox"
                    width={20}
                  />
                </span>) : null}

              </div>
              {cryptoBalanceList.map((crypto, index) => {
                return (
                  <div
                    key={index}
                    className="currencyListItemWrap"
                    data-e2e="currencyListItemWrap"
                    onClick={() => {
                      setToCrypto(crypto);
                      setExchangeToDropDown(false);
                      if ((fromCrytpo.amount < fromAmount)) {
                        setError(`Insufficient funds in account.`);
                      } else {
                        setError("");
                      }

                    }}
                  >
                    <div className="currencyListIconNameWrap">
                      <div className="currencyList_Name">{crypto.coin}</div>
                    </div>
                    <img
                      src={`${image_base_url}${crypto.icon}`}
                      alt="checkbox"
                      width={20}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel update-merchant-label">
            You get
          </div>
          <div className="FilterModalInputDropdown merchant-update-input cursor-not-allowed">
            <input
              className="bg-white"
              placeholder="to amount"
              name="toAmount"
              type="number"
              disabled
              value={parseFloat(toAmount).toFixed(8)}
            />
          </div>
        </div>
      </div>
      <div className="FilterModalInputGroup">
        <div className="FilterModalInputLabel update-merchant-label">Rate</div>
        <div className="FilterModalInputDropdown merchant-update-input">
          <input
            className="cursor-not-allowed bg-white"
            value={toCryto !== null ? `1 ${fromCrytpo.coin} = ${exchangeRate} ${toCryto.coin}` :
              `Select both the currencies`}
            disabled
          />
        </div>
      </div>
      {error !== "" && <div className="d-flex justify-content-center text-danger pt-3 h6">
        {error}
      </div>}
      <button
        disabled={disabled}
        className={!disabled ? "FilterModalSave border-0" : "FilterModalSave bg-secondary border-0"}
        data-e2e="FilterModalSave"
        onClick={() => {
          handleExchange();
        }}
      >
        EXCHANGE
      </button>
    </div>
  );
}

export default ExchangeModel;
