import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { call } from '../../../../../config/axios';
import { ADD_NEW_BLOCKED_CARD } from "../../../../../config/endpoints"
import localDb from '../../../../../localDb';
import {maskCardNumber} from '../../../../common/CommonUtils'
import {
    Dialog
} from '@mui/material';


const AddBlockedCard = ({ open, setOpen, acquireId, cardList }) => {
    const [cardNumber, setCardNumber] = useState("")
    const [cardNumberLast4Digits, setCardNumberLast4Digits] = useState("")
    const [error, setError] = useState(null)
    const baseUrl = useSelector((state) => state.config.api_url);
    const handleAddNewBlockedCard = async () => {
        const isNumeric = /^[0-9]+$/.test(cardNumber); // Ensure that it contains only numbers
        const isNumericLast4 = /^[0-9]+$/.test(cardNumberLast4Digits); // Ensure that it contains only numbers
        const maskedNumber=maskCardNumber(cardNumber + cardNumberLast4Digits)
        const isBlocked = cardList.includes(maskedNumber)
        if(isBlocked){
            setError("Card is already blocked")
            return
        }
        if (!isNumeric) {
            setError("Card Number should be numbers only")
            return
        }
        if (!isNumericLast4) {
            setError("Card Number Last4 digits should be numbers only")
            return
        }
        const isValid = /^\d{6}$/.test(cardNumber);
        if (!isValid) {
            setError("Invalid first 6 digits")
            return
        }
        const isValidLast4 = /^\d{4}$/.test(cardNumberLast4Digits);
        if (!isValidLast4) {
            setError("Invalid last 4 digits")
            return
        }
        let payload = {
            email: localDb.getVal('email'),
            cardNumber: maskedNumber,
            acquireId: acquireId
        }
        try {
            const response = await call(
                {
                    ...ADD_NEW_BLOCKED_CARD,
                    url: baseUrl + ADD_NEW_BLOCKED_CARD.url,
                },
                payload
            )
            if (response) {
                window.location.reload()
            }
        } catch (err) {
            console.log("====Error in Api Call ====", err)
        }
    }
    return (
        <Dialog
            disableEnforceFocus
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}>
            <div className='addBlockCardContainer bg-white min-h-[300px] min-w-[400px] p-4 h-full'>
                <div className='font-bold text-[22px] mb-8 '>Add Card to Blocked List</div>
                <div>
                    <div className='font-bold text-[15px] mb-2'>Blocked Card</div>
                    <input name='cardNumber' type='number' minLength={6} maxLength={6} value={cardNumber} onChange={(e) => { setCardNumber(e.target.value); setError(null) }} placeholder='Enter the first 6 digits' className="form-control myAccoutOptModalLable_input_wrap rounded-full" />
                    <input name='cardNumber' type='number' minLength={4} maxLength={4} value={cardNumberLast4Digits} onChange={(e) => { setCardNumberLast4Digits(e.target.value); setError(null) }} placeholder='Enter the last 4 digits' className="form-control myAccoutOptModalLable_input_wrap rounded-full" />
                </div>
                <div className='text-red-700 mt-4'>{error}</div>
                <button
                    onClick={handleAddNewBlockedCard}
                    className='px-2 font-bold py-2 mt-8 w-full border rounded-full theme-btn'>Add</button>
            </div>
        </Dialog>
    )
}
export default AddBlockedCard