import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import Dialog from "@mui/material/Dialog";
import { call, logoutAfterError } from "../../../../config/axios";
import localDb from "../../../../localDb";
import {
    UPDATE_B2C_USER
} from "../../../../config/endpoints";
const closeImage = "/cryptonpay/crytoicons/close.png";

const UpdateUserDetails = ({ open, onClose, data, setLoading, getUsersDetails }) => {
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const base_url = useSelector((state) => state.config.api_url);
    const email = localDb.getVal("email");
    const [b2cUserData, setB2cUserData] = useState(data);
    const [error, setError] = useState("");

    const handleChange = (e) => {
        setB2cUserData({ ...b2cUserData, [e.target.name]: e.target.value });
        setError('');
    };

    const updateB2CUser = async () => {
        let paylod = {
            firstName: b2cUserData?.firstName?.trim(),
            lastName: b2cUserData?.lastName?.trim(),
            domainNameUrl: b2cUserData?.domainNameUrl,
            surname: b2cUserData?.surname?.trim(),
            country: b2cUserData?.country?.trim(),
            city: b2cUserData?.city?.trim(),
            zipCode: b2cUserData?.zipCode?.trim(),
            mobile: b2cUserData?.mobile?.trim(),
            addressTwo: b2cUserData?.addressTwo?.trim(),
            addressOne: b2cUserData?.addressOne?.trim(),
            email: b2cUserData?.email?.trim()
        };
        setLoading(true);
        await call(
            {
                ...UPDATE_B2C_USER,
                url: base_url + UPDATE_B2C_USER.url,
            },
            { paylod, email }
        )
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    getUsersDetails();
                    onClose();
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.message, "err");
                logoutAfterError(err)
            });
    };


    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div
                    className="create_account_container"
                    style={{ width: "520px", marginTop: "0px" }}
                >
                    <div className="create_account_header">
                        <div></div>
                        <div className="create_account_heading">{`Update User Details`}</div>
                        <div className="filter_close_button" onClick={onClose}>
                            <img
                                src={`${image_base_url}${closeImage}`}
                                alt="closeImage"
                            />
                        </div>
                    </div>

                    <div className="create_account_form">
                        <div style={{ margin: "20px 0px" }}>
                            <div className="update_user">
                                <div className="input_row">
                                    <div className="input_wrap">
                                        <div className="input_lable">{`First Name`}</div>
                                        <input type="text" placeholder='Emerald'
                                            name="firstName"
                                            defaultValue={b2cUserData && b2cUserData.firstName}
                                            onChange={(e) => handleChange(e)} />
                                    </div>

                                    <div className="input_wrap">
                                        <div className="input_lable">{`Middle Name`}</div>
                                        <input placeholder='Junior' type="text" name="lastName"
                                            defaultValue={b2cUserData && b2cUserData.lastName}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="input_row">
                                    <div className="input_wrap">
                                        <div className="input_lable">{`Surname`}</div>
                                        <input type="text" placeholder='Hopkins' name="surname"
                                            defaultValue={b2cUserData && b2cUserData.surname}
                                            onChange={(e) => handleChange(e)} />
                                    </div>

                                    <div className="input_wrap">
                                        <div className="input_lable">{`Date of Birth`}</div>
                                        <input type="text" placeholder='03/04/1999' name="dob"
                                            defaultValue={b2cUserData && b2cUserData.dob}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="input_row">
                                    <div className="input_wrap">
                                        <div className="input_lable">{`Address 1`}</div>
                                        <input type="text" placeholder='241' name="addressOne"
                                            defaultValue={b2cUserData && b2cUserData.addressOne}
                                            onChange={(e) => handleChange(e)} />
                                    </div>

                                    <div className="input_wrap">
                                        <div className="input_lable">{`Address 2`}</div>
                                        <input type="text" placeholder='Ravenscourt Road' name="addressTwo"
                                            defaultValue={b2cUserData && b2cUserData.addressTwo}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="input_row">
                                    <div className="input_wrap">
                                        <div className="input_lable">{`City`}</div>
                                        <input type="text" placeholder='South Yorkshire' name="city"
                                            defaultValue={b2cUserData && b2cUserData.city}
                                            onChange={(e) => handleChange(e)} />
                                    </div>

                                    <div className="input_wrap">
                                        <div className="input_lable">{`State/Region`}</div>
                                        <input type="text" placeholder='Lancashire' name="state"
                                            defaultValue={b2cUserData && b2cUserData.state}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="input_row">
                                    <div className="input_wrap">
                                        <div className="input_lable">{`Postcode`}</div>
                                        <input type="text" placeholder='WE 162' name="zipCode"
                                            defaultValue={b2cUserData && b2cUserData.zipCode}
                                            onChange={(e) => handleChange(e)} />
                                    </div>

                                    <div className="input_wrap">
                                        <div className="input_lable">{`Country`}</div>
                                        <input type="text" placeholder='United Kingdom' name="country"
                                            defaultValue={b2cUserData && b2cUserData.country}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="input_row">
                                    <div className="input_wrap w-100">
                                        <div type="text" className="input_lable">{`Email`}</div>
                                        <input placeholder='emerald@gmail.com' name="email"
                                            value={b2cUserData && b2cUserData.email}
                                            onChange={() => { }}
                                        />
                                    </div>
                                </div>

                                <div className="input_row">
                                    <div className="input_wrap w-100">
                                        <div type="text" className="input_lable">{`Phone Number`}</div>
                                        <input placeholder='(-441) 123123456' name="mobile"
                                            defaultValue={b2cUserData && b2cUserData.mobile}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                {error != "" && (
                                    <div className="add_note_error">{error}</div>
                                )}

                                <div className="d-flex justify-content-center pt-4">
                                    <button
                                        onClick={() => updateB2CUser()}
                                        type="button"
                                        className="btn btn-primary btn-md rounded-pill add-btn w-100"
                                    >
                                        {"Save"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default UpdateUserDetails