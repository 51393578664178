import React from "react";
import App from "./App.js";
import createStore from "./helpers/createStore";
import { Provider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { createRoot } from 'react-dom/client';
const initialState =
  window && process.env.NODE_ENV !== "development"
    ? {
      config: {
        api_url: window.INITIAL_STATE?.config?.REACT_APP_API_URL,
        payment_api_url: window.INITIAL_STATE?.config?.REACT_APP_API_URL,
        image_base_url: window.INITIAL_STATE?.config?.IMAGE_BASE_URL + "/images",
        btc_mainnet: window.INITIAL_STATE?.config?.BTC_MAINNET,
        btc_testnet: window.INITIAL_STATE?.config?.BTC_TESTNET,
        eth_mainnet: window.INITIAL_STATE?.config?.ETH_MAINNET,
        eth_sepolia: window.INITIAL_STATE?.config?.ETH_TESTNET,
        ltc_mainnet: window.INITIAL_STATE?.config?.LTC_MAINNET,
        xrp_mainnet: window.INITIAL_STATE?.config?.XRP_MAINNET,
        trx_mainnet: window.INITIAL_STATE?.config?.TRX_TRON_NET_ADDRESS,
        trx_testnet: window.INITIAL_STATE?.config?.TRX_TRON_NET_ADDRESS,
        adminLogin2FA: window.INITIAL_STATE?.config?.ADMIN_LOGIN_2FA
      }
    }
    : {
      config: {
        api_url: "http://localhost:8081",
        // api_url: "https://dev.cryptonyte.org",
        payment_api_url: "http://localhost:8080",
        image_base_url: "https://dev.cryptonyte.org/images",
        btc_mainnet: "https://www.blockchain.com/btc/address",
        btc_testnet: "https://www.blockchain.com/btc-testnet/address",
        eth_mainnet: "https://etherscan.io/address",
        eth_sepolia: "https://sepolia.etherscan.io/address",
        ltc_mainnet: "https://blockchair.com/litecoin/address",
        xrp_mainnet: "https://blockchair.com/ripple/account",
        trx_mainnet: process.env.TRX_TRON_NET_ADDRESS,
        trx_testnet: process.env.TRX_TRON_NET_ADDRESS,
        adminLogin2FA: "false"
      },
    };

const store = createStore(initialState);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <App />
    </ErrorBoundary>
  </Provider>
);
