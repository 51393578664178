import React, { useState, useEffect } from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Loading from '../../../common/Loading';
import localDb from '../../../../localDb';
import { GET_CRYPTOS ,GET_B2C_USER_TRANSACTIONS} from '../../../../config/endpoints';
import { call, logoutAfterError } from '../../../../config/axios';
import moment from 'moment';
import { setHeader } from '../../../Redux/actions/commonAction';
import UserTransactionTable from './UserTransactionTable';
import UserTransactionFilter from '../B2C/UserTransactionFilter';
import DownloadCsv from '../CommonPages/DownloadCsv';
import SettingsIcon from '@mui/icons-material/Settings';
import useDebounce from '../../global/useDebounce';
const searchicon = '/cryptonpay/crytoicons/searchicon1.png';
const filterIcon = "/cryptonpay/filter_icon.svg";
const downloadIcon = "/cryptonpay/download_icon.svg";

function UserTransaction() {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal('email');
  const base_url = useSelector((state) => state.config.api_url);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearhText] = useState('');
  const [orderBy, setOrderBy] = useState('desc');
  const [sortBy, setSortBy] = useState('createdDate');
  const [fromDate, setFromDate] = useState(
    moment().subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  );
  const [toDate, setToDate] = useState(
    moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  );
  const [dateSelected, setDateSelected] = useState(false);
  const [transactionFitlers, setTransactionFilters] = useState(false);

  const [statusList, setStatusList] = useState([
    'Success',
    'Pending',
    'Rejected'
  ]);
  const [selectedStatusList, setSelectedStatusList] = useState([
    'Success',
    'Pending',
    'Rejected'
  ]);
  const [type, setType] = useState(["receive", "send","buy"]);
  const [selectedType, setSelectedType] = useState(["receive", "send","buy"]);
  const [cryptos, setCryptos] = useState([]);
  const [selectedCryptos, setSelectedCryptos] = useState([]);
  const [minFiatAmount, setMinFiatAmount ] =useState(0);
  const [maxFiatAmount, setMaxFiatAmount ] =useState(1);
  const [save, setSave] = useState(false);

  const handleTransactionFilters = () => {
    setTransactionFilters(false);
  };
  const [downloadCsv, setDownloadCsv] = useState(false);

  const handleCloseDownloadCsv = () => {
    setDownloadCsv(false);
  };

  const handleSave = () => {
    setSave(!save);
  };
  const resetFilters = () => {
    setSearhText('');
    setOrderBy('desc');
    setSortBy('status.updated');
    setPage(1);
    setDateSelected(false);
    setTotalCount(0);
    setSelectedStatusList([
      'Success',
      'Pending',
    ]);
    setFromDate([
      moment().subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    ]);
    setToDate([moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')]);
    setSelectedCryptos([]);
    setSelectedType([]);
    setMinFiatAmount(0);
    setMaxFiatAmount(1);
  };
  
  const getCryptos = async () => {
    await call(
      {
        ...GET_CRYPTOS,
        url: base_url + GET_CRYPTOS.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setCryptos(res.data.cryptos);
          let cryptos = res.data.cryptos.map((crypto) => {
            return crypto.symbol;
          });
          setSelectedCryptos(cryptos);
        }
      })
      .catch((err) => {
        console.log(err.message, 'UserTransaction > getCryptos > err')
        logoutAfterError(err)
      });
  };

  useEffect(() => {
    getCryptos();
  }, []);

  const getTransactions = async (page) => {
    let pageNo = 1;
    if (page) {
      pageNo = page;
    }
    let filterParams = {
      email: email,
      transactionId: searchText.toString(),
      cryptoId: searchText.toString(),
      userEmail: searchText.toString(),
      orderBy: orderBy,
      sortBy: sortBy,
      page: pageNo,
      status:
        selectedStatusList.length === 0 ||
        selectedStatusList.length === statusList.length
          ? 'All'
          : selectedStatusList,
      CryptoCurrency:
        selectedCryptos.length === 0 ||
        selectedCryptos.length === cryptos.length
          ? 'All'
          : selectedCryptos,  
      type:
          selectedType.length === 0 ||
            selectedType.length === type.length
              ? 'All'
              : selectedType,
      minFiatAmount:minFiatAmount === 0 ? 'All' : minFiatAmount,
      maxFiatAmount:maxFiatAmount === 1 ? 'All' : maxFiatAmount,    
    };
    if (dateSelected) {
      filterParams = {
        ...filterParams,
        fromDate: fromDate,
        toDate: toDate,
      };
    }
    let payload = filterParams
    setLoading(true);
    await call(
      {
        ...GET_B2C_USER_TRANSACTIONS,
        url: base_url + GET_B2C_USER_TRANSACTIONS.url,
      },
      payload
    )
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          // console.log('data: ', res.data);
          if (pageNo === 1) {
            setData(res.data.b2cUserTransactionsList);
            setTotalCount(res.data.total_transactions);
          } else {
            let list = data.concat(res.data.b2cUserTransactionsList);
            setData(list);
            setTotalCount(res.data.total_transactions);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, 'UserTransaction > getTransactions > err');
        logoutAfterError(err)
      });
  };

  const debouncedSearchText = useDebounce(searchText);
  useEffect(() => {
    dispatch(setHeader('User Transactions'));
    getTransactions()
  }, [debouncedSearchText, orderBy, sortBy, save]);

  const name = 'User Transactions';

  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
        <CommonHeader name={name}  />
        <div>
          <div className="transaction_filter">
            <div className="transactionsFiltersWrap">
              <div className="transactionsFilters">
                <div className="transactionsFiltersItem transactionsSearchFilter">
                  <div className="transactionsFilterTextWrap">
                    <input
                      type="text"
                      className="transactionsFilterInput"
                      value={searchText}
                      name="searchText"
                      data-e2e="search-textbox"
                      onChange={(e) => {
                        setSearhText(e.target.value);
                        setPage(1);
                      }}
                      placeholder="Search Merchant, Transaction ID, Merchant Transaction ID or Merchant User ID"
                    />
                  </div>
                  <div className="transactionsFilterSingleIconWrap">
                    <img
                      src={`${image_base_url}${searchicon}`}
                      alt="searchicon"
                      className="transactionsFilterSingleIcon"
                      data-e2e="searchIcon"
                      onClick={() => {
                        getTransactions();
                        setPage(1);
                      }}
                    />
                  </div>
                </div>

                <div
                  className="filterBtn"
                  data-e2e="filterBtn"
                  onClick={() => {
                    setTransactionFilters(true);
                  }}
                >
                  <img
                    data-e2e="filterIcon"
                    src={`${image_base_url}${filterIcon}`}
                    alt="filterIcon"
                    className="filterBtnIcon"
                  />
                </div>
                <Dialog
                  open={transactionFitlers}
                  onClose={handleTransactionFilters}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <UserTransactionFilter
                    handleTransactionFilters={handleTransactionFilters}
                    statusList={statusList}
                    setStatusList={setStatusList}
                    selectedStatusList={selectedStatusList}
                    setSelectedStatusList={setSelectedStatusList}
                    fromDate={moment(fromDate)}
                    toDate={moment(toDate)}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    handleSave={handleSave}
                    setDateSelected={setDateSelected}
                    dateSelected={dateSelected}
                    resetFilters={resetFilters}
                    cryptos={cryptos}
                    selectedCryptos={selectedCryptos}
                    setSelectedCryptos={setSelectedCryptos}
                    type={type}
                    setMinFiatAmount={setMinFiatAmount}
                    setMaxFiatAmount={setMaxFiatAmount}
                    minFiatAmount={minFiatAmount}
                    maxFiatAmount={maxFiatAmount}
                    selectedType = {selectedType}
                    setSelectedType = {setSelectedType}
                  />
                </Dialog>
              </div>
            </div>
            <div
            className={`filterBtn`}
            data-e2e="filterBtn"
            style={{ marginLeft: '10px' }}
            onClick={() => setDownloadCsv(true)}
          >
            <img
              data-e2e="downloadIcon"
              src={`${image_base_url}${downloadIcon}`}
              alt="filterIcon"
              className="downloadBtnIcon"
            />
          </div>
            <Dialog
              open={downloadCsv}
              onClose={handleCloseDownloadCsv}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DownloadCsv
                handleCloseDownloadCsv={handleCloseDownloadCsv}
                data={data}
                resetFilters={resetFilters}
                name="wallets"
                data-e2e="wallets"
              />
            </Dialog>
          </div>
          <div>
            <UserTransactionTable
              table_name="Induvisual_transactions"
              data={data}
              sortBy={sortBy}
              orderBy={orderBy}
              // getTransactions={()=> { 
              //   getTransactions(1)
              // }}
              changeOrder={(sort) => {
                if (sortBy !== sort) {
                  setOrderBy('desc');
                  setSortBy(sort);
                } else {
                  setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
                }
              }}
              handleShowMore={() => {
                let newPage = page + 1;
                setPage(newPage);
                getTransactions(newPage);
              }}
              showMoreButton={totalCount && data && data.length < totalCount}
            />
          </div>
        </div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
}

export default UserTransaction;
