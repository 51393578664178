import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import {
    ADMIN_FORGOT_PASSWORD,
    ADMIN_NEW_PASSWORD,
} from "../../../../config/endpoints";
import { useSelector } from "react-redux";
import { call } from "../../../../config/axios";
import localDb from '../../../../localDb';
const AdminNewPassword = () => {
    const homeMainBG = "/home/homemainbg.png";
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const base_url = useSelector((state) => state.config.api_url);

    const { token } = useParams();
    const [userPass, setuserPass] = useState({
        newPassword: "",
        confirmNewPassword: "",
    });
    const [alertMsg, setalertMsg] = useState("");
    const [userEmail, setuserEmail] = useState("");
    const [isSuccess, setisSuccess] = useState(false);


    useEffect(() => {
        if (token) {
            localDb.setVal('token', token);
            verifyToken(token);
        }
    }, [token]);
    const verifyToken = async (token) => {
        await call(
            { ...ADMIN_FORGOT_PASSWORD, url: base_url + ADMIN_FORGOT_PASSWORD.url },
            {
                verifyToken: token,
                hostname: window.location.hostname,
            }
        ).then((data) => {
                if (data.data.success) {
                    setuserEmail(data.data.email)
                }
            })
            .catch((err) => {
                console.log(err.message, "err");
            });
    };

    const submitHandler = async (e) => {
        if (!userPass.newPassword || !userPass.confirmNewPassword) {
            setalertMsg("Please, fill in all fields");
            return;
        }
        if (userPass.newPassword !== userPass.confirmNewPassword) {
            setalertMsg("Passwords do not match");
            return;
        }
        await call(
            { ...ADMIN_NEW_PASSWORD, url: base_url + ADMIN_NEW_PASSWORD.url },
            {
                verifyToken: token,
                email: userEmail,
                newPassword: userPass.newPassword,
            }
        )
            .then((res) => {
                setisSuccess(true)
            })
            .catch((err) => {
                console.log(err.message, "err");
            });
    };
    return (
        <div className="login_container">
            <div
                className="login_body"
                style={{ backgroundImage: `url(${image_base_url}${homeMainBG})` }}
            >
                <Card
                    className="login_card"
                    style={{
                        background: "radial-gradient(circle, #0F1029 0%, #000000 100%)",
                    }}
                >
                    <div className="login-card-inner">
                        <div className="login_heading mx-5">New Password</div>
                        {
                            isSuccess ?
                                <div className="w-auto mb-3">
                                    <div className="d-flex justify-content-center  align-items-center flex-column">
                                        <svg className="" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="59" height="59" rx="29.5" fill="#ECFDF3" /><circle cx="30" cy="30" r="15" fill="#62CA7A" /><path d="M22 30.25L23.5 28.75L27.25 32.5L35.5 24.25L37 25.75L27.25 35.5L22 30.25Z" fill="white" /><rect x="0.5" y="0.5" width="59" height="59" rx="29.5" stroke="#D1FADF" /></svg>
                                        <p className="login-form-inp-title">Thanks!</p>
                                        <p className="login-form-inp-title w-50 text-center">Your password has been updated, return and log in to Cryptonadmin.</p>
                                    </div>
                                </div> :
                                <React.Fragment>
                                    <div className="login_form">
                                        <div>
                                            <label className="login-form-inp-title">
                                                <b>Enter your new password</b>
                                            </label>
                                            <div className="loginInputWrap my-2">
                                                <input
                                                    className="login_form_input"
                                                    type="password"
                                                    name="newPassword"
                                                    data-e2e="newPassword"
                                                    onChange={(e) =>
                                                        setuserPass({ ...userPass, newPassword: e.target.value })
                                                    }
                                                    value={userPass.newPassword}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="login_form">
                                        <div>
                                            <label className="login-form-inp-title">
                                                <b>Confirm new password</b>
                                            </label>
                                            <div className="loginInputWrap my-2">
                                                <input
                                                    className="login_form_input"
                                                    type="password"
                                                    name="confirmNewPassword"
                                                    data-e2e="confirmNewPassword"
                                                    onChange={(e) =>
                                                        setuserPass({
                                                            ...userPass,
                                                            confirmNewPassword: e.target.value,
                                                        })
                                                    }
                                                    value={userPass.confirmNewPassword}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-danger">{alertMsg}</p>
                                    <div
                                        className="login_button_get_started mt-5"
                                        data-e2e="login_button_get_started"
                                    >
                                        <button data-e2e="get_started" onClick={submitHandler}>
                                            Save
                                        </button>
                                    </div>
                                </React.Fragment>
                        }
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default AdminNewPassword;
