import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import localDb from '../../../../localDb';
import { CREATE_CRYPTO } from '../../../../config/endpoints';
import { connect } from 'react-redux';
import { call, logoutAfterError } from '../../../../config/axios';
import SavingAlert from '../CommonPages/SavingAlert';
let timeout = null;
const selectedCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_filled.png';
const emptyCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_empty.png';

class AddNewCrypto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addNewData: {
        cryptoId: '',
        name: '',
        blockchain: '',
        icon: '',
        requireFiatSettlementTrade: true,
        incrementSize: 0,
        enabled: true,
        addContractAddress: ''
      },
      showSavingModal: false,
      showStatusDropDown: false,
      statusData: [
        {
          name: 'ENABLED',
          value: true,
        },
        {
          name: 'DISABLED',
          value: false,
        },
      ],
      loading: false,
    };
  }

  componentWillUnmount() {
    if (timeout) {
      clearTimeout(timeout);
    }
  }

  validate = () => {
    const { cryptoId, name, blockchain, icon, incrementSize, addContractAddress } =
      this.state.addNewData;
    if (!cryptoId || cryptoId === '' || cryptoId === 'Select') {
      this.setState({
        errorMsg: 'Crypto ID is required.',
        errorField: 'cryptoId',
      });
    } else if (!name || name === '') {
      this.setState({
        errorMsg: 'Crypto Name is required.',
        errorField: 'name',
      });
    } else if (!blockchain || blockchain === '') {
      this.setState({
        errorMsg: 'Blockchain is required.',
        errorField: 'blockchain',
      });
    } else if (!icon || icon === '') {
      this.setState({ errorMsg: 'Icon path is required.', errorField: 'icon' });
    } else if (!addContractAddress || addContractAddress === '') {
      this.setState({ errorMsg: 'contract address is required.', errorField: 'addContractAddress' });
    } else if (!incrementSize || incrementSize === '') {
      this.setState({
        errorMsg: 'Increment Size is required.',
        errorField: 'incrementSize',
      });
    } else if(incrementSize <0){
       this.setState({ errorMsg: "Increment Size should be positive.", errorField: "incrementSize" })

  }
  };

  addNewCrypto = () => {
    this.setState({ addClicked: true });
    const {
      cryptoId,
      name,
      blockchain,
      icon,
      requireFiatSettlementTrade,
      incrementSize,
      enabled,
      addContractAddress,
    } = this.state.addNewData;
    this.validate();
    timeout = setTimeout(async () => {
      if (this.state.errorField === '' && this.state.errorMsg === '') {
        let payload = {
          email: localDb.getVal('email'),
          cryptoId: cryptoId.trim(),
          name: name.trim(),
          symbol: cryptoId.trim(),
          blockchain: blockchain.trim(),
          icon: icon.trim(),
          requireFiatSettlementTrade,
          incrementSize,
          enabled,
          addContractAddress: addContractAddress.trim(),
        };
        this.setState({loading: true})
      
        await call(
          {
            ...CREATE_CRYPTO,
            url: this.props.base_url + CREATE_CRYPTO.url,
          },
          payload
        )
          .then((res) => {
            if (res.status === 200) {
              this.setState({ showSavingModal: true, loading: false });
            }
          })
          .catch((err) => {
          this.setState({loading: false})
            console.log(err.message, 'err');
            alert('Failed to Add!');
            logoutAfterError(err)
          });
      }
    });
  };

  handleChange = (e) => {
    this.setState(
      {
        addNewData: {
          ...this.state.addNewData,
          [e.target.name]: e.target.value,
        },
        errorMsg: '',
        errorField: '',
      },
      () => {
        if (this.state.addClicked) {
          this.validate();
        }
      }
    );
  };

  render() {
    return (
      <div>
        <div className="myAccoutDetailsMain">
          <div className="myAccoutDetailsCard">
            <div className="d-flex justify-content-between">
              <h5 className="myAccoutOptModalHeading card-title text-center cursor-pointer">
                Add New{' '}
              </h5>
              <div onClick={this.props.handleClose}>
                <ArrowBackIcon size={24} />
              </div>
            </div>
            <form>
              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect1"
                >
                  Crypto ID
                </label>
                <input
                  type="text"
                  value={this.state.addNewData.cryptoId}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect3"
                  placeholder="Enter Crypto ID"
                  name="cryptoId"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect3"
                >
                  Crypto Name
                </label>
                <input
                  type="text"
                  value={this.state.addNewData.name}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect3"
                  placeholder="Enter Crypto Name"
                  name="name"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect3"
                >
                  Blockchain
                </label>
                <input
                  type="text"
                  value={this.state.addNewData.blockchain}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect3"
                  placeholder="Enter Blockchain"
                  name="blockchain"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect3"
                >
                  Icon
                </label>
                <input
                  type="text"
                  value={this.state.addNewData.icon}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect3"
                  placeholder="Enter Icon"
                  name="icon"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect3"
                >
                  Increment Size
                </label>
                <input
                  type="text"
                  value={this.state.addNewData.incrementSize}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect3"
                  placeholder="Enter Icon"
                  name="incrementSize"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect3"
                >
                  Add Contract Address
                </label>
                <input
                  type="text"
                  value={this.state.addNewData.addContractAddress}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect3"
                  placeholder="Add Contract Address"
                  name="addContractAddress"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div
                className="FilterModalInputGroup"
                style={{ marginBottom: '20px' }}
              >
                <div className="FilterModalInputLabel">Status</div>
                <div
                  className="FilterModalInputDropdown"
                  data-e2e="FilterModalInputDropdown"
                  onClick={() => {
                    this.setState({
                      showStatusDropDown: true,
                    });
                  }}
                >
                  <div
                    className="FilterModalInputText"
                    data-e2e="FilterModalInputText"
                  >
                    {this.state.addNewData.enabled ? 'ENABLED' : 'DISABLED'}
                  </div>
                  <i
                    className="fa fa-chevron-down FilterModalInputDropIcon"
                    data-e2e="FilterModalInputDropIcon"
                  />
                </div>
                {this.state.showStatusDropDown && (
                  <div
                    className="FilterModalCurrencyDropdownlist"
                    data-e2e="FilterModalCurrencyDropdownlist"
                  >
                    <div
                      className="FM_currencyDrop_All"
                      data-e2e="FM_currencyDrop_All"
                      onClick={() => {
                        this.setState({
                          showStatusDropDown: false,
                        });
                      }}
                    >
                      <span
                        className="FM_currencyDrop_AllSelector"
                        data-e2e="FM_currencyDrop_AllSelector"
                      >
                        {this.state.addNewData.enabled ? 'ENABLED' : 'DISABLED'}
                      </span>
                      <span className="FilterModalInputDropUpIconWrap">
                        <i
                          data-e2e="allowableCryptoDropDownIcon"
                          className="fa fa-chevron-up FilterModalInputDropUpIcon"
                        />
                      </span>
                    </div>
                    {this.state.statusData &&
                      this.state.statusData.map((ele, index) => {
                        return (
                          <div
                            key={index}
                            className="currencyListItemWrap"
                            name="blockchain"
                            data-e2e={ele.name}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  showStatusDropDown: false,
                                  addNewData: {
                                    ...this.state.addNewData,
                                    ['enabled']: ele.value,
                                  },
                                  errorMsg: '',
                                  errorField: '',
                                },
                                () => {
                                  if (this.state.addClicked) {
                                    this.validate();
                                  }
                                }
                              );
                            }}
                          >
                            <div className="currencyListIconNameWrap">
                              <div
                                className="currencyList_Name"
                                name="blockchain"
                                data-e2e={ele.name}
                              >
                                {ele.name}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>

              <div
                className="form-check form-group myAccoutOptModal_form_group"
                style={{ alignItems: 'flex-start', flexDirection: 'row' }}
              >
                <img
                  data-e2e="checkBox"
                  src={`${this.props.image_base_url}${
                    this.state.addNewData.requireFiatSettlementTrade
                      ? selectedCheckBoxIcon
                      : emptyCheckBoxIcon
                  }`}
                  alt="checkbox"
                  className="checkboxes"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState((prevState) => ({
                      addNewData: {
                        ...this.state.addNewData,
                        requireFiatSettlementTrade:
                          !prevState.addNewData.requireFiatSettlementTrade,
                      },
                    }));
                  }}
                  style={{ paddingTop: '5px' }}
                />

                <label
                  className="form-check-label myAccoutOptModalLable pl-3"
                  htmlFor="flexCheckDefault"
                >
                  Fiat settlement trade
                </label>
              </div>

              <div className="d-flex justify-content-center">
                <div
                  className={`submit ${
                    this.state.errorMsg !== '' ? 'inactive' : ''
                  }`}
                  onClick={() => {
                    this.addNewCrypto();
                  }}
                >
                  Add
                </div>
              </div>
              {this.state.errorMsg !== '' && (
                <div style={{ color: 'red' }}>{this.state.errorMsg}</div>
              )}
            </form>
            <SavingAlert
              msg="All Transactions Details has been saved successfuly."
              header="Details Saved"
              btnName="Close"
              showModal={this.state.showSavingModal}
              onCloseClickHandler={() => {
                this.setState({ showSavingModal: false });
                this.props.getCryptos();
                this.props.handleClose();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    base_url: state.config.api_url,
    image_base_url: state.config.image_base_url,
  };
};

export default connect(mapStateToProps, null)(AddNewCrypto);
