import React, { useState } from "react";
import { Table } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { Link } from "react-router-dom";
import { isViewPermissionValid } from "../../../../common/CommonUtils";

const cardAcquirerTable = (props) => {
  const { antiFraudServiceHeaders, antiFraudServiceData, paymentProviderList } = props;

  return (
    <div className="">
      {antiFraudServiceData && antiFraudServiceData.length > 0 ? (
        <div >
          <Table responsive style={{ padding: '10px', overflowX: "auto" }}>
            <tbody  >
              <tr style={{ backgroundColor: "#F4F9FB", width: "100%" }}>
                {antiFraudServiceHeaders.map((item, index) => (
                  <th
                    className="GBtableHeading pl-0 pr-0 "
                    style={{ width: item.width }}
                    key={index}
                    data-e2e={item.name}
                  >
                    {item.name}
                  </th>
                ))}
              </tr>
              {antiFraudServiceData &&
                antiFraudServiceData.map((item, index) => {
                  const paymentProviderName = paymentProviderList.find(val => val._id === item.paymentProvider)
                  return (
                    <tr key={index}>
                      <td className="table_item merchant_table_font  text-black">
                        <div className="text-blue-500 px-2">
                          <Tooltip
                            title={item.uniqueId}
                            placement="top"
                          >
                            <Link to={`/card-acquirer-routing/${item.uniqueId}`}>
                              <div>
                                {item.uniqueId.substring(0, 4) + "..." + item.uniqueId.substring(item.uniqueId.length - 4)}
                              </div>
                            </Link>
                          </Tooltip>
                        </div>
                      </td>
                      <td className="table_item merchant_table_font pl-0 pr-0 text-black">
                        {paymentProviderName
                          ? paymentProviderName.firstName + " " + paymentProviderName.lastName
                          : "N/A"}
                      </td>
                      <td className="table_item merchant_table_font pl-0 pr-0 text-blue-500">
                        <Link data-e2e={item?.acquireRoutingServiceName
                            ? item?.acquireRoutingServiceName
                            : "N/A"} to={`/card-acquirer-routing/${item.uniqueId}`}>
                          {item.acquireRoutingServiceName
                            ? item.acquireRoutingServiceName
                            : "N/A"}
                        </Link>
                      </td>
                      <td className="table_item merchant_table_font pl-0 pr-0 text-black">
                            {item.ftdToTrustedCount}
                      </td>
                      <td className="table_item merchant_table_font pl-0 pr-0 text-black">
                        {item.createdDate
                          ? moment(item.createdDate).format('lll')
                          : "N/A"}
                      </td>
                      <td
                        className="table_item merchant_table_font px-2 pr-0 text-black"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <EditIcon
                          fontSize="large"
                          color="action"
                          className={`cursor-pointer pr-3 ${isViewPermissionValid? "disabled": ""}`}
                          data-e2e="ip_edit_icon"
                          onClick={() => isViewPermissionValid? null:(
                            props.handleEditAntiFraudService({
                              ...item,
                            })
                        )}
                        />
                        <span className={`cursor-pointer ${isViewPermissionValid? "disabled": ""}`} data-e2e="ip_delete_icon">
                          <CloseIcon
                            onClick={() => isViewPermissionValid? null: (
                              props.deleteAntiFraud(item._id)
                            )}
                            fontSize="small"
                          />
                        </span>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table >
          <div data-e2e="create-new-service-btn" className={`buttonAddAntiFraud uppercase w-full ${isViewPermissionValid? "disabled": ""}`} onClick={() =>isViewPermissionValid? null: props.setShowAddNewAntiFraudModal(true)}>Create New Service</div>
        </div>
      ) : (
          <div>
            <div className="NoDataFound">No Data Found</div>
            <div data-e2e="create-new-service-btn" className={`buttonAddAntiFraud uppercase w-full ${isViewPermissionValid? "disabled": ""}`} onClick={() => isViewPermissionValid? null: props.setShowAddNewAntiFraudModal(true)}>Create New Service</div>
          </div>
      )
      }
    </div >
  );
};
export default cardAcquirerTable

