import React from 'react';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map'
import DataTable from 'react-data-table-component';
import { customStylesTable } from './helpers';
import { formatTransactionId } from './helpers';
import { merchantTransactionStatuses } from "../../global/constant";

const TransactionTable = (props) => {
	const sortingValues = {
		"Merchant": "name",
		"Type": "type",
		"Network": "network",
		"Transaction ID": "transactionId",
		"Status": "status.code",
		"Created Date": "createdDate",
		"Last Updated": "status.updated",
		"Fiat Amount": "depositAmount",
		"Crypto Amount": "selected.amount",
		"Transaction Fee": "transactionFee",
	}
	
	const onChangePage = (page) => {
		props.setPage(page)
	}
	
	const customSort = (column, sortDirection) => {
		props.changeOrder(sortingValues[column.name.props.children])
	};
	
	const goToTransactionPage = (row) => {
		const url = `/merchant-transaction/${row.transactionId}`;
		window.open(url, '_blank');
	};
	
	const columns = [
		{
			name: <p data-e2e="name">{Object.keys(sortingValues)[0]}</p>,
			selector: row => row.merchant && row.merchant.name || "N/A",
			sortable: true,
			width: "150px",
			cell: row => 
			<div data-tag="allowRowEvents">{row.merchant && row.merchant.name || "N/A"}</div>,
		},
		{
			name: <p>{Object.keys(sortingValues)[1]}</p>,
			selector: row => row.type || "N/A",
			sortable: true,
			cell: row => <div data-tag="allowRowEvents" className="text-capitalize">{row.type || "N/A"}</div>
		},
		{
			name: <p>{Object.keys(sortingValues)[2]}</p>,
			selector: row => row.network || "N/A",
			sortable: true,
			cell: row => <div data-tag="allowRowEvents">{row.network || "N/A"}</div>
		},
		{
			name: <p data-e2e="transactionId">{Object.keys(sortingValues)[3]}</p>,
			selector: row => row.transactionId || "N/A",
			sortable: true,
			width: "145px",
			cell: row => 
			<div data-tag="allowRowEvents">{formatTransactionId(row.transactionId)}</div>,
		},
		{
			name: <p>{Object.keys(sortingValues)[4]}</p>,
			selector: row => row.status && row.status.code || "N/A",
			sortable: true,
			width: "145px",
			cell: (row) => <div data-tag="allowRowEvents" style={{width: "100%"}}>
				{row.status && row.status.code ? getStatusComponent(row.status.code) : "N/A"}
			</div>
		},
		{
			name: <p>{Object.keys(sortingValues)[5]}</p>,
			selector: row => row.createdDate || "N/A",
			sortable: true,
			width: "145px",
			cell: (row) => 
			<div data-tag="allowRowEvents">
				{row.createdDate ? moment(row.createdDate).format("DD/MM/YYYY - HH:mm") : "N/A"}
			</div>
		},
		{
			name: <p>{Object.keys(sortingValues)[6]}</p>,
			selector: row => row.status && row.status.updated || "N/A",
			sortable: true,
			width: "145px",
			cell: (row) => 
			<div data-tag="allowRowEvents">
				{row.status ? moment(row.status.updated).format("DD/MM/YYYY - HH:mm") : "N/A"}
			</div>
		},
		{
			name: <p>{Object.keys(sortingValues)[7]}</p>,
			selector: row => row.depositAmount,
			sortable: true,
			width: "165px",
			cell: row => 
			<div data-tag="allowRowEvents">
				{row && (row.type === "Autotrade" || row.type === "Swap" || row.type === "Deposit" || row.type === "AdminDeposit" || row.type === "receive") ? (getSymbolFromCurrency("USD") + parseFloat(row.USDAmount).toFixed(6)) : (row.depositFiatCurrency && row.depositAmount ? (
					getSymbolFromCurrency(row.depositFiatCurrency) + row.depositAmount
				) :
					("N/A"))
				}
			</div>
		},
		{
			name: <p>{Object.keys(sortingValues)[8]}</p>,
			selector: row => row.fromAmount,
			sortable: true,
			width: "170px",
			cell: row => 
			<div data-tag="allowRowEvents">
				{row && (row.type === "Autotrade" || row.type === "Swap") ?
					<span>
						{"(" + row.fromCrypto + "-" + row.toCrypto + ")"}<br/>
						{"(" + parseFloat(row.fromAmount).toFixed(4) + "-" + parseFloat(row.toAmount).toFixed(4) + ")"}
					</span> :
					(row.type === "Deposit" || row.type === "AdminDeposit" || row.type === "receive" ? (row.cryptoId + " - " + parseFloat(row.amount).toFixed(6)) :
						((row.selected &&
							row.selected.cryptoId + " - " + parseFloat(row.selected.amount).toFixed(6)) ||
							"0 ")
					)
				}
			</div>
		},
		{
			name: <p>{Object.keys(sortingValues)[9]}</p>,
			selector: row => row.transactionFee || "0",
			sortable: true,
			cell: row => 
			<div data-tag="allowRowEvents">
				{row.transactionFee || "0"}
			</div>
		},
	];

  const getStatusComponent = (status) => {
    switch (status) {
      case merchantTransactionStatuses.DEPOSIT_PENDING_APPROVAL:
      case merchantTransactionStatuses.WITHDRAWAL_PENDING_APPROVAL:
        return (
          <div className="theme-table-status witheld"  data-e2e-status="status" data-e2e="REQUIRED APPROVAL">
            REQUIRED APPROVAL
          </div>
        );
      case merchantTransactionStatuses.DEPOSIT_TIMED_OUT_MONITORING:
        return <div className="theme-table-status witheld">TIMED OUT</div>;
      case merchantTransactionStatuses.DEPOSIT_REJECTED:
      case merchantTransactionStatuses.WITHDRAWAL_REJECTION_COMPLETE:
      case merchantTransactionStatuses.WITHDRAWAL_INSUFFICIENT_FUNDS_REJECT:
      case merchantTransactionStatuses.WITHDRAWAL_REJECTED_DUE_TO_BALANCE:
	  	case merchantTransactionStatuses.WITHDRAWAL_ERROR:
        return <div className="theme-table-status inactive" data-e2e="rejected" data-e2e-status="status">REJECTED</div>;
      case merchantTransactionStatuses.DEPOSIT_WALLET_STATUS_AVAILABLE:
      case merchantTransactionStatuses.DEPOSIT_WALLET_STATUS_LOCKED:
      case merchantTransactionStatuses.DEPOSIT_UPDATE_WALLET_STATUS:
      case merchantTransactionStatuses.DEPOSIT_NOTIFY_MERCHANT:
      case merchantTransactionStatuses.DEPOSIT_MANUAL_APPROVE:
      case merchantTransactionStatuses.DEPOSIT_KYT_FAIL:
      case merchantTransactionStatuses.DEPOSIT_KYT_PASS:
      case merchantTransactionStatuses.DEPOSIT_KYT_CHECK:
      case merchantTransactionStatuses.DEPOSIT_CONFIRMED:
      case merchantTransactionStatuses.DEPOSIT_GAS_TOO_LOW:
      case merchantTransactionStatuses.DEPOSIT_EXCESS_TRANSACTION_EXECUTED:
      case merchantTransactionStatuses.DEPOSIT_TRANSACTION_EXECUTED:
      case merchantTransactionStatuses.DEPOSIT_TRANSACTION_EXECUTING:
      case merchantTransactionStatuses.DEPOSIT_RECEIVED_TO_MEMPOOL:
      case merchantTransactionStatuses.DEPOSIT_TRANSACTION_COMPLETE:
      case merchantTransactionStatuses.WITHDRAWAL_UNABLE_TO_SOURCE_WALLET:
        return (
          <div className="theme-table-status pending" data-e2e="PROCESSING" data-e2e-status="status">
            PROCESSING
          </div>
        );
      case merchantTransactionStatuses.DEPOSIT_MANUAL_COMPLETE:
      case merchantTransactionStatuses.DEPOSIT_COMPLETED:
      case merchantTransactionStatuses.MERCHANT_BALANCE_UPDATED:
      case merchantTransactionStatuses.WITHDRAWAL_COMPLETED:
      case merchantTransactionStatuses.WALLET_TRANSFER_COMPLETED:
      case merchantTransactionStatuses.DEPOSIT_WALLET_FLOW_COMPLETED:
      case merchantTransactionStatuses.AUTO_TRADE_COMPLETED:
      case merchantTransactionStatuses.MERCHANT_SWAP_COMPLETED:
      case merchantTransactionStatuses.MERCHANT_SETTLEMENT_COMPLETED:
      case merchantTransactionStatuses.MERCHANT_WITHDRAWAL_COMPLETED:
      case merchantTransactionStatuses.MERCHANT_RECEIVE_COMPLETED:
      case merchantTransactionStatuses.MERCHANT_AUTO_TRADE_EXECUTED:
      case merchantTransactionStatuses.MERCHANT_WALLET_BALANCE_UPDATE:
      case merchantTransactionStatuses.MERCHANT_COMPLETED:
        return (
          <div className="theme-table-status active" data-e2e="success" data-e2e-status="status">
            SUCCESS
          </div>
        );
      case merchantTransactionStatuses.CREATE_PAYMENT:
      case merchantTransactionStatuses.DEPOSIT_FLOW_LAUNCHED:
      case merchantTransactionStatuses.DEPOSIT_FLOW_AWAITING_DEPOSIT:
      case merchantTransactionStatuses.DEPOSIT_MONITORING_MEMPOOL:
      case merchantTransactionStatuses.DEPOSIT_PENDING_MANUAL_APPROVING:
        return (
          <div className="theme-table-status inactive" data-e2e="pending" data-e2e-status="status">
            PENDING
          </div>
        );
	case merchantTransactionStatuses?.DEPOSIT_CANCELLED:
		return (
			<div className="theme-table-status inactive" data-e2e="cancelled" data-e2e-status="status">
				CANCELLED
			</div>
		);
	case merchantTransactionStatuses?.DEPOSIT_ABANDONED:
		return (
			<div className="theme-table-status inactive" data-e2e="abandoned" data-e2e-status="status">
				ABANDONED
			</div>
		);
      default:
        return (
          <div className="theme-table-status inactive" data-e2e="processing" data-e2e-status="status">
            PROCESSING
          </div>
        );
    }
  };

  return (
		<div>
			<DataTable
        onChangePage={onChangePage}
				paginationTotalRows={props.totalCount}
				paginationPerPage={10}
				paginationComponentOptions={{
					noRowsPerPage: true
				}}
				sortServer={true}
				paginationServer
				pagination
				columns={columns}
				data={props.data}
				customStyles={customStylesTable}
				onSort={customSort}
				highlightOnHover={true}
				pointerOnHover={true}
				noDataComponent={<div style={{ padding: '24px', fontSize: "14px" }}>There are no data to display</div>}
				onRowClicked={(row, e) => goToTransactionPage(row)}
			/>
    </div>
  );
}
export default TransactionTable;

