import React, { useEffect, useState } from "react";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import * as actions from '../../../Redux/actions';
import {
  GET_ALL_MERCHANTS_LIST,
  GET_ALL_PAYMENT_PROVIDERS
} from "../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../config/axios";
import CommonHeader from "../CommonPages/CommonHeader";
// import SettingsIcon from "@mui/icons-material/ArrowDownward";
import { connect, useSelector } from "react-redux";
import Loading from "../../../common/Loading";
import localDb from "../../../../localDb";
import Dialog from "@mui/material/Dialog";
import MerchantTab from "../../Layouts/Dashboard/Tabs/MerchantTab";
// const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";
import { styled } from "@mui/material/styles";
import moment from "moment";
// import MediaQuery from "react-responsive";
const searchicon = "/cryptonpay/crytoicons/searchicon1.png";
const selectedCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_filled.png';
const emptyCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_empty.png';
const closeImage = '/cryptonpay/crytoicons/close.png';

const timearry = [
  {
    name: "All",
  },
  {
    name: "Last 30 Min"
  },
  {
    name: "Last 1 Hour"
  },
  {
    name: "Last 6 Hour"
  },
  {
    name: "Last 12 Hour"
  },
  {
    name: "Last 14 Hour"
  },
  {
    name: "Last 24 Hour"
  },
  {
    name: "Last 28 Hour"
  },
  {
    name: "Last 48 Hour"
  },
  {
    name: "Last 7 Days"
  }
]

const techSupport = [
  {
    path: "/real-time-exchange-rates",
    name: "Realtime Exchange Rates",
  },
  {
    path: "/daily-exchange-rates",
    name: "Daily Exchage Rates",
  },
  {
    path: "/error-logs",
    name: "ErrorLogs",
  },
  {
    path: "/sweep-logs",
    name: "SweepLogs",
  },
  {
    path: "/house-keeping-transactions",
    name: "Housekeeping Transactions",
  },
  {
    path: "/house-keeping-alerts",
    name: "Housekeeping Alerts",
  },
  {
    path: "/house-keeping-wallet-balances",
    name: "Housekeeping wallet balances",
  },
];

const useStyles = styled(Dialog)({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: "white",
    },
  },
});

const Dashboard = (props) => {
  const classes = useStyles;
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal("email");
  const adminRole = localDb.getVal("adminRole");
  const base_url = useSelector((state) => state.config.api_url);

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearhText] = useState("");
  const [orderBy, setOrderBy] = useState("asc");
  const [sortBy, setSortBy] = useState("firstName");
  const [dateSelected, setDateSelected] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [save, setSave] = useState(false);


  useEffect(() => {
  }, [searchText, orderBy, sortBy, save]);

  const [openSelectProviderModal, setOpenSelectProviderModal] = useState(false);

  const [providerNameList, setProviderNameList] = useState([]);
  const [selectedProviderName, setSelectedProviderName] = useState([]);

  const getProvidersList = async () => {
    setLoading(true);
    await call(
      {
        ...GET_ALL_PAYMENT_PROVIDERS,
        url: base_url + GET_ALL_PAYMENT_PROVIDERS.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setProviderNameList(res.data.providerList);
          setSelectedProviderName(res.data.providerList);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message, 'err');
        setLoading(false);
        logoutAfterError(err)
      });
  };

  const resetFilters = () => {
    props.setSearchText("");
    props.setOrderBy("desc");
    props.setSortBy("status.updated");
    props.setDateSelected(false);
    props.setSelectedStatusList([]);
    props.setSelectedStatusGroupList([]);
    props.setFromDate([
      moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    ]);
    props.setToDate([moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")]);
    props.setSelectedCryptos([]);
    props.setSelectedFiatCurrency([]);
    props.setSelectedNetworkList(["mainnet", "testnet"]);
    props.setSelectedType([]);
    props.setSelectedAcquirer([]);
    props.setSelectedMethod([]);
    props.setSelectedMerchantName([]);
    props.setMinFiatAmount(0);
    props.setMaxFiatAmount(0);
    props.changeIsContainsNote(false);
    props.setSelectedPaymentProvider([])
  };

  const toggleProviderNameSelection = (provider) => {
    if (selectedProviderName.map(list => list._id).includes(provider._id)) {
      let newList = selectedProviderName.filter(
        (symbolObj) => symbolObj._id !== provider._id
      );
      setSelectedProviderName(newList);
    } else {
      let newList = [...selectedProviderName];
      newList.push(provider);
      setSelectedProviderName(newList);
    }
  };
  useEffect(() => {
    resetFilters()
    getMerchantsList();
    adminRole === "SuperAdmin" && getProvidersList();
  }, []);

  const [providerName, setProviderName] = useState({
    name: "All Payment Providers",
    id: 1
  });
  const [merchantHeaderName, setMerchantHeaderName] = useState({
    name: "All Merchants",
    id: 2
  });
  const [time, setTime] = useState(timearry);
  const [selectedTime, setSelectedTime] = useState("Last 24 Hour")
  const [isSelectedTime, setIsSelectedTime] = useState(false)

  const handleSelectAll = () => {
    if (selectedProviderName.length === providerNameList.length) {
      setSelectedProviderName([]);
    } else if (selectedProviderName.length < providerNameList.length) {
      setSelectedProviderName(providerNameList);
    }
  };

  const [isChangeProvider, setIsChangeProvider] = useState(false);
  const handleAddBtn = () => {
    setMerchantHeaderName({ name: "All Merchants", id: 2 });
    if (selectedProviderName.length === 1) {
      let name = selectedProviderName[0].firstName + " " + selectedProviderName[0].lastName;
      setProviderName({ name: name, id: 1 });
    } else if (selectedProviderName.length === 0 || selectedProviderName.length === providerNameList.length) {
      setProviderName({ name: "All Providers Selected", id: 1 });
    } else {
      let name = selectedProviderName.length + " Providers Selected";
      setProviderName({ name: name, id: 1 });
    }
    setOpenSelectProviderModal(false);
    setIsChangeProvider(true);
  };


  const handleOnClickOfSubheader = (id) => {
    if (id === 2) {
      setMerchantFilterModal(true);
    } else {
      setOpenSelectProviderModal(true)
    }
  };

  const [merchantFilterModal, setMerchantFilterModal] = useState(false);
  const [merchantName, setMerchatName] = useState([]);
  const [selectedMerchantName, setSelectedMerchantName] = useState([]);
  const [isMerchantChange, setIsMerchantChange] = useState(false);

  const getMerchantsList = async () => {
    let payload = {
      email,
      ...(adminRole === "SuperAdmin" && {
        providers:
          selectedProviderName.length === 0 ||
            selectedProviderName.length === providerNameList.length
            ? 'All'
            : selectedProviderName
      }),
      ...(adminRole === "PaymentProviderUser" && {
        providers:[{_id:localDb.getVal("paymentProviderId")}]
      }),
      merchant: searchMerchantText.toString()
    }
    setLoading(true);
    await call(
      {
        ...GET_ALL_MERCHANTS_LIST,
        url: base_url + GET_ALL_MERCHANTS_LIST.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setMerchatName(res.data.merchants);
          setSelectedMerchantName(res.data.merchants);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message, 'err');
        setLoading(false);
        logoutAfterError(err)
      });
  };

  const toggleMerchantNameSelection = (name, merchantId, id) => {
    if (selectedMerchantName.map(list => list.name).includes(name)) {
      let newList = selectedMerchantName.filter(
        (symbolObj) => symbolObj.name !== name
      );
      setSelectedMerchantName(newList);
    } else {
      let newList = [...selectedMerchantName];
      newList.push({ '_id': id, 'name': name, 'merchantId': merchantId });
      setSelectedMerchantName(newList);
    }
  };

  const [searchMerchantText, setSearchMerchantText] = useState('');
  const [searchProviderText, setSearchProviderText] = useState('');


  useEffect(() => {
    isChangeProvider && getMerchantsList();
  }, [searchMerchantText, isChangeProvider]);

  useEffect(() => {
    getMerchantsList();
  }, [searchMerchantText]);

  const handleMerchantSelectAll = () => {
    if (selectedMerchantName.length === merchantName.length) {
      setSelectedMerchantName([]);
    } else if (selectedMerchantName.length < merchantName.length) {
      setSelectedMerchantName(merchantName);
    }
  };

  const handleMerchantAddBtn = () => {
    if (selectedMerchantName.length === 1) {
      setMerchantHeaderName({ name: selectedMerchantName[0].name, id: 2 });
    } else if (selectedMerchantName.length === 0 || selectedMerchantName.length === merchantName.length) {
      setMerchantHeaderName({ name: "All Merchants", id: 2 });
    } else {
      let name = selectedMerchantName.length + " Merchants Selected";
      setMerchantHeaderName({ name: name, id: 2 });
    }
    setMerchantFilterModal(false);
    setIsMerchantChange(true);
    // setSearchMerchantText('');
  };
  const filtered_providers = providerNameList.filter((provider) =>{
    if(searchProviderText === ""){
      return provider;
    }else if((provider.firstName.toLowerCase().includes(searchProviderText.toLowerCase())) || (provider.lastName.toLowerCase().includes(searchProviderText.toLowerCase()) )){
      return provider;
    }
    })
const tabData = adminRole === "PaymentProviderUser"? [merchantHeaderName]: [providerName, merchantHeaderName]
  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
				<div className="">
					<CommonHeader view={"Dashboard"} time={time} selectedTime={selectedTime} setSelectedTime={setSelectedTime} setIsSelectedTime={setIsSelectedTime} nameTab={tabData} onClick={handleOnClickOfSubheader} />
					<div>
						<MerchantTab setLoading={setLoading}
							isChangeProvider={isChangeProvider}
							setIsChangeProvider={setIsChangeProvider}
							selectedPaymentProvider={selectedProviderName}
							paymentProviderList={providerNameList}
							selectedMerchantName={selectedMerchantName}
							merchantName={merchantName}
							isMerchantChange={isMerchantChange}
							setIsMerchantChange={setIsMerchantChange}
							adminRole={adminRole}
							selectedTime={selectedTime}
							setSelectedTime={setSelectedTime}
							isSelectedTime={isSelectedTime}
							setIsSelectedTime={setIsSelectedTime}
						/>
					</div>
				</div>

        {/* <MediaQuery minWidth={768}>
          <CommonHeader view={"Dashboard"} time={time} selectedTime={selectedTime} setSelectedTime={setSelectedTime} setIsSelectedTime={setIsSelectedTime} nameTab={[providerName, merchantHeaderName]} onClick={handleOnClickOfSubheader} />
        </MediaQuery> */}
        {/* <MediaQuery maxWidth={767} >
          <div className="sub_header">
            <div className="transaction_search_filter_wrap">
              <h1>{`All Merchant`}<i onClick={() => setOpenSelectMerchnatModal(true)} className="fa fa-angle-down pl-1 cursor-pointer" aria-hidden="true"></i></h1>
              <div className="log_wrap">
                <h4 onClick={() => { }} className="pl-4 pr-2 cursor-pointer"> <i className="fa fa-filter" aria-hidden="true"></i> </h4>
              </div>
            </div>
          </div>
        </MediaQuery> */}

       

        {openSelectProviderModal && (
          <Dialog
            open={true}
            className={`${classes.root} change_status_modal dashbord_change_merchant_modal mt-4`}
            style={{ height: "fit-content" }}
          >
            <div className="change_status_body">
              <div className="heading_closeIcon">
                <div className="heading">Select Payment Providers</div>
                <div
                  className="filter_close_button"
                  onClick={() => {
                    setOpenSelectProviderModal(false);
                  }}
                >
                  <img
                    src={`${image_base_url}${closeImage}`}
                    alt="closeImage"
                    data-e2e="close-icon"
                  />
                </div>
              </div>

              <div className="search_Box">
                <img
                  src={`${image_base_url}${searchicon}`}
                  alt="closeImage"
                  data-e2e="close-icon"
                />
                <input type="text" placeholder="Search for an Provider" value={searchProviderText} onChange={(e) => setSearchProviderText(e.target.value)}  />
              </div>


              <div className="select_container">
                <div className="merchant_name_wrap">
                  <div className="select_row" onClick={handleSelectAll}>
                    <img
                      src={`${image_base_url}${selectedProviderName.length === providerNameList.length
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                        }`}
                      alt="checkbox"
                      className="currencyListCheckBox"
                    />
                    <div className="merchnat_name" >{`Select All`}</div>
                  </div>
                  {filtered_providers.length > 0 && filtered_providers.map((provider, index) => {
                    return (
                      <div className="select_row" key={index}
                        onClick={() => {
                          toggleProviderNameSelection(provider);
                        }}>
                        <img
                          src={`${image_base_url}${selectedProviderName.map(list => list._id).includes(provider._id)
                            ? selectedCheckBoxIcon
                            : emptyCheckBoxIcon
                            }`}
                          alt="checkbox"
                          className="currencyListCheckBox"
                        />
                        <div className="merchnat_name">{provider.firstName + " " + provider.lastName}</div>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="notify_btn_wrap">
                <div
                  className="change_trans_button mt-4 w-100"
                  onClick={() => { handleAddBtn() }}
                >
                  Add
                </div>
                {/* <div
                  className="change_trans_go_back mt-4"
                  onClick={() => { }}
                >
                  Reset
                </div> */}
              </div>
            </div>
          </Dialog>
        )}

        {merchantFilterModal && (
          <Dialog
            open={true}
            className={`${classes.root} change_status_modal dashbord_change_merchant_modal mt-4`}
            style={{ height: "fit-content" }}
          >
            <div className="change_status_body">
              <div className="heading_closeIcon">
                <div className="heading">Select Merchant</div>
                <div
                  className="filter_close_button"
                  onClick={() => {
                    setMerchantFilterModal(false);
                  }}
                >
                  <img
                    src={`${image_base_url}${closeImage}`}
                    alt="closeImage"
                    data-e2e="close-icon"
                  />
                </div>
              </div>

              <div className="search_Box">
                <img
                  src={`${image_base_url}${searchicon}`}
                  alt="closeImage"
                  data-e2e="close-icon"
                />
                <input type="text" value={searchMerchantText} onChange={(e) => setSearchMerchantText(e.target.value)} placeholder="Search for an Merchant" />
              </div>


              <div className="select_container">
                <div className="merchant_name_wrap">
                  <div className="select_row" onClick={handleMerchantSelectAll}>
                    <img
                      src={`${image_base_url}${selectedMerchantName.length === merchantName.length
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                        }`}
                      alt="checkbox"
                      className="currencyListCheckBox"
                    />
                    <div className="merchnat_name" >{`Select All`}</div>
                  </div>
                  {merchantName.length > 0 && merchantName.map((merchant, index) => {
                    return (
                      <div className="select_row" key={index}
                        onClick={() => {
                          toggleMerchantNameSelection(merchant.name, merchant.merchantId, merchant._id);
                        }}>
                        <img
                          src={`${image_base_url}${selectedMerchantName.map(list => list.name).includes(merchant.name)
                            ? selectedCheckBoxIcon
                            : emptyCheckBoxIcon
                            }`}
                          alt="checkbox"
                          className="currencyListCheckBox"
                        />
                        <div className="merchnat_name">{merchant.name}</div>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="notify_btn_wrap">
                <div
                  className="change_trans_button mt-4 w-100"
                  onClick={() => { handleMerchantAddBtn() }}
                >
                  Add
                </div>
                {/* <div
                  className="change_trans_go_back mt-4"
                  onClick={() => { }}
                >
                  Reset
                </div> */}
              </div>
            </div>
          </Dialog>
        )}
      </HeaderAndFooterLayout>
      {loading && <Loading />}

    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchText: (value) => dispatch(actions.setSearchText(value)),
    setOrderBy: (value) => dispatch(actions.setOrderBy(value)),
    setSortBy: (value) => dispatch(actions.setSortBy(value)),
    setFromDate: (value) => dispatch(actions.setFromDate(value)),
    setToDate: (value) => dispatch(actions.setToDate(value)),
    setDateSelected: (value) => dispatch(actions.setDateSelected(value)),
    setSelectedStatusList: (value) => dispatch(actions.setSelectedStatusList(value)),
    setSelectedStatusGroupList: (value) => dispatch(actions.setSelectedStatusGroupList(value)),
    setSelectedMethod: (value) => dispatch(actions.setSelectedMethod(value)),
    setSelectedType: (value) => dispatch(actions.setSelectedType(value)),
    setSelectedAcquirer: (value) => dispatch(actions.setSelectedAcquirer(value)),
    setSelectedCryptos: (value) => dispatch(actions.setSelectedCryptos(value)),
    setSelectedFiatCurrency: (value) => dispatch(actions.setSelectedFiatCurrency(value)),
    setSelectedNetworkList: (value) => dispatch(actions.setSelectedNetworkList(value)),
    setMinFiatAmount: (value) => dispatch(actions.setMinFiatAmount(value)),
    setMaxFiatAmount: (value) => dispatch(actions.setMaxFiatAmount(value)),
    setMerchantName: (value) => dispatch(actions.setMerchantName(value)),
    setSelectedMerchantName: (value) => dispatch(actions.setSelectedMerchantName(value)),
    setSelectedPaymentProvider: (value) => dispatch(actions.setSelectedPaymentProvider(value)),
    changeIsContainsNote: (value) => dispatch(actions.changeIsContainsNote(value)),
  }
}

export default connect(null, mapDispatchToProps)(Dashboard);