import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../../../common/Loading";
import CustomTextFields from "../../../../../common/CustomTextFields";
import TabButton from "../../../../../common/TabButton";
import { Grid } from "@mui/material";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";
import {
  UPDATE_MAXIMUM_TRAFFIC_SETTING,
  GET_MAXIMUM_TRAFFIC_SETTING,
} from "../../../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../../../config/axios";
import localDb from "../../../../../../localDb";

const MaxTrafficTab = ({ acquirerServiceData }) => {
  const baseURL = useSelector((state) => state.config.api_url);
  const [tabs, setTabs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [acquirerId, setAcquirerId] = useState(
    acquirerServiceData[0]?.acquirerUniqueId || "",
  );
  const fieldNames = {
    maxValueAllowed: "Max Transaction Value",
    maxNumberAllowed: "Max No of Transactions",
    maxLimitPercentage: "Max Traffic %",
    currency: "Currency",
  };
  const initialValues = {
    maxValueAllowed: 0,
    maxNumberAllowed: 0,
    maxLimitPercentage: 0,
    currency: "USD",
  };
  const initialEditValue = {
    maxValueAllowed: false,
    maxNumberAllowed: false,
    maxLimitPercentage: false,
    currency: false,
  };
  const [values, setValues] = useState(initialValues);
  const [isEditable, setIsEditable] = useState(initialEditValue);

  useEffect(() => {
    getMaxtrafficByAcquirer();
  }, [tabs]);

  const handleChange = (field, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };
  useEffect(() => {
    const item = acquirerServiceData[tabs];
    const currentValues = item?.loadBalancing?.maximumTraffic;
    setValues((prevValues) => ({
      ...prevValues,
      ...currentValues,
    }));
  }, [tabs]);
  const handleSave = async () => {
    if (
      values?.maxValueAllowed < 0 ||
      values?.maxNumberAllowed < 0 ||
      values?.maxLimitPercentage < 0
    ) {
      setError("please enter positive values");
      return;
    }
    const data = {
      email: localDb.getVal("email"),
      acquirerUniqueId: acquirerId,
      ...values,
    };
    setLoading(true);
    try {
      const res = await call(
        {
          ...UPDATE_MAXIMUM_TRAFFIC_SETTING,
          url: baseURL + UPDATE_MAXIMUM_TRAFFIC_SETTING.url,
        },
        data,
      );

      if (res?.status === 200) {
        setError("");
        setLoading(false);
        setIsEditable(initialEditValue);
      }
    } catch (error) {
      setError("");
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const getMaxtrafficByAcquirer = async () => {
    const data = {
      email: localDb.getVal("email"),
      acquirerUniqueId: acquirerId,
    };
    setLoading(true);
    try {
      const res = await call(
        {
          ...GET_MAXIMUM_TRAFFIC_SETTING,
          url: baseURL + GET_MAXIMUM_TRAFFIC_SETTING.url,
        },
        data,
      );

      if (res?.status === 200) {
        const data = {
          maxLimitPercentage:
            res?.data?.maxLimitPercentage == null
              ? 0
              : res?.data?.maxLimitPercentage,
          maxNumberAllowed:
            res?.data?.maxNumberAllowed == null
              ? 0
              : res?.data?.maxNumberAllowed,
          maxValueAllowed:
            res?.data?.maxValueAllowed == null ? 0 : res?.data?.maxValueAllowed,
        };
        setValues((prevValues) => ({
          ...prevValues,
          ...data,
        }));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const toggleEditable = (field) => {
    setIsEditable((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleTabSwitch = (index, itemId) => {
    setError("");
    setAcquirerId(itemId?.acquirerUniqueId);
    setTabs(index);
    setIsEditable(initialEditValue);
  };

  return (
    <div className="TabContainer">
      <div className="acquireServiceScroll">
        {acquirerServiceData?.map((tab, index) => (
          <TabButton
            key={index}
            activeTab={tabs}
            handleTabSwitch={handleTabSwitch}
            tab={tab?.acquirerSetting}
            index={index}
            itemId={tab}
          />
        ))}
      </div>
      <div className="ml-3 flex pt-10">
        <Grid container spacing={2}>
          {acquirerServiceData?.map((items, index) => {
            return (
              <div key={index}>
                {tabs === index && (
                  <CustomTextFields
                    initialValues={initialValues}
                    values={values}
                    isEditable={isEditable}
                    handleChange={handleChange}
                    toggleEditable={toggleEditable}
                    fieldNames={fieldNames}
                  />
                )}
              </div>
            );
          })}
        </Grid>
      </div>
      {error && (
        <div className="my-2 w-full text-left text-red-600">{error}</div>
      )}
      <div className="mt-4">
        <button
          className={`${isViewPermissionValid ? "disabled" : ""} rounded bg-blue-500 px-4 py-2 text-base font-bold text-white hover:bg-blue-700`}
          onClick={isViewPermissionValid ? null : handleSave}
          data-e2e="save-btn"
        >
          Save
        </button>
      </div>

      {loading && <Loading />}
    </div>
  );
};

export default MaxTrafficTab;
