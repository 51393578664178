import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { call, logoutAfterError } from "../../../../config/axios";
import { GET_TRAFFIC_MASKEDPAN_WHITELIST } from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import TrafficMaskedPanTable from "./TrafficMaskedPanTable";

const TrafficMaskedPanWhitelist = () => {
  const email = localDb.getVal("email");
  const base_url = useSelector((state) => state.config.api_url);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("");
  const [searchString, setSearchString] = useState("");

  const getTrafficMaskedPan = async (page = 1) => {
    let payload = {
      email: email,
      ...(localDb.getVal("paymentProviderId") && {
        paymentProviderId: localDb.getVal("paymentProviderId"),
      }),
      orderBy: orderBy,
      sortBy: sortBy,
      page: page,
      searchKeyword: searchString,
    };

    setLoader(true);
    await call(
      {
        ...GET_TRAFFIC_MASKEDPAN_WHITELIST,
        url: base_url + GET_TRAFFIC_MASKEDPAN_WHITELIST.url,
      },
      payload,
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.results);
          setTotalCount(res.data.totalCount);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        logoutAfterError(err);
      });
  };

  useEffect(() => {
    getTrafficMaskedPan(page);
  }, [page, orderBy, sortBy, searchString]);

  const name = "Traffic Masked Pan Whitelist";

  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
        <div>
          <CommonHeader name={name} />
          <div className="transactionFilter__allMerchants">
            <div className="transactionFilter">
              <input
                type="text"
                className="transactionFilter__input"
                value={searchString}
                name="searchText"
                data-e2e="search-textbox"
                onChange={(e) => setSearchString(e.target.value.trim())}
                placeholder="Search Merchant Id and Masked Pan..."
              />
            </div>
          </div>
          <div>
            <TrafficMaskedPanTable
              data={data}
              changeOrder={(sort) => {
                if (sortBy !== sort) {
                  setOrderBy("desc");
                  setSortBy(sort);
                } else {
                  setOrderBy(orderBy === "desc" ? "asc" : "desc");
                }
              }}
              setPage={(page) => {
                setPage(page);
                getTrafficMaskedPan(page);
              }}
              page={page}
              totalCount={totalCount}
              sortBy={sortBy}
              orderBy={orderBy}
            />
          </div>
        </div>
        {loader && <Loading />}
      </HeaderAndFooterLayout>
    </div>
  );
};

export default TrafficMaskedPanWhitelist;
