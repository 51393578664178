import {Modal} from "react-bootstrap";
import React from "react";
const closeImage = "/cryptonpay/crytoicons/close.png";

export const UpdateWalletBalanceModal = ({
  setBalanceToEdit,
  balanceToEdit,
  setShowEditBalanceModal,
  newBalance,
  setNewBalance,
  balanceUpdateNote,
  setBalanceUpdateNote,
  error,
  saveNewBalanceValues,
  imageBaseUrl
}) => {
  const changeNewBalance = (e) => {
    const newValue = e.target.value;
    if (!isNaN(newValue)) {
      setNewBalance(newValue);
    }
  }

  return (
    <div className="merchant_dailog_screen">
      <Modal
        show={true}
        className="ClearAlertModal transferModal transferFundsModal"
        onHide={() => {
          setShowEditBalanceModal(false);
          setBalanceToEdit(null);
        }}
      >
        <Modal.Body className="ClearAlertModalBody transferModalBody">
          <div className="transferModalTitleWrap">
            <div className="transferModalTitle">Edit Balance</div>
            <img
              className="closeIcon"
              alt="closeIcon"
              src={`${imageBaseUrl}${closeImage}`}
              onClick={() => {
                setShowEditBalanceModal(false);
                setBalanceToEdit(null);
              }}
            />
          </div>
          <div className="form-group myAccoutOptModal_form_group transferModalGroup">
            <label
              className="myAccoutOptModalLable"
              htmlFor="exampleFormControlSelect1"
            >
              Current Balance
            </label>
            <input
              type="text"
              data-e2e="current-balance"
              disabled
              value={balanceToEdit.balanceAmount}
              className="form-control inputdropdown"
              id="balanceAmount"
              placeholder="Current Balance Amount"
              name="balanceAmount"
            />
          </div>
          <div className="form-group myAccoutOptModal_form_group transferModalGroup">
            <label
              className="myAccoutOptModalLable"
              htmlFor="exampleFormControlSelect1"
            >
              New Balance
            </label>
            <input
              type="text"
              data-e2e="new-balance"
              value={newBalance}
              className="form-control inputdropdown"
              id="newBalanceInCrypto"
              placeholder="Enter New Balance"
              name="newBalanceInCrypto"
              onChange={(e) => {
                changeNewBalance(e);
              }}
            />
          </div>
          <div className="form-group myAccoutOptModal_form_group transferModalGroup">
            <label
              className="myAccoutOptModalLable"
              htmlFor="exampleFormControlSelect1"
            >
              Note
            </label>
            <textarea
              rows="3"
              cols="50"
              value={balanceUpdateNote}
              className="form-control inputdropdown"
              id="balanceUpdateNote"
              placeholder="Enter Note"
              data-e2e="enter-note-text-box"
              name="balanceUpdateNote"
              style={{ minHeight: "100px" }}
              onChange={(e) => {
                setBalanceUpdateNote(e.target.value);
              }}
            />
          </div>
          {error !== "" && (
            <div className="limitErrorText">{error}</div>
          )}
          <div
            className={`ClearAlertModalClearBtn transferFundsBtn ${
              newBalance &&
              balanceUpdateNote &&
              newBalance !== "" &&
              balanceUpdateNote !== "" &&
              !error
                ? ""
                : "transferFundsBtnDisable"
            }`}
            onClick={() => {
              if (
                newBalance &&
                balanceUpdateNote &&
                newBalance !== "" &&
                balanceUpdateNote !== "" &&
                error === ""
              ) {
                saveNewBalanceValues(balanceToEdit.cryptoId);
              }
            }}
            data-e2e="save-btn"
          >
            Save
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
