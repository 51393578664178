import React from "react";
import { useSelector } from "react-redux";
const closeImage = "/cryptonpay/crytoicons/close.png";

function WhiteLabel(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const handleSaveDetails = () => {};

  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <div></div>
        <div className="add-new-wallet-heading">Merchant Whitelabel</div>
        <div
          className="filter_close_button"
          data-e2e="filter_close_button"
          onClick={() => {
            props.handleClose();
          }}
        >
          <img
            src={`${image_base_url}${closeImage}`}
            alt="closeImage"
            data-e2e="close-icon"
          />
        </div>
      </div>
      <div className="upload__merchantId">
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Primary Color </div>
          <input
            type="text"
            data-e2e="priamryColor"
            placeholder="Set primary hexcolor"
            name="primaryColor"
            value=""
          />
        </div>
      </div>
      <div className="upload__merchantId">
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Secondary Color</div>
          <input
            type="text"
            data-e2e="secondaryColor"
            placeholder="Set secondary hexcolor"
            name="secondaryColor"
            value=""
          />
        </div>
      </div>
      <div className="upload__merchantId">
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Upload Logo </div>
          <div className="file-upload-wrapper" data-text="Choose File">
            <input
              name="file-upload-field"
              type="file"
              //   onChange={(e) => handleImageUpload(e)}
              data-max-size="5000000"
              className="file-upload-field"
            />
          </div>
        </div>
      </div>
      <div
        className="FilterModalSave"
        data-e2e="FilterModalSave"
        onClick={() => {
          handleSaveDetails();
        }}
      >
        UPDATE SETTINGS
      </div>
    </div>
  );
}

export default WhiteLabel;
