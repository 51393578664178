import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import { ADMIN_FORGOT_PASSWORD, LOGIN } from '../../../../config/endpoints';
import { call } from '../../../../config/axios';
import localDb from '../../../../localDb';
import MediaQuery from 'react-responsive';
import SubHeader from '../../Layouts/HeaderAndFooter/SubHeader';
import { checkImageUrl } from '../../../common/CheckImageUrl';
import AuthenticatorModal from './AuthenticatorModal';
import VerificationModal from './VerificationModal';
import Loading from '../../../common/Loading';
const homeMainBG = '/home/homemainbg.png';
const logo = '/cryptonpay/logo_samll.svg';
const emailIcon = "/cryptonpay/crytoicons/mail.png";
const passwordIcon = "/cryptonpay/crytoicons/password.png";

function Login() {
  const [loading, setLoading] = useState(false);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [handleForgotpassModal, sethandleForgotpassModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [show2FAVerificationModal, setShow2FAVerificationModal] = useState(false);
  const [showAuthenticatorModal, setShowAuthenticatorModal] = useState(false);
  const [userData, setUserData] = useState();
  const [userEmail, setUserEmail] = useState();
  const authenticationDone = localStorage.getItem("authenticationDone")

  const closeModal = () => {
    setShowModal(false)
  };

  const setVerifyModal = (data, email) => {
    setShow2FAVerificationModal(true)
    setUserData(data)
    setUserEmail(email)
  }
  const setAuthenticatorModal = (data, email) => {
    setShowAuthenticatorModal(true)
    setUserData(data)
    setUserEmail(email)
  }
  const onVerificationSucess = (data, email) => {
    localStorage.setItem("token", data.token)
    if (!sessionStorage.getItem("colorMode") || sessionStorage.getItem("colorMode") === "") {
      sessionStorage.setItem("colorMode", 'light')
    }
    if (data.is2faActivated && data.is2faActivated.secret) {
      if (data.is2faActivated.secret != null) {
        localStorage.setItem("2FAStatus", true);
      } else {
        localStorage.setItem("2FAStatus", false);
      }
    }
  }
  useEffect(() => {
    if (!authenticationDone) {
      localStorage.clear();
    }
  }, [authenticationDone]);
  const handleClose = () => {
    const authenticationDone = localStorage.getItem("authenticationDone")
    if (!authenticationDone) {
      localStorage.clear();
    }
    setShow2FAVerificationModal(false);
    setShowAuthenticatorModal(false)
  }
  return (
    <div className="login_container">
      <MediaQuery minWidth={768}>
        <div
          className="login_body"
          style={{ backgroundImage: `url(${image_base_url}${homeMainBG})` }}
        >
          <Card className="login_card" style={{ background: 'radial-gradient(circle, #0F1029 0%, #000000 100%)', minWidth: "450px" }}>
            {
              handleForgotpassModal ?
                <ForgotPassword sethandleForgotpassModal={sethandleForgotpassModal} />
                :
                <LoginInnerContainer
                  sethandleForgotpassModal={sethandleForgotpassModal}
                  closeModal={closeModal}
                  setVerifyModal={setVerifyModal}
                  setAuthenticatorModal={setAuthenticatorModal}
                  onVerificationSucess={onVerificationSucess}
                />
            }
          </Card>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <div
          className="login_body"
        >
          <SubHeader image_base_url={image_base_url} />
          <Card className="login_card">
            {
              handleForgotpassModal ?
                <ForgotPassword sethandleForgotpassModal={sethandleForgotpassModal} />
                :
                <LoginInnerContainer
                  sethandleForgotpassModal={sethandleForgotpassModal}
                  show={true}
                  closeModal={closeModal}
                  setVerifyModal={setVerifyModal}
                  setAuthenticatorModal={setAuthenticatorModal}
                  onVerificationSucess={onVerificationSucess}
                />
            }
          </Card>
        </div>
      </MediaQuery>
      {show2FAVerificationModal &&
        <VerificationModal
          setLoading={setLoading}
          open={show2FAVerificationModal}
          onClose={handleClose}
          onVerificationSucess={onVerificationSucess}
          userData={userData}
          userEmail={userEmail}
        />
      }
      {showAuthenticatorModal &&
        <AuthenticatorModal
          setLoading={setLoading}
          open={showAuthenticatorModal}
          onClose={handleClose}
          userData={userData}
          userEmail={userEmail}
          onVerificationSucess={onVerificationSucess}
          component={"head"}
        />
      }
      {loading && <Loading />}
    </div>
  );
}

export default Login;

const LoginInnerContainer = (props) => {
  const base_url = useSelector((state) => state.config.api_url);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const adminLogin2FA = useSelector((state) => state.config.adminLogin2FA);
  const [alertMsg, setAlertMsg] = useState(null)
  const [showLogoDarkImage, setShowLogoDarkImage] = useState(true)
  const [data, setData] = useState([]);
  const paymentProviderName = sessionStorage.getItem("paymentProviderName")
  const paymentProviderId = sessionStorage.getItem("paymentProviderId")
  const domainId = sessionStorage.getItem('domainId')

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    try {
      const res = await call({ ...LOGIN, url: base_url + LOGIN.url }, data);
      if (res.status === 200) {
        const responseData = res.data;
        if (responseData) {
          localDb.setVal("adminRole", responseData.role);
          localDb.setVal('email', data.email);
          localDb.setVal('viewPermission', responseData.viewPermission);
          localDb.setVal('userId', responseData?.userId);
          const isLocalhost = window.location.hostname === 'localhost';
          const has2FA = props && (isLocalhost ? false : adminLogin2FA === "true" || adminLogin2FA === true);
          if (has2FA) {
            const is2faEnabled = responseData.is2faActivated && responseData.is2faActivated.enabled;
            if (is2faEnabled) {
              if (!responseData.is2faActivated.secret) {
                props.closeModal && props.closeModal();
                props.setAuthenticatorModal && props.setAuthenticatorModal(responseData, responseData.email || '');
              } else {
                props.closeModal && props.closeModal();
                props.setVerifyModal && props.setVerifyModal(responseData, data.email);
              }
            } else {
              if (["PaymentProviderAdmin", "PaymentProviderUser"].includes(responseData.role)) {
                localDb.setVal("paymentProviderId", responseData.paymentProviderId);
                localDb.setVal("font", responseData.font);
              }
              if (responseData.domains && responseData.domains.length > 0) {
                const firstDomain = responseData.domains[0];
                localDb.setVal("paymentProviderHeaderColor", firstDomain.headerButtonLightBgColour);
                localDb.setVal("paymentProvideDomainId", firstDomain._id);
              }
              localDb.setVal('token', responseData.token);
              const redirectUrl = responseData.role === 'SuperAdmin' ? '/all-payment-providers' : '/merchants';
              window.location.href = redirectUrl;
            }
          } else {
            if (["PaymentProviderAdmin", "PaymentProviderUser"].includes(responseData.role)) {
              localDb.setVal("paymentProviderId", responseData.paymentProviderId);
              localDb.setVal("font", responseData.font);
            }
            if (responseData.domains && responseData.domains.length > 0) {
              const firstDomain = responseData.domains[0];
              localDb.setVal("paymentProviderHeaderColor", firstDomain.headerButtonLightBgColour);
              localDb.setVal("paymentProvideDomainId", firstDomain._id);
            }
            localDb.setVal('token', responseData.token);
            const redirectUrl = responseData.role === 'SuperAdmin' ? '/all-payment-providers' : '/merchants';
            window.location.href = redirectUrl;
          }
        }
      }
      
    } catch (err) {
      setAlertMsg(err?.response?.data?.err ? err?.response?.data?.err : 'Invalid username/password')
      console.error(err?.response?.data, 'err');
    }
  };
  const getImageUrl = async () => {
    return await checkImageUrl(`${image_base_url}/payment-provider/${paymentProviderId}/${domainId !== null ? domainId : ""}/logodark.png`)
  }
  useEffect(() => {
    const validUrl = getImageUrl(); 
    if (validUrl) {
      setShowLogoDarkImage(true)
    } else {
      setShowLogoDarkImage(false)
    }
  }, [domainId])
  return (<div className="login-card-inner">
    <MediaQuery minWidth={768}>
      <React.Fragment>
        <div className="login_logo" data-e2e="login_logo">
          {paymentProviderName && paymentProviderName !== 'null' && domainId && domainId !== "null" && showLogoDarkImage && <img style={{ maxHeight: "50px" }} src={`${image_base_url}/payment-provider/${paymentProviderId}/${domainId !== null ? domainId : ""}/logodark.png`} alt="" />}
          {domainId && domainId === "null" && !showLogoDarkImage && <img src={`${image_base_url}${logo}`} />}
        </div>
        <div className="login_heading">
          Sign In to {domainId && paymentProviderId && paymentProviderName ? paymentProviderName : `Crypton${<span style={{ color: '#0091FF' }}>admin</span>}`}
        </div>
      </React.Fragment>
    </MediaQuery>
    <div className="login_form">
      <div>
        <label className='login-form-inp-title'>
          <b>Email</b>
        </label>
        <div className='loginInputWrap'>
          <MediaQuery maxWidth={767}>
            <img className="loginformicon" src={`${image_base_url}${emailIcon}`} alt="mail-logo" style={{ height: "17px" }} />
          </MediaQuery>
          <input
            className="login_form_input"
            type="text"
            placeholder="johnsmith@cryptonpay.com"
            name="email"
            data-e2e="email"
            onChange={(e) => handleChange(e)}
            value={(data && data['email']) || ''}
            required
          />
        </div>
      </div>
      <div>
        <label className='login-form-inp-title'>
          <b>Password</b>
        </label>
        <div className='loginInputWrap'>
          <MediaQuery maxWidth={767}>
            {paymentProviderName && paymentProviderName !== 'null' && domainId && domainId !== "null" && showLogoDarkImage && <img style={{ maxHeight: "50px" }} src={`${image_base_url}/payment-provider/${paymentProviderId}/${domainId !== null ? domainId : ""}/logodark.png`} alt="" />}
            {domainId && domainId === "null" && !showLogoDarkImage && <img className="loginformicon" src={`${image_base_url}${passwordIcon}`} alt="pwd-logo" />}
          </MediaQuery>
          <input
            className="login_form_input"
            type="password"
            placeholder="Your password"
            name="password"
            data-e2e="password"
            onChange={(e) => handleChange(e)}
            value={(data && data['password']) || ''}
            required
          />
        </div>
      </div>
      <div className="login-form-inp-title" onClick={() => props.sethandleForgotpassModal(true)}>
        <p className='cursor-pointer'>Forgot password?</p>
      </div>
    </div>
    {alertMsg && (
      <div className="alert alert-danger" role="alert">
        {alertMsg}
      </div>
    )}
    <div
      className="login_button_get_started"
      data-e2e="login_button_get_started"
    >
      <button onClick={handleLogin} data-e2e="get_started">
        get started
      </button>
    </div>
  </div>
  )
}

const ForgotPassword = (props) => {
  const [userEmail, setUserEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [screen, setScreen] = useState(1)

  const base_url = useSelector((state) => state.config.api_url);
  const handleChange = (e) => {
    setEmailError('');
    setUserEmail(e.target.value);
  };

  const handleResetPassword = async () => {
    if (userEmail && userEmail !== "") {
      await call({ ...ADMIN_FORGOT_PASSWORD, url: base_url + ADMIN_FORGOT_PASSWORD.url }, {
        email: userEmail,
        hostname: window.location.hostname
      }).then((res) => {
        setScreen(2)
      })
        .catch((err) => {
          setEmailError(err.response.data.msg)
        });
    } else {
      setEmailError("Please enter valid email address!")
      console.err("error");
    }
  }
  return (
    <div className="login-card-inner">
      <MediaQuery minWidth={768}>
        <div className="login_heading mx-5">
          Forgot Password
        </div>
      </MediaQuery>
      {
        screen === 1 ?
          <React.Fragment>
            <div className="login_form">
              <div>
                <label className='login-form-inp-title'>
                  <b>Email</b>
                </label>
                <div className='loginInputWrap my-2'>
                  <input
                    className="login_form_input"
                    type="text"
                    placeholder="johnsmith@cryptonpay.com"
                    name="email"
                    data-e2e="email"
                    onChange={(e) => handleChange(e)}
                    value={userEmail}
                    required
                  />
                </div>
              </div>
              <p className='text-danger'>
                {emailError}
              </p>
            </div>
            <div
              className="login_button_get_started mt-5"
              data-e2e="login_button_get_started"
            >
              <button data-e2e="get_started" onClick={handleResetPassword}>
                Reset Password
              </button>
            </div>
          </React.Fragment>
          :
          <div className="w-auto mb-3">
            <div className="d-flex justify-content-center  align-items-center flex-column">
              <svg className="" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="59" height="59" rx="29.5" fill="#ECFDF3" /><circle cx="30" cy="30" r="15" fill="#62CA7A" /><path d="M22 30.25L23.5 28.75L27.25 32.5L35.5 24.25L37 25.75L27.25 35.5L22 30.25Z" fill="white" /><rect x="0.5" y="0.5" width="59" height="59" rx="29.5" stroke="#D1FADF" /></svg>
              <p className="login-form-inp-title">Thanks!</p>
              <p className="login-form-inp-title w-50 text-center">{`If a Cryptonadmin account exists for that email address, we will email you instructions for resetting your password.`}</p>
            </div>
          </div>
      }
      <div className="login-form-inp-title text-center cursor-pointer full-width" onClick={() => props.sethandleForgotpassModal(false)}>
        <p>Back to Login</p>
      </div>
    </div>
  )
}