import moment from 'moment';
import * as actionTypes from '../actions/actionTypes'
import { NUMBER_RECENT_DAYS_FILTER } from '../../components/Pages/Transactions/helpers';
const initialState = {
    searchText: "",
    orderBy: "desc",
    sortBy: "status.updated",
    fromDate: moment().subtract(NUMBER_RECENT_DAYS_FILTER, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    toDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    dateSelected: false,
    selectedStatusList: [],
    selectedStatusGroupList: [
        "Success",
        "Processing",
        "Require Approval",
        "Withdraw Request",
        "Pending",
        "Rejected",
        "Cancelled",  
        "Timed Out",
        "Abandoned"
    ],
    statusList: [],
    transactionStatusCodes: [],
    selectedMethod: [
        "crypto",
        "card",
        "wallet",
    ],
    selectedType: [
        "deposit",
        "withdrawal",
        "Transfer",
        "Admin-Deposit",
        "MerchantWalletBalanceUpdate",
        "SweepTransfer"
    ],
    acquirerNameData: [],
    selectedAcquirer: [],
    cryptos: [],
    selectedCryptos: [],
    issuerCountryList: [],
    selectedIssuerCountryList: [],
    selectedFiatCurrency: [
        "EUR",
        "USD",
        "GBP",
    ],
    selectedNetworkList: [
        "mainnet",
        "testnet",
    ],
    minFiatAmount: 0,
    maxFiatAmount: 0,
    merchantName: [],
    selectedMerchantName: [],
    isContainsNote: false,
    paymentProviderList: [],
    selectedPaymentProvider: [],
    merchantNameForHeader: "",
};

const setValue = (state, action, field) => {
    return {
        ...state,
        [field]: action.data
    }
}

const transactionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SEARCH_TEXT: return setValue(state, action, "searchText");
        case actionTypes.SET_ORDER_BY: return setValue(state, action, "orderBy");
        case actionTypes.SET_SORT_BY: return setValue(state, action, "sortBy");
        case actionTypes.SET_FROM_DATE: return setValue(state, action, "fromDate");
        case actionTypes.SET_TO_DATE: return setValue(state, action, "toDate");
        case actionTypes.SET_DATE_SELECTED: return setValue(state, action, "dateSelected");
        case actionTypes.SET_SELECTED_STATUS_LIST: return setValue(state, action, "selectedStatusList");
        case actionTypes.SET_SELECTED_STATUS_GROUP_LIST: return setValue(state, action, "selectedStatusGroupList");
        case actionTypes.SET_STATUS_LIST: return setValue(state, action, "statusList");
        case actionTypes.SET_TRANSACTION_STATUS_CODES: return setValue(state, action, "transactionStatusCodes");
        case actionTypes.SET_SELECTED_METHOD: return setValue(state, action, "selectedMethod");
        case actionTypes.SET_SELECTED_TYPE: return setValue(state, action, "selectedType");
        case actionTypes.SET_ACQUIRER_NAME_DATA: return setValue(state, action, "acquirerNameData");
        case actionTypes.SET_SELECTED_ACQUIRER: return setValue(state, action, "selectedAcquirer");
        case actionTypes.SET_CRYPTOS: return setValue(state, action, "cryptos");
        case actionTypes.SET_SELECTED_CRYPTOS: return setValue(state, action, "selectedCryptos");
        case actionTypes.SET_ISSUER_COUNTRY_LIST: return setValue(state, action, "issuerCountryList");
        case actionTypes.SET_SELECTED_ISSUER_COUNTRY_LIST: return setValue(state, action, "selectedIssuerCountryList");
        case actionTypes.SET_SELECTED_FIAT_CURRENCY: return setValue(state, action, "selectedFiatCurrency");
        case actionTypes.SET_SELECTED_NETWORK_LIST: return setValue(state, action, "selectedNetworkList");
        case actionTypes.SET_MIN_FIAT_AMOUNT: return setValue(state, action, "minFiatAmount");
        case actionTypes.SET_MAX_FIAT_AMOUNT: return setValue(state, action, "maxFiatAmount");
        case actionTypes.SET_MERCHANT_NAME: return setValue(state, action, "merchantName");
        case actionTypes.SET_MERCHANT_NAME_FOR_HEADER: return setValue(state, action, "merchantNameForHeader");
        case actionTypes.SET_SELECTED_MERCHANT_NAME: return setValue(state, action, "selectedMerchantName");
        case actionTypes.SET_IS_CONTAINS_NOTE: return setValue(state, action, "isContainsNote");
        case actionTypes.SET_PAYMENT_PROVIDER_LIST: return setValue(state, action, "paymentProviderList");
        case actionTypes.SET_SELECTED_PAYMENT_PROVIDER_LIST: return setValue(state, action, "selectedPaymentProvider");
        default:
            return state;
    }
};

export default transactionReducer;