import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    IconButton,
    Typography,
    styled
} from '@mui/material';
import { Close } from '@mui/icons-material';

const DeleteButton = styled(Button)({
    background: 'red',
    border: 0,
    borderRadius: 100,
    color: 'white',
    height: 30,
    width: "100%",
    padding: '0 30px',
    "&:hover": {
        background: 'red',
    }
});
const ConfirmDialog = ({ confirmDialog, setConfirmDialog,deleteEmail, deleteCardNumber, handleDeleteFormBlockList }) => {
    return (
        <Dialog open={confirmDialog} maxWidth="sm" fullWidth>
            <DialogTitle>Confirm Delete card</DialogTitle>
            <Box onClick={() => setConfirmDialog(false)} position="absolute" top={0} right={0}>
                <IconButton>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent>
                <Typography>Your are deleting the blocked card</Typography>
            </DialogContent>
            <DialogActions>
                <DeleteButton onClick={() => handleDeleteFormBlockList(deleteEmail ? deleteEmail : deleteCardNumber)} sx={{
                    "& .MuiButton-contained": {
                        backgroundColor: "red",
                    }
                }} variant="contained">
                    Confirm
                </DeleteButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog