import React, { useState } from "react";
const closeImage = "/cryptonpay/crytoicons/close.png";

function WithdrawalModel(props) {
  const { image_base_url, onClose, base_url, email, setLoading, selectedCoin, blockchaninAddress, merchantId, walletList, commonIndex,
    setNewBalance, setNewAmount,note, setNote,setCommonConfirmationModel, handleCloseModel, isContinue } = props;

  const [cryptoCurrency, setCryptoCurrency] = useState(selectedCoin);
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState(selectedCoin.amount);

  const handleWithdraw = () => {
    handleCloseModel();
    setCommonConfirmationModel(true);
  };

  let newBalance = 0;
  if (amount && amount > 0) {
    if (commonIndex === 4) {
      if (amount >= parseFloat(balance).toFixed(6)) {
        newBalance = 0;
      } else {
        newBalance = balance - Number(amount);
      }
    } else {
      newBalance = Number(amount) + balance;
    }
    setNewBalance(newBalance);
  }
  let name = commonIndex === 4 ? "Withdraw" : "Deposit"
  let disabled = (amount <= 0 || (commonIndex === 4 && amount > balance && newBalance !==0) || note === "");
  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <div></div>
        <div className="add-new-wallet-heading">{name}</div>
        <div
          className="filter_close_button"
          onClick={() => {
            onClose();
          }}
        >
          <img src={`${image_base_url}${closeImage}`} alt="closeImage" />
        </div>
      </div>
      {walletList.length > 0 && (commonIndex !== 4 || balance > 0) ? (
        <div>
          <div className="upload__merchantId">
            <div className="FilterModalInputGroup">
              <div className="FilterModalInputLabel">{`Current Balance`} </div>
              <input
                type="number"
                placeholder={parseFloat(balance).toFixed(6) + " " + cryptoCurrency.coin}
                disabled
              />
            </div>
          </div>

          <div className="upload__merchantId">
            <div className="FilterModalInputGroup">
              <div className="FilterModalInputLabel">{`Amount to ${name}`} </div>
              <input
                type="number"
                placeholder="Enter amount"
                name="amount"
                min="0"
                step="1"
                value={amount && Math.max(0, amount)}
                onChange={e => {
                  if (commonIndex === 4 && balance < Number(e.target.value)) {
                    setAmount(parseFloat(balance).toFixed(6));
                    setNewAmount(parseFloat(balance).toFixed(6));
                  } else {
                    setAmount(e.target.value ? Number(e.target.value) : e.target.value);
                    setNewAmount(e.target.value ? Number(e.target.value) : e.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className="upload__merchantId">
            <div className="FilterModalInputGroup">
              <div className="FilterModalInputLabel">Note</div>
              <input
                type="textarea"
                placeholder="Note..."
                onChange={(e)=>setNote(e.target.value)}
              />
            </div>
          </div>
          <div className="upload__merchantId">
            <div className="FilterModalInputGroup">
              <div className="FilterModalInputLabel">{`New Balance`} </div>
              <input
                type="number"
                placeholder={parseFloat(newBalance).toFixed(6) + " " + cryptoCurrency.coin}
                disabled
              />
            </div>
          </div>

          <div className="d-flex justify-content-center pt-4">
            <button
              disabled={disabled}
              type="button"
              onClick={() => {
                handleWithdraw();
              }}
              className="btn btn-primary btn-md rounded-pill add-btn w-100 text-uppercase"
              data-e2e={name}
            >
              {name}
            </button>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center mt-3">
          {balance && balance > 0 ? <h5>{`Wallets not found.`}</h5> :
            <h5>{`Insufficient balance to withdraw.`}</h5>}
        </div>
      )}
    </div>
  );
}

export default WithdrawalModel;
