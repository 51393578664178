import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { call, logoutAfterError } from '../../../../config/axios';
import {
  GET_SINGLE_B2C_USER_TRANSACTIONS,
} from '../../../../config/endpoints';
import localDb from '../../../../localDb';
import Loading from '../../../common/Loading';
import JSONPretty from 'react-json-pretty';
import { setHeader } from '../../../Redux/actions/commonAction';
import { Table } from 'reactstrap';
import Tooltip from '@mui/material/Tooltip';
import { getEmailVal } from '../../../common/CommonUtils';
const blockchainExternalLink = "/cryptonpay/blockchain_external_link.png";

function TransactionView(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const BTC_MAINNET = useSelector((state) => state.config.btc_mainnet);
  const BTC_TESTNET = useSelector((state) => state.config.btc_testnet);
  const ETH_MAINNET = useSelector((state) => state.config.eth_mainnet);
  const ETH_SEPOLIA = useSelector((state) => state.config.eth_sepolia);
  const TRX_MAINNET = useSelector((state) => state.config.trx_mainnet);
  const TRX_TESTNET = useSelector((state) => state.config.trx_testnet);
  
  const [data, setData] = useState(null);
  const [memoPoolReceiptData, setMemoPoolReceiptData] = useState(null);
  const [loading, setLoading] = useState(false);
  const email = localDb.getVal('email');
  let [walletId,setwalletId] = useState("");
  const [indexOfOpenTab, setIndexOpenTab] = useState(null);

  const view_transaction_url = useSelector((state) => state.config.api_url);
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const getCryptoLink = (network, cryptoId) => {
    let link = network + "-" + cryptoId;
    switch (link) {
      case "mainnet-BTC":
        return BTC_MAINNET;
      case "testnet-BTC":
        return BTC_TESTNET;
      case "mainnet-ETH":
        return ETH_MAINNET;
      case "goerli-ETH":
      case "testnet-ETH":
      case "testnet-DAI":
      case "testnet-USDC":
      case "goerli-USDC":
      case "testnet-USDT":
        return ETH_SEPOLIA;
      case "mainnet-TRX":
      case "mainnet-TRC20-USDT":
        return TRX_MAINNET;
      case "testnet-TRX":
      case "testnet-TRC20-USDT":
        return TRX_TESTNET;
      default:
        return "";
    }
  };

  const getCryptoIcon = (symbol) => {
    switch (symbol) {
      case 'BTC':
        return '/cryptonpay/crytoicons/bitcoin1.png';
      case 'ETH':
        return '/cryptonpay/crytoicons/eth1.png';
      case 'BCC':
        return '/cryptonpay/crytoicons/btt_cash1.png';
      case 'USDC':
        return '/cryptonpay/crytoicons/usd.png';
      case 'EOS':
        return '/cryptonpay/crytoicons/eos.png';
      default:
        return '/cryptonpay/crytoicons/usd.png';
    }
  };

  const viewSingleTransactionDetails = async () => {
    await call(
      {
        ...GET_SINGLE_B2C_USER_TRANSACTIONS,
        url: view_transaction_url + GET_SINGLE_B2C_USER_TRANSACTIONS.url,
      },
      { email: email, transactionId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          const emailVal = getEmailVal(res?.data?.b2cUserTrans?.b2cUser?.email)
          res.data.b2cUserTrans.b2cUser = {...res.data.b2cUserTrans.b2cUser, email: emailVal}
          setData(res.data.b2cUserTrans);
          if(res.data.walletId){
            setwalletId(res.data.walletId);
          }
          let temp =
            id.substring(0, 10) +
            '....' +
            id.substring(id.length - 6, id.length);
          props.handleId(temp);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, 'TransactionView > viewSingleTransactionDetails > err');
        logoutAfterError(err)
      });
  };



  useEffect(() => {
     viewSingleTransactionDetails();
     dispatch(setHeader('User Transactions'));
  }, [id]);

  return (
    <div className="wallet_container">
      {props.view === 'wallet' ? (
        <div className="wallet_listing_page">
          <div
            className="TransactionTableMain transaction_table_link"
            style={{ flex: 1, marginTop: '10.5px' }}
          >
            {data && data.balance && data.balance.length > 0 && (
              <Table responsive style={{ border: 'none' }}>
                <tbody
                  className="TransactionTable_BODY"
                  style={{ border: 'none' }}
                >
                  <tr className="TransactionTable_BODY_Header" >
                    <th className="TransactionTable_heading transaction_table">
                      Token
                    </th>
                    <th className="TransactionTable_heading transaction_table">
                      Balance
                    </th>
                    <th className="TransactionTable_heading transaction_table">
                      Last Updated
                    </th>
                  </tr>
                  {data &&
                    data.balance &&
                    data.balance.length > 0 &&
                    data.balance.map((item, index) => {
                      return (
                        <tr className="CP_TableRow" key={index}>
                          <td className="transactionsTable_item transactionsTable_item_fonts">
                            <img
                              src={`${image_base_url}${getCryptoIcon(
                                item.cryptoId
                              )}`}
                              alt={item.cryptoId}
                              className="currencyList_Icon"
                              data-e2e="currencyList_Icon"
                              width="25px"
                              height="25px"
                            />{' '}
                            <span>&nbsp;{item.cryptoName || 'N/A'}</span>{' '}
                            <span style={{ color: '#afb4be' }}>
                              ({item.cryptoId})
                            </span>
                          </td>
                          <td className="transactionsTable_item transactionsTable_item_fonts">
                            {item.balanceAmount ? item.balanceAmount : '-'}
                          </td>
                          <td className="transactionsTable_item transactionsTable_item_fonts">
                            {item.updated
                              ? moment(item.updated).format(
                                  'DD/MM/YYYY - HH:mm'
                                )
                              : 'N/A'}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                {data.balance.length > 0 && (
                  <div
                    className="walletsTotalBalance"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>Approx USD Balance</div>
                    <div>
                      {(data.balanceAmountUSDApprox &&
                        data.balanceAmountUSDApprox) ||
                        'N/A'}
                    </div>
                  </div>
                )}
              </Table>
            )}
          </div>
          <div
            className="wallet_view transaction_view_sub_Scroll"
            style={{ flex: 1, maxWidth: '432px' }}
          >
            <div className="transaction_head">Event Log </div>
            <div className="transaction_items text-align-left margin-t-10 margin-b-10">
              <strong>Current Status Message</strong>
              {
                data && data.status && data.status.message && data.status.message.length > 20 && data.status.message.includes("tranferTrasactionID=")
                  ? <div className="transaction_items text-align-left main-color">
                    <a href={"/transaction/" + data.status.message.split("=")[1]} className="LinkToTransaction">{data.status.message.split("=")[1]}</a>
                  </div>
                  : <div className="transaction_items text-align-left main-color">
                    <strong>{data && data.status && data.status.message && data.status.message}</strong>
                  </div>
              }
            </div>
            {
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {data.status &&
                data.status.history &&
                data.status.history.length > 0 ? (
                  data.status.history.reverse().map((ele, index) => {
                    return (
                      <div
                        className="transaction_subHeading"
                        style={{
                          display: 'inline-block',
                          marginBottom: '20px',
                        }}
                        key={index}
                      >
                        {ele.updated
                          ? moment(ele.updated).format('DD/MM/YYYY - HH:mm:ss.SSS')
                          : 'N/A'}
                        <div
                          className="transaction_items text-align-left"
                        >
                          {ele.code ? ele.code : 'N/A'}
                        </div>
                        {ele.code === 'In Use' ? (
                          <div
                            className="transaction_items text-align-left"
                          >
                            {ele.message ? (
                              <a
                                href={`https://${window.location.hostname}/transaction/${ele.message}`}
                              >
                                {ele.message}
                              </a>
                            ) : (
                              'N/A'
                            )}
                          </div>
                        ) : null}
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="No_Transactions_Found"
                    data-e2e="No_Transactions_Found"
                  >
                    No Data Found
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      ) : (
        <div>
          {data && (
            <div>
              <div className="transaction_view" style={{ marginBottom: '0px' }}>
                <div
                  className="transaction_view_sub transaction_view_sub_Scroll"
                  style={{ overflowX: 'hidden' }}
                >
                  <div className="transaction_head mb-3">Details </div>
                  {
                    <div>
                      {' '}
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">Type</div>
                        <div className="transaction_items text-capiltalize">
                          {(data && data.type && data.type) || "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">Network</div>
                        <div className="transaction_items">
                          {(data && data.network && data.network) || "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row MerchantDtls_cards_value">
                        <div className="transaction_subHeading">Status</div>
                        <div
                          className="transaction_items"
                          style={{ color: "#0091FF" }}
                        >
                          {(data && data.status && data.status.code && data.status.code) || "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row MerchantDtls_cards_value">
                        <div className="transaction_subHeading">
                          Status.Message
                        </div>
                        <div
                          className="transaction_items"
                          style={{ color: "#0091FF" }}
                          data-e2e={(data && data.status && data.status.message && data.status.message) || "N/A"}
                        >
                          {(data && data.status && data.status.message && data.status.message) || "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">User ID</div>
                        <div className="transaction_items">
                          {data.b2cUser && data.b2cUser.email || 'N/A'}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">
                          Last Updated
                        </div>
                        <div className="transaction_items">
                          {data.createdDate
                            ? moment(data.createdDate).format('DD/MM/YYYY')
                            : 'N/A'}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">
                          From Address
                        </div>
                        <div className="transaction_items_link">
                        
                        {data && data.type === "send" ? <Link
                              to={`/b2c-user-wallet/${walletId }`}
                            >
                              <div
                                style={{
                                  color: "#0091FF",
                                  textDecoration: "underline",
                                }}
                              >
                                {data && data.fromWallet
                                  ? data.fromWallet.substring(0, 10) +
                                    "....." +
                                    data.fromWallet.substring(
                                      data.fromWallet.length - 6,
                                      data.fromWallet.length
                                    )
                                  : "N/A"}
                              </div>
                            </Link> :
                            data && data.fromWallet
                                  ? data.fromWallet.substring(0, 10) +
                                    "....." +
                                    data.fromWallet.substring(
                                      data.fromWallet.length - 6,
                                      data.fromWallet.length
                                    )
                                  : "N/A"}
                        {data && data.fromWallet && data.fromWallet && (
                          <a
                            href={`${getCryptoLink(
                              data && data.network && data.network,
                              data &&
                                data.cryptoId &&
                                data.cryptoId
                            )}/${data.fromWallet && data.fromWallet}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="view-on-blockchain-btn-link"
                              src={`${image_base_url}${blockchainExternalLink}`}
                              alt={"external-link"}
                              data-e2e={"external-link"}
                              width="15px"
                              height="15px"
                            />
                          </a>
                        )}
                        </div>
                      </div>
                        <div className="transaction_items_row">
                          <div className="transaction_subHeading">
                            To Address
                          </div>
                          <div className="transaction_items_link">
                          {data && data.type === "receive" ? <Link
                              to={`/b2c-user-wallet/${walletId }`}
                            >
                              <div
                                style={{
                                  color: "#0091FF",
                                  textDecoration: "underline",
                                }}
                              >
                                {data && data.toWallet
                                  ? data.toWallet.substring(0, 10) +
                                    "....." +
                                    data.toWallet.substring(
                                      data.toWallet.length - 6,
                                      data.toWallet.length
                                    )
                                  : "N/A"}
                              </div>
                            </Link> :
                            data && data.toWallet
                                  ? data.toWallet.substring(0, 10) +
                                    "....." +
                                    data.toWallet.substring(
                                      data.toWallet.length - 6,
                                      data.toWallet.length
                                    )
                                  : "N/A"}
                                  {data && data.fromWallet && data.fromWallet && (
                          <a
                            href={`${getCryptoLink(
                              data && data.network && data.network,
                              data &&
                                data.cryptoId &&
                                data.cryptoId
                            )}/${data.toWallet && data.toWallet}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="view-on-blockchain-btn-link"
                              src={`${image_base_url}${blockchainExternalLink}`}
                              alt={"external-link"}
                              data-e2e={"external-link"}
                              width="15px"
                              height="15px"
                            />
                          </a>
                        )}
                        </div>
                        </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">
                          Crypto Value
                        </div>
                        <div className="transaction_items w-50">
                        {data.amount || 'N/A'}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">Transaction Fee</div>
                        <div className="transaction_items">
                          {data.transactionFee || 'N/A'}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">Gas Fee</div>
                        <div className="transaction_items">
                          {data.gasFee || 'N/A'}
                        </div>
                      </div>

                      {data && data.transactionRefLink &&
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              {`Transaction Link`}
                            </div>
                            <Tooltip title={data && data.transactionRefLink} placement="top">
                            <Link
                              to={`/transaction/${data && data.transactionRefLink}`}
                            >
                              <div
                                className="transaction_items cursor-pointer"
                                style={{
                                  color: "#0091FF",
                                  textDecoration: "underline",
                                }}
                              >
                                {data && data.transactionRefLink &&
                                  data.transactionRefLink 
                                  ? data.transactionRefLink.substring(0, 10) +
                                  "....." +
                                  data.transactionRefLink.substring(
                                    data.transactionRefLink.length - 6,
                                    data.transactionRefLink.length
                                  )
                                  : "N/A"}
                              </div>
                            </Link>
                            </Tooltip>
                          </div>
                      }
                    </div>
                  }
                </div>
                <div className="transaction_view_sub transaction_view_sub_Scroll">
                  <div className="transaction_head mb-3">Event Log </div>
                  {
                    <div>
                      {data && data.status &&
                        data.status.history &&
                        data.status.history.map((ele, index) => {
                          return (
                            <div
                              className="transaction_subHeading"
                              style={{
                                display: "inline-block",
                                marginBottom: "20px",
                              }}
                              key={index}
                            >
                              {ele.updated
                                ? moment(ele.updated).format(
                                    'DD/MM/YYYY - HH:mm:ss.SSS'
                                  )
                                : "N/A"}{" "}
                              -{" "}
                              <div
                                className="transaction_items eventLogItemWrap"
                                style={{ textAlign: "left" }}
                              >
                                <span>{ele.code}</span>
                                {ele.message && (
                                  <span>
                                    {indexOfOpenTab === index ? (
                                      <i
                                        className="fa fa-minus eventLogItemExpander"
                                        onClick={() => {
                                          setIndexOpenTab(null);
                                        }}
                                      />
                                    ) : (
                                      <i
                                        className="fa fa-plus eventLogItemExpander"
                                        onClick={() => {
                                          setIndexOpenTab(index);
                                        }}
                                      />
                                    )}
                                  </span>
                                )}
                              </div>
                              {ele.message && indexOfOpenTab === index && (
                                <div className="eventLogItemMsg">
                                  {ele.message && (
                                    <div>
                                      {ele && ele.message && ele.message.toString().includes("{") &&
                                      ele && ele.message && ele.message.toString().charAt(0) ===
                                        "{" ? (
                                        <JSONPretty
                                          id="json-pretty"
                                          data={ele && ele.message}
                                        ></JSONPretty>
                                      ) : <div style={{whiteSpace: "pre-wrap"}}>{(
                                        ele && ele.message
                                      )}</div>}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  }
                            

                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {loading && <Loading />}
    </div>
  );
}

export default TransactionView;
