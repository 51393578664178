import React, { useState, useEffect } from "react";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import EditIcon from '@mui/icons-material/Edit';import { Table } from "reactstrap";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useSelector } from "react-redux";
import {
  GET_B2C_USER_DETAILS,
  ADD_USER_NOTES,
  USER_SUMMARY,
  GET_B2C_SPREAD_LEVEL,
  UPDATE_B2C_USER_SPREAD_LEVEL,
  UPDATE_B2C_USER_RISK_LEVEL,
  GET_B2C_RISK_LEVEL,
  GET_CLIENT_PRESIGNED_URL
} from "../../../../config/endpoints";
import Loading from "../../../common/Loading";
import { call, logoutAfterError } from "../../../../config/axios";
import localDb from "../../../../localDb";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import copy from "copy-to-clipboard";
import SingleUserTransactions from "./SingleUserTransactions";
import SingleUserBalance from "./SingleUserBalance";
import { Menu, MenuItem } from "@mui/material";
import UpdateUserDetails from "./UpdateUserDetails";
import NavTabs from "../../../common/NavTabs";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { getEmailVal, isViewPermissionValid } from "../../../common/CommonUtils";
const closeImage = "/cryptonpay/crytoicons/close.png";
const tick_green = "/cryptonpay/crytoicons/tick_green.png";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 46,
  height: 28,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 30,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(19px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(19px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 24,
    height: 24,
    borderRadius: 12,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 32 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const tabs = [
  {
    name: "Settings",
    id: 1,
  },
  {
    name: "Balances",
    id: 2,
  },
  {
    name: "Transactions",
    id: 3,
  },
  {
    name: "User Summary",
    id: 4,
  },
  {
    name: "Activity Log",
    id: 5,
  },
];
const StyledMenu = styled((props) => (
  <Menu
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          width: 10,
          height: 10,
          right: "50%",
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
    }}
    transformOrigin={{ horizontal: "center", vertical: "top" }}
    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
    {...props}
  />
))(({ theme }) => ({}));

const defaultUserImage = "/homeb2c/user-default-pic.svg";
const copyIcon = "/homeb2c/transaction-cpy.png";

function UserManagement(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");

  const [profileImage, setProfileImage] = useState("")
  const [showCoppied1, toggleCoppied1] = useState(false);
  const [showCoppied2, toggleCoppied2] = useState(false);

  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [viewKyc, setViewKyc] = useState(false);
  const [successEditBalances, setSuccesEditBalances] = useState(false);

  const [isUpdateUserDetails, setIsUpdateUserDetails] = useState(false);

  const handleUpdateCloseModel = () => {
    setIsUpdateUserDetails(false);
  };

  const [spreadAnchor, setSpreadDropDown] = useState(null);
  const spreadDropDown = Boolean(spreadAnchor);

  const [riskLevelAnchor, setRiskLevelDropDown] = useState(null);
  const riskDropDown = Boolean(riskLevelAnchor);

  const handleOpenSpreadDropDown = (event) => {
    setSpreadDropDown(event.currentTarget);
  };

  const handleOpenRiskLevelDropDown = (event) => {
    setRiskLevelDropDown(event.currentTarget);
  };

  const handleCloseSpreadDropDown = () => {
    setSpreadDropDown(null);
  };

  const handleCloseRiskLevelDropDown = () => {
    setRiskLevelDropDown(null);
  };

  const handleClickOpenSettings = () => {
    setViewKyc(true);
  };

  const handleCloseSettings = () => {
    setViewKyc(false);
  };



  const [userNote, setUserNote] = useState("");
  const [addNote, setAddNote] = useState(false);
  const handleCloseAddNote = () => {
    setAddNote(false);
  };
  const [newNotes, setNewNotes] = useState("");
  const [error, setError] = useState("");
  const [updateNote, setUpdateNote] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [spreadData, setSpreadData] = useState([]);
  const [riskLevels, setRiskLevels] = useState([]);

  const handleUpdateNotes = () => {
    setUpdateNote(true);
    setAddNote(true);
  };

  if (successAlert) {
    setInterval(() => {
      setSuccessAlert(false);
    }, 3000);
  }

  const addNotes = async () => {
    if (newNotes == "") {
      setError("Notes should be required");
      return;
    } else {
      let payload = {
        email: email,
        userId: window.location.pathname.split("/")[2],
        notes: newNotes,
      };
      setLoading(true);
      await call(
        {
          ...ADD_USER_NOTES,
          url: base_url + ADD_USER_NOTES.url,
        },
        payload
      )
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            setLoading(false);
            setAddNote(false);
            setNewNotes("");
            setSuccessAlert(true);
            getUsersDetails();
            window.scrollTo({
              top: 0,
              right: 0,
              behavior: "smooth",
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message, "err");
          logoutAfterError(err)
        });
    }
  };

  const getUsersDetails = async () => {
    let payload = {
      email: email,
      userId: window.location.pathname.split("/")[2],
    };

    setLoading(true);
    await call(
      {
        ...GET_B2C_USER_DETAILS,
        url: base_url + GET_B2C_USER_DETAILS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          const emailVal = getEmailVal(res?.data?.email)
          res.data = {...res?.data, email: emailVal}
          setUserDetails(res.data);
          if (res.data.notes) {
            setUserNote(res.data.notes);
            setNewNotes(res.data.notes);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const {
    externalUserId,
    clientComment,
    moderationComment,
    rejectLabels,
    reviewAnswer,
    reviewRejectType,
    reviewStatus,
  } = { ...userDetails.kyc };
  
  const getAllSpreadLevels = async () => {
    setLoading(true);
    await call(
      {
        ...GET_B2C_SPREAD_LEVEL,
        url: base_url + GET_B2C_SPREAD_LEVEL.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setSpreadData(res.data);
          const imgSrc = `${image_base_url}/payment-provider-client/${window?.location?.pathname?.split("/")[2]}/logo`
          isImgUrl(imgSrc).then((data) => {
            if (data === true) {
              setProfileImage(imgSrc) 
            } else {
              const imgSrc = `${image_base_url}${defaultUserImage}`
              setProfileImage(imgSrc) 
            }
          })
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };
  const getAllRiskLevels = async () => {
    setLoading(true);
    await call(
      {
        ...GET_B2C_RISK_LEVEL,
        url: base_url + GET_B2C_RISK_LEVEL.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setRiskLevels(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const updateUserSpreadLevel = async (spreadId, userId) => {
    handleCloseSpreadDropDown();
    setLoading(true);
    await call(
      {
        ...UPDATE_B2C_USER_SPREAD_LEVEL,
        url: base_url + UPDATE_B2C_USER_SPREAD_LEVEL.url,
      },
      { email: email, userId: userId, spreadId: spreadId }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setSuccesEditBalances((prev)=>!prev)
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const updateUserRiskLevel = async (riskId, userId) => {
    handleCloseRiskLevelDropDown();
    setLoading(true);
    await call(
      {
        ...UPDATE_B2C_USER_RISK_LEVEL,
        url: base_url + UPDATE_B2C_USER_RISK_LEVEL.url,
      },
      { email: email, userId: userId, riskId: riskId }

    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setSuccesEditBalances((prev)=>!prev)
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const getMinRiskLevel = () => {
    return  Math.min.apply(Math, riskLevels.map(a => a.riskLevel))
}

async function isImgUrl(url) {
  const img = new Image();
  img.src = url;

  try {
    await new Promise((resolve, reject) => {
      img.onerror = () => reject(false);
      img.onload = () => resolve(true);
    });
  } catch (error) {
    return false;
  }

  return true;
}

  const changeProfileImage = async (img) => {
    if (img === null) {
      alert('please attach image')
      return
    }
    const payload = {
      adminRole: localDb.getVal("adminRole"),
      email: email,
      clientId: window.location.pathname.split("/")[2],
      imageType: "clientLogo",
    };

    try{
      const res = await call(
        {
          ...GET_CLIENT_PRESIGNED_URL,
          url: base_url + GET_CLIENT_PRESIGNED_URL.url,
        },
        payload
        )
        if(res){
          const signedUrl = res?.data?.data[0]
          const imageType = "jpeg"
            const result = await fetch(signedUrl, {
              method: "PUT",
              body: img,
              headers: { "Content-Type": `image/${imageType ? imageType : "jpeg"}`, "cache-control": "public, max-age=0" },
            })
              if(result){
                const imgSrc = `${image_base_url}/payment-provider-client/${window?.location?.pathname?.split("/")[2]}/logo`
                setProfileImage(imgSrc) 
                setLoading(false);
                window.location.reload()
              }else{
                setLoading(false);
                console.error("err: ", err);
              }
        }
        else{
          setLoading(false);
          console.error("err: ", err);
        }
        
       }catch(err){
          setLoading(false);
          console.error(err.message, "err");
       }
  }
  useEffect(() => {
    getAllSpreadLevels();
    getAllRiskLevels();
  }, []);
  useEffect(() => {
    getAllSpreadLevels();
  }, [profileImage]); 
  
  useEffect(() => {
    getUsersDetails();
  }, [successEditBalances]);
  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
        <CommonHeader
          backButton={true}
          name={`${userDetails.firstName} ${userDetails.lastName}`}
          view="b2c-users"
          id={id}
        />
        <Dialog
          open={viewKyc}
          onClose={handleCloseSettings}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="myAccoutDetailsMain">
            <div className="myAccoutDetailsCard" style={{ maxWidth: "540px" }}>
              <div className="d-flex justify-content-between">
                <h5
                  className="myAccoutOptModalHeading card-title text-center"
                  data-e2e="kyc-details-heading"
                >
                  KYC Details{" "}
                </h5>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handleCloseSettings}
                >
                  <CloseIcon size={24} />
                </div>
              </div>

              <div
                className="row pt-3 border-bottom pb-2"
                style={{ width: "500px", fontSize: "15px" }}
              >
                <div className="col-4 pr-0 font-weight-bold ">
                  External User ID
                </div>
                <div className="col-1 pl-0 pr-0">:</div>
                <div className="col-7">{externalUserId}</div>
              </div>

              <div
                className="row pt-3 border-bottom pb-2"
                style={{ width: "500px", fontSize: "15px" }}
              >
                <div className="col-4 pr-0 font-weight-bold ">
                  Client Comment{" "}
                </div>
                <div className="col-1 pl-0 pr-0">:</div>
                <div className="col-7">{clientComment}</div>
              </div>

              <div
                className="row pt-3 border-bottom pb-2"
                style={{ width: "500px", fontSize: "15px" }}
              >
                <div className="col-4 pr-0 font-weight-bold ">
                  Moderation Comment
                </div>
                <div className="col-1 pl-0 pr-0">:</div>
                <div className="col-7">{moderationComment}</div>
              </div>

              <div
                className="row pt-3 border-bottom pb-2"
                style={{ width: "500px", fontSize: "15px" }}
              >
                <div className="col-4 pr-0 font-weight-bold ">
                  Reject Labels
                </div>
                <div className="col-1 pl-0 pr-0">:</div>
                <div className="col-7">{rejectLabels?.join(", ")}</div>
              </div>

              <div
                className="row pt-3 border-bottom pb-2"
                style={{ width: "500px", fontSize: "15px" }}
              >
                <div className="col-4 pr-0 font-weight-bold ">
                  Review Answer
                </div>
                <div className="col-1 pl-0 pr-0">:</div>
                <div className="col-7 text-success">{reviewAnswer}</div>
              </div>

              <div
                className="row pt-3 border-bottom pb-2"
                style={{ width: "500px", fontSize: "15px" }}
              >
                <div className="col-4 pr-0 font-weight-bold ">
                  Review Reject Type
                </div>
                <div className="col-1 pl-0 pr-0">:</div>
                <div className="col-7">{reviewRejectType}</div>
              </div>

              <div
                className="row pt-3 "
                style={{ width: "500px", fontSize: "15px" }}
              >
                <div className="col-4 pr-0 font-weight-bold ">
                  Review Status
                </div>
                <div className="col-1 pl-0 pr-0">:</div>
                <div className="col-7">{reviewStatus}</div>
              </div>
            </div>
          </div>
        </Dialog>

        <div className="user_profile">
          <div className="profile">
            <div className="profile_pic">
              <img
                className="pic"
                src={profileImage} 
                alt="Profile_pic"
              />
              <input
                className={`select__file ${isViewPermissionValid? "disabled": ""}`}
                style={{ fontSize: "20px" }}
                type="file"
                data-max-size="5000000"
                accept="image/*"
                id="profileImg"
                onClick={isViewPermissionValid? null: ()=>{}}
                // onClick={()=>changePls(e)}
                onChange={(e)=>changeProfileImage(
                  ...e.target.files
                )}
              ></input>
              <label htmlFor="profileImg" className={`upload_submit cursor-pointer" for="profileImg ${isViewPermissionValid? "disabled": ""}`}>
                <div className={`d-flex justify-content-center`}>{`Upload Image`}</div>
              </label>
            </div>

            <div className="profile_detail">
              <div className="details">
                <div className="pb-3 d-flex justify-content-between">
                  <div className="block-header">{`Details`}</div>
                  <div
                    className={`block-header ${isViewPermissionValid ? 'disabled' : ''}`}
                    onClick={isViewPermissionValid ? null : () => setIsUpdateUserDetails(!isUpdateUserDetails)}
                  >
                    <span className="edit_b2c_user">{`Edit`}</span>
                    <EditIcon
                      fontSize="large"
                      color='action'
                      data-e2e="edit_button"
                      className={`cursor-pointer text-primary ${isViewPermissionValid ? 'disabled' : ''}`}
                    />
                  </div>
                </div>
                <div className="pt-2 pb-2 border-bottom">
                  <span className="leftContent">Creation Date</span>
                  <span className="rightContent">
                    {userDetails.createdDate ? userDetails.createdDate : "N/A"}
                  </span>
                </div>
                <div className="pt-2 pb-2 border-bottom">
                  <span className="leftContent">Full Name</span>
                  <span className="rightContent">
                    {userDetails.firstName} {userDetails.lastName}
                    {userDetails && userDetails.firstName && (
                      !showCoppied1 ? (
                        <img
                          src={`${image_base_url}/homeb2c/transaction-cpy.png`}
                          alt="image"
                          className="cursor-pointer copyImg"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                      copy(userDetails.firstName + " " + userDetails.lastName);
                            toggleCoppied1(true);
                            setTimeout(() => {
                              toggleCoppied1(false);
                            }, 2000);
                          }}
                        />
                      ) : (
                          <DoneAllIcon
                            style={{ color: "#6fbf73", marginLeft: "5px" }}
                          />
                        
                      )
                    )}
                  </span>
                </div>
                <div className="pt-2 pb-2 border-bottom">
                  <span className="leftContent">Email</span>
                  <span className="rightContent">
                    {userDetails.email}
                    {userDetails && userDetails.email && (
                      !showCoppied2 ? (
                        <img
                          src={`${image_base_url}/homeb2c/transaction-cpy.png`}
                          alt="image"
                          className="cursor-pointer copyImg"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                      copy(userDetails.email);
                            toggleCoppied2(true);
                            setTimeout(() => {
                              toggleCoppied2(false);
                            }, 2000);
                          }}
                        />
                      ) : (
                          <DoneAllIcon
                            style={{ color: "#6fbf73", marginLeft: "5px" }}
                          />
                        
                      )
                    )}
                  </span>
                </div>
                <div className="pt-2 pb-2 border-bottom">
                  <span className="leftContent">Phone</span>
                  <span className="rightContent">
                    {userDetails && userDetails.mobile
                      ? userDetails.mobile
                      : "N/A"}
                  </span>
                </div>
                <div className="pt-2 pb-2 border-bottom">
                  <span className="leftContent">Address</span>
                  <span className="rightContent">
                    {userDetails && userDetails.addressOne
                      ? userDetails.addressOne
                      : "N/A"}
                  </span>
                </div>
                <div className="pt-2 pb-2 border-bottom">
                  <span className="leftContent">Country</span>
                  <span className="rightContent">
                    {userDetails && userDetails.country
                      ? userDetails.country
                      : "N/A"}
                  </span>
                </div>
                <div className="pt-2 pb-2">
                  <span className="leftContent">Password</span>
                  <span className="rightContent">
                    {"********"}{" "}
                    <span className="pl-2">
                      <EditIcon
                        fontSize="large"
                        color='action'
                        data-e2e="edit_button"
                        className="cursor-pointer text-primary"
                      />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="user_history">
            <div className="form_wrap">
              <div className="card loginSignupCard GlobalSettingsTable table-shadow">
                <div className="pt-0">
                  <NavTabs
                    tabs={tabs}
                    selectedTab={selectedTab}
                    changeTab={(tab) => {
                      setSelectedTab(tab);
                    }}
                  />
                  <div
                    className="TransactionTableMain"
                    style={{
                      marginTop: "-1.8px",
                      marginBottom: "0px",
                    }}
                  >
                    {selectedTab.id === 3 && (
                      <SingleUserTransactions
                        setLoading={setLoading}
                        setSuccesEditBalances={setSuccesEditBalances}
                      />
                    )}

                    {selectedTab.id === 2 && (
                      <div>
                        <SingleUserBalance
                          user_email={userDetails.email}
                          domainNameUrl={userDetails.domainNameUrl}
                          setLoading={setLoading}
                          setSuccesEditBalances={setSuccesEditBalances}
                        />
                        <div className="mt-3 mb-2 d-flex justify-content-between user_transaction_items pl-2 pr-2">
                          <div>Notes</div>
                          <div className="text-primary">
                            {`Add Notes`}
                            <span className="rightContent ml-2">
                              <i
                                className="fa fa-plus cursor-pointer text-primary"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {selectedTab.id === 1 && (
                      <div className="pt-3 table_border">
                        <div className="pt-2 pb-2 border-bottom">
                          <span className="leftContent">
                            Block access to account
                          </span>
                          <span className="rightContent">
                          <div className={`cursor-pointer ${isViewPermissionValid ? 'disabled' : ''}`} disabled={isViewPermissionValid}>
                              <Stack direction="row" spacing={1} alignItems="center">
                                  <AntSwitch
                                      inputProps={{ "aria-label": "ant design" }}
                                      // onChange={() => {
                                      //   setProfileDropDown(!profileDropdownOpen);
                                      //   handleTheme()
                                      // }}
                                      // checked={localDb.getVal('colorMode') === 'light' ? false : true}
                                  />
                              </Stack>
                          </div>
                      </span>
                        </div>

                        <div className="pt-2 pb-2 border-bottom">
                            <span className="leftContent">{`Risk Level`}</span>
                            <div
                                className={`button-container-flex rightContent text-primary ${isViewPermissionValid ? 'disabled' : ''}`}
                                onClick={isViewPermissionValid ? null : handleOpenRiskLevelDropDown}
                            >
                                <button
                                    style={{ marginTop: "2px" }}
                                    className={`${
                                        riskDropDown
                                            ? "navItemActive navItemWrapA text-dark"
                                            : "navItemWrapA text-dark"
                                    } nav-btn-right text-dark`}
                                    id="demo-customized-button"
                                    aria-controls={
                                        riskDropDown
                                            ? "demo-customized-menu"
                                            : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={riskDropDown ? "true" : undefined}
                                    variant="contained"
                                    disableelevation="true"
                                    disabled={isViewPermissionValid}
                                >
                                    {userDetails.riskLevel
                                        ? userDetails.riskLevel.riskLevel
                                        : getMinRiskLevel()}
                                </button>
                                <i
                                    className={`mt-0 fa fa-chevron-${
                                        riskLevelAnchor ? "up" : "down"
                                    } FilterModalInputDropUpIcon`}
                                />
                            </div>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    "aria-labelledby": "demo-customized-button",
                                }}
                                anchorEl={riskLevelAnchor}
                                open={riskDropDown}
                                onClose={handleCloseRiskLevelDropDown}
                            >
                                {riskLevels &&
                                    riskLevels.length > 0 &&
                                    riskLevels.map((ele, i) => {
                                        return (
                                            <MenuItem
                                                key={i}
                                                onClick={() =>
                                                    updateUserRiskLevel(
                                                        ele._id,
                                                        userDetails._id
                                                    )
                                                }
                                                disableRipple
                                            >
                                                {ele.riskLevel}
                                            </MenuItem>
                                        );
                                    })}
                            </StyledMenu>
                        </div>
                        <div className={`pt-2 pb-2 border-bottom ${isViewPermissionValid ? 'disabled' : ''}`}>
                          <span className="leftContent">{`Spread Level`}</span>
                          <div
                              className="button-container-flex rightContent text-primary"
                              onClick={isViewPermissionValid ? null : handleOpenSpreadDropDown}
                          >
                              <button
                                  style={{ marginTop: "2px" }}
                                  className={`${
                                      spreadDropDown
                                          ? "navItemActive navItemWrapA text-dark"
                                          : "navItemWrapA text-dark"
                                  } nav-btn-right text-dark`}
                                  id="demo-customized-button"
                                  aria-controls={
                                      spreadDropDown
                                          ? "demo-customized-menu"
                                          : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={
                                      spreadDropDown ? "true" : undefined
                                  }
                                  variant="contained"
                                  disableelevation="true"
                                  disabled={isViewPermissionValid}
                              >
                                  {userDetails.spreadLevel
                                      ? userDetails.spreadLevel.name
                                      : "N/A"}
                              </button>
                              <i
                                  className={`mt-0 fa fa-chevron-${
                                      spreadAnchor ? "up" : "down"
                                  } FilterModalInputDropUpIcon`}
                              />
                          </div>
                          <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                  "aria-labelledby": "demo-customized-button",
                              }}
                              anchorEl={spreadAnchor}
                              open={spreadDropDown}
                              onClose={handleCloseSpreadDropDown}
                          >
                              {spreadData &&
                                  spreadData.length > 0 &&
                                  spreadData.map((ele, i) => {
                                      return (
                                          <MenuItem
                                              key={i}
                                              onClick={() =>
                                                  updateUserSpreadLevel(
                                                      ele._id,
                                                      userDetails._id
                                                  )
                                              }
                                              disableRipple
                                          >
                                              {ele.name}
                                          </MenuItem>
                                      );
                                  })}
                          </StyledMenu>
                      </div>
                        <div className="pt-2 pb-2 border-bottom">
                          <span className="leftContent">
                            User's trust level
                          </span>
                          <span className="rightContent">Trusted</span>
                        </div>
                        <div className="pt-2 pb-2 border-bottom">
                          <span className="leftContent">KYC data</span>
                          <span
                            className="rightContent text-primary cursor-pointer"
                            onClick={handleClickOpenSettings}
                          >
                            <u>View Here</u>
                          </span>
                        </div>
                        <div className="pt-2 pb-2 border-bottom">
                          <span className="leftContent">2FA Settings</span>
                          <span className="rightContent">
                            {userDetails && userDetails.twoFA
                              ? userDetails.twoFA.enabled
                                ? "ON"
                                : "OFF"
                              : "OFF"}
                          </span>
                        </div>
                        <div className="pt-2 pb-2">
                          <span className="leftContent">Notes</span>
                          {userNote == "" ? (
                            <span
                              className={`rightContent ${isViewPermissionValid? "disabled": ""}`}
                              onClick={isViewPermissionValid? null: () => setAddNote(true)}
                            >
                              {" "}
                              <i
                                className="fa fa-plus cursor-pointer text-primary"
                                aria-hidden="true"
                              ></i>
                            </span>
                          ) : (
                            <span
                            className={`rightContent ${isViewPermissionValid? "disabled": ""}`}
                            onClick={isViewPermissionValid ? null: () => handleUpdateNotes()}
                            >
                              {" "}
                              <EditIcon
                                fontSize="large"
                                color='action'
                                data-e2e="edit_button"
                                className="cursor-pointer text-primary"
                              />
                            </span>
                          )}
                        </div>
                        <Dialog
                          open={addNote}
                          onClose={handleCloseAddNote}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <div
                            className="create_account_container"
                            style={{ width: "520px", marginTop: "0px" }}
                          >
                            <div className="create_account_header">
                              <div></div>
                              <div className="create_account_heading">Note</div>
                              <div
                                className="filter_close_button"
                                onClick={handleCloseAddNote}
                              >
                                <img
                                  src={`${image_base_url}${closeImage}`}
                                  alt="closeImage"
                                />
                              </div>
                            </div>

                            <div className="create_account_form">
                              <div style={{ margin: "20px 0px" }}>
                                <div>
                                  <textarea
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="10"
                                    className="add-note-input"
                                    placeholder={
                                      updateNote
                                        ? "Update note..."
                                        : "Add note..."
                                    }
                                    onChange={(e) => {
                                      setNewNotes(e.target.value);
                                    }}
                                    value={newNotes}
                                  />
                                  {error != "" && (
                                    <div className="add_note_error">
                                      {error}
                                    </div>
                                  )}
                                  <div className="d-flex justify-content-center pt-4">
                                    <button
                                      onClick={() => addNotes()}
                                      type="button"
                                      className="btn btn-primary btn-md rounded-pill add-btn"
                                      data-e2e={`${
                                        updateNote ? "update" : "add"
                                      }-btn`}
                                    >
                                      {updateNote ? "UPDATE" : "ADD"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Dialog>

                        {successAlert && (
                          <Stack
                            className="position-absolute successAlert"
                            spacing={2}
                          >
                            <Alert
                              icon={
                                <img
                                  src={`${image_base_url}${tick_green}`}
                                  alt="tick_green"
                                  style={{ width: "32px" }}
                                />
                              }
                              onClose={() => {
                                setSuccessAlert(false);
                              }}
                              className="MuiAlert-root"
                            >
                              {updateNote
                                ? "Note has been updated."
                                : "Note has been added."}
                            </Alert>
                          </Stack>
                        )}

                        {userNote != "" && (
                          <div className="pt-2 pb-2">
                            <div className="b2c-notes-container p-3">
                              <span className="b2c-notes">{userNote}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {selectedTab.id === 4 && (
                      <UserSummary
                        userId={userDetails._id}
                        setLoading={setLoading}
                      />
                    )}

                    {selectedTab.id === 5 && (
                      <div className="pt-3 table_border">
                        <div className="pt-3 pb-3 border-bottom">
                          <div className="user_summary_title">{`07/08/2021 - 17:56`}</div>
                          <div className="user_summary_content">{`Transaction Note has been added`}</div>
                        </div>

                        <div className="pt-3 pb-3 border-bottom">
                          <div className="user_summary_title">{`07/08/2021 - 17:56`}</div>
                          <div className="user_summary_content">{`Spread Level has been changed`}</div>
                        </div>

                        <div className="pt-3 pb-3 border-bottom">
                          <div className="user_summary_title">{`07/08/2021 - 17:56`}</div>
                          <div className="user_summary_content">{`Access Blocked to account`}</div>
                        </div>

                        <div className="pt-3 pb-3 border-bottom">
                          <div className="user_summary_title">{`07/08/2021 - 17:56`}</div>
                          <div className="user_summary_content">{`Risk Level has been changed`}</div>
                        </div>

                        <div className="pt-3 pb-3 border-bottom">
                          <div className="user_summary_title">{`07/08/2021 - 17:56`}</div>
                          <div className="user_summary_content">{`Transaction Note has been added`}</div>
                        </div>

                        <div className="pt-3 pb-3 border-bottom">
                          <div className="user_summary_title">{`07/08/2021 - 17:56`}</div>
                          <div className="user_summary_content">{`Spread Level has been changed`}</div>
                        </div>

                        <div className="pt-3 pb-3 border-bottom">
                          <div className="user_summary_title">{`07/08/2021 - 17:56`}</div>
                          <div className="user_summary_content">{`Access Blocked to account`}</div>
                        </div>

                        <div className="pt-3 pb-3 border-bottom">
                          <div className="user_summary_title">{`07/08/2021 - 17:56`}</div>
                          <div className="user_summary_content">{`Risk Level has been changed`}</div>
                        </div>

                        <div className="pt-3 pb-3">
                          <div className="user_summary_title">{`07/08/2021 - 17:56`}</div>
                          <div className="user_summary_content">{`Transaction Note has been added`}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isUpdateUserDetails && (
            <UpdateUserDetails
              open={isUpdateUserDetails}
              onClose={handleUpdateCloseModel}
              data={userDetails}
              setLoading={setLoading}
              getUsersDetails={getUsersDetails}
            />
          )}
        </div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
}

export default UserManagement;

const UserSummary = ({ userId, setLoading }) => {
  const base_url = useSelector((state) => state.config.api_url);
  const [totalNumberOfTransactions, setTotalNumberOfTransactions] = useState(0);
  const [netUsdEquivalent, setNetUsdEquivalent] = useState(0);
  const [cryptoWiseSummary, setCryptoWiseSummary] = useState([]);
  const email = localDb.getVal("email");

  const getUserSummary = async () => {
    setLoading(true);
    await call(
      {
        ...USER_SUMMARY,
        url: base_url + USER_SUMMARY.url,
      },
      { userId: userId, email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setTotalNumberOfTransactions(res.data.numberOfTransaction);
          setNetUsdEquivalent(res.data.totalUsdAmount);
          setCryptoWiseSummary(res.data.cryptoSummary);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };


  useEffect(() => {
    getUserSummary();
  }, []);


  return (
    <div>
      <div className="pt-3 table_border">
        <div className="pt-3 pb-3 border-bottom">
          <div className="user_summary_title">{`Number of transactions`}</div>
          <div className="user_summary_content">
            {totalNumberOfTransactions}
          </div>
        </div>

        <div className="pt-3 pb-3 border-bottom">
          <div className="user_summary_title">{`Net USD Equivalent`}</div>
          <div className="user_summary_content">
            {parseFloat(netUsdEquivalent).toFixed(8)}
          </div>
        </div>

        {cryptoWiseSummary.length > 0 &&
          cryptoWiseSummary.map((ele, i) => (
            <div className="pt-3 pb-3 border-bottom" key={i}>
              <div className="user_summary_title">{ele._id}</div>
              <div className="user_summary_content">
                {parseFloat(ele.sum).toFixed(8)}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
