import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  CREATE_MERCHANT, GET_CRYPTOS, MERCHANT_IMAGE_UPLOAD_SIGNED_URL, GET_MERCHANT_WITH_EMAIL, GET_FRAUD_SERVICE, GET_CARD_AQUIRER_SERVICES
} from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";
import CreateAccountStageOne from "./CreateAccountStageOne";
import CreateAccountStageTwo from "./CreateAccountStageTwo";
function CreateMerchant(props) {
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const [loading, setLoading] = useState(true);

  const [stageOne, setStageOne] = useState(false);
  const [merchantName, setMerchantName] = useState("");
  const [merchantEmail, setMerchantEmail] = useState("");
  const [password, setPassword] = useState("");
  const [timeLockingPeriod, setTimeLockingPeriod] = useState("");
  const [kytRequired, setKytRequired] = useState(true);
  const [acceptExactAmountsOnly, setAcceptExactAmountsOnly] = useState(true);
  const [userWalletDeposit, setUserWalletDeposit] = useState(true);
  const [merchantWithdrawalAutoApproval, setMerchantWithdrawalAutoApproval] = useState(false)
  const [merchantEmailValidation, setMerchantEmailValidation] = useState(false)
  const [cryptos, setCryptos] = useState([]);
  const [selectedCryptos, setSelectedCryptos] = useState([]);
  const [antiFraudServiceData, setAntiFraudServiceData] = useState([])
  const [getAcquirerRoutingNewData, setGetAcquirerRoutingNewData] = useState([]);
  const [cardAcquireName, setCardAcquireName] = useState({ label: "Card Aquirer Service Name", value: "" })
  const [cardAcquireNameNew, setCardAcquireNameNew] = useState({ label: "Card Aquirer Service Name New", value: "" })

  const [errorMsg, setErrorMsg] = useState("");
  const [errorField, setErrorField] = useState(false);


  const handleNextStageOne = (data) => {
    if (
      !data.merchant_name ||
      data.merchant_name === "" ||
      data.merchant_name === "undefined"
    ) {
      setErrorMsg("Merchant Name is required.");
      setErrorField("merchant_name");
      return;
    } else if (data.merchant_name.length < 4) {
      setErrorMsg("merchant name atleat contain 4 charecters");
      setErrorField("merchant_name");
      return;
    } else if (
      !data.merchant_email ||
      data.merchant_email === "" ||
      data.merchant_email === "undefined"
    ) {
      setErrorMsg("Merchant Email is required.");
      setErrorField("merchant_email");
      return;
    } else if (
      merchantEmailValidation === true
    ) {
      setErrorField("merchant_email");
      setErrorMsg("Merchant Email is already Taken")
    } else if (
      !data.create_password ||
      data.create_password === "" ||
      data.create_password === "undefined"
    ) {
      setErrorMsg("Password is required.");
      setErrorField("create_password");
      return;
    } else if (
      !data.confirm_password ||
      data.confirm_password === "" ||
      data.confirm_password === "undefined" ||
      data.create_password !== data.confirm_password
    ) {
      setErrorMsg("Password does not match.");
      setErrorField("confirm_password");
      return;
    } else if (data.create_password === data.confirm_password) {
      setMerchantName(data.merchant_name);
      setMerchantEmail(data.merchant_email);
      setPassword(data.create_password);
      setStageOne(true);
    } else if (
      !kytRequired ||
      kytRequired === "" ||
      kytRequired === "undefined"
    ) {
      setErrorMsg("KYT required is required field.");
      setErrorField("kytRequired");
      return;
    }
  };

  const handleTimePeriod = (value) => {
    setTimeLockingPeriod(value);
  };
  const handelKytRequired = (value) => {
    setKytRequired(value);
  };
  const handelAcceptExactAmountsOnly = (value) => {
    setAcceptExactAmountsOnly(value);
  };

  const handleUserWalletDeposit = (val) => {
    setUserWalletDeposit(val);
  };

  const handleMerchantAutoWithdrawal = (val) => {
    setMerchantWithdrawalAutoApproval(val);
  };

  const getSignedUrl = async (merchantId, profileImage) => {
    setLoading(true);
    const payload = {
      email: localDb.getVal("email"),
      merchantId: merchantId,
    };
    await call(
      {
        ...MERCHANT_IMAGE_UPLOAD_SIGNED_URL,
        url: base_url + MERCHANT_IMAGE_UPLOAD_SIGNED_URL.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          uploadMerchantProfileImage(res.data.data, profileImage, res.data.key);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        props.handleCloseAddNew();
        logoutAfterError(err)
      });
  };

  const uploadMerchantProfileImage = async (url, img, key) => {
    setLoading(true);
    await fetch(url,{ method: "PUT", body: img, headers: { 'Content-Type': 'image/jpeg' }, "cache-control": "public, max-age=0" })
      .then(async (res) => {
        props.handleCloseAddNew();
        window.location.reload();
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        console.log("err: ", err)
        props.handleCloseAddNew();
      })
  };

  const handleCreateAccount = async (data) => {
    const {
      transaction_fee,
      time_period_locking,
      deposit_spread,
      withdrawal_spread,
      credit_limit_usd,
      profileImage,
      transactionFeeOnFailure
    } = data;
    const payload = {
      name: merchantName,
      merchantEmail: merchantEmail,
      password: password,
      transactionFee: transaction_fee,
      cryptos: selectedCryptos,
      rateExpiryPeriod: timeLockingPeriod,
      withdrawalSpread: withdrawal_spread,
      kytRequired: kytRequired,
      acceptExactAmountsOnly: acceptExactAmountsOnly,
      depositSpread: deposit_spread,
      creditLimit: credit_limit_usd,
      userWalletDeposit: userWalletDeposit,
      cardAcquireRouting: cardAcquireName.value !== "None" ? cardAcquireName.value : "",
      cardAcquirerRoutingNew: cardAcquireNameNew.value !== "None" ? cardAcquireNameNew.value : "",
      merchantWithdrawalAutoApproval: merchantWithdrawalAutoApproval,
      email: localDb.getVal("email"),
      paymentProviderId: props.paymentProviderId,
      transactionFeeOnFailure: transactionFeeOnFailure
    };
    if (
      !transaction_fee ||
      transaction_fee === "" ||
      transaction_fee === "undefined"
    ) {
      setErrorMsg("Transaction Fee is required.");
      setErrorField("transaction_fee");
      return;
    } else if (
      !timeLockingPeriod ||
      timeLockingPeriod === "" ||
      timeLockingPeriod === "undefined"
    ) {
      setErrorMsg("Time Locking Period is required.");
      setErrorField("time_period_locking");
      return;
    } else if (
      !deposit_spread ||
      deposit_spread === "" ||
      deposit_spread === "undefined"
    ) {
      setErrorMsg("Deposit Spread is required.");
      setErrorField("deposit_spread");
      return;
    } else if (
      !withdrawal_spread ||
      withdrawal_spread === "" ||
      withdrawal_spread === "undefined"
    ) {
      setErrorMsg("Withdrawal Spread is required.");
      setErrorField("withdrawal_spread");
      return;
    } else if (
      !credit_limit_usd ||
      credit_limit_usd === "" ||
      credit_limit_usd === "undefined"
    ) {
      setErrorMsg("Credit Limit is required.");
      setErrorField("credit_limit_usd");
      return;
    }
    setLoading(true);

    await call(
      { ...CREATE_MERCHANT, url: base_url + CREATE_MERCHANT.url },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          (profileImage !== "" || profileImage !== undefined) &&
            getSignedUrl(
              res && res.data && res.data.merchantId && res.data.merchantId,
              profileImage
            );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const getCryptos = async () => {
    setLoading(true);

    await call(
      {
        ...GET_CRYPTOS,
        url: base_url + GET_CRYPTOS.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);

          setCryptos(res.data.cryptos);

          let cryptos = res.data.cryptos.map((crypto) => {
            return crypto._id;
          });
          setSelectedCryptos(cryptos);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };
  const getMerchantWithEmail = async (merchantEmail) => {
    await call(
      {
        ...GET_MERCHANT_WITH_EMAIL,
        url: base_url + GET_MERCHANT_WITH_EMAIL.url,
      },
      {
        email: email,
        merchantEmail: merchantEmail
      }
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data && res.data.email) {
            setMerchantEmailValidation(true)
          } else {
            setMerchantEmailValidation(false)

          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };
  const adminRole = localStorage.getItem("adminRole");
  useEffect(() => {
    getCryptos();
    getCardAcquireRouting()
    getCardAcquireRoutingNew();
   if(adminRole === "PaymentProviderAdmin"){
    setTimeLockingPeriod(30)
   }
  }, []);
  

  const CREATEACCOUNTSTAGEONE = [
    {
      input_type: "input_box",
      id: "merchant name",
      type: "text",
      name: "merchant_name",
      label: "Merchant Name",
      placeholder: "Enter Name",
    },
    {
      input_type: "input_box",
      id: "merchant email",
      type: "email",
      name: "merchant_email",
      label: "Merchant Email",
      placeholder: "Enter Email",
    },
    {
      input_type: "input_box",
      id: "create password",
      type: "password",
      name: "create_password",
      label: "Password",
      placeholder: "Create Password",
    },
    {
      input_type: "input_box",
      id: "confirm password",
      type: "password",
      name: "confirm_password",
      label: "Confirm Password",
      placeholder: "Confirm Password",
    },
    {
      input_type: "select",
      id: "kytRequired",
      type: "select",
      name: "kytRequired",
      label: "KYT Required",
      placeholder: "Choose KYT required or not",
      isMulti: false,
      disableField:adminRole === "PaymentProviderAdmin"? true :false,
      options: [
        {
          value: true,
          label: "KYT Required",
        },
        {
          value: false,
          label: "KYT Not Required",
        },
      ],
    },
    {
      input_type: "select",
      id: "acceptExactAmountsOnly",
      type: "select",
      name: "acceptExactAmountsOnly",
      label: "Accept Amounts Type",
      placeholder: "Choose Amount Accept type",
      isMulti: false,
      options: [
        {
          value: true,
          label: "Accept Exact Amounts Only",
        },
        {
          value: false,
          label: "Accept Any Amounts",
        },
      ],
    },
    {
      input_type: "input_button",
      id: "create_account_stage_1",
      type: "button",
      name: "CONTINUE",
      onClick: handleNextStageOne,
    },
  ];

  const CREATEACCOUNTSTAGETWO = [
    {
      input_type: "input_box",
      name: "transactionFeeOnFailure",
      type: "number",
      label: "Transaction fee on failure (in cents)",
      placeholder: "Transaction fee on failure (in cents)",
      id: "transactionFeeOnFailure",
    },
    {
      input_type: "input_box",
      id: "transaction_fee",
      type: "input",
      name: "transaction_fee",
      label: "Transaction Fee",
      placeholder: "Transaction Fee",
    },
    {
      input_type: "select",
      id: "acceptable_crypto",
      type: "select",
      name: "acceptable_crypto",
      label: "Acceptable Crypto",
      placeholder: "Select Cryptos",
      isMulti: true,
    },
    // {
    //   input_type: "select",
    //   id: "cardAcquireRouting",
    //   type: "select",
    //   name: "cardAcquireRouting",
    //   label: "Card Aquirer Service Name",
    //   placeholder: "Card Aquirer Service Name",
    //   isMulti: false,
    //   options: antiFraudServiceData.length > 0 ? antiFraudServiceData.map((item) => ({ label: item.acquireRoutingServiceName, value: item._id })) : [
    //     { label: "None", value: "None" },
    //   ],
    // },
    {
      input_type: "select",
      id: "cardAcquireRoutingNew",
      type: "select",
      name: "cardAcquireRoutingNew",
      label: "Card Aquirer Service Name New",
      placeholder: "Card Aquirer Service Name New",
      isMulti: false,
      options: getAcquirerRoutingNewData?.length > 0 ? getAcquirerRoutingNewData?.map((item) => ({ label: item?.acquirerRoutingServiceName, value: item?._id })) : [
        { label: "None", value: "None" },
      ],
    },
    {
      input_type: "select",
      id: "time_period_locking",
      type: "select",
      name: "time_period_locking",
      label: "Rate Expiry Period",
      placeholder: "Select %",
      isMulti: false,
      disableField:adminRole === "PaymentProviderAdmin"? true :false,
      options: [
        {
          value: "5",
          label: "5 mins",
        },
        {
          value: "10",
          label: "10 mins",
        },
        {
          value: "30",
          label: "30 mins",
        },
        {
          value: "60",
          label: "60 mins",
        },
      ],
    },
    {
      input_type: "input_box",
      id: "deposit_spread",
      type: "number",
      name: "deposit_spread",
      label: "Deposit Spread",
      placeholder: "Input %",
    },
    {
      input_type: "input_box",
      id: "withdrawal_spread",
      type: "number",
      name: "withdrawal_spread",
      label: "Withdrawal Spread",
      placeholder: "Input %",
    },
    {
      input_type: "input_box",
      id: "creditLimitUSD",
      type: "number",
      name: "credit_limit_usd",
      label: "Credit Limit (USD)",
      placeholder: "Eg: 23.02",
    },
    {
      input_type: "select",
      id: "merchantWithdrawalAutoApproval",
      type: "select",
      name: "merchantWithdrawalAutoApproval",
      label: "Merchant  Withdrawal Auto-Approval",
      placeholder: "Merchant Withdrawal Auto Approval required or not",
      isMulti: false,
      options: [
        {
          value: true,
          label: "Enable",
        },
        {
          value: false,
          label: "Disable",
        },
      ],
    },
    {
      input_type: "select",
      id: "userWalletDeposit",
      type: "select",
      name: "userWalletDeposit",
      label: "User Wallet Deposit",
      placeholder: "User Wallet Deposit required or not",
      isMulti: false,
      options: [
        {
          value: true,
          label: "Enable",
        },
        {
          value: false,
          label: "Disable",
        },
      ],
    },
    {
      input_type: "file",
      label: "Merchant Image (only *jpg)",
      id: "merchantProfile",
      type: "file",
      name: "profileImage",
      placeholder: "Merchant Image Upload",
    },
    {
      input_type: "input_button",
      id: "create_account_stage_3",
      type: "button",
      name: "Create Account",
      onClick: handleCreateAccount,
    },
  ];

  const getCardAcquireRouting = async () => {
    const payload = {
      email: localDb.getVal("email"),
      paymentProvider: props.paymentProviderId
    };
    await call(
      {
        ...GET_FRAUD_SERVICE,
        url: base_url + GET_FRAUD_SERVICE.url
      },
      payload
    ).then((res) => {
      if (res.status === 200) {
        setAntiFraudServiceData(res.data)
      }
    }).catch((err) => {
      console.error(err);
    })
  }
  // GET_CARD_AQUIRER_SERVICES
  const getCardAcquireRoutingNew = async () => {
    const payload = {
      email: localDb.getVal("email"),
      paymentProvider: props?.paymentProviderId
    };
    await call(
      {
        ...GET_CARD_AQUIRER_SERVICES,
        url: base_url + GET_CARD_AQUIRER_SERVICES?.url
      },
      payload
    ).then((res) => {
      if (res?.status === 200) {
        setGetAcquirerRoutingNewData(res?.data)
      }
    }).catch((err) => {
      console.error(err);
    })
  }

  return (
    <div>
      <div>
        {stageOne ? (
          <CreateAccountStageTwo
            data={CREATEACCOUNTSTAGETWO}
            handleCloseAddNew={props.handleCloseAddNew}
            cryptos={cryptos}
            selectedCryptos={selectedCryptos}
            setSelectedCryptos={setSelectedCryptos}
            timeLockingPeriod={timeLockingPeriod}
            handleTimePeriod={handleTimePeriod}
            errorMsg={errorMsg}
            errorField={errorField}
            userWalletDeposit={userWalletDeposit}
            handleUserWalletDeposit={handleUserWalletDeposit}
            merchantWithdrawalAutoApproval={merchantWithdrawalAutoApproval}
            handleMerchantAutoWithdrawal={handleMerchantAutoWithdrawal}
            setCardAcquireName={setCardAcquireName}
            cardAcquireName={cardAcquireName}
            setCardAcquireNameNew={setCardAcquireNameNew}
            cardAcquireNameNew={cardAcquireNameNew}
          />
        ) : (
          <CreateAccountStageOne
            data={CREATEACCOUNTSTAGEONE}
            setMerchantEmail={setMerchantEmail}
            getMerchantWithEmail={getMerchantWithEmail}
            handleCloseAddNew={props.handleCloseAddNew}
            errorMsg={errorMsg}
            errorField={errorField}
            setErrorMsg={setErrorMsg}
            handelKytRequired={handelKytRequired}
            handelAcceptExactAmountsOnly={handelAcceptExactAmountsOnly}
            kytRequired={kytRequired}
            acceptExactAmountsOnly={acceptExactAmountsOnly}
          />
        )}
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default CreateMerchant;
