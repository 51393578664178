import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const STATUS_STYLES = {
  Success: {
    icon: DoneIcon,
    color: "#0ce846",
    backgroundColor: "#D1FADF",
  },
  Processing: {
    icon: AccessTimeIcon,
    color: "#101828",
    backgroundColor: "#F2F4F7",
  },
  Rejected: {
    icon: WarningAmberIcon,
    color: "#B54708",
    backgroundColor: "#FEF0C7",
  },
};

function CardStatus({ status, total, totalSum, percentage, fromDate, toDate, updateQueryParams }) {
  const statusStyle = STATUS_STYLES[status] || {};
  const StatusIcon = statusStyle.icon;
  const goToTransactionPage = async () => {
    let navigateLink;
    if(status === "Processing"){
      navigateLink = await updateQueryParams({"exceptList": ["Success", "Rejected"]});
    }
    else{
    navigateLink = await updateQueryParams({"statusgroup": status})
    }
  window.open(navigateLink, '_blank');
	};
  return (
    <div className="card_dashboard_status__card flex-grow shadow-md cursor-pointer" onClick={goToTransactionPage}>
      <div className="card_dashboard_status__card__text_area border-left-blue"></div>
      <div className="card_dashboard_status__card__text_area">
        <div className="card_dashboard_status__card__text_area__left">
          <div className="card_dashboard_status__card__text_area__left__status">
            {status}
          </div>
          <div className="card_dashboard_status__card__text_area__left__total">
            {total?.toLocaleString() || 0}
          </div>
        </div>
        <div className="px-2">
          {StatusIcon && (
            <div className="flex justify-end w-full">
              <div
                className="rounded p-2 w-fit"
                style={{ backgroundColor: statusStyle.backgroundColor }}
              >
                <StatusIcon
                  fontSize="large"
                  style={{ color: statusStyle.color }}
                />
              </div>
            </div>
          )}
          <div className="text-[18px] mt-2 text-right"><span>{percentage && typeof percentage === "string" && `(${Math.round(Number(percentage))+"%"})`}</span> $ {parseFloat(totalSum)?.toLocaleString() || 0}</div>
        </div>
      </div>
    </div>
  );
}

export default CardStatus;
