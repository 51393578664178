import React from 'react';

const ShowMoreButton = (props) => {
    return (
        <div>
            {props.showMoreButton ? (
                <div className="Show_more_mobile_wrap">
                    <button disabled={props.page === 1} onClick={() => {
                        props.handleShowLess();
                    }} className="left_btn"><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                    <div className="pages">{`Page ${props.page} of ${Math.round(props.totalCount / 10)}`}</div>
                    <button disabled={props.page === (Math.round(props.totalCount / 10) + 1)} className="right_btn" onClick={() => {
                        props.handleShowMore();
                    }}><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
                </div>
            ) : null}
        </div>
    )
};

export default ShowMoreButton;