import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { call } from '../../../../config/axios';
import { ADMIN_GENERATE_QR_CODE, ADMIN_USER_VERIFY_2FA_TOKEN } from '../../../../config/endpoints';
import localDb from '../../../../localDb';
import { getHeaderButtonStyle } from "./common"

const AuthenticatorModal = (props) => {
    const { userData } = props;
    const base_url = useSelector((state) => state.config.api_url);
    const [error, setError] = useState('');
    const [verificationCode2fa, setVerificationCode2fa] = useState('');
    const [imageData, setImageData] = useState(null);
    const [secret, setSecret] = useState(null);

    const [colorMode, setColorMode] = useState(null);
    const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
    const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
    const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
    const [headerLightTextColour, setheaderLightTextColour] = useState(null);

    const checkValueInSS = (value) => sessionStorage.getItem(value) && sessionStorage.getItem(value) !== '' && sessionStorage.getItem(value) !== 'null';
    useEffect(() => {
        generateQRcode();
        ['colorMode', 'headerButtonDarkBgColour', 'headerButtonLightBgColour', 'headerDarkTextColour', 'headerLightTextColour'].forEach((value) => {
            if (checkValueInSS(value)) {
                switch (value) {
                    case 'colorMode':
                        setColorMode(sessionStorage.getItem(value));
                        break;
                    case 'headerButtonDarkBgColour':
                        setheaderButtonDarkBgColour(sessionStorage.getItem(value));
                        break;
                    case 'headerButtonLightBgColour':
                        setheaderButtonLightBgColour(sessionStorage.getItem(value));
                        break;
                    case 'headerDarkTextColour':
                        setheaderDarkTextColour(sessionStorage.getItem(value));
                        break;
                    case 'headerLightTextColour':
                        setheaderLightTextColour(sessionStorage.getItem(value));
                        break;
                    default:
                        break;
                }
            }
        });
    }, []);

    const generateQRcode = async () => {
        try {
            const url = base_url + ADMIN_GENERATE_QR_CODE.url
            const token = localDb.getVal('token');
            const viewPermission = localDb.getVal('viewPermission');
            const response = await fetch(
                url,{
                method: 'POST',
                body: JSON.stringify({ email: localDb?.getVal("email"), paymentProviderName : null }),
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token || '',
                    'x-view-permission': viewPermission || ''
                },
            });            
    
            if (!response.ok) {
                throw new Error('Failed to generate QR code');
            }
    
            const responseData = await response.json();
            const { qrCode , secret} = responseData;
            setImageData(qrCode);
            setSecret(secret);
        } catch (error) {
            console.error('Error generating QR code:', error);
            alert('Failed to generate QR code');
        }
    };

    const onVerifyCode = async () => {
        if (verificationCode2fa.length === 6) {
            props.setLoading(true);
            try {
                const payload = {
                    email: localStorage.getItem("email"),
                    user2faToken: verificationCode2fa,
                    changeActivation: 'activate',
                    secret: secret.base32,
                    encoding: 'base32',
                    authenticator: true,
                    token: userData.token,
                };
                const data = await call({ ...ADMIN_USER_VERIFY_2FA_TOKEN, url: base_url + ADMIN_USER_VERIFY_2FA_TOKEN.url }, payload);
                if (data && data.data && data.data.success === true) {
                    if (["PaymentProviderAdmin", "PaymentProviderUser"].includes(userData.role)) {
                        localDb.setVal("paymentProviderId", userData.paymentProviderId);
                        localDb.setVal("font", userData.font);
                    }
                    if (userData.domains && userData.domains.length > 0) {
                        const firstDomain = userData.domains[0];
                        localDb.setVal("paymentProviderHeaderColor", firstDomain.headerButtonLightBgColour);
                        localDb.setVal("paymentProvideDomainId", firstDomain._id);
                    }
                    localDb.setVal('token', userData.token);
                    localStorage.setItem('authenticationDone', 'true');
                    if (props.component === 'head') {
                        props.onVerificationSucess(userData, userData && userData.email);
                    } else {
                        props.setIs2faActivated();
                        localStorage.setItem('2FAStatus', true);
                    }
                    const redirectUrl = userData.role === 'SuperAdmin' ? '/all-payment-providers' : '/merchants';
                    window.location.href = redirectUrl;
                    props.onClose();
                } else {
                    props.setLoading(false);
                    setError('Invalid verification code!');
                }
            } catch (err) {
                localStorage.clear();
                props.setLoading(false);
                console.error(err);
            }
        } else {
            setError('Invalid token! 2FA token must have 6 digits only');
        }
    };
    const onInputKeyDown = (e) => {
        // Handle Enter key press
        if (e.key === 'Enter') {
            e.preventDefault();
            // Verify code only if it's valid (length is 6)
            if (verificationCode2fa.length === 6) {
                onVerifyCode();
            }
        }
    };
    const disabledVerify = verificationCode2fa.length !== 6;
    const headerButtonStyle = getHeaderButtonStyle(colorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour);

    return (
        <Dialog open={props.open} className="theme-modal">
            <div className="theme-modal-body">
                <div className="theme-modal-close">
                    <ClearIcon onClick={props.onClose} className="icon-close" />
                </div>
                <form>
                    <p className="theme-modal-title">Authenticator</p>
                    <div className="theme-modal-authenticator">
                        <img src={imageData} alt="QR Code" />
                    </div>
                    <p className="theme-modal-text">Scan the QR Code in Google's Authenticator app and input the verification code.</p>
                    <br />
                    <div className="theme-modal-field">
                        <p className="theme-modal-field-label">Verification Code</p>
                        <input
                            value={verificationCode2fa}
                            type="text"
                            className="theme-modal-field-input"
                            placeholder=""
                            onKeyDown={onInputKeyDown}
                            onChange={(e) => {
                                setError('');
                                setVerificationCode2fa(e.target.value);
                            }}
                        />
                        {error !== '' && <div className="errorMessage">{error}</div>}
                    </div>
                    <div className="theme-btn-block theme-modal-submit full-width">
                        <button
                            type="button"
                            className={`theme-btn ${disabledVerify ? 'disabled' : ''}`}
                            onClick={() => {
                                if (!disabledVerify) {
                                    onVerifyCode();
                                }
                            }}
                            style={headerButtonStyle}
                        >
                            <p>Verify</p>
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    );
};

export default AuthenticatorModal;
