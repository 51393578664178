import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import localDb from "../../../../localDb";
import {
    UPDATE_TRANSACTION,
} from '../../../../config/endpoints';
import { call, logoutAfterError } from '../../../../config/axios';
import Dialog from "@mui/material/Dialog";
import { styled } from '@mui/material/styles';

const useStyles = styled(Dialog)({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "white"
        }
    }
});

const withdrawalchangeStatusList = [
    "WithdrawalFlow:ApprovedForProcessing",
    "WalletManagementService:WithdrawalCompleted",
    "WithdrawalFlow:Rejected",
    "WalletManagementService:WithdrawalRejectionCompleted"
];

const changeStatusList = [
    "DepositFlow:AwaitingDeposit",
    "DepositMonitor:MonitoringMemPool",
    "DepositMonitor:DepositReceivedToMemPool",
    "DepositMonitor:RejectDeposit",
    "DepositMonitor:DepositManualApproved",
    "DepositMonitor:DepositManualRejected",
    "DepositMonitor:DepositCompleted",
    "DepositMonitor:DepositManuallyCompleted"
];
const ChangeTransactionStatus = (props) => {
    const email = localDb.getVal("email");
    const base_url = useSelector((state) => state.config.api_url);
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(props.status);
    const [statusMsg, setStatusMsg] = useState("");
    const [error, setError] = useState("");
    const [statusList, setStatusList] = useState([]);
    const classes = useStyles;
    const transId = useParams().id;
    const handleNotifactionStatus = (status) => {
        setSelectedStatus(status);
        setShowStatusDropdown(false);
    };
    useEffect(() => {
        if (props.transactionType && props.transactionType === "withdrawal") {
            setStatusList(withdrawalchangeStatusList);
        } else {
            setStatusList(changeStatusList);
        }
    }, [])

    const updateTransactionStatus = async () => {
        if (statusMsg == "") {
            setError("Comment should be required");
            return
        } else {
            props.setLoading(true);
            await call(
                {
                    ...UPDATE_TRANSACTION,
                    url: base_url + UPDATE_TRANSACTION.url,
                },
                { email: email, transactionId: transId, message: statusMsg, status: selectedStatus }
            )
                .then((res) => {
                    props.setLoading(false);
                    if (res.status === 200) {
                        setSelectedStatus("");
                        setStatusList([]);
                        localStorage.setItem("setMsg", true);
                        props.onClose();
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    props.setLoading(false);
                    console.log(err.message, 'err');
                    logoutAfterError(err)
                });
        }
    };

    const statusName = (sts) => {
        let newStatus = sts.split(":");
        return newStatus[1];
    }
    return (
        <div>
            <Dialog open={true} className={`${classes.root} change_status_modal mt-4`} style={{ height: 'fit-content' }}>
                <div className="change_status_body">
                    <img
                        src={`${image_base_url}/homeb2c/change_status_logo.svg`}
                        alt="image"
                        className="d-block m-auto"
                    />
                    <div className="change_trans_header mt-4 ">
                        Change transaction status
                    </div>
                    <div className="change_trans_sure_want mt-4">
                        Are you sure you want to change the transaction status?
                    </div>
                    <div className="FilterModalInputGroup">
                        <div className="FilterModalInputLabel">Status</div>
                        <div
                            className="FilterModalInputDropdown"
                            onClick={() => {
                                setShowStatusDropdown(true);
                            }}
                        >
                            <div
                                className="FilterModalInputText wrap_word"
                                style={{ flexGrow: 0 }}
                            >
                                {statusName(selectedStatus)}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {showStatusDropdown && (
                            <div
                                className="FilterModalCurrencyDropdownlist"
                            >
                                <div
                                    className="FM_currencyDrop_All"
                                    onClick={() => {
                                        setShowStatusDropdown(false);
                                    }}
                                >
                                    <span
                                        className="FM_currencyDrop_AllSelector wrap_word"
                                    >
                                        {statusName(selectedStatus)}
                                    </span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i
                                            className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                        />
                                    </span>
                                </div>
                                <div className="statusList_wrap">
                                    {statusList.map((status, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="currencyListItemWrap wrap_word"
                                                onClick={() => handleNotifactionStatus(status)}
                                            >
                                                <div
                                                    className="currencyListIconNameWrap"
                                                >
                                                    <div className="currencyList_Name wrap_word">
                                                        {statusName(status)}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                    <textarea
                        placeholder="Add note.."
                        data-e2e="add-note"
                        value={statusMsg}
                        name="message"
                        className="change_trans_note mt-4"
                        cols="40"
                        rows="5"
                        onChange={(e) => { setError(""); setStatusMsg(e.target.value) }}
                    />
                    {error && (
                        <div className="add_note_error">
                            {"Please Add something "}
                        </div>
                    )}
                    <div className="notify_btn_wrap">
                        <div
                            className="change_trans_button mt-4"
                            onClick={() => updateTransactionStatus()}
                            data-e2e="save-btn"
                        >
                            Save
                        </div>
                        <div
                            className="change_trans_go_back mt-4"
                            onClick={() => props.onClose()}
                            data-e2e="go-back-btn"
                        >
                            Go Back
                        </div>
                    </div>

                </div>
            </Dialog>
        </div>
    )
}

export default ChangeTransactionStatus