import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CPMultiSelect } from '../../../common/CPMultiSelect';
import ResetModal from "../CommonPages/ResetModal";
import DateFilterInput from "../../../common/DateFilterInput";
const closeImage = "/cryptonpay/crytoicons/close.png";
const selectedCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_filled.png";
const emptyCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_empty.png";

function MerchantFilter(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [showProviderDropdown, setShowProviderDropdown] = useState(false);
  const [openPopup, resetOpenPopup] = useState(false)
  const closeReset = () => resetOpenPopup(false)
  const toggleStatusSelection = (symbol) => {
    if (props.selectedStatusList.includes(symbol)) {
      let newList = props.selectedStatusList.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedStatusList(newList);
    } else {
      let newList = [...props.selectedStatusList];
      newList.push(symbol);
      props.setSelectedStatusList(newList);
    }
  };
	const formatDate = (date) => moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  const onChangeDate = (start, end, label) => {
    props.setFromDate(moment(start).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    props.setToDate(moment(end).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    props.setDateSelected(true);
  };

  const togglePaymentProviderSelection = (symbol) => {
    if (props.selectedPaymentProvider.includes(symbol)) {
      let newList = props.selectedPaymentProvider.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedPaymentProvider(newList);
    } else {
      let newList = [...props.selectedPaymentProvider];
      newList.push(symbol);
      props.setSelectedPaymentProvider(newList);
    }
  };

  const handleSaveDetails = () => {
    props.handleMerchantFilters();
    props.handleSave();
  };
  const handleDropdownClick = (dropdownSetter, toggle) => {
    setShowProviderDropdown(false)
    setShowStatusDropdown(false)
    toggle ? dropdownSetter(false) : dropdownSetter(true)
  };

  const sortDropDownData = (data, field) => {
    if (field) {
      const filteredData = data.filter(item => item[field] !== undefined && item[field] !== null);
      return filteredData.sort((a, b) => {
        if (!a[field] && !b[field]) {
          return 0;
        }
        if (!a[field]) {
          return -1;
        }
        if (!b[field]) {
          return 1;
        }
        return a[field].localeCompare(b[field], { sensitivity: 'accent' });
      });
    } else {
      return data.sort((a, b) => a.localeCompare(b, { sensitivity: 'accent' }));
    }
  };

  return (
    <div className="filter_model_container">
      <div className="filter_model">

        <ResetModal open={openPopup} close={closeReset} reset={props.resetFilters} />
        <div
          className="filter_clear"
          data-e2e="filter-clear-btn"
          onClick={() => resetOpenPopup(true)}
        >
          Clear
        </div>
        <div className="filter_heading">Filters</div>
        <div
          className="filter_close_button"
          data-e2e="filter_close_button"
          onClick={() => {
            props.handleMerchantFilters();
          }}
        >
          <img
            src={`${image_base_url}${closeImage}`}
            alt="closeImage"
            data-e2e="close-icon"
          />
        </div>
      </div>
      <DateFilterInput
        fromDate={props.fromDate}
        toDate={props.toDate}
        onChangeDate={onChangeDate}
        dateSelected={props.dateSelected}
      />
      {props.adminRole == "SuperAdmin" &&
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">{`Payment Providers`}</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setShowProviderDropdown)
            }}
          >
            <div className="FilterModalInputText">
              {props.selectedPaymentProvider.length === props.paymentProviderList.length ||
                props.selectedPaymentProvider.length === 0
                ? 'All'
                : `${props.selectedPaymentProvider.length} Selected`}
            </div>
            <i
              className="fa fa-chevron-down FilterModalInputDropIcon"
            />
          </div>
          {showProviderDropdown && (
            <CPMultiSelect
              dataE2eLabel="provider-"
              setShowDropDown={setShowProviderDropdown}
              handleDropdownClick={handleDropdownClick}
              items={sortDropDownData(props.paymentProviderList, 'firstName')}
              selectedItems={
                props.selectedPaymentProvider
              }
              setSelectedItems={(items) =>
                props.setSelectedPaymentProvider(items.map(a => a._id) || [])}


              ItemComponent={({ item: provider }) => {

                return (
                  <div

                    className="currencyListItemWrap"
                    onClick={() => {
                      togglePaymentProviderSelection(provider._id);
                    }}
                  >
                    <div
                      className="currencyListIconNameWrap"
                    >
                      <div className="currencyList_Name" id="available-test">{provider.firstName + " " + provider.lastName}</div>
                    </div>
                    <img
                      src={`${image_base_url}${props.selectedPaymentProvider.includes(provider._id)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                        }`}
                      alt="checkbox"
                      className="currencyListCheckBox"
                    />
                  </div>
                );
              }}
            />)
          }
        </div>
      }
      <div className="FilterModalInputGroup">
        <div className="FilterModalInputLabel">Status</div>
        <div
          className="FilterModalInputDropdown"
          data-e2e="FilterModalInputDropdown"
          onClick={() => {
            handleDropdownClick(setShowStatusDropdown)
          }}
        >
          <div className="FilterModalInputText" data-e2e="FilterModalInputText">
            {props.selectedStatusList.length === props.statusList.length ||
              props.selectedStatusList.length === 0
              ? "All"
              : `${props.selectedStatusList.length} Selected`}
          </div>
          <i
            className="fa fa-chevron-down FilterModalInputDropIcon"
            data-e2e="FilterModalInputDropIcon"
          />
        </div>
        {showStatusDropdown && (
          <div
            className="FilterModalCurrencyDropdownlist"
            data-e2e="FilterModalCurrencyDropdownlist"
          >
            <div
              className="FM_currencyDrop_All"
              data-e2e="FM_currencyDrop_All"
              onClick={() => {
                handleDropdownClick(setShowStatusDropdown, true)
              }}
            >
              <span
                className="FM_currencyDrop_AllSelector"
                data-e2e="FM_currencyDrop_AllSelector"
              >
                {props.selectedStatusList.length === props.statusList.length ||
                  props.selectedStatusList.length === 0
                  ? "All"
                  : `${props.selectedStatusList.length} Selected`}
              </span>
              <span className="FilterModalInputDropUpIconWrap">
                <i
                  className="fa fa-chevron-up FilterModalInputDropUpIcon"
                  data-e2e="iconUp"
                />
              </span>
            </div>
            {props.statusList.map((status, index) => {
              return (
                <div
                  key={index}
                  className="currencyListItemWrap"
                  data-e2e="currencyListItemWrap"
                  onClick={() => {
                    toggleStatusSelection(status);
                  }}
                >
                  <div className="currencyListIconNameWrap">
                    <div className="currencyList_Name">{status}</div>
                  </div>
                  <img
                    src={`${image_base_url}${props.selectedStatusList.includes(status)
                      ? selectedCheckBoxIcon
                      : emptyCheckBoxIcon
                      }`}
                    alt="checkbox"
                    data-e2e="checkBox"
                    className="currencyListCheckBox"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div
        className="FilterModalSave"
        data-e2e="FilterModalSave"
        onClick={() => {
          handleSaveDetails();
        }}
      >
        SAVE
      </div>
    </div>
  );
}

export default MerchantFilter;
