import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  GET_CRYPTO_AMOUNT_EXCHANGE_RATE
} from "../../../../config/endpoints";

const CommonConfirmationModel = (props) => {
  const { open, onClose, image_base_url, crypto, haddleSuccess, header, btnName, amount, newBalance, base_url, email, setLoading } = props;

  const handleWithdraw = () => {
    onClose()
    haddleSuccess()
  }
  const [exchangeValue, setExchangeValue] = useState(0);

  useEffect(() => {
    getCryptoExchangedRate();
  }, []);
  
  const getCryptoExchangedRate = () => {
    return new Promise(async (resolve, reject) => {
      await call(
        {
          ...GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
          url: base_url + GET_CRYPTO_AMOUNT_EXCHANGE_RATE.url,
        },
        { email: email, currencyAmount: amount, fromCurrency: ["USDC", "DAI"].includes(crypto.coin) ? "USDT" : crypto.coin, toCurrency: "USD" }
      )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            resolve(res.data);
            setExchangeValue(res.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message, 'err');
          logoutAfterError(err)
        });
    })
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="ClearAlertModal" id="balances-common-model">
          <div className="ClearAlertModalBody">
            <div className="ClearAlertModalTitle">{header}</div>
            <div className="ClearAlertModalDescription">
              Please, doublecheck the summary below, before making a purchase
            </div>
            <div className="common-model-balances-tab">
              <div className="balances-head">Currency:</div>
              <div>
                {crypto.coin} / {crypto.cryptoName}{" "}
                <img
                  src={`${image_base_url}${crypto.icon}`}
                  alt="closeImage"
                  width={30}
                  height={30}
                />{" "}
              </div>
            </div>
            <div className="common-model-balances-tab">
              <div className="balances-head">{header + ":"}</div>
              <div>{amount + " " + crypto.coin + " (" + exchangeValue + "$" + ")"}</div>
            </div>
            <div className="common-model-balances-tab">
              <div className="balances-head">{`New Balance:`}</div>
              <div>{newBalance + " " + crypto.coin}</div>
            </div>

            <div data-e2e={btnName} className="ClearAlertModalClearBtn common-balances-btn" onClick={() => handleWithdraw()}>
              {btnName}
            </div>
            <div
              className="ClearAlertModalGoBackBtn"
              onClick={() => {
                onClose();
              }}
              data-e2e="cancel-btn"
            >
              Cancel
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CommonConfirmationModel;
