import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "reactstrap";
import localDb from "../../../../localDb";
import { setHeader } from "../../../Redux/actions/commonAction";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import UploadIcon from "@mui/icons-material/Upload";
import { Menu, MenuItem } from "@mui/material";
import { isViewPermissionValid } from "../../../common/CommonUtils";

const PaymentProviderTable = (props) => {
  const { orderBy, sortBy } = props;

  function SortIconElement(props) {
    const isActive = props.isActive;
    return (
      <i
        className={`fa fa-chevron-${
          isActive && orderBy === "desc" ? "up" : "down"
        } ${isActive ? "active" : ""}`}
      />
    );
  }

  return (
    <div>
      <div className="theme-table">
        <Table responsive>
          <tbody>
            <tr>
              <th
                className="theme-table-hover"
                onClick={() => {
                  props.changeOrder("firstName");
                }}
              >
                <p className="theme-table-text">
                  Providers
                  <SortIconElement isActive={sortBy === "firstName"} />
                </p>
              </th>
              <th
                className="theme-table-hover"
                onClick={() => {
                  props.changeOrder("createdAt");
                }}
              >
                <p className="theme-table-text">
                  Created Date
                  <SortIconElement
                    data-e2e="createdAt"
                    isActive={sortBy === "createdAt"}
                  />
                </p>
              </th>
              <th
                className="theme-table-hover"
                onClick={() => {
                  props.changeOrder("enabled");
                }}
              >
                <p className="theme-table-text">
                  Status
                  <SortIconElement isActive={props.sortBy === "enabled"} />
                </p>
              </th>
              <th
                className="theme-table-hover"
                onClick={() => {
                  props.changeOrder("email");
                }}
              >
                <p className="theme-table-text">
                  Provider Contact Information
                  <SortIconElement isActive={sortBy === "email"} />
                </p>
              </th>
              <th>
                <p className="theme-table-text">Actions</p>
              </th>
            </tr>
            {props && props.data && props.data.length > 0 ? (
              props.data.map((TData, i) => {
                return (
                  <SingleProviderTableRow
                    {...props}
                    setPaymentProviderIdForUpdate={
                      props.setPaymentProviderIdForUpdate
                    }
                    setProviderIdForMerchant={props.setProviderIdForMerchant}
                    setAddNewClient={props.setAddNewClient}
                    setAddNewUser={props.setAddNewUser}
                    setAddNewMerchant={props.addNewMerchant}
                    data={TData}
                    key={i}
                  />
                );
              })
            ) : (
              <tr data-e2e="No_Transactions_Found">
                <td colSpan="7">
                  <p className="theme-table-empty">No Data Found</p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {props.showMoreButton && (
        <div className="theme-btn-block center show-btn">
          <div
            className="theme-btn"
            data-e2e="showMore"
            onClick={() => {
              props.handleShowMore();
            }}
          >
            <p>Show more</p>
          </div>
        </div>
      )}
    </div>
  );
};

const SingleProviderTableRow = (props) => {
  const [menuButton, setMenuButton] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  let { data } = props;
  const navigate = useNavigate();

  const getStatusComponent = (status) => {
    const isActive = status == "Active";
    return (
      <div className={`theme-table-status ${isActive ? "active" : "inactive"}`}>
        {isActive ? "Active" : "Inactive"}
      </div>
    );
  };

  const providerOnClickHandler = (providerId) => {
    if(menuButton){
      setMenuButton(false)
      setAnchorEl(null)
    }else{
      dispatch(setHeader("Merchants"));
      localDb.setVal("paymentProviderId", providerId);
      navigate("/");
    }
  };
  const setUser = (username) => {
    localStorage.setItem("userName", username);
  };
  const handleUplodIINModel = (e) => {
    e.stopPropagation();
  };
  const handleCloseMenu = (e) => {
    e.preventDefault(); // Prevent default behavior
    e.stopPropagation(); // Stop propagation to the table row
    setAnchorEl(null);
  };
  return (
    <tr
      onClick={() => providerOnClickHandler(data._id)}
      className="theme-table-hover"
    >
      <td>
        <div className="theme-table-row-td">
          <p className="theme-table-text">
            {(data && data.firstName + " " + data.lastName) || "N/A"}
          </p>
        </div>
      </td>

      <td>
        <div className="theme-table-row-td">
          <p className="theme-table-text">
            {data && data.createdAt
              ? moment(data.createdAt).format("DD/MM/YYYY - HH:mm")
              : "N/A"}
          </p>
        </div>
      </td>

      <td>
        <div className="theme-table-row-td">
          {data && data.enabled
            ? getStatusComponent("Active")
            : getStatusComponent("Inactive")}
        </div>
      </td>

      <td>
        <div className="theme-table-row-td">
          <p className="theme-table-text">{(data && data.email) || "N/A"}</p>
        </div>
      </td>
      <td>
        <div className="theme-table-actions">
          <div className="relative">
            <button
              type="button"
              className={`theme-table-action  mr ${isViewPermissionValid? "disabled": ""}`}
              onClick={(e) => isViewPermissionValid ? null : (
                (e?.stopPropagation(),
                menuButton ? handleCloseMenu(e) : setAnchorEl((prevAnchorEl) => prevAnchorEl ? null : e?.currentTarget),
                setMenuButton(!menuButton))
              )}
              data-e2e={`${data && data.firstName&& data.firstName}-${data &&data.lastName && data.lastName}-payment-provider`}
            >
              Add New{" "}
              <span>
                {menuButton ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </span>
            </button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem>
                <button
                  type="button"
                  className="theme-table-action w-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.setAddNewMerchant(true);
                    props.setProviderIdForMerchant(data._id);
                  }}
                  data-e2e={`${data &&data.firstName && data.firstName}-${data &&data.lastName && data.lastName}-Add-New-Merchant`}
                >
                  Merchant
                </button>
              </MenuItem>
              <MenuItem>
                <button
                  type="button"
                  className="theme-table-action w-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.setAddNewClient(true);
                    props.setProviderIdForMerchant(data._id);
                  }}
                  data-e2e={`${data &&data.firstName && data.firstName} ${data &&data.lastName && data.lastName} Add New Client`}
                >
                  Client
                </button>
              </MenuItem>
              <MenuItem>
                <button
                  type="button"
                  className="theme-table-action w-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.setAddNewUser(true);
                    props.setProviderIdForMerchant(data._id);
                  }}
                >
                  User
                </button>
              </MenuItem>
            </Menu>
          </div>
          <button
            type="button"
            className={`theme-table-action mr ${isViewPermissionValid? "disabled": ""}`}
            onClick={(e) => isViewPermissionValid? null: (
              e?.stopPropagation(),
              props?.setPaymentProviderIdForUpdate(data?._id)
            )}
            data-e2e={`${data &&data.firstName && data.firstName} ${data &&data.lastName && data.lastName} Update`}
          >
            <EditIcon />
          </button>
          <Link
            to={`/all-payment-providers/${data._id}`}
            className={`theme-table-action mr ${isViewPermissionValid ? "disabled": ""}`}
            onClick={(e) => isViewPermissionValid? null: (
              e?.stopPropagation(),
              setUser(`${data?.firstName} ${data?.lastName}`)
            )}
            data-e2e={`${data &&data.firstName && data.firstName} ${data &&data.lastName && data.lastName} Settings`}
          >
            <SettingsIcon />
          </Link>
          <Link
            to={`/all-payment-providers/UploadIINPage/${data?._id}`}
              onClick={(e) => isViewPermissionValid? null: handleUplodIINModel(e)}
            className={`${isViewPermissionValid? "disabled": ""} theme-table-action`}
          >
            <UploadIcon />
          </Link>
        </div>
      </td>
    </tr>
  );
};

export default PaymentProviderTable;
