import React, { useEffect, useState } from "react";
import { Alert, Stack } from "@mui/material";
import {
  GET_PAYMENT_PROVIDER_SETTINGS,
  UPDATE_PAYMENT_PROVIDER_SETTINGS,
} from "../../../../config/endpoints";
import { useSelector } from "react-redux";
import localDb from "../../../../localDb";
import { useParams } from "react-router-dom";
import { call, logoutAfterError } from "../../../../config/axios";
import Loading from "../../../common/Loading";

const PaymentProviderSettings = () => {
  const [selectedCryptos, setSelectedCryptos] = useState([]);
  const { id } = useParams();
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const selectedCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_filled.png";
  const emptyCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_empty.png";
  const tick_green = "/cryptonpay/crytoicons/tick_green.png";

  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [cryptos, setCryptos] = useState([]);
  const [merchantWalletActivation, setMerchantWalletActivation] = useState("");
  const [merchantWalletsDropDown, setMerchantWalletsDropDown] = useState(false);
  const [userWalletDeposit, setUserWalletDeposit] = useState("");
  const [userWalletDropDown, setUserWalletDropDown] = useState(false);
  const [depositByCardFixedFee, setDepositByCardFixedFee] = useState("");
  const [depositByCardFee, setDepositByCardFee] = useState("");
  const [cardSettlementPeriod, setCardSettlementPeriod] = useState("");
  const [cardReservePeriod, setCardReservePeriod] = useState("");
  const [cardReservePercent, setCardReservePercent] = useState("");
  const [depositSpread, setDepositSpread] = useState("");
  const [withdrawalSpread, setWithdrawalSpread] = useState("");
  const [CryptoDepositfixedfee, setCryptoDepositfixedfee] = useState("");
  const [CryptoDeposittransactionfee, setCryptoDeposittransactionfee] =
    useState("");
  const [CryptoWithdrawaltransactionfee, setCryptoWithdrawaltransactionfee] =
    useState("");
  const [isActiveCreditCard, setisActiveCreditCard] = useState("Disable");
  const [isActiveCreditCardDropDown, setisActiveCreditCardDropDown] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [successAlert, setSuccessAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [updateStatus, setUpdateStatus] = useState(false);

  const getPaymentSettingDetails = async () => {
    let payload = {
      email: email,
      paymentId: id,
    };
    await call(
      {
        ...GET_PAYMENT_PROVIDER_SETTINGS,
        url: base_url + GET_PAYMENT_PROVIDER_SETTINGS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          let response = res.data.settings;
          setDepositByCardFixedFee(response.depositByCardFixedFee);
          setDepositByCardFee(response.depositByCardFee);
          setDepositSpread(response.depositSpread);
          setMerchantWalletActivation(
            response.merchantWalletActivation &&
              response.merchantWalletActivation === true
              ? "Enable"
              : "Disable"
          );
          setUserWalletDeposit(
            response.userWalletActive && response.userWalletActive === true
              ? "Enable"
              : "Disable"
          );
          setWithdrawalSpread(response.withdrawalSpread);
          setCryptoDeposittransactionfee(response.cryptodepositTransactionFee);
          setCryptoDepositfixedfee(response.cryptodepositFixedFee);
          setCryptoWithdrawaltransactionfee(
            response.cryptoWithdrawalTransactionFee
          );
          setisActiveCreditCard(
            response.creditCardActive && response.creditCardActive == true
              ? "Enable"
              : "Disable"
          );
          setCardSettlementPeriod(response.cardSettlementPeriod);
          setCardReservePeriod(response.cardReservePeriod);
          setCardReservePercent(response.cardReservePercent);
          let allCryptos =
            res.data && res.data.settings && res.data.settings.allCryptoList;
          setCryptos(allCryptos);
          let temp =
            res.data &&
            res.data.settings &&
            res.data.settings.allowedCryptoList.map((item) => {
              return item.symbol;
            });
          setSelectedCryptos(temp);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };

  useEffect(() => {
    getPaymentSettingDetails();
  }, []);

  const handleUpdateSetting = async () => {
    setLoading(true);
    let payload = {
      paymentId: id,
      email: email,
      allowedCryptoList: selectedCryptos,
      cryptodepositFixedFee: CryptoDepositfixedfee,
      cryptodepositTransactionFee: CryptoDeposittransactionfee,
      cryptoWithdrawalTransactionFee: CryptoWithdrawaltransactionfee,
      depositByCardFixedFee: depositByCardFixedFee,
      depositByCardFee: depositByCardFee,
      cardSettlementPeriod: cardSettlementPeriod,
      cardReservePeriod: cardReservePeriod,
      cardReservePercent: cardReservePercent,
      depositSpread: depositSpread,
      withdrawalSpread: withdrawalSpread,
      userWalletDeposit: userWalletDeposit === "Enable" ? "true" : "false",
      creditCardActive: isActiveCreditCard === "Enable" ? "true" : "false",
      merchantWalletActivation:
        merchantWalletActivation === "Enable" ? "true" : "false",
    };

    await call(
      {
        ...UPDATE_PAYMENT_PROVIDER_SETTINGS,
        url: base_url + UPDATE_PAYMENT_PROVIDER_SETTINGS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setSuccessAlert(true);
          setAlertMsg("Payment Provider details updated successfuly");
          setTimeout(() => {
            setSuccessAlert(false);
            setUpdateStatus((prev) => !prev);
          }, 2000);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };
  const toggleSelection = (symbol) => {
    if (selectedCryptos.includes(symbol)) {
      let newList = selectedCryptos.filter((symbolObj) => symbolObj !== symbol);
      setSelectedCryptos(newList);
    } else {
      let newList = [...selectedCryptos];
      newList.push(symbol);
      setSelectedCryptos(newList);
    }
  };
  return (
    <div className="update-merchant-settings">
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          Crypto Currency
        </div>
        <div
          className="FilterModalInputDropdown"
          onClick={() => {
            setShowCurrencyDropdown(true);
            setUserWalletDropDown(false);
            setMerchantWalletsDropDown(false);
            setisActiveCreditCardDropDown(false);
          }}
        >
          <div className="FilterModalInputText">
            {selectedCryptos.length === cryptos.length ||
            selectedCryptos.length === 0
              ? "All"
              : `${selectedCryptos.length} Selected`}
          </div>
          <i
            className="fa fa-chevron-down FilterModalInputDropIcon"
            data-e2e="crypto-currency-expand"
          />
        </div>
        {showCurrencyDropdown && (
          <div className="FilterModalCurrencyDropdownlist update-merchant-settings-dropdown">
            <div
              className="FM_currencyDrop_All"
              onClick={() => {
                setShowCurrencyDropdown(false);
              }}
            >
              <span
                className="FM_currencyDrop_AllSelector"
                data-e2e={
                  selectedCryptos.length === cryptos.length ||
                  selectedCryptos.length === 0
                    ? "All"
                    : `${selectedCryptos.length} Selected`
                }
              >
                {selectedCryptos.length === cryptos.length ||
                selectedCryptos.length === 0
                  ? "All"
                  : `${selectedCryptos.length} Selected`}
              </span>
              <span className="FilterModalInputDropUpIconWrap">
                <i
                  data-e2e="crypto-currency-contract"
                  className="fa fa-chevron-up FilterModalInputDropUpIcon"
                />
              </span>
            </div>
            {cryptos.map((crypto, index) => {
              let { name, symbol, icon } = crypto;
              return (
                <div
                  key={index}
                  className="currencyListItemWrap"
                  onClick={() => {
                    toggleSelection(symbol);
                  }}
                >
                  <div className="currencyListIconNameWrap">
                    <img
                      src={`${image_base_url}${icon}`}
                      alt={symbol}
                      className="currencyList_Icon"
                    />
                    <div
                      className="currencyList_Name"
                      data-e2e={name.toLowerCase().replaceAll(" ", "-")}
                    >
                      {name}
                    </div>
                  </div>
                  <img
                    src={`${image_base_url}${
                      selectedCryptos.includes(symbol)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                    }`}
                    alt="checkbox"
                    className="currencyListCheckBox"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="horizontal-line"></div>
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          Merchant Wallets Activation
        </div>
        <div
          className="FilterModalInputDropdown"
          onClick={() => {
            setMerchantWalletsDropDown(true);
            setShowCurrencyDropdown(false);
            setUserWalletDropDown(false);
            setisActiveCreditCardDropDown(false);
          }}
        >
          <div className="FilterModalInputText">{merchantWalletActivation}</div>
          <i
            className="fa fa-chevron-down FilterModalInputDropIcon"
            data-e2e="user-wallet-expand"
          />
        </div>
        {merchantWalletsDropDown && (
          <div className="FilterModalCurrencyDropdownlist update-merchant-settings-dropdown">
            <div
              className="FM_currencyDrop_All"
              onClick={() => {
                setMerchantWalletsDropDown(false);
              }}
            >
              <span className="FM_currencyDrop_AllSelector">
                {merchantWalletActivation}
              </span>
              <span className="FilterModalInputDropUpIconWrap">
                <i
                  data-e2e="user-wallet-contract"
                  className="fa fa-chevron-up FilterModalInputDropUpIcon"
                />
              </span>
            </div>
            {["Enable", "Disable"].map((status, index) => {
              return (
                <div
                  key={index}
                  className="currencyListItemWrap"
                  onClick={() => {
                    setMerchantWalletActivation(status);
                    setMerchantWalletsDropDown(false);
                  }}
                >
                  <div className="currencyListIconNameWrap">
                    <div
                      className="currencyList_Name"
                      data-e2e={status.toLowerCase().replaceAll(" ", "-")}
                    >
                      {status}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="horizontal-line"></div>
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          User Wallet Deposits
        </div>
        <div
          className="FilterModalInputDropdown"
          onClick={() => {
            setUserWalletDropDown(true);
            setMerchantWalletsDropDown(false);
            setShowCurrencyDropdown(false);
            setisActiveCreditCardDropDown(false);
          }}
        >
          <div className="FilterModalInputText">{userWalletDeposit}</div>
          <i
            className="fa fa-chevron-down FilterModalInputDropIcon"
            data-e2e="user-wallet-expand"
          />
        </div>
        {userWalletDropDown && (
          <div className="FilterModalCurrencyDropdownlist update-merchant-settings-dropdown">
            <div
              className="FM_currencyDrop_All"
              onClick={() => {
                setUserWalletDropDown(false);
              }}
            >
              <span className="FM_currencyDrop_AllSelector">
                {userWalletDeposit}
              </span>
              <span className="FilterModalInputDropUpIconWrap">
                <i
                  data-e2e="user-wallet-contract"
                  className="fa fa-chevron-up FilterModalInputDropUpIcon"
                />
              </span>
            </div>
            {["Enable", "Disable"].map((status, index) => {
              return (
                <div
                  key={index}
                  className="currencyListItemWrap"
                  onClick={() => {
                    setUserWalletDeposit(status);
                    setUserWalletDropDown(false);
                  }}
                >
                  <div className="currencyListIconNameWrap">
                    <div
                      className="currencyList_Name"
                      data-e2e={status.toLowerCase().replaceAll(" ", "-")}
                    >
                      {status}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="horizontal-line"></div>
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          Crypto Deposit fixed fee
        </div>
        <div className="FilterModalInputDropdown merchant-update-input">
          <input
            placeholder="Crypto Deposit fixed fee"
            name="CryptoDepositfixedfee"
            value={CryptoDepositfixedfee}
            onChange={(e) => setCryptoDepositfixedfee(e.target.value)}
            data-e2e="email-text-box"
          />
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          Crypto Deposit transaction fee
        </div>
        <div className="FilterModalInputDropdown merchant-update-input">
          <input
            placeholder="Crypto Deposit transaction fee"
            name="CryptoDeposittransactionfee"
            value={CryptoDeposittransactionfee}
            onChange={(e) => setCryptoDeposittransactionfee(e.target.value)}
            data-e2e="email-text-box"
          />
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          Crypto Withdrawal transaction fee
        </div>
        <div className="FilterModalInputDropdown merchant-update-input">
          <input
            placeholder="Crypto Withdrawal transaction fee"
            name="CryptoWithdrawaltransactionfee"
            value={CryptoWithdrawaltransactionfee}
            onChange={(e) => setCryptoWithdrawaltransactionfee(e.target.value)}
            data-e2e="email-text-box"
          />
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          DepositByCard Fixed Fee (in cents)
        </div>
        <div className="FilterModalInputDropdown merchant-update-input">
          <input
            placeholder="DepositByCard Fixed Fee (in cents)"
            name="depositByCardFixedFee"
            type="number"
            value={depositByCardFixedFee}
            onChange={(e) => setDepositByCardFixedFee(e.target.value)}
          />
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          DepositByCard % Fee
        </div>
        <div className="FilterModalInputDropdown merchant-update-input">
          <input
            placeholder="DepositByCard Fee in %"
            name="depositByCardFee"
            type="number"
            value={depositByCardFee}
            onChange={(e) => setDepositByCardFee(e.target.value)}
          />
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          Card settlement (days)
        </div>
        <div className="FilterModalInputDropdown merchant-update-input">
          <input
            placeholder="Card Settlement Period in days"
            name="cardSettlementPeriod"
            type="number"
            value={cardSettlementPeriod}
            onChange={(e) => setCardSettlementPeriod(e.target.value)}
          />
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          card reserve period (days)
        </div>
        <div className="FilterModalInputDropdown merchant-update-input">
          <input
            placeholder="Card Reserve Period in days"
            name="cardReservePeriod"
            type="number"
            value={cardReservePeriod}
            onChange={(e) => setCardReservePeriod(e.target.value)}
          />
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          card reserve percent ( % )
        </div>
        <div className="FilterModalInputDropdown merchant-update-input">
          <input
            placeholder="Card Reserve Percent"
            name="cardReservePercent"
            type="number"
            value={cardReservePercent}
            onChange={(e) => setCardReservePercent(e.target.value)}
          />
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          Deposit spread
        </div>
        <div className="FilterModalInputDropdown merchant-update-input">
          <input
            placeholder="Deposit Spread"
            data-e2e="deposit-spread-text-box"
            name="depositSpread"
            type="number"
            value={depositSpread}
            onChange={(e) => setDepositSpread(e.target.value)}
          />
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          Withdrawal spread
        </div>
        <div className="FilterModalInputDropdown merchant-update-input">
          <input
            placeholder="Withdrawal Spread"
            data-e2e="withdrawal-spread-text-box"
            name="withdrawalSpread"
            type="number"
            value={withdrawalSpread}
            onChange={(e) => setWithdrawalSpread(e.target.value)}
          />
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="FilterModalInputGroup flex-direction-row">
        <div className="FilterModalInputLabel update-merchant-label">
          Credit Card
        </div>
        <div
          className="FilterModalInputDropdown"
          onClick={() => {
            setMerchantWalletsDropDown(false);
            setShowCurrencyDropdown(false);
            setUserWalletDropDown(false);
            setisActiveCreditCardDropDown(true);
          }}
        >
          <div className="FilterModalInputText">{isActiveCreditCard}</div>
          <i
            className="fa fa-chevron-down FilterModalInputDropIcon"
            data-e2e="control-settlement-period-expand"
          />
        </div>
        {isActiveCreditCardDropDown && (
          <div className="FilterModalCurrencyDropdownlist update-merchant-settings-dropdown">
            <div
              className="FM_currencyDrop_All"
              onClick={() => {
                setisActiveCreditCardDropDown(false);
              }}
            >
              <span className="FM_currencyDrop_AllSelector">
                {isActiveCreditCard}
              </span>
              <span className="FilterModalInputDropUpIconWrap">
                <i
                  data-e2e="control-settlement-period-contract"
                  className="fa fa-chevron-up FilterModalInputDropUpIcon"
                />
              </span>
            </div>
            {["Enable", "Disable"].map((item, index) => {
              return (
                <div
                  key={index}
                  className="currencyListItemWrap"
                  onClick={() => {
                    console.log("setting ", item);
                    setisActiveCreditCard(item);
                    setisActiveCreditCardDropDown(false);
                  }}
                >
                  <div className="currencyListIconNameWrap">
                    <div
                      className="currencyList_Name"
                      data-e2e={item.toLowerCase().replaceAll(" ", "-")}
                    >
                      {item}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="horizontal-line"></div>
      <div className="login_button_get_started merchant-update-button">
        <button
          onClick={() => handleUpdateSetting()}
          data-e2e="update-settings-btn"
        >
          Update Settings
        </button>
      </div>
      {successAlert && (
        <Stack className="position-fixed successAlert" spacing={2}>
          <Alert
            icon={
              <img
                src={`${image_base_url}${tick_green}`}
                alt="tick_green"
                width={32}
              />
            }
            onClose={() => {
              setSuccessAlert(false);
            }}
            className="MuiAlert-root"
          >
            {alertMsg}
          </Alert>
        </Stack>
      )}
      {loading && <Loading />}
    </div>
  );
};

export default PaymentProviderSettings;
