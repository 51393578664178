import React from "react";
import { Table } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import EditIcon from '@mui/icons-material/Edit';
import { connect } from "react-redux";
import { call, logoutAfterError } from "../../../../config/axios";
import localDb from "../../../../localDb";
import AddNewGlobalSettings from "./AddNewB2CGlobalSetting";
import { Modal } from "react-bootstrap";
import Loading from "../../../common/Loading";
import {
  DELETE_B2C_USER_GLOBAL_SETTING_WALLET,
  GET_B2C_USER_GLOBAL_SETTING_WALLETS,
} from "../../../../config/endpoints";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import B2CUserSpreadLevelTab from "./B2CUserSpreadLevelTab";
import RiskLevelTab from "./RiskLevelTab";
import NavTabs from "../../../common/NavTabs";
import EditB2CUserGlobalsettings from "./EditB2CUserGlobalsettings";
import { isViewPermissionValid } from "../../../common/CommonUtils";
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";

const profiles = [
  {
    name: "Bitcoin",
    trustLevel: "Bitcoin",
    kyc: "BTC",
    withdrawaLimits: 0.0001,
    purchaseLimits: 0.0001,
  },
];

const profileHeaders = [
  {
    header_name: "NAMES",
    header_width: "15%",
  },
  {
    header_name: "TRUST LEVEL",
    header_width: "15%",
  },
  {
    header_name: "KYC",
    header_width: "10%",
  },
  {
    header_name: "WITHDRAWAL LIMITS",
    header_width: "25%",
  },
  {
    header_name: "PURCHASE LIMITS",
    header_width: "25%",
  },
];

const cryptoHeaders = [
  {
    name: "Crypto ID",
    width: "10%",
  },
  {
    name: "Name",
    width: "15%",
  },
  {
    name: "Blockchain",
    width: "15%",
  },
  {
    name: "Icon",
    width: "8%",
  },
  {
    name: "Fiat Settlement Trade",
    width: "20%",
  },
  {
    name: "Increment Size",
    width: "12%",
  },
  {
    name: "Status",
    width: "10%",
  },
];

const walletHeaders = [
  {
    name: "BLOCKCHAIN",
  },
  {
    name: "WALLET TYPE",
  },
  {
    name: "CRYPTO",
  },
  {
    name: "NETWORK",
  },
  {
    name: "THRESHOLD",
  },
  {
    name: "WALLET #",
  },
  {
    name: "STATUS",
  },
  {
    name: "",
  },
];


const tabs = [
  // {
  //   name: "Profile",
  //   id: 1,
  // },
  // {
  //   name: "Crypto",
  //   id: 2,
  // },
  {
    name: "Wallets",
    id: 3,
  },
  {
    name: "Risk Level",
    id: 4,
  },
  {
    name: "Spread Level",
    id: 5,
  },
];

class GlobalSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      walletsData: [],
      selectedTab: tabs[0],
      loading: false,
      showAddNewModal: false,
      deleteId: null,
      editGlobalSetting: false,
      editData: null,
    };
  }

  componentDidMount() {
    this.getWalletsData();
  }

  handleEditGlobalSettings = (data) => {
    this.setState({ editGlobalSetting: true, editData: data });
  };
  handleCloseEditGlobalSettings = () => {
    this.setState({ editGlobalSetting: false, editData: null });
  };
  changeDeleteWalletId = (id) => {
    this.setState({ delelteId: id });
  };

  getWalletsData = async () => {
    this.setState({ loading: true });
    let payload = {
      email: localDb.getVal("email"),
    };
    await call(
      {
        ...GET_B2C_USER_GLOBAL_SETTING_WALLETS,
        url: this.props.base_url + GET_B2C_USER_GLOBAL_SETTING_WALLETS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            walletsData: res.data.globalSettings,
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  deleteSettings = async () => {
    this.setState({ loading: true });
    let payload = {
      email: localDb.getVal("email"),
      id: this.state.deleteId,
    };
    await call(
      {
        ...DELETE_B2C_USER_GLOBAL_SETTING_WALLET,
        url: this.props.base_url + DELETE_B2C_USER_GLOBAL_SETTING_WALLET.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({ deleteId: null, loading: false });
          this.getWalletsData();
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };
  render() {
    return (
      <HeaderAndFooterLayout>
        <CommonHeader name={`B2C User Settings`} />
        <div className="merchant_dailog_screen edit-dailog_screen">
          <Dialog
            open={this.state.editGlobalSetting || false}
            onClose={this.handleCloseGlobalSetting}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="edit-dailog_screen"
            maxWidth={"md"}
          >
            <div>
              <EditB2CUserGlobalsettings
                editData={this.state.editData}
                getWalletsData={this.getWalletsData}
                handleCloseEditGlobalSettings={
                  this.handleCloseEditGlobalSettings
                }
              />
            </div>
          </Dialog>
        </div>

        <div>
          <div className="form_wrap merchant-setting">
            <div className="card loginSignupCard GlobalSettingsTable table-shadow">
              <div className="pt-0">
                <NavTabs
                  tabs={tabs}
                  selectedTab={this.state.selectedTab}
                  changeTab={(tab) => {
                    this.setState({ selectedTab: tab });
                  }}
                />
                <div
                  className="TransactionTableMain"
                  style={{
                    marginTop: "-1.8px",
                    marginBottom: "0px",
                  }}
                >
                  {this.state.selectedTab.id === 1 && (
                    <div>
                      {profiles && profiles.length > 0 ? (
                        <Table responsive style={{ border: "none" }}>
                          <tbody className="TransactionTable_BODY">
                            <tr>
                              {profileHeaders.map((item, index) => (
                                <th
                                  className="GBtableHeading pl-0 pr-0"
                                  width={item.header_width}
                                  key={index}
                                  data-e2e={item.header_name}
                                >
                                  {item.header_name}
                                </th>
                              ))}
                            </tr>
                            {profiles &&
                              profiles.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    className="table_item merchant_table_font text-black"
                                    width="15%"
                                  >
                                    {item.name ? `${item.name}` : "N/A"}
                                  </td>
                                  <td
                                    className="table_item merchant_table_font text-black"
                                    width="15%"
                                  >
                                    {item.trustLevel ? item.trustLevel : "N/A"}
                                  </td>
                                  <td
                                    className="table_item merchant_table_font text-black"
                                    width="10%"
                                  >
                                    {item.kyc ? item.kyc : "N/A"}
                                  </td>
                                  <td
                                    className="table_item merchant_table_font text-black"
                                    width="25%"
                                  >
                                    {item.withdrawalLimits
                                      ? item.withdrawalLimits
                                      : "N/A"}
                                  </td>
                                  <td
                                    className="table_item merchant_table_font text-black"
                                    width="25%"
                                  >
                                    {item.purchaseLimits
                                      ? item.purchaseLimits
                                      : "N/A"}
                                  </td>
                                  <td className="table_item merchant_table_font text-black text-center">
                                    <EditIcon
                                      fontSize="large"
                                      color='action'
                                      data-e2e="edit_button"
                                      className="cursor-pointer pr-3"
                                    />
                                    <span
                                      className="cursor-pointer"
                                      data-e2e="close_icon"
                                    >
                                      <CloseIcon fontSize="small" />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="NoDataFound">No Data Found</div>
                      )}{" "}
                    </div>
                  )}
                  {this.state.selectedTab.id === 2 && (
                    <Crypto
                      cryptoHeaders={cryptoHeaders}
                      cryptoDate={this.state.cryptoDate}
                      image_base_url={this.props.image_base_url}
                      changeDeletingCryptoId={(id) => {
                        this.setState({ deletingCryptoId: id });
                      }}
                    />
                  )}
                  {this.state.selectedTab.id === 3 && (
                    <WalletList
                      walletHeaders={walletHeaders}
                      walletsData={this.state.walletsData}
                      handleEditGlobalSettings={this.handleEditGlobalSettings}
                      changeDeletingCryptoId={(id) => {
                        this.setState({ deleteId: id });
                      }}
                    />
                  )}
                  {this.state.selectedTab.id === 4 && <RiskLevelTab />}
                  {this.state.selectedTab.id === 5 && <B2CUserSpreadLevelTab />}
                </div>

                {this.state.selectedTab.id != 5 && this.state.selectedTab.id != 4 && (
                  <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className={`btn btn-primary btn-md rounded-pill pl-5 pr-5 pt-2 pb-2 ${isViewPermissionValid ? 'disabled' : ''}`}
                    data-e2e="add-new-btn"
                    onClick={() => {
                      if (!isViewPermissionValid && this?.state?.selectedTab?.id === 3) {
                        this.setState({ showAddNewModal: true });
                      }
                    }}
                  >
                    Add New
                  </button>
                  </div>
                )}

                {
                  <div className="merchant_dailog_screen">
                    {this.state.selectedTab.id === 3 && (
                      <Dialog
                        open={this.state.showAddNewModal || false}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth={"md"}
                        onClose={() => {
                          this.setState({ showAddNewModal: false });
                        }}
                      >
                          <AddNewGlobalSettings
                            getGlobalSetting={this.getWalletsData}
                            handleClose={() => {
                              this.setState({ showAddNewModal: false });
                            }}
                          />
                      </Dialog>
                    )}
                    <DeletingAlert
                      deletingId={
                        this.state.deleteId ? this.state.deleteId : null
                      }
                      onHide={() => {
                        if (
                          this.state.selectedTab.id === 3 ||
                          this.state.selectedTab.id === 4 ||
                          this.state.selectedTab.id === 5
                        ) {
                          this.setState({ deleteId: null });
                        }
                      }}
                      image_base_url={this.props.image_base_url}
                      msg="Are you sure you want to delete selected setting? This action cannot be undone."
                      onDeleteClickHandler={() => {
                        if (this.state.selectedTab.id === 3) {
                          this.deleteSettings();
                        }
                      }}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
          {this.state.loading && <Loading />}
        </div>
      </HeaderAndFooterLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    base_url: state.config.api_url,
    image_base_url: state.config.image_base_url,
  };
};

export default connect(mapStateToProps, null)(GlobalSetting);

const WalletList = (props) => {
  const { walletHeaders, walletsData, handleEditGlobalSettings } = props;

  const getStatus = (status) => {
    switch (status) {
      case false:
        return <div className="DisabledStatus">DISABLED</div>;
      case true:
        return <div className="EnabledStatus">ENABLED</div>;
    }
  };
  return (
    <div>
      <Table responsive style={{ border: "none" }}>
        <tbody className="TransactionTable_BODY">
          <tr>
            {walletHeaders.map((item, index) => (
              <th className={`GBtableHeading`} key={index} data-e2e={item.name}>
                {item.name}
              </th>
            ))}
          </tr>
          {walletsData && walletsData.length > 0 ? (
            walletsData.map((item, index) => (
              <tr key={index}>
                <td className="table_item merchant_table_font text-black">
                  {item.cryptoId && item.cryptoId.blockchain
                    ? item.cryptoId.blockchain
                    : "N/A"}
                </td>
                <td className="table_item merchant_table_font text-black">
                  {false ? "N/A" : "Cashier"}
                </td>
                <td className="table_item merchant_table_font text-black">
                  {item.cryptoId
                    ? `${item.cryptoId.name}(${item.cryptoId.symbol})`
                    : "N/A"}
                </td>
                <td className="table_item merchant_table_font text-black">
                  {item.network && item.network !== "" ? item.network : "N/A"}
                </td>
                <td className="table_item merchant_table_font text-black">
                  {(item.minCapacityThreshold ||
                    item.minCapacityThreshold === 0) &&
                  (item.maxCapacityThreshold || item.maxCapacityThreshold === 0)
                    ? `${item.minCapacityThreshold}-${item.maxCapacityThreshold}`
                    : "N/A"}
                </td>
                <td className="table_item merchant_table_font text-black">
                  {item.minimunWallets || item.minimunWallets === 0
                    ? item.minimunWallets
                    : "N/A"}
                  -
                  {item.maximumWallets || item.maximumWallets === 0
                    ? item.maximumWallets
                    : "N/A"}
                </td>

                <td className="table_item merchant_table_font text-black">
                  {(item.cryptoId && item.cryptoId.enabled !== true) ||
                  (item.cryptoId && item.cryptoId.enabled !== false)
                    ? getStatus(item.cryptoId && item.cryptoId.enabled)
                    : "N/A"}
                </td>
                <td className={`table_item merchant_table_font text-black${isViewPermissionValid ? 'disabled' : ''}`}>
                  <EditIcon
                    fontSize="large"
                    color='action'
                    data-e2e="edit_button"
                    className={`cursor-pointer pr-3 ${isViewPermissionValid ? 'disabled' : ''}`}
                    onClick={() => isViewPermissionValid? null:
                          handleEditGlobalSettings({
                            ...item,
                            blockchain: item?.cryptoId?.blockchain ? item?.cryptoId?.blockchain : "",
                            cryptoId: item?.cryptoId?._id,
                            cryptoName: `${item?.cryptoId?.name}(${item?.cryptoId?.symbol})`,
                          })
                    }
                  />
               <span
                  className={`cursor-pointer ${isViewPermissionValid ? 'disabled' : ''}`}
                  data-e2e="close_icon"
                >
                  <CloseIcon
                    onClick={() => isViewPermissionValid? null:
                      props?.changeDeletingCryptoId(item?._id)
                    }
                    fontSize="small"
                  />
                </span>
                </td>
              </tr>
            ))
          ) : (
            <div className="NoDataFound">No Data Found</div>
          )}
        </tbody>
      </Table>
    </div>
  );
};

const Crypto = (props) => {
  const { cryptoHeaders, handleEditCrypto, cryptoDate, image_base_url } = props;
  const selectedCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_filled.png";
  const emptyCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_empty.png";

  const getStatus = (status) => {
    switch (status) {
      case false:
        return <div className="DisabledStatus">DISABLED</div>;
      case true:
        return <div className="EnabledStatus">ENABLED</div>;
    }
  };
  return (
    <div className="ScrollBarStyle">
      <Table responsive style={{ border: "none" }}>
        <tbody className="TransactionTable_BODY">
          <tr>
            {cryptoHeaders.map((item, index) => (
              <th
                className={`GBtableHeading pl-0 pr-0 ${
                  item.name == "Fiat Settlement Trade" ||
                  item.name == "Status" ||
                  item.name == "Increment Size"
                    ? "text-center"
                    : ""
                }`}
                style={{ width: item.width }}
                key={index}
                data-e2e={item.name}
              >
                {item.name}
              </th>
            ))}
          </tr>
          {cryptoDate &&
            cryptoDate.map((item, index) => (
              <tr key={index}>
                <td className="table_item merchant_table_font pl-0 pr-0">
                  {item.cryptoId ? item.cryptoId : "N/A"}
                </td>
                <td className="table_item merchant_table_font pl-0 pr-0">
                  {item.name ? item.name : "N/A"}
                </td>
                <td className="table_item merchant_table_font pl-0 pr-0">
                  {item.blockchain ? item.blockchain : "N/A"}
                </td>
                <td className="table_item merchant_table_font pl-0 pr-0">
                  <img
                    src={`${image_base_url}${item.icon}`}
                    width="30"
                    height="30"
                    alt="icon"
                    data-e2e="icon"
                    className="text-center"
                  />
                </td>
                <td className="table_item merchant_table_font pl-0 pr-0 text-center">
                  <img
                    src={`${image_base_url}${
                      item.requireFiatSettlementTrade
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                    }`}
                    width="14"
                    height="14"
                    alt="icon"
                    data-e2e="icon"
                    className="text-center"
                  />
                </td>
                <td className="table_item merchant_table_font pl-0 pr-0 text-center">
                  {item.incrementSize ? item.incrementSize : "N/A"}
                </td>
                <td className="table_item merchant_table_font pl-0 pr-0 text-center">
                  {item.enabled !== true || item.enabled !== false
                    ? getStatus(item.enabled)
                    : "N/A"}
                </td>
                <td
                  className="table_item merchant_table_font pl-0 pr-0"
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <EditIcon
                    fontSize="small"
                    className="cursor-pointer pr-3"
                    data-e2e={index}
                    onClick={() => isViewPermissionValid? null: 
                      handleEditCrypto({
                        ...item,
                      })
                    }
                  />
                  <span className="cursor-pointer" data-e2e="crypto_close_icon">
                    <CloseIcon
                      onClick={() => isViewPermissionValid? null:
                        props?.changeDeletingCryptoId(item?._id)
                      }
                      fontSize="small"
                    />
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

const DeletingAlert = (props) => {
  return (
    <div className="merchant_dailog_screen">
      <Dialog
        open={props.deletingId ? true : false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
      >
        <div>
          <Modal
            show={true}
            className="ClearAlertModal deleteAlertModal ClearAlertModals"
            onHide={() => {
              props.onHide();
            }}
          >
            <Modal.Body className="ClearAlertModalBody">
              <div className="ClearAlertModalIconWrap">
                <img
                  src={`${props.image_base_url}${deleteIcon}`}
                  alt="resetIcon"
                  className="ClearAlertModalIcon"
                />
              </div>
              <div className="ClearAlertModalTitle">Are you sure?</div>
              <div className="ClearAlertModalDescription">{props.msg}</div>
              <div
                className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
                style={{ background: "#E02020" }}
                onClick={() => isViewPermissionValid ? null : (props?.onDeleteClickHandler(), props?.onHide())}
                data-e2e="clear"
              >
                Delete
              </div>
              <div
                className="ClearAlertModalGoBackBtn"
                onClick={() => {
                  props.onHide();
                }}
              >
                Go Back
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Dialog>
    </div>
  );
};
