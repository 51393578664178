import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { call } from '../../../../../config/axios';
import { ADD_NEW_BLOCKED_EMAIL } from "../../../../../config/endpoints"
import localDb from '../../../../../localDb';
import {
    Dialog
} from '@mui/material';


const AddBlockedEmail = ({ open, setOpen, acquireId, emailList }) => {
    const [cardNumber, setCardNumber] = useState("")
    const [blockedEmail, setBlockedEmail]=useState("")
    const [cardNumberLast4Digits, setCardNumberLast4Digits] = useState("")
    const [error, setError] = useState(null)
    const baseUrl = useSelector((state) => state.config.api_url);
    const handleAddNewBlockedCard = async () => {
        const validation=(email)=>{
            if(emailList.includes(email)){
                setError("This email has been already blocked")
                return true
            }
            if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))){
                setError("Please enter a valid EmailID")
                return true
            }
        }
        if(validation(blockedEmail)){
            return
        }
        let payload = {
            email: localDb.getVal('email'),
            blockedEmail: blockedEmail,
            acquireId: acquireId
        }
        try {
            const response = await call(
                {
                    ...ADD_NEW_BLOCKED_EMAIL,
                    url: baseUrl + ADD_NEW_BLOCKED_EMAIL.url,
                },
                payload
            )
            if (response) {
                window.location.reload()
            }
        } catch (err) {
            console.error("Error in handleAddNewBlockedCard ", err)
        }
    }
    return (
        <Dialog
            disableEnforceFocus
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}>
            <div className='addBlockCardContainer bg-white min-h-[300px] min-w-[400px] p-4 h-full'>
                <div className='font-bold text-[22px] mb-8 '>Add Email to Blocked List</div>
                <div>
                    <div className='font-bold text-[15px] mb-2'>Blocked Email</div>
                    <input name='blockedEmail' type='email'value={blockedEmail} onChange={(e) => { setBlockedEmail(e.target.value); setError(null) }} placeholder='Enter the email address' className="form-control myAccoutOptModalLable_input_wrap rounded-full" />

                </div>
                <div className='text-red-700 mt-4'>{error}</div>
                <button
                    onClick={handleAddNewBlockedCard}
                    className='px-2 font-bold py-2 mt-8 w-full border rounded-full theme-btn'>Add</button>
            </div>
        </Dialog>
    )
}
export default AddBlockedEmail