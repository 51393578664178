import React, { useEffect, useState } from 'react';
import { call } from './config/axios';
import { WHITE_LEBAL_PAYMENT_PROVIDER } from './config/endpoints';
import { useSelector } from "react-redux"
import GlobalStyle from './client/common/GlobalTheming';
import { removeHttp, saveToSessionStorage } from './client/common/CommonUtils';
import RoutesList from './client//Routes.js';
import "./scss/style.scss"

function App(props) {
  const [showFullPageLoading, setShowFullPageLoading] = useState(true)
  const base_url = useSelector((state) => state.config.api_url)
  useEffect(() => {
    const ele = document.getElementById('ipl-progress-indicator');
    if (ele && !showFullPageLoading) {
      ele.classList.add('available');
      ele.outerHTML = '';
    }
    const customColor = sessionStorage.getItem("headerButtonLightBgColour");
    const hoverColor = sessionStorage.getItem('headerLightTextColour');
    if (customColor && customColor !== "null" && customColor !== "undefined" ) {
      document.documentElement.style.setProperty("--headerButtonLightBgColour", customColor); 
    }
    if(hoverColor && hoverColor !== "null" && hoverColor !== "undefined"){
      document.documentElement.style.setProperty("--headerLightTextColour", hoverColor);
    }
  }, [showFullPageLoading]);

  const getWhiteLebalData = async () => {
    const pathname = window.location.hostname
    await call(
      {
        ...WHITE_LEBAL_PAYMENT_PROVIDER,
        url: base_url + WHITE_LEBAL_PAYMENT_PROVIDER.url,
      },
      { redirectUrl: removeHttp(pathname) }
    )
      .then((data) => {
        saveToSessionStorage(data.data)
        setShowFullPageLoading(false)
      })
      .catch((err) => {
        setShowFullPageLoading(false)
      });
  }
  useEffect(() => {
    getWhiteLebalData()
  }, [])

  return (
    <div className="App">
      <GlobalStyle />
      <RoutesList />
    </div>
  );
}

export default App;

