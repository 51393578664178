import React, { useState } from 'react'
import { Table } from 'reactstrap';
import moment from 'moment';
import JSONPretty from "react-json-pretty";
import localDb from "../../../../localDb";
import { SortByIcon } from '../../../common/CommonUtils';

const SweepLogTable = (props) => {
    const [loading, setLoading] = useState(false);
    return (
        <div className="TransactionTableMain">
            {props && props.data && props.data.length > 0 ? (
                <Table responsive style={{ border: 'none' }}>
                    <tbody className="TransactionTable_BODY">
                        <tr className="TransactionTable_BODY_Header">
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                onClick={() => {
                                    props.changeOrder('creationDate');
                                }}
                                data-e2e="createDate"
                            >
                                CREATED DATE{' '}
                                {props.sortBy === 'creationDate' && (
                                    <SortByIcon orderBy={props.orderBy} />
                                )}
                            </th>
                        </tr>
                        {props.data.map((TData, i) => {
                            return <SingleTransactionTableRow data={TData} key={i} loading={loading} setLoading={setLoading} getSweepLogs={() => { props.getSweepLogs() }} />;
                        })}
                    </tbody>
                </Table>
            ) : (
                <div className="No_Transactions_Found">No Data Found</div>
            )}
            {props.showMoreButton ? (
                <div className="Show_more_wrap">
                    <div
                        className="Show_more"
                        onClick={() => {
                            props.handleShowMore();
                        }}
                        data-e2e="showMore"
                    >
                        Show More
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default SweepLogTable

const SingleTransactionTableRow = (props) => {
    const [expendTableRow, setExpendTableRow] = useState(false)
    let { data } = props;
    // const EventUrl = getEventPage(data)
    return (
        <React.Fragment>
            <tr className="CP_TableRow cursor_pointer" onClick={() => setExpendTableRow(!expendTableRow)}>
                <td className="transactionsTable_item cursor_pointer" colSpan="6">
                    {data.creationDate && data.creationDate
                        ? moment(data.creationDate).format('DD/MM/YYYY - HH:mm')
                        : 'N/A'}
                </td>
            </tr>
            {expendTableRow && <tr style={{ padding: "0px, 20px", width: "100vh", overflowY: "auto" }} >
                <td colSpan="6" >
                    <Table responsive style={{ border: 'none' }}>
                    <tbody className="TransactionTable_BODY">
                   {localDb.getVal("adminRole") == "SuperAdmin" ?
                           (data.providers && data.providers.map((provider) => {
                                return <SingleProviderRow provider={provider} />;

                            }))
                        :
                        data.providers && data.providers.map((provider) => {
                          return  <React.Fragment> {provider && provider.USDT &&
                              <SingleCurrencyRow data={provider.USDT} currencyName="USDT" />
                         }
                         {provider && provider.DAI &&
                             <SingleCurrencyRow data={provider.DAI} currencyName="DAI" />
                         }
                         {provider && provider.USDC &&
                             <SingleCurrencyRow data={provider.USDC} currencyName="USDC" />
                         }
                         {provider && provider.ETH &&
                             <SingleCurrencyRow data={provider.ETH} currencyName="ETH" />
                         }
                         {provider && provider.LTC &&
                             <SingleCurrencyRow data={provider.LTC} currencyName="LTC" />
                         }
                         {provider && provider.XRP &&
                             <SingleCurrencyRow data={provider.XRP} currencyName="XRP" />
                         }
                         {provider && provider.BTC &&
                             <SingleCurrencyRow data={provider.BTC} currencyName="BTC" />
                         }
                         {provider && provider.TRX &&
                             <SingleCurrencyRow data={provider.TRX} currencyName="TRX" />
                         }
                         {provider && provider['TRC20-USDT'] &&
                             <SingleCurrencyRow data={provider['TRC20-USDT']} currencyName="TRC20-USDT" />
                         }
                         </React.Fragment>
                     })}
                     </tbody>
                    </Table>
                </td>
            </tr>}
        </React.Fragment>
    );
};


const SingleProviderRow = (props) => {
    const [expendRow, setExpendRow] = useState(false)
    let { provider } = props;
    // const EventUrl = getEventPage(data)
    return (
        <React.Fragment> <tr className="CP_TableRow" onClick={() => setExpendRow(!expendRow)}>
            <td className="transactionsTable_item cursor_pointer">
                {provider && provider.providerName}
            </td>
        </tr>
            {expendRow && <tr style={{ padding: "0px, 20px", width: "100vh", overflowY: "auto" ,}} >
                <td colSpan="6" >
                    <Table responsive style={{ border: 'none' }}>
                        <tbody className="TransactionTable_BODY">
                            {provider && provider.USDT &&
                                <SingleCurrencyRow data={provider.USDT} currencyName="USDT" />
                            }
                            {provider && provider.DAI &&
                                <SingleCurrencyRow data={provider.DAI} currencyName="DAI" />
                            }
                            {provider && provider.USDC &&
                                <SingleCurrencyRow data={provider.USDC} currencyName="USDC" />
                            }
                            {provider && provider.ETH &&
                                <SingleCurrencyRow data={provider.ETH} currencyName="ETH" />
                            }
                            {provider && provider.LTC &&
                                <SingleCurrencyRow data={provider.LTC} currencyName="LTC" />
                            }
                            {provider && provider.XRP &&
                                <SingleCurrencyRow data={provider.XRP} currencyName="XRP" />
                            }
                            {provider && provider.BTC &&
                                <SingleCurrencyRow data={provider.BTC} currencyName="BTC" />
                            }
                            {provider && provider.TRX &&
                                <SingleCurrencyRow data={provider.TRX} currencyName="TRX" />
                            }
                            {provider && provider['TRC20-USDT'] &&
                              <SingleCurrencyRow data={provider['TRC20-USDT']} currencyName="TRC20-USDT" />
                            }
                        </tbody>
                    </Table>
                </td>
            </tr>}
        </React.Fragment>

    );
};

const SingleCurrencyRow = (props) => {
    const [expendRow, setExpendRow] = useState(false)
    let { data,currencyName } = props;
    // const EventUrl = getEventPage(data)
    return (
        <React.Fragment> <tr className="CP_TableRow" onClick={() => setExpendRow(!expendRow)}>
            <td className="transactionsTable_item cursor_pointer">
                {currencyName}
            </td>
        </tr>
            {expendRow && <tr style={{ padding: "0px, 20px", width: "100vh", height: "300px", overflowY: "auto" }} >
                <td colSpan="6" style={{ maxHeight: "400px", height: "400px", overflow: "auto" }} >
                    <JSONPretty data={data} />
                </td>
            </tr>}
        </React.Fragment>

    );
};