import {Table} from "@mui/material";
import {getCryptoIcon} from "./utils/helperFunctions";
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment/moment";
import React from "react";
import { isViewPermissionValid } from "../../../common/CommonUtils";

export const BalanceTableDesktop = ({
  data,
  imageBaseUrl,
  handleEditBalance
}) => {
  return (
    <div
      className="TransactionTableMain transaction_table_link table-full-width table_padding"
    >
      <Table responsive>
        <tbody
          className="TransactionTable_BODY transaction_view_sub transaction_view_sub_Scroll make-table-flex scrollit up"
        >
        <tr className="tr-flex">
          <th className="TransactionTable_heading transaction_table remove crypto-width">
            Token
          </th>
          <th className="TransactionTable_heading transaction_table remove">
            Balance
          </th>
          <th className="TransactionTable_heading transaction_table table-centered remove">
            Last Updated
          </th>
        </tr>
        {data &&
          data.balance &&
          data.balance.length > 0 &&
          data.balance.map((item, index) => {
            return (
              <tr className="CP_TableRow tr-flex" key={index}>
                <td className="transactionsTable_item transactionsTable_item_fonts balance-parent crypto-width d-flex align-items-center">
                  <img
                    src={`${imageBaseUrl}${getCryptoIcon(
                      item.cryptoId
                    )}`}
                    alt={item.cryptoId}
                    className="currencyList_Icon"
                    data-e2e="currencyList_Icon"
                    width="25px"
                    height="25px"
                  />{" "}
                  <span>&nbsp;{item.cryptoName || "N/A"}</span>{" "}
                  <span style={{ color: "#afb4be" }}>
                      ({item.cryptoId})
                    </span>
                </td>
                <td className="transactionsTable_item transactionsTable_item_fonts balance-parent ">
                  <div className="balanceAndEditWrap balance-child">
                      <span>
                        {item.balanceAmount
                          ? item.balanceAmount
                          : item.balanceAmount === 0
                            ? 0
                            : "-"}
                      </span>
                    <EditIcon
                      data-e2e={`${
                        item.cryptoName
                          ? item.cryptoName
                            .toLowerCase()
                            .replaceAll(" ", "-")
                          : ""
                      }-eidt-icon`}
                      data-e2e-amount={
                        item.balanceAmount
                          ? item.balanceAmount
                          : item.balanceAmount === 0
                            ? 0
                            : "-"
                      }
                      fontSize="large"
                      color="action"
                      className={`${isViewPermissionValid? "disabled": ""} cursor-pointer balanceEditButton`}
                      onClick={isViewPermissionValid? null: () => {
                        handleEditBalance(item);
                      }}
                    />
                  </div>
                </td>
                <td className="transactionsTable_item transactionsTable_item_fonts table-centered">
                  {item.updated
                    ? moment(item.updated).format(
                      "DD/MM/YYYY - HH:mm"
                    )
                    : "N/A"}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
        {data &&
          data.balance &&
          data.balance.length > 0 && (
            <tr
              className="walletsTotalBalance"
            >
              <th
                className="approx-left"
              >
                Approx USD Balance
              </th>
              <th
                className="approx-middle"
                data-e2e-val="amount"
                data-e2e={
                  data
                    ? data.balanceAmountUSDApprox
                    : "N/A"
                }
              >
                {data
                  ? data.balanceAmountUSDApprox
                  : "N/A"}
              </th>
              <th
                className="approx-right"
              ></th>
            </tr>
          )}
        </tfoot>
      </Table>
    </div>
  );
}
