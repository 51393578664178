import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import SettingsIcon from "@mui/icons-material/Settings";
import Loading from "../../../common/Loading";
import localDb from "../../../../localDb";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { call, logoutAfterError } from "../../../../config/axios";
import moment from "moment";
import HouseKeepingCsv from "./HouseKeepingAlertsCsv";
import Dialog from "@mui/material/Dialog";
import HouseKeepingFilters from "./HouseKeepingAlertsFilters";
import HouseKeepingTable from "./HouseKeepingAlertsTable";
import {
  GET_CRYPTOS,
  GET_ALL_HOUSE_KEEPING_ALERTS,
} from "../../../../config/endpoints";
import { setHeader } from "../../../Redux/actions/commonAction";
import useDebounce from "../../global/useDebounce";

const searchicon = "/cryptonpay/crytoicons/searchicon1.png";
const filterIcon = "/cryptonpay/filter_icon.svg";
const downloadIcon = "/cryptonpay/download_icon.svg";

function HouseKeepingAlerts() {
  const dispatch = useDispatch();
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal("email");
  const base_url = useSelector((state) => state.config.api_url);
  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  const { time } = searchParams

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearhText] = useState("");
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("alertTimestamp");
  const [fromDate, setFromDate] = useState(
    time ? timedata(searchParams) : moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [toDate, setToDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [dateSelected, setDateSelected] = useState(false);

  const [houseKeepingFilter, setHouseKeepingFilters] = useState(false);
  const [downloadCsv, setDownloadCsv] = useState(false);

  const [save, setSave] = useState(false);

  const [statusList, setStatusList] = useState(["Matched", "Unmatched"]);
  const [selectedStatusList, setSelectedStatusList] = useState([
    "Matched",
    "Unmatched",
  ]);

  const [blockchainList, setBlockchainList] = useState(["BitCoin", "Ethereum"]);
  const [selectedBlockchainList, setSelectedBlockchainList] = useState([
    "Bitcoin",
    "Ethereum",
  ]);

  const [networkList, setNetworkList] = useState(["testnet", "mainnet"]);
  const [selectedNetworkList, setSelectedNetworkList] = useState([
    "testnet",
    "mainnet",
  ]);

  const [symbolList, setSymbolList] = useState([]);
  const [selectedSymbolList, setSelectedSymbolList] = useState([]);

  const handleSave = () => {
    setSave(!save);
  };
  const handleHouseKeepingFilters = () => {
    setHouseKeepingFilters(false);
  };

  const handleGlobalSettings = () => {
    alert("under development...");
  };

  const resetFilters = () => {
    setSearhText("");
    setOrderBy("desc");
    setSortBy("alertTimestamp");
    setPage(1);
    setDateSelected(false);
    setTotalCount(0);
    setSelectedStatusList(["Matched", "Unmatched"]);
    setSelectedBlockchainList(blockchainList);
    setSelectedSymbolList(symbolList);
    setSelectedNetworkList(networkList);
    setFromDate([
      moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    ]);
    setToDate([moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")]);
  };
  const handleCloseDownloadCsv = () => {
    setDownloadCsv(false);
  };
  

  const name = "Housekeeping Alerts";

  const buttons = [
    {
      name: "Settings",
      onClick: handleGlobalSettings,
      icon: <SettingsIcon />,
    },
  ];
  const getAllBlockchainTransactions = async (page) => {
    let pageNo = 1;

    if (page) {
      pageNo = page;
    }
    let filterParams = {
      transactionId: searchText,
      page: pageNo,
      sortBy: sortBy,
      orderBy: orderBy,
      status:
        selectedStatusList.length === 0 ||
        selectedStatusList.length === statusList.length
          ? "All"
          : selectedStatusList,
      blockchain:
        selectedBlockchainList.length === 0 ||
        selectedBlockchainList.length === blockchainList.length
          ? "All"
          : selectedBlockchainList,
      network:
        selectedNetworkList.length === 0 ||
        selectedNetworkList.length === networkList.length
          ? "All"
          : selectedNetworkList,
      symbol:
        selectedSymbolList.length === 0 ||
        selectedSymbolList.length === symbolList.length
          ? "All"
          : selectedSymbolList,
    };
    if (dateSelected || time) {
      filterParams = {
        ...filterParams,
        fromDate: fromDate,
        toDate: toDate,
      };
    }
    let payload = {
      email: email,
      filterParams: filterParams,
    };

    setLoading(true);
    await call(
      {
        ...GET_ALL_HOUSE_KEEPING_ALERTS,
         url: base_url + GET_ALL_HOUSE_KEEPING_ALERTS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          if (pageNo === 1) {
            setData(res.data.houseKeepingAlerts);
            setTotalCount(res.data.totalCount);
          } else {
            let list = data.concat(res.data.houseKeepingAlerts);
            setData(list);
            setTotalCount(res.data.totalCount);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const getAllCryptos = async () => {
    let payload = {
      email: localDb.getVal("email"),
    };
    setLoading(true);
    await call(
      {
        ...GET_CRYPTOS,
        url: base_url + GET_CRYPTOS.url,
      },
      payload
    )
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          let cryptos = res.data.cryptos.map((crypto) => {
            return crypto.symbol;
          });
          let blockchains = [];
          res.data.cryptos.map((crypto) => {
            if (!blockchains.includes(crypto.blockchain)) {
              blockchains.push(crypto.blockchain);
            }
          });
          setSymbolList(cryptos);
          setSelectedSymbolList(cryptos);
          setBlockchainList(blockchains);
          setSelectedBlockchainList(blockchains);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };
  const debouncedSearchText = useDebounce(searchText);
  useEffect(() => {
    dispatch(setHeader("Housekeeping Alerts"));
    getAllBlockchainTransactions()
  }, [debouncedSearchText, orderBy, sortBy, save]);

  useEffect(() => {
    getAllCryptos();
  }, []);

  return (
    <div className="pageContainer merchant_page">
      <HeaderAndFooterLayout>
        <CommonHeader name={name} buttons={buttons} />
        <div style={{ height: "100vh" }}>
          <div className="transaction_filter">
            <div className="transactionsFiltersWrap">
              <div className="transactionsFilters">
                <div className="transactionsFiltersItem transactionsSearchFilter">
                  <div className="transactionsFilterTextWrap">
                    <input
                      type="text"
                      className="transactionsFilterInput"
                      value={searchText}
                      name="searchText"
                      id="searchText"
                      data-e2e="search-textbox"
                      onChange={(e) => {
                        setSearhText(e.target.value);
                        setPage(1);
                      }}
                      placeholder="Search Blockchain, Network,  Wallet Address, Transaction Id "
                    />
                  </div>
                  <div className="transactionsFilterSingleIconWrap">
                    <img
                      src={`${image_base_url}${searchicon}`}
                      alt="searchicon"
                      className="transactionsFilterSingleIcon"
                      data-e2e="searchIcon"
                      onClick={() => {
                        getAllBlockchainTransactions();
                      }}
                    />
                  </div>
                </div>

                <div
                  className="filterBtn"
                  onClick={() => {
                    setHouseKeepingFilters(true);
                  }}
                >
                  <img
                    data-e2e="filter-icon"
                    src={`${image_base_url}${filterIcon}`}
                    alt="filterIcon"
                    className="filterBtnIcon"
                  />
                </div>
                <Dialog
                  open={houseKeepingFilter}
                  onClose={handleHouseKeepingFilters}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <HouseKeepingFilters
                    handleHouseKeepingFilters={handleHouseKeepingFilters}
                    statusList={statusList}
                    setStatusList={setStatusList}
                    selectedStatusList={selectedStatusList}
                    setSelectedStatusList={setSelectedStatusList}
                    blockchainList={blockchainList}
                    setBlockchainList={setBlockchainList}
                    selectedBlockchainList={selectedBlockchainList}
                    setSelectedBlockchainList={setSelectedBlockchainList}
                    networkList={networkList}
                    setNetworkList={setNetworkList}
                    selectedNetworkList={selectedNetworkList}
                    setSelectedNetworkList={setSelectedNetworkList}
                    symbolList={symbolList}
                    setSymbolList={setSymbolList}
                    selectedSymbolList={selectedSymbolList}
                    setSelectedSymbolList={setSelectedSymbolList}
                    fromDate={moment(fromDate)}
                    toDate={moment(toDate)}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    handleSave={handleSave}
                    setDateSelected={setDateSelected}
                    dateSelected={dateSelected}
                    resetFilters={resetFilters}
                  />
                </Dialog>
              </div>
            </div>
            <div
              className="filterBtn"
              style={{ marginLeft: "10px" }}
              onClick={() => setDownloadCsv(true)}
            >
              <img
                data-e2e="downloadIcon"
                src={`${image_base_url}${downloadIcon}`}
                alt="filterIcon"
                className="downloadBtnIcon"
              />
            </div>
            <Dialog
              open={downloadCsv}
              onClose={handleCloseDownloadCsv}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <HouseKeepingCsv
                handleCloseDownloadCsv={handleCloseDownloadCsv}
                data={data}
                email={email}
                base_url={base_url}
                resetFilters={resetFilters}
                name="merchants"
                data-e2e="merchants"
              />
            </Dialog>
          </div>
          <div>
            <HouseKeepingTable
              data={data}
              sortBy={sortBy}
              orderBy={orderBy}
              changeOrder={(sort) => {
                if (sortBy !== sort) {
                  setOrderBy("desc");
                  setSortBy(sort);
                } else {
                  setOrderBy(orderBy === "desc" ? "asc" : "desc");
                }
              }}
              handleShowMore={() => {
                let newPage = page + 1;
                setPage(newPage);
                getAllBlockchainTransactions(newPage);
              }}
              showMoreButton={totalCount && data && data.length < totalCount}
            />
          </div>
        </div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
}

export default HouseKeepingAlerts;

const timedata = (searchParams) => {
  const { time } = searchParams
  if (time === "Last 30 Min") {
    return moment().subtract(30, "minutes").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  } else if (time === "Last 1 Hour") {
    return moment().subtract(60, "minutes").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  } else if (time === "Last 24 Hour") {
    return moment().subtract(1, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  } else if (time ==="Last 48 Hour"){
  return moment().subtract(2, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  }else if(time === "Last 7 Days") {
    return moment().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  }
}