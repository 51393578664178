import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { isViewPermissionValid } from "../../../../../../client/common/CommonUtils";

const IntegrationType = ({
  setEditIntegrationTypes,
  setEditIntegrationTypesName,
  setShowEditModelIntegration,
  setShowIntergartionModel,
  integrationType,
  setDeleteIntegrationType,
  setDeleteIntegrationTypeId,
}) => {
  const onClickEdit = (item) => {
    setEditIntegrationTypes(item._id);
    setEditIntegrationTypesName(item.acquireName);
    setShowEditModelIntegration(true);
  };

  const onClickDelete = (item) => {
    setDeleteIntegrationType(true);
    setDeleteIntegrationTypeId(item._id);
  };

  return (
    <div className="TabContainer">
      <div className="bg-[#F9FAFB] h-full">
        <h2 className="heading">Integration</h2>
        <div>
          {integrationType &&
            integrationType.map((item, i) => {
              return (
                <div key={i} className=" border-b ml-[10px]">
                  <div className="w-full px-2 max-w-[600px] py-4  flex  ">
                    <div className="flex-1 ">{item.acquireName}</div>
                    <div className="flex-1"></div>
                    <div className="flex-1 flex gap-2">
                      <div onClick={() => isViewPermissionValid? null: onClickEdit(item)}>
                        <EditIcon
                          fontSize="large"
                          color="action"
                          className="cursor-pointer pr-3"
                          data-e2e="ip_edit_icon"
                        />
                      </div>

                      <div onClick={() => isViewPermissionValid? null: onClickDelete(item)}>
                        <DeleteIcon
                          fontSize="large"
                          color="warning"
                          className={`cursor-pointer pr-3 ${isViewPermissionValid? "disabled": ""}`}
                          data-e2e="ip_edit_icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          <button
            onClick={() => isViewPermissionValid? null: setShowIntergartionModel(true)}
            className={`btnAddService ${isViewPermissionValid? "disabled": ""}`}
          >
            Add Integration
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntegrationType;
