import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import ResetModal from "../CommonPages/ResetModal"
import DateFilterInput from "../../../common/DateFilterInput";
const closeImage = "/cryptonpay/crytoicons/close.png";

function RealTimeExchangeRatesFilters(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [openPopup,resetOpenPopup]=useState(false)
  const closeReset=()=>resetOpenPopup(false)

  const formatDate = (date) => moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  const onChangeDate = (start, end, label) => {
    props.setFromDate(moment(start).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    props.setToDate(moment(end).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    props.setDateSelected(true);
  };
  const handleUpdateSettings = () => {
    props.handleExchangeRateFilters();
    props.handleSave();
  };
  return (
    <div className="filter_model_container">
      <div className="filter_model">
      <ResetModal open={openPopup} close={closeReset} reset={props.resetFilters}/>
        <div className="filter_model_heading_wrap">
          <div
            className="filter_clear"
            data-e2e="filter-clear-btn"
            onClick={() => resetOpenPopup(true)}
          >
            Clear
          </div>
          <div className="filter_heading">Exchange Rate Filters</div>
          <div
            className="filter_close_button"
            onClick={() => {
              props.handleExchangeRateFilters();
            }}
          >
            <img
              src={`${image_base_url}${closeImage}`}
              alt="closeImage"
              data-e2e="close-icon"
            />
          </div>
        </div>
      </div>
      <DateFilterInput
        fromDate={props.fromDate}
        toDate={props.toDate}
        onChangeDate={onChangeDate}
        dateSelected={props.dateSelected}
      />

      <div
        className="FilterModalSave"
        data-e2e="FilterModalSave"
        onClick={() => {
          handleUpdateSettings();
        }}
      >
        UPDATE SETTINGS
      </div>
    </div>
  );
}

export default RealTimeExchangeRatesFilters;
