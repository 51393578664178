import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { call, logoutAfterError } from '../../../../config/axios';
import { GET_ALL_PAYMENT_PROVIDERS } from '../../../../config/endpoints';
import localDb from '../../../../localDb';
import Loading from '../../../common/Loading';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import CreateMerchant from '../Merchants/CreateMerchant';
import CreateUser from './CreateUser';

import CreateClient from './CreateClient';
import CreatePaymentProvider from './CreatePaymentProvider';
import PaymentProviderTable from './paymentProviderTable';
import UpdatePaymentProvider from './UpdatePaymentProvider';
import UploadIINPage from './UploadIINPage';
import useDebounce from '../../global/useDebounce';

const searchicon = '/cryptonpay/crytoicons/searchicon1.png';
const tick_green = '/cryptonpay/crytoicons/tick_green.png';

const PaymentProvider = () => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal('email');
  const base_url = useSelector((state) => state.config.api_url);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearhText] = useState('');
  const [orderBy, setOrderBy] = useState('asc');
  const [sortBy, setSortBy] = useState('createdAt');
  const [alertMsg, setAlertMsg] = useState('Payment Provider is updated.');
  const [successAlert, setSuccessAlert] = useState(false);
  const [updateSuccessAlert, setUpdateSuccessAlert] = useState(false);
  const [providerIdForMerchant, setProviderIdForMerchant] = useState('');
  const debouncedSearchText = useDebounce(searchText);
  useEffect(() => {
    getProviders()
  }, [debouncedSearchText, sortBy, orderBy]);

  const getProviders = async (page) => {
    let pageNo = 1;
    if (page) {
      pageNo = page;
    }
    let payload = {
      email,
      firstName: searchText,
      page: pageNo,
      sortBy: sortBy,
      orderBy: orderBy,
    };
    setLoading(true);
    await call(
      {
        ...GET_ALL_PAYMENT_PROVIDERS,
        url: base_url + GET_ALL_PAYMENT_PROVIDERS.url,
      },
      payload,
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          if (pageNo === 1) {
            setData(res.data.providerList);
            setTotalCount(res.data.totalCount);
          } else {
            let list = data.concat(res.data.providerList);
            setData(list);
            setTotalCount(res.data.totalCount);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, 'err');
        logoutAfterError(err);
      });
  };
  const [paymentProviderIdForUpdate, setPaymentProviderIdForUpdate] =
    useState(null);
  const [addNewMerchant, setAddNewMerchant] = useState(null);
  const [addNewClient, setAddNewClient] = useState(false);
  const [addNewUser, setAddNewUser] = useState(false);

  const [addNew, setAddNew] = useState(false);
  const handleCloseAddNew = () => {
    setAddNew(false);
    setAddNewMerchant(false);
    setAddNewUser(false);
  };

  const handleAddNewProvider = () => {
    setAddNew(true);
  };
  const buttons = [
    {
      name: 'Create New',
      onClick: handleAddNewProvider,
      icon: <AddCircleOutlineIcon />,
    },
  ];
  if (successAlert) {
    setInterval(() => {
      setSuccessAlert(false);
    }, 3000);
  }
  if (updateSuccessAlert) {
    setInterval(() => {
      setUpdateSuccessAlert(false);
    }, 3000);
  }
  return (
    <div className="pageContainer merchant_page">
      <HeaderAndFooterLayout>
        {successAlert && (
          <Stack className="position-absolute successAlert" spacing={2}>
            <Alert
              icon={
                <img
                  src={`${image_base_url}${tick_green}`}
                  alt="tick_green"
                  style={{ width: '32px' }}
                />
              }
              onClose={() => {
                setSuccessAlert(false);
              }}
              className="MuiAlert-root"
            >
              New Payment Provider has been added.
            </Alert>
          </Stack>
        )}
        {updateSuccessAlert && (
          <Stack className="position-absolute successAlert" spacing={2}>
            <Alert
              icon={
                <img
                  src={`${image_base_url}${tick_green}`}
                  alt="tick_green"
                  style={{ width: '32px' }}
                />
              }
              onClose={() => {
                setUpdateSuccessAlert(false);
              }}
              className="MuiAlert-root"
            >
              {alertMsg}
            </Alert>
          </Stack>
        )}
        <Dialog
          open={addNew}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CreatePaymentProvider
            handleCloseAddNew={handleCloseAddNew}
            getProviders={getProviders}
            setSuccessAlert={setSuccessAlert}
          />
        </Dialog>
        <Dialog
          open={paymentProviderIdForUpdate ? true : false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <UpdatePaymentProvider
            paymentProviderId={paymentProviderIdForUpdate}
            handleClose={() => {
              setPaymentProviderIdForUpdate(null);
            }}
            getProviders={getProviders}
            setAlertMsg={setAlertMsg}
            setUpdateSuccessAlert={setUpdateSuccessAlert}
          />
        </Dialog>
        <Dialog
          open={addNewMerchant ? true : false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CreateMerchant
            paymentProviderId={providerIdForMerchant}
            handleCloseAddNew={() => {
              setAddNewMerchant(null);
            }}
            setAlertMsg="Merchant added successfully"
            setUpdateSuccessAlert={setUpdateSuccessAlert}
          />
        </Dialog>
        <Dialog
          open={addNewClient}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CreateClient
            paymentProviderId={providerIdForMerchant}
            handleCloseAddNew={() => {
              setAddNewClient(false);
            }}
            getProviders={getProviders}
            setAlertMsg="Client added successfully"
            setUpdateSuccessAlert={setUpdateSuccessAlert}
          />
        </Dialog>
        <Dialog
          open={addNewUser}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CreateUser
            handleCloseAddNew={handleCloseAddNew}
            paymentProviderId={providerIdForMerchant}
            getProviders={getProviders}
            editPaymentProvideUser={false}
            setSuccessAlert={'User added successfully'}
          />
        </Dialog>
        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        <UploadIINPage
          />
        </Dialog>
        <div className="">
          <div className="pageHeader__blockMobile">
            <CommonHeader name={'All Payment Providers'} buttons={buttons} />
          </div>
          <div className="transactionFilter mb">
            <input
              type="text"
              className="transactionFilter__input"
              value={searchText}
              name="searchText"
              id="searchText"
              data-e2e="search-textbox"
              onChange={(e) => {
                setSearhText(e.target.value);
                setPage(1);
              }}
              placeholder="Search Provider ..."
            />
            <img
              src={`${image_base_url}${searchicon}`}
              alt="searchicon"
              className="transactionFilter__icon"
              data-e2e="searchIcon"
              onClick={() => {
                getProviders();
              }}
            />
          </div>

          <div>
            <PaymentProviderTable
              data={data}
              addNewMerchant={setAddNewMerchant}
              setAddNewClient={setAddNewClient}
              setAddNewUser={setAddNewUser}
              setPaymentProviderIdForUpdate={setPaymentProviderIdForUpdate}
              setProviderIdForMerchant={setProviderIdForMerchant}
              sortBy={sortBy}
              orderBy={orderBy}
              changeOrder={(sort) => {
                if (sortBy !== sort) {
                  setOrderBy('desc');
                  setSortBy(sort);
                } else {
                  setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
                }
              }}
              handleShowMore={() => {
                let newPage = page + 1;
                setPage(newPage);
                getProviders(newPage);
              }}
              showMoreButton={totalCount && data && data.length < totalCount}
            />
          </div>
        </div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
};

export default PaymentProvider;
