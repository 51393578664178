import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import IOSSwitch from "../IOSSwitch";
import localDb from "../../../../../../localDb";
import { call } from "../../../../../../config/axios";
import { UPDATE_CODE } from "../../../../../../config/endpoints";
import { useParams } from "react-router-dom";
import TabButton from "../../../../../common/TabButton";
import Loading from "../../../../../common/Loading";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";
import constant from "../../../../global/constant.js"
const selectedCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_filled.png";
const emptyCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_empty.png";

const CPMultiCountryCode = ({
  codeid,
  dataE2eLabel,
  field,
  items,
  selectedItems,
  ItemComponent,
  setShowDropDown,
  containerStyle = {},
}) => {
  const baseURL = useSelector((state) => state.config.api_url);
  const { id } = useParams();

  const handleAddNewAquirer = async () => {
    try {
      let payload = {
        email: localDb.getVal("email"),
        id: id,
        codeid: codeid._id,
      };
      if (field === "cardSchemeData") {
        payload.cardSchemeList = selectedItems;
      } else if (field === "AllCurrenciesData") {
        payload.allowedCurrenciesList = selectedItems;
      } else if (field === "AllIpAddressTypesData") {
        payload.ipAddressTypes = selectedItems;
      } else if (field === "AllMandatoryFieldsData") {
        payload.mandatoryFieldsList = selectedItems;
      } else {
        payload.cardCountryCode = selectedItems;
      }

      await call(
        {
          ...UPDATE_CODE,
          url: baseURL + UPDATE_CODE.url,
        },
        payload,
      );
    } catch (err) {
      console.error(err);
    }
  };

  const uniqueItems = new Set(selectedItems?.map(item => (field === "AllIpAddressTypesData" ? item : item?.name)));
  const sortedItems = [
    ...selectedItems,
    ...items?.filter(item => !uniqueItems?.has(field === "AllIpAddressTypesData" ? item : item?.name)),
  ];

  return (
    <div
      style={containerStyle}
      className="FilterModalCurrencyDropdownlist relative w-full"
    >
      <div className="sticky top-0 w-full">
        <div
          className="w-full"
          style={{ backgroundColor: "white", borderRadius: "5px" }}
        >
          <div
            className="FM_currencyDrop_All"
            onClick={() => {
              handleAddNewAquirer();
              setShowDropDown(false);
            }}
          >
            <span className="FM_currencyDrop_AllSelector">
              {selectedItems?.length === items?.length || selectedItems?.length === 0
                ? "Select options"
                : `${selectedItems.length} Selected`}
            </span>
            <span className="FilterModalInputDropUpIconWrap">
              <i
                className="fa fa-chevron-up FilterModalInputDropUpIcon"
                data-e2e={`${dataE2eLabel}contract-icon`}
              />
            </span>
          </div>
        </div>
      </div>

      {sortedItems.map((item, index) => (
        <ItemComponent item={item} key={index} />
      ))}
    </div>
  );
};

const CPMultiMerchentCode = ({
  codeid,
  dataE2eLabel,
  items,
  selectedItems,
  ItemComponent,
  setShowDropDown,
  containerStyle = {},
}) => {
  const baseURL = useSelector((state) => state.config.api_url);
  const { id } = useParams();

  const handleAddNewAquirer = async () => {
    try {
      let payload = {
        email: localDb.getVal("email"),
        id: id,
        codeid: codeid._id,
        merchantCountryCode: selectedItems,
      };
      const response = await call(
        {
          ...UPDATE_CODE,
          url: baseURL + UPDATE_CODE.url,
        },
        payload,
      );
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const onClickCurrencyDrop = () => {
    handleAddNewAquirer();
    setShowDropDown(false);
  };

  return (
    <div style={containerStyle} className="FilterModalCurrencyDropdownlist">
      <div className="sticky top-0 w-full">
        <div className="modalDropdownList">
          <div
            className="FM_currencyDrop_All"
            onClick={() => {
              onClickCurrencyDrop();
            }}
          >
            <span className="FM_currencyDrop_AllSelector">
              {selectedItems.length === items.length ||
              selectedItems.length === 0
                ? "Select options"
                : `${selectedItems.length} Selected`}
            </span>
            <span className="FilterModalInputDropUpIconWrap">
              <i
                className="fa fa-chevron-up FilterModalInputDropUpIcon"
                data-e2e={`${dataE2eLabel}contract-icon`}
              />
            </span>
          </div>
        </div>
      </div>
      {items.map((item, index) => {
        return <ItemComponent item={item} key={index} />;
      })}
    </div>
  );
};

const RulesTab = ({
  acquireService,
  handletoggleActiveCountryCode,
  countryCodesData,
  merchentCodesData,
  cardSchemeData,
  AllCurrenciesData,
  AllMandatoryFieldsData,
  getSingleAquireService,
  loading,
}) => {
  const imageBaseURL = useSelector((state) => state.config.image_base_url);
  const [tabs, setTabs] = useState(0);
  const [blockedCountryDropDown, setBlockedCountryDropDown] = useState(false);
  const [blockedCardSchemeDropDown, setBlockedCardSchemeDropDown] =
    useState(false);
  const [allowedCurrenciesDropDown, setAllowedCurrenciesDropDown] =
  useState(false);
  const [ipAddressTypesDropDown, setIpAddressTypesDropDown] =
  useState(false);
  const [mandatoryFieldsDropDown, setMandatoryFieldsDropDown] = useState(false);
  const [blockedMerchentCodeDropDown, setBlockedMerchentCodeDropDown] =
    useState(false);

  const onClickSwitch = (e, item) => {
    const payload = {
      isCountryCodeActive: !e.target.checked ? false : true,
      codeid: item._id,
      uniqueId: acquireService.uniqueId,
    };
    handletoggleActiveCountryCode(payload);
  };

  const handleTabSwitch = (index) => {
    setTabs(index);
  };

  const [selectedCardCountryCode, setSelectedCardCountryCode] = useState([]);
  const [selectedMerchantCode, setSelectedMerchantCode] = useState([]);
  const [selectedCardScheme, setSelectedCardScheme] = useState([]);
  const [selectedAllowedCurrencies, setSelectedAllowedCurrencies] = useState(
    [],
  );
  const [selectedIpAddressTypes, setSelectedIpAddressTypes] = useState(
    [],
  );
  const [selectedMandatoryFields, setSelectedMandatoryFields] = useState([]);

  useEffect(() => {
    const item = acquireService.routingService[tabs];
    setSelectedCardCountryCode(
      item && item.rules && item.rules.cardCountryCode,
    );
    setSelectedMerchantCode(
      item && item.rules && item.rules.merchantCountryCode,
    );
    setSelectedCardScheme(item && item.rules && item.rules.cardSchemeList);
    setSelectedAllowedCurrencies(
      item && item.rules && item.rules.allowedCurrenciesList,
    );
    setSelectedIpAddressTypes(
      item && item.rules && item.rules.ipAddressTypes,
    );
    setSelectedMandatoryFields(
      item && item.rules && item.rules.mandatoryFieldsList,
    );
    getSingleAquireService();
  }, [tabs]);

  return (
    <div className="TabContainer">
      {loading && <Loading />}
      {acquireService ? (
        <div>
          <div className="acquireServiceScroll">
            {acquireService?.routingService?.map((tab, index) => (
              <TabButton
                key={index}
                activeTab={tabs}
                handleTabSwitch={handleTabSwitch}
                tab={tab}
                index={index}
              />
            ))}
          </div>
          {acquireService?.routingService?.length !== 0 ? (
            <div>
              {acquireService?.routingService?.map((item, index) => {
                countryCodesData = selectedCardCountryCode.concat(
                  countryCodesData.filter(
                    (item) =>
                      !selectedCardCountryCode.some(
                        (obj) => obj.code === item.code,
                      ),
                  ),
                );
                merchentCodesData = selectedMerchantCode.concat(
                  merchentCodesData.filter(
                    (item) =>
                      !selectedMerchantCode.some(
                        (obj) => obj.code === item.code,
                      ),
                  ),
                );

                const toggleCardCountry = (item) => {
                  if (
                    selectedCardCountryCode.some(
                      (code) => code.code === item.code,
                    )
                  ) {
                    let newList = selectedCardCountryCode.filter(
                      (code) => code.code !== item.code,
                    );
                    setSelectedCardCountryCode(newList);
                  } else {
                    let newList = [...selectedCardCountryCode];
                    newList.push(item);
                    setSelectedCardCountryCode(newList);
                  }
                };
                const toggleCardScheme = (item) => {
                  if (
                    selectedCardScheme.some((code) => code.name === item.name)
                  ) {
                    let newList = selectedCardScheme.filter(
                      (code) => code.name !== item.name,
                    );
                    setSelectedCardScheme(newList);
                  } else {
                    let newList = [...selectedCardScheme];
                    newList.push(item);
                    setSelectedCardScheme(newList);
                  }
                };
                const toggleAllowedCurrency = (item) => {
                  if (
                    selectedAllowedCurrencies.some(
                      (code) => code.name === item.name,
                    )
                  ) {
                    let newList = selectedAllowedCurrencies.filter(
                      (code) => code.name !== item.name,
                    );
                    setSelectedAllowedCurrencies(newList);
                  } else {
                    let newList = [...selectedAllowedCurrencies];
                    newList.push(item);
                    setSelectedAllowedCurrencies(newList);
                  }
                };
                const toggleIpAddressTypes = (item) => {
                  if (
                    selectedIpAddressTypes.some(
                      (type) => type === item,
                    )
                  ) {
                    let newList = selectedIpAddressTypes.filter(
                      (type) => type !== item,
                    );
                    setSelectedIpAddressTypes(newList);
                  } else {
                    let newList = [...selectedIpAddressTypes];
                    newList.push(item);
                    setSelectedIpAddressTypes(newList);
                  }
                };
                const toggleMandatoryFields = (item) => {
                  if (
                    selectedMandatoryFields.some(
                      (code) => code.name === item.name,
                    )
                  ) {
                    let newList = selectedMandatoryFields.filter(
                      (code) => code.name !== item.name,
                    );
                    setSelectedMandatoryFields(newList);
                  } else {
                    let newList = [...selectedMandatoryFields];
                    newList.push(item);
                    setSelectedMandatoryFields(newList);
                  }
                };
                const toggleMerchentCode = (item) => {
                  if (
                    selectedMerchantCode.some((code) => code.code === item.code)
                  ) {
                    let newList = selectedMerchantCode.filter(
                      (code) => code.code !== item.code,
                    );
                    setSelectedMerchantCode(newList);
                  } else {
                    let newList = [...selectedMerchantCode];
                    newList.push(item);
                    setSelectedMerchantCode(newList);
                  }
                };
                return (
                  <div key={index} className="w-full overflow-auto">
                    {tabs === index ? (
                      <div className="rulesTabs mt-2 h-full w-full rounded bg-[#F9FAFB]">
                        <div className="border-b">
                          <h3 className="px-2 py-4 text-lg">
                            {item.integrationType.acquireName}
                          </h3>
                        </div>

                        <div className="mt-2 flex border-b px-2 py-4">
                          <div className="w-80">
                            <div className="text-[16px] font-medium">
                              Blocked Card Issuer Countries
                            </div>
                            <div className="text-[12px] text-gray-700">
                              Select the countries that should be blocked from
                              BINN deposit
                            </div>
                          </div>
                          <div className={`mx-6 ${isViewPermissionValid? "disabled": ""}`}>
                            <IOSSwitch
                              defaultChecked={item.rules.isCountryCodeActive}
                              onClickSwitch
                              onClick={(e) => isViewPermissionValid? null: onClickSwitch(e, item)}
                            />
                          </div>
                          <div className="ml-8 w-2/5">
                            <div className="mb-2 text-[14px] ">Location</div>
                            <div
                              className={`FilterModalInputDropdown ${isViewPermissionValid? "disabled": ""}`}
                              onClick={() => isViewPermissionValid? null: 
                                setBlockedCountryDropDown(true)
                              }
                            >
                              <div
                                className="FilterModalInputText"
                                data-e2e="crypto-currency-expand"
                              >
                                {countryCodesData.length ===
                                  selectedCardCountryCode.length ||
                                selectedCardCountryCode.length === 0
                                  ? "Select options"
                                  : `${selectedCardCountryCode.length} Selected`}
                              </div>
                              <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                            </div>

                            {blockedCountryDropDown ? (
                              <CPMultiCountryCode
                                codeid={item}
                                dataE2eLabel="crypto-urrency-"
                                items={countryCodesData}
                                field="cardCountryCode"
                                selectedItems={selectedCardCountryCode}
                                setShowDropDown={setBlockedCountryDropDown}
                                setSelectedItems={(items) => {
                                  setSelectedCardCountryCode(items || []);
                                }}
                                ItemComponent={({ item: code }) => {
                                  return (
                                    <div
                                      className="currencyListItemWrap"
                                      onClick={() => {
                                        toggleCardCountry(code);
                                      }}
                                    >
                                      <div className="currencyListIconNameWrap">
                                        <div
                                          className="currencyList_Name"
                                          data-e2e={code.code
                                            .toLowerCase()
                                            .replaceAll(" ", "-")}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {code.name}
                                        </div>
                                      </div>
                                      <img
                                        src={`${imageBaseURL}${
                                          selectedCardCountryCode.some(
                                            (item) => item.code === code.code,
                                          )
                                            ? selectedCheckBoxIcon
                                            : emptyCheckBoxIcon
                                        }`}
                                        alt="checkbox"
                                        className="currencyListCheckBox"
                                      />
                                    </div>
                                  );
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="mt-2 flex border-b px-2 py-4">
                          <div className="w-80">
                            <div className="text-[16px] ">
                              Blocked Merchent User Issuer Countries
                            </div>
                            <div className="text-[12px] text-gray-700">
                              Select the countries that should be blocked from
                              BINN deposit
                            </div>
                          </div>
                          <div className={`mx-6 ${isViewPermissionValid? "disabled": ""}`}>
                            <IOSSwitch
                              defaultChecked={
                                item.rules.isMerchantCountryCodeActive
                              }
                              onClick={(e) => isViewPermissionValid? null: (
                                (()=>{
                                  const payload = {
                                    isMerchantCountryCodeActive: !e?.target?.checked
                                      ? false
                                      : true,
                                    codeid: item?._id,
                                    uniqueId: acquireService?.uniqueId,
                                  };
                                  handletoggleActiveCountryCode(payload);
                                })()
                             )}
                            />
                          </div>
                          <div className="ml-8 w-2/5">
                            <div className="mb-2 text-[14px] ">Location</div>
                            <div
                              className={`FilterModalInputDropdown ${isViewPermissionValid? "disabled": ""}`}
                              onClick={() => isViewPermissionValid? null: 
                                setBlockedMerchentCodeDropDown(true)
                              }
                            >
                              <div
                                className="FilterModalInputText"
                                data-e2e="crypto-currency-expand"
                              >
                                {countryCodesData.length ===
                                  selectedMerchantCode.length ||
                                selectedMerchantCode.length === 0
                                  ? "Select options"
                                  : `${selectedMerchantCode.length} Selected`}
                              </div>
                              <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                            </div>

                            {blockedMerchentCodeDropDown ? (
                              <CPMultiMerchentCode
                                codeid={item}
                                dataE2eLabel="crypto-urrency-"
                                items={merchentCodesData}
                                field="merchantCountryCode"
                                selectedItems={selectedMerchantCode}
                                setShowDropDown={setBlockedMerchentCodeDropDown}
                                setSelectedItems={(items) => {
                                  setSelectedMerchantCode(items || []);
                                }}
                                ItemComponent={({ item: code }) => {
                                  return (
                                    <div
                                      className="currencyListItemWrap"
                                      onClick={() => {
                                        toggleMerchentCode(code);
                                      }}
                                    >
                                      <div className="currencyListIconNameWrap">
                                        <div
                                          className="currencyList_Name"
                                          data-e2e={code.code
                                            .toLowerCase()
                                            .replaceAll(" ", "-")}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {code.name}
                                        </div>
                                      </div>
                                      <img
                                        src={`${imageBaseURL}${
                                          selectedMerchantCode.some(
                                            (item) => item.code === code.code,
                                          )
                                            ? selectedCheckBoxIcon
                                            : emptyCheckBoxIcon
                                        }`}
                                        alt="checkbox"
                                        className="currencyListCheckBox"
                                      />
                                    </div>
                                  );
                                }}
                              />
                            ) : null}
                          </div>
                        </div>

                        <div className="mt-2 flex border-b px-2 py-4">
                          <div className="w-80">
                            <div className="text-[16px] font-medium">
                              Blocked Card scheme
                            </div>
                            <div className="text-[12px] text-gray-700">
                              Select the Card scheme that should be blocked from
                              BINN deposit
                            </div>
                          </div>
                          <div className={`mx-6 ${isViewPermissionValid? "disabled": ""}`}>
                            <IOSSwitch
                              defaultChecked={item.rules.isCardSchemeListActive}
                              onClick={(e) => isViewPermissionValid? null: (
                                (()=>{
                                  const payload = {
                                    isCardSchemeListActive: !e?.target?.checked
                                      ? false
                                      : true,
                                    codeid: item?._id,
                                    uniqueId: acquireService?.uniqueId,
                                  };
                                  handletoggleActiveCountryCode(payload);
                                })()
                              )}
                            />
                          </div>
                          <div className="ml-8 w-2/5">
                            <div className="mb-2 text-[14px] ">Card scheme</div>
                            <div
                              className={`FilterModalInputDropdown ${isViewPermissionValid? "disabled": ""}`}
                              onClick={() => isViewPermissionValid? null: 
                                setBlockedCardSchemeDropDown(true)
                              }
                            >
                              <div
                                className="FilterModalInputText"
                                data-e2e="crypto-currency-expand"
                              >
                                {cardSchemeData.length ===
                                  selectedCardScheme.length ||
                                selectedCardScheme.length === 0
                                  ? "Select options"
                                  : `${selectedCardScheme.length} Selected`}
                              </div>
                              <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                            </div>

                            {blockedCardSchemeDropDown ? (
                              <CPMultiCountryCode
                                codeid={item}
                                dataE2eLabel="crypto-urrency-"
                                items={cardSchemeData}
                                field="cardSchemeData"
                                selectedItems={selectedCardScheme}
                                setShowDropDown={setBlockedCardSchemeDropDown}
                                setSelectedItems={(items) => {
                                  setSelectedCardScheme(items || []);
                                }}
                                ItemComponent={({ item }) => {
                                  return (
                                    <div
                                      className="currencyListItemWrap"
                                      onClick={() => {
                                        toggleCardScheme(item);
                                      }}
                                    >
                                      <div className="currencyListIconNameWrap">
                                        <div
                                          className="currencyList_Name"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {item.name}
                                        </div>
                                      </div>
                                      <img
                                        src={`${imageBaseURL}${
                                          selectedCardScheme.some(
                                            (value) => value.name === item.name,
                                          )
                                            ? selectedCheckBoxIcon
                                            : emptyCheckBoxIcon
                                        }`}
                                        alt="checkbox"
                                        className="currencyListCheckBox"
                                      />
                                    </div>
                                  );
                                }}
                              />
                            ) : null}
                          </div>
                        </div>

                        <div className="mt-2 flex border-b px-2 py-4">
                          <div className="w-80">
                            <div className="text-[16px] font-medium">
                              Allowed Currencies
                            </div>
                            <div className="text-[12px] text-gray-700">
                              Select the allowed currencies for the acquirer.
                            </div>
                          </div>
                          <div className={`mx-6 ${isViewPermissionValid? "disabled": ""}`}>
                            <IOSSwitch
                              defaultChecked={
                                item.rules.isAllowedCurrenciesListActive
                              }
                              onClick={(e) => isViewPermissionValid? null: (
                                (()=>{
                                  const payload = {
                                    isAllowedCurrenciesListActive: !e?.target
                                      ?.checked
                                      ? false
                                      : true,
                                    codeid: item?._id,
                                    uniqueId: acquireService?.uniqueId,
                                  };
                                  handletoggleActiveCountryCode(payload);
                                })()
                              )}
                            />
                          </div>
                          <div className="ml-8 w-2/5">
                            <div className="mb-2 text-[14px] ">Currencies</div>
                            <div
                              className={`FilterModalInputDropdown ${isViewPermissionValid? "disabled": ""}`}
                              onClick={() => isViewPermissionValid? null: 
                                setAllowedCurrenciesDropDown(true)
                              }
                            >
                              <div
                                className="FilterModalInputText"
                                data-e2e="crypto-currency-expand"
                              >
                                {AllCurrenciesData.length ===
                                  selectedAllowedCurrencies.length ||
                                selectedAllowedCurrencies.length === 0
                                  ? "Select options"
                                  : `${selectedAllowedCurrencies.length} Selected`}
                              </div>
                              <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                            </div>

                            {allowedCurrenciesDropDown ? (
                              <CPMultiCountryCode
                                codeid={item}
                                dataE2eLabel="crypto-urrency-"
                                items={AllCurrenciesData}
                                field="AllCurrenciesData"
                                selectedItems={selectedAllowedCurrencies}
                                setShowDropDown={setAllowedCurrenciesDropDown}
                                setSelectedItems={(items) => {
                                  setSelectedAllowedCurrencies(items || []);
                                }}
                                ItemComponent={({ item: item }) => {
                                  return (
                                    <div
                                      className="currencyListItemWrap"
                                      onClick={() => {
                                        toggleAllowedCurrency(item);
                                      }}
                                    >
                                      <div className="currencyListIconNameWrap">
                                        <div
                                          className="currencyList_Name"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {item.name}
                                        </div>
                                      </div>
                                      <img
                                        src={`${imageBaseURL}${
                                          selectedAllowedCurrencies.some(
                                            (value) => value.name === item.name,
                                          )
                                            ? selectedCheckBoxIcon
                                            : emptyCheckBoxIcon
                                        }`}
                                        alt="checkbox"
                                        className="currencyListCheckBox"
                                      />
                                    </div>
                                  );
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="mt-2 flex border-b px-2 py-4">
                          <div className="w-80">
                            <div className="text-[16px] font-medium">
                              Ip Address Types
                            </div>
                            <div className="text-[12px] text-gray-700">
                              Select the IP Address types for the acquirer. (By default both types will be checked)
                            </div>
                          </div>
                          <div className={`mx-6 disabled`}>
                          <IOSSwitch
                              defaultChecked={
                                true
                              }
                              onClick={(e) => null
                              }
                            />
                          </div>
                          <div className="ml-8 w-2/5">
                            <div className="mb-2 text-[14px] ">Ip Address Types</div>
                            <div
                              className={`FilterModalInputDropdown ${isViewPermissionValid? "disabled": ""}`}
                              onClick={() => isViewPermissionValid? null: 
                                setIpAddressTypesDropDown(true)
                              }
                            >
                              <div
                                className="FilterModalInputText"
                                data-e2e="crypto-currency-expand"
                              >
                                {constant.AllIpAddressTypesData.length ===
                                  selectedIpAddressTypes.length ||
                                  selectedIpAddressTypes.length === 0
                                  ? "Select options"
                                  : `${selectedIpAddressTypes.length} Selected`}
                              </div>
                              <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                            </div>

                            {ipAddressTypesDropDown ? (
                              <CPMultiCountryCode
                                codeid={item}
                                dataE2eLabel="crypto-urrency-"
                                items={constant.AllIpAddressTypesData}
                                field="AllIpAddressTypesData"
                                selectedItems={selectedIpAddressTypes}
                                setShowDropDown={setIpAddressTypesDropDown}
                                setSelectedItems={(items) => {
                                  setSelectedIpAddressTypes(items || []);
                                }}
                                ItemComponent={({ item: item }) => {
                                  return (
                                    <div
                                      className="currencyListItemWrap"
                                      onClick={() => {
                                        toggleIpAddressTypes(item);
                                      }}
                                    >
                                      <div className="currencyListIconNameWrap">
                                        <div
                                          className="currencyList_Name"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {item}
                                        </div>
                                      </div>
                                      <img
                                        src={`${imageBaseURL}${
                                          selectedIpAddressTypes.some(
                                            (type) => type === item,
                                          )
                                            ? selectedCheckBoxIcon
                                            : emptyCheckBoxIcon
                                        }`}
                                        alt="checkbox"
                                        className="currencyListCheckBox"
                                      />
                                    </div>
                                  );
                                }}
                              />
                            ) : null}
                          </div>
                        </div>

                        <div className="mt-2 flex border-b px-2 py-4">
                          <div className="w-80">
                            <div className="text-[16px] font-medium">
                              Mandatory Fields
                            </div>
                            <div className="text-[12px] text-gray-700">
                              Select the all mandatory fields for the acquirer.
                            </div>
                          </div>
                          <div className={`mx-6 ${isViewPermissionValid? "disabled": ""}`}>
                            <IOSSwitch
                              defaultChecked={
                                item.rules.isMandatoryFieldsListActive
                              }
                              onClick={(e) => isViewPermissionValid? null: (
                                (()=>{
                                  const payload = {
                                    isMandatoryFieldsListActive: !e?.target?.checked
                                      ? false
                                      : true,
                                    codeid: item?._id,
                                    uniqueId: acquireService?.uniqueId,
                                  };
                                  handletoggleActiveCountryCode(payload);
                                })()
                              )}
                            />
                          </div>
                          <div className="ml-8 w-2/5">
                            <div className="mb-2 text-[14px]">Fields</div>
                            <div
                              className={`FilterModalInputDropdown ${isViewPermissionValid? "disabled": ""}`}
                              onClick={() => isViewPermissionValid? null: 
                                setMandatoryFieldsDropDown(true)
                              }
                            >
                              <div
                                className="FilterModalInputText"
                                data-e2e="crypto-currency-expand"
                              >
                                {AllMandatoryFieldsData.length ===
                                  selectedMandatoryFields.length ||
                                selectedMandatoryFields.length === 0
                                  ? "Select options"
                                  : `${selectedMandatoryFields.length} Selected`}
                              </div>
                              <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                            </div>

                            {mandatoryFieldsDropDown ? (
                              <CPMultiCountryCode
                                codeid={item}
                                items={AllMandatoryFieldsData}
                                field="AllMandatoryFieldsData"
                                selectedItems={selectedMandatoryFields}
                                setShowDropDown={setMandatoryFieldsDropDown}
                                setSelectedItems={(items) => {
                                  setSelectedMandatoryFields(items || []);
                                }}
                                ItemComponent={({ item: item }) => {
                                  return (
                                    <div
                                      className="currencyListItemWrap"
                                      onClick={() => {
                                        toggleMandatoryFields(item);
                                      }}
                                    >
                                      <div className="currencyListIconNameWrap">
                                        <div
                                          className="currencyList_Name"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {item.name}
                                        </div>
                                      </div>
                                      <img
                                        src={`${imageBaseURL}${
                                          selectedMandatoryFields.some(
                                            (value) => value.name === item.name,
                                          )
                                            ? selectedCheckBoxIcon
                                            : emptyCheckBoxIcon
                                        }`}
                                        alt="checkbox"
                                        className="currencyListCheckBox"
                                      />
                                    </div>
                                  );
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="p-4 text-center">No Data</div>
          )}
        </div>
      ) : (
        <div className="w-full text-center">No Data</div>
      )}
    </div>
  );
};

export default RulesTab;
