import React from "react";
import { Table } from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { Link } from "react-router-dom";
import { isViewPermissionValid } from "../../../../../../common/CommonUtils";

const cardAcquirerTable = (props) => {
  const { serviceHeaders, antiFraudServiceData, paymentProviderList } = props;

  return (
    <div>
      <Table responsive className="routingServiceCofigTableHead">
        <tbody>
          <tr className="routingServiceCofigTableMain">
            {serviceHeaders?.map((item, index) => (
              <th
                className="GBtableHeading pl-0 pr-0 "
                style={{ width: item?.width }}
                key={index}
                data-e2e={item?.name}
              >
                {item?.name}
              </th>
            ))}
          </tr>
          {antiFraudServiceData?.map((item, index) => {
            const paymentProviderName = paymentProviderList?.find(
              (val) => val?._id === item?.paymentProvider,
            );
            return (
              <tr key={index}>
                <td className="table_item merchant_table_font  text-black">
                  <div className="px-2 text-blue-500">
                    <Tooltip title={item?.uniqueId} placement="top">
                      <Link to={`/card-acquirer-routing-new/${item?.uniqueId}`}>
                        <div>
                          {item?.uniqueId?.substring(0, 4) +
                            "..." +
                            item?.uniqueId?.substring(
                              item?.uniqueId?.length - 4,
                            )}
                        </div>
                      </Link>
                    </Tooltip>
                  </div>
                </td>
                <td className="table_item merchant_table_font pl-0 pr-0 text-black">
                  {paymentProviderName
                    ? paymentProviderName?.firstName +
                      " " +
                      paymentProviderName?.lastName
                    : "N/A"}
                </td>
                <td className="table_item merchant_table_font pl-0 pr-0 text-blue-500">
                  <Link
                    data-e2e={
                      item?.acquirerRoutingServiceName
                        ? item?.acquirerRoutingServiceName
                        : "N/A"
                    }
                    to={`/card-acquirer-routing-new/${item?.uniqueId}`}
                  >
                    {item?.acquirerRoutingServiceName
                      ? item?.acquirerRoutingServiceName
                      : "N/A"}
                  </Link>
                </td>
                <td className="table_item merchant_table_font pl-0 pr-0 text-black">
                  {item?.ftdToTrustedCount}
                </td>
                <td className="table_item merchant_table_font pl-0 pr-0 text-black">
                  {item?.createdAt
                    ? moment(item?.createdAt).format("lll")
                    : "N/A"}
                </td>
                <td className="table_item merchant_table_font routingServiceCofigTableDataMain px-2 pr-0 text-black">
                  <EditIcon
                    fontSize="large"
                    color="action"
                    className={`cursor-pointer pr-3 ${isViewPermissionValid ? "disabled" : ""}`}
                    data-e2e="ip_edit_icon"
                    onClick={() =>
                      isViewPermissionValid
                        ? null
                        : props.handleEditAntiFraudService({
                            ...item,
                          })
                    }
                  />
                  <span
                    className={`cursor-pointer ${isViewPermissionValid ? "disabled" : ""}`}
                    data-e2e="ip_delete_icon"
                  >
                    <DeleteIcon
                      onClick={() =>
                        isViewPermissionValid
                          ? null
                          : props?.deleteAntiFraud(item?.uniqueId)
                      }
                      fontSize="small"
                      color="warning"
                    />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div
        data-e2e="create-new-service-btn"
        className={`buttonAddAntiFraud w-full uppercase ${isViewPermissionValid ? "disabled" : ""}`}
        onClick={() =>
          isViewPermissionValid ? null : props.setShowAddNewAntiFraudModal(true)
        }
      >
        Create New Service
      </div>
    </div>
  );
};
export default cardAcquirerTable;
