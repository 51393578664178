import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import { call, logoutAfterError } from "../../../../config/axios";
import {
    GET_B2C_SPREAD_LEVEL,
    CREATE_MINIMUM_CONVERSION_AMOUNT,
    GET_MINIMUM_CONVERSION_AMOUNT
} from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import CommonHeader from '../CommonPages/CommonHeader';
import Loading from '../../../common/Loading';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from "@mui/material/styles";
import { isViewPermissionValid } from '../../../common/CommonUtils';

const StyledMenu = styled((props) => (
    <Menu
        PaperProps={{
            elevation: 0,
            sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    width: 10,
                    height: 10,
                    right: "50%",
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,

                },
            },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        {...props}
    />
))(({ theme }) => ({}));

const header = ["Currency", "ERU", "USD"];

const MinimumConversionAmount = () => {
    const base_url = useSelector((state) => state.config.api_url);
    const email = localDb.getVal("email");
    const [loading, setLoading] = useState(true);
    const [spreadLevelData, setSpreadLevelData] = useState([]);

    useEffect(() => {
        getAllSpreadLevelSettings();
    }, []);

    const getAllSpreadLevelSettings = async () => {
        setLoading(true);
        await call(
            {
                ...GET_B2C_SPREAD_LEVEL,
                url: base_url + GET_B2C_SPREAD_LEVEL.url,
            },
            { email: email }
        )
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    setSpreadLevelData(res.data);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.message, "err");
                logoutAfterError(err)
            });
    };
    const name = "Minimum Conversion Amount";
    return (
        <div className='fees'>
            <HeaderAndFooterLayout>
                <CommonHeader name={name} />
                <div className="margin_bottom">
                    {spreadLevelData.length > 0 && spreadLevelData.map((ele, i) => {
                        return (
                            <div className='fees_table' key={i}>
                                <MinimumAmountTable heading={ele.name}
                                    id={ele._id}
                                    setLoading={setLoading} />
                            </div>
                        );
                    })}
                </div>
            </HeaderAndFooterLayout>
            {loading && <Loading />}
        </div>
    )
}

export default MinimumConversionAmount;

const MinimumAmountTable = ({ heading, id, setLoading }) => {
    const base_url = useSelector((state) => state.config.api_url);
    const email = localDb.getVal("email");
    const [minimumConversionAmountData, setMinimumConversionAmountData] = useState([]);

    useEffect(() => {
        getAllMinimumConversionAmountSettingWise(id);
    }, []);

    const getAllMinimumConversionAmountSettingWise = async (id) => {
        setLoading(true);
        await call(
            {
                ...GET_MINIMUM_CONVERSION_AMOUNT,
                url: base_url + GET_MINIMUM_CONVERSION_AMOUNT.url,
            },
            { email: email, spreadSetting: id }
        )
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    setMinimumConversionAmountData(res.data);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.message, "err");
                logoutAfterError(err)
            });
    };


    return (
        <div className='table_content'>
            <div className='table_heading mb-3'>{heading}{` %`}</div>
            <div className='table'>
                <div className='table_head mt-0'>
                    {header.map((headerName, i) => {
                        return (<div className="title text-uppercase" key={i} >{headerName}</div>)
                    })}
                </div>
                {minimumConversionAmountData && minimumConversionAmountData.length > 0 && minimumConversionAmountData.map((ele, i) => {
                    return <SingleRowData id={id} data={ele} key={i}
                        getAllMinimumConversionAmountSettingWise={getAllMinimumConversionAmountSettingWise}
                    />
                })}
            </div>
        </div>
    );
};

const SingleRowData = ({ id, data, key, getAllMinimumConversionAmountSettingWise }) => {
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const base_url = useSelector((state) => state.config.api_url);
    const email = localDb.getVal("email");
    const [eurAnchorEl, setEurAnchorEl] = useState(null);
    const openEur = Boolean(eurAnchorEl);
    const handleClick = (event) => {
        setEurAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setEurAnchorEl(null);
    };

    const [usdAnchorEl, setUsdAnchorEl] = useState(null);
    const openUsd = Boolean(usdAnchorEl);
    const handleClickUsd = (event) => {
        setUsdAnchorEl(event.currentTarget);
    };
    const handleCloseUsd = () => {
        setUsdAnchorEl(null);
    };

    const [updateMinimumConversionValue, setUpdateMinimumConversionValue] = useState();
    const [cryptoId, setCryptoId] = useState("");
    const [currency, setCurrency] = useState("");

    const handleOnChange = (e, currency, crypto) => {
        if (e < 0) {
            setUpdateMinimumConversionValue(0);
        } else {
            setUpdateMinimumConversionValue(e);
        }
        setCurrency(currency);
        setCryptoId(crypto);
    }

    const handleOnSumbitSpreadValue = async () => {
        if (updateMinimumConversionValue != undefined && currency !== "" && cryptoId !== "") {
            let payload = {
                eamil: email,
                spreadSetting: id,
                cryptoId: cryptoId,
                currencySymbol: currency,
                currencyValue: updateMinimumConversionValue
            }
            await call(
                {
                    ...CREATE_MINIMUM_CONVERSION_AMOUNT,
                    url: base_url + CREATE_MINIMUM_CONVERSION_AMOUNT.url,
                },
                { payload, email: email }
            )
                .then((res) => {
                    if (res.status === 200) {
                        getAllMinimumConversionAmountSettingWise(id);
                        setUpdateMinimumConversionValue(0);
                        setCryptoId("");
                        setCurrency("");
                        handleCloseUsd();
                        handleClose();
                    }
                })
                .catch((err) => {
                    console.log(err.message, "err");
                    logoutAfterError(err)
                });

        }
    }

    const faitVal = (currency) => {
        if (data.fiat.length === 0) {
            return 0;
        } else {
            let val = data && data.fiat && data.fiat.find(e => e.fiatCurrency === currency);
            if (val) {
                return val.fiatValue;
            } else {
                return 0;
            }
        }
    }

    return (
        <div className='single_Row' key={key}>
            <div className='table_data d-flex align-items-center'>
                <div>
                    <img src={`${image_base_url}${data.crypto.icon}`} width={20} height={20} alt={data.crypto.name} className="" />
                </div>
                <div className="p-1">{data.crypto.name}</div>
            </div>

            <div className='table_data text-decoration-underline cursor-pointer text-center' id="demo-positioned-button"
                aria-controls={openEur ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openEur ? 'true' : undefined}
                onClick={isViewPermissionValid? null: handleClick}> {faitVal("EUR")}
            </div>

            <StyledMenu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={eurAnchorEl}
                open={openEur}
                onClose={handleClose}
            >
                <MenuItem className="p-0 m-0 spread_menuItem">
                    <input type="number" min="0" onChange={(e) => handleOnChange(e.target.value, `EUR`, data.crypto._id)}
                        Value={faitVal("EUR")} />
                    <span className="spreadPopUp_button"><i onClick={() => handleClose()} className="fa fa-times" aria-hidden="true"></i></span>
                    <span className="spreadPopUp_button mr-3" onClick={() => handleOnSumbitSpreadValue()} ><i className="fa fa-check" aria-hidden="true"></i></span>
                </MenuItem>
            </StyledMenu>

            <div className='table_data text-decoration-underline cursor-pointer text-center' id="demo-positioned-button"
                aria-controls={openUsd ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openUsd ? 'true' : undefined}
                onClick={isViewPermissionValid? null: handleClickUsd}> {faitVal("USD")}
            </div>

            <StyledMenu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={usdAnchorEl}
                open={openUsd}
                onClose={handleCloseUsd}
            >
                <MenuItem className="p-0 m-0 spread_menuItem">
                    <input type="number" onChange={(e) => handleOnChange(e.target.value, `USD`, data.crypto._id)}
                        Value={faitVal("USD")} />
                    <span className="spreadPopUp_button" onClick={() => handleCloseUsd()}><i className="fa fa-times" aria-hidden="true"></i></span>
                    <span className="spreadPopUp_button mr-3" onClick={() => handleOnSumbitSpreadValue()} ><i className="fa fa-check" aria-hidden="true"></i></span>
                </MenuItem>
            </StyledMenu>

        </div>
    );
};