import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import { connect, useSelector } from "react-redux";
import { call, logoutAfterError } from "../../../../config/axios";
import localDb from "../../../../localDb";
import Dialog from "@mui/material/Dialog";
import { Modal } from "react-bootstrap";
import Loading from "../../../common/Loading";
import {
  GET_B2C_RISK_LEVEL,
  UPDATE_B2C_RISK_LEVEL,
  DELETE_B2C_RISK_LEVEL,
  CREATE_B2C_RISK_LEVEL,
} from "../../../../config/endpoints";
import { isViewPermissionValid } from "../../../common/CommonUtils";
import DeletingAlert from "../CommonPages/DeletingAlert";
const closeImage = "/cryptonpay/crytoicons/close.png";

const riskLevelTab = [
  {
    name: "Risk Level",
  },
];

const RiskLevelTab = () => {
  const email = localDb.getVal("email");
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const base_url = useSelector((state) => state.config.api_url);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [addSpreadLevelModal, setAddSpreadLevelModal] = useState(false);
  const [addSpreadLevelData, setAddSpreadLevelData] = useState("");
  const [updateSpreadLevel, setUpdateSpreadLevel] = useState(false);
  const [upadteSpreadLevelData, setUpadteSpreadLevelData] = useState(null);
  const [updatedName, setUpdatedName] = useState("");
  const [success, setSuccess] = useState(false);

  const handleCloseAddSpreadLevelModal = () => {
    setAddSpreadLevelModal(false);
    setAddSpreadLevelData("");
    setError("");
    setUpadteSpreadLevelData(null);
    setUpdatedName("");
    setUpdateSpreadLevel(false);
  };

  const getAllSpreadLevels = async () => {
    setLoading(true);
    await call(
      {
        ...GET_B2C_RISK_LEVEL,
        url: base_url + GET_B2C_RISK_LEVEL.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setData(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const deleteSpreadLevel = async () => {
    setLoading(true);
    await call(
      {
        ...DELETE_B2C_RISK_LEVEL,
        url: base_url + DELETE_B2C_RISK_LEVEL.url,
      },
      { email: email, id: deleteId }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          getAllSpreadLevels();
          setSuccess((prev) => !prev);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const addSubmitHandler = async () => {
    const data = addSpreadLevelData.trim();
    if (data.length === 0) {
      setError(`Please Enter Risk Level.`);
    } else {
      setLoading(true);
      await call(
        {
          ...CREATE_B2C_RISK_LEVEL,
          url: base_url + CREATE_B2C_RISK_LEVEL.url,
        },
        { email: email, riskLevel: data }
      )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setAddSpreadLevelData("");
            handleCloseAddSpreadLevelModal();
            setSuccess((prev) => !prev);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err.response.data.msg);
          console.log(err.message, "err");
          logoutAfterError(err)
        });
    }
  };

  const updateSubmitHandler = async () => {
    const data = updatedName.trim();
    if (data.length === 0) {
      setError(`Please Enter Risk Level.`);
    }
    if (data === upadteSpreadLevelData.riskLevel) {
      setError(`Please Update Risk Level.`);
    } else {
      setLoading(true);
      await call(
        {
          ...UPDATE_B2C_RISK_LEVEL,
          url: base_url + UPDATE_B2C_RISK_LEVEL.url,
        },
        { email: email, riskLevel: data, id: upadteSpreadLevelData._id }
      )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setUpadteSpreadLevelData(null);
            setUpdatedName("");
            setUpdateSpreadLevel(false);
            handleCloseAddSpreadLevelModal();
            setSuccess((prev) => !prev);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err.response.data.msg);
          console.log(err.message, "err");
          logoutAfterError(err)
        });
    }
  };

  useEffect(() => {
    getAllSpreadLevels();
  }, [success]);

  const disabled = updateSpreadLevel
    ? error !== "" || updatedName === ""
    : error !== "" || addSpreadLevelData === "";
  return (
    <div className="ScrollBarStyle">
      <Dialog
        open={addSpreadLevelModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleCloseAddSpreadLevelModal}
      >
        <div className="create_account_container">
          <div className="create_account_header">
            <div></div>
            <div className="create_account_heading">
              {updateSpreadLevel ? "Edit Risk Level" : "Add New Risk Level"}
            </div>
            <div
              className="filter_close_button"
              onClick={handleCloseAddSpreadLevelModal}
            >
              <img src={`${image_base_url}${closeImage}`} alt="closeImage" />
            </div>
          </div>

          <div className="create_account_form">
            <div style={{ margin: "20px 0px" }}>
              <div>
                <div className="addModalLabel">
                  {updateSpreadLevel
                    ? `Edit Risk Level`
                    : `Enter Risk Level`}
                </div>
                <input
                  name=""
                  type="number"
                  onChange={(e) => {
                    setError("");
                    if (updateSpreadLevel) {
                      setUpdatedName(e.target.value);
                    } else {
                      setAddSpreadLevelData(e.target.value);
                    }
                  }}
                  value={updateSpreadLevel ? updatedName : addSpreadLevelData}
                />
                {error != "" && (
                  <div className="add_note_error mt-4 font-font-weight-bold">
                    {error}
                  </div>
                )}
                <div className="d-flex justify-content-center pt-4">
                  <button
                    disabled={disabled}
                    onClick={() => {
                      if (updateSpreadLevel) {
                        updateSubmitHandler();
                      } else {
                        addSubmitHandler();
                      }
                    }}
                    type="button"
                    className="btn btn-primary btn-md rounded-pill add-btn w-100"
                    data-e2e={`${updateSpreadLevel ? "update" : "add"}-btn`}
                  >
                    {updateSpreadLevel ? "Update" : "Add New"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Table responsive style={{ border: "none" }}>
        <tbody className="TransactionTable_BODY">
          <tr>
            {riskLevelTab.map((item, index) => (
              <th
                className="GBtableHeading pl-0"
                key={index}
                data-e2e={item.name}
              >
                {item.name}
              </th>
            ))}
          </tr>
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <tr key={index}>
                <td className="table_item merchant_table_font  text-capiltalize text-black">
                  {item.riskLevel}
                </td>

                <td className="table_item merchant_table_font text-black">
                  <EditIcon
                   fontSize="large"
                    color='action'
                    data-e2e="edit_button"
                    className={`cursor-pointer pr-3 ${isViewPermissionValid ? 'disabled' : ''}`}
                    onClick={isViewPermissionValid
                      ? null
                      : () => {
                          setUpdatedName(item.riskLevel);
                          setUpdateSpreadLevel(true);
                          setUpadteSpreadLevelData(item);
                          setAddSpreadLevelModal(true);
                        }
                    }
                  />
                  <span
                className={`cursor-pointer ${isViewPermissionValid ? 'disabled' : ''}`}
                data-e2e="close_icon"
              >
                <CloseIcon
                  onClick={isViewPermissionValid
                    ? null
                    : () => {
                        setDeleteId(item._id);
                      }
                  }
                  fontSize="small"
                />
              </span>
                </td>
              </tr>
            ))
          ) : (
            <div className="NoDataFound">No Data Found</div>
          )}
        </tbody>
      </Table>

      <div className="d-flex justify-content-center mt-3 mb-2">
      <button
        type="button"
        className={`btn btn-primary btn-md rounded-pill pl-5 pr-5 pt-2 pb-2 ${isViewPermissionValid ? 'disabled' : ''}`}
        data-e2e="add-new-btn"
        onClick={isViewPermissionValid
          ? null
          : () => {
              setAddSpreadLevelModal(true);
            }
        }
      >
        {`Add New`}
      </button>
      </div>
      <DeletingAlert
        deletingId={deleteId ? deleteId : null}
        onHide={() => {
          setDeleteId(null);
        }}
        msg="Are you sure you want to delete selected setting? This action cannot be undone."
        onDeleteClickHandler={() => {
          deleteSpreadLevel();
        }}
      />
      {loading && <Loading />}
    </div>
  );
};

export default RiskLevelTab;
