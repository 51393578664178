import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import { GET_MERCHANTS } from "../../../../config/endpoints";
import Loading from '../../../common/Loading';
import { call, logoutAfterError } from '../../../../config/axios';
import { getBalanceSign, getCurrencySymbol, toFixedTrunc } from '../../global/helpers';
import { getWalletStatus, getTransactionStatus, getClientTransactionStatus } from '../Transactions/statusMapping';
import moment from 'moment';
import constant from '../../global/constant';
import { getEmailVal } from '../../../common/CommonUtils';

const downloadAlertIcon = '/cryptonpay/crytoicons/downloadalerticon.png';

function DownloadCsv(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);

  const getTitleAndFilename = (name) => {
  switch (name) {
    case 'merchants':
      return 'Merchants';
    case 'wallets':
      return 'Wallets';
    case 'singleClient':
    case 'allClients':
      return 'Client Balances';
    default:
      return 'Transactions';
  }
};

const options = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: true,
  title: getTitleAndFilename(props.name) + ':',
  filename: getTitleAndFilename(props.name),
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  headers: ['Column 1', 'Column 2', 'col 3', 'col 4', 'col 5'],
};

  const csvExporter = new ExportToCsv(options);

  const getAllMerchants = async () => {

    let payload = {
      email: props.email,
      filterParams: { paymentProvider: localStorage.getItem("paymentProviderId") },
    };

    setLoading(true);
    await call(
      {
        ...GET_MERCHANTS,
        url: props.base_url + GET_MERCHANTS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setData(res && res.data && res.data.merchants && res.data.merchants);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };

  useEffect(() => {
    if(props.name === 'merchants') {
      getAllMerchants();
    }
  }, []);

  const transformMerchantData = (data) => {
    return data.map((tr) => {
      return {
        Merchant: (tr.name && tr.name) ||(tr.merchant && tr.merchant.name)  || 'N/A',
        merchantId: (tr.merchantId && tr.merchantId) || 'N/A',
        creationDate:
          (tr.creationDate && tr.creationDate) || 'N/A',
        status: (tr.activitation && tr.activitation) || 'N/A',
        MerchantContactInformation:
          (tr.contacts &&
            tr.contacts.length > 0 &&
            tr.contacts[0].email) ||
          'N/A',
      };
    });
  };

  const transformWalletData = (data) => {
    let walletCsvDataArr = [];
    data.forEach((tr) => {
      if(tr.balance && tr.balance.length > 0){
        const walletBalanceDataArr = tr.balance.map((balanceObj) => {
          return getWalletCsvRowData({...tr,cryptoId: balanceObj.cryptoId, balanceAmount: balanceObj.balanceAmount });
        });
        walletCsvDataArr = [...walletCsvDataArr, ...walletBalanceDataArr];
      } else {
        walletCsvDataArr.push(getWalletCsvRowData({...tr, cryptoId: getDefaultTokenAsPerBlockchain(tr.blockchain) }));
      }
    });
    return walletCsvDataArr;
  };

  const getWalletCsvRowData = (walletData) => {
    return  {
      Blockchain: (walletData.blockchain && walletData.blockchain) || 'N/A',
      Crypto: (walletData.blockchain && walletData.blockchain) || 'N/A',
      Network: (walletData.network && walletData.network) || 'N/A',
      WalletAddress: (walletData.address && walletData.address.key) || 'N/A',
      SeedIndex:
        (walletData.address && walletData.address.seedIndex) || 'N/A',
      LastUsed: (walletData.status && walletData.status.updated) || 'N/A',
      Token : walletData.cryptoId || "N/A",
      Balance: walletData.balanceAmount || 0,
      Status:
        (walletData.status && (getWalletStatus(walletData.status.code)).toUpperCase()) ||
        'N/A',
    }
  }

  const getDefaultTokenAsPerBlockchain=(blockchain)=>{
    switch(blockchain){
      case constant.BLOCKCHAIN_NAMES.LITECOIN_BLOCKCHAIN:
        return constant.DEFAULT_TOKENS.LTC;
      case constant.BLOCKCHAIN_NAMES.ETHEREUM_BLOCKCHAIN:
        return constant.DEFAULT_TOKENS.ETH;
      case constant.BLOCKCHAIN_NAMES.BITCOIN_BLOCKCHAIN:
        return constant.DEFAULT_TOKENS.BTC;
      case constant.BLOCKCHAIN_NAMES.RIPPLE_BLOCKCHAIN:
        return constant.DEFAULT_TOKENS.XRP;
      case constant.BLOCKCHAIN_NAMES.TRON_BLOCKCHAIN:
        return constant.DEFAULT_TOKENS.TRX;
      default:
        return "N/A"
    }
  }

  const transformTransactionData = (data, isMerchant=false) => {
    return data.map((tr) => {
      let tr_fee = (tr && tr.transactionFee) || 0;
      let fiat_sent = (tr && tr.depositAmount) || 0;
      const settlement = fiat_sent - tr_fee;

      // The following block is specific to transactions and not merchant transactions
      let result = null;
      if (!isMerchant && tr && tr.status && tr.status.history) {
        const FTX = tr.status.history.filter((ele) => ele.code === 'DepositMonitor:SettlementTradeExecuted');
        if (FTX.length > 0 && FTX[0].message) {
          result = JSON.parse(FTX[0].message);
        }
      }
      const merchantName = (tr?.merchant?.name) || 'N/A'
      let paymentProviderName = 'N/A';
      if (tr?.merchant?.paymentProvider && tr.merchant.paymentProvider.length > 0) {
        paymentProviderName = tr.merchant.paymentProvider[0].firstName + ' ' + tr.merchant.paymentProvider[0].lastName;
      }
      let baseData = {
        'Payment Provider Name': paymentProviderName,
        'Merchant Name': merchantName,
        Network: (tr && tr.network) || 'N/A',
        "Acquirer Name": (tr && tr.acquirer) || 'N/A',
        Status: (tr && tr.status && tr.status.code && (getTransactionStatus(tr.status.code).toUpperCase())) || 'N/A',
        'TX Type': (tr && tr.type) || 'N/A',
        'Merchant Id': (tr && tr.merchantId) || 'N/A',
        'Merchant User Id': (tr && tr.merchantUserId) || 'N/A',
        "Merchant Transaction Id": (tr && tr.merchantTransactionId) || 'N/A',
        'Transaction Id': (tr && tr.transactionId) || 'N/A',
        'Crypto Currency': (tr && tr.selected && tr.selected.cryptoId) || 'N/A',
        'Crypto Amount': (tr && tr.selected && (tr.selected.actualAmountSent || tr.selected.amount)) || 'N/A',
        'Fiat Currency': (tr && tr.depositFiatCurrency) || 'N/A',
        'Fiat Amount': (tr && tr.depositAmount) || 'N/A',
        'Fiat Amount Sent': (tr && tr.depositAmount) || 'N/A',
        'Spread %': (tr && tr.type && tr.merchant) ? (tr.type.toLowerCase() === 'withdrawal' ? tr.merchant.withdrawalSpread : tr.merchant.depositSpread) : 'N/A',
        'Gas Fee': (tr && tr.gasFee) || 'N/A',
        'Merchant Settlement': settlement || 'N/A',
        'Created Date': (tr && tr.createdDate) || 'N/A',
        'Last Updated Date': (tr && tr.status && tr.status.updated) || 'N/A',
        'Send Fee': (tr && tr.sendFee) || 'N/A',
        'Merchant Settlement Currency': (tr && tr.merchantSettlementCurrency) || 'N/A',
        'Merchant Wallet Balance Adjustment': (tr && tr.merchantWalletBalanceAdjustment) || 'N/A',
        'Fx Rate': (tr && tr.fxRate) || 'N/A',
        'Revised Fx Rate': (tr && tr.revisedFxRate) || 'N/A',
        'Revised Fx Rate Amount': (tr && tr.revisedFxRateAmount) || 'N/A',
        'To Address': ((tr?.withdrawalWalletAddress) ||
          (tr?.destinationWallet?.address) ||
          (tr?.depositWallet?.address)) ||
          "N/A",
      'User Billing Country': (tr && tr.userDetails && tr.userDetails.billingCountry) || 'N/A',
      "fixedFee": (tr && tr.fixedFee) || 'N/A',
      "percentageFee": (tr && tr.percentageFee) || 'N/A',
      "orderId": (tr && tr.orderId) || 'N/A',
      "transId": (tr && tr.transId) || 'N/A',
      "Account Holder": (tr?.userDetails?.firstName + ' ' + tr?.userDetails?.lastName) || 'N/A',
      "User Email": (tr?.merchantUserEmailAddress) || 'N/A',
      "IP country": (tr?.billingCountry) || 'N/A',
      'User Country': (tr?.userDetails?.billingCountry) || 'N/A',
      "Masked PAN": (tr?.maskedPan) || 'N/A',
      'Card issuer country': (tr?.binCountry, tr?.billingCountry) || "N/A",
      'Card Scheme': tr?.cardScheme || "N/A",
      'Traffic Type': tr?.trafficType || "N/A",
      "Acquirer TransactionId" : tr?.acquirerTransactionReference || "N/A"
      };
      const reserveReleaseDate = (tr && tr.reserve && tr.reserve.final.dueDate) || 'N/A';
      const reserveValue = (tr && tr.reserve && tr.reserve.final.amount) || 'N/A';
      const settlementReleaseDate = (tr && tr.reserve && tr.reserve.settlement.dueDate) || 'N/A';;
      let fiatValueToUSDT = 'N/A';
      let transactionFeeToUSDT = 'N/A';

      if (tr && tr.depositAmount) {
        const exchangeRateEntry = props.data.find((exchangeRateObj) => (
          exchangeRateObj.quoteCurrency === tr.depositFiatCurrency
          && exchangeRateObj.baseCurrency === 'USDT'
        ))
        const exchangeRate = exchangeRateEntry ? exchangeRateEntry.price : 0
        fiatValueToUSDT = tr.depositAmount / exchangeRate;
      }

      if (tr && tr.transactionFee && tr.selected && tr.selected.cryptoId) {
        const exchangeRateEntry = props.data.find((exchangeRateObj) => (
          exchangeRateObj.baseCurrency === tr.selected.cryptoId && exchangeRateObj.quoteCurrency === 'USD'
        ));
        const exchangeCryptoToUSDRate = exchangeRateEntry ? exchangeRateEntry.price : 0

        const cryptFeesToUSD = exchangeCryptoToUSDRate * tr.transactionFee;
        const exchangeUsdToUSDTRateEntry = props.data.find((ele) => (
          ele.quoteCurrency === "USD"
          && ele.baseCurrency === 'USDT'
        ))
        const exchangeUsdToUSDTRate = exchangeUsdToUSDTRateEntry ? exchangeUsdToUSDTRateEntry.price : 0
        transactionFeeToUSDT = tr.transactionFee / exchangeUsdToUSDTRate;
      }

      if (!isMerchant) {
        baseData['FTX UID'] = (result && result.result && result.result.id) || 'N/A';
        baseData['FTX Size'] = (result && result.result && result.result.size) || 'N/A';
        baseData['FTX Rate'] = (result && result.result && result.result.price) || 'N/A';
        baseData['Rate Offered'] = (tr && tr.selected && tr.selected.exchangeRate) || 'N/A';
        baseData['Transaction Fee'] = (tr && tr.transactionFee) || 'N/A';
        baseData['Actual Crypto Amount Sent'] = (tr && tr.selected && tr.selected.actualAmountDeposited) || 'N/A';
        baseData['Fixed Fee Amount'] = (tr && tr.merchant && tr.merchant.depositByCardFixedFee) || 'N/A';
        baseData['Card Fee %'] = (tr && tr.merchant && tr.merchant.depositByCardFee) || 'N/A';
        baseData['Card Reserve %'] = (tr && tr.merchant && tr.merchant.cardReservePercent) || 'N/A';
        baseData['Card Reserve Period'] = (tr && tr.merchant && tr.merchant.cardReservePeriod) || 'N/A';
        baseData['Card Reserve Release Date'] = reserveReleaseDate;
        baseData['Card Reserve Value'] = reserveValue;
        baseData['Deposit amount in USDT'] = fiatValueToUSDT;
        baseData['Transaction Fee in USDT'] = transactionFeeToUSDT;
        baseData['Settlement Release Date'] = settlementReleaseDate;
        const hashValue = (tr?.depositHash || tr?.transactionHash )|| 'N/A';
        const isDeposit = tr && tr.type && tr.type && ["deposit", "Admin-Deposit"].includes(tr.type);
        if (isDeposit) {
          baseData['Deposit Hash'] = hashValue 
          baseData['Transaction Hash'] = "N/A"
        } else {
          baseData['Deposit Hash'] =  "N/A"
          baseData['Transaction Hash'] = hashValue
        }
      }
      if (tr && tr.errorDetails) {
        baseData['Error Group'] = tr.errorDetails.group || 'N/A';
        baseData['Error Type'] = tr.errorDetails.type || 'N/A';
        baseData['Error Detail'] = tr.errorDetails.details || 'N/A';
      } else {
        baseData['Error Group'] = 'N/A';
        baseData['Error Type'] = 'N/A';
        baseData['Error Detail'] = 'N/A';
      }
      if (tr?.autoTrade) {
        baseData['Trade Order Id'] = tr?.autoTrade?.tradeOrderId || 'N/A';
        baseData['Trade Type'] = tr?.autoTrade?.tradeType || 'N/A';
        baseData['SendOrReceive Currency'] = tr?.autoTrade?.sendOrReceiveCurrency || 'N/A';
        baseData['SendOrReceive Quantity'] = tr?.autoTrade?.sendOrReceiveQuantity || 0;
      } else {
        baseData['Trade Order Id'] = 'N/A';
        baseData['Trade Type'] = 'N/A';
        baseData['SendOrReceive Currency'] = 'N/A';
        baseData['SendOrReceive Quantity'] = 0;
      } 
      return baseData;
    });
  };

  const transformClientTransactionData = (data) => {
    function getAmount(data) {
      if (data.type !== "FX") {
        return `${getBalanceSign(data)} ${data.transactionDetails[0].currency && getCurrencySymbol(data.transactionDetails[0].currency)} ${toFixedTrunc(data.transactionDetails[0].amount)}`
      } else {
        return `${data.transactionDetails && data.transactionDetails[0].fromCurrency} - ${toFixedTrunc(data.transactionDetails[0].fromAmount)} , ${data.transactionDetails && data.transactionDetails[0].toCurrency} + ${toFixedTrunc(data.transactionDetails[0].toAmount)}`
      } 
    }

    return data.map((tr) => {
      const paymentProvider = tr.paymentProvider
        ? `${tr.paymentProvider.firstName}` + ` ${tr.paymentProvider.lastName}`
        : 'N/A';
      return {
        'Client Name': tr.client[0].name || 'N/A',
        'Type': tr.type === "Transfer" ? `${tr.type} (${tr.transferInOut})` : tr.type,
        'Transaction Id': tr.transactionId || 'N/A',
        'Transaction Fee': tr.transactionFee ? tr.type !== "FX" ? tr.transactionFee : `${tr.transactionDetails && tr.transactionDetails[0].fromCurrency} - ${tr.transactionFee}` : 0,
        "BeneficiaryName": tr.transactionDetails[0].beneficiaryName || 'N/A',
        "BeneficiaryAddress": tr.transactionDetails[0].beneficiaryAddress || 'N/A',
        "BankName": tr.transactionDetails[0].bankName || 'N/A',
        "BankAddress": tr.transactionDetails[0].bankAddress || 'N/A',
        "AccountNumber": tr.transactionDetails[0].beneficiaryAccountNumber || 'N/A',
        "IBAN": tr.transactionDetails[0].iban || 'N/A',
        "AdditionalBankDetails": tr.transactionDetails[0].additionalBankDetails || 'N/A',
        "Swift": tr.transactionDetails[0].swift || 'N/A',
        "SortCode": tr.transactionDetails[0].sortCode || 'N/A',
        "Reference": tr.transactionDetails[0].reference || 'N/A',
        "Currency": (tr.transactionDetails[0].currency || tr.transactionDetails[0].cryptoId) || 'N/A',
        'Amount': getAmount(tr),
        'Payment Provider': paymentProvider,
        'Status': (tr.status &&
          tr.status.code &&
          (getClientTransactionStatus(tr.status.code).toUpperCase())) ||
          'N/A',
        'Client Email': tr.client[0].clientEmail || 'N/A',
        'Client Id': tr.client[0].clientId || 'N/A',
        'Last Updated': tr.status.updated || 'N/A',
        'Created Date': tr.createdDate || 'N/A',
      }
    });
  }

  const transformUsersData = (data) => {
    return data.map((user) => {
      const emailVal = getEmailVal(user?.email)
      return {
        'fullName': `${user?.firstName} ${user?.lastName}` || 'N/A',
        'Email': emailVal || 'N/A',
        'Payment Provider': `${user?.paymentProvider?.firstName} ${user?.paymentProvider?.lastName}` || 'N/A',
        'Phone': user.phone || 'N/A',
        'User Status': (user.emailVerified
          && (user.emailVerified ? 'Active' : 'Onboarding'))
          || 'N/A',
        'Risk Level': user.riskLevel || 1,
        'Last Transaction': (user.lastTransactionId && `${user.lastTransactionId}`) || 'N/A',
        'Last Login': user.lastLogin || 'N/A',
      }
    });
  }

  const transformClientsData = (data) => {
    return data.map((tr) => {
      if (tr.clientBalance) {
        const balanceAmount = tr.clientBalance.balance ? parseFloat(tr.clientBalance.balance.balanceAmount.toFixed(6)) : 0
        return {
          "Payment Provider": tr.paymentProvider && `${tr.paymentProvider.firstName} ${tr.paymentProvider.lastName}` || 'N/A',
          "ClientId": tr.clientId || 'N/A',
          'Client Name': tr.name || 'N/A',
          "Account Number": tr.accountNumber || 'N/A',
          "Asset": tr.clientBalance.currency || 'N/A',
          "Balance": toFixedTrunc(balanceAmount),
          "Last Updated": tr.clientBalance.balance !== null ? moment(tr.clientBalance.balance.updated).format("DD-MM-YYYY") : 'N/A'
        }
      }
    }).filter(Boolean)
  }

  const transformSingleClientsData = (obj) => {
    const data = obj[0]
    return data && data.clientBalances && data.clientBalances.map((tr) => {
      return {
        "Payment Provider": data.paymentProvider && `${data.paymentProvider.firstName} ${data.paymentProvider.lastName}` || 'N/A',
        "ClientId": data.clientId || 'N/A',
        'Client Name': data.name || 'N/A',
        "Account Number": data.accountNumber || 'N/A',
        "Asset": tr.currency || 'N/A',
        "Balance": tr.balance && toFixedTrunc(tr.balance.balanceAmount) || 0,
        "Last Updated": tr.balance !== null ? moment(tr.balance.updated).format("DD-MM-YYYY") : 'N/A'
      }
    })
  }

  const generateCsvData = (name, data) => {
    let transactionList = [];
    switch (name) {
      case 'merchants':
        transactionList = transformMerchantData(data);
        break;
      case 'wallets':
        transactionList = transformWalletData(data);
        break;
      case 'transactions':
        transactionList = transformTransactionData(data, false);
        break;
      case 'merchantTransactions':
        transactionList = transformTransactionData(data, true);
        break;
      case 'users':
        transactionList = transformUsersData(data);
        break;
      case 'allClients':
        transactionList = transformClientsData(data);
        break;
      case 'singleClient':
        transactionList = transformSingleClientsData(data);
        break;
      default:
        transactionList = transformClientTransactionData(data);
    }
    return transactionList;
  };

  
  return (
    <div className="ClearAlertModal">
      <div className="ClearAlertModalBody">
        <div
          className="ClearAlertModalIconWrap"
          data-e2e="ClearAlertModalIconWrap"
        >
          <img
            src={`${image_base_url}${downloadAlertIcon}`}
            alt="downloadAlertIcon"
            className="ClearAlertModalIcon"
            data-e2e="ClearAlertModalIcon"
          />
        </div>
        <div className="ClearAlertModalTitle">Download in CSV</div>
        <div className="ClearAlertModalDescription">
          Are you sure you want to download CSV file with all the information?{' '}
        </div>
        <div
          className="ClearAlertModalClearBtn"
          data-e2e="ClearAlertModalClearBtn"
          onClick={() => {
            props.handleCloseDownloadCsv();
            if (props.name === 'merchants' ? data && data.length > 0 : props.data && props.data.length > 0) {
              const transactionList = props.name === 'merchants'
                ? generateCsvData(props.name, data)
                : generateCsvData(props.name, props.data);
              csvExporter.generateCsv(transactionList);
            }
          }}
        >
          Download
        </div>
        <div
          className="ClearAlertModalGoBackBtn"
          onClick={() => {
            props.handleCloseDownloadCsv();
          }}
        >
          Go Back
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default DownloadCsv;
