import React, { useState } from "react";
import IOSSwitch from "../IOSSwitch";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";

const LoadBalance = ({ acquireService, handletoggleLoadBalancing }) => {
  const [maxTrafficFlag, setMaxTrafficFlag] = useState(
    acquireService.loadBalancing.maxTrafficFlag || false,
  );
  const [successRoutingFlag, setSuccessRoutingFlag] = useState(
    acquireService.loadBalancing.successRoutingFlag || false,
  );
  const [leaseCostRoutingFlag, setLeaseCostRoutingFlag] = useState(
    acquireService.loadBalancing.leaseCostRoutingFlag || false,
  );
  const [weightingFlag, setWeightingFlag] = useState(
    acquireService.loadBalancing.weightingFlag || false,
  );
  const [failoverFlag, setFailoverFlag] = useState(
    acquireService.loadBalancing.failoverFlag || false,
  );

  const onClickMaxTraffic = (e) => {
    const newMaxTrafficFlag = e.target.checked;
    setMaxTrafficFlag(newMaxTrafficFlag);
    const payload = {
      maxTrafficFlag: newMaxTrafficFlag,
      codeid: e._id,
      uniqueId: acquireService.uniqueId,
      successRoutingFlag: successRoutingFlag,
      leaseCostRoutingFlag: leaseCostRoutingFlag,
      weightingFlag: weightingFlag,
      failoverFlag: failoverFlag,
    };
    handletoggleLoadBalancing(payload);
  };

  const onClickSuccessRouting = (e) => {
    const newSuccessRoutingFlag = e.target.checked;
    setSuccessRoutingFlag(newSuccessRoutingFlag);
    const payload = {
      successRoutingFlag: newSuccessRoutingFlag,
      codeid: e._id,
      uniqueId: acquireService.uniqueId,
      maxTrafficFlag: maxTrafficFlag,
      leaseCostRoutingFlag: leaseCostRoutingFlag,
      weightingFlag: weightingFlag,
      failoverFlag: failoverFlag,
    };
    handletoggleLoadBalancing(payload);
  };

  const onClickLeaseCostRoutingFlag = (e) => {
    const newLeaseCostRoutingFlag = e.target.checked;
    setLeaseCostRoutingFlag(newLeaseCostRoutingFlag);
    const payload = {
      leaseCostRoutingFlag: newLeaseCostRoutingFlag,
      codeid: e._id,
      uniqueId: acquireService.uniqueId,
      maxTrafficFlag: maxTrafficFlag,
      weightingFlag: weightingFlag,
      failoverFlag: failoverFlag,
      successRoutingFlag: successRoutingFlag,
    };
    handletoggleLoadBalancing(payload);
  };

  const onClickWeightingFlag = (e) => {
    const newWeightingFlag = e.target.checked;
    setWeightingFlag(newWeightingFlag);
    const payload = {
      weightingFlag: newWeightingFlag,
      leaseCostRoutingFlag: leaseCostRoutingFlag,
      codeid: e._id,
      uniqueId: acquireService.uniqueId,
      maxTrafficFlag: maxTrafficFlag,
      failoverFlag: failoverFlag,
      successRoutingFlag: successRoutingFlag,
    };
    handletoggleLoadBalancing(payload);
  };

  const onClickFailoverFlag = (e) => {
    const newFailoverFlag = e.target.checked;
    setFailoverFlag(newFailoverFlag);
    const payload = {
      failoverFlag: newFailoverFlag,
      weightingFlag: weightingFlag,
      leaseCostRoutingFlag: leaseCostRoutingFlag,
      codeid: e._id,
      uniqueId: acquireService.uniqueId,
      maxTrafficFlag: maxTrafficFlag,
      successRoutingFlag: successRoutingFlag,
    };
    handletoggleLoadBalancing(payload);
  };

  return (
    <div className="TabContainer">
      <div className="h-full bg-[#F9FAFB]">
        <h2 className="heading">Load Balancing</h2>
        <div className="ml-2 flex w-1/3 flex-col">
          <div className="mx-2 my-2 flex border-b py-2">
            <div className="flex-1 pr-4">Maximum Traffic</div>
            <IOSSwitch
            data-e2e="maximum-traffic"
              defaultChecked={maxTrafficFlag}
              onClick={(e) => isViewPermissionValid? null: onClickMaxTraffic(e)}
              className={`${isViewPermissionValid? "disabled": ""}`}
            />
          </div>
          <div className="mx-2 my-2 flex border-b py-2">
            <div className="flex-1 pr-4">Success Routing</div>
            <IOSSwitch
            data-e2e="success-routing"
              defaultChecked={successRoutingFlag}
              onClick={(e) => isViewPermissionValid? null: onClickSuccessRouting(e)}
              className={`${isViewPermissionValid? "disabled": ""}`}
            />
          </div>
          <div className="mx-2 my-2 flex border-b py-2">
            <div className="flex-1 pr-4">Least-Cost Routing</div>
            <IOSSwitch
            data-e2e="least-cost-routing"
              defaultChecked={leaseCostRoutingFlag}
              onClick={(e) => isViewPermissionValid? null: onClickLeaseCostRoutingFlag(e)}
              className={`${isViewPermissionValid? "disabled": ""}`}
            />
          </div>
          <div className="mx-2 my-2 flex border-b py-2">
            <div className="flex-1 pr-4">Weighting</div>
            <IOSSwitch
            data-e2e="weight"
              defaultChecked={weightingFlag}
              onClick={(e) => isViewPermissionValid? null: onClickWeightingFlag(e)}
              className={`${isViewPermissionValid? "disabled": ""}`}
            />
          </div>
          <div className="mx-2 my-2 flex border-b py-2">
            <div className="flex-1 pr-4">Failover</div>
            <IOSSwitch
            data-e2e="fail-over"
              defaultChecked={failoverFlag}
              onClick={(e) => isViewPermissionValid? null: onClickFailoverFlag(e)}
              className={`${isViewPermissionValid? "disabled": ""}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadBalance;
