import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import HeaderAndFooterLayout from "../../../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../../../CommonPages/CommonHeader";
import localDb from "../../../../../../localDb";
import {
  GET_INTEGRATION_TYPE,
  GET_ACQUIRER_DATA,
} from "../../../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../../../config/axios";
const integrationIcon = "/cryptonpay/crytoicons/integration.png";
const acquirersIcon = "/cryptonpay/crytoicons/acquirers.png";
const rulesIcon = "/cryptonpay/crytoicons/rules.png";
const splitTrafficIcon = "/cryptonpay/crytoicons/status.png";
const trafficIcon = "/cryptonpay/crytoicons/traffic.png";
const loadIcon = "/cryptonpay/crytoicons/load.png";
const routingIcon = "/cryptonpay/crytoicons/routing.png";
const statusIcon = "/cryptonpay/crytoicons/status.png";

import IntegrationType from "./IntegrationType";
import Sidebar from "./Sidebar";
import AcquirerTab from "./AcquirerTab";
import RulesTab from "./RulesTab";
import SplitTrafficTab from "./SplitTrafficTab";
import MaxTrafficTab from "./MaxTrafficTab";
import StatusTab from "./StatusTab";
import SuccessRoutingTab from "./SuccessRoutingTab";
import LeastCostRoutingTab from "./LeastCostRoutingTab";
import WeightingTab from "./WeightingTab";
import Loading from "../../../../../common/Loading";

const AcquirerConfig = (props) => {
  const [integrationType, setIntegrationType] = useState();
  const [cardAquirer, setCardAquirer] = useState();
  const baseURL = useSelector((state) => state.config.api_url);
  const imageBaseURL = useSelector((state) => state?.config?.image_base_url);
  const [value, setValue] = useState(1);
  const [loading, setLoading] = useState(false);
  const [acquirerServiceData, setAcquirerServiceData] = useState([]);
  const [reload, setReload] = useState(false);

  const tabNames = [
    {
      name: "Integration",
      image: imageBaseURL + integrationIcon,
      value: 1,
    },
    {
      name: "Acquirer",
      image: imageBaseURL + acquirersIcon,
      value: 2,
    },
    {
      name: "Rules",
      image: imageBaseURL + rulesIcon,
      value: 3,
    },
    {
      name: "Split Traffic",
      image: imageBaseURL + splitTrafficIcon,
      value: 4,
    },
  ];
  const tabWithSubTabsNames = [
    {
      name: "Balance",
      tabValue: 1,
      image: imageBaseURL + loadIcon,
      subTabs: [
        {
          name: "Maximum Traffic",
          image: imageBaseURL + trafficIcon,
          value: 6,
        },
        {
          name: "Status",
          image: imageBaseURL + statusIcon,
          value: 7,
        },
        {
          name: "Success Routing",
          image: imageBaseURL + routingIcon,
          value: 8,
        },
        {
          name: "LeastCost Routing",
          image: imageBaseURL + routingIcon,
          value: 9,
        },
        {
          name: "Weighting",
          image: imageBaseURL + routingIcon,
          value: 10,
        },
      ],
    },
  ];
  const handletoggleActiveCountryCode = async (payload) => {
    // Need API call
  };
  const onSelectTab = (val) => {
    setValue(val);
  };
  const getIntegrationNames = async () => {
    try {
      const res = await call(
        {
          ...GET_INTEGRATION_TYPE,
          url: baseURL + GET_INTEGRATION_TYPE.url,
        },
        {
          email: localDb.getVal("email"),
        },
      );
      if (res?.status === 200) {
        setIntegrationType(res?.data);
      }
    } catch (err) {
      console.error(err, " in getAcquirerNames");
    }
  };
  useEffect(() => {
    getIntegrationNames();
  }, []);
  //Get all Acquirers
  const getAllAcquirers = async () => {
    setLoading(true);
    try {
      const res = await call(
        {
          ...GET_ACQUIRER_DATA,
          url: baseURL + GET_ACQUIRER_DATA.url,
        },
        {
          email: localDb.getVal("email"),
        },
      );

      if (res?.status === 200) {
        
        setAcquirerServiceData(res?.data);
        setLoading(false);
      }
    } catch (error) {
      logoutAfterError(error);
      setLoading(false);
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    getAllAcquirers();
  }, [reload]);
  return (
    <HeaderAndFooterLayout>
      <CommonHeader name="Global Acquirer Configuration" />
      <div className="cardAcquirerRouting container">
        <div className="flex flex-col md:flex-row">
          <div className="mb-4 mt-12 md:mb-0 md:mt-0">
          {loading && <Loading />}
            <Sidebar
              tabNames={tabNames}
              tabWithSubTabsNames={tabWithSubTabsNames}
              onSelectTab={onSelectTab}
            />
          </div>
          {value === 1 ? (
            <IntegrationType
              integrationType={integrationType}
              getIntegrationNames={getIntegrationNames}
            />
          ) : null}
          {value === 2 ? (
            <AcquirerTab integrationTypeData={integrationType} setReload={setReload} reload={reload}/>
          ) : null}
          {value === 3 ? <RulesTab acquirerServiceData={acquirerServiceData} setReload={setReload} reload={reload} /> : null}
          {value === 4 ? (
            <SplitTrafficTab acquirerServiceData={acquirerServiceData} />
          ) : null}
          {value === 6 ? (
            <MaxTrafficTab
            acquirerServiceData={acquirerServiceData}
              handletoggleActiveCountryCode={handletoggleActiveCountryCode}
              setReload={setReload} reload={reload}
            />
          ) : null}
          {value === 7 ? <StatusTab acquirerServiceData={acquirerServiceData} /> : null}
          {value === 8 ? (
            <SuccessRoutingTab acquirerServiceData={acquirerServiceData} />
          ) : null}
          {value === 9 ? (
            <LeastCostRoutingTab
            acquirerServiceData={acquirerServiceData}
            />
          ) : null}
          {value === 10 ? (
            <WeightingTab
            acquirerServiceData={acquirerServiceData}
            />
          ) : null}
        </div>
      </div>
    </HeaderAndFooterLayout>
  );
};

export default AcquirerConfig;
