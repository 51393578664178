import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import AddOrEditAcquirer from "./AddOrEditAcquirer";
import DeleteIntegration from "./DeleteIntegration";
import { call, logoutAfterError } from "../../../../../../config/axios";
import { GET_ACQUIRER_DATA } from "../../../../../../config/endpoints";
import localDb from "../../../../../../localDb";
import Loading from "../../../../../common/Loading";

const AcquirerTab = ({ acquireService, integrationTypeData,setReload, reload }) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const baseUrl = useSelector((state) => state?.config?.api_url);
  const [loading, setLoading] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [editData, setEditData] = useState([]);
  const [editOpted, setEditOpted] = useState(false);
  const [openAddOrEditIcon, setOpenAddOrEditIcon] = useState(false);
  const [openDeleteIcon, setOpenDeleteIcon] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [currentAcquirer, setCurrentAcquirer] = useState(null);
  const [deletingId, setDeletingId] = useState("");
  const [acquirerServiceData, setAcquirerServiceData] = useState([]);
  const handleCloseAddNew = () => {
    setCurrentAcquirer(null);
    setAddNew(false);
    setEditOpted(false);
  };
  const handleOpenAddNew = () => {
    setAddNew(true);
    setEditOpted(false);
    setOpenAddOrEditIcon(true);
  };
  const openEditDialog = (item) => {
    setCurrentAcquirer(item);
    setEditOpted(true);
    setAddNew(true);
    setOpenAddOrEditIcon(true);
  };

  const openDeleteDialog = (item) => {
    setDeletingId(item?.acquirerUniqueId);
    setOpenDeleteIcon(true);
    setShowModel(true);
  };
  const handleCloseDeleteDialog = () => {
    setShowModel(false);
    setDeletingId("");
    setOpenDeleteIcon(false);
  };
  //Get all Acquirers
  const getAllAcquirers = async () => {
    setLoading(true);
    try {
      const res = await call(
        {
          ...GET_ACQUIRER_DATA,
          url: baseUrl + GET_ACQUIRER_DATA.url,
        },
        {
          email: localDb.getVal("email"),
        },
      );

      if (res?.status === 200) {
        setAcquirerServiceData(res?.data);
        setLoading(false);
      }
    } catch (error) {
      logoutAfterError(error);
      setLoading(false);
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    getAllAcquirers();
  }, [reload]);
  return (
    <div className="TabContainer">
      <div className="h-full bg-[#F9FAFB]">
        <h2 className="heading">Global Acquirers</h2>
        <div>
          {acquirerServiceData?.map((item, i) => {
            return (
              <div key={i} className=" ml-[10px] border-b">
                <div className="flex w-full max-w-[600px] px-2  py-4  ">
                  <div className="flex-1 ">
                    {item?.acquirerSetting?.acquirerName}
                  </div>
                  <div className="flex min-w-[100px] max-w-[200px] items-center justify-between px-1 py-2">
                    <EditIcon
                      className={`text-blue-500 ${isViewPermissionValid ? "disabled" : ""}`}
                      fontSize="small"
                      onClick={() =>
                        isViewPermissionValid ? null : openEditDialog(item)
                      }
                    />

                    <DeleteIcon
                      className={`text-danger ${isViewPermissionValid ? "disabled" : ""}`}
                      onClick={() =>
                        isViewPermissionValid ? null : openDeleteDialog(item)
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <button
            onClick={() => (isViewPermissionValid ? null : handleOpenAddNew())}
            className={`btnAddService ${isViewPermissionValid ? "disabled" : ""}`}
          >
            Add Acquirer
          </button>
        </div>
      </div>
      {loading && <Loading />}
      {/* edit and add acquirer */}
      {openAddOrEditIcon && (
        <Dialog
          open={addNew}
          onClose={() => {
            handleCloseAddNew();
            setEditData([]);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AddOrEditAcquirer
            handleCloseAddNew={() => handleCloseAddNew()}
            editOpted={editOpted}
            currentAcquirer={currentAcquirer}
            setAddNew={setAddNew}
            setOpenAddOrEditIcon={setOpenAddOrEditIcon}
            integrationTypeData={integrationTypeData}
            setReload={setReload}
            reload={reload}
          />
        </Dialog>
      )}

      {/* delete acquirer */}
      {openDeleteIcon && (
        <DeleteIntegration
          imageBaseURL={image_base_url}
          deletingId={deletingId}
          msg=""
          onHide={() => handleCloseDeleteDialog()}
          acquirerId={deletingId}
          setReload={setReload}
          reload={reload}
        />
      )}
    </div>
  );
};

export default AcquirerTab;
