import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Modal,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const isValidJSON = (input) => {
  try {
    JSON.parse(input);
    return true;
  } catch (err) {
    return false;
  }
};

const parseJSON = (str, errorType) => {
  try {
    if (errorType === "DepositFlow:3dsFailed") {
      return str ? JSON.parse(str?.replace("value=", "")) : null;
    }
    return isValidJSON(str) ? JSON.parse(str) : str;
  } catch (err) {
    console.error("Description is not a valid JSON string.", err);
    return null;
  }
};

const formatCurrency = (amount) => {
  return `$${parseFloat(amount?.toFixed(0))?.toLocaleString()}`;
};

const sortData = (data) => {
  if (!Array.isArray(data) || data?.length === 0) return [];

  return data
    .map((group) => ({
      ...group,
      errorTypes: group?.errorTypes
        ?.map((type) => ({
          ...type,
          details: type?.details?.sort((a, b) => (b?.count ?? 0) - (a?.count ?? 0)),
        }))
        ?.sort(
          (a, b) =>
            (b?.details?.reduce((sum, detail) => sum + (detail?.count ?? 0), 0) ?? 0) -
            (a?.details?.reduce((sum, detail) => sum + (detail?.count ?? 0), 0) ?? 0)
        ),
    }))
    .sort(
      (a, b) =>
        (b?.errorTypes?.reduce(
          (sum, type) =>
            sum +
            (type?.details?.reduce((detailSum, detail) => detailSum + (detail?.count ?? 0), 0) ??
              0),
          0
        ) ?? 0) -
        (a?.errorTypes?.reduce(
          (sum, type) =>
            sum +
            (type?.details?.reduce((detailSum, detail) => detailSum + (detail?.count ?? 0), 0) ??
              0),
          0
        ) ?? 0)
    );
};

const ErrorGroupTotals = ({ data, updateQueryParams }) => {
  const [open, setOpen] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const [finalData, setFinalData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultData = sortData(data);
        // Use Promise.all to wait for all async operations to complete
        const promises = resultData.flatMap((group) =>
          group?.errorTypes?.flatMap((type) =>
            type?.details?.map((detail) =>
              getErrorMessage(type?.errorType, detail, group?.errorGroup)
            )
          )
        );

        const results = await Promise.all(promises);

        const result = results.reduce((acc, { message, type, group, details }) => {
          if (!acc[group]) {
            acc[group] = {};
          }

          if (!acc[group][type]) {
            acc[group][type] = [];
          }

          const existingMessage = acc[group][type]?.find(
            (entry) => entry.message === message
          );

          if (existingMessage) {
            existingMessage.totalSum += details?.totalSum;
            existingMessage.count += details?.count;
          } else {
            acc[group][type].push({ message, totalSum: details?.totalSum, count: details?.count, details });
          }

          return acc;
        }, {});
        // Now you can use `results` to process further if needed
        setFinalData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [data]);


  const getErrorMessage = async (errorType, detail, errorGroup) => {
    try {
      const errorParsers = {
        "Prohibited Scheme": parseProhibitedSchemeError,
        "Acquirer Call Back": parseAcquirerCallbackError,
        "Miscellaneous Error": parseMiscellaneousError,
        "DepositFlow:3dsFailed": parse3dsFailedDepsoitFlowError,
        "3DS failed": parse3dsFailedError,
        "DepositFlow:ResponseReceivedFromAcquirer": parseResponseReceivedError,
        "NO IIN Found": parseNoIINFoundError,
      };

      if (errorType in errorParsers) {
        return {
          type: errorType,
          group: errorGroup,
          message: await errorParsers[errorType](detail) || "N/A",
          details: detail,
        };
      } else if (errorGroup === "ApplicationError") {
        return {
          type: errorType,
          group: errorGroup,
          message: await parseApplicationError(detail) || "N/A",
          details: detail,
        };
      } else {
        return {
          message: detail?.description || detail?.errormessage || "N/A",
          type: errorType,
          group: errorGroup,
          details: detail,
        };
      }
    } catch (error) {
      return {
        message: "N/A",
        type: errorType,
        group: errorGroup,
        details: detail,
      };
    }
  };

  const parseProhibitedSchemeError = (detail) => {
    const descriptionObj = detail?.description ? JSON.parse(detail.description) : null;
    if (descriptionObj && typeof descriptionObj === "object") {
      return descriptionObj?.message || "N/A";
    } else if (
      typeof descriptionObj === "string" &&
      descriptionObj?.trim()?.startsWith("{")
    ) {
      const parsedDescriptionObj = JSON.parse(descriptionObj);
      return parsedDescriptionObj?.message || "N/A";
    } else {
      return "N/A";
    }
  };

  const parseAcquirerCallbackError = (detail) => {
    const descriptionObj = detail?.description ? JSON.parse(detail.description) : null;
    switch (true) {
      case descriptionObj?.result?.description !== undefined:
        return descriptionObj?.result?.description || "N/A";
      case descriptionObj?.error_message !== undefined:
        return descriptionObj.error_message || "N/A";
      case descriptionObj?.data?.status_description !== undefined:
        return descriptionObj?.data?.status_description || "N/A";
      case descriptionObj?.status_description !== undefined:
        return descriptionObj.status_description || "N/A";
      case descriptionObj?.cancelReason !== undefined:
        return descriptionObj?.cancelReason || "N/A";
      case descriptionObj?.responseMessage !== undefined:
        return descriptionObj?.responseMessage || "N/A";
      case descriptionObj?.parameterErrors?.[0]?.message !== undefined:
        return descriptionObj?.parameterErrors[0].message || "N/A";
      case descriptionObj?.decline_reason !== undefined:
        return descriptionObj?.decline_reason || "N/A";
      case descriptionObj?.description !== undefined:
        return descriptionObj?.description || "N/A";
      case descriptionObj?.callbackResponse?.reason !== undefined:
        return descriptionObj?.callbackResponse?.reason || "N/A";
      case descriptionObj?.errormessage !== undefined:
        return (
          descriptionObj?.errormessage || descriptionObj?.errordata || "N/A"
        );
      case descriptionObj?.result?.message !== undefined:
        return descriptionObj?.result.message || "N/A";
      case descriptionObj?.data?.payment?.message !== undefined:
        return descriptionObj?.data?.payment?.message || "N/A";
      case descriptionObj?.message !== undefined:
        return descriptionObj?.message || "N/A";
      default:
        return "N/A";
    }
  };

  const parseMiscellaneousError = (detail) => {
    const descriptionObj = detail?.description ? JSON.parse(detail.description) : null;
    if (descriptionObj) {
      if (descriptionObj?.success === false && descriptionObj?.data) {
        return descriptionObj?.data?.gatewayResponse || "N/A";
      } else if (descriptionObj?.result === "ERROR") {
        if (descriptionObj.errors) {
          return (
            descriptionObj?.errors[0]?.error_message ||
            descriptionObj?.error_message ||
            "N/A"
          );
        }
      } else if(descriptionObj?.code === "REJECT") {
          if(descriptionObj?.errorFields){
            return (
              descriptionObj?.errorFields[0] ||
              "N/A"
            );
          }
      } 
      else {
        return descriptionObj?.message || "N/A";
      }
    }
  };

  const parse3dsFailedError = (detail) => {
    const descriptionObj = detail?.description ? JSON.parse(detail.description) : null;
    if (descriptionObj && typeof descriptionObj === "object") {
      if (descriptionObj && descriptionObj?.errormessage) {
        return descriptionObj?.errormessage || "N/A";
      }
      else if(descriptionObj?.acquirerresponsemessage){
        return descriptionObj?.acquirerresponsemessage || "N/A"
      }
    }
  };
  const parse3dsFailedDepsoitFlowError = (detail) => {
    const descriptionObj = detail?.description
      ? JSON.parse(detail?.description?.replace("value=", ""))
      : null;
    if (descriptionObj?.response_message) {
      return descriptionObj?.response_message || "N/A";
    } else if (descriptionObj) {
      return descriptionObj?.errormessage || descriptionObj?.errordata || "N/A";
    } else {
      return detail?.description && detail?.description !== ""
        ? detail.description
        : "N/A";
    }
  };
  const parseApplicationError = (detail) => {
    const descriptionObj = detail?.description ? JSON.parse(detail.description) : null;
    if (descriptionObj?.errorDetails) {
      return descriptionObj?.errorDetails?.message || "N/A";
    } else if (descriptionObj?.data?.status_description) {
      return descriptionObj?.data?.status_description || "N/A";
    } else if (descriptionObj?.error) {
      return descriptionObj?.error || "N/A";
    }
  };

  const parseResponseReceivedError = (detail) => {
    const descriptionObj = detail?.description ? JSON.parse(detail.description) : null;
    if (descriptionObj && typeof descriptionObj === "object") {
      if (descriptionObj && descriptionObj?.errormessage) {
        return (
          descriptionObj?.errormessage || descriptionObj?.errordata || "N/A"
        );
      }
    }
  };

  const parseNoIINFoundError = (detail) => {
    const descriptionObj =
      detail?.description && detail?.description?.trim()?.startsWith("{")
        ? JSON.parse(detail?.description)
        : null;
    if (descriptionObj?.message) {
      return descriptionObj?.message || "N/A";
    } else if (detail?.description) {
      return detail?.description || "N/A";
    }
  };

  const handleOpen = (errorType, data) => {
    setJsonData(parseJSON(data?.description, errorType));
    setOpen(true);
  };

  const goToTransactionPage = async (errorGroup) => {
    const navigateLink = await updateQueryParams({
      errorDetailsType: errorGroup?.description,
    });
    window.open(navigateLink, "_blank");
  };

  const handleClose = () => {
    setOpen(false);
    setJsonData(null);
  };

  return (
    <div className="p-4">
      <Typography variant="h6" className="mb-4">
        Error group totals:
      </Typography>
      {Object.entries(finalData).map(([errorGroup, types], index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className="flex w-full justify-between">
              <Typography className="font-bold">{errorGroup}:</Typography>
              <Typography className="ml-2 text-blue-500">
                (
                {formatCurrency(
                  Object.values(types).reduce(
                    (acc, messages) =>
                      acc +
                      messages.reduce((sum, { count }) => sum + count, 0),
                    0
                  )
                )}
                ){" "}
                {Object.values(types)
                  .reduce(
                    (acc, messages) =>
                      acc +
                      messages.reduce((sum, { count }) => sum + count, 0),
                    0
                  )
                  ?.toLocaleString()}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {Object.entries(types).map(([errorType, messages], typeIndex) => (
              <Accordion key={typeIndex} className="mb-2">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="flex w-full justify-between">
                    <Typography className="font-semibold">{errorType}:</Typography>
                    <Typography className="ml-2 text-blue-500">
                      (
                      {messages.reduce((sum, { count }) => sum + count, 0)?.toLocaleString()}
                      ){" "}
                      {formatCurrency(
                        messages.reduce((sum, { totalSum }) => sum + totalSum, 0)
                      )}
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="ml-4">Error detail totals:</Typography>
                  <ul className="ml-8 list-disc">
                    {messages.map(({ message, totalSum, count, details }, messageIndex) => (
                      <li
                        key={messageIndex}
                        
                        className="cursor-pointer"
                      >
                        <div className="mt-2 flex w-full justify-between">
                          <div className="w-[90%] overflow-auto"
                          onClick={() => handleOpen(errorType, details)}
                          >
                            {message || "N/A"}
                          </div>
                          <Typography onClick={()=>goToTransactionPage(details)}>
                            <span className="text-blue-500">
                              ({formatCurrency(totalSum)}) {count?.toLocaleString()}
                            </span>
                          </Typography>
                        </div>
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6" component="h2">
            JSON Data
          </Typography>
          <pre>{JSON.stringify(jsonData, null, 2)}</pre>
        </Box>
      </Modal>
    </div>
  );
};

export default ErrorGroupTotals;
