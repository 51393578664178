import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const closeImage = '/cryptonpay/crytoicons/close.png';

function CreateAccountStageOne(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [data, setData] = useState([]);

  const [timeLocking, setTimeLocking] = useState(false);
  const [showAcceptAmountOption, setAcceptAmountOption] = useState(false);


  const handleChange = (e) => {
    if (e.target.name === "merchant_email") {
      props.setMerchantEmail(false)
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (regex.test(e.target.value) === true) {
        props.getMerchantWithEmail(e.target.value)
      }
    }
    setData({ ...data, [e.target.name]: e.target.value });
    props.setErrorMsg('')
  };

  return (
    <div className="create_account_container">
      <div className="create_account_header">
        <div className="create_account_heading">Create Account (1/2)</div>
        <div
          className="filter_close_button"
          onClick={() => {
            props.handleCloseAddNew();
          }}
        >
          <img src={`${image_base_url}${closeImage}`} alt="closeImage" />
        </div>
      </div>

      <div className="create_account_form">
        {props.data &&
          props.data.map((item, index) => {
            return (
              <div style={{ margin: '20px 0px' }} key={index}>
                {item.input_type === 'input_box' && (
                  <div key={index}>
                    <label>
                      <b>{item.label}</b>
                    </label>
                    <input
                      className="create_account_input"
                      type={item.type}
                      id={item.id}
                      name={item.name}
                      value={(data && data[item.name]) || item.value || ''}
                      placeholder={item.placeholder}
                      data-e2e={item.placeholder}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </div>
                )}
                {props.errorMsg !== '' && props.errorField === item.name && (
                  <div style={{ color: 'red' }}>{props.errorMsg}</div>
                )}
                {item.input_type === 'input_button' && (
                  <div
                    className="login_button_get_started"
                    key={index}
                    data-e2e="login_button_get_started"
                  >
                    <button
                      onClick={() => item.onClick(data)}
                      data-e2e={item.name}
                    >
                      {item.name}
                    </button>
                  </div>
                )}

                {item.input_type === 'select' && item.id === "kytRequired" && (
                  <div className="form-group-cryptonpay" key={index}>
                    <div className="FilterModalInputGroup">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "100%"
                        }}
                      >
                        <label>
                          <b>{item.label}</b>
                        </label>
                      </div>
                      <div
                        className={!item.disableField?"FilterModalInputDropdown":"FilterModalInputDropdown FilterModalInputDropdownDisabled"}
                        data-e2e="FilterModalInputDropdown"
                        onClick={() => {
                          setAcceptAmountOption(false)
                          if(!item.disableField){
                            setTimeLocking(true);
                          }
                        }}
                      >
                        <div
                          className="FilterModalInputText"
                        >
                          {props.kytRequired === true ? ('KYT Required') : ('KYT Not Required')}
                        </div>
                        <i
                          className="fa fa-chevron-down FilterModalInputDropIcon"
                          data-e2e={props.kytRequired === true ? ('KYT Required') : ('KYT Not Required')}
                        />
                      </div>
                      {timeLocking && (
                        <div
                          className="FilterModalCurrencyDropdownlist"
                          data-e2e="FilterModalCurrencyDropdownlist"
                        >
                          <div
                            className="FM_currencyDrop_All"
                            data-e2e="FM_currencyDrop_All"
                            onClick={() => {
                              setTimeLocking(false);
                            }}
                          >
                            <span
                              className="FM_currencyDrop_AllSelector"
                              data-e2e="FM_currencyDrop_AllSelector"
                            >
                              {props.kytRequired === true ? ('KYT Required') : ('KYT Not Required')}
                            </span>
                            <span className="FilterModalInputDropUpIconWrap">
                              <i
                                data-e2e="allowableCryptoDropDownIcon"
                                className="fa fa-chevron-up FilterModalInputDropUpIcon"
                              />
                            </span>
                          </div>
                          {item.options &&
                            item.options.map((ele, index) => {
                              return (
                                <div
                                  key={index}
                                  className="currencyListItemWrap"
                                  data-e2e={ele.name}
                                  name={ele.name}
                                  onClick={(e) => {
                                    props.handelKytRequired(ele.value);
                                    setTimeLocking(false);
                                  }}
                                >
                                  <div className="currencyListIconNameWrap" data-e2e={ele.name}>
                                    <div className="currencyList_Name" data-e2e={ele.label}>
                                      {ele.label}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {item.input_type === 'select' && item.id === "acceptExactAmountsOnly" && (
                  <div className="form-group-cryptonpay" key={index}>
                    <div className="FilterModalInputGroup">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "100%"
                        }}
                      >
                        <label>
                          <b>{item.label}</b>
                        </label>
                      </div>
                      <div
                        className="FilterModalInputDropdown"
                        data-e2e="FilterModalInputDropdown"
                        onClick={() => {
                          setTimeLocking(false);
                          setAcceptAmountOption(true);
                        }}
                      >
                        <div
                          className="FilterModalInputText"
                          data-e2e="FilterModalInputText"
                        >
                          {(props.acceptExactAmountsOnly ? "Accept Exact Amounts Only" : "Accept Any Amounts")}
                        </div>
                        <i
                          className="fa fa-chevron-down FilterModalInputDropIcon"
                          data-e2e={(props.acceptExactAmountsOnly ? "Accept Exact Amounts Only" : "Accept Any Amounts")}
                        />
                      </div>
                      {showAcceptAmountOption && (
                        <div
                          className="FilterModalCurrencyDropdownlist"
                        >
                          <div
                            className="FM_currencyDrop_All"
                            onClick={() => {
                              setAcceptAmountOption(false);
                            }}
                          >
                            <span
                              className="FM_currencyDrop_AllSelector"
                            >
                              {props.acceptExactAmountsOnly === true ? ('Accept Exact Amounts Only') : ('Accept Any Amounts')}
                            </span>
                            <span className="FilterModalInputDropUpIconWrap">
                              <i
                                data-e2e={props.acceptExactAmountsOnly === true ? ('Accept Exact Amounts Only') : ('Accept Any Amounts')}
                                className="fa fa-chevron-up FilterModalInputDropUpIcon"
                              />
                            </span>
                          </div>
                          {item.options &&
                            item.options.map((ele, index) => {
                              return (
                                <div
                                  key={index}
                                  className="currencyListItemWrap"
                                  data-e2e={ele.name}
                                  name={ele.name}
                                  onClick={(e) => {
                                    props.handelAcceptExactAmountsOnly(ele.value);
                                    setAcceptAmountOption(false);
                                  }}
                                >
                                  <div className="currencyListIconNameWrap" data-e2e={ele.name}>
                                    <div className="currencyList_Name" data-e2e={ele.label}>
                                      {ele.label}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default CreateAccountStageOne;
