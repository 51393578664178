import * as actionTypes from './actionTypes'

export const setSearchText = (data) => {
    return {
        type: actionTypes.SET_SEARCH_TEXT,
        data
    }
}
export const setOrderBy = (data) => {
    return {
        type: actionTypes.SET_ORDER_BY,
        data
    }
}
export const setSortBy = (data) => {
    return {
        type: actionTypes.SET_SORT_BY,
        data
    }
}
export const setFromDate = (data) => {
    return {
        type: actionTypes.SET_FROM_DATE,
        data
    }
}
export const setToDate = (data) => {
    return {
        type: actionTypes.SET_TO_DATE,
        data
    }
}
export const setDateSelected = (data) => {
    return {
        type: actionTypes.SET_DATE_SELECTED,
        data
    }
}
export const setSelectedStatusList = (data) => {
    return {
        type: actionTypes.SET_SELECTED_STATUS_LIST,
        data
    }
}
export const setSelectedStatusGroupList = (data) => {
    return {
        type: actionTypes.SET_SELECTED_STATUS_GROUP_LIST,
        data
    }
}
export const setStatusList = (data) => {
    return {
        type: actionTypes.SET_STATUS_LIST,
        data
    }
}
export const setTransactionStatusCodes = (data) => {
    return {
        type: actionTypes.SET_TRANSACTION_STATUS_CODES,
        data
    }
}
export const setSelectedMethod = (data) => {
    return {
        type: actionTypes.SET_SELECTED_METHOD,
        data
    }
}
export const setSelectedType = (data) => {
    return {
        type: actionTypes.SET_SELECTED_TYPE,
        data
    }
}
export const setAcquirerNameData = (data) => {
    return {
        type: actionTypes.SET_ACQUIRER_NAME_DATA,
        data
    }
}
export const setSelectedAcquirer = (data) => {
    return {
        type: actionTypes.SET_SELECTED_ACQUIRER,
        data
    }
}
export const setCryptos = (data) => {
    return {
        type: actionTypes.SET_CRYPTOS,
        data
    }
}
export const setSelectedCryptos = (data) => {
    return {
        type: actionTypes.SET_SELECTED_CRYPTOS,
        data
    }
}
export const setIssuerCountryList = (data) => {
    return {
        type: actionTypes.SET_ISSUER_COUNTRY_LIST,
        data
    }
}
export const setSelectedIssuerCountryList = (data) => {
    return {
        type: actionTypes.SET_SELECTED_ISSUER_COUNTRY_LIST,
        data
    }
}
export const setSelectedFiatCurrency = (data) => {
    return {
        type: actionTypes.SET_SELECTED_FIAT_CURRENCY,
        data
    }
}
export const setSelectedNetworkList = (data) => {
    return {
        type: actionTypes.SET_SELECTED_NETWORK_LIST,
        data
    }
}
export const setMinFiatAmount = (data) => {
    return {
        type: actionTypes.SET_MIN_FIAT_AMOUNT,
        data
    }
}
export const setMaxFiatAmount = (data) => {
    return {
        type: actionTypes.SET_MAX_FIAT_AMOUNT,
        data
    }
}
export const setMerchantName = (data) => {
    return {
        type: actionTypes.SET_MERCHANT_NAME,
        data
    }
}
export const setMerchantNameForHeader = (data) => {
    return {
        type: actionTypes.SET_MERCHANT_NAME_FOR_HEADER,
        data
    }
}
export const setSelectedMerchantName = (data) => {
    return {
        type: actionTypes.SET_SELECTED_MERCHANT_NAME,
        data
    }
}
export const changeIsContainsNote = (data) => {
    return {
        type: actionTypes.SET_IS_CONTAINS_NOTE,
        data
    }
}
export const setPaymentProviderList = (data) => {
    return {
        type: actionTypes.SET_PAYMENT_PROVIDER_LIST,
        data
    }
}
export const setSelectedPaymentProvider = (data) => {
    return {
        type: actionTypes.SET_SELECTED_PAYMENT_PROVIDER_LIST,
        data
    }
}