import React from 'react';
import DataTable from 'react-data-table-component';

const customStyles = {
	headCells: {
		style: {
			fontWeight: 700,
			fontSize: "14px"
		},
	},
	cells: {
		style: {
			fontSize: "14px",
		}
	}
};

export default function TableComponent(props) {
	const { columns, items } = props;
	return (
		<div>
			<DataTable
				columns={columns}
				data={items}
				pagination
				customStyles={customStyles}
				noDataComponent={<div style={{ padding: '24px', fontSize: "14px" }}>There are no data to display</div>}
			/>
		</div>
	)
}