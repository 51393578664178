import React, { useState, useEffect } from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Loading from '../../../common/Loading';
import localDb from '../../../../localDb';
import { GET_ERROR_LOGS } from '../../../../config/endpoints';
import { call, logoutAfterError } from '../../../../config/axios';
import moment from 'moment';
import { setHeader } from '../../../Redux/actions/commonAction';
import ErrorLogTable from './ErrorLogTable';
import TransactionFilters from '../Transactions/TransactionFilters';
import DownloadCsv from '../CommonPages/DownloadCsv';
import useDebounce from '../../global/useDebounce';

const searchicon = '/cryptonpay/crytoicons/searchicon1.png';
const filterIcon = "/cryptonpay/filter_icon.svg";
const downloadIcon = "/cryptonpay/download_icon.svg";

function ErrorLogs() {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal('email');
  const base_url = useSelector((state) => state.config.api_url);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearhText] = useState('');
  const [orderBy, setOrderBy] = useState('desc');
  const [sortBy, setSortBy] = useState('createdAt');
  const [fromDate, setFromDate] = useState(
    moment().subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  );
  const [toDate, setToDate] = useState(
    moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  );
  const [dateSelected, setDateSelected] = useState(false);
  const [transactionFitlers, setTransactionFilters] = useState(false);

  const [save, setSave] = useState(false);

  const handleTransactionFilters = () => {
    setTransactionFilters(false);
  };
  const [downloadCsv, setDownloadCsv] = useState(false);

  const handleCloseDownloadCsv = () => {
    setDownloadCsv(false);
  };

  const handleSave = () => {
    setSave(!save);
  };
  const resetFilters = () => {
    setSearhText('');
    setOrderBy('desc');
    setSortBy('createdAt');
    setPage(1);
    setDateSelected(false);
    setTotalCount(0);
    setFromDate([
      moment().subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    ]);
    setToDate([moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')]);
    // setSelectedCryptos([]);
  };

  const getErrorLogs = async (page) => {
    let pageNo = 1;
    if (page) {
      pageNo = page;
    }

    let payload = {
      email: email,
      merchantId: searchText.toString(),
      transactionId: searchText.toString(),
      component: searchText,
      severity: searchText,
      errorCode:searchText,
      errorMsg:searchText,
      orderBy: orderBy,
      sortBy: sortBy,
      page: pageNo,
    };

    if (dateSelected) {
      payload = {
        ...payload,
        fromDate: fromDate,
        toDate: toDate,
      };
    }
    setLoading(true);
    await call(
      {
        ...GET_ERROR_LOGS,
        url: base_url + GET_ERROR_LOGS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          if (pageNo === 1) {
            setData(res.data.errorList);
            setTotalCount(res.data.totalCount);
          } else {
            let list = data.concat(res.data.errorList);
            setData(list);
            setTotalCount(res.data.totalCount);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };
  const debouncedSearchText = useDebounce(searchText);
  useEffect(() => {
    dispatch(setHeader('ErrorLogs'));
    getErrorLogs()
  }, [debouncedSearchText,orderBy, sortBy, save]);

  const name = 'Error Logs';

  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
        <CommonHeader name={name} />
        <div>
          <div className="transaction_filter">
            <div className="transactionsFiltersWrap">
              <div className="transactionsFilters">
                <div className="transactionsFiltersItem transactionsSearchFilter">
                  <div className="transactionsFilterTextWrap">
                    <input
                      type="text"
                      className="transactionsFilterInput"
                      value={searchText}
                      name="searchText"
                      data-e2e="search-textbox"
                      onChange={(e) => {
                        setSearhText(e.target.value);
                        setPage(1);
                      }}
                      placeholder="Search Error Logs..."
                    />
                  </div>
                  <div className="transactionsFilterSingleIconWrap">
                    <img
                      src={`${image_base_url}${searchicon}`}
                      alt="searchicon"
                      className="transactionsFilterSingleIcon"
                      data-e2e="searchIcon"
                      onClick={() => {
                        getErrorLogs();
                      }}
                    />
                  </div>
                </div>

                {/* <div
                  className="filterBtn"
                  data-e2e="filterBtn"
                  onClick={() => {
                    setTransactionFilters(true);
                  }}
                >
                  <img
                    data-e2e="filterIcon"
                    src={`${image_base_url}${filterIcon}`}
                    alt="filterIcon"
                    className="filterBtnIcon"
                  />
                </div> */}
                <Dialog
                  open={transactionFitlers}
                  onClose={handleTransactionFilters}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <TransactionFilters
                    filter_name="errorLogs"
                    statusGroupList={[]}
                    selectedStatusGroupList={[]}
                    handleTransactionFilters={handleTransactionFilters}
                    fromDate={moment(fromDate)}
                    toDate={moment(toDate)}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    handleSave={handleSave}
                    setDateSelected={setDateSelected}
                    dateSelected={dateSelected}
                    resetFilters={resetFilters}
                  />
                </Dialog>
              </div>
            </div>
            {/* <div
              className="filterBtn"
              data-e2e="filterBtn"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setDownloadCsv(true);
              }}
            >
              <img
                data-e2e="downloadIcon"
                src={`${image_base_url}${downloadIcon}`}
                alt="filterIcon"
                className="downloadBtnIcon"
              />
            </div> */}
            <Dialog
              open={downloadCsv}
              onClose={handleCloseDownloadCsv}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DownloadCsv
                handleCloseDownloadCsv={handleCloseDownloadCsv}
                data={data}
                resetFilters={resetFilters}
                name="errorLogs"
              />
            </Dialog>
          </div>
          <div>
            <ErrorLogTable
              table_name="Induvisual_transactions"
              data={data}
              sortBy={sortBy}
              orderBy={orderBy}
              getErrorLogs={()=> { 
                getErrorLogs(1)
              }}
              changeOrder={(sort) => {
                if (sortBy !== sort) {
                  setOrderBy('desc');
                  setSortBy(sort);
                } else {
                  setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
                }
              }}
              handleShowMore={() => {
                let newPage = page + 1;
                setPage(newPage);
                getErrorLogs(newPage);
              }}
              showMoreButton={totalCount && data && data.length < totalCount}
            />
          </div>
        </div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
}

export default ErrorLogs;
