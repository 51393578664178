export const removeHttp = (url) => {
  url = url.replace(/^(https?:\/\/)/i, '');
  url = url.replace(/^(www\.)/i, '');
  url = url.replace(/\/$/, '');
  return url;
};

export const maskCardNumber = (cardNumber) =>  {
  // Mask the digits between the first six and last four digits
      cardNumber = cardNumber?.toString();
      return cardNumber?.slice(0, 6) + "******" + cardNumber?.slice(-4);
}
export const getEmailVal = (email) => {
  const emailSplit = email ? email.split("|") : []
  const emailVal = emailSplit.length > 0 ? emailSplit[0] : null
  return emailVal
}

export const SortByIcon = (props) => {
  return (
    <i
      className={`fa fa-chevron-${props.orderBy === 'desc' ? 'down' : 'up'
        } TransactionTable_heading_sortIcon`}
    />
  );
};
  
  export const saveToSessionStorage = (data) => {
    sessionStorage.setItem("domainId", data.domainId ? data.domainId : null);
    localStorage.setItem("domainNameUrl", data.domainNameUrl ? data.domainNameUrl : null);
    sessionStorage.setItem("paymentProviderId", data.paymentProviderId ? data.paymentProviderId : null);
    sessionStorage.setItem("paymentProviderName", data.paymentProviderName ? data.paymentProviderName : null);
    sessionStorage.setItem("defaultB2CTheme", data.defaultB2CTheme);
    sessionStorage.setItem("headerLightBgColour", data.headerLightBgColour);
    sessionStorage.setItem("headerDarkBgColour", data.headerDarkBgColour);
    sessionStorage.setItem("headerButtonLightBgColour", data.headerButtonLightBgColour);
    sessionStorage.setItem("headerButtonDarkBgColour", data.headerButtonDarkBgColour);
    sessionStorage.setItem('headerLightTextColour', data.headerLightTextColour)
    sessionStorage.setItem("logoWidth", data.logoWidth);
  }
export const getAvailableCryptos = (blockchain, cryptos) => {
    const availableBlockchains = {
      "Bitcoin": ["BTC"],
      "Litecoin": ["LTC"],
      "Ripple": ["XRP"],
      "Ethereum": ["ETH", "USDT", "USDC", "DAI"],
      "Tron": ["TRX", "TRC20-USDT"]
    };
    
    const selectedBlockchain = availableBlockchains[blockchain];
    
    if (selectedBlockchain) {
      return  cryptos && cryptos.filter(crypto => selectedBlockchain.includes(crypto.symbol));
    }

  }
  let isViewPermissionValid;
  if (typeof localStorage !== "undefined") {
    isViewPermissionValid = localStorage.getItem("viewPermission") === "true" && (localStorage.getItem("adminRole") === "PaymentProviderUser" ||localStorage.getItem("adminRole") === "SuperAdmin" );
  } else {
    isViewPermissionValid = false;
  }
  export { isViewPermissionValid };