import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  ACQUIRER_RULES_UPDATE_BLOCKED_BIN_COUNTRIES,
  ACQUIRER_RULES_UPDATE_BLOCKED_USER_COUNTRIES,
  ACQUIRER_RULES_UPDATE_BLOCKED_CARD_SCHEMES,
  ACQUIRER_RULES_UPDATE_ALLOWED_CURRENCIES,
  ACQUIRER_RULES_UPDATE_IP_ADRESSES,
  ACQUIRER_RULES_UPDATE_MANDATORY_FIELDS,
  UPDATE_BLOCKED_COUNTRY_BY_SERVICE_LIST,
} from "../../../../../../config/endpoints.js";
import localDb from "../../../../../../localDb";
import { call } from "../../../../../../config/axios";
import Loading from "../../../../../common/Loading.js";

const CPMultiCountryCode = ({
  codeId,
  dataE2eLabel,
  field,
  items,
  selectedItems,
  ItemComponent,
  setShowDropDown,
  containerStyle = {},
  serviceUniqueId,
}) => {
  const baseURL = useSelector((state) => state.config.api_url);
  const [loading, setLoading] = useState(false);

  const handleAddNewAquirer = async () => {
    try {
      if (field === "cardCountryCode") {
        return handleAddNewAPI(
          selectedItems,
          "countryList",
          ACQUIRER_RULES_UPDATE_BLOCKED_BIN_COUNTRIES,
        );
      }
      if (field === "cardCountryCodeBasedOnService") {
        return handleAddNewServiceLevelAPI(
          selectedItems,
          "countryList",
          UPDATE_BLOCKED_COUNTRY_BY_SERVICE_LIST,
        );
      }
      if (field === "cardSchemeData") {
        return handleAddNewAPI(
          selectedItems,
          "cardList",
          ACQUIRER_RULES_UPDATE_BLOCKED_CARD_SCHEMES,
        );
      } else if (field === "AllCurrenciesData") {
        return handleAddNewAPI(
          selectedItems,
          "currencyList",
          ACQUIRER_RULES_UPDATE_ALLOWED_CURRENCIES,
        );
      } else if (field === "AllIpAddressTypesData") {
        return handleAddNewAPI(
          selectedItems,
          "ipAddressesList",
          ACQUIRER_RULES_UPDATE_IP_ADRESSES,
        );
      } else if (field === "AllMandatoryFieldsData") {
        return handleAddNewAPI(
          selectedItems,
          "mandatoryFieldsList",
          ACQUIRER_RULES_UPDATE_MANDATORY_FIELDS,
        );
      } else {
        return handleAddNewAPI(
          selectedItems,
          "countryList",
          ACQUIRER_RULES_UPDATE_BLOCKED_USER_COUNTRIES,
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleAddNewAPI = async (selectedItems, field, APIName) => {
    const payload = {
      acquirerUniqueId: codeId?.acquirerUniqueId,
      [field]: selectedItems,
      email: localDb.getVal("email"),
    };
    setLoading(true);
    try {
      const res = await call(
        {
          ...APIName,
          url: baseURL + APIName.url,
        },
        payload,
      );

      if (res?.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const handleAddNewServiceLevelAPI = async (selectedItems, field, APIName) => {
    const payload = {
      uniqueId: serviceUniqueId,
      [field]: selectedItems,
      email: localDb.getVal("email"),
    };
    setLoading(true);
    try {
      const res = await call(
        {
          ...APIName,
          url: baseURL + APIName.url,
        },
        payload,
      );

      if (res?.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const uniqueItems = new Set(
    selectedItems?.map((item) =>
      field === "AllIpAddressTypesData" ? item : item?.name,
    ),
  );
  const sortedItems = [
    ...selectedItems,
    ...items?.filter(
      (item) =>
        !uniqueItems?.has(
          field === "AllIpAddressTypesData" ? item : item?.name,
        ),
    ),
  ];

  return (
    <div
      style={containerStyle}
      className="FilterModalCurrencyDropdownlist rulesTab relative w-full"
    >
      {loading && <Loading />}
      <div className="sticky w-full">
        <div
          className="w-full"
          style={{ backgroundColor: "white", borderRadius: "5px" }}
        >
          <div
            className="FM_currencyDrop_All"
            onClick={() => {
              handleAddNewAquirer();
              setShowDropDown(false);
            }}
          >
            <span className="FM_currencyDrop_AllSelector">
              {selectedItems?.length === items?.length ||
              selectedItems?.length === 0
                ? "Select options"
                : `${selectedItems?.length} Selected`}
            </span>
            <span className="FilterModalInputDropUpIconWrap">
              <i
                className="fa fa-chevron-up FilterModalInputDropUpIcon"
                data-e2e={`${dataE2eLabel}contract-icon`}
              />
            </span>
          </div>
        </div>
      </div>

      {sortedItems?.map((item, index) => (
        <ItemComponent item={item} key={index} />
      ))}
    </div>
  );
};

export default CPMultiCountryCode;
