
export default (
  state = {},
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};
