import React, {useState } from "react";
import MediaQuery from "react-responsive";
import { Table } from "reactstrap";
import ShowMoreButton from "../MobileViewCommonComponent/ShowMoreButton";
import SingleWalletMobileRow from "../MobileViewCommonComponent/SingleWalletMobileRow";
import SingleTransactionTableRow from "./SingleRowCentralisedWallet";

const WalletTable = (props) => {
  const [loading, setLoading] = useState(false);
  const { sortBy, orderBy } = props

  const SortByIcon = (props) => {
    const isActive = props.isActive;
    return (
      <i className={`fa fa-chevron-${isActive && orderBy === "desc" ? 'up' : 'down'} ${isActive ? 'active' : ''}`} />
    );
  };
  return (
    <div className="TransactionTableMain">
      {props && props.data && props.data.length > 0 ? (
        <React.Fragment>
          <MediaQuery minWidth={768}>
            <Table responsive style={{ border: "none" }}>
              <tbody className="TransactionTable_BODY">
                <tr className="TransactionTable_BODY_Header">
                  <th
                    className="TransactionTable_heading "
                    width="10%"
                    onClick={() => {
                      props.changeOrder("blockchain");
                    }}
                    data-e2e="blockchain"
                  >
                    Blockchain{" "}
                    <SortByIcon
                      isActive={sortBy === 'blockchain'}
                      data-e2e="blockchain"
                    />
                  </th>
                  <th
                    className="TransactionTable_heading"
                    width="10%"
                    onClick={() => {
                      props.changeOrder("cryptoId.cryptoId");
                    }}
                    data-e2e="blockchain"
                  >
                    Crypto{" "}
                    <SortByIcon

                      isActive={sortBy === 'cryptoId.cryptoId'}
                      data-e2e="blockchain"
                    />
                  </th>
                  <th
                    className="TransactionTable_heading"
                    width="10%"
                    onClick={() => {
                      props.changeOrder("network");
                    }}
                    data-e2e="network"
                  >
                    Network{" "}
                    <SortByIcon
                      isActive={sortBy === 'network'}
                      data-e2e="network" />
                  </th>
                  <th
                    className="TransactionTable_heading"
                    width="20%"
                    onClick={() => {
                      props.changeOrder("address.key");
                    }}
                    data-e2e="address.key"
                  >
                    Wallet Address{" "}
                    <SortByIcon
                      data-e2e="address.key"
                      isActive={sortBy === 'address.key'}

                    />
                  </th>
                  <th
                    className="TransactionTable_heading"
                    width="16%"
                    onClick={() => {
                      props.changeOrder("address.seedIndex");
                    }}
                    data-e2e="address.seedIndex"
                  >
                    Seed Index{" "}
                    <SortByIcon

                      isActive={sortBy === 'address.seedIndex'}
                      data-e2e="address.seedIndex"
                    />
                  </th>
                  <th
                    className="TransactionTable_heading"
                    width="20%"
                    onClick={() => {
                      props.changeOrder("balanceAmountUSDApprox");
                    }}
                    data-e2e="balanceAmountUSDApprox"
                  >
                    Approx USD Balance{" "}
                    <SortByIcon

                      isActive={sortBy === 'balanceAmountUSDApprox'}
                      data-e2e="balanceAmount"
                    />
                  </th>
                  <th
                    className="TransactionTable_heading"
                    width="20%"
                    onClick={() => { props.changeOrder('paymentProvider.firstName') }}
                  >
                    Payment Provider{" "}
                    <SortByIcon
                      isActive={sortBy === 'paymentProvider.firstName'}

                    />
                  </th>
                  <th
                    className="TransactionTable_heading"
                    width="20%"
                    onClick={() => {
                      props.changeOrder("status.updated");
                    }}
                    data-e2e="status.updated"
                  >
                    Last Updated{" "}
                    <SortByIcon

                      isActive={sortBy === 'status.updated'}
                      data-e2e="balanceAmount"
                    />
                  </th>
                  <th
                    className="TransactionTable_heading"
                    width="20%"
                    onClick={() => {
                      props.changeOrder("status.code");
                    }}
                    data-e2e="status.code"
                  >
                    Status{" "}
                    <SortByIcon

                      isActive={sortBy === 'status.code'}
                      data-e2e="status[0].code"
                    />
                  </th>

                </tr>
                {props.data.map((TData, i) => {
                  return (
                    <SingleTransactionTableRow
                      data={TData}
                      key={i}
                      loading={loading}
                      setLoading={setLoading}
                      getWallets={() => {
                        props.getWallets();
                      }}
                    />
                  );
                })}
              </tbody>
            </Table>
          </MediaQuery>

          <MediaQuery maxWidth={767}>
            {props.data.map((TData, i) => {
              return (
                <SingleWalletMobileRow
                  data={TData}
                  key={i}
                  setLoading={setLoading}
                  getWallets={() => {
                    props.getWallets();
                  }}
                  view={"wallets"}
                />
              );
            })}
          </MediaQuery>
        </React.Fragment>
      ) : (
        <div className="No_Transactions_Found">No Data Found</div>
      )}

      <MediaQuery minWidth={768}>
        {props.showMoreButton ? (
          <div className="Show_more_wrap">
            <div
              className="Show_more"
              onClick={() => {
                props.handleShowMore();
              }}
              data-e2="show-more-btn"
            >
              Show More
            </div>
          </div>
        ) : null}
      </MediaQuery>

      <MediaQuery maxWidth={767}>
        <ShowMoreButton
          showMoreButton={props.showMoreButton}
          handleShowMore={() => props.handleShowMore()}
          handleShowLess={() => props.handleShowLess()}
          page={props.page}
          totalCount={props.totalCount}
        />
      </MediaQuery>
    </div>
  );
};

export default WalletTable;
