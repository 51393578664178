import React from 'react';
import { useSelector } from 'react-redux';

const saveIcon = '/cryptonpay/crytoicons/info.png';

function WalletStatusChangeAlert(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const handleAccept = () => {
    props.handleAccpetCloseForm();
    props.callUpdateWalletStatus()
  };
  const handleNofity = () => {
    props.sendMerchantNotificationFromAdmin()
    props.callUpdateNofityStatus()
    props.handleAccpetCloseForm();
  }
  return (
    <div className="ClearAlertModal wallet_status_alert_model">
      <div className="ClearAlertModalBody">
        <div
          className="ClearAlertModalIconWrap"
          data-e2e="ClearAlertModalIconWrap"
        >
          <img
            src={`${image_base_url}${saveIcon}`}
            alt="downloadAlertIcon"
            className="ClearAlertModalIcon"
            data-e2e="ClearAlertModalIcon"
          />
        </div>
        <div className="ClearAlertModalTitle">Are you sure?</div>
        <div className="ClearAlertModalDescription">
          {props.type === "transactions" ? 'Are you sure you want to send notification' : 'Are you sure you want to change selected Wallet Status?'}
        </div>
        <div className="alert_btn_wrap">
          <div
            className="ClearAlertModelApproveBtn change_trans_button"
            data-e2e="yes-btn"
            style={{ color: 'white', backgroundColor: '#FF8300' }}
            onClick={() => {
              props.type === "transactions" ? handleNofity() : handleAccept()
            }}
          >
            Yes
          </div>
          <div
            className="ClearAlertModalGoBackBtn change_trans_go_back"
            onClick={() => {
              props.handleAccpetCloseForm();
            }}
          >
            Go Back
          </div>
        </div>
      </div>
    </div>
  );
}

export default WalletStatusChangeAlert;
