import React, { useState } from 'react';
import {
    GET_TRANSFER_WALLETS,
    VIEW_SINGLE_WALLET,
} from '../../../../config/endpoints';
import { call, logoutAfterError } from '../../../../config/axios';
import localDb from '../../../../localDb';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TransferWalletFund from './TransferWalletFund';
import { isViewPermissionValid } from '../../../common/CommonUtils';
const email = localDb.getVal('email');

function SingleWalletMobileRow(props) {
    let { data } = props;
    const [transactionDetails, setTransactionDetails] = useState(false);
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const api_url = useSelector((state) => state.config.api_url);

    const [showTransferModal, toggleShowModal] = useState(false);
    const [transferWallets, setTransferWallets] = useState([]);
    const [walletIdForTransferFunds, setWalletIdForTransferFunds] = useState(null);
    const [selectedWalletForTransfer, setSelectedWallet] = useState(null);

    const getStatusComponent = (status) => {
        switch (status) {
            case 'Available':
                return (
                    <div className="Mstatus_active" style={{ width: '120px' }} data-e2e="available">
                        AVAILABLE
                    </div>
                );
            case 'Locked':
                return (
                    <div className="Mstatus_witheld" style={{ width: '120px' }} data-e2e="locked">
                        LOCKED
                    </div>
                );
            case 'Transfer Requested':
                return <div className="Mstatus_witheld" data-e2e="TRANSFER RQUESTED">TRANSFER RQUESTED</div>;
            case 'In Use':
                return (
                    <div className="Mstatus_inactive" style={{ width: '120px' }} data-e2e="IN USE">
                        IN USE
                    </div>
                );
            case 'Failed':
                return (
                    <div className="Mstatus_inactive" style={{ width: '120px' }} data-e2e="Failed">
                        FAILED
                    </div>
                );
            case 'Inuse':
                return (
                    <div className="Tstatus_failed" style={{ width: '120px' }} data-e2e="inUse">
                        INUSE
                    </div>
                );
            default:
                return (
                    <div className="Mstatus_inactive" style={{ width: '120px' }} data-e2e="inUse">
                        IN USE
                    </div>
                );
        }
    };
    const getTransactionStatus = (sts) => {
        let status = getStatusComponent(sts);
        status = status.props.children;
        switch (status) {
            case "TIMED OUT":
            case "TRANSFER RQUESTED":
            case "LOCKED":
                return <div className="status status_timeout"></div>;
            case "AVAILABLE":
                return <div className="status status_Success"></div>;
            case "PROCESSING":
                return <div className="status status_processing"></div>;
            case "IN USE":
            case "PENDING":
            case "CANCELLED":
            case "ABANDONED":
                return <div className="status status_pending"></div>;
        }
    };
    
    const getTransferWallets = async (blockchain, network) => {
        props.setLoading(true);
        await call(
            {
                ...GET_TRANSFER_WALLETS,
                url: api_url + GET_TRANSFER_WALLETS.url,
            },
            { email: email, blockchain, network }
        )
            .then((res) => {
                props.setLoading(false);
                if (res.status === 200) {
                    setTransferWallets(res.data.transferWallets);
                }
            })
            .catch((err) => {
                props.setLoading(false);
                console.log(err.message, 'err');
                logoutAfterError(err)
            });
    };

    const viewSingleWalletDetails = async (id) => {
        await call(
            {
                ...VIEW_SINGLE_WALLET,
                url: api_url + VIEW_SINGLE_WALLET.url,
            },
            { email: email, walletId: id }
        )
            .then((res) => {
                if (res.status === 200) {
                    setSelectedWallet(res.data.wallets);
                    props.setLoading(false);
                }
            })
            .catch((err) => {
                props.setLoading(false);
                console.log(err.message, 'err');
                logoutAfterError(err)
            });
    };

    const handleTransfer = async (id, blockchain, network) => {
        await getTransferWallets(blockchain, network);
        await viewSingleWalletDetails(id);
        setWalletIdForTransferFunds(id);
        toggleShowModal(true);
    };
    let detailsUrl = props.view === "wallets" ? "/wallets": "/merchant-wallets"
    return (
        <div className="single_mobile_row_wrap">
            <div className="single_mobile_row">
                <div className="row_status">{getTransactionStatus(data.status.code)}</div>
                <div className="row_content">
                    <div className="row_content_transInfo_left">
                        <div className='merchant_name pb-2 text-capitalize'>{data.blockchain || "N/A"}</div>
                        <div className="transaction_cryp"> {data.cryptoId.cryptoId || "N/A"}</div>
                    </div>

                    <div className="row_content_transInfo_right">
                        <div className="content">
                            <div className="pb-2 text-capitalize">{data.network || "N/A"}
                            </div>
                            <Link to={`${detailsUrl}/${data._id}`} data-e2e-blockchain={data.blockchain.toLowerCase().replaceAll(' ', '-')} data-e2e={`${data.status.code}-${data.blockchain}`} data-e2e-full-addres={data.address.key && data.address.key}>
                                <div className="transaction_Id"> {
                                    data.address && data.address.key ?
                                        (data.address.key.substring(0, 12) + "..." + data.address.key.substring(data.address.key.length - 3, data.address.key.length))
                                        : "N/A"
                                }
                                </div>
                            </Link>
                        </div>
                        <div
                            onClick={() => setTransactionDetails(!transactionDetails)} className="down_icon pl-3 pr-1">
                            <i className={`fa fa-chevron-${!transactionDetails ? 'down' : 'up'}`} aria-hidden="true"></i>
                        </div>
                    </div>

                </div>
            </div>

            {transactionDetails &&
                <div className="transaction_details_wrap">
                    <div className="content">
                        <div className="content_heading">{`Balance: `}</div>
                        <div className="content_val">{(data.balanceAmountUSDApprox + " " + data.cryptoId.cryptoId) || 0}</div>
                    </div>
                    <div className="content">
                        <div className="content_heading">{`Seed index: `}</div>
                        <div className="content_val">{data.address.seedIndex || 0}</div>
                    </div>
                    <div className="content">
                        <div className="content_heading">{`Status: `}</div>
                        <div className="content_val">{getStatusComponent(data.status.code)}</div>
                    </div>
                    <div className="content">
                        <div className="content_heading">{`Last updated: `}</div>
                        <div className="content_val">{data.status && data.status.updated
                            ? moment(data.status.updated).format("DD/MM/YYYY - HH:mm")
                            : "N/A"}</div>
                    </div>

                    {data.status && data.status.code === 'Locked' ?
                        <React.Fragment>
                            <div className="hori_line"></div>
                            <div className={`${isViewPermissionValid? "disabled": ""} transfer_btn`}
                                onClick={() => isViewPermissionValid? null: handleTransfer(data?._id, data?.blockchain, data?.network)}
                                data-e2e={`${data.blockchain.toLowerCase().replaceAll(' ', '-')}`}
                                data-e2e-wallet-address={(data && data.address && data.address.key) || null}>
                                <div><i className="fa fa-plus-circle mr-2" aria-hidden="true"></i></div>
                                <div>{`Transfer`}</div>
                            </div>
                        </React.Fragment>
                        : null
                    }
                </div>
            }
            <TransferWalletFund open={showTransferModal}
                image_base_url={image_base_url}
                onClose={() => toggleShowModal(false)}
                transferWallets={transferWallets}
                selectedWalletForTransfer={selectedWalletForTransfer}
                setLoading={props.setLoading}
                walletIdForTransferFunds={walletIdForTransferFunds}
                getWallets={() => {
                    props.getWallets();
                }}
                data={data}
            />
        </div>
    )
}

export default SingleWalletMobileRow