import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import TransactionView from './MerchantTransactionView';

function MerchantTransactionDetails() {
  const [id, setId] = useState('');

  const sub_name_last = `Transaction ID: ${id}`;
  const handleId = (id) => {
    setId(id);
  };

  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
				<div className="">
					<CommonHeader
						name={sub_name_last}
						fullId={useParams().id}
						copyButton={true}
					/>
					<div>
						<TransactionView handleId={handleId} view="MerchantTransactions" />
					</div>
				</div>
      </HeaderAndFooterLayout>
    </div>
  );
}

export default MerchantTransactionDetails;
