import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

const labelColor = {
  process: "#667085",
  success: "#12B76A",
  rejected: "#F79009",
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export default function Chart(props) {
  const mockData = props.mockData;

  return (
    <div className="rounded-2xl px-2 py-2 shadow-md">
      <div className="card_dashboard_line_graph">
        <div className="card_dashboard_line_graph__title_bar">
          <p className="dashboard__account_title border-left-blue">
            Transactions
          </p>
        </div>
        <div className="card_dashboard_line_graph__graph">
          <LineChart labels={mockData?.labels} dataSets={mockData?.dataSets} />
        </div>
      </div>
    </div>
  );
}

function dataSetsMapping(dataSet) {
  let data = [];
  for (var i = 0; i < dataSet.length; i++) {
    var ele = {};
    ele.label = dataSet[i].status;
    ele.fill = false;
    if (dataSet[i].status === "Processing") {
      ele.borderColor = labelColor.process;
      ele.backgroundColor = labelColor.process;
    }
    if (dataSet[i].status === "Rejected") {
      ele.borderColor = labelColor.rejected;
      ele.backgroundColor = labelColor.rejected;
    }
    if (dataSet[i].status === "Success") {
      ele.borderColor = labelColor.success;
      ele.backgroundColor = labelColor.success;
    }
    ele.data = dataSet[i].transactions;

    data.push(ele);
  }

  return data;
}

const LineChart = ({ labels, dataSets }) => {
  const data = {
    labels: labels,
    datasets: dataSetsMapping(dataSets),
  };

  let options = {
    responsive: true,
    datasetStrokeWidth: 3,
    pointDotStrokeWidth: 4,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align : "start",
        padding : 20,
        labels: {
         
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          display: true,
        },
      },
    },
  };

  return (
    <div className="line-chart">
      <Line height={115} data={data} options={options} />
    </div>
  );
};
