import { Alert, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  GET_FRAUD_SERVICE,
  GET_CARD_AQUIRER_SERVICES,
  GET_MERCHANT_SETTING,
  MERCHANT_IMAGE_UPLOAD_SIGNED_URL,
  UPDATE_MERCHANT_SETTINGS,
  UPLOAD_MERCHANT_IMAGE
} from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import { isViewPermissionValid } from "../../../common/CommonUtils";
import { useParams } from "react-router-dom";
import Loading from "../../../common/Loading";
import constants from "../../global/constant";
const selectedCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_filled.png";
const emptyCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_empty.png";
const tick_green = "/cryptonpay/crytoicons/tick_green.png";

function UpdateMerchant() {
  const { id } = useParams();
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const role = localDb.getVal("adminRole") === "SuperAdmin" ? true : false;
  const [loading, setLoading] = useState(true);
  const [successAlert, setSuccessAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const [cryptos, setCryptos] = useState([]);
  const [selectedCryptos, setSelectedCryptos] = useState([]);
  const [updateMerchantData, setUpdateMerchantData] = useState({});
  const image_base_url = useSelector((state) => state.config.image_base_url);
 
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [keyStatusDropDown, setKeyStatusDropDown] = useState(false);
  const [isActiveCreditCardDropDown, setisActiveCreditCardDropDown] =
    useState(false);
  const [buyCryptoScreenDropDown, setBuyCryptoScreenDropDown] = useState(false);
  const [kytRequiredDropDown, setKytRequiredDropDown] = useState(false);
  const [accpetAmountDropDown, setAcceptAmountDropDown] = useState(false);
  const [
    merchantAutoWithdrawalDropDown,
    setMerchantWithdrawalAutoApprovalDropDown,
  ] = useState(false);
  const [userWalletDropDown, setUserWalletDropDown] = useState(false);
  const [merchantWalletsDropDown, setMerchantWalletsDropDown] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [rateExpiredDropDown, setRateExpiredDropDown] = useState(false);
  const [controlledSettelmentDropDown, setControlledSettelmentDropDown] =
    useState(false);
  const [googleAurthDropDown, setGoogleAurthDropDown] = useState(false);
  const [cardSettings, setCardSettings] = useState(false)
  const [antiFraudServiceData, setAntiFraudServiceData] = useState([])
  const [showCardServiceDropdown, setShowCardServiceDropdown] = useState(false)
  const [showCardServiceNewDropdown, setShowCardServiceNewDropdown] = useState(false);
  const [cardAcquire, setCardAcquire] = useState("")
  const [getAcquirerRoutingNewData, setGetAcquirerRoutingNewData] = useState([]);
  const [showLiquidityDropdown, setShowLiquidityDropdown] = useState(false);
  const handleUpdateSetting = () => {
    updateMerchantSetting();
  };
  
  const toggleSelection = (symbol) => {
    if (selectedCryptos.includes(symbol)) {
      let newList = selectedCryptos.filter((symbolObj) => symbolObj !== symbol);
      setSelectedCryptos(newList);
    } else {
      let newList = [...selectedCryptos];
      newList.push(symbol);
      setSelectedCryptos(newList);
    }
  };
  const getCardAcquireRouting = async () => {
    const payload = {
      email: localDb.getVal("email"),
      paymentProvider: localDb.getVal("merchantPaymentProvider")
    };
    await call(
      {
        ...GET_FRAUD_SERVICE,
        url: base_url + GET_FRAUD_SERVICE.url
      },
      payload
    ).then((res) => {
      if (res.status === 200) {
        setAntiFraudServiceData(res.data)
      }
    }).catch((err) => {
      console.error(err);
    })
  }
  const getCardAcquirerRoutingNew = async () => {
    const payload = {
      email: localDb.getVal("email"),
      paymentProvider: localDb.getVal("merchantPaymentProvider")
    };
    await call(
      {
        ...GET_CARD_AQUIRER_SERVICES,
        url: base_url + GET_CARD_AQUIRER_SERVICES.url
      },
      payload
    ).then((res) => {
      if (res?.status === 200) {
        setGetAcquirerRoutingNewData(res?.data)
      }
    }).catch((err) => {
      console.error(err);
    })
  }
  const updateMerchantSetting = async () => {
    setLoading(true);
    let payload = {
      merchantId: id,
      ...updateMerchantData,
      email: email,
      cardAcquireRouting: antiFraudServiceData?.length > 0 && updateMerchantData?.cardAcquireRouting !== 'None' ? antiFraudServiceData?.find(val => val?.acquireRoutingServiceName === updateMerchantData?.cardAcquireRouting)?._id : '',
      cardAcquirerRoutingNew: getAcquirerRoutingNewData?.length > 0 && updateMerchantData?.cardAcquirerRoutingNew,
      allowedCryptoList: selectedCryptos,
      merchant_email: updateMerchantData.email,
      key_status:
        updateMerchantData.key_status === "Active" ? "Active" : "Inactive",
      kytRequired:
        updateMerchantData.kytRequired === "KYT Required" ? "true" : "false",
      acceptExactAmountsOnly:
        updateMerchantData.acceptExactAmountsOnly ===
        "Accept Exact Amounts Only"
          ? "true"
          : "false",
          userWalletDeposit:
        updateMerchantData.userWalletDeposit === "Enable" ? "true" : "false",
      merchantWithdrawalAutoApproval:
        updateMerchantData.merchantWithdrawalAutoApproval === "Enable"
          ? "true"
          : "false",
      creditCardActive:
        updateMerchantData.creditCardActive === "Enable" ? "true" : "false",
      buyCryptoScreenActive:
        updateMerchantData?.buyCryptoScreenActive === "Enable" ? true : false,
      merchantWalletActivation:
        updateMerchantData.merchantWalletActivation === "Enable"
          ? "true"
          : "false",
      googleAurth:
        updateMerchantData.googleAurth === "Enable" ? "true" : "false",
      transactionFeeOnFailure: updateMerchantData?.transactionFeeOnFailure || 0,
      liquidityProvider:updateMerchantData?.liquidityProvider || 'aquanow',
      fxFee:updateMerchantData?.fxFee || 0,
    };

    await call(
      {
        ...UPDATE_MERCHANT_SETTINGS,
        url: base_url + UPDATE_MERCHANT_SETTINGS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setSuccessAlert(true);
          setAlertMsg("Merchant details updated successfuly");
          setTimeout(() => {
            setSuccessAlert(false);
            setUpdateStatus((prev) => !prev);
          }, 2000);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };

  const getMerchantSettingDetails = async () => {
    let payload = {
      email: email,
      merchantId: id,
    };
    await call(
      {
        ...GET_MERCHANT_SETTING,
        url: base_url + GET_MERCHANT_SETTING.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          let response = res.data.settings;
          setCardAcquire(response.cardAcquireRouting)
          setCardSettings(response.paymentProvider && response.paymentProvider.card && response.paymentProvider.card)
          setUpdateMerchantData({
            rateExpiryPeriod: response.rateExpiryPeriod || "10",
            ...response,
            name: response.merchantInfo.name,
            email: response.merchantInfo.email,
            transactionFeeOnFailure: response?.transactionFeeOnFailure || 0,
            cardAcquireRouting: response.cardAcquireRouting ? response.cardAcquireRouting.acquireRoutingServiceName : "None",
            cardAcquirerRoutingNew: response?.cardAcquirerRoutingNew?._id || "None",
            acceptExactAmountsOnly:
              response.acceptExactAmountsOnly &&
                response.acceptExactAmountsOnly === true
                ? "Accept Exact Amounts Only"
                : "Accept Any Amounts",
            kytRequired:
              response.kytRequired && response.kytRequired === true
                ? "KYT Required"
                : "KYT Not Required",
            key_status: response.activitation === true ? "Active" : "Inactive",
            userWalletDeposit:
              response.userWalletActive && response.userWalletActive === true
                ? "Enable"
                : "Disable",
            merchantWithdrawalAutoApproval:
              response.merchantWithdrawalAutoApproval &&
              response.merchantWithdrawalAutoApproval === true
                ? "Enable"
                : "Disable",
            merchantWalletActivation:
              response.merchantWalletActivation &&
              response.merchantWalletActivation === true
                ? "Enable"
                : "Disable",
            creditCardActive:
              response.creditCardActive && response.creditCardActive == true
                ? "Enable"
                : "Disable",
            buyCryptoScreenActive:
              response?.buyCryptoScreenActive == true
                ? "Enable"
                : "Disable",
                googleAurth:
              response.twoFAStatus && response.twoFAStatus.enabled === true
                ? "Enable"
                : "Disable",
          });
          let allCryptos =
            res.data && res.data.settings && res.data.settings.allCryptoList;
          setCryptos(allCryptos);
          let temp =
            res.data &&
            res.data.settings &&
            res.data.settings.allowedCryptoList.map((item) => {
              return item.symbol;
            });

          setSelectedCryptos(temp);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      });
  };

  useEffect(() => {
    getCardAcquireRouting()
    getCardAcquirerRoutingNew();
    getMerchantSettingDetails();
  }, [updateStatus]);

  const handleDropdownClick = (dropdownSetter, toggle) => {
    setShowCurrencyDropdown(false);
    setMerchantWalletsDropDown(false);
    setKeyStatusDropDown(false);
    setKytRequiredDropDown(false);
    setAcceptAmountDropDown(false);
    setUserWalletDropDown(false);
    setRateExpiredDropDown(false);
    setControlledSettelmentDropDown(false);
    setMerchantWithdrawalAutoApprovalDropDown(false);
    setBuyCryptoScreenDropDown(false)
    toggle ? dropdownSetter(false) : dropdownSetter(true)
  };

  const adminRole = localStorage.getItem("adminRole");
  const updateMerchantFields = [
    {
      name: "name",
      type: "text",
      label: "Full Name",
      placeholder: "Full Name",
      id: "name",
      e2e: "full-name-text-box",
      value: updateMerchantData.name || "",
    },
    {
      name: "email",
      type: "text",
      label: "Email",
      placeholder: "Email",
      id: "email",
      e2e: "email-text-box",
      value: updateMerchantData.email || "",
    },
    {
      name: "key_status",
      type: "select",
      label: "Key Status",
      placeholder: "Key Status",
      id: "key_status",
      e2e: "key-status-expand",
      e2e1: "key-status-contract",
      options: [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" },
      ],
      value: updateMerchantData.key_status || [],
      dropDown: keyStatusDropDown,
      setDropDown: setKeyStatusDropDown,
    },
    {
      name: "kytRequired",
      type: "select",
      label: "KYT Required",
      placeholder: "KYT Required",
      id: "kytRequired",
      e2e: "kyt-required-expand",
      e2e1: "kyt-required-contract",
      disableField: adminRole === "PaymentProviderAdmin" ? true : false,
      options: [
        { label: "KYT Required", value: "KYT Required" },
        { label: "KYT Not Required", value: "KYT Not Required" },
      ],
      value: updateMerchantData.kytRequired || [],
      dropDown: kytRequiredDropDown,
      setDropDown: setKytRequiredDropDown,
      styles: !role ? "pointer-events-none" : "",
    },
    {
      name: "acceptExactAmountsOnly",
      type: "select",
      label: "Accept Amounts Type",
      placeholder: "Accept Amounts Type",
      id: "acceptExactAmountsOnly",
      e2e: "accept-amount-type-expand",
      e2e1: "accept-amount-type-contract",
      options: [
        {
          label: "Accept Exact Amounts Only",
          value: "Accept Exact Amounts Only",
        },
        { label: "Accept Any Amounts", value: "Accept Any Amounts" },
      ],
      value: updateMerchantData.acceptExactAmountsOnly || [],
      dropDown: accpetAmountDropDown,
      setDropDown: setAcceptAmountDropDown,
    },
    {
      name: "rateExpiryPeriod",
      type: "select",
      label: "Rate Expiry Period",
      placeholder: "Rate Expiry Period",
      id: "rateExpiryPeriod",
      e2e: "rate-expiried-period-expand",
      e2e1: "rate-expiried-period-contract",
      disableField: adminRole === "PaymentProviderAdmin" ? true : false,
      options: [
        {
          value: "5",
          label: "5 mins",
        },
        {
          value: "10",
          label: "10 mins",
        },
        {
          value: "30",
          label: "30 mins",
        },
        {
          value: "60",
          label: "60 mins",
        },
      ],
      value: updateMerchantData.rateExpiryPeriod || [],
      dropDown: rateExpiredDropDown,
      setDropDown: setRateExpiredDropDown,
      styles: !role ? "pointer-events-none" : "",
    },

    {
      name: "merchantWalletActivation",
      type: "select",
      label: "Merchant Wallets Activation",
      placeholder: "Merchant Wallets Activation",
      id: "rateExpiryPeriod",
      e2e: "merchant-walletactivation-expand",
      e2e1: "merchant-walletactivation-contract",
      options: [
        { label: "Enable", value: "Enable" },
        { label: "Disable", value: "Disable" },
      ],
      value: updateMerchantData.merchantWalletActivation || [],
      dropDown: merchantWalletsDropDown,
      disableField: adminRole === "PaymentProviderAdmin" ? true : false,
      setDropDown: setMerchantWalletsDropDown,
      styles: !role ? "pointer-events-none" : "",
    },
    {
      name: "userWalletDeposit",
      type: "select",
      label: "User Wallet Deposits",
      placeholder: "User Wallet Deposits",
      id: "userWalletDeposit",
      e2e: "user-wallet-expand",
      e2e1: "user-wallet-contract",
      options: [
        { label: "Enable", value: "Enable" },
        { label: "Disable", value: "Disable" },
      ],
      value: updateMerchantData.userWalletDeposit || [],
      dropDown: userWalletDropDown,
      setDropDown: setUserWalletDropDown,
    },
    {
      name: "merchantWithdrawalAutoApproval",
      type: "select",
      label: "Merchant Withdrawal Auto-Approval",
      placeholder: "Merchant Withdrawal Auto-Approval",
      id: "merchantWithdrawalAutoApproval",
      e2e: "merchant-withdrawal-auto-approval-expand",
      e2e1: "merchant-withdrawal-auto-approval-contract",
      options: [
        { label: "Enable", value: "Enable" },
        { label: "Disable", value: "Disable" },
      ],
      value: updateMerchantData.merchantWithdrawalAutoApproval || [],
      dropDown: merchantAutoWithdrawalDropDown,
      setDropDown: setMerchantWithdrawalAutoApprovalDropDown,
    },
    {
      name: "buyCryptoScreenActive",
      type: "select",
      label: "Buy Crypto Screen",
      placeholder: "Buy Crypto Screen",
      id: "buyCryptoScreenActive",
      options: [
        { label: "Enable", value: "Enable" },
        { label: "Disable", value: "Disable" },
      ],
      value: updateMerchantData.buyCryptoScreenActive || [],
      dropDown: buyCryptoScreenDropDown,
      setDropDown: setBuyCryptoScreenDropDown,
    },
    {
      name: "depositTransactionFee",
      type: "text",
      label: "Deposits Transaction Fee",
      placeholder: "Deposits Transaction Fee",
      id: "depositTransactionFee",
      e2e: "deposit-transaction-fee",
      value: updateMerchantData.depositTransactionFee || "",
    },
    {
      name: "withdrawalTransactionFee",
      type: "text",
      label: "Withdrawal Transaction Fee",
      placeholder: "Withdrawal Transaction Fee",
      id: "withdrawalTransactionFee",
      e2e: "withdrawal-transaction-fee",
      value: updateMerchantData.withdrawalTransactionFee || "",
    },
    {
      name: "transactionFeeOnFailure",
      type: "text",
      inputType: "number",
      label: "Transaction fee on failure (in cents)",
      placeholder: "Transaction fee on failure (in cents)",
      id: "transactionFeeOnFailure",
      e2e: "transaction-fee-on-failure",
      value: updateMerchantData?.transactionFeeOnFailure || "",
    },
    {
      name: "creditCardActive",
      type: "select",
      label: "Credit Card",
      placeholder: "Credit Card",
      id: "creditCardActive",
      e2e: "credit-card-expand",
      e2e1: "credit-card-contract",
      options: [
        { label: "Enable", value: "Enable" },
        { label: "Disable", value: "Disable" },
      ],
      value: updateMerchantData.creditCardActive || [],
      dropDown: isActiveCreditCardDropDown,
      setDropDown: setisActiveCreditCardDropDown,
    },
    {
      name: "depositByCardFixedFee",
      type: "text",
      inputType: "number",
      label: "DepositByCard Fixed Fee (in cents)",
      placeholder: "DepositByCard Fixed Fee (in cents)",
      id: "depositByCardFixedFee",
      e2e: "withdrawal-transaction-fee",
      value: updateMerchantData.depositByCardFixedFee || "",
      styles: !role && !cardSettings ? "option-display-none" : "",
    },
    {
      name: "depositByCardFee",
      type: "text",
      inputType: "number",
      label: "DepositByCard % (of deposit amount) Fee",
      placeholder: "DepositByCard % (of deposit amount) Fee",
      id: "depositByCardFee",
      e2e: "withdrawal-transaction-fee",
      value: updateMerchantData.depositByCardFee || "",
      styles: !role && !cardSettings ? "option-display-none" : "",
    },
    {
      name: "cardSettlementPeriod",
      type: "text",
      inputType: "number",
      label: "Card Settlement Period In Days",
      placeholder: "Card Settlement Period In Days",
      id: "cardSettlementPeriod",
      e2e: "withdrawal-transaction-fee",
      value: updateMerchantData.cardSettlementPeriod || "",
      styles: !role && !cardSettings ? "option-display-none" : "",
    },
    {
      name: "cardReservePeriod",
      type: "text",
      inputType: "number",
      label: "Card Reserve Period In Days",
      placeholder: "Card Reserve Period In Days",
      id: "cardReservePeriod",
      e2e: "withdrawal-transaction-fee",
      value: updateMerchantData.cardReservePeriod || "",
      styles: !role && !cardSettings ? "option-display-none" : "",
    },
    {
      name: "cardReservePercent",
      type: "text",
      inputType: "number",
      label: "Card Reserve Percent",
      placeholder: "Card Reserve Percent",
      id: "cardReservePercent",
      e2e: "withdrawal-transaction-fee",
      value: updateMerchantData.cardReservePercent || "",
      styles: !role && !cardSettings ? "option-display-none" : "",
    },
    {
      name: "creditLimit",
      type: "text",
      inputType: "number",
      label: " Credit Limit USD",
      placeholder: " Credit Limit USD",
      id: "creditLimit",
      e2e: "withdrawal-transaction-fee",
      value: updateMerchantData.creditLimit || "",
    },
    {
      name: "depositSpread",
      type: "text",
      inputType: "number",
      label: "Deposit Spread",
      placeholder: "Deposit Spread",
      id: "depositSpread",
      e2e: "withdrawal-transaction-fee",
      value: updateMerchantData.depositSpread || "",
    },
    {
      name: "withdrawalSpread",
      type: "text",
      inputType: "number",
      label: "Withdrawal Spread",
      placeholder: "Withdrawal Spread",
      id: "withdrawalSpread",
      e2e: "withdrawal-transaction-fee",
      value: updateMerchantData.withdrawalSpread || "",
    },
    {
      name: "googleAurth",
      type: "select",
      label: "2FAauthentication",
      placeholder: "2FAauthentication",
      id: "googleAurth",
      e2e: "2fa-expand",
      e2e1: "2fa-contract",
      options: [
        { label: "Enable", value: "Enable" },
        { label: "Disable", value: "Disable" },
      ],
      value: updateMerchantData.googleAurth || [],
      dropDown: googleAurthDropDown,
      setDropDown: setGoogleAurthDropDown,
    },
    {
      name: "cardSettlementPeriod1",
      type: "select",
      label: "Control Settlement Period",
      placeholder: "Control Settlement Period",
      id: "cardSettlementPeriod1",
      e2e: "control-settlement-period-expand",
      e2e1: "control-settlement-period-contract",
      options: [
        { label: "Enable", value: "Enable" },
        { label: "Disable", value: "Disable" },
      ],
      value: updateMerchantData.cardSettlementPeriod1 || "Disable",
      dropDown: controlledSettelmentDropDown,
      setDropDown: setControlledSettelmentDropDown,
    },
    // {
    //   name: "cardAcquireRouting",
    //   type: "select",
    //   label: "Card Aquirer Service Name",
    //   placeholder: "Card Aquirer Service Name",
    //   e2e: "card-aquirer-expand",
    //   e2e1: "card-acquirer-contract",
    //   id: "cardAcquireRouting",
    //   options:
    //     antiFraudServiceData.length > 0
    //       ? antiFraudServiceData.map((item) => ({
    //           label: item.acquireRoutingServiceName,
    //           value: item.acquireRoutingServiceName,
    //         }))
    //       : [{ label: "None", value: "None" }],
    //   value: updateMerchantData.cardAcquireRouting || [],
    //   dropDown: showCardServiceDropdown,
    //   setDropDown: setShowCardServiceDropdown,
    // },
    {
      name: "cardAcquirerRoutingNew",
      type: "select",
      label: "Card Aquirer Service Name New",
      placeholder: "Card Aquirer Service Name New",
      e2e: "card-aquirer-expand",
      e2e1: "card-acquirer-contract",
      id: "cardAcquirerRoutingNew",
      options:
        getAcquirerRoutingNewData?.length > 0
          ? getAcquirerRoutingNewData?.map((item) => ({
              label: item.acquirerRoutingServiceName,
              value: item._id,
            }))
          : [{ label: "None", value: "None" }],
      value:
        getAcquirerRoutingNewData?.find(
          (item) => item?._id === updateMerchantData?.cardAcquirerRoutingNew,
        )?.acquirerRoutingServiceName || [],
      dropDown: showCardServiceNewDropdown,
      setDropDown: setShowCardServiceNewDropdown,
    },
    {
      name: "liquidityProvider",
      type: "select",
      label: "Liquidity Provider",
      placeholder: "Liquidity Provider",
      id: "liquidityProvider",
      options: [
        { label: "Aquanow", value: constants?.AQUANOW },
        { label: "Kraken", value: constants?.KRAKEN },
      ],
      value: updateMerchantData.liquidityProvider || constants?.AQUANOW,
      dropDown: showLiquidityDropdown,
      setDropDown: setShowLiquidityDropdown,
    },
    {
      name: "fxFee",
      type: "text",
      inputType: "number",
      label: "FX Fee",
      placeholder: "FX Fee",
      id: "fxFee",
      value: updateMerchantData.fxFee,
    },
  ];
  const LabelName = ({ label }) => {
    return (
      <div className="FilterModalInputLabel update-merchant-label">{label}</div>
    );
  };
  return (
    <div className="update-merchant-settings">
      {updateMerchantFields.map(
        (
          {
            label,
            e2e,
            id,
            name,
            placeholder,
            type,
            value,
            dropDown,
            e2e1,
            options,
            setDropDown,
            styles,
            disableField,
            inputType="text"
          },
          index
        ) => {
          if (type === "text")
            return (
              <div key={index} className={styles}>
                <div className='FilterModalInputGroup flex-direction-row'>
                  <LabelName label={label} />
                  <div className="FilterModalInputDropdown merchant-update-input">
                    <input
                      placeholder={placeholder}
                      name={name}
                      value={value}
                      id={id}
                      onChange={(e) => setUpdateMerchantData({...updateMerchantData, [e.target.name]: e.target.value})}
                      data-e2e={e2e}
                      type={inputType}
                      disabled={disableField || false}
                      style={{ marginRight: name === 'fxFee' ? '4px' : '0' }}
                    />
                    {name === 'fxFee' && <span>%</span>}
                  </div>
                </div>
                <div className="horizontal-line"></div>
              </div>
            );
          return (
            <div className={styles} key={index}>
              {" "}
              <div className="FilterModalInputGroup flex-direction-row">
                <LabelName label={label} />

                <div
                  className="FilterModalInputDropdown"
                  onClick={() => {
                    if (!disableField) {
                      handleDropdownClick(setDropDown);
                    }
                  }}
                >
                  <div className="FilterModalInputText">{value}</div>
                  <i
                    className="fa fa-chevron-down FilterModalInputDropIcon"
                    data-e2e={e2e}
                  />
                </div>
                {dropDown && (
                  <div className="FilterModalCurrencyDropdownlist update-merchant-settings-dropdown">
                    <div
                      className="FM_currencyDrop_All"
                      onClick={() => {
                        handleDropdownClick(setDropDown, true);
                      }}
                    >
                      <span className="FM_currencyDrop_AllSelector">
                        {value}
                      </span>
                      <span className="FilterModalInputDropUpIconWrap">
                        <i
                          data-e2e={e2e1}
                          className="fa fa-chevron-up FilterModalInputDropUpIcon"
                        />
                      </span>
                    </div>
                    {name !== "cardAcquirerRoutingNew" && options?.map(({ label, value }, index) => {
                      return (
                        <div
                          key={index}
                          className="currencyListItemWrap"
                          onClick={() => {
                            setUpdateMerchantData({
                              ...updateMerchantData,
                              [name]: value,
                            });
                            handleDropdownClick(setDropDown, true);
                          }}
                        >
                          <div className="currencyListIconNameWrap">
                            <div
                              className="currencyList_Name"
                              data-e2e={value
                                ?.toLowerCase()
                                .replaceAll(" ", "-")}
                            >
                              {value}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {name === "cardAcquirerRoutingNew" && options?.map(({ label, value }, index) => {
                      return (
                        <div
                          key={index}
                          className="currencyListItemWrap"
                          onClick={() => {
                            setUpdateMerchantData({
                              ...updateMerchantData,
                              [name]: value,
                            });
                            handleDropdownClick(setDropDown, true);
                          }}
                        >
                          <div className="currencyListIconNameWrap">
                            <div
                              className="currencyList_Name"
                              data-e2e={value
                                ?.toLowerCase()
                                .replaceAll(" ", "-")}
                            >
                              {label}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="horizontal-line"></div>
            </div>
          );
        }
      )}
      <div className="FilterModalInputGroup flex-direction-row">
        <LabelName label="Crypto Currency" />
        <div
          className="FilterModalInputDropdown"
          onClick={() => {
            handleDropdownClick(setShowCurrencyDropdown);
          }}
        >
          <div className="FilterModalInputText">
            {selectedCryptos.length === cryptos.length ||
            selectedCryptos.length === 0
              ? "All"
              : `${selectedCryptos.length} Selected`}
          </div>
          <i
            className="fa fa-chevron-down FilterModalInputDropIcon"
            data-e2e="crypto-currency-expand"
          />
        </div>
        {showCurrencyDropdown && (
          <div className="FilterModalCurrencyDropdownlist update-merchant-settings-dropdown">
            <div
              className="FM_currencyDrop_All"
              onClick={() => {
                setShowCurrencyDropdown(false);
              }}
            >
              <span
                className="FM_currencyDrop_AllSelector"
                data-e2e={
                  selectedCryptos.length === cryptos.length ||
                  selectedCryptos.length === 0
                    ? "All"
                    : `${selectedCryptos.length} Selected`
                }
              >
                {selectedCryptos.length === cryptos.length ||
                selectedCryptos.length === 0
                  ? "All"
                  : `${selectedCryptos.length} Selected`}
              </span>
              <span className="FilterModalInputDropUpIconWrap">
                <i
                  data-e2e="crypto-currency-contract"
                  className="fa fa-chevron-up FilterModalInputDropUpIcon"
                />
              </span>
            </div>
            {cryptos.map((crypto, index) => {
              let { name, symbol, icon } = crypto;
              return (
                <div
                  key={index}
                  className="currencyListItemWrap"
                  onClick={() => {
                    toggleSelection(symbol);
                  }}
                >
                  <div className="currencyListIconNameWrap">
                    <img
                      src={`${image_base_url}${icon}`}
                      alt={symbol}
                      className="currencyList_Icon"
                    />
                    <div
                      className="currencyList_Name"
                      data-e2e={name.toLowerCase().replaceAll(" ", "-")}
                    >
                      {name}
                    </div>
                  </div>
                  <img
                    src={`${image_base_url}${
                      selectedCryptos.includes(symbol)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                    }`}
                    alt="checkbox"
                    className="currencyListCheckBox"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="horizontal-line"></div>
      <div className="login_button_get_started merchant-update-button">
      <button
      onClick={isViewPermissionValid ? null : () => handleUpdateSetting()}
      data-e2e="update-settings-btn"
      className={isViewPermissionValid ? 'disabled' : ''}
    >
      Update Settings
    </button>
      </div>
      {successAlert && (
        <Stack className="position-fixed successAlert" spacing={2}>
          <Alert
            icon={
              <img
                src={`${image_base_url}${tick_green}`}
                alt="tick_green"
                width={32}
              />
            }
            onClose={() => {
              setSuccessAlert(false);
            }}
            className="MuiAlert-root"
          >
            {alertMsg}
          </Alert>
        </Stack>
      )}
      {loading && <Loading />}
    </div>
  );
}

export default UpdateMerchant;
