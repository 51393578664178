import React from "react";
import { Table } from "reactstrap";
import moment from "moment";
import { SortByIcon } from "../../../common/CommonUtils";


const RealTimeExchangeRatesTable = (props) => {
  return (
    <div className="TransactionTableMain">
      {props?.data?.length > 0 ? (
        <Table responsive style={{ border: "none" }}>
          <tbody className="TransactionTable_BODY merchant_table_text_align">
            <tr className="TransactionTable_BODY_Header">
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("cryptoId");
                }}
                data-e2e="cryptoId"
              >
                CryptoID{" "}
                {props.sortBy === "cryptoId" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="cryptoId" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("price");
                }}
                data-e2e="price"
              >
                Price (USD){" "}
                {props.sortBy === "price" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="price" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("date");
                }}
                data-e2e="date"
              >
                Date{" "}
                {props.sortBy === "date" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="date" />
                )}
              </th>
            </tr>
            {props && props.data && props.data.map((TData, i) => {
              return <SingleTransactionTableRow data={TData} key={i} />;
            })}
          </tbody>
        </Table>
      ) : (
        <div className="No_Transactions_Found" data-e2e="No_Transactions_Found">
          No Data Found
        </div>
      )}
      {props.showMoreButton ? (
        <div className="Show_more_wrap">
          <div
            className="Show_more"
            data-e2e="showMore"
            onClick={() => {
              props.handleShowMore();
            }}
          >
            Show More
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RealTimeExchangeRatesTable;

const SingleTransactionTableRow = (props) => {

  let { data } = props;

  return (
    <tr className="CP_TableRow housekeeping-table-row">
      <td className="transactionsTable_item">{data && data.cryptoId && data.cryptoId || "N/A"}</td>
      <td className="transactionsTable_item">{data && data.price && data.price || 0}</td>
      <td className="transactionsTable_item" data-e2e={"date"}>
      {data && data.date && data.date
            ? `${moment(data.date).format("DD.MM.YYYY")} (${moment(data.date).format("HH:mm")})`
            : "N/A"}
      </td>
    </tr>
  );
};
