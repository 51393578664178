import { MenuItem, Select, styled } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
const closeImage = '/cryptonpay/crytoicons/close.png';

const CreateClientAccount = (props) => {
  const { handleCloseAddNew, setEmail, getMerchantWithEmail, feildsData } = props
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [data, setData] = useState([]);
  const [errorMsg, setErrorMsg] = useState({});

  const validatePassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      setErrorMsg((prevData) => ({
        ...prevData,
        ["confirm_password"]: "Passwords do not match!",
      }));
    } else {
      setErrorMsg((prevData) => {
        const updatedData = { ...prevData };
        delete updatedData["confirm_password"];
        return updatedData;
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "clientEmail") {
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (regex.test(e.target.value) === false) {
        setErrorMsg((prevData) => ({
          ...prevData,
          ["clientEmail"]: "Invalid email!",
        }));
      }
      else {
        setErrorMsg((prevData) => {
          const updatedData = { ...prevData };
          delete updatedData["clientEmail"];
          return updatedData;
        });
      }
    }
    if (e.target.name === "password") {
      validatePassword(e.target.value, data["confirm_password"])
    }
    if (e.target.name === "confirm_password") {
      validatePassword(data["password"], e.target.value)
    }
    setData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
  };
  return (
    <div className="create_account_container">
      <div className="create_account_header">
        <div className="create_account_heading">Create Client</div>
        <div
          className="filter_close_button"
          onClick={() => handleCloseAddNew()}
        >
          <img src={`${image_base_url}${closeImage}`} alt="closeImage" />
        </div>
      </div>

      <div className="create_account_form">
        {feildsData &&
          feildsData.map((item, index) => {
            return (
              <div style={{ margin: '20px 0px' }} key={index}>
                {item.input_type === 'input_box' && (
                  <div key={index}>
                    <label>
                      <b>{item.label}</b>
                    </label>
                    <input
                      className="create_account_input"
                      type={item.type}
                      id={item.id}
                      name={item.name}
                      value={(data && data[item.name]) || item.value || ''}
                      placeholder={item.placeholder}
                      data-e2e={item.placeholder}
                      onChange={(e) => handleChange(e)}
                      autoComplete={item.autocomplete}
                      required
                    />
                  </div>
                )}
                {errorMsg && errorMsg[`${item.name}`] && (
                  <div style={{ color: 'red' }}>{errorMsg[`${item.name}`]}</div>
                )}
                {item.input_type === 'input_select' && (
                  <div>
                    <label>
                      <b>{item.label}</b>
                    </label>
                    <Select
                      variant="outlined"
                      style={{
                        borderRadius: '50px',
                        width: "100%",
                        padding: "0px",
                        marginTop: "0px",
                      }}
                      defaultValue={item.label === "STATUS" ? true : false}
                      id={item.id}
                      name={item.name}
                      value={(data && data[item.name]) || item.value}
                      disabled={item.disabled ? true : false}
                      placeholder={item.placeholder}
                      data-e2e={item.placeholder}
                      onChange={(e) => handleChange(e)}
                      autoComplete={item.autocomplete}
                      required>
                      {item.options && item.options.map((opt) => <MenuItem data-e2e={`${item.name}-${opt.value}`} key={opt.label} value={opt.value}>{opt.label}</MenuItem>)}
                    </Select>
                  </div>
                )}
                {item.input_type === 'input_button' && (
                  <div className="">
                    {props.error &&
                      <div style={{ color: 'red' }}>{props.error}</div>
                    }
                    <div
                      className="login_button_get_started"
                      key={index}
                      data-e2e="login_button_get_started"
                    >
                      <button
                        onClick={() => item.onClick(data)}
                        data-e2e={item.name}
                      >
                        {item.name}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default CreateClientAccount;
