import React, { useState } from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import TransactionView from './UserTransactionView';
import { useParams} from 'react-router-dom'

function TransactionDetails() {
  const [id, setId] = useState('');

  const sub_name_last = `Transaction ID: ${id}`;
  const handleId = (id) => {
    setId(id);
  };

  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
        <CommonHeader
          backButton={true}
          name={sub_name_last}
          copyButton={true}
          fullId={useParams().id}
        />
        <div>
          <TransactionView view = {"User Transactions"} handleId={handleId} />
        </div>
      </HeaderAndFooterLayout>
    </div>
  );
}

export default TransactionDetails;
