// AggregateContent.js
import React from "react";
import { Box, Typography, Paper } from "@mui/material";

function AggregateContent({ data }) {
  return (
    <Paper
      sx={{
        padding: 3,
        borderRadius: 1,
        boxShadow: "none",
        border: "1px solid #E0E0E0",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Transaction Summary
      </Typography>
      <Box sx={{ marginTop: 2 }}>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 1,
            borderBottom: "1px solid #E0E0E0",
            paddingBottom: 1,
          }}
        >
          Average Transaction Value:{" "}
          <strong>$ {data?.averageTransactionValue}</strong>
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 1,
            borderBottom: "1px solid #E0E0E0",
            paddingBottom: 1,
          }}
        >
          Maximum Transaction Value:{" "}
          <strong>$ {data?.maxTransactionValue}</strong>
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 1,
            borderBottom: "1px solid #E0E0E0",
            paddingBottom: 1,
          }}
        >
          Minimum Transaction Value:{" "}
          <strong>$ {data?.minTransactionValue}</strong>
        </Typography>
      </Box>
    </Paper>
  );
}

export default AggregateContent;
