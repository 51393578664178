import EditIcon from '@mui/icons-material/Edit';
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  DELETE_CRYPTO,
  DELETE_GLOBAL_SETTING,
  DELETE_WHITELIST_IP,
  GET_All_FEATURE_CONFIG,
  GET_CRYPTOS_DETAILS,
  GET_GLOBAL_SETTING,
  GET_WHITELIST_IPS,
  UPDATE_CRYPTO,
  UPDATE_FEATURE_CONFIG,
} from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";
import NavTabs from "../../../common/NavTabs";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import EditAddGlobalSettings from "../GlobalSettings/EditAddGlobalSettings";
import AddNewCrypto from "./AddNewCrypto";
import AddNewGlobalSettings from "./AddNewGlobalSettings";
import AddNewWhiteListIP from "./AddNewWhiteListIP";
import EditCrypto from "./EditCrypto";
import EditWhiteListIP from "./EditWhiteListIP";
import FeatureConfig from "./FeatureConfig";
import { isViewPermissionValid } from '../../../common/CommonUtils';
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";

const tableHeaders = [
  {
    header_name: "BLOCKCHAIN",
  },
  {
    header_name: "CRYPTO",
  },
  {
    header_name: "NETWORK",
  },
  {
    header_name: "Max Capacity (Approx USD) Threshold",
  },
  {
    header_name: "MIN-MAX WALLETS",
  },
  {
    header_name: "DEPOSIT TIMER",
  },
  {
    header_name: "",
  },
];

const cryptoHeaders = [
  {
    name: "Crypto ID",
  },
  {
    name: "Crypto Name",
  },
  {
    name: "Blockchain",
  },
  {
    name: "Icon",
  },
  {
    name: "Fiat Settlement Trade",
  },
  {
    name: "Status",
  },
  {
    name: "Increment Size",
  },
  {
    name: "CONTRACT ADDRESS",
    width: "20%",
  },
  {
    name: "",
  },
];

const whiteListHeaders = [
  {
    name: "Merchant Names",
    width: "35%",
  },
  {
    name: "IP Address",
    width: "30%",
  },
  {
    name: "status",
    width: "25%",
  },
  {
    name: "",
    width: "10%",
  },
];

const featureConfigHeaders = [
  {
    name: "Feature Name",
    width: "35%",
  },
  {
    name: "Status",
    width: "35%",
  },
  {
    name: "",
    width: "30%",
  },
];

const tabs = [
  {
    name: "Wallets",
    id: 1,
  },
  {
    name: "Crypto",
    id: 2,
  },
  {
    name: "IP Address Whitelist",
    id: 3,
  },
  {
    name: "Feature Configurations",
    id: 4,
  }
];

class GlobalSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSetting: null,
      WhiteListIPs: null,
      showAddNewModal: false,
      showAddNewIPModal: false,
      deletingId: null,
      deletingCryptoId: null,
      deletingWhiteListId: null,
      selectedTab: tabs[0],
      editWhiteListIPData: null,
      showEditWhiteListIPModal: false,
      editCryptoData: null,
      showEditCryptoModal: false,
      loading: false,
      featureConfigList: null,
      editGlobalSetting: false,
      editData: null,
    };
  }
  componentDidMount() {
    this.getGlobalSetting();
    this.getWhiteListIPs();
    this.getCryptos();
    this.getAllFeatureConfigList();
  }

  handleEditGlobalSettings = (data) => {
    this.setState({ editGlobalSetting: true, editData: data });
  };
  handleCloseEditGlobalSettings = () => {
    this.setState({ editGlobalSetting: false, editData: null });
  };


  getGlobalSetting = async () => {
    this.setState({ loading: true });
    let payload = {
      email: localDb.getVal("email"),
    };
    await call(
      {
        ...GET_GLOBAL_SETTING,
        url: this.props.base_url + GET_GLOBAL_SETTING.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            globalSetting: res.data.globalSettings,
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  getWhiteListIPs = async () => {
    this.setState({ loading: true });
    let payload = {
      email: localDb.getVal("email"),
    };
    await call(
      {
        ...GET_WHITELIST_IPS,
        url: this.props.base_url + GET_WHITELIST_IPS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            whiteListDate: res.data.WhiteListIPs,
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  getAllFeatureConfigList = async () => {
    this.setState({ loading: true });
    let payload = {
      email: localDb.getVal("email"),
    };
    await call(
      {
        ...GET_All_FEATURE_CONFIG,
        url: this.props.base_url + GET_All_FEATURE_CONFIG.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            featureConfigList: res.data ? res.data : [],
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  updateFeatureConfig = async (featureName, enabled) => {
    this.setState({ loading: true });
    let payload = {
      email: localDb.getVal("email"),
      featureName,
      enabled,
    };
    await call(
      {
        ...UPDATE_FEATURE_CONFIG,
        url: this.props.base_url + UPDATE_FEATURE_CONFIG.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          let newFeatureConfigList = this.state.featureConfigList.map(
            (feature) => {
              if (feature.featureName === featureName)
                return { ...feature, enabled };
              else return feature;
            }
          );
          this.setState({
            featureConfigList: newFeatureConfigList,
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  getCryptos = async () => {
    this.setState({ loading: true });
    let payload = {
      email: localDb.getVal("email"),
    };
    await call(
      {
        ...GET_CRYPTOS_DETAILS,
        url: this.props.base_url + GET_CRYPTOS_DETAILS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({ cryptoDate: res.data.cryptos, loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  deleteSettings = async () => {
    this.setState({ loading: true });
    let payload = {
      email: localDb.getVal("email"),
      id: this.state.deletingId,
    };
    await call(
      {
        ...DELETE_GLOBAL_SETTING,
        url: this.props.base_url + DELETE_GLOBAL_SETTING.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({ deletingId: null, loading: false });
          this.getGlobalSetting();
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  deleteWhiteListIP = async () => {
    this.setState({ loading: true });
    let payload = {
      email: localDb.getVal("email"),
      id: this.state.deletingWhiteListId,
    };
    await call(
      {
        ...DELETE_WHITELIST_IP,
        url: this.props.base_url + DELETE_WHITELIST_IP.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            deletingWhiteListId: null,
            showEditWhiteListIPModal: false,
            loading: false,
          });
          this.getWhiteListIPs();
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  deleteCrypto = async () => {
    this.setState({ loading: true });
    let payload = {
      email: localDb.getVal("email"),
      id: this.state.deletingCryptoId,
    };
    await call(
      {
        ...DELETE_CRYPTO,
        url: this.props.base_url + DELETE_CRYPTO.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            deletingCryptoId: null,
            showEditCryptoModal: false,
            loading: false,
          });
          this.getCryptos();
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  handleEditWhiteListIP = (data) => {
    this.setState({
      editWhiteListIPData: data,
      showEditWhiteListIPModal: true,
    });
  };

  handleEditCrypto = (data) => {
    this.setState({
      editCryptoData: data,
      showEditCryptoModal: true,
    });
  };


  render() {
    return (
      <HeaderAndFooterLayout>
        <CommonHeader name={`Merchant Settings`} />
        <div className="merchant_dailog_screen edit-dailog_screen">
          <Dialog
            open={this.state.editGlobalSetting || false}
            onClose={this.handleCloseGlobalSetting}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="edit-dailog_screen"
            maxWidth={"md"}
          >
            <div>
              <EditAddGlobalSettings
                editData={this.state.editData}
                getGlobalSetting={this.getGlobalSetting}
                handleCloseEditGlobalSettings={
                  this.handleCloseEditGlobalSettings
                }
              />
            </div>
          </Dialog>
        </div>
        <div>
          <div className="form_wrap merchant-setting">
            <div className="card loginSignupCard GlobalSettingsTable table-shadow">
              <div className="pt-0">
                <NavTabs
                  tabs={tabs}
                  selectedTab={this.state.selectedTab}
                  changeTab={(tab) => {
                    this.setState({ selectedTab: tab });
                  }}
                />
                <div
                  className="TransactionTableMain"
                  style={{
                    marginTop: "-1.8px",
                    marginBottom: "0px",
                  }}
                >
                  {this.state.selectedTab.id === 1 && (
                    <div>
                      {this.state.globalSetting &&
                        this.state.globalSetting.length > 0 ? (
                        <Table responsive style={{ border: "none" }}>
                          <tbody className="TransactionTable_BODY">
                            <tr>
                              {tableHeaders.map((item, index) => (
                                <th
                                  className="GBtableHeading pl-0 pr-0"
                                  width={item.header_width}
                                  key={index}
                                  data-e2e={item.header_name}
                                >
                                  {item.header_name}
                                </th>
                              ))}
                            </tr>
                            {this.state.globalSetting &&
                              this.state.globalSetting.map((item, index) => (
                                <tr key={index}>
                                  <td className="table_item merchant_table_font text-black">
                                    {item.cryptoId && item.cryptoId.blockchain
                                      ? `${item.cryptoId.blockchain}`
                                      : "N/A"}
                                  </td>
                                  <td className="table_item merchant_table_font text-black">
                                    {item.cryptoId
                                      ? `${item.cryptoId.name}(${item.cryptoId.symbol})`
                                      : "N/A"}
                                  </td>
                                  <td className="table_item merchant_table_font text-black">
                                    {item.network && item.network !== ""
                                      ? item.network
                                      : "N/A"}
                                  </td>
                                  <td className="table_item merchant_table_font text-black">
                                    {(item.minCapacityThreshold ||
                                      item.minCapacityThreshold === 0) &&
                                      (item.maxCapacityThreshold ||
                                        item.maxCapacityThreshold === 0)
                                      ? `${item.minCapacityThreshold}-${item.maxCapacityThreshold}`
                                      : "N/A"}
                                  </td>
                                  <td className="table_item merchant_table_font text-black">
                                    {item.minimunWallets ||
                                      item.minimunWallets === 0
                                      ? item.minimunWallets
                                      : "N/A"}
                                    -
                                    {item.maximumWallets ||
                                      item.maximumWallets === 0
                                      ? item.maximumWallets
                                      : "N/A"}
                                  </td>
                                  <td className="table_item merchant_table_font text-black">
                                    {item.depositTimer
                                      ? item.depositTimer
                                      : "N/A"}
                                  </td>
                                  <td className="table_item merchant_table_font text-black">
                                    <EditIcon
                                      fontSize="large"
                                      color='action'
                                      data-e2e="edit_button"
                                      className={`${isViewPermissionValid? "disabled": ""} cursor-pointer pr-3`}
                                      onClick={() => isViewPermissionValid? null: (
                                        this.handleEditGlobalSettings({
                                          ...item,
                                          blockchain: item?.cryptoId?.blockchain
                                            ? item?.cryptoId?.blockchain
                                            : "",
                                          cryptoId: item?.cryptoId?._id,
                                          cryptoName: `${item?.cryptoId?.name}(${item?.cryptoId?.symbol})`,
                                        })
                                     )}
                                    />
                                    <span
                                      className="cursor-pointer"
                                      data-e2e="close_icon"
                                    >
                                      <CloseIcon
                                      className={`${isViewPermissionValid? "disabled": ""}`}
                                        onClick={() => isViewPermissionValid? null: 
                                          this.setState({
                                            deletingId: item?._id,
                                          })
                                        }
                                        fontSize="small"
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="NoDataFound">No Data Found</div>
                      )}
                    </div>
                  )}
                  {this.state.selectedTab.id === 2 && (
                    <Crypto
                      cryptoHeaders={cryptoHeaders}
                      getCryptos={this.getCryptos}
                      cryptoDate={this.state.cryptoDate}
                      image_base_url={this.props.image_base_url}
                      base_url={this.props.base_url}
                      changeDeletingCryptoId={(id) => {
                        this.setState({ deletingCryptoId: id });
                      }}
                      handleEditCrypto={this.handleEditCrypto}
                    />
                  )}
                  {this.state.selectedTab.id === 3 && (
                    <Whitelist
                      whiteListHeaders={whiteListHeaders}
                      whiteListDate={this.state.whiteListDate}
                      changeDeletingWhiteListId={(id) => {
                        this.setState({ deletingWhiteListId: id });
                      }}
                      handleEditWhiteListIP={this.handleEditWhiteListIP}
                    />
                  )}
                  {this.state.selectedTab.id === 4 && (
                    <FeatureConfig
                      featureConfigHeaders={featureConfigHeaders}
                      featureConfigList={this.state.featureConfigList}
                      updateFeatureConfig={this.updateFeatureConfig}
                    />
                  )}
                </div>

                {this.state.selectedTab.id != 4 && (
                  <div className="d-flex justify-content-center p-3">
               <button
                  type="button"
                  className={`${isViewPermissionValid? "disabled": ""} btn btn-primary btn-md rounded-pill pl-5 pr-5 pt-2 pb-2`}
                  data-e2e="add-new-btn"
                  onClick={() => {
                    if (!isViewPermissionValid) {
                      if (this?.state?.selectedTab?.id === 1) {
                        this.setState({ showAddNewModal: true });
                      } else if (this?.state?.selectedTab?.id === 2) {
                        this.setState({ showAddNewCrypto: true });
                      } else if (this?.state?.selectedTab?.id === 3) {
                        this.setState({ showAddNewIPModal: true });
                      }
                    }
                  }}
                >
                  Add New
                </button>
                  </div>
                )}
                {
                  <div className="merchant_dailog_screen">
                    {this.state.selectedTab.id === 1 && (
                      <Dialog
                        open={this.state.showAddNewModal || false}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth={"md"}
                      >
                        <div>
                          <AddNewGlobalSettings
                            getGlobalSetting={this.getGlobalSetting}
                            handleClose={() => {
                              this.setState({ showAddNewModal: false });
                            }}
                          />
                        </div>
                      </Dialog>
                    )}
                    {this.state.selectedTab.id === 2 && (
                      <Dialog
                        open={this.state.showAddNewCrypto || false}
                        onClose={() => {
                          this.setState({ showAddNewCrypto: false });
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth={"md"}
                      >
                        <div>
                          <AddNewCrypto
                            getCryptos={this.getCryptos}
                            handleClose={() => {
                              this.setState({ showAddNewCrypto: false });
                            }}
                          />
                        </div>
                      </Dialog>
                    )}
                    {this.state.selectedTab.id === 2 &&
                      this.state.editCryptoData && (
                        <Dialog
                          open={this.state.showEditCryptoModal || false}
                          onClose={() => {
                            this.setState({
                              showEditCryptoModal: false,
                              deletingCryptoId: null,
                            });
                          }}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          maxWidth={"md"}
                        >
                          <div>
                            <EditCrypto
                              handleDelete={(id) => {
                                this.setState({ deletingCryptoId: id });
                              }}
                              editData={this.state.editCryptoData}
                              getCryptos={this.getCryptos}
                              handleClose={() => {
                                this.setState({
                                  showEditCryptoModal: false,
                                  deletingCryptoId: null,
                                });
                              }}
                            />
                          </div>
                        </Dialog>
                      )}
                    {this.state.selectedTab.id === 3 && (
                      <Dialog
                        open={this.state.showAddNewIPModal || false}
                        onClose={() => {
                          this.setState({ showAddNewIPModal: false });
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth={"md"}
                      >
                        <div>
                          <AddNewWhiteListIP
                            getWhiteListIPs={this.getWhiteListIPs}
                            handleClose={() => {
                              this.setState({ showAddNewIPModal: false });
                            }}
                          />
                        </div>
                      </Dialog>
                    )}
                    {this.state.selectedTab.id === 3 &&
                      this.state.editWhiteListIPData && (
                        <Dialog
                          open={this.state.showEditWhiteListIPModal || false}
                          onClose={() => {
                            this.setState({
                              showEditWhiteListIPModal: false,
                              deletingWhiteListId: null,
                            });
                          }}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          maxWidth={"md"}
                        >
                          <div>
                            <EditWhiteListIP
                              handleDelete={(id) => {
                                this.setState({ deletingWhiteListId: id });
                              }}
                              editData={this.state.editWhiteListIPData}
                              getWhiteListIPs={this.getWhiteListIPs}
                              handleClose={() => {
                                this.setState({
                                  showEditWhiteListIPModal: false,
                                  deletingWhiteListId: null,
                                });
                              }}
                            />
                          </div>
                        </Dialog>
                      )}
                    {this.state.selectedTab.id === 3 &&
                      this.state.editWhiteListIPData && (
                        <Dialog
                          open={this.state.showEditWhiteListIPModal || false}
                          onClose={() => {
                            this.setState({
                              showEditWhiteListIPModal: false,
                              deletingWhiteListId: null,
                            });
                          }}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          maxWidth={"md"}
                        >
                          <div>
                            <EditWhiteListIP
                              handleDelete={(id) => {
                                this.setState({ deletingWhiteListId: id });
                              }}
                              editData={this.state.editWhiteListIPData}
                              getWhiteListIPs={this.getWhiteListIPs}
                              handleClose={() => {
                                this.setState({
                                  showEditWhiteListIPModal: false,
                                  deletingWhiteListId: null,
                                });
                              }}
                            />
                          </div>
                        </Dialog>
                      )}
                    <DeletingAlert
                      deletingId={
                        this.state.deletingId
                          ? this.state.deletingId
                          : this.state.deletingWhiteListId
                            ? this.state.deletingWhiteListId
                            : this.state.deletingCryptoId
                              ? this.state.deletingCryptoId
                              : this.state.deletingAntiFraudServiceId
                                ? this.state.deletingAntiFraudServiceId
                                : null
                      }
                      onHide={() => {
                        if (this.state.selectedTab.id === 1) {
                          this.setState({ deletingId: null });
                        } else if (this.state.selectedTab.id === 2) {
                          this.setState({ deletingCryptoId: null });
                        } else if (this.state.selectedTab.id === 3) {
                          this.setState({ deletingWhiteListId: null });
                        }
                      }}
                      image_base_url={this.props.image_base_url}
                      msg="Are you sure you want to delete selected setting? This action cannot be undone."
                      onDeleteClickHandler={() => {
                        if (this.state.selectedTab.id === 1) {
                          this.deleteSettings();
                        } else if (this.state.selectedTab.id === 2) {
                          this.deleteCrypto();
                        } else if (this.state.selectedTab.id === 3) {
                          this.deleteWhiteListIP();
                        }
                      }}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
          {this.state.loading && <Loading />}
        </div>
      </HeaderAndFooterLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    base_url: state.config.api_url,
    image_base_url: state.config.image_base_url,
  };
};

export default connect(mapStateToProps, null)(GlobalSetting);

const Whitelist = (props) => {
  const { whiteListHeaders, whiteListDate, handleEditWhiteListIP } = props;

  const getStatus = (status) => {
    switch (status) {
      case false:
        return <div className="DisabledStatus">DISABLED</div>;
      case true:
        return <div className="EnabledStatus">ENABLED</div>;
    }
  };

  return (
    <div className="ScrollBarStyle">
      {whiteListDate && whiteListDate.length > 0 ? (
        <Table responsive style={{ border: "none" }}>
          <tbody className="TransactionTable_BODY">
            <tr>
              {whiteListHeaders.map((item, index) => (
                <th
                  className="GBtableHeading pl-0 pr-0"
                  style={{ width: item.width }}
                  key={index}
                  data-e2e={item.name}
                >
                  {item.name}
                </th>
              ))}
            </tr>
            {whiteListDate &&
              whiteListDate.map((item, index) => (
                <tr key={index}>
                  <td className="table_item merchant_table_font pl-0 pr-0 text-black">
                    {item.merchant && item.merchant.name
                      ? item.merchant.name
                      : "N/A"}
                  </td>
                  <td className="table_item merchant_table_font pl-0 pr-0 text-black">
                    {item.ipAddress ? item.ipAddress : "N/A"}
                  </td>
                  <td className="table_item merchant_table_font pl-0 pr-0">
                    {item.status !== true || item.status !== false
                      ? getStatus(item.status)
                      : "N/A"}
                  </td>
                  <td
                    className="table_item merchant_table_font pl-0 pr-0 text-black"
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <EditIcon
                      fontSize="large"
                      color='action'
                      className={`${isViewPermissionValid? "disabled": ""} cursor-pointer pr-3`}
                      data-e2e="ip_edit_icon"
                      onClick={() => isViewPermissionValid? null: 
                        handleEditWhiteListIP({
                          ...item,
                        })
                      }
                    />
                    <span className="cursor-pointer" data-e2e="ip_delete_icon">
                      <CloseIcon
                        className={`${isViewPermissionValid? "disabled": ""}`}
                        onClick={() => isViewPermissionValid? null: 
                          props.changeDeletingWhiteListId(item?._id)
                        }
                        fontSize="small"
                      />
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <div className="NoDataFound">No Data Found</div>
      )}
    </div>
  );
};

const Crypto = (props) => {
  const {
    cryptoHeaders,
    handleEditCrypto,
    cryptoDate,
    image_base_url,
    base_url,
    getCryptos,
  } = props;
  const selectedCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_filled.png";
  const emptyCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_empty.png";
  const closeImage = "/cryptonpay/crytoicons/close.png";
  const [editContractAddresss, setEditContractAddress] = useState(false);
  const [contractAddress, setContractAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [cryptoId, setCryptoId] = useState("");
  const [transactionInformation, setTransactionInformation] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOpenEditContractAddress = (index, id, add) => {
    setContractAddress(add);
    setCryptoId(id);
    setEditContractAddress(true);
  };

  const handleCloseEditContractAddress = () => {
    setEditContractAddress(false);
  };

  const handleUpdateContractAddress = async (payload) => {
    setLoading(true);
    await call(
      {
        ...UPDATE_CRYPTO,
        url: base_url + UPDATE_CRYPTO.url,
      },
      payload
    )
      .then((res) => {
        setLoading(false);
        getCryptos();
        setEditContractAddress(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        alert("Failed to Update!");
        logoutAfterError(err)
      });
  };

  const getStatus = (status) => {
    switch (status) {
      case false:
        return <div className="DisabledStatus">DISABLED</div>;
      case true:
        return <div className="EnabledStatus">ENABLED</div>;
    }
  };

  const handleDeleteContractAddress = (index, id, contactHistory) => {
    if (index >= 0 && index < contactHistory.length) {
      const newArray = contactHistory.filter((_, i) => i !== index);
      const payload = {
        type: "delete_address",
        email: localDb.getVal("email"),
        id,
        updatedContactAddresses: newArray,
      };
      handleUpdateContractAddress(payload)
    } else {
      console.error("Invalid index. Cannot delete item.");
    }
  }

  return (
    <div className="ScrollBarStyle">
      {cryptoDate && cryptoDate.length > 0 ? (
        <div>
          <div className="TransactionTable">
            <div className="singleRow__data">
              <div
                className={
                  transactionInformation
                    ? "transactionRow borderBottomNone"
                    : "transactionRow"
                }
              >
                {cryptoHeaders.map((item, index) => (
                  <div
                    key={index}
                    className="transaction_item_name GBtableHeading s merchant-setting-crypto-header"
                    data-e2e={item.name}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="TransactionTable">
            {cryptoDate &&
              cryptoDate.map((item, index) => {
                return (
                  <div className="singleRow__data" key={index}>
                    <div
                      className={
                        transactionInformation
                          ? "transactionRow borderBottomNone"
                          : "transactionRow"
                      }
                    >
                      <div className="transaction_item_name">
                        {item.cryptoId ? item.cryptoId : "N/A"}
                      </div>
                      <div className="transaction_item_name">
                        {item.name ? item.name : "N/A"}
                      </div>{" "}
                      <div className="transaction_item_name">
                        {item.blockchain ? item.blockchain : "N/A"}
                      </div>{" "}
                      <div className="transaction_item_name">
                        <img
                          src={`${image_base_url}${item.icon}`}
                          width="30"
                          height="30"
                          alt="icon"
                          data-e2e="icon"
                          className="text-center"
                        />
                      </div>{" "}
                      <div className="transaction_item_name">
                        <img
                          src={`${image_base_url}${item.requireFiatSettlementTrade
                              ? selectedCheckBoxIcon
                              : emptyCheckBoxIcon
                          }`}
                          width="14"
                          height="14"
                          alt="icon"
                          data-e2e="icon"
                          className="text-center"
                        />
                      </div>{" "}
                      <div className="transaction_item_name">
                        {item.enabled !== true || item.enabled !== false
                          ? getStatus(item.enabled)
                          : "N/A"}
                      </div>{" "}
                      <div className="transaction_item_name">
                        {item.incrementSize ? item.incrementSize : "N/A"}
                      </div>{" "}
                      <div
                        className="transaction_item_name"
                        onClick={() => {
                          setCurrentIndex(index);
                          transactionInformation
                            ? setTransactionInformation(false)
                            : setTransactionInformation(true);
                        }}
                      >
                        <div className="merchantSettingCrypto">
                          <div>
                            {item.contractAddressHistory &&
                            (item.contractAddressHistory.length === 0 ||
                              item.contractAddressHistory[
                                item.contractAddressHistory.length - 1
                              ] === null ||
                              item.contractAddressHistory[
                                item.contractAddressHistory.length - 1
                              ] === "")
                              ? "N/A"
                              : item.contractAddressHistory[
                                  item.contractAddressHistory.length - 1
                                ].substring(0, 5) +
                                "..." +
                                item.contractAddressHistory[
                                  item.contractAddressHistory.length - 1
                                ].substring(
                                  item.contractAddressHistory[
                                    item.contractAddressHistory.length - 1
                                  ].length - 5,
                                )}
                          </div>
                          <div>
                            {item.contractAddressHistory &&
                              item.contractAddressHistory.length > 0 && (
                                <span className="mr-4">
                                  <i className="fa fa-chevron-down contract-down-icon"></i>
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <div
                        div
                        className="transaction_item_name"
                        style={{
                          justifyContent: "flex-end",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <EditIcon
                          fontSize="large"
                          color="action"
                          className={`${isViewPermissionValid ? "disabled" : ""} cursor-pointer pr-3`}
                          data-e2e={`${item.cryptoId}-edit`}
                          onClick={() =>
                            isViewPermissionValid
                              ? null
                              : handleEditCrypto({
                                  ...item,
                                })
                          }
                        />
                        <span
                          className="cursor-pointer"
                          data-e2e="crypto_close_icon"
                        >
                          <CloseIcon
                            className={`${isViewPermissionValid ? "disabled" : ""}`}
                            onClick={() => {
                              props.changeDeletingCryptoId(item._id);
                            }}
                            fontSize="small"
                          />
                        </span>
                      </div>
                    </div>
                    {index === currentIndex &&
                      transactionInformation &&
                      item.contractAddressHistory.length - 1 > 0 && (
                        <div className="singleTR__row edit-contract-address-container">
                          <div className="title">
                            {"Contract Address: "}
                            <span>
                              {
                                item.contractAddressHistory[
                                  item.contractAddressHistory.length - 1
                                ]
                              }
                            </span>
                            <span
                              className={`${isViewPermissionValid ? "disabled" : ""} edit-contract-address-update`}
                              onClick={() =>
                                isViewPermissionValid
                                  ? null
                                  : handleOpenEditContractAddress(
                                      index,
                                      item?._id,
                                      item?.contractAddressHistory &&
                                        item?.contractAddressHistory[
                                          item?.contractAddressHistory?.length -
                                            1
                                        ],
                                    )
                              }
                            >
                              Update
                            </span>
                          </div>

                          <div className="title">
                            {"Contract Address History"}
                          </div>
                          <div>
                            {item &&
                              item.contractAddressHistory &&
                              item.contractAddressHistory.map(
                                (address, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="edit-contract-address-history"
                                    >
                                      <div className="edit-contract-adddress-date">
                                        {`${moment(new Date()).format(
                                          "DD/MM/YY",
                                        )} - ${moment(new Date()).format(
                                          "HH:mm",
                                        )}:`}
                                      </div>
                                      <div className="edit-contract-address-history-inner-div">
                                        <div>{address ? address : "N/A"}</div>
                                        <CloseIcon
                                          className={`${isViewPermissionValid ? "disabled" : ""}`}
                                          onClick={() =>
                                            isViewPermissionValid
                                              ? null
                                              : handleDeleteContractAddress(
                                                  index,
                                                  item?._id,
                                                  item?.contractAddressHistory,
                                                )
                                          }
                                          fontSize="small"
                                        />
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                          </div>
                        </div>
                      )}
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="NoDataFound">No Data Found</div>
      )}
      <div>
        <Dialog
          open={editContractAddresss || false}
          onClose={handleCloseEditContractAddress}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="ClearAlertModal">
            <div className="ClearAlertModalBody">
              <div className="filter_model mb-40">
                <div></div>
                <div className="filter_heading">Update Contract Address</div>
                <div
                  className="filter_close_button"
                  data-e2e="filter_close_button"
                  onClick={() => {
                    handleCloseEditContractAddress();
                  }}
                >
                  <img
                    src={`${image_base_url}${closeImage}`}
                    alt="closeImage"
                    data-e2e="close-icon"
                  />
                </div>
              </div>
              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable font-weight-bold"
                  htmlFor="exampleFormControlSelect1"
                >
                  Enter new Address
                </label>
                <input
                  type="text"
                  value={contractAddress}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect3"
                  placeholder="Enter new Address"
                  name="cryptoId"
                  onChange={(e) => {
                    setContractAddress(e.target.value);
                  }}
                />
              </div>

              <div
                className="ClearAlertModalClearBtn mt-40"
                data-e2e="ClearAlertModalClearBtn"
                onClick={() => {
                  handleUpdateContractAddress({
                    type: "edit_address",
                    email: localDb.getVal("email"),
                    id: cryptoId,
                    addContractAddress: contractAddress,
                  });
                }}
              >
                Update
              </div>
              <div
                className="ClearAlertModalGoBackBtn"
                onClick={() => {
                  handleCloseEditContractAddress();
                }}
              >
                Go Back
              </div>
            </div>
          </div>
        </Dialog>
        {loading && <Loading />}
      </div>
    </div>
  );
};

const DeletingAlert = (props) => {
  return (
    <div className="merchant_dailog_screen">
      <Dialog
        open={props.deletingId ? true : false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
      >
        <div>
          <Modal
            show={true}
            className="ClearAlertModal deleteAlertModal ClearAlertModals"
            onHide={() => {
              props.onHide();
            }}
          >
            <Modal.Body className="ClearAlertModalBody">
              <div className="ClearAlertModalIconWrap">
                <img
                  src={`${props.image_base_url}${deleteIcon}`}
                  alt="resetIcon"
                  className="ClearAlertModalIcon"
                />
              </div>
              <div className="ClearAlertModalTitle">Are you sure?</div>
              <div className="ClearAlertModalDescription">{props.msg}</div>
              <div
                className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
                style={{ background: "#E02020" }}
                onClick={() => {
                  props.onDeleteClickHandler();
                }}
                data-e2e="clear"
              >
                Delete
              </div>
              <div
                className="ClearAlertModalGoBackBtn"
                onClick={() => {
                  props.onHide();
                }}
              >
                Go Back
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Dialog>
    </div>
  );
};
