import React, { useEffect, useState } from 'react';
import Dialog from "@mui/material/Dialog";
import { styled } from '@mui/material/styles';
import { call, logoutAfterError } from '../../../../config/axios';
import {
    SAVE_TRANSACTION_FROM_ADMIN,
    UPDATE_WALLET_STATUS,
    GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
} from '../../../../config/endpoints';
import localDb from '../../../../localDb';
import { useSelector } from 'react-redux';

const email = localDb.getVal('email');

const useStyles = styled(Dialog)({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "white",
            height: "80vh",
            width: "90vw !important"
        }
    }
});

const TransferWalletFund = (props) => {
    const classes = useStyles;
    const api_url = useSelector((state) => state.config.api_url);
    const [error, setError] = useState("");
    const [showWalletListDropdown, setShowWalletsListDropdown] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState('');

    const [showBalanceCryptoDropdown, setShowBalanceCryptoDropdown] = useState(false);
    const [selectedBalaneCrypto, setSelectedBalaneCrypto] = useState(null);
    const [cryptoAmount, setCryptoAmount] = useState(0);
    const [cryptoEquUSDAmount, setCryptoEquUSDAmount] = useState(0);

    const validateAmount = (value) => {
        if (value <= 0) {
            setError("Amount must be greater than zero");
        } else if (selectedBalaneCrypto.cryptoId === "XRP" && value > selectedBalaneCrypto.balanceAmount - 10) {
            setError("Amount must be less than equal to " + (selectedBalaneCrypto.balanceAmount - 10) + " " + selectedBalaneCrypto.cryptoId);
        } else if (value > selectedBalaneCrypto.balanceAmount) {
            setError("Amount must be less than equal to " + selectedBalaneCrypto.balanceAmount + " " + selectedBalaneCrypto.cryptoId);
        } else {
            setError("");
        }
    }

    const onCryptoAmountChange = async (value) => {
        if (value) {
            setCryptoAmount(value);
        } else if (value === 0) {
            setCryptoAmount(0);
        } else {
            setCryptoAmount(null);
        }
        const result = await getSingleCryptoExchangedRate(value, selectedBalaneCrypto.cryptoId, "USD");
        if (value && ReadableStreamDefaultController) {
            // const usdEquAmt = parseFloat(value / result.cryptoAmounts[selectedToken]).toFixed(8)
            const usdEquAmt = parseFloat(result).toFixed(8)
            validateAmount(value)
            setCryptoEquUSDAmount(usdEquAmt);
        } else {
            validateAmount(0)
            setCryptoEquUSDAmount(0);
        }
    };
    const onCryptoEquUSDAmountChange = async (value) => {
        if (value) {
            setCryptoEquUSDAmount(value);
        } else if (value === 0) {
            setCryptoEquUSDAmount(0);
        } else {
            setCryptoEquUSDAmount(undefined);
        }
        const result = await getSingleCryptoExchangedRate(value, "USD", selectedBalaneCrypto.cryptoId);
        if (value && result) {
            setCryptoAmount(parseFloat(result).toFixed(8))
            // validateAmount(result.cryptoAmounts[selectedToken])
        } else {
            setCryptoAmount(0);
            // validateAmount(0)
        }
    };

    const getSingleCryptoExchangedRate = (amount, cryptoCurrency, fiatCurrency) => {
        return new Promise(async (resolve, reject) => {
            await call(
                {
                    ...GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
                    url: api_url + GET_CRYPTO_AMOUNT_EXCHANGE_RATE.url,
                },
                { email: email, currencyAmount: amount, fromCurrency: cryptoCurrency, toCurrency: fiatCurrency }
            )
                .then((res) => {
                    if (res.status === 200) {
                        props.setLoading(false);
                        resolve(res.data);
                    }
                })
                .catch((err) => {
                    props.setLoading(false);
                    console.log(err.message, 'err');
                    logoutAfterError(err)
                });
        })
    };

    const createTransaction = async (data) => {
        let payload = {
            email: localStorage.getItem('email'),
            type: 'Transfer',
            network: data.network,
            createdBy: localStorage.getItem('email'),
            fromWalletId: data._id,
            fromWalletaddress: data.address.key,
            toWalletId: selectedWallet._id,
            toWalletAddress: selectedWallet.address,
            toWalletName: selectedWallet.name,
            cryptoId: selectedBalaneCrypto.cryptoId,
            cryptoAmount: cryptoAmount,
            usdAmount: cryptoEquUSDAmount,
            status: 'WalletTransferFlow:TransferRequested',
            message: "Transfer created by: " + localStorage.getItem('email'),
        };
        props.setLoading(true);
        await call(
            {
                ...SAVE_TRANSACTION_FROM_ADMIN,
                url: api_url + SAVE_TRANSACTION_FROM_ADMIN.url,
            },
            payload
        )
            .then((res) => {
                props.setLoading(false);
                if (res.status === 200) {
                }
            })
            .catch((err) => {
                props.setLoading(false);
                console.log(err.message, 'err');
                logoutAfterError(err)

            });
    };

    const transferFunds = async () => {
        if (props.walletIdForTransferFunds && props.walletIdForTransferFunds !== '') {
            props.setLoading(true);
            await call(
                {
                    ...UPDATE_WALLET_STATUS,
                    url: api_url + UPDATE_WALLET_STATUS.url,
                },
                {
                    email: localStorage.getItem('email'),
                    status: 'Transfer Requested',
                    message: `${localStorage.getItem('email')}`,
                    walletId: props.walletIdForTransferFunds,
                }
            )
                .then((res) => {
                    props.setLoading(false);
                    if (res.status === 200) {
                    }
                })
                .catch((err) => {
                    props.setLoading(false);
                    console.log(err.message, 'err');
                    logoutAfterError(err)
                });
        } else {
        }
    };

    const handleTransferFund = async () => {
        await transferFunds();
        await createTransaction(props.data);
        props.getWallets(1);
        props.onClose();
    };

    useEffect(() => {
        setCryptoAmount(0);
        setCryptoEquUSDAmount(0);
        setError("");
        setSelectedWallet('');
        setSelectedBalaneCrypto(null);
    }, []);

    return (
        <div>
            <Dialog open={props.open} className={`${classes.root} change_status_modal mt-4`} style={{ height: 'fit-content' }}>
                <div className="change_status_body" style={{ width: "90vw" }}>
                    <img
                        src={`${props.image_base_url}/homeb2c/change_status_logo.svg`}
                        alt="image"
                        className="d-block m-auto"
                    />
                    <div className="change_trans_header mt-4 ">
                        Transfer funds
                    </div>
                    <div className="FilterModalInputGroup">
                        <div className="FilterModalInputLabel">Destination wallet</div>
                        <div
                            className="FilterModalInputDropdown"
                            onClick={() => {
                                setShowWalletsListDropdown(true);
                            }}
                        >
                            <div
                                className="FilterModalInputText wrap_word"
                                style={{ flexGrow: 0 }}
                            >
                                {selectedWallet === '' ? "Choose Transfer Wallet" : selectedWallet.name}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {showWalletListDropdown && (
                            <div
                                className="FilterModalCurrencyDropdownlist"
                            >
                                <div
                                    className="FM_currencyDrop_All"
                                    onClick={() => {
                                        setShowWalletsListDropdown(false);
                                    }}
                                >
                                    <span
                                        className="FM_currencyDrop_AllSelector wrap_word"
                                    >
                                        {selectedWallet === '' ? "Choose Transfer Wallet" : selectedWallet.name}
                                    </span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i
                                            className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                        />
                                    </span>
                                </div>
                                <div className="statusList_wrap">
                                    {props.transferWallets && props.transferWallets.length > 0 && props.transferWallets.map((wal, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="currencyListItemWrap wrap_word"
                                                onClick={() => {
                                                    setSelectedWallet(wal);
                                                    setShowWalletsListDropdown(false);
                                                }}
                                            >
                                                <div
                                                    className="currencyListIconNameWrap"
                                                >
                                                    <div className="currencyList_Name wrap_word">
                                                        {wal.name}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="FilterModalInputGroup">
                        <div className="FilterModalInputLabel">CryptoCurrency</div>
                        <div
                            className="FilterModalInputDropdown"
                            onClick={() => {
                                setShowBalanceCryptoDropdown(true);
                            }}
                        >
                            <div
                                className="FilterModalInputText wrap_word"
                                style={{ flexGrow: 0 }}
                            >
                                {selectedBalaneCrypto === null ? "Select CryptoCurrency" : selectedBalaneCrypto.cryptoId}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {showBalanceCryptoDropdown && (
                            <div
                                className="FilterModalCurrencyDropdownlist"
                            >
                                <div
                                    className="FM_currencyDrop_All"
                                    onClick={() => {
                                        setShowBalanceCryptoDropdown(false);
                                    }}
                                >
                                    <span
                                        className="FM_currencyDrop_AllSelector wrap_word"
                                    >
                                        {selectedBalaneCrypto === null ? "Select CryptoCurrency" : selectedBalaneCrypto.cryptoId}
                                    </span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i
                                            className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                        />
                                    </span>
                                </div>
                                <div className="statusList_wrap">
                                    {props.selectedWalletForTransfer &&
                                        props.selectedWalletForTransfer.balance &&
                                        props.selectedWalletForTransfer.balance.length > 0 &&
                                        props.selectedWalletForTransfer.balance.map((cry, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="currencyListItemWrap wrap_word"
                                                    onClick={() => {
                                                        setSelectedBalaneCrypto(cry);
                                                        setShowBalanceCryptoDropdown(false);
                                                    }}
                                                >
                                                    <div
                                                        className="currencyListIconNameWrap"
                                                    >
                                                        <div className="currencyList_Name wrap_word">
                                                            {cry.cryptoId}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        )}
                    </div>

                    {
                        selectedBalaneCrypto !== null ?
                            <div className="FilterModalInputGroup">
                                <div className="FilterModalInputLabel">Amount</div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div>
                                        <input
                                            type="number"
                                            value={cryptoAmount}
                                            className="form-control inputdropdown"
                                            id="cryptoAmount"
                                            data-e2e="crypto-amount"
                                            placeholder="amount in crypto"
                                            name="cryptoAmount"
                                            onChange={(e) => {
                                                onCryptoAmountChange(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="ml-3 mr-3"><i className="fa fa-exchange"></i></div>
                                    <div>
                                        <input
                                            type="number"
                                            value={cryptoEquUSDAmount}
                                            className="form-control inputdropdown"
                                            id="cryptoEquUSDAmount"
                                            placeholder="amount in USD"
                                            name="cryptoEquUSDAmount"
                                            onChange={(e) => {
                                                onCryptoEquUSDAmountChange(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>

                            </div> : null
                    }

                    {error && (
                        <div className="add_note_error">
                            {error}
                        </div>
                    )}
                    <div className="notify_btn_wrap">
                        <div
                            className={error === "" && selectedWallet !== '' && selectedBalaneCrypto !== null && cryptoAmount > 0 && cryptoEquUSDAmount > 0 ? "change_trans_button mt-4" : "change_trans_button mt-4 bg-secondary"}
                            onClick={() => {
                                if (error === "" && selectedWallet !== '' && selectedBalaneCrypto !== null && cryptoAmount > 0 && cryptoEquUSDAmount > 0) {
                                    handleTransferFund()
                                }
                            }}
                        >
                            Transfer
                        </div>
                        <div
                            className="change_trans_go_back mt-4"
                            onClick={() => props.onClose()}
                            data-e2e="go-back"
                        >
                            Go Back
                        </div>
                    </div>

                </div>
            </Dialog>
        </div>
    )
}

export default TransferWalletFund