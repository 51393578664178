import React,{useState}from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { getEmailVal, isViewPermissionValid } from "../../../common/CommonUtils";
const copyicon = "/cryptonpay/crytoicons/copyicon.svg";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { SortByIcon } from "../../../common/CommonUtils";

const useStyles = styled(Tooltip)({
  tooltip: {
    fontSize: "1em",
  },
});

const AllUsersTable = ({ data, showMoreButton, sortBy, orderBy, changeOrder, handleShowMore, setDeletingId }) => {

  return (
    <div className="TransactionTableMainAllUser" style={{ border: "none" }}>
      {data && data.length > 0 ? (
        <Table responsive style={{ border: "none" }}>
          <tbody className="TransactionTable_BODY" style={{ border: "none" }}>
            <tr>
              <th className="TransactionTable_heading" width="14%"
                onClick={() => {
                  changeOrder("firstName");
                }}
              >
                {`FULL NAME`}  {sortBy === "firstName" && (
                  <SortByIcon orderBy={orderBy} data-e2e="firstName" />
                )}
              </th>

              <th className="TransactionTable_heading" width="17%"
                onClick={() => {
                  changeOrder("email");
                }}
              >
                {`EMAIL`}{sortBy === "email" && (
                  <SortByIcon orderBy={orderBy}  />
                )}
              </th>
              <th className="TransactionTable_heading" width="10%"
                // onClick={() => {
                //   changeOrder("");
                // }}
              >
                {`PAYMENT PROVIDER`}
                {/* {sortBy === "" && (
                  <SortByIcon orderBy={orderBy}  />
                )} */}
              </th>
              <th className="TransactionTable_heading" width="15%"
                onClick={() => {
                  changeOrder("mobile");
                }}
              >
                {`PHONE`}{sortBy === "mobile" && (
                  <SortByIcon orderBy={orderBy}/>
                )}
              </th>
              <th className="TransactionTable_heading" width="5%"
                onClick={() => {
                  changeOrder("emailVerified");
                }}
              >
                {`USER STATUS`}{sortBy === "emailVerified" && (
                  <SortByIcon orderBy={orderBy}  />
                )}
              </th>
              <th className="TransactionTable_heading" width="5%"
                // onClick={() => {
                //   changeOrder("");
                // }}
              >
                {`CA AML`}
                {/* {sortBy === "" && (
                  <SortByIcon orderBy={orderBy} />
                )} */}
              </th>
              <th className="TransactionTable_heading" width="7%"
                // onClick={() => {
                //   changeOrder("");
                // }}
              >
                {`PEP`}
                {/* {sortBy === "" && (
                  <SortByIcon orderBy={orderBy} />
                )} */}
              </th>
              <th className="TransactionTable_heading" width="4%"
                // onClick={() => {
                //   changeOrder("");
                // }}
              >
                {`RISK LEVEL`}
                {/* {sortBy === "" && (
                  <SortByIcon orderBy={orderBy}  />
                )} */}
              </th>
              <th className="TransactionTable_heading" width="10%" 
                onClick={() => {
                  changeOrder("lastTransaction");
                }}
              >
                {`LAST TRANSACTION`}{sortBy === "lastTransaction" && (
                  <SortByIcon orderBy={orderBy}  />
                )}
              </th>
              <th className="TransactionTable_heading" width="10%"
                onClick={() => {
                  changeOrder("lastLogin");
                }}
              >
                {`LAST LOGIN`}{sortBy === "lastLogin" && (
                  <SortByIcon orderBy={orderBy} />
                )}
              </th>

              <th className="TransactionTable_heading" width="10%">
                {" "}
              </th>
            </tr>
            {data.map((TData, i) => {
              const emailVal = getEmailVal(TData?.email)
              TData = {...TData, email: emailVal}
              return <SingleTransactionTableRow data={TData} key={i} setDeletingId={setDeletingId}/>;
            })}
          </tbody>
        </Table>
      ) : (
        <div className="No_Transactions_Found">No Data Found</div>
      )}
      {showMoreButton ? (
        <div className="Show_more_wrap">
          <div
            className="Show_more"
            data-e2e="Show_more"
            onClick={() => {
              handleShowMore();
            }}
          >
            Show More
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AllUsersTable;

const SingleTransactionTableRow = (props) => {
  const [showCoppied1, toggleCoppied1] = useState(false);
  const [showCoppied2, toggleCoppied2] = useState(false);

  const image_base_url = useSelector((state) => state.config.image_base_url);
  let { data } = props;
  const redirectUserManagement = (value) =>{
    window.location.replace(`/b2c-users/${value._id}`);
  }
  const classes = useStyles;
  return (
    <tr className="CP_TableRow" >
      <td className="transactionsTable_item ">
        {data && data.firstName
          ? data.lastName
            ? data.firstName + " " + data.lastName
            : "N/A"
          : null}
        {data && data.firstName ? (
          data.lastName ? (
            		!showCoppied1 ? (
                  <img
                    src={`${image_base_url}/homeb2c/transaction-cpy.png`}
                    alt="image"
                    className="cursor-pointer copyImg"
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                copy(data.firstName + " " + data.lastName);
                      toggleCoppied1(true);
                      setTimeout(() => {
                        toggleCoppied1(false);
                      }, 2000);
                    }}
                  />
                ) : (
                    <DoneAllIcon
                      style={{ color: "#6fbf73", marginLeft: "5px" }}
                    />
                  
                )
          ) : null
        ) : null}
      </td>

      <td className="transactionsTable_item">
        <Tooltip classes={{tooltip: classes.tooltip}} title={data.email &&  data.email} placement="top">
        <div>
          {data && data.email
            ? data.email.length > 4
              ? data.email.charAt(0) +
                "..." +
                data.email.substring(data.email.indexOf("@") - 3)
              : data.email
            : "N/A"}

          {data && data.email && (
            !showCoppied2 ? (
              <img
                src={`${image_base_url}/homeb2c/transaction-cpy.png`}
                alt="image"
                className="cursor-pointer copyImg"
                style={{ marginLeft: "5px" }}
                onClick={() => {
                copy(data.email);
                  toggleCoppied2(true);
                  setTimeout(() => {
                    toggleCoppied2(false);
                  }, 2000);
                }}
              />
            ) : (
                <DoneAllIcon
                  style={{ color: "#6fbf73", marginLeft: "5px" }}
                />
              
            )
          )}
        </div>
        </Tooltip>

      </td>
      <td className="transactionsTable_item">
        <div>
          {data && data.paymentProvider && (
            <p>{data.paymentProvider.firstName + " " + data.paymentProvider.lastName}</p>
          )}
        </div>
      </td>
      <td className="transactionsTable_item">
        <div>
          {data && data.mobile ? data.mobile : "N/A"}
          {data && data.mobile && (
            <span
              className="cursor-pointer"
              style={{ paddingLeft: "5px" }}
              onClick={() => {
                copy(data.mobile);
              }}
            >
              <img src={`${image_base_url}${copyicon}`} alt="filterIcon" />
            </span>
          )}
        </div>
      </td>
      <td
        className={
          data.emailVerified === true
            ? "transactionsTable_item Active__user"
            : "transactionsTable_item onboarding__user"
        }
      >
        {data && data.emailVerified === true ? "Active" : "Onboarding"}
      </td>

      <td className="transactionsTable_item">{"N/A"}</td>
      <td className="transactionsTable_item">{"N/A"}</td>
      <td className="transactionsTable_item">{(data && data.riskLevel && data.riskLevel.riskLevel ) || 1}</td>
      <td className="transactionsTable_item">
          {data.lastTransactionId && data.lastTransactionId !='' ?
            <Tooltip title={data.lastTransactionId && data.lastTransactionId} placement="top">
              <Link to={`/b2c-user-transactions/${data.lastTransactionId}`}>
                {data.lastTransactionId
                  ? data.lastTransactionId.substring(0, 5) +
                  '.....' +
                  data.lastTransactionId.substring(
                    data.lastTransactionId.length - 6,
                    data.lastTransactionId.length
                  )
                  : 'N/A'}
              </Link>
            </Tooltip>
           :"N/A"}
      </td>
      <td className="transactionsTable_item">
        {data.lastLogin ? data.lastLogin : "N/A"}
      </td>

      <td className="transactionsTable_item">
        {
         <span><i
            onClick={() => redirectUserManagement(data)}
            style={{ color: "#AFAFAF" }}
            className="fa fa-chevron-right cursor-pointer"
            data-e2e={data.firstName + " " + data.lastName}
            aria-hidden="true"
          ></i>
         <i
        className={`fa fa-trash deleteB2cUserIcon cursor-pointer ${isViewPermissionValid ? 'disabled' : ''}`}
        aria-hidden="true"
        onClick={isViewPermissionValid ? null : () => props.setDeletingId(data._id)}
        data-e2e={data.firstName + " " + data.lastName + " delete"}
      ></i></span> 
        }
      </td>
    </tr>
  );
};


