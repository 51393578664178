import TableRow from "@mui/material/TableRow";
import {TableCell} from "@mui/material";
import moment from "moment";
import {Link} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

const TransactionsTableRow = ({balance}) => {
  const transactionId = balance?.transactionId;
  return (
    <TableRow>
      <TableCell>
        {balance?.updated
          ?moment(balance.updated).format(
            "DD/MM/YYYY - HH:mm"
          ) : 'N/A'}
      </TableCell>
      <TableCell>
        {
          balance?.transactionType
            ? balance.transactionType
            : 'N/A'
        }
      </TableCell>
      <TableCell>
        {
          transactionId
            ? <Link to={`/transaction/${transactionId}`}>
              <Tooltip
                title={transactionId}
                placement="top"
              >
                <div className="status-msg-text">
                  {transactionId.substring(0, 6) +
                    "....." +
                    transactionId.substring(
                      transactionId.length - 6,
                      transactionId.length
                    )}
                </div>
              </Tooltip>
            </Link>
            : 'N/A'
        }
      </TableCell>
      <TableCell>
        {balance?.cryptoId ? balance.cryptoId : 'N/A'}
      </TableCell>
      <TableCell>
        {balance?.amount !== undefined ? balance.amount : 'N/A'}
      </TableCell>
      <TableCell>
        {balance?.previousBalance !== undefined ? balance.previousBalance : 'N/A'}
      </TableCell>
      <TableCell>
        {balance?.currentBalance !== undefined ? balance.currentBalance : 'N/A'}
      </TableCell>
    </TableRow>
  )
}

export default TransactionsTableRow;
