import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader } from '../../../Redux/actions/commonAction';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import TransactionView from '../CommonPages/TransactionView';
import { call, logoutAfterError } from "../../../../config/axios";
import {
  VIEW_B2C_SINGLE_USER_WALLET
} from "../../../../config/endpoints";
import { useParams} from 'react-router-dom'


import localDb from "../../../../localDb";

function UserWalletView() {
  const view_transaction_url = useSelector((state) => state.config.api_url);
  const dispatch = useDispatch();
  const email = localDb.getVal("email");
  
  
  const [id, setId] = useState('');
  const [data, setData] = useState([]);
  const [UseWalletId, setUserWalletId] = useState(useParams().id);
  const [walletId, setWalletId] = useState('');

  const handleId = (id) => {
    setId(id);
  };

  const handleFlag = () => {
    window.location.reload()
  }

  const sub_name_last = walletId
    ? "Wallet Address: " + walletId.substring(0, 8) + '.....' + walletId.substring(walletId.length - 6, walletId.length)
    : 'N/A';

  useEffect(() => {
    viewSingleWalletAddress()
    dispatch(setHeader('User Wallets'));
  }, []);

  const viewSingleWalletAddress = async () => {
    await call(
      {
        ...VIEW_B2C_SINGLE_USER_WALLET,
        url: view_transaction_url + VIEW_B2C_SINGLE_USER_WALLET.url,
      },
      { email: email, walletId: UseWalletId }
    )
      .then((res) => {
        if (res.status === 200) {  
          setData(res.data.userWallets ? res.data.userWallets : []);
          setWalletId(res.data.userWallets.address.key);
        }
      })
      .catch((err) => {
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const name = 'Wallets';
  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
        <CommonHeader name={sub_name_last} 
        fullId={walletId} view="user-wallet" 
        id={id} 
        backButton ={true}
        handleFlag={handleFlag}
				copyButton={true}
         />
        <div>
          <TransactionView handleId={handleId} view="B2C User Wallets" data={data} setData={setData} viewSingleWalletAddress={viewSingleWalletAddress}/>
        </div>
      </HeaderAndFooterLayout>
    </div>
  );
}

export default UserWalletView;
