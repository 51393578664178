import React, {useState} from "react";
import {TableCell} from "@mui/material";

export const TableHeader = ({
  header,
  sortBalances,
  currentSortConfig,
  setSortConfig,
  sortKey
}) => {
  const ArrowUpwardIcon = <i className="fa fa-chevron-up TransactionTable_heading_sortIcon sort_wallet_icon"></i>;
  const ArrowDownwardIcon = <i className="fa fa-chevron-down TransactionTable_heading_sortIcon sort_wallet_icon"></i>;
  const handleSort = () => {
    const isSortedAsc = currentSortConfig.key === sortKey && currentSortConfig.direction === 'ascending';
    setSortConfig({ key: sortKey, direction: isSortedAsc ? 'descending' : 'ascending' });
    sortBalances(header);
  };
  const showArrow = currentSortConfig.key === sortKey;
  const arrowIcon = currentSortConfig.direction === 'ascending' ? ArrowUpwardIcon : ArrowDownwardIcon;

  return (
    <TableCell onClick={handleSort} style={{ cursor: 'pointer' }}>
      {header}
      {showArrow ? arrowIcon : null}
    </TableCell>
  );
}
