import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CPMultiSelect } from '../../../common/CPMultiSelect';
import ResetModal from '../CommonPages/ResetModal';
import DateRangePicker2 from 'react-bootstrap-daterangepicker';
const dateImage = '/cryptonpay/crytoicons/date.png';
const closeImage = '/cryptonpay/crytoicons/close.png';
const selectedCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_filled.png';
const emptyCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_empty.png';


function UserTransactionFilter(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [showType, setShowType] = useState(false);
  const [value, setValue] =useState([ props.minFiatAmount, props.maxFiatAmount]);
  const [openPopup,resetOpenPopup]=useState(false)
  const [showProviderDropdown, setShowProviderDropdown] = useState(false);

  const closeReset=()=>resetOpenPopup(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.setMinFiatAmount(newValue[0]);
    props.setMaxFiatAmount(newValue[1]);    
  };
  

  const toggleStatusSelection = (symbol) => {
    if (props.selectedStatusList.includes(symbol)) {
      let newList = props.selectedStatusList.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedStatusList(newList);
    } else {
      let newList = [...props.selectedStatusList];
      newList.push(symbol);
      props.setSelectedStatusList(newList);
    }
  };
  const toggleTypeSelection = (symbol) => {
    if (props.selectedType.includes(symbol)) {
      let newList = props.selectedType.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedType(newList);
    } else {
      let newList = [...props.selectedType];
      newList.push(symbol);
      props.setSelectedType(newList);
    }
  };
  const togglePaymentProviderSelection = (symbol) => {
    if (props.selectedPaymentProvider.includes(symbol)) {
      let newList = props.selectedPaymentProvider.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedPaymentProvider(newList);
    } else {
      let newList = [...props.selectedPaymentProvider];
      newList.push(symbol);
      props.setSelectedPaymentProvider(newList);
    }
  };


  const toggleSelection = (symbol) => {
    if (props.selectedCryptos.includes(symbol)) {
      let newList = props.selectedCryptos.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedCryptos(newList);
    } else {
      let newList = [...props.selectedCryptos];
      newList.push(symbol);
      props.setSelectedCryptos(newList);
    }
  };

  const handleSaveDetails = () => {
    props.handleTransactionFilters();
    props.handleSave();
  };

  const handleDropdownClick = (dropdownSetter, toggle) => {
    setShowCurrencyDropdown(false)
    setShowStatusDropdown(false)
    setShowType(false)
    setShowProviderDropdown(false);
    toggle ? dropdownSetter(false) : dropdownSetter(true)
  };

  const sortDropDownData = (data, field) => {
    if (field) {
      const filteredData = data.filter(item => item[field] !== undefined && item[field] !== null);
      return filteredData.sort((a, b) => {
        if (!a[field] && !b[field]) {
          return 0;
        }
        if (!a[field]) {
          return -1;
        }
        if (!b[field]) {
          return 1;
        }
        return a[field].localeCompare(b[field], { sensitivity: 'accent' });
      });
    } else {
      return data.sort((a, b) => a.localeCompare(b, { sensitivity: 'accent' }));
    }
  };

  const handleCallback = async (start, end, label) => {
    props.setFromDate(moment(start).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    props.setToDate(moment(end).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    props.setDateSelected(true);
  };

  useEffect(()=>{
    setValue([props.minFiatAmount, props.maxFiatAmount])
  },[props.minFiatAmount,props.maxFiatAmount])

  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <ResetModal  open={openPopup} close={closeReset} reset={props?.resetFilters} />
        <div
          className="filter_clear"
          data-e2e="filter-clear-btn"
          onClick={()=>resetOpenPopup(true)}
        >
          Clear
        </div>
        <div className="filter_heading">Filters</div>
        <div
          className="filter_close_button"
          onClick={() => {
            props.handleTransactionFilters();
          }}
        >
          <img
            src={`${image_base_url}${closeImage}`}
            alt="closeImage"
            data-e2e="close-icon"
          />
        </div>
      </div>
      {props?.fromDate && props?.toDate &&
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Date Range</div>
          <div className="transactionsFilter-date-ranger">
          <DateRangePicker2
							onCallback={handleCallback}
							initialSettings={{
								startDate: moment(props?.fromDate),
								endDate: moment(props?.toDate),
							}}
						>
							<div
								className="FilterModalInputDropdown"
								data-e2e="FilterModalInputDropdown"
							>
								<img
									src={`${image_base_url}${dateImage}`}
									alt="dateImage"
									className="FilterModalInputDropdownDateImage"
								/>
								<div className="FilterModalInputDropdownDateTextWrap">
								<span data-e2e="start-date">{`${props?.dateSelected
									? moment(props?.fromDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
										'D MMMM'
									)
									: 'Start Date'
								}`}</span>
									<span style={{ color: 'black' }}>{` To `}</span>
									<span style={{ marginRight: '15%' }} data-e2e="end-date">{`${props?.dateSelected
										? moment(props?.toDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
											'D MMMM'
										)
										: 'End Date'
									}`}</span>
								</div>
							</div>
						</DateRangePicker2>
          </div>
        </div>
      }
      {props.selectedCryptos &&
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Crypto Currency</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setShowCurrencyDropdown)
            }}
          >
            <div className="FilterModalInputText" data-e2e="crypto-currency-drop-down">
              {props.selectedCryptos.length === props.cryptos.length ||
              props.selectedCryptos.length === 0
                ? 'All'
                : `${props.selectedCryptos.length} Seletcted`}
            </div>
            <i
              className="fa fa-chevron-down"
            />
          </div>
          {showCurrencyDropdown && (
            <CPMultiSelect
              items={sortDropDownData(props.cryptos, 'name')}
              selectedItems={
                props.selectedCryptos
              }
              setShowDropDown={setShowCurrencyDropdown}
              handleDropdownClick={handleDropdownClick}
              setSelectedItems={(items) =>
                props.setSelectedCryptos(items.map(a => a.symbol) || [])}
              ItemComponent={({ item: crypto }) => {
                let { name, symbol, _id, icon } = crypto;
                return (
                  <div

                    className="currencyListItemWrap"
                    onClick={() => {
                      props.filter_name === 'wallet'
                        ? toggleSelection(_id)
                        : toggleSelection(symbol);
                    }}
                  >
                    <div className="currencyListIconNameWrap">
                      <img
                        src={`${image_base_url}${icon}`}
                        alt={symbol}
                        className="currencyList_Icon"
                      />
                      <div className="currencyList_Name" data-e2e={name.toLowerCase().replaceAll(' ', '-')}>
                        {name}
                      </div>
                    </div>
                    <img
                      data-e2e="checkBox"
                      src={`${image_base_url}${props.filter_name === 'wallet'
                        ? props.selectedCryptos.includes(_id)
                          ? selectedCheckBoxIcon
                          : emptyCheckBoxIcon
                        : props.selectedCryptos.includes(symbol)
                          ? selectedCheckBoxIcon
                          : emptyCheckBoxIcon
                      }`}
                      alt="checkbox"
                      className="currencyListCheckBox"
                    />
                  </div>
                );
              }}
            />)
          }
        </div>
      }

      {props.setMinFiatAmount && props.setMaxFiatAmount &&
        <div  className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Fiat Amount Range</div>
          <div style={{marginTop:'20px'}}>
            <Box sx={{ width: 370 }}>
              <Slider
                getAriaLabel={() => 'Fiat Amount Range'}
                value={value}
                min={0}
                max={1000}
                onChange={handleChange}
                valueLabelDisplay="auto"
                data-e2e={value}
              />
            </Box>
          </div>
        </div>
      }

      {props.statusList &&
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Status</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setShowStatusDropdown)
            }}
          >
            <div className="FilterModalInputText" data-e2e="status-drop-down">
              {props.selectedStatusList.length === props.statusList.length ||
              props.selectedStatusList.length === 0
                ? 'All'
                : `${props.selectedStatusList.length} Seletcted`}
            </div>
            <i
              className="fa fa-chevron-down"
            />
          </div>
          {showStatusDropdown && (
            <CPMultiSelect
              setShowDropDown={setShowStatusDropdown}
              handleDropdownClick={handleDropdownClick}
              items={sortDropDownData(props.statusList)}
              selectedItems={
                props.selectedStatusList
              }
              setSelectedItems={(items) =>
                props.setSelectedStatusList(items.map(a => a) || [])}


              ItemComponent={({ item: status }) => {

                return (
                  <div

                    className="currencyListItemWrap"
                    onClick={() => {
                      toggleStatusSelection(status);
                    }}
                  >
                    <div
                      className="currencyListIconNameWrap"
                    >
                      <div className="currencyList_Name" id="available-test" data-e2e={status.toLowerCase().replaceAll(' ', '-')}>{status}</div>
                    </div>
                    <img
                      src={`${image_base_url}${props.selectedStatusList.includes(status)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                      }`}
                      alt="checkbox"
                      className="currencyListCheckBox"
                      data-e2e={`${status.toLowerCase().replaceAll(' ', '-')}-checkbox`}
                    />
                  </div>
                );
              }}
            />)
          }
        </div>
      }
      {props.type &&
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Type</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setShowType)
            }}
          >
            <div
              className="FilterModalInputText"
              data-e2e="type-drop-down"
            >
              {props.selectedType.length === props.type.length ||
              props.selectedType.length === 0
                ? 'All'
                : `${props.selectedType.length} Seletcted`}
            </div>
            <i
              className="fa fa-chevron-down"
            />
          </div>
          {showType && (
            <CPMultiSelect
              items={sortDropDownData(props.type)}
              selectedItems={
                props.selectedType
              }
              setShowDropDown={setShowType}
              handleDropdownClick={handleDropdownClick}
              setSelectedItems={(items) =>
                props.setSelectedType(items.map(a => a) || [])}
              ItemComponent={({ item: status }) => {
                return <div

                  className="currencyListItemWrap"
                  onClick={() => {
                    toggleTypeSelection(status);
                  }}
                >
                  <div
                    className="currencyListIconNameWrap"
                  >
                    <div className="currencyList_Name" style={{ textTransform: 'capitalize' }} data-e2e={status.toLowerCase().replaceAll(' ', '-')}>{status}</div>
                  </div>
                  <img
                    src={`${image_base_url}${props.selectedType.includes(status)
                      ? selectedCheckBoxIcon
                      : emptyCheckBoxIcon
                    }`}
                    alt="checkbox"
                    className="currencyListCheckBox"
                  />
                </div>
              }}
            />)
          }
        </div>
      }
      {props.paymentProviderList && props.adminRole == "SuperAdmin" &&
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">{`Payment Providers`}</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setShowProviderDropdown)
            }}
          >
            <div className="FilterModalInputText">
              {props.selectedPaymentProvider.length === props.paymentProviderList.length ||
              props.selectedPaymentProvider.length === 0
                ? 'All'
                : `${props.selectedPaymentProvider.length} Selected`}
            </div>
            <i
              className="fa fa-chevron-down"
            />
          </div>
          {showProviderDropdown && (
            <CPMultiSelect
              dataE2eLabel="provider-"
              setShowDropDown={setShowProviderDropdown}
              handleDropdownClick={handleDropdownClick}
              items={sortDropDownData(props.paymentProviderList, 'firstName')}
              selectedItems={
                props.selectedPaymentProvider
              }
              setSelectedItems={(items) =>
                props.setSelectedPaymentProvider(items.map(a => a._id) || [])}


              ItemComponent={({ item: provider }) => {

                return (
                  <div

                    className="currencyListItemWrap"
                    onClick={() => {
                      togglePaymentProviderSelection(provider._id);
                    }}
                  >
                    <div
                      className="currencyListIconNameWrap"
                    >
                      <div className="currencyList_Name" id="available-test">{provider.firstName + " " + provider.lastName}</div>
                    </div>
                    <img
                      src={`${image_base_url}${props.selectedPaymentProvider.includes(provider._id)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                      }`}
                      alt="checkbox"
                      className="currencyListCheckBox"
                    />
                  </div>
                );
              }}
            />)
          }
        </div>
      }

      <div
        className="FilterModalSave"
        data-e2e="save-btn"
        onClick={() => {
          handleSaveDetails();
        }}
      >
        SAVE
      </div>
    </div>
  );
}

export default UserTransactionFilter;
