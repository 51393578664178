import React from "react";

const NavTabs = ({ tabs, selectedTab, changeTab, fontSize }) => {
  return (
    <div className={`globalSettings_nave_wrap ${fontSize ? "common_header_container box" : null}`}>
      {tabs.map((tab, i) => {
        return (
          <div
            key={i}
            data-e2e={tab.name.toLowerCase().replaceAll(' ', '-')}
            className={`globalSettings_nav_item ${
              fontSize ? "globalSettings_nav_item_merchant " : null
            } ${
              tab.id === selectedTab.id ? "globalSettings_nav_item_active" : ""
            }`}
            onClick={() => {
              changeTab(tab);
            }}
          >
            {tab.name}
          </div>
        );
      })}
    </div>
  );
};

export default NavTabs;
