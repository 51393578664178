import React from 'react';
import { Link } from 'react-router-dom';

const SubHeader = (props) => {
    let pathname = window && window.location && window.location.pathname && window.location.hostname
    pathname = pathname && pathname.split('.')
    return (<div className={`header_body_mobile text-dark bg-white hamburgermenu_open ${pathname === "admin" ? "header-banner-bg-prod" : "header-banner-bg-dev" }`}>
        <div className="crypton-admin">
            <Link to="/merchants" className="admin_logo">
                <div
                    className="logo-header-mobile"
                >
                    <img
                        className="main-logo-dimensions"
                        src={`${props.image_base_url}/homeb2c/main_logo_admin_dark.svg`}
                        alt="CryptonAdmin"
                    />
                    <div className={`main-header-text text-dark`}>
                        CRYPTON<span className="header-blue">ADMIN</span>
                    </div>
                </div>
            </Link>
        </div>
    </div>)
}

export default SubHeader;