import Dialog from "@mui/material/Dialog"
import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap"
import { useSelector } from 'react-redux'
import { call } from '../../../../../config/axios'
import { CREATE_FRAUD_SERVICE, DELETE_FRAUD_SERVICE, GET_ALL_PAYMENT_PROVIDERS, GET_FRAUD_SERVICE } from '../../../../../config/endpoints'
import localDb from '../../../../../localDb'
import HeaderAndFooterLayout from '../../../Layouts/HeaderAndFooter/HeaderAndFooterLayout'
import CommonHeader from '../../CommonPages/CommonHeader'
import AddCardAquireService from './AddCardAquirerService'
import EditCardAquireService from './EditCardAquireService'
import CardAquireServiceTable from './cardAquirerServiceTable'
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";


const antiFraudServiceHeaders = [
    {
        name: "Id",
        width: "15%"
    },
    {
        name: "Payment Provider",
        width: "20%",
    },
    {
        name: "Service Name",
        width: "20%",
    },
    {
        name: "FTD To Trusted Count",
        width: "15%",
    },
    {
        name: "Creation Date",
        width: "20%"
    },
    {
        name: "",
        width: "10%",
    },
];

const CardAquireService = () => {
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const base_url = useSelector((state) => state.config.api_url);
    const [antiFraudServiceData, setAntiFraudServiceData] = useState([])
    const [fraudServiceName, setFraudServiceName] = useState("")
    const [errorField, setErrorField] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [deletingAntiFraudServiceId, setDeletingAntiFraudServiceId] = useState(null)
    const [showAddNewAntiFraudModal, setShowAddNewAntiFraudModal] = useState(false)
    const [showEditAntiFraudModal, setShowEditAntiFraudModal] = useState(false)
    const [editAntiFraudServiceData, setEditAntiFraudServiceData] = useState(null)
    const [paymentProviderList, setPaymentProviderList] = useState([])
    const [selectedPaymentProvider, setSelectedPaymentProvider] = useState("")
    const email = localDb.getVal("email");



    useEffect(() => {
        getAntiFraud()
    }, [deletingAntiFraudServiceId])
    //Get Anti Fraud Service
    const getAntiFraud = async () => {
        let payload = {
            email: localDb.getVal("email"),
        };
        await call(
            {
                ...GET_FRAUD_SERVICE,
                url: base_url + GET_FRAUD_SERVICE.url
            },
            payload
        ).then((res) => {
            if (res.status === 200) {
                setAntiFraudServiceData(res.data)
            }
        }).catch((err) => {
            console.error(err);
        })
    }

    //Delete Anti
    const deleteAntiFraudService = async () => {
        if (!deletingAntiFraudServiceId) {
            alert("No id selected")
        }
        let payload = {
            email: localDb.getVal('email'),
            _id: deletingAntiFraudServiceId
        }
        await call(
            {
                ...DELETE_FRAUD_SERVICE,
                url: base_url + DELETE_FRAUD_SERVICE.url,
            },
            payload
        ).then((res) => {
            if (res.status === 200) {
                let newlist = antiFraudServiceData
                newlist.splice(newlist.findIndex(a => a._id === deletingAntiFraudServiceId), 1)
                setAntiFraudServiceData(newlist)
                setDeletingAntiFraudServiceId(null)

            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const handleEditAntiFraudService = (data) => {
        setEditAntiFraudServiceData(data);
        setShowEditAntiFraudModal(true);
    };
    const validateFrom = () => {
        if (!fraudServiceName || fraudServiceName === "") {
            setErrorField("fraudServiceName")
            setErrorMessage("Service name is Required")
        }
    }
    const handleChange = (e) => {
        setFraudServiceName(e.target.value)
        if (e.target.value === '') {
            setErrorField("fraudServiceName")
            setErrorMessage("Service name is required")
        } else {
            setErrorField(null)
            setErrorMessage(null)
        }

    };

    

    const handleAddNewFraudService = () => {
        validateFrom()
        if (errorField === null && errorMessage === null) {
            let timeout = null
            timeout = setTimeout(async () => {
                if (errorField === null && errorMessage === null) {
                    let payload = {
                        email: localDb.getVal('email'),
                        fraudServiceName: fraudServiceName ? fraudServiceName : null,
                        paymentProvider: selectedPaymentProvider ? selectedPaymentProvider : ""
                        // tokenType: formStates.tokenType ? formStates.tokenType : null,
                        // siteReferenceName: formStates.siteReferenceName ? formStates.siteReferenceName : null,
                        // username: formStates.username ? formStates.username : null,
                        // pwd: formStates.pwd ? formStates.pwd : null,
                        // jwtUsername: formStates.jwtUsername ? formStates.jwtUsername : null,
                        // jwtPassword: formStates.jwtPassword ? formStates.jwtPassword : null,
                        // livestatus: formStates.livestatus ? formStates.livestatus : null,
                        // notes: formStates.notes ? formStates.notes : null,
                    }
                    await call(
                        {
                            ...CREATE_FRAUD_SERVICE,
                            url: base_url + CREATE_FRAUD_SERVICE.url,
                        },
                        payload
                    ).then((res) => {
                        if (res.status === 200) {
                            setShowAddNewAntiFraudModal(false)
                            let newList = antiFraudServiceData
                            newList.push(res.data)
                            setAntiFraudServiceData(newList)
                            setFraudServiceName("")

                        }
                    }).catch((err) => {
                        if(err.response.data.err.code === 11000) {
                            setErrorMessage("Service name should be unique!")
                            setErrorField("fraudServiceName")
                        }
                        else{
                            setErrorMessage("Some thing went wrong..Please check")
                            setErrorField("fraudServiceName")
                        }
                    })
                }
            })
        } else (
            console.log(errorField, errorMessage)
        )
    }

    const getPaymentProviders = async (page) => {
        const payload = {
            email,
            orderBy: "asc",
        };
        await call(
            {
                ...GET_ALL_PAYMENT_PROVIDERS,
                url: base_url + GET_ALL_PAYMENT_PROVIDERS.url,
            },
            payload
        )
            .then((res) => {
                if (res.status === 200) {
                    setPaymentProviderList(res.data.providerList);
                }
            })
            .catch((err) => {
                console.log(err.message, "err");
            });
    };

      useEffect(()=>{
        getPaymentProviders()
      },[])
    return (
        <HeaderAndFooterLayout>
            <CommonHeader name={`Card Acquirer Routing Service`}  backButton={true}/>
            <div className="merchant-setting">
                <div
                    className=""
                    style={{
                        // marginTop: "-1.8px",
                        marginBottom: "-10px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        width: "100%",
                        border: "1px solid #ececec",
                        borderRadius: "5px"
                    }}
                >
                    <CardAquireServiceTable
                        antiFraudServiceHeaders={antiFraudServiceHeaders}
                        paymentProviderList={paymentProviderList}
                        antiFraudServiceData={antiFraudServiceData}
                        setShowAddNewAntiFraudModal={setShowAddNewAntiFraudModal}
                        deleteAntiFraud={(id) => {
                            setDeletingAntiFraudServiceId(id);
                        }}
                        setEditModel={(val) => setShowAddNewAntiFraudModal(val)}
                        handleEditAntiFraudService={handleEditAntiFraudService}
                    />
                </div>
                {/* Add New Fraud Service */}
                <Dialog
                    open={showAddNewAntiFraudModal}
                    onClose={() => {
                        setShowAddNewAntiFraudModal(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={"md"}
                >
                    <div>
                        <AddCardAquireService
                            // formStates={formStates}
                            // setFormStates={setFormStates}
                            handleClose={() => {
                                setShowAddNewAntiFraudModal(false)
                            }}
                            paymentProviderList={paymentProviderList}
                            setSelectedPaymentProvider={setSelectedPaymentProvider}
                            selectedPaymentProvider={selectedPaymentProvider}
                            handleChange={handleChange}
                            handleAddNewFraudService={handleAddNewFraudService}
                            fraudServiceName={fraudServiceName}
                            setFraudServiceName={setFraudServiceName}
                            errorField={errorField}
                            setErrorField={setErrorField}
                            errorMessage={errorMessage}
                            setErrorMessage={setErrorMessage}
                            antiFraudServiceData={antiFraudServiceData}
                            setAntiFraudServiceData={(val) => setAntiFraudServiceData(val)}
                        />
                    </div>
                </Dialog>
                {/* Edit Fraud Service */}
                <Dialog
                    open={showEditAntiFraudModal}
                    onClose={() => {
                        setEditAntiFraudServiceData(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={"md"}
                >
                    <EditCardAquireService
                        handleClose={() => setShowEditAntiFraudModal(false)}
                        handleDelete={(id) => {
                            setDeletingAntiFraudServiceId(id)
                        }}
                        getAntiFraud={getAntiFraud}
                        editAntiFraudServiceData={editAntiFraudServiceData}
                        setEditAntiFraudServiceData={setEditAntiFraudServiceData}
                        antiFraudServiceData={antiFraudServiceData}
                        setAntiFraudServiceData={(val) => setAntiFraudServiceData(val)}
                        paymentProviderList={paymentProviderList}
                    />
                </Dialog>
                <DeletingAlert
                    deletingId={deletingAntiFraudServiceId}
                    onHide={() => setDeletingAntiFraudServiceId(null)}
                    image_base_url={image_base_url}
                    msg="Are you sure you want to delete selected setting? This action cannot be undone."
                    onDeleteClickHandler={() => deleteAntiFraudService()}
                />
            </div>
        </HeaderAndFooterLayout>
    )
}

export default CardAquireService

const DeletingAlert = (props) => {
    return (
        <div className="merchant_dailog_screen">
            <Dialog
                open={props.deletingId ? true : false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"md"}
            >
                <div>
                    <Modal
                        show={true}
                        className="ClearAlertModal deleteAlertModal ClearAlertModals"
                        onHide={() => {
                            props.onHide();
                        }}
                    >
                        <Modal.Body className="ClearAlertModalBody">
                            <div className="ClearAlertModalIconWrap">
                                <img
                                    src={`${props.image_base_url}${deleteIcon}`}
                                    alt="resetIcon"
                                    className="ClearAlertModalIcon"
                                />
                            </div>
                            <div className="ClearAlertModalTitle">Are you sure?</div>
                            <div className="ClearAlertModalDescription">{props.msg}</div>
                            <div
                                className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
                                style={{ background: "#E02020" }}
                                onClick={() => {
                                    props.onDeleteClickHandler();
                                }}
                                data-e2e="clear"
                            >
                                Delete
                            </div>
                            <div
                                className="ClearAlertModalGoBackBtn"
                                onClick={() => {
                                    props.onHide();
                                }}
                            >
                                Go Back
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </Dialog>
        </div>
    );
};