const getHeaderButtonStyle = (colorMode, darkBgColour, darkTextColour, lightBgColour, lightTextColour) => {
    let style = {};
    if (colorMode === 'dark') {
        if (darkBgColour && darkBgColour !== '') {
            style.backgroundColor = darkBgColour;
            style.borderColor = darkBgColour;
        }
        if (darkTextColour && darkTextColour !== '') {
            style.color = darkTextColour;
        }
    } else {
        if (lightBgColour && lightBgColour !== '') {
            style.backgroundColor = lightBgColour;
            style.borderColor = lightBgColour;
        }
        if (lightTextColour && lightTextColour !== '') {
            style.color = lightTextColour;
        }
    }
    return style;
};
const getValueFromSS = (value) => {
    const storedValue = sessionStorage.getItem(value);
    return storedValue !== null && storedValue !== '' && storedValue !== 'null' ? storedValue : null;
};

const setValuesFromSS = (valueMap, valueSetter) => {
    Object.keys(valueMap).forEach((key) => {
        const storedValue = getValueFromSS(key);
        if (storedValue !== null) {
            valueSetter[key](storedValue);
        }
    });
};

module.exports = {
    getHeaderButtonStyle,
    setValuesFromSS
};