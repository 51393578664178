import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import moment from 'moment';
import React, { useState } from 'react';
import DateRangePicker2 from 'react-bootstrap-daterangepicker';
import { useSelector } from 'react-redux';
import { CPMultiSelect } from '../../../common/CPMultiSelect';
import CurrencyListItem from '../../../common/CurrencyListItem';
import ResetModal from "../CommonPages/ResetModal";
const dateImage = '/cryptonpay/crytoicons/date.png';
const closeImage = '/cryptonpay/crytoicons/close.png';
const selectedCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_filled.png';
const emptyCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_empty.png';

function TransactionFilters(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [showStatusGroupDropdown, setShowStatusGroupDropdown] = useState(false);
  const [showStatusIconDropdown, setShowStatusIconDropdown] = useState(false);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [showFiatCurrencyDropdown, setShowFiatCurrencyDropdown] =
    useState(false);
  const [showType, setShowType] = useState(false);
  const [showAcquirer, setShowAcquirer] = useState(false);
  const [showMethod, setShowMethod] = useState(false);
  const [blockchain, setBlockchain] = useState(false);
  const [network, setNetwork] = useState(false);
  const [houseKeeper, setHouseKeeper] = useState(false);
  const [issuerCountry, setIssuerCountry] = useState(false);

  const [merchantName, setMerchantName] = useState(false);
  const [value, setValue] = useState([props.minFiatAmount, props.maxFiatAmount]);
  const [openPopup, resetOpenPopup] = useState(false)
  const closeReset = () => resetOpenPopup(false)
  const [showProviderDropdown, setShowProviderDropdown] = useState(false);
  const [trafficMaskedPanData, settrafficMaskedPanData] = useState();
  const [error, setError] = useState(false);

  const handleCallback = async (start, end, label) => {
    props.setFromDate(moment(start).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    props.setToDate(moment(end).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    props.setDateSelected(true);
  };
	

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.setMinFiatAmount(newValue[0]);
    props.setMaxFiatAmount(newValue[1]);
  };
  const handleChangetrafficMaskedPan = (e) => {
    const value = e?.target?.value;
    // Allow input only up to the correct format length
    if (value?.length > 16) {
      return;
    }

    if (value?.length <= 6) {
      if (/^[0-9]{0,6}$/.test(value)) {
        settrafficMaskedPanData(value);
        props?.settrafficMaskedPan(value)
        setError(false);
      } else {
        setError(true);
      }
    } else if (value?.length <= 12) {
      if (/^[0-9]{6}\*{0,6}$/.test(value)) {
        settrafficMaskedPanData(value);
        props?.settrafficMaskedPan(value)
        setError(false);
      } else {
        setError(true);
      }
    } else if (value?.length <= 16) {
      if (/^[0-9]{6}\*{6}[0-9]{0,4}$/.test(value)) {
        settrafficMaskedPanData(value);
        props?.settrafficMaskedPan(value)
        setError(false);
      } else {
        setError(true);
      }
    }

    // If the complete format is matched, prevent further input
    if (/^[0-9]{6}\*{6}[0-9]{4}$/.test(value)) {
      settrafficMaskedPanData(value);
      props?.settrafficMaskedPan(value)
      setError(false);
    }
  };
  const togglePaymentProviderSelection = (symbol) => {
    if (props.selectedPaymentProvider.includes(symbol)) {
      let newList = props.selectedPaymentProvider.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedPaymentProvider(newList);
    } else {
      let newList = [...props.selectedPaymentProvider];
      newList.push(symbol);
      props.setSelectedPaymentProvider(newList);
    }
  };

  const toggleMerchantNameSelection = (name, merchantId, id) => {
    if (props.selectedMerchantName.map(list => list.name).includes(name)) {
      let newList = props.selectedMerchantName.filter(
        (symbolObj) => symbolObj.name !== name
      );
      props.setSelectedMerchantName(newList);
    } else {
      let newList = [...props.selectedMerchantName];
      newList.push({ '_id': id, 'name': name, 'merchantId': merchantId });
      props.setSelectedMerchantName(newList);
    }
  };

  const toggleStatusSelection = (symbol) => {
    if (props.selectedStatusList.includes(symbol)) {
      let newList = props.selectedStatusList.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedStatusList(newList);
    } else {
      let newList = [...props.selectedStatusList];
      newList.push(symbol);
      props.setSelectedStatusList(newList);
    }
  };
  const toggleStatusGroupSelection = (symbol) => {
    if (props.selectedStatusGroupList.includes(symbol)) {
      let newList = props.selectedStatusGroupList.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedStatusGroupList(newList);
    } else {
      let newList = [...props.selectedStatusGroupList];
      newList.push(symbol);
      props.setSelectedStatusGroupList(newList);
    }
  };
  const toggleStatusIconSelection = (symbol) => {
    props.setSelectedStatusIconList(symbol)
  };
  const toggleTypeSelection = (symbol) => {
    if (props.selectedType.includes(symbol)) {
      let newList = props.selectedType.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedType(newList);
      if (
        props.filter_name !== "client_transaction" &&
        props.resetSelectedStatus
      ) {
        props.resetSelectedStatus(newList);
      }
    } else {
      let newList = [...props.selectedType];
      newList.push(symbol);
      props.setSelectedType(newList);
      if (
        props.filter_name !== "client_transaction" &&
        props.resetSelectedStatus
      ) {
        props.resetSelectedStatus(newList);
      }
    }
  };
  const toggleAcquirerSelection = (name) => {
    if (props.selectedAcquirer.includes(name)) {
      let newList = props.selectedAcquirer.filter(
        (Obj) => Obj !== name
      );
      props.setSelectedAcquirer(newList);
    } else {
      let newList = [...props.selectedAcquirer];
      newList.push(name);
      props.setSelectedAcquirer(newList);
    }
  };
  const toggleMethodSelection = (symbol) => {
    if (props.selectedMethod.includes(symbol)) {
      let newList = props.selectedMethod.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedMethod(newList);
    } else {
      let newList = [...props.selectedMethod];
      newList.push(symbol);
      props.setSelectedMethod(newList);
    }
  };
  const toggleBlockchainSelection = (symbol) => {
    if (props.selectedBlockchainList.includes(symbol)) {
      let newList = props.selectedBlockchainList.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.SetSelectedBlockchainList(newList);
    } else {
      let newList = [...props.selectedBlockchainList];
      newList.push(symbol);
      props.SetSelectedBlockchainList(newList);
    }
  };

  const toggleNetworkSelection = (symbol) => {
    if (props.selectedNetworkList.includes(symbol)) {
      let newList = props.selectedNetworkList.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedNetworkList(newList);
    } else {
      let newList = [...props.selectedNetworkList];
      newList.push(symbol);
      props.setSelectedNetworkList(newList);
    }
  };

  const toggleHouseKeeperSelection = (symbol) => {
    if (props.selectedHousekeeperList.includes(symbol)) {
      let newList = props.selectedHousekeeperList.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedHousekeeperList(newList);
    } else {
      let newList = [...props.selectedHousekeeperList];
      newList.push(symbol);
      props.setSelectedHousekeeperList(newList);
    }
  };

	// kris: for new date range component
	const formatDate = (date) => moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
 
  const toggleSelection = (symbol) => {
    if (props.selectedCryptos.includes(symbol)) {
      let newList = props.selectedCryptos.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedCryptos(newList);
    } else {
      let newList = [...props.selectedCryptos];
      newList.push(symbol);
      props.setSelectedCryptos(newList);
    }
  };
  const toggleSelectionIssuerCountry = (countryObj) => {
    const selectedList = props.selectedIssuerCountryList || [];
    const isSelected = selectedList.some(obj => obj._id === countryObj._id);
    if (isSelected) {
      const newList = selectedList.filter(dataObj => dataObj._id !== countryObj._id);
      props.setSelectedIssuerCountryList(newList);
    } else {
      const newList = [...selectedList, countryObj];
      props.setSelectedIssuerCountryList(newList);
    }
  };
  
  const toggleFiatSelection = (symbol) => {
    if (props.selectedFiatCurrency.includes(symbol)) {
      let newList = props.selectedFiatCurrency.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedFiatCurrency(newList);
    } else {
      let newList = [...props.selectedFiatCurrency];
      newList.push(symbol);
      props.setSelectedFiatCurrency(newList);
    }
  };
  const validateMaskedPan = (input)=> {
    const regex = /^[0-9]{6}\*{6}[0-9]{4}$/;
    return regex.test(input);
  }
  
  const handleSaveDetails = () => {
    if(trafficMaskedPanData?.length>0){
      if(validateMaskedPan(trafficMaskedPanData)){
        props?.handleTransactionFilters();
        props?.handleSave();
        setError(false);
      }
      else{
        setError(true);
      }
    }
    else{
      props.handleTransactionFilters();
      props.handleSave();
    }
  };
  const handleDropdownClick = (dropdownSetter, toggle) => {
    setShowCurrencyDropdown(false);
    setShowStatusDropdown(false);
    setShowStatusGroupDropdown(false);
    setShowFiatCurrencyDropdown(false);
    setBlockchain(false);
    setShowProviderDropdown(false);
    setNetwork(false);
    setMerchantName(false)
    setShowMethod(false)
    setShowType(false)
    setShowAcquirer(false)
    setShowStatusIconDropdown(false)
    setIssuerCountry(false)
    toggle ? dropdownSetter(false) : dropdownSetter(true)
  };

  const sortDropDownData = (data, field) => {
    if (field) {
      const filteredData = data.filter(item => item[field] !== undefined && item[field] !== null);
      return filteredData.sort((a, b) => {
        if (!a[field] && !b[field]) {
          return 0;
        }
        if (!a[field]) {
          return -1;
        }
        if (!b[field]) {
          return 1;
        }
        return a[field].localeCompare(b[field], { sensitivity: 'accent' });
      });
    } else {
      return  data && data.sort((a, b) => a.localeCompare(b, { sensitivity: 'accent' }));
    }
  };
  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <ResetModal open={openPopup} close={closeReset} reset={props.resetFilters} />
        <div
          className="filter_clear"
          data-e2e="filter-clear-btn"
          onClick={() => resetOpenPopup(true)}
        >
          Clear
        </div>
        <div className="filter_heading">Filters</div>
        <div
          className="filter_close_button"
          onClick={() => {
            props.handleTransactionFilters();
          }}
        >
          <img
            src={`${image_base_url}${closeImage}`}
            alt="closeImage"
            data-e2e="close-icon"
          />
        </div>
      </div>
      <div className="FilterModalInputGroup">
        <div className="FilterModalInputLabel">Crypto Currency</div>
        <div
          className="FilterModalInputDropdown"
          onClick={() => {
           handleDropdownClick(setShowCurrencyDropdown)
          }}
        >
          <div className="FilterModalInputText" data-e2e="crypto-currency-expand">
            {props.selectedCryptos.length === props.cryptos.length ||
              props.selectedCryptos.length === 0
              ? 'All'
              : `${props.selectedCryptos.length} Selected`}
          </div>
          <i
            className="fa fa-chevron-down FilterModalInputDropIcon"
          />
        </div>
        {showCurrencyDropdown && (
          <CPMultiSelect
            dataE2eLabel="crypto-urrency-"
            items={sortDropDownData(props.cryptos, 'name')}
            selectedItems={
              props.selectedCryptos
            }
            setShowDropDown={setShowCurrencyDropdown}
            handleDropdownClick={handleDropdownClick}
            setSelectedItems={(items) =>{
                if(props.filter_name === "wallet"){
                   props.setSelectedCryptos(items.map(a => a._id) || [])
                }else{
                  props.setSelectedCryptos(items.map(a => a.symbol) || [])

                }}
            }
            ItemComponent={({ item: crypto }) => {
              let { name, symbol, _id, icon } = crypto;
              return (
                <div
                  className="currencyListItemWrap"
                  onClick={() => {
                    props.filter_name === 'wallet'
                      ? toggleSelection(_id)
                      : toggleSelection(symbol);
                  }}
                >
                  <div className="currencyListIconNameWrap">
                    <img
                      src={`${image_base_url}${icon}`}
                      alt={symbol}
                      className="currencyList_Icon"
                    />
                    <div className="currencyList_Name" data-e2e={name.toLowerCase().replaceAll(' ', '-')}>
                      {name}
                    </div>
                  </div>
                  <img
                    data-e2e={`${name}-checkbox`}
                    src={`${image_base_url}${props.filter_name === 'wallet'
                      ? props.selectedCryptos.includes(_id)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                      : props.selectedCryptos.includes(symbol)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                      }`}
                    alt="checkbox"
                    className="currencyListCheckBox"
                  />
                </div>
              );
            }}
          />)
        }
      </div>
			
      {props.filter_name === 'client_transaction' ? null : (
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Date Range</div>
						<DateRangePicker2
							onCallback={handleCallback}
							initialSettings={{
								startDate: moment(props.fromDate),
								endDate: moment(props.toDate),
							}}
						>
							<div
								className="FilterModalInputDropdown"
								data-e2e="FilterModalInputDropdown"
							>
								<img
									src={`${image_base_url}${dateImage}`}
									alt="dateImage"
									className="FilterModalInputDropdownDateImage"
								/>
								<div className="FilterModalInputDropdownDateTextWrap">
								<span data-e2e="start-date">{`${props.dateSelected
									? moment(props.fromDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
										'D MMMM'
									)
									: 'Start Date'
								}`}</span>
									<span style={{ color: 'black' }}>{` To `}</span>
									<span style={{ marginRight: '15%' }} data-e2e="end-date">{`${props.dateSelected
										? moment(props.toDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
											'D MMMM'
										)
										: 'End Date'
									}`}</span>
								</div>
							</div>
						</DateRangePicker2>
        </div>
      )}
      { !props.paymentProvider && props.adminRole == "SuperAdmin" &&
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">{`Payment Providers`}</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
             handleDropdownClick(setShowProviderDropdown)
            }}
          >
            <div className="FilterModalInputText">
              {props.selectedPaymentProvider.length === props.paymentProviderList.length ||
                props.selectedPaymentProvider.length === 0
                ? 'All'
                : `${props.selectedPaymentProvider.length} Selected`}
            </div>
            <i
              className="fa fa-chevron-down FilterModalInputDropIcon"
            />
          </div>
          {showProviderDropdown && (
            <CPMultiSelect
              dataE2eLabel="provider-"
              setShowDropDown={setShowProviderDropdown}
              handleDropdownClick={handleDropdownClick}
              items={sortDropDownData(props.paymentProviderList, 'firstName')}
              selectedItems={
                props.selectedPaymentProvider
              }
              setSelectedItems={(items) =>
                props.setSelectedPaymentProvider(items.map(a => a._id) || [])}


              ItemComponent={({ item: provider }) => {

                return (
                  <div

                    className="currencyListItemWrap"
                    onClick={() => {
                      togglePaymentProviderSelection(provider._id);
                    }}
                  >
                    <div
                      className="currencyListIconNameWrap"
                    >
                      <div className="currencyList_Name" id="available-test">{provider.firstName + " " + provider.lastName}</div>
                    </div>
                    <img
                      src={`${image_base_url}${props.selectedPaymentProvider.includes(provider._id)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                        }`}
                      alt="checkbox"
                      className="currencyListCheckBox"
                    />
                  </div>
                );
              }}
            />)
          }
        </div>
      }
      
      {props.filter_name === 'wallet' ? null : (
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Type</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setShowType)
            }}
          >
            <div
              className="FilterModalInputText"
            >
              {props.selectedType.length === props.type.length ||
                props.selectedType.length === 0
                ? 'All'
                : `${props.selectedType.length} Selected`}
            </div>
            <i
              className="fa fa-chevron-down FilterModalInputDropIcon"
              data-e2e="type-expand"
            />
          </div>
          {showType && (
            <CPMultiSelect
              dataE2eLabel="type-"
              items={sortDropDownData(props.type)}
              selectedItems={
                props.selectedType
              }
              setShowDropDown={setShowType}
              handleDropdownClick={handleDropdownClick}
              setSelectedItems={(items) =>
                props.setSelectedType(items.map(a => a) || [])}
              ItemComponent={({ item: status }) => {
                return <div

                  className="currencyListItemWrap"
                  onClick={() => {
                    toggleTypeSelection(status);
                  }}
                >
                  <div
                    className="currencyListIconNameWrap"
                  >
                    <div className="currencyList_Name" style={{ textTransform: 'capitalize' }} data-e2e={status.toLowerCase().replaceAll(' ', '-')}>{status}</div>
                  </div>
                  <img
                    src={`${image_base_url}${props.selectedType.includes(status)
                      ? selectedCheckBoxIcon
                      : emptyCheckBoxIcon
                      }`}
                    alt="checkbox"
                    className="currencyListCheckBox"
                  />
                </div>
              }}
            />)
          }
        </div>
      )}
      {props.filter_name === 'wallet' || !props.showAcquirerDropD ? null : (
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Acquirer</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setShowAcquirer)
            }}
          >
            <div
              className="FilterModalInputText"
            >
              {props.selectedAcquirer.length === props.aquireNameData.length ||
                props.selectedAcquirer.length === 0
                ? 'All'
                : `${props.selectedAcquirer.length} Selected`}
            </div>
            <i
              className="fa fa-chevron-down FilterModalInputDropIcon"
              data-e2e="type-expand"
            />
          </div>
          {showAcquirer && (
            <CPMultiSelect
              dataE2eLabel="type-"
              items={sortDropDownData(props.aquireNameData)}
              selectedItems={
                props.selectedAcquirer
              }
              setShowDropDown={setShowAcquirer}
              handleDropdownClick={handleDropdownClick}
              setSelectedItems={(items) =>
                props.setSelectedAcquirer(items.map(a => a) || [])}
              ItemComponent={({ item: name }) => {
                return <div
                  className="currencyListItemWrap"
                  onClick={() => {
                    toggleAcquirerSelection(name);
                  }}
                >
                  <div
                    className="currencyListIconNameWrap"
                  >
                    <div className="currencyList_Name" style={{ textTransform: 'capitalize' }}>{name}</div>
                  </div>
                  <img
                    src={`${image_base_url}${props.selectedAcquirer.includes(name)
                      ? selectedCheckBoxIcon
                      : emptyCheckBoxIcon
                      }`}
                    alt="checkbox"
                    className="currencyListCheckBox"
                  />
                </div>
              }}
            />)
          }
        </div>
      )}

      {props.filter_name === 'wallet' || props.statusGroupList &&  props.statusGroupList.length === 0 ? null : (<div className="FilterModalInputGroup">
        <div className="FilterModalInputLabel">Status Group</div>
        <div
          className="FilterModalInputDropdown"
          onClick={() => {
            handleDropdownClick(setShowStatusGroupDropdown)
            
          }}
        >
          <div className="FilterModalInputText" data-e2e="status-exapnd">
            {!props.selectedStatusGroupList || props.selectedStatusGroupList.length === props.statusGroupList.length ||
              props.selectedStatusGroupList.length === 0
              ? 'All'
              : `${props.selectedStatusGroupList.length} Selected`}
          </div>
          <i
            className="fa fa-chevron-down FilterModalInputDropIcon"
          />
        </div>
        {showStatusGroupDropdown && (
          <CPMultiSelect
            dataE2eLabel="status-"
            setShowDropDown={setShowStatusGroupDropdown}
            handleDropdownClick={handleDropdownClick}
            items={sortDropDownData(props.statusGroupList)}
            smallName={"currencyList_Name_small"}
            selectedItems={
              props.selectedStatusGroupList
            }
            setSelectedItems={(items) =>
              props.setSelectedStatusGroupList(items.map(a => a) || [])}


            ItemComponent={({ item: status }) => {

              return (
                <div

                  className="currencyListItemWrap"
                  onClick={() => {
                    toggleStatusGroupSelection(status);
                  }}
                >
                  <div
                    className="currencyListIconNameWrap"
                  >
                    <div className="currencyList_Name currencyList_Name_small" id="available-test" data-e2e={status.toLowerCase().replaceAll(' ', '-')}>{status}</div>
                  </div>
                  <img
                    src={`${image_base_url}${props.selectedStatusGroupList.includes(status)
                      ? selectedCheckBoxIcon
                      : emptyCheckBoxIcon
                      }`}
                    alt="checkbox"
                    className="currencyListCheckBox"
                    data-e2e={`${status.toLowerCase().replaceAll(' ', '-')}-checkbox`}
                  />
                </div>
              );
            }}
          />)
        }
      </div>)}

      {props.filter_name === 'wallet' && props.CentralisedWallets && (
      <div className='FilterModalInputGroup'>
      <div className="FilterModalInputLabel update-merchant-label">Status Icon</div>

        <div
          className="FilterModalInputDropdown"
          onClick={() => {
            handleDropdownClick(setShowStatusIconDropdown)
          }}
        >
          <div className="FilterModalInputText">{props.selectedStatusIconList}</div>
          <i
            className="fa fa-chevron-down FilterModalInputDropIcon"
          />
        </div>
        {showStatusIconDropdown && (
          <div className="FilterModalCurrencyDropdownlist update-merchant-settings-dropdown">
            <div
              className="FM_currencyDrop_All"
              onClick={() => {
                handleDropdownClick(setShowStatusIconDropdown, true)
              }}
            >
              <span className="FM_currencyDrop_AllSelector">
                {props.selectedStatusIconList}
              </span>
              <span className="FilterModalInputDropUpIconWrap">
                <i
                  className="fa fa-chevron-up FilterModalInputDropUpIcon"
                />
              </span>
            </div>
            {props.statusIconList.map((status, index) => {
              return (
                <div
                  key={index}
                  className="currencyListItemWrap"
                  onClick={() => {
                    toggleStatusIconSelection(status);
                    handleDropdownClick(setShowStatusIconDropdown, true)
                  }}
                >
                  <div className="currencyListIconNameWrap">
                    <div
                      className="currencyList_Name"
                    >
                      {status}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
    </div>
      )}
      {props.filter_name !== 'client_transaction' && (
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Status</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setShowStatusDropdown)
            }}
          >
            <div className="FilterModalInputText" data-e2e="status-exapnd">
              {!props.selectedStatusGroupList || props.selectedStatusList.length === props.statusList.length ||
              props.selectedStatusList.length === 0
                ? 'All'
                : `${props.selectedStatusList.length} Selected`}
            </div>
            <i
              className="fa fa-chevron-down FilterModalInputDropIcon"
            />
          </div>
          {showStatusDropdown && (
            <CPMultiSelect
              dataE2eLabel="status-"
              setShowDropDown={setShowStatusDropdown}
              handleDropdownClick={handleDropdownClick}
              items={sortDropDownData(props.statusList)}
              smallName={"currencyList_Name_small"}
              selectedItems={
                props.selectedStatusList
              }
              setSelectedItems={(items) =>
                props.setSelectedStatusList(items.map(a => a) || [])}


              ItemComponent={({ item: status }) => {

                return (
                  <div

                    className="currencyListItemWrap"
                    onClick={() => {
                      toggleStatusSelection(status);
                    }}
                  >
                    <div
                      className="currencyListIconNameWrap"
                    >
                      <div className="currencyList_Name currencyList_Name_small" id="available-test" data-e2e={status.toLowerCase().replaceAll(' ', '-')}>{status}</div>
                    </div>
                    <img
                      src={`${image_base_url}${props.selectedStatusList.includes(status)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                      }`}
                      alt="checkbox"
                      className="currencyListCheckBox"
                      data-e2e={`${status.toLowerCase().replaceAll(' ', '-')}-checkbox`}
                    />
                  </div>
                );
              }}
            />)
          }
        </div>
      )}
      {props.filter_name === 'wallet' ? null : (
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Fiat Currency</div>
          <div
            className="FilterModalInputDropdown"
            data-e2e="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setShowFiatCurrencyDropdown)
            }}
          >
            <div
              className="FilterModalInputText"
              data-e2e="fiatDropDown"
            >
              {props.selectedFiatCurrency.length ===
                props.fiatCurrency.length ||
                props.selectedFiatCurrency.length === 0
                ? 'All'
                : `${props.selectedFiatCurrency.length} Selected`}
            </div>
            <i
              className="fa fa-chevron-down FilterModalInputDropIcon"
              data-e2e="fiatDropDown"
            />
          </div>
          {showFiatCurrencyDropdown && (

            <CPMultiSelect
              dataE2eLabel="fiat-currency-"
              setShowDropDown={setShowFiatCurrencyDropdown}
              handleDropdownClick={handleDropdownClick}
              items={sortDropDownData(props.fiatCurrency, 'name')}
              selectedItems={
                props.selectedFiatCurrency
              }
              setSelectedItems={(items) =>
                props.setSelectedFiatCurrency(items.map(a => a.symbol) || [])}


              ItemComponent={({ item: obj }) => {
                let { name, symbol, icon } = obj;
                return (
                  <div

                    className="currencyListItemWrap"
                    data-e2e="currencyListItemWrap"
                    onClick={() => {
                      toggleFiatSelection(symbol);
                    }}
                  >
                    <div className="currencyListIconNameWrap">
                      <img
                        src={`${image_base_url}${icon}`}
                        alt={symbol}
                        className="currencyList_Icon"
                        data-e2e="currencyList_Icon"
                      />
                      <div className="currencyList_Name">{name}</div>
                    </div>
                    <img
                      src={`${image_base_url}${props.selectedFiatCurrency.includes(symbol)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                        }`}
                      alt="checkbox"
                      className="currencyListCheckBox"
                      data-e2e="checkBox"
                    />
                  </div>
                );
              }} />
          )}
        </div>
      )}
      {props.filter_name === 'wallet'
      || props.filter_name === 'client_transaction'
      || props.filter_name === 'merchant_transactions'  ? null : (
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Method</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setShowMethod)
            }}
          >
            <div
              className="FilterModalInputText"
            >
              {!props.selectedMethod || (props.selectedMethod && props.selectedMethod.length === props.method.length) ||
                (props.selectedMethod && props.selectedMethod.length === 0)
                ? 'All'
                : `${props.selectedMethod ? props.selectedMethod.length : 0} Selected`}
            </div>
            <i
              className="fa fa-chevron-down FilterModalInputDropIcon"
              data-e2e="type-expand"
            />
          </div>
          {showMethod && (
            <CPMultiSelect
              dataE2eLabel="method-"
              items={sortDropDownData(props.method)}
              selectedItems={
                props.selectedMethod
              }
              setShowDropDown={setShowMethod}
              handleDropdownClick={handleDropdownClick}
              setSelectedItems={(items) =>
                props.setSelectedMethod(items.map(a => a) || [])}
              ItemComponent={({ item: status }) => {
                return <div

                  className="currencyListItemWrap"
                  onClick={() => {
                    toggleMethodSelection(status);
                  }}
                >
                  <div
                    className="currencyListIconNameWrap"
                  >
                    <div className="currencyList_Name" style={{ textTransform: 'capitalize' }} data-e2e={status.toLowerCase().replaceAll(' ', '-')}>{status}</div>
                  </div>
                  <img
                    src={`${image_base_url}${props.selectedMethod.includes(status)
                      ? selectedCheckBoxIcon
                      : emptyCheckBoxIcon
                      }`}
                    alt="checkbox"
                    className="currencyListCheckBox"
                  />
                </div>
              }}
            />)
          }
        </div>
      )}
      {props.filter_name === 'wallet' && (
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Blockchain</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setBlockchain)
            }}
          >
            <div
              className="FilterModalInputText" data-e2e="block-chain-expand"
            >
              {props.selectedBlockchainList.length === props.blockchainList.length ||
                props.selectedBlockchainList.length === 0
                ? 'All'
                : `${props.selectedBlockchainList.length} Selected`}
            </div>
            <i
              className="fa fa-chevron-down FilterModalInputDropIcon"
            />
          </div>
          {blockchain && (
            <CPMultiSelect
              dataE2eLabel="block-chain-"
              items={sortDropDownData(props.blockchainList)}

              selectedItems={
                props.selectedBlockchainList
              }
              setShowDropDown={setBlockchain}
              handleDropdownClick={handleDropdownClick}
              setSelectedItems={(items) =>
                props.SetSelectedBlockchainList(items.map(a => a) || [])}
              ItemComponent={({ item: blockchain }) => {
                return (
                  <div

                    className="currencyListItemWrap"
                    data-e2e="currencyListItemWrap"
                    onClick={() => {
                      toggleBlockchainSelection(blockchain);
                    }}
                  >
                    <div
                      className="currencyListIconNameWrap"
                      data-e2e="currencyListIconNameWrap"
                    >
                      <div className="currencyList_Name" data-e2e={blockchain.toLowerCase().replaceAll(' ', '-')}>{blockchain}</div>
                    </div>
                    <img
                      src={`${image_base_url}${props.selectedBlockchainList.includes(blockchain)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                        }`}
                      alt="checkbox"
                      className="currencyListCheckBox"
                    />
                  </div>
                )
              }} />)
          }
        </div>
      )}

      {(!["merchantTransactions"].includes(props.filter_merchant_name)
        && props.filter_name !== 'client_transaction'
        ) &&
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Network</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setNetwork)
            }}
          >
            <div
              className="FilterModalInputText" data-e2e="network-expand"
            >
              {props.selectedNetworkList.length === props.networkList.length ||
                props.selectedNetworkList.length === 0
                ? 'All'
                : `${props.selectedNetworkList.length} Selected`}
            </div>
            <i
              className="fa fa-chevron-down FilterModalInputDropIcon"
            />
          </div>
          {network && (

            <CPMultiSelect
              dataE2eLabel="network-"
              items={sortDropDownData(props.networkList)}
              setShowDropDown={setNetwork}
              handleDropdownClick={handleDropdownClick}
              selectedItems={
                props.selectedNetworkList
              }
              setSelectedItems={(items) =>
                props.setSelectedNetworkList(items.map(a => a) || [])}
              ItemComponent={({ item: network }) => {

                return <div

                  className="currencyListItemWrap"
                  data-e2e="currencyListItemWrap"
                  onClick={() => {
                    toggleNetworkSelection(network);
                  }}
                >
                  <div
                    className="currencyListIconNameWrap"
                    data-e2e="currencyListIconNameWrap"
                  >
                    <div className="currencyList_Name" data-e2e={network.toLowerCase().replaceAll(' ', '-')}>{network}</div>
                  </div>
                  <img
                    src={`${image_base_url}${props.selectedNetworkList.includes(network)
                      ? selectedCheckBoxIcon
                      : emptyCheckBoxIcon
                      }`}
                    alt="checkbox"
                    className="currencyListCheckBox"
                  />
                </div>
              }} />
          )}
        </div>
      }

      {/* {props.filter_name === 'wallet' ? null:(
        <div  className="FilterModalInputGroup">
        <div className="FilterModalInputLabel">Fiat Amount Range</div>
          <div style={{marginTop:'20px'}}>
            <Box sx={{ width: 370 }}>
              <Slider
                getAriaLabel={() => 'Fiat Amount Range'}
                value={value}
                min={1}
                max={1000}
                onChange={handleChange}
                valueLabelDisplay="auto"
              />
            </Box>
          </div>       
        </div>
      )} */}
      {["induvisual_transactions", "merchantTransactions"].includes(props.filter_merchant_name)
        ||  props.filter_name === 'client_transaction'
        ? null : props.filter_name !== 'wallet' && (
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Merchant Name</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setMerchantName)
            }}
          >
            <div
              className="FilterModalInputText"
            >
              {props.selectedMerchantName.length === props.merchantNameList.length ||
                props.selectedMerchantName.length === 0
                ? 'All'
                : `${props.selectedMerchantName.length} Selected`}
            </div>
            <i
              className="fa fa-chevron-down FilterModalInputDropIcon"
              data-e2e="merchant-name-expand"
            />
          </div>
          {merchantName && (

            <CPMultiSelect
              dataE2eLabel="merchant-name-"
              items={sortDropDownData(props.merchantNameList, 'name')}
              selectedItems={
                props.selectedMerchantName
              }
              containerStyle={{ overflowY: 'scroll', height: "200px" }}
              setSelectedItems={(items) =>
                props.setSelectedMerchantName(items.map(a => a) || [])}
              ItemComponent={({ item: merchant }) => {

                return (
                  <div
                    style={{ paddingTop: '10px' }}

                    className="currencyListItemWrap"
                    onClick={() => {
                      toggleMerchantNameSelection(merchant.name, merchant.merchantId, merchant._id);
                    }}
                  >
                    <div
                      className="currencyListIconNameWrap"
                    >
                      <div className="currencyList_Name" data-e2e={merchant && merchant.name && merchant.name}>{merchant.name}</div>
                    </div>
                    <img
                      src={`${image_base_url}${props.selectedMerchantName.map(list => list.name).includes(merchant.name)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                        }`}
                      alt="checkbox"
                      className="currencyListCheckBox"
                    />
                  </div>
                )
              }}
              setShowDropDown={setMerchantName}
              handleDropdownClick={handleDropdownClick}
            />
          )

          }
        </div>
      )}

      {(!["merchantTransactions"].includes(props.filter_merchant_name)
        && props.filter_name !== 'client_transaction' && props.filter_name !== 'wallet' && props.filter_name !== 'merchant_transactions' 
        ) &&
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">HouseKeeper</div>
          <div
            className="FilterModalInputDropdown"
            onClick={() => {
              handleDropdownClick(setHouseKeeper)
            }}
          >
            <div
              className="FilterModalInputText" data-e2e="network-expand"
            >
              {
                props.selectedHousekeeperList
                && props.housekeeperList
                && (props.selectedHousekeeperList.length === props.housekeeperList.length
                  || props.selectedHousekeeperList.length === 0
                  ? 'All'
                  : `${props.selectedHousekeeperList.length} Selected`
                )
              }
            </div>
            <i
              className="fa fa-chevron-down FilterModalInputDropIcon"
            />
          </div>
          {houseKeeper && (

            <CPMultiSelect
              dataE2eLabel="houseKeeper-"
              items={sortDropDownData(props.housekeeperList)}
              setShowDropDown={setHouseKeeper}
              handleDropdownClick={handleDropdownClick}
              selectedItems={
                props.selectedHousekeeperList
              }
              setSelectedItems={(items) =>
                props.setSelectedHousekeeperList(items.map(a => a) || [])}
                ItemComponent={({ item: houseKeeper }) => (
                  <CurrencyListItem
                    name={houseKeeper}
                    toggleFunction={toggleHouseKeeperSelection}
                    imageBaseUrl={image_base_url}
                    selected={props.selectedHousekeeperList}
                    selectedCheckBoxIcon={selectedCheckBoxIcon}
                    emptyCheckBoxIcon={emptyCheckBoxIcon}
                  />
                )}
               />
          )}
        </div>
      }
{      <div className="FilterModalInputGroup">
        <div className="FilterModalInputLabel">Issuer(Country)</div>
        <div
          className="FilterModalInputDropdown"
          onClick={() => {
           handleDropdownClick(setIssuerCountry)
          }}
        >
          <div className="FilterModalInputText" data-e2e="crypto-currency-expand">
            {props?.selectedIssuerCountryList?.length === props?.issuerCountryList?.length ||
              props?.selectedIssuerCountryList?.length === 0
              ? 'All'
              : `${props?.selectedIssuerCountryList?.length} Selected`}
          </div>
          <i
            className="fa fa-chevron-down FilterModalInputDropIcon"
          />
        </div>
        {issuerCountry && (
          <CPMultiSelect
            dataE2eLabel="issuer-country-"
            items={sortDropDownData(props?.issuerCountryList, 'name')}
            selectedItems={
              props?.selectedIssuerCountryList
            }
            setShowDropDown={setIssuerCountry}
            handleDropdownClick={handleDropdownClick}
            setSelectedItems={(items) =>{
                   props?.setSelectedIssuerCountryList(items.map(a => a) || [])
                }
            }
            ItemComponent={({ item: issuerCountryItem }) => {
              let { name } = issuerCountryItem;
              return (
                <div
                  className="currencyListItemWrap"
                  onClick={() => {
                   toggleSelectionIssuerCountry(issuerCountryItem);
                  }}
                >
                  <div className="currencyListIconNameWrap">
                    <div className="currencyList_Name" data-e2e={name?.toLowerCase()?.replaceAll(' ', '-')}>
                      {name}
                    </div>
                  </div>
                  <img
                    data-e2e={`${name}-checkbox`}
                    src={`${image_base_url}${
                     props?.selectedIssuerCountryList?.includes(issuerCountryItem)
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                      }`}
                    alt="checkbox"
                    className="currencyListCheckBox"
                  />
                </div>
              );
            }}
          />)
        }
      </div>}
      {
           <div className="FilterModalInputGroup">
           <div className="FilterModalInputLabel">MaskedPan</div>
           <input
             className="FilterModalInputDropdown"
             type="text"
             name="trafficMaskedPan"
             value={trafficMaskedPanData}
             placeholder="Expected format 123456******1234"
             onChange={handleChangetrafficMaskedPan}
             maxLength={16} // Limit input length to 16 characters
           />
           {error && (
             <div className="error-message-mask-pan">
               Invalid format. The sample format is 123456******1234.
             </div>
           )}
         </div>
      }
           <div className="FilterModalInputGroup">
           <div className="FilterModalInputLabel">Merchant TransactionId</div>
           <input
             className="FilterModalInputDropdown"
             type="text"
             name="merchantTransactionId"
             value={props.merchantTransactionId}
             placeholder="Merchant TransactionId"
             onChange={e=>{props.setMerchantTransactionId(e?.target?.value)}}
           />
         </div>
         <div className="FilterModalInputGroup">
           <div className="FilterModalInputLabel">Acquirer TransactionId</div>
           <input
             className="FilterModalInputDropdown"
             type="text"
             name="acquirerTransactionReference"
             value={props.acquirerTransactionReference}
             placeholder="Acquirer TransactionId"
             onChange={e=>{props.setAcquirerTransactionReference(e?.target?.value)}}
           />
         </div>
      {['wallet', 'client_transaction'].includes(props.filter_name)
      || props.filter_merchant_name === "merchantTransactions" ? null : (
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Fiat Amount Range</div>
          <div style={{ marginTop: '20px' }}>
            <Box sx={{ width: 370 }}>
              <Slider
                getAriaLabel={() => 'Fiat Amount Range'}
                value={value}
                min={1}
                max={1000}
                onChange={handleChange}
                valueLabelDisplay="auto"
              />
            </Box>
          </div>
        </div>
      )}
      {props.filter_name === 'wallet' && (props.minCryptoAmount || props.minCryptoAmount == "") ? (
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Crypto Currency Amount Range</div>
          <div className='MinMaxCryptoRangeWrap'>
            <input className="rangeInput rangeInputLeft" type="text" placeholder='Min Amount'
              name="minCryptoAmount"
              value={props.minCryptoAmount}
              onChange={(e) => { props.setMinCryptoAmount(e.target.value) }} />
            <input className="rangeInput rangeInputRight" type="text" placeholder='Max Amount'
              name="maxCryptoAmount"
              value={props.maxCryptoAmount}
              onChange={(e) => { props.setMaxCryptoAmount(e.target.value)}} />
          </div>
          {props.rangeError !== "" && <div style={{ color: "red", marginTop: "10px" }} >{props.rangeError}</div>}
        </div>
      ) : null}

      { props.filter_name === "wallet" ?
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Crypto Currency Amount</div>
          <input className="FilterModalInputDropdown FilterModalInputText" type="text" placeholder='Amount'
              name="cryptoAmount"
              value={props.cryptoAmount}
              onChange={(e) => { props.handleCryptoAmountChange(e.target.value) }} />
        </div>:null}
      {["merchantTransactions"].includes(props.filter_merchant_name) ? null :
        <div className="ContainsNoteFilterWrap" onClick={() => {
          props.changeIsContainsNote();
        }}>
          <img
            src={`${image_base_url}${props.isContainsNote
              ? selectedCheckBoxIcon
              : emptyCheckBoxIcon
              }`}
            alt="checkbox"
            className="currencyListCheckBox"
          />
          <div className="ContainsNoteFilterName" >{"Contains a Note"}</div>
        </div>
      }

      <div
        className={`FilterModalSave ${!props.rangeError || props.rangeError == "" ? "" :"FilterModalSaveDisabled"}`}
        data-e2e="save-btn"
        onClick={() => {
          if (!props.rangeError || props.rangeError === "") {
            handleSaveDetails();
          }
        }}
      >
        SAVE
      </div>
    </div>

  );
}

export default TransactionFilters;
