import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Select from "react-select";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import AddOrEditCity from "./AddOrEditCity";
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  UPDATE_CUSTOMCITY,
  DELETE_CUSTOMCITY,
} from "../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../config/axios";
import Loading from "../../../common/Loading";

function DeletingAlert(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const base_url = useSelector((state) => state.config.api_url);
  const [loading, setLoading] = useState(false);

  const handleDeleteCity = async () => {
    var payloadDeleteCity = {
      country_id: props.country_id,
      deleteCity: {
        stateName: props.stateName,
        cityName: props.cityName,
      },
    };

    setLoading(true);
    let URL;
    URL = {
      ...UPDATE_CUSTOMCITY,
      url: base_url + UPDATE_CUSTOMCITY.url,
    };

    await call(URL, payloadDeleteCity)
      .then((res) => {
        setLoading(false);
        props.setShowModel(false);
        props.setOpenDeleteIcon(false);
        window.location.reload();
        if (res.status === 200) {
          props?.setReload(!props?.reload);
        }
      })
      .catch((err) => {
        setLoading(false);
        window.location.reload();
        logoutAfterError(err);
      });
  };

  return (
    
      
        <Dialog
          open={props.showModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
        >
          <div>
            <Modal
              show={props.showModel}
              className="ClearAlertModal deleteAlertModal ClearAlertModals"
              onHide={() => {
                props?.onHide();
                props.setShowModel(false)
              }}
            >
              <Modal.Body className="ClearAlertModalBody">
                <div className="ClearAlertModalIconWrap">
                  <img
                    src={`${image_base_url}${deleteIcon}`}
                    onClick={() => {
                      props?.onHide();
                      window.location.reload();
                    }}
                    alt="resetIcon"
                    className="ClearAlertModalIcon"
                  />
                </div>
                <div className="ClearAlertModalTitle">Are you sure?</div>
                <div className="ClearAlertModalDescription">{`Are you sure you want to delete ${props.cityName} city from ${props.stateName} state? This action cannot be undone.`}</div>
                <div
                  className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
                  style={{ background: "#E02020" }}
                  onClick={() => {
                    handleDeleteCity();
                  }}
                  data-e2e="clear"
                >
                  Delete
                </div>
                <div
                  className="ClearAlertModalGoBackBtn"
                  onClick={() => {
                    props.onHide();
                    window.location.reload();
                    props.setOpenDeleteIcon(false);
                    props.setShowModel(false)
                  }}
                >
                  Go Back
                </div>
              </Modal.Body>
            </Modal>
          </div>
          {loading && <Loading />}
        </Dialog>
    
    
    
  );
}
export default DeletingAlert;
