import  React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Select from "react-select";
import StateAccordian from "./StateAccordian";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AddOrEditCity from "./AddOrEditCity";
import AddOrEditCountry from "./AddOrEditCountry"
import AddOrEditState from "./AddOrEditState";
import { isViewPermissionValid } from "../../../common/CommonUtils";
export default function SingleAccordion({
  country_id,
  country,
  countryCode,
  stateData,
  setReload,
  reload
}) {
  const [addNew, setAddNew] = useState(false);
  const [editData, setEditData] = useState([]);
  const [editCity, setEditCity] = useState(false);
  const [openEditCountryIcon, setOpenEditCountryIcon] = useState(false);
  const [editCountry, setEditCountry] = useState(false);
  const [openEditStateIcon, setOpenEditStateIcon] = useState(false);
  const handleCloseAddNew = () => {
    setAddNew(false);
    setEditCity(false);
    setEditCountry(false)
    setOpenEditCountryIcon(false);

  };
  
  const openAddDialog = () => {
    
    setOpenEditStateIcon(true);
    setAddNew(true);
  };
  const openEditCountryDialog = () => {
    
    setOpenEditCountryIcon(true);
    setEditCountry(true)
  };

  const sortDropDownData = (data, field) => {
    if (field) {
      const filteredData = data?.filter(
        (item) => item[field] !== undefined && item[field] !== null,
      );
      return filteredData?.sort((a, b) =>
        a[field].localeCompare(b[field], { sensitivity: "accent" }),
      );
    } else {
      return data?.sort((a, b) => a.localeCompare(b, { sensitivity: "accent" }));
    }
  };
  const stateDataSorted =sortDropDownData(stateData, "stateName")

  return (
    <div>
      <Accordion
        sx={{
          width: "80vh",
          maxWidth: "80vh",
          marginTop: "10px",
        }}
      >
        <AccordionSummary
          sx={{
            width: "80vh",
            maxWidth: "80vh",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="text-lg">{`${country} (${countryCode})`}</div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            width: "80vh",
            maxWidth: "80vh",
          }}
        >
          <div>
            <div className="flex justify-end gap-2">
              <Button
                variant="contained"
                sx={{ width: "auto", maxWidth: "auto", marginBottom: "10px" }}
                onClick={() => isViewPermissionValid? null: openEditCountryDialog()}
                className={`${isViewPermissionValid? "disabled": ""}`}
              >
                Edit Country
              </Button>
              <Button
                variant="contained"
                sx={{ width: "auto", maxWidth: "auto", marginBottom: "10px" }}
                onClick={() => isViewPermissionValid? null: openAddDialog()}
                className={`${isViewPermissionValid? "disabled": ""}`}
              >
                Add State
              </Button>
            </div>
            
            {stateDataSorted?.map((stateOption) => (
              <StateAccordian
                country_id={country_id}
                state={stateOption.stateName}
                stateCode={stateOption.stateCode}
                nativeStateName={stateOption.nativeStateName}
                citiesData={stateOption.allCities}
                setReload={setReload}
            reload={reload}
              />
            ))}
          </div>
          <div>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* edit Country */}
      {openEditCountryIcon && (
        <Dialog
          open={openEditCountryIcon}
          onClose={() => {
            handleCloseAddNew();
            setEditData([]);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AddOrEditCountry
            handleCloseAddNew={() => handleCloseAddNew()}
            country_id ={country_id}
            editCountry={editCountry}
            setReload={setReload}
            reload={reload}
            countryName={country}
            countryCode={countryCode}
            setAddNew={setAddNew}
            setOpenEditCountryIcon={setOpenEditCountryIcon}
          />
        </Dialog>
      )}
      {/* add State */}
      {openEditStateIcon && (
        <Dialog
          open={addNew}
          onClose={() => {
            handleCloseAddNew();
            setEditData([]);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AddOrEditState
            handleCloseAddNew={() => handleCloseAddNew()}
            country_id={country_id}
            editCity={editCity}
            setReload={setReload}
            reload={reload}
            setOpenEditStateIcon={setOpenEditStateIcon}
            setAddNew ={setAddNew}
          />
        </Dialog>
      )}
    </div>
  );
}
