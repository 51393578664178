import React from "react";
import { Table } from "reactstrap";
import { useSelector } from "react-redux";

const BlockchainSettingsTable = (props) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const data = [
    {
      name: "Max gas fee",
      value: "200.00000000 WEI",
    },
    {
      name: "Priority fee (tip)",
      value: "100.00000000 WEI",
    },
  ];

  const getCryptoIcon = (symbol) => {
    switch (symbol) {
      case "BTC":
        return "/cryptonpay/crytoicons/bitcoin1.png";
      case "ETH":
        return "/cryptonpay/crytoicons/eth1.png";
      case "BCC":
        return "/cryptonpay/crytoicons/btt_cash1.png";
      case "USDC":
        return "/cryptonpay/crytoicons/usd.png";
      case "EOS":
        return "/cryptonpay/crytoicons/eos.png";
      default:
        return "/cryptonpay/crytoicons/usd.png";
    }
  };

  return (
    <div className="TransactionTableMain">
      {data.length > 0 ? (
        <Table responsive>
          <tbody className="TransactionTable_BODY merchant_table_text_align">
            <tr className="TransactionTable_BODY_Header">
              <th className="TransactionTable_heading" data-e2e="blockchain">
                <img
                  src={`${image_base_url}${getCryptoIcon()}`}
                  alt={"logo"}
                  className="etherumLogo"
                  height={20}
                  width={20}
                />{" "}
                <span className="blockchain-setting-heading">ETHEREUM SETTINGS{" "}</span>
              </th>
              <th className="TransactionTable_heading" data-e2e="crypto"></th>
            </tr>
            {data.map((TData, i) => {
              return <SingleTransactionTableRow data={TData} key={i} />;
            })}
          </tbody>
        </Table>
      ) : (
        <div className="No_Transactions_Found" data-e2e="No_Transactions_Found">
          No Data Found
        </div>
      )}
      {props.showMoreButton ? (
        <div className="Show_more_wrap">
          <div
            className="Show_more"
            data-e2e="showMore"
            onClick={() => {
              props.handleShowMore();
            }}
          >
            Show More
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BlockchainSettingsTable;

const SingleTransactionTableRow = (props) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);

  let { data } = props;

  return (
    <tr className="CP_TableRow housekeeping-table-row">
      <td className="transactionsTable_item">
        {(data && data.name && data.name) || "N/A"}
      </td>
      <td className="transactionsTable_item table-row-underline">
        {(data && data.name && data.value) || "N/A"}
      </td>
    </tr>
  );
};
