class LocalDB {
  getVal(key) {
    if (key === undefined) {
      return;
    }
    return typeof localStorage !== 'undefined' && localStorage.getItem(key);
  }

  setVal(key, val) {
    if (key === undefined || val === undefined) {
      return;
    }
    typeof localStorage !== 'undefined' && localStorage.setItem(key, val);
  }

  getObject(key) {
    if (key === undefined) {
      return {};
    }
    return typeof localStorage !== 'undefined' && localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : {};
  }

  setObject(key, val) {
    if (key === undefined || val === undefined) {
      return;
    }
    typeof localStorage !== 'undefined' &&
      localStorage.setItem(key, JSON.stringify(val));
  }

  remove(key) {
    if (key === undefined) {
      return;
    }
    typeof localStorage !== 'undefined' && localStorage.removeItem(key);
  }
  clear() {
    typeof localStorage !== 'undefined' && localStorage.clear();
  }
}
const LocalDBObj = new LocalDB()
export default LocalDBObj;
