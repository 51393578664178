import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import DateRangePicker2 from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

export default function CardDashboardFilter(props) {
  const { setFromDate, setToDate, fromDate, toDate } = props;
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const handleCallback = (start, end) => {
    props.setFromDate(moment(start).format("YYYY-MM-DD HH:mm:ss"));
    props.setToDate(moment(end).format("YYYY-MM-DD HH:mm:ss"));
    props.setDateSelected(true);
  };

  return (
    <div className="card_filter_list_date">
      <div className="FilterModalInputGroup">
        <div className="FilterModalInputLabel">Date Range</div>
        <div className="transactionsFilter-date-ranger">
          <DateRangePicker2
            onCallback={handleCallback}
            initialSettings={{
              startDate: moment(fromDate),
              endDate: moment(toDate),
              timePicker: true, // Enable time picker
              timePicker24Hour: true, // Use 24-hour format
              locale: {
                format: "YYYY-MM-DD HH:mm:ss", // Date and time format
              },
            }}
          >
            <div
              className="FilterModalInputDropdown"
              data-e2e="FilterModalInputDropdown"
            >
              <img
                src={`${image_base_url}/cryptonpay/crytoicons/date.png`}
                alt="dateImage"
                className="FilterModalInputDropdownDateImage"
              />
              <div className="FilterModalInputDropdownDateTextWrap">
                <span data-e2e="start-date">
                  {props?.dateSelected
                    ? moment(fromDate).format("D MMMM HH:mm")
                    : "Start Date"}
                </span>
                <span className="text-black">{" To "}</span>
                <span style={{ marginRight: "15%" }} data-e2e="end-date">
                  {props?.dateSelected
                    ? moment(toDate).format("D MMMM HH:mm")
                    : "End Date"}
                </span>
              </div>
            </div>
          </DateRangePicker2>
        </div>
      </div>
    </div>
  );
}
