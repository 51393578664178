import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader } from '../../../Redux/actions/commonAction';
import { call, logoutAfterError } from "../../../../config/axios";
import {
  VIEW_SINGLE_CENTRALISED_WALLET
} from "../../../../config/endpoints";
import localDb from "../../../../localDb"
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import TransactionView from '../CommonPages/TransactionView';
import Dialog from '@mui/material/Dialog';
// import UpdateMerchant from '../Wallets/UpdateMerchant';
import Deposit from './Deposits';
import { useParams } from 'react-router-dom';
import Loading from '../../../common/Loading';

function WalletView() {
  const dispatch = useDispatch();

  const [id, setId] = useState('');
  const [fullId, setFullId] = useState('');
  const [depositFlow, setDepositFlow] = useState(false);
  const [data, setData] = useState([])
  const [UseWalletId, setUserWalletId] = useState(useParams().id);
  const [walletId, setWalletId] = useState(null);
  const email = localDb.getVal("email");

  const view_transaction_url = useSelector((state) => state.config.api_url);

  const [loading, setLoading] = useState(false);


  const handleId = (id) => {
    setId(id);
  };

  const handleFullId = (id) => {
    setFullId(id)
  }

  const handleFlag = () => {
    window.location.reload()
  }

  const handleClickOpenSettings = () => {
    setDepositFlow(true);
  };

  const handleCloseSettings = () => {
    setDepositFlow(false);
    window.location.reload()
  };

  const handleUpdateSettings = () => {
    handleClickOpenSettings();
  };

  const sub_name_last = walletId
    ? "Wallet Address: " + walletId.substring(0, 8) + '.....' + walletId.substring(walletId.length - 6, walletId.length)
    : 'N/A';
  const walletCpyAdd = id ? id : ""

  useEffect(() => {
    dispatch(setHeader('Merchant User Wallets'));
    viewSingleWalletDetails();
  }, []);

  const viewSingleWalletDetails = async () => {
    setLoading(true)
    await call(
      {
        ...VIEW_SINGLE_CENTRALISED_WALLET,
        url: view_transaction_url + VIEW_SINGLE_CENTRALISED_WALLET.url,
      },
      { email: email, walletId: UseWalletId }
    )
      .then((res) => {

        if (res.status === 200) {
          setData(res.data.wallets ? res.data.wallets : [])
          setWalletId(res.data.wallets.address.key);
          setLoading(false)

        }
      })
      .catch((err) => {
        console.log(err.message, "err");
        setLoading(false)
        logoutAfterError(err)
      });
  };

  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
				<div className="">
					{
						walletId ?
							<React.Fragment>
								<CommonHeader data={data} copyButton={true} view="centralised wallets" backButton={true} name={sub_name_last} id={id} handleFlag={handleFlag} handleUpdateSettings={handleUpdateSettings} fullId={walletId} walletCpyAdd={walletCpyAdd} transMode={'true'} />
								<Dialog
									open={depositFlow}
									onClose={handleCloseSettings}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
								>
									<Deposit handleClose={handleCloseSettings} id={id} />
									{/* <UpdateMerchant handleCloseSettings={handleCloseSettings} id={id} /> */}
								</Dialog>

								<div>
									<TransactionView view="centralised wallets" handleId={handleId} handleFullId={handleFullId} isCenterlisedWallet={true} data={data} setData={setData}/>
								</div>
							</React.Fragment> :
							<React.Fragment>
								<CommonHeader copyButton={true} view="centralised wallets" backButton={true} name={UseWalletId} id={id} handleFlag={handleFlag} handleUpdateSettings={handleUpdateSettings} fullId={walletId} walletCpyAdd={walletCpyAdd} transMode={'true'} />
								{!walletId && !loading && <div className="d-flex justify-content-center h2 mt-5">{`Wallet not found.`}</div>}
							</React.Fragment>
					}
				</div>

      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
}

export default WalletView;
