import React, { useState } from 'react'
import { Table } from 'reactstrap';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { SortByIcon } from '../../../../common/CommonUtils';

const DepositHashTable = (props) => {
    const [loading, setLoading] = useState(false);
    return (
        <div style={{ paddingLeft: "0px", paddingRight: "0px" }} className="TransactionTableMain w-[100%]">
            {props && props.countTable && props.countTable.length > 0 ? (
                <Table responsive style={{ border: 'none' }} className="relative">
                    <tbody className="TransactionTable_BODY " style={{ overflowY: "scroll", maxHeight: "500px", width: "100%" }} >
                        <tr className="TransactionTable_BODY_Header" >
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                data-e2e="crypto"
                            >
                                TYPE
                            </th>
                            {/* <th
                                className="TransactionTable_heading"
                                width="16%"
                                data-e2e="crypto"
                            >
                                METHOD
                            </th> */}
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                data-e2e="crypto"
                            >
                                STATUS
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                data-e2e="crypto"
                                onClick={() => {
                                    props.changeOrder("count");
                                }}
                            >
                                TOTAL
                                {props.sortBy === "count" && (
                                    <SortByIcon orderBy={props.orderBy} data-e2e="name" />
                                )}
                            </th>
                        </tr>
                        {props.countTable.map((TData, i) => {
                            return <SingleTransactionTableRow time={props.time} method={props.method} data={TData} key={i} loading={loading} setLoading={setLoading} getSweepLogs={() => { props.getSweepLogs() }} />;
                        })}
                    </tbody>
                </Table>
            ) : (
                <div className="No_Transactions_Found">No Data Found</div>
            )}
        </div>
    )
}

export default DepositHashTable



const SingleTransactionTableRow = (props) => {
    let { data } = props;
    return (
        <tr className="CP_TableRow" >
            <td className="transactionsTable_item" >
                <Link to={`/transactions?type=${data._id.type}&status=${[data._id.statuscode]}&method=${props.method}&time=${props.time}`} className="w-full">
                    <Tooltip
                        title={data._id.type}
                    >
                        <div>
                            {data._id.type.substring(0, 15)}
                        </div>
                    </Tooltip>
                </Link>
            </td>
            {/* <td className="transactionsTable_item">
                {data.method ? data.method : "N/A"}
            </td> */}
            <td className="transactionsTable_item">
                <Link to={`/transactions?type=${data._id.type}&status=${data._id.statuscode}&method=${props.method}&time=${props.time}`} className="w-full">

                    <Tooltip
                        title={data._id.statuscode}
                        placement="top"
                    >
                        <div>
                            {data._id.statuscode ? data._id.statuscode.substring(0, 45) : "N/A"}
                        </div>

                    </Tooltip>

                </Link>
            </td>
            <td className="transactionsTable_item">
                <Link to={`/transactions?type=${data._id.type}&status=${[data._id.statuscode]}&method=${props.method}&time=${props.time}`} className="w-full">

                    {data.count}
                </Link>
            </td>

        </tr>
    );
};
