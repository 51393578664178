import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { call } from '../../../../config/axios';
import {
  CREATE_PAYMENT_PROVIDER_USER,
  UPDATE_PAYMENT_PROVIDER_USER,
} from '../../../../config/endpoints';
import localDb from '../../../../localDb';
import Loading from '../../../common/Loading';

const closeImage = '/cryptonpay/crytoicons/close.png';
const selectedCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_filled.png';
const emptyCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_empty.png';

const CreatePaymentProviderUserComp = (props) => {
  const { paymentProviderId, editPaymentProvideUser, editData } = props;
  const base_url = useSelector((state) => state.config.api_url);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal('email');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    postcode: '',
    password: '', // For create mode
    confirm_password: '', // For create mode
    viewPermission: false,
  });
  const handleSaveOrUpdate = () => {
    let payload = {
      paymentProviderUserId: editData && editData._id,
      paymentProviderId:
        paymentProviderId ||
        (editData && editData.parentUser && editData.parentUser._id),
      adminRole: localDb.getVal('adminRole'),
      email: email,
      providerEmail: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      country: data.country,
      postcode: data.postcode,
      phoneNo: data.phone,
      viewPermission: data.viewPermission,
    };
    saveOrUpdatePaymentProviderUser(payload);
  };
  const saveOrUpdatePaymentProviderUser = async (payload) => {
    setLoading(true);
    let URL;
    
    if (editPaymentProvideUser) {
      URL = {
        ...UPDATE_PAYMENT_PROVIDER_USER,
        url: base_url + UPDATE_PAYMENT_PROVIDER_USER.url,
      };
    } else {
      URL = {
        ...CREATE_PAYMENT_PROVIDER_USER,
        url: base_url + CREATE_PAYMENT_PROVIDER_USER.url,
      };
    }
  
    try {
      const res = await call(URL, payload);
      if (res.status === 200) {
        if (editPaymentProvideUser) {
          props.getPaymentProviderUserList();
        }
        props.handleCloseAddNew();
        props.getProviders();
        props.setSuccessAlert(true);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err.message, 'err');
    }
  };
  
  useEffect(() => {
    // If in edit mode, pre-fill the form fields with editData
    if (editPaymentProvideUser && editData) {
      setData({
        firstName: editData.firstName,
        lastName: editData.lastName,
        email: editData.email,
        phone: editData.phoneNo,
        address1: editData.address1,
        address2: editData.address2,
        city: editData.city,
        state: editData.state,
        country: editData.country,
        postcode: editData.postcode,
        viewPermission: editData.viewPermission
      });
    }
  }, [editPaymentProvideUser, editData]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handlePermissionAccess = () => {
    setData({ ...data, viewPermission: !data.viewPermission });
  };
  
  // Determine if the form is in "create" or "edit" mode
  const isEditMode = editPaymentProvideUser;

  // Determine if the password fields should be disabled in "edit" mode
  const isPasswordDisabled = isEditMode && editData && editData.password;

  // Determine if the form is in a valid state for submission
  const isValidForm =
    data &&
    data.email &&
    data.email.length >= 5 &&
    data &&
    data.firstName &&
    data.firstName.length >= 3 &&
    data &&
    data.lastName &&
    data.lastName.length >= 3 &&
    (!isEditMode ||
      (data &&
        data.password &&
        data.password.length >= 3 &&
        data.confirm_password &&
        data.password === data.confirm_password));
  return (
    <div>
      <div className="create_account_container">
        <div className="create_account_header">
          <div className="create_account_heading">
            {editPaymentProvideUser ? `Update User` : `Create User`}
          </div>
          <div
            className="filter_close_button"
            onClick={() => {
              props.handleCloseAddNew();
            }}
          >
            <img src={`${image_base_url}${closeImage}`} alt="closeImage" />
          </div>
        </div>

        <div className="create_account_form">
          <div className="d-flex justify-content-space-between">
            <div>
              <label>
                <b>{'First Name'}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="firstName"
                value={data['firstName']}
                placeholder="First name."
                onChange={(e) => handleChange(e)}
                required
              />
            </div>

            <div className="pl-3">
              <label>
                <b>{'Last Name'}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="lastName"
                value={data['lastName']}
                placeholder="Last name"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
          </div>

          <div className="mt-4">
            <label>
              <b>{'Email'}</b>
            </label>
            <input
              className="create_account_input"
              type="email"
              name="email"
              value={data['email']}
              placeholder="Email"
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          {!isEditMode && (
            <div>
              <div className="mt-4">
                <label>
                  <b>{'Password'}</b>
                </label>
                <input
                  className="create_account_input"
                  type="password"
                  name="password"
                  value={data['password']}
                  placeholder="Password"
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>

              <div className="mt-4">
                <label>
                  <b>{'Confirm password'}</b>
                </label>
                <input
                  className="create_account_input"
                  type="password"
                  name="confirm_password"
                  value={data['confirm_password']}
                  placeholder="Confirm password"
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
            </div>
          )}
          <div className="mt-4">
            <label>
              <b>{'Phone'}</b>
            </label>
            <input
              className="create_account_input"
              type="number"
              name="phone"
              value={data['phone']}
              placeholder="Phone"
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className="d-flex justify-content-space-between mt-4">
            <div>
              <label>
                <b>{'Address 1'}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="address1"
                value={data['address1']}
                placeholder="Address 1"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>

            <div className="pl-3">
              <label>
                <b>{'Address 1'}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="address2"
                value={data['address2']}
                placeholder="Address2"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
          </div>

          <div className="d-flex justify-content-space-between mt-4">
            <div>
              <label>
                <b>{'State'}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="state"
                value={data['state']}
                placeholder="State"
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="pl-3">
              <label>
                <b>{'City'}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="city"
                value={data['city']}
                placeholder="City"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>

          <div className="d-flex justify-content-space-between mt-4">
            <div>
              <label>
                <b>{'Country'}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="country"
                value={data['country']}
                placeholder="Country"
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="pl-3">
              <label>
                <b>{'Post code'}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="postcode"
                value={data['postcode']}
                placeholder="Postcode"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="ContainsNoteFilterWrap" onClick={()=>handlePermissionAccess()}>
          <img
            src={`${image_base_url}${data.viewPermission
              ? selectedCheckBoxIcon
              : emptyCheckBoxIcon
              }`}
            alt="checkbox"
            className="currencyListCheckBox"
          />
          <div className="ContainsNoteFilterName" >{"View Permission Access"}</div>
        </div>
          <div className="login_button_get_started">
            <button
              className={isEditMode ? "" : !isValidForm ? 'bg-secondary' : ""}
              disabled={isEditMode ? false : !isValidForm}
              onClick={() => handleSaveOrUpdate()}
            >
              {isEditMode ? 'Update' : 'Save'}
            </button>
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default CreatePaymentProviderUserComp;
