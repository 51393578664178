import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ExportToCsv } from "export-to-csv-fix-source-map";
import moment from "moment";
import Loading from "../../../common/Loading";
import { call, logoutAfterError } from "../../../../config/axios";
import { GET_ALL_BLOCKCHAIN_TRANSACTIONS } from "../../../../config/endpoints";

const downloadAlertIcon = "/cryptonpay/crytoicons/downloadalerticon.png";

function HouseKeepingCsv(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "HouseKeeping Transactions",
    filename: "HouseKeepingTransactions",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    headers: ["Column 1", "Column 2", "col 3", "col 4", "col 5"],
  };

  const csvExporter = new ExportToCsv(options);

  const downloadTransactions = () => {
    if (data && data.length > 0) {
      let transactionList = [];
      {
        data.map((tr) => {
          let transactionObj = {
            Blockchain: (tr.blockchain && tr.blockchain) || "N/A",
            Symbol: (tr.crypto && tr.crypto) || "N/A",
            Network: (tr.network && tr.network) || "N/A",
            Amount: (tr.cryptoValue && tr.cryptoValue) || "N/A",
            Timestamp:
              moment(tr.timestamp && tr.timestamp).format("HH:mm DD/MM/YYYY") ||
              "N/A",
            "Wallet Address": (tr.toWallet && tr.toWallet) || "N/A",
            "Transaction ID":
              (tr.transactionHash && tr.transactionHash) || "N/A",
            Status: (tr.Status && tr.Status) || "N/A",
          };
          transactionList.push(transactionObj);
        });
      }
      csvExporter.generateCsv(transactionList);
      props.handleCloseDownloadCsv();
    }
  };
  
  const getAllBlockchainTransactions = async () => {
    let payload = {
      email: props.email,
      filterParams: {},
    };

    setLoading(true);

    await call(
      {
        ...GET_ALL_BLOCKCHAIN_TRANSACTIONS,
        url: props.base_url + GET_ALL_BLOCKCHAIN_TRANSACTIONS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setData(res.data.blockchainTransactions);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  useEffect(() => {
    getAllBlockchainTransactions();
  }, []);

  return (
    <div className="ClearAlertModal">
      <div className="ClearAlertModalBody">
        <div
          className="ClearAlertModalIconWrap"
          data-e2e="ClearAlertModalIconWrap"
        >
          <img
            src={`${image_base_url}${downloadAlertIcon}`}
            alt="downloadAlertIcon"
            className="ClearAlertModalIcon"
            data-e2e="ClearAlertModalIcon"
          />
        </div>
        <div className="ClearAlertModalTitle">Download in CSV</div>
        <div className="ClearAlertModalDescription">
          Are you sure you want to download CSV file with all the information?{" "}
        </div>
        <div
          className="ClearAlertModalClearBtn"
          data-e2e="ClearAlertModalClearBtn"
          onClick={() => {
            downloadTransactions();
          }}
        >
          Download
        </div>
        <div
          className="ClearAlertModalGoBackBtn"
          onClick={() => {
            props.handleCloseDownloadCsv();
          }}
        >
          Go Back
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default HouseKeepingCsv;
