import React, { useState, useEffect } from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../common/Loading';
import localDb from '../../../../localDb';
import { GET_DEPOSIT_HASH, GET_CRYPTOS } from '../../../../config/endpoints';
import { call, logoutAfterError } from '../../../../config/axios';
import DepositHashTable from './DepositHashTable';
import useDebounce from '../../global/useDebounce';
const searchicon = "/cryptonpay/crytoicons/searchicon1.png";
const filterIcon = "/cryptonpay/filter_icon.svg";
const downloadIcon = "/cryptonpay/download_icon.svg";
import Dialog from "@mui/material/Dialog";
import DepositHashFilters from './DepositHashFilters';
import {
  DEPOSIT_HASH_FILTER_NETWORK_LIST,
  DEFAULT_ORDER_BY_FOR_DEPOSIT_HASH,
  DEFAULT_SORTBY_FOR_DEPOSIT_HASH,
} from "../../global/constant";



const DepositHash = () => {
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const email = localDb.getVal('email');
    const base_url = useSelector((state) => state.config.api_url);

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState('desc');
    const [sortBy, setSortBy] = useState('createdAt');
    const [searchText, setSearchText] = useState('')
    const [depositHashFilters, setDepositHashFilters] = useState(false);
    const [networkList, setNetworkList] = useState(DEPOSIT_HASH_FILTER_NETWORK_LIST);
    const [selectedNetworkList, setSelectedNetworkList] = useState(DEPOSIT_HASH_FILTER_NETWORK_LIST);

    const [symbolList, setSymbolList] = useState([]);
    const [selectedSymbolList, setSelectedSymbolList] = useState([]);
    const [save, setSave] = useState(false);

    const handleDepositHashFilters = () => {
        setDepositHashFilters(false);
      };
    const handleSave = () => {
    setSave(!save);
    };
    const resetFilters = () => {
    setSearchText("");
    setOrderBy(DEFAULT_ORDER_BY_FOR_DEPOSIT_HASH);
    setSortBy(DEFAULT_SORTBY_FOR_DEPOSIT_HASH);
    setPage(1);
    setSelectedSymbolList(symbolList);
    setSelectedNetworkList(networkList);
    };

    const getDepositHash = async (page) => {
        let pageNo = 1;
        if (page) {
            pageNo = page;
        }
        let payload = {
          email: email,
          orderBy: orderBy,
          sortBy: sortBy,
          page: pageNo,
          depositHash: searchText,
          transactionId: searchText,
          network:
            selectedNetworkList.length === 0 ||
            selectedNetworkList.length === networkList.length
              ? "All"
              : selectedNetworkList,
          symbol:
            selectedSymbolList.length === 0 ||
            selectedSymbolList.length === symbolList.length
              ? "All"
              : selectedSymbolList,
        };

        setLoading(true);
        await call(
            {
                ...GET_DEPOSIT_HASH,
                url: base_url + GET_DEPOSIT_HASH.url,
            },
            payload
        )
            .then((res) => {
                if (res.status === 200) {
                    if (pageNo === 1) {
                        setData(res.data.depositHash);
                        setTotalCount(res.data.totalCount);
                    } else {
                        let list = data.concat(res.data.depositHash);
                        setData(list);
                        setTotalCount(res.data.totalCount);
                    }
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.error(err.message, 'err in getDepositHash');
                logoutAfterError(err)
            });
    };
    const getAllCryptos = async () => {
        const payload = {
          email: localDb.getVal("email"),
        };
        setLoading(true);
        await call(
          {
            ...GET_CRYPTOS,
            url: base_url + GET_CRYPTOS.url,
          },
          payload
        )
          .then((res) => {
            setLoading(false);
            if (res?.status === 200) {
              const cryptos = res?.data?.cryptos?.map((crypto) => {
                return crypto?.symbol;
              });
              let blockchains = [];
              res?.data?.cryptos?.map((crypto) => {
                if(!blockchains?.includes(crypto?.blockchain)){
                  blockchains.push(crypto?.blockchain);
                }
              });
              setSymbolList(cryptos);
              setSelectedSymbolList(cryptos);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.error(err?.message, "err in getAllCryptos");
            logoutAfterError(err)
          });
      };
    const debouncedSearchText = useDebounce(searchText);
    useEffect(() => {
      getDepositHash();
    }, [debouncedSearchText, orderBy, sortBy, save]);
    useEffect(() => {
        getAllCryptos();
      }, [])
    const name = 'Deposit Hash';
    return (
      <div className="pageContainer">
        <HeaderAndFooterLayout>
          <CommonHeader name={name} />
          <div className="transaction_filter">
            <div className="transactionsFiltersWrap">
              <div className="transactionsFilters">
                <div className="transactionsFiltersItem transactionsSearchFilter">
                  <div className="transactionsFilterTextWrap">
                    <input
                      type="text"
                      className="transactionsFilterInput"
                      value={searchText}
                      name="searchText"
                      data-e2e="search-textbox"
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      placeholder="Search deposit hash, Transaction ID "
                    />
                  </div>
                  <div className="transactionsFilterSingleIconWrap">
                    <img
                      src={`${image_base_url}${searchicon}`}
                      alt="searchicon"
                      className="transactionsFilterSingleIcon"
                      data-e2e="searchIcon"
                      onClick={() => {
                        getDepositHash();
                        setPage(1);
                      }}
                    />
                  </div>
                </div>
                <div
                  className="filterBtn"
                  onClick={() => {
                    setDepositHashFilters(true);
                  }}
                >
                  <img
                    data-e2e="filter-icon"
                    src={`${image_base_url}${filterIcon}`}
                    alt="filterIcon"
                    className="filterBtnIcon"
                  />
                </div>
                <Dialog
                  open={depositHashFilters}
                  onClose={handleDepositHashFilters}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DepositHashFilters
                    handleDepositHashFilters={handleDepositHashFilters}
                    networkList={networkList}
                    setNetworkList={setNetworkList}
                    selectedNetworkList={selectedNetworkList}
                    setSelectedNetworkList={setSelectedNetworkList}
                    symbolList={symbolList}
                    setSymbolList={setSymbolList}
                    selectedSymbolList={selectedSymbolList}
                    setSelectedSymbolList={setSelectedSymbolList}
                    handleSave={handleSave}
                    resetFilters={resetFilters}
                  />
                </Dialog>
              </div>
            </div>
          </div>
          <div>
            <DepositHashTable
              data={data}
              sortBy={sortBy}
              orderBy={orderBy}
              getDepositHash={() => {
                getDepositHash(1);
              }}
              changeOrder={(sort) => {
                if (sortBy !== sort) {
                  setOrderBy(DEFAULT_ORDER_BY_FOR_DEPOSIT_HASH);
                  setSortBy(sort);
                } else {
                  setOrderBy(orderBy === "desc" ? "asc" : "desc");
                }
              }}
              handleShowMore={() => {
                const newPage = page + 1;
                setPage(newPage);
                getDepositHash(newPage);
              }}
              showMoreButton={totalCount && data?.length < totalCount}
            />
          </div>
          {loading && <Loading />}
        </HeaderAndFooterLayout>
      </div>
    );
}

export default DepositHash