import React, { useState } from 'react'
import { Table } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import JSONPretty from "react-json-pretty";
import { SortByIcon } from '../../../common/CommonUtils';



const AuditTrailTable = (props) => {
    const [loading, setLoading] = useState(false);
    return (
        <div className="TransactionTableMain">
            {props && props.data && props.data.length > 0 ? (
                <Table responsive style={{ border: 'none' }}>
                    <tbody className="TransactionTable_BODY">
                        <tr className="TransactionTable_BODY_Header">
                            <th
                                className="TransactionTable_heading"
                                width="15%"
                                onClick={() => {
                                    props.changeOrder('merchantId');
                                }}
                                data-e2e="merchantId"
                            >
                                Event Type{' '}
                                {props.sortBy === 'merchantId' && (
                                    <SortByIcon orderBy={props.orderBy} />
                                )}
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="15%"
                                onClick={() => {
                                    props.changeOrder('transactionId');
                                }}
                                data-e2e="transactionID"
                            >
                                Event Id{' '}
                                {props.sortBy === 'transactionId' && (
                                    <SortByIcon orderBy={props.orderBy} />
                                )}
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="15%"
                                onClick={() => {
                                    props.changeOrder('component');
                                }}
                                data-e2e="component"
                            >
                                User ID{' '}
                                {props.sortBy === 'component' && (
                                    <SortByIcon orderBy={props.orderBy} />
                                )}
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="15%"
                                onClick={() => {
                                    props.changeOrder('severity');
                                }}
                                data-e2e="severity"
                            >
                                IP Address{' '}
                                {props.sortBy === 'severity' && (
                                    <SortByIcon
                                        orderBy={props.orderBy}
                                    />
                                )}
                            </th>

                            <th
                                className="TransactionTable_heading"
                                width="15%"
                                onClick={() => {
                                    props.changeOrder('error.code');
                                }}
                                data-e2e="error code"
                            >
                                Component{' '}
                                {props.sortBy === 'error.code' && (
                                    <SortByIcon
                                        orderBy={props.orderBy}
                                    />
                                )}
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="15%"
                                onClick={() => {
                                    props.changeOrder('createdAt');
                                }}
                                data-e2e="createDate"
                            >
                                Created At{' '}
                                {props.sortBy === 'createdAt' && (
                                    <SortByIcon orderBy={props.orderBy} />
                                )}
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="5%"
                                data-e2e="createDate"
                            >
                            </th>
                        </tr>
                        {props.data.map((TData, i) => {
                            return <SingleTransactionTableRow data={TData} key={i} loading={loading} setLoading={setLoading} getErrorLogs={() => { props.getErrorLogs() }} />;
                        })}
                    </tbody>
                </Table>
            ) : (
                <div className="No_Transactions_Found">No Data Found</div>
            )}
            {props.showMoreButton ? (
                <div className="Show_more_wrap">
                    <div
                        className="Show_more"
                        onClick={() => {
                            props.handleShowMore();
                        }}
                        data-e2e="showMore"
                    >
                        Show More
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default AuditTrailTable

const SingleTransactionTableRow = (props) => {
    const [expendTableRow, setExpendTableRow] = useState(false)
    let { data } = props;
    const EventUrl = getEventPage(data)
    return (
        <React.Fragment>
            <tr className="CP_TableRow" onClick={() => setExpendTableRow(!expendTableRow)}>
                <td className="transactionsTable_item">
                    {(data.event_Type && data.event_Type) || "N/A"}
                </td>
                <td className="transactionsTable_item">
                    {EventUrl === "N/A" ? <Tooltip title={data.event_Id && data.event_Id} placement="top">
                        <span>  {data.event_Id
                            ? data.event_Id.substring(0, 5) +
                            '.....' +
                            data.event_Id.substring(
                                data.event_Id.length - 6,
                                data.event_Id.length
                            )
                            : 'N/A'} </span>
                    </Tooltip> :
                        <Tooltip title={data.event_Id && data.event_Id} placement="top">
                            <Link target="_blank" to={EventUrl}>
                                <span>  {data.event_Id
                                    ? data.event_Id.substring(0, 5) +
                                    '.....' +
                                    data.event_Id.substring(
                                        data.event_Id.length - 6,
                                        data.event_Id.length
                                    )
                                    : 'N/A'} </span>
                            </Link>
                        </Tooltip>}
                </td>
                <td className="transactionsTable_item">
                    {(data.user_Id && data.user_Id) || "N/A"}
                </td>
                <td className="transactionsTable_item">
                    {data.ipAddress && data.ipAddress}
                </td>
                <td className="transactionsTable_item wallet_address_link">
                    {(data.component && data.component) || "N/A"}
                </td>
                <td className="transactionsTable_item">
                    {(data.timeStamp && new Date(data.timeStamp).toLocaleString()) || "N/A"}
                </td>
                <td>
                    {expendTableRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </td>
            </tr>
            {expendTableRow && <tr style={{ padding: "0px, 20px", width: "100vh",  height: "300px", overflowY: "auto" }} >
                <td colSpan="6" style={{maxHeight:"400px", height:"400px", overflow:"auto"}} >
                        <JSONPretty data={data.detailedData} />
                </td>
            </tr>}
        </React.Fragment>

    );
};

const getEventPage = (data) => {
    if (data.page_Type === "User Transaction") {
        return `transaction/${data.event_Id}`
    } else if (data.page_Type === "Merchant User Wallet") {
        return `wallets/${data.event_Id}`
    } else if (data.page_Type === "Merchant Transaction") {
        return `merchant-transaction/${data.event_Id}`
    } else if (data.page_Type === "Centralised Wallet") {
        return `centralised-wallets/${data.event_Id}`
    } else {
        return "N/A"
    }
}