import React, { useEffect, useState } from 'react';
import queryString from "query-string";
import { useDispatch, useSelector } from 'react-redux';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import Dialog from '@mui/material/Dialog';
import Loading from '../../../common/Loading';
import localDb from '../../../../localDb';
import { MERCHANT_TRANSACTIONS, GET_CRYPTOS, GET_ALL_MERCHANTS_LIST, GET_MERCHANT_TRANSACTIONS_SUMMARY, GET_ALL_PAYMENT_PROVIDERS } from '../../../../config/endpoints';
import { call, logoutAfterError } from '../../../../config/axios';
import moment from 'moment';
import TransactionFilters from './TransactionFilters';
import TransactionTableMerchant from './TransactionTableMerchant';
import { setHeader } from '../../../Redux/actions/commonAction';
import DownloadCsv from '../CommonPages/DownloadCsv';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { NUMBER_RECENT_DAYS_FILTER } from './helpers';
import { useLocation } from "react-router-dom";
import useDebounce from '../../global/useDebounce';

const searchicon = '/cryptonpay/crytoicons/searchicon1.png';
const filterIcon = "/cryptonpay/filter_icon.svg";
const downloadIcon = "/cryptonpay/download_icon.svg";


function TransactionsMerchant() {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal('email');
  const base_url = useSelector((state) => state.config.api_url);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  const { time } = searchParams
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([])
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearhText] = useState('');
  const [orderBy, setOrderBy] = useState('desc');
  const [sortBy, setSortBy] = useState('status.updated');
  const [fromDate, setFromDate] = useState(
    moment().subtract(NUMBER_RECENT_DAYS_FILTER, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  );
  const [toDate, setToDate] = useState(
    moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  );
  const [dateSelected, setDateSelected] = useState(false);
  const [transactionFitlers, setTransactionFilters] = useState(false);
  const [downloadCsv, setDownloadCsv] = useState(false);

  const [statusList, setStatusList] = useState(['completed', 'rejected', 'processing']);
  const [selectedStatusList, setSelectedStatusList] = useState(['completed', 'rejected', 'processing']);

  const [type, setType] = useState(['Autotrade', 'Withdrawal', 'Deposit', 'Swap', 'Settlement', "AdminDeposit", "AdminWithdrawal", "settlementOut", "receive"]);
  const initialTrType = getTrType(searchParams, type);
  const [selectedType, setSelectedType] = useState(initialTrType);

  const [cryptos, setCryptos] = useState([]);
  const [selectedCryptos, setSelectedCryptos] = useState([]);

  const [fiatCurrency, setFiatCurrency] = useState([
    {
      icon: '/cryptonpay/crytoicons/euro.png',
      name: 'Euro',
      symbol: 'EUR',
    },
    {
      icon: '/cryptonpay/crytoicons/usa.png',
      name: 'US Dollar',
      symbol: 'USD',
    },
    {
      icon: '/cryptonpay/crytoicons/uk.png',
      name: 'British Pound',
      symbol: 'GBP',
    },
  ]);
  const [selectedFiatCurrency, setSelectedFiatCurrency] = useState([
    'EUR',
    'USD',
    'GBP',
  ]);

  const [save, setSave] = useState(false);
  const [networkList, setNetworkList] = useState([
    'mainnet',
    'testnet',
  ]);

  const [selectedNetworkList, setSelectedNetworkList] = useState([
    'mainnet',
    'testnet',
  ]);

  const [minFiatAmount, setMinFiatAmount] = useState(0);
  const [maxFiatAmount, setMaxFiatAmount] = useState(1);

  const [merchantName, setMerchatName] = useState([]);
  const [isContainsNote, changeIsContainsNote] = useState(false);
  const [selectedMerchantName, setSelectedMerchantName] = useState([]);
  const [ignoredFirstCallForDashboardCall, setIgnoredFirstCallForDashboardCall] = useState(false);

  const handleTransactionFilters = () => {
    setTransactionFilters(false);
  };

  const handleSave = () => {
    setSave(!save);
  };
  const resetFilters = () => {
    setSearhText('');
    setOrderBy('desc');
    setSortBy('status.updated');
    setPage(1);
    setDateSelected(false);
    setTotalCount(0);
    setSelectedStatusList([
      'completed', 'rejected', 'processing'
    ]);
    setFromDate([
      moment().subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    ]);
    setToDate([moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')]);
    setSelectedCryptos([]);
    setSelectedFiatCurrency([]);
    setNetworkList([
      'mainnet',
      'testnet',
    ]);
    setSelectedType([]);
    setSelectedMerchantName([]);
    setMinFiatAmount(0);
    setMaxFiatAmount(1);
    changeIsContainsNote(false)
  };
  const setTime = () => {
    if (time) {
      setDateSelected(true)
      setFromDate(timedata(searchParams))
      setToDate(moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"))
    }
  }
  const adminRole = localStorage.getItem("adminRole");
  const [transactionLoading, setTransactionLoading] = useState(false);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  });
  const getTransactions = async (page) => {
    let pageNo = 1;
    let allMode = false

    if (page) {
      pageNo = page;
    }

    if (page === 'all') {
      allMode = true
    }

    let payload = {
      ...(localDb.getVal("paymentProviderId") && { paymentProviderId: localDb.getVal("paymentProviderId") }),
      CryptoCurrency: "All",
      status: "All",
      type: "All",
      email: email,
      adminMode: true,
      transactionId: searchText.toString(),
      merchant: searchText,
      sortBy: sortBy,
			orderBy: orderBy,
			fromDate: moment().subtract(NUMBER_RECENT_DAYS_FILTER, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
			toDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      page: pageNo,
      CryptoCurrency:
        selectedCryptos.length === 0 ||
          selectedCryptos.length === cryptos.length
          ? 'All'
          : selectedCryptos,
      network: selectedNetworkList.length === 0 ||
        selectedNetworkList.length === networkList.length
        ? 'All'
        : selectedNetworkList,
      FiatCurrency:
        selectedFiatCurrency.length === 0 ||
          selectedFiatCurrency.length === fiatCurrency.length
          ? 'All'
          : selectedFiatCurrency,
      status:
        selectedStatusList.length === 0 ||
          selectedStatusList.length === statusList.length
          ? 'All'
          : selectedStatusList,
      merchant_name: selectedMerchantName.length === 0 ||
        selectedMerchantName.length === merchantName.length
        ? 'All'
        : selectedMerchantName,
      type:
        selectedType.length === 0 ||
          selectedType.length === type.length
          ? 'All'
          : selectedType,
      minFiatAmount: minFiatAmount === 0 ? 'All' : minFiatAmount,
      maxFiatAmount: maxFiatAmount === 1 ? 'All' : maxFiatAmount,
      isContainsNote,
      ...(adminRole == "SuperAdmin" && {
        providers:
          selectedPaymentProvider.length === 0 ||
            selectedPaymentProvider.length === paymentProviderList.length
            ? 'All'
            : selectedPaymentProvider
      })
    };

    if (dateSelected) {
      payload = {
        ...payload,
        fromDate: fromDate,
        toDate: toDate,
      };
    }

    if (allMode) {
      delete payload.page
      payload = {
        ...payload,
        findAll: true
      }
    }

    setTransactionLoading(true);
    await call(
      {
        ...MERCHANT_TRANSACTIONS,
        url: base_url + MERCHANT_TRANSACTIONS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          if (allMode) {
            setAllData(res.data.transactionsList)
            setTransactionLoading(false);
            return
          }
          if (isMobile) {
            setData(res.data.transactionsList);
            setTotalCount(res.data.totalCount);
          } else {
            if (pageNo === 1) {
              setData(res.data.transactionsList);
              setTotalCount(res.data.totalCount);
            } else {
              setData(res.data.transactionsList);
              setTotalCount(res.data.totalCount);
            }
          }
          setTransactionLoading(false);
        }
      })
      .catch((err) => {
        setTransactionLoading(false);
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };

  const name = 'Merchant Transactions';

  const getMerchantList = async () => {
    setLoading(true);
    await call(
      {
        ...GET_ALL_MERCHANTS_LIST,
        url: base_url + GET_ALL_MERCHANTS_LIST.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setMerchatName(res.data.merchants);
          setSelectedMerchantName(res.data.merchants);
        }
      })
      .catch((err) => {
        console.log(err.message, 'err')
        setLoading(false);
        logoutAfterError(err)
      });
  };

  const getCryptos = async () => {
    setLoading(true);
    await call(
      {
        ...GET_CRYPTOS,
        url: base_url + GET_CRYPTOS.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setCryptos(res.data.cryptos);
          let cryptos = res.data.cryptos.map((crypto) => {
            return crypto.symbol;
          });
          setSelectedCryptos(cryptos);
        }
      })
      .catch((err) => {
        console.log(err.message, 'err');
        setLoading(false);
        logoutAfterError(err)
      });
  };

  const handleCloseDownloadCsv = () => {
    setDownloadCsv(false);
    getTransactions();
  };

  const handleDownloadTransaction = async () => {
    await getTransactions('all')
    setDownloadCsv(true);
  }

  useEffect(() => {
    localDb.setVal('sub_name_test', 'transactions');
    getCryptos();
    getMerchantList();
    if (adminRole == "SuperAdmin") {
      getProviders();
    }
    if (Object.keys(searchParams).length > 0) {
      setSave(!save);
    }
  }, []);
  const debouncedSearchText = useDebounce(searchText);
  useEffect(() => {
    dispatch(setHeader('Merchant Transactions'));
      getTransactions();
    setTime()
  }, [debouncedSearchText, orderBy, sortBy, save]);

  const [totalNumberOfTransactions, setTotalNumberOfTransactions] = useState(0);
  const [totalUsdAmount, setTotalUsdAmount] = useState(0);
  const [sumOfEachCrypto, setsumOfEachCrypto] = useState([]);

  // useEffect(() => {
  //   getTransactionSummary();
  // }, [searchText, save]);

  const getTransactionSummary = async () => {
    let payload = {
      email: email,
      transactionId: searchText.toString(),
      merchantUserId: searchText.toString(),
      merchantTransactionId: searchText.toString(),
      merchant: searchText,
      CryptoCurrency:
        selectedCryptos.length === 0 ||
          selectedCryptos.length === cryptos.length
          ? 'All'
          : selectedCryptos,
      network: selectedNetworkList.length === 0 ||
        selectedNetworkList.length === networkList.length
        ? 'All'
        : selectedNetworkList,
      FiatCurrency:
        selectedFiatCurrency.length === 0 ||
          selectedFiatCurrency.length === fiatCurrency.length
          ? 'All'
          : selectedFiatCurrency,
      status:
        selectedStatusList.length === 0 ||
          selectedStatusList.length === statusList.length
          ? 'All'
          : selectedStatusList,
      merchant_name: selectedMerchantName.length === 0 ||
        selectedMerchantName.length === merchantName.length
        ? 'All'
        : selectedMerchantName,
      type:
        selectedType.length === 0 ||
          selectedType.length === type.length
          ? 'All'
          : selectedType,
      minFiatAmount: minFiatAmount === 0 ? 'All' : minFiatAmount,
      maxFiatAmount: maxFiatAmount === 1 ? 'All' : maxFiatAmount,
    };


    if (dateSelected) {
      payload = {
        ...payload,
        fromDate: fromDate,
        toDate: toDate,
      };
    }
    await call(
      {
        ...GET_MERCHANT_TRANSACTIONS_SUMMARY,
        url: base_url + GET_MERCHANT_TRANSACTIONS_SUMMARY.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalNumberOfTransactions(res.data.numberOfTransaction);
          setTotalUsdAmount(res.data.totalDepositAmount.length > 0 ? res.data.totalDepositAmount[0].depositAmount : 0);
          setsumOfEachCrypto(res.data.sumOfCrypto);
        }
      })
      .catch((err) => {
        console.log(err.message, 'err')
        logoutAfterError(err)
      });
  };

  const [openMobileSearch, setOpenMobileSearch] = useState(false);
  const [transactionFitlerMobile, setTransactionFilterMobile] = useState(false);
  const [paymentProviderList, setPaymentProviderList] = useState([]);
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState([]);
  const getProviders = async () => {
    let payload = {
      email,
      firstName: searchText,
    };
    setLoading(true);
    await call(
      {
        ...GET_ALL_PAYMENT_PROVIDERS,
        url: base_url + GET_ALL_PAYMENT_PROVIDERS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setPaymentProviderList(res.data.providerList);
          let providerId = [];
          if (res.data.providerList.length > 0) {
            providerId = res.data.providerList.map(e => e._id);
          }
          setSelectedPaymentProvider(providerId);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };
	
  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
				<div className="">
					<CommonHeader name={name} />
					<div className="transactionFilter__allMerchants">
						<div className="transactionFilter">
							<input
								type="text"
								className="transactionFilter__input"
								value={searchText}
								name="searchText"
								data-e2e="search-textbox"
								onChange={(e) => {
									setSearhText(e.target.value);
								}}
								placeholder="Search Merchant ID, Transaction ID"
							/>
							<img
								src={`${image_base_url}${searchicon}`}
								alt="searchicon"
								className="transactionFilter__icon"
								data-e2e="searchIcon"
								onClick={() => {
									getTransactions();
									setPage(1);
								}}
							/>
						</div>
						<button type="button" data-e2e="filterBtn" className="transactionFilter__btn" onClick={() => {setTransactionFilters(true);}}>
							<img data-e2e="filterIcon" src={`${image_base_url}${filterIcon}`} alt="filterIcon" />
						</button>
            <button
            type="button"
            data-e2e="filterBtn"
            className={`transactionFilter__btn`}
            onClick={handleDownloadTransaction}
          >
            <img
              data-e2e="downloadIcon"
              src={`${image_base_url}${downloadIcon}`}
              alt="downloadIcon"
            />
          </button>
						<Dialog
							open={transactionFitlers}
							onClose={handleTransactionFilters}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<TransactionFilters
                filter_name = "merchant_transactions"
								merchantNameList={merchantName}
								setMerchatName={setMerchatName}
								isContainsNote={isContainsNote}
								changeIsContainsNote={() => { changeIsContainsNote(!isContainsNote) }}
								selectedMerchantName={selectedMerchantName}
								setSelectedMerchantName={setSelectedMerchantName}
								minFiatAmount={minFiatAmount}
								maxFiatAmount={maxFiatAmount}
								setMinFiatAmount={setMinFiatAmount}
								setMaxFiatAmount={setMaxFiatAmount}
								statusGroupList={[]}
								selectedStatusGroupList={[]}
								networkList={networkList}
								setNetworkList={setNetworkList}
								selectedNetworkList={selectedNetworkList}
								setSelectedNetworkList={setSelectedNetworkList}
								handleTransactionFilters={handleTransactionFilters}
								statusList={statusList}
								setStatusList={setStatusList}
								selectedStatusList={selectedStatusList}
								setSelectedStatusList={setSelectedStatusList}
								fromDate={moment(fromDate)}
								toDate={moment(toDate)}
								setFromDate={setFromDate}
								setToDate={setToDate}
								handleSave={handleSave}
								setDateSelected={setDateSelected}
								dateSelected={dateSelected}
								resetFilters={resetFilters}
								cryptos={cryptos}
								selectedCryptos={selectedCryptos}
								setSelectedCryptos={setSelectedCryptos}
								fiatCurrency={fiatCurrency}
								selectedFiatCurrency={selectedFiatCurrency}
								setSelectedFiatCurrency={setSelectedFiatCurrency}
								type={type}
								selectedType={selectedType}
								setSelectedType={setSelectedType}
								paymentProviderList={paymentProviderList}
								setPaymentProviderList={setPaymentProviderList}
								selectedPaymentProvider={selectedPaymentProvider}
								setSelectedPaymentProvider={setSelectedPaymentProvider}
								adminRole={adminRole}
							/>
						</Dialog>
            <Dialog
              open={downloadCsv}
              onClose={handleCloseDownloadCsv}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DownloadCsv
                handleCloseDownloadCsv={handleCloseDownloadCsv}
                data={allData}
                resetFilters={resetFilters}
                name="merchantTransactions"
                data-e2e="transactions"
              />
            </Dialog>
					</div>
					<div>
            <TransactionTableMerchant
              data={data}
              changeOrder={(sort) => {
                if (sortBy !== sort) {
                  setOrderBy('desc');
                  setSortBy(sort);
                } else {
                  setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
                }
              }}
							setPage={(page) => {
								setPage(page)
								getTransactions(page)
							}}
              page={page}
              totalCount={totalCount}
            />
          </div>
				</div>
      </HeaderAndFooterLayout>
      {(loading || transactionLoading) && <Loading />}
    </div>
  );
}

export default TransactionsMerchant;


{/* <div> */}
{/* <div className="transaction_filter"> */}
{/* <MediaQuery maxWidth={767} >
	<div className="sub_header">
		<div className="transaction_search_filter_wrap">
			{!openMobileSearch ?
				<h1>{`Merchant transactions`}</h1> :
				<div className="input_search_box">
					<div onClick={() => { setOpenMobileSearch(false); setSearhText('') }}><i className="fa fa-chevron-left back_arrow_color" aria-hidden="true"></i></div>
					<div> <input
						type="text"
						value={searchText}
						name="searchText"
						data-e2e="search-textbox"
						onChange={(e) => {
							setSearhText(e.target.value);
						}}
						placeholder="Search Transactions"
						autoFocus
					/></div>
				</div>
			}
			<div className="log_wrap">
				{!openMobileSearch && <h4> <i className="fa fa-search" aria-hidden="true" onClick={() => setOpenMobileSearch(true)}></i></h4>}
				<h4 onClick={() => setTransactionFilterMobile(true)} className="pl-4 pr-2 cursor-pointer"> <i className="fa fa-filter" aria-hidden="true"></i> </h4>
			</div>
		</div>
	</div>
	<div >
		<Dialog
			className="transaction_filter_mobile m-0"
			open={transactionFitlerMobile}
			onClose={() => setTransactionFilterMobile(false)}
		>
			<TransactionFilterMobile
				merchantNameList={merchantName}
				setMerchatName={setMerchatName}
				isContainsNote={isContainsNote}
				changeIsContainsNote={() => { changeIsContainsNote(!isContainsNote) }}
				selectedMerchantName={selectedMerchantName}
				setSelectedMerchantName={setSelectedMerchantName}
				minFiatAmount={minFiatAmount}
				maxFiatAmount={maxFiatAmount}
				setMinFiatAmount={setMinFiatAmount}
				setMaxFiatAmount={setMaxFiatAmount}
				networkList={networkList}
				statusGroupList={[]}
				selectedStatusGroupList={[]}
				setNetworkList={setNetworkList}
				selectedNetworkList={selectedNetworkList}
				setSelectedNetworkList={setSelectedNetworkList}
				handleTransactionFilters={() => setTransactionFilterMobile(false)}
				statusList={statusList}
				setStatusList={setStatusList}
				selectedStatusList={selectedStatusList}
				setSelectedStatusList={setSelectedStatusList}
				fromDate={moment(fromDate)}
				toDate={moment(toDate)}
				setFromDate={setFromDate}
				setToDate={setToDate}
				handleSave={handleSave}
				setDateSelected={setDateSelected}
				dateSelected={dateSelected}
				resetFilters={resetFilters}
				cryptos={cryptos}
				selectedCryptos={selectedCryptos}
				setSelectedCryptos={setSelectedCryptos}
				fiatCurrency={fiatCurrency}
				selectedFiatCurrency={selectedFiatCurrency}
				setSelectedFiatCurrency={setSelectedFiatCurrency}
				type={type}
				selectedType={selectedType}
				setSelectedType={setSelectedType}
				paymentProviderList={paymentProviderList}
				setPaymentProviderList={setPaymentProviderList}
				selectedPaymentProvider={selectedPaymentProvider}
				setSelectedPaymentProvider={setSelectedPaymentProvider}
				adminRole={adminRole}
			/>
		</Dialog>
	</div>
</MediaQuery> */}
{/* </div> */}

{/* <MediaQuery minWidth={768}>
<div className='transaction_summary transaction_summary_display_block'>
	<div className='summary_title'>{`Summary: `}</div>
	<div className='transaction_summary_line' >
		<div className='summary_content'>{` Number of transactions: `} <span>{totalNumberOfTransactions}</span> </div>
		<div className='summary_content'>{`| Net USD Equivalent: `} <span>{parseFloat(totalUsdAmount).toFixed(2)}</span> </div>
	</div>
	<div className='transaction_summary_line' >
		<div className='summary_content'>{`Sum by Crypto Amount: `}
			<span>{
				sumOfEachCrypto.length > 0 && sumOfEachCrypto.map((ele, index) => (
					ele._id !== null &&
					<span key={index}>{ele._id + ": " + parseFloat(ele.sum).toFixed(4) + (index != (sumOfEachCrypto.length - 1) ? "| " : "")}</span>
				))
			}
			</span>
		</div>
	</div>
</div>
</MediaQuery>

*/}
{/* <MediaQuery maxWidth={767}>
<MobileViewSummary
	totalNumberOfTransactions={totalNumberOfTransactions}
	totalUsdAmount={totalUsdAmount}
	sumOfEachCrypto={sumOfEachCrypto}
	show={0}
/>
</MediaQuery> */}


{/* </div> */ }
const getTrType = (searchParams, defaultList) => {
  const { type } = searchParams
  if (searchParams && type) {
    const capitalMehtod = type.charAt(0).toUpperCase() + type.slice(1)
    return [type, capitalMehtod]
  } else {
    return defaultList
  }
}
const timedata = (searchParams) => {
  const { time } = searchParams
  if (time === "Last 30 Min") {
    return moment().subtract(30, "minutes").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  } else if (time === "Last 1 Hour") {
    return moment().subtract(60, "minutes").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  } else if (time === "Last 24 Hour") {
    return moment().subtract(1, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  } else if (time === "Last 48 Hour") {
    return moment().subtract(2, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  } else if (time === "Last 7 Days") {
    return moment().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  }
}