import React, { useState } from "react";
import { useSelector } from "react-redux";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  UPDATE_CUSTOMCITY,
  DELETE_CUSTOMCITY,
} from "../../../../config/endpoints";

import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";
const closeImage = "/cryptonpay/crytoicons/close.png";

function AddOrEditCountry({
  handleCloseAddNew,
  editCountry,
  country_id,
  setReload,
  reload,
  countryName,
  countryCode,
  setAddNew,
  setOpenEditCountryIcon
}) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const base_url = useSelector((state) => state.config.api_url);
  const [loading, setLoading] = useState(false);
  const [addcountry, setAddcountry] = useState(countryName || "");
  const [addcountryCode, setAddcountryCode] = useState(countryCode || "");
  const [errorMessage, setErrorMessage] = useState(false);
  

  const handleSaveDetails = async () => {
    if(!addcountry || ! addcountryCode){
      setErrorMessage(true)
      return
    }
    else{
      var payloadEditCountry = {
        country_id: country_id,
        editCountry: {
          countryName: addcountry,
          countryCode: addcountryCode,
        },
      };
  
      var payloadAddCountry = {
        addcountry: addcountry,
        addcountryCode: addcountryCode,
      };
  
      setLoading(true);
      let URL;
      if (editCountry) {
        URL = {
          ...UPDATE_CUSTOMCITY,
          url: base_url + UPDATE_CUSTOMCITY.url,
        };
        var payload = payloadEditCountry;
      } else {
        URL = {
          ...UPDATE_CUSTOMCITY,
          url: base_url + UPDATE_CUSTOMCITY.url,
        };
        var payload = payloadAddCountry;
      }
      await call(URL, payload)
        .then((res) => {
          setLoading(false);
          setAddNew(false);
          setOpenEditCountryIcon(false)
          if (res.status === 200) {
            setEditData([]);
            setReload(!reload);
            openEditCountryIcon(false)
            handleCloseAddNew();
          }
        })
        .catch((err) => {
          setLoading(false);
          setReload(!reload);
          
          logoutAfterError(err);
        });
    }
  };

  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <div className="font-large font-weight-bold text-lg">{`${
          editCountry ? "Edit" : "Add"
        } Country`}</div>
        <div
          className="filter_close_button"
          onClick={() => {
            handleCloseAddNew();
          }}
        >
          <img
            src={`${image_base_url}${closeImage}`}
            alt="closeImage"
          />
        </div>
      </div>
      <div className="upload__merchantId">
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Country Name: </div>
          <input
            type="text"
            placeholder="Country Name"
            value={addcountry}
            onChange={(e) => setAddcountry(e.target.value)}
          />
        </div>
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Country Code: </div>
          <input
            type="text"
            placeholder="Country Code"
            value={addcountryCode}
            onChange={(e) => setAddcountryCode(e.target.value)}
          />
        </div>
      </div>
      {errorMessage && (
        <div className="my-2 w-full text-left text-red-600">
          {"Please fill all Country details"}
        </div>
      )}
      <div className="d-flex justify-content-center pt-4">
        <button
          type="button"
          onClick={() => {
            handleSaveDetails();
          }}
          className="btn btn-primary btn-md rounded-pill add-btn w-100"
        >
          {editCountry ? "Update" : "Add"}
        </button>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default AddOrEditCountry;
