import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatTransactionId, formatNotes } from "../helpers";
import Tooltip from "@mui/material/Tooltip";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import WarningIcon from '@mui/icons-material/Warning';

const editNote = "/cryptonpay/crytoicons/edit_note.svg";

export default function TransactionIdNotes(props) {
	const imageBaseUrl = useSelector((state) => state.config.image_base_url);
	const { housekeeper, notes, transactionId } = props.row;
	const hasNotes = notes && notes.length > 0;
	return (
		<div data-tag="allowRowEvents" className="flex justify-between gap-2 items-center">
			{hasNotes ? (
				<React.Fragment>
					<Tooltip placement="top" classes={{ tooltip: "tooltip-bg" }}
						title={(
								<div className="tooltip-notes">
									<span className="tooltip-initial-text">Notes ({notes.length}):</span>{" "}{formatNotes(notes)}
								</div>) || ""} 
						>
						<Link className="theme-table-status-block" to={`/transaction/${transactionId}`}>
							{formatTransactionId(transactionId)}
							<span className="pl-2"><img src={`${imageBaseUrl}${editNote}`} alt="editNote" style={{ width: "24px" }}/></span>
						</Link>
					</Tooltip>
				</React.Fragment>
			) : formatTransactionId(transactionId)}
			{housekeeper && housekeeper.status === "Matched" && <CheckBoxIcon style={{ color: 'green', fontSize: '18px' }} />}
			{housekeeper && housekeeper.status === "Unmatched" && <WarningIcon style={{ color: 'red', fontSize: '18px' }} />}
		</div>
	)
}