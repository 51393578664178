import React from "react";
import { Table } from "reactstrap";
import moment from "moment";
import { SortByIcon } from "../../../common/CommonUtils";

const RealTimeExchangeRatesTable = (props) => {
  return (
    <div className="TransactionTableMain">
      {true ? (
        <Table responsive style={{ border: "none" }}>
          <tbody className="TransactionTable_BODY merchant_table_text_align">
            <tr className="TransactionTable_BODY_Header">
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("baseCurrency");
                }}
                data-e2e="baseCurrency"
              >
                Cryptocurrency{" "}
                {props.sortBy === "baseCurrency" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="baseCurrency" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("quoteCurrency");
                }}
              >
                Fiat Currency{" "}
                {props.sortBy === "quoteCurrency" && (
                  <SortByIcon orderBy={props.orderBy}/>
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("price");
                }}
                data-e2e="price"
              >
                Price{" "}
                {props.sortBy === "price" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="price" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("change1h");
                }}
                data-e2e="change1h"
              >
                Change 1H{" "}
                {props.sortBy === "change1h" && (
                  <SortByIcon data-e2e="change1h" orderBy={props.orderBy} />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("change24h");
                }}
                data-e2e="change24h"
              >
                change 24H{" "}
                {props.sortBy === "change24h" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="change24h" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("lastUpdated");
                }}
                data-e2e="lastUpdated"
              >
                Last Updated{" "}
                {props.sortBy === "lastUpdated" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="lastUpdated" />
                )}
              </th>
            </tr>
            {props && props.data && props.data.map((TData, i) => {
              return <SingleTransactionTableRow data={TData} key={i} />;
            })}
          </tbody>
        </Table>
      ) : (
        <div className="No_Transactions_Found" data-e2e="No_Transactions_Found">
          No Data Found
        </div>
      )}
      {props.showMoreButton ? (
        <div className="Show_more_wrap">
          <div
            className="Show_more"
            data-e2e="showMore"
            onClick={() => {
              props.handleShowMore();
            }}
          >
            Show More 
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RealTimeExchangeRatesTable;

const SingleTransactionTableRow = (props) => {

  let { data } = props;

  return (
    <tr className="CP_TableRow housekeeping-table-row">
      <td className="transactionsTable_item">{data && data.baseCurrency && data.baseCurrency || "N/A"}</td>
      <td className="transactionsTable_item">{data && data.quoteCurrency && data.quoteCurrency || "N/A"}</td>
      <td className="transactionsTable_item">{data && data.price && parseFloat(data.price).toFixed(8) || 0}</td>
      <td className="transactionsTable_item change1h">{data && data.change1h && parseFloat(data.change1h).toFixed(8) || 0}</td>
      <td className="transactionsTable_item change24h">{data && data.change24h && parseFloat(data.change24h).toFixed(8) || 0}</td>
      <td className="transactionsTable_item" data-e2e={"lastUpdated"}>
      {data && data.lastUpdated && data.lastUpdated
            ? `${moment(data.lastUpdated).format("DD.MM.YYYY")} (${moment(data.lastUpdated).format("HH:mm")})`
            : "N/A"}
      </td>
    </tr>
  );
};
