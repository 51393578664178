import React, { useState, useEffect } from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import Loading from '../../../common/Loading';
import PaymentProviderListTable from './PaymentProviderListTable';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import {
  DELETE_PAYMENT_PROVIDER_USER,
  GET_PAYMENT_PROVIDER_USER_LIST,
  UPDATE_PAYMENT_PROVIDER_USER,
} from '../../../../config/endpoints';
import localDb from '../../../../localDb';
import { call } from '../../../../config/axios';
import CreateUser from "./CreateUser";
import DeletingAlert from "../CommonPages/DeletingAlert";

function PaymentProviderUserTable() {
  const email = localDb.getVal('email');
  const base_url = useSelector((state) => state.config.api_url);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [deletingId, setDeletingId] = useState(null);
  const [addUpdatesuccess, setAddUpdateSuccess] = useState(false);
  const [editWallet, setEditWallet] = useState(false);
  const [editData, setEditData] = useState([]);
  const [disableData, setDisableData] = useState(null);
  const [disable, setDisable] = useState(false);

  const handleAddUpdateSuccess = () => {
    setPage(1);
    setAddUpdateSuccess((prev) => !prev);
  };
  const handleEditUser = () => {
    setAddNew(true);
    setEditWallet(true);
  };
  const handleDisableUser = () => {
    setAddUpdateSuccess((prev) => !prev);
  };
  const handleCloseAddNew = () => {
    setAddNew(false);
  };

  const name = 'Payment Provider User List';
  
  const disableUser = async () => {
    setLoading(true);
    try {
      const res = await call({
        ...UPDATE_PAYMENT_PROVIDER_USER,
        url: base_url + UPDATE_PAYMENT_PROVIDER_USER.url,
      }, {
        email: email,
        paymentProviderUserId: disableData?._id,
        paymentProviderId: disableData?.parentUser?._id,
        isUserEnabled: !disableData?.isUserEnabled
      });
      setLoading(false);
      
      if (res.status === 200) {
        setDisableData(null);
        handleDisableUser();
        setPage(1);
      }
    } catch (err) {
      setLoading(false);
      console.error(err?.message, 'err');
    }
  };

  const deleteUser = async () => {
    setLoading(true);
    try {
      const res = await call({
        ...DELETE_PAYMENT_PROVIDER_USER,
        url: base_url + DELETE_PAYMENT_PROVIDER_USER.url,
      }, {
        email: email,
        id: deletingId,
      });
      
      setLoading(false);
      
      if (res.status === 200) {
        setDeletingId(null);
        handleAddUpdateSuccess();
      }
    } catch (err) {
      setLoading(false);
      console.log(err.message, 'err');
    }
  };
  

  const getPaymentProviderUserList = async (page) => {
    let pageNo = 1;
    if (page) {
      pageNo = page;
    }
  
    let payload = {
      email: email,
      page: pageNo,
    };
  
    try {
      setLoading(true);
      const res = await call({
        ...GET_PAYMENT_PROVIDER_USER_LIST,
        url: base_url + GET_PAYMENT_PROVIDER_USER_LIST.url,
      }, payload);
  
      if (res.status === 200) {
        if (pageNo === 1) {
          setData(res.data.providerUserList);
          setTotalCount(res.data.totalCount);
        } else {
          let list = data.concat(res.data.providerUserList);
          setData(list);
          setTotalCount(res.data.totalCount);
        }
      }
  
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message, 'err');
    }
  };

  useEffect(() => {
    getPaymentProviderUserList();
  }, [addUpdatesuccess]);

  return (
    <div className="pageContainer merchant_page">
      <HeaderAndFooterLayout>
        <CommonHeader name={name} />
        <Dialog
          open={addNew}
          onClose={() => {
            handleCloseAddNew();
            setEditData([]);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CreateUser
            handleCloseAddNew={() => handleCloseAddNew()}
            handleAddUpdateSuccess={handleAddUpdateSuccess}
            editWallet={editWallet}
            editData={editData}
            setEditData={setEditData}
            editPaymentProvideUser={true}
            getPaymentProviderUserList={()=>getPaymentProviderUserList()}
          />
        </Dialog>
        <div>
          <PaymentProviderListTable
            data={data}
            setDeletingId={setDeletingId}
            setEditData={setEditData}
            handleShowMore={() => {
              let newPage = page + 1;
              setPage(newPage);
              getPaymentProviderUserList(newPage);
            }}
            showMoreButton={totalCount && data && data.length < totalCount}
            handleEditUser={handleEditUser}
            setDisableData={setDisableData}
            disable={disable}
          />
        </div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
      <DeletingAlert
        deletingId={deletingId ? deletingId : null}
        onHide={() => {
          setDeletingId(null);
        }}
        image_base_url={image_base_url}
        msg="Are you sure you want to delete this User? This action cannot be undone."
        onDeleteClickHandler={() => {
          deleteUser();
          setDeletingId(false)
        }}
      />
      <DeletingAlert
          deletingId={disableData?._id ? true : false}
          enable={!disableData?.isUserEnabled}
          enableUser={true}
          onHide={() => {
            setDisableData(null);
          }}
          msg={`Are you sure you want to ${!disableData?.isUserEnabled ? "Enable" : "Disable"
            } this user?`}
          onDeleteClickHandler={() => {
            disableUser();
            setDisable(disable);
          }}
        />
    </div>
  );
}

export default PaymentProviderUserTable;
