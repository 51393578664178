import React from "react";
import { Table } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { SortByIcon } from "../../../common/CommonUtils";

const HouseKeepingTable = (props) => {
  return (
    <div className="TransactionTableMain">
      {props && props.data && props.data.length > 0 ? (
        <Table responsive style={{ border: "none" }}>
          <tbody className="TransactionTable_BODY merchant_table_text_align">
            <tr className="TransactionTable_BODY_Header">
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("alertId");
                }}
                data-e2e="blockchain"
              >
                Alert Id
                {props.sortBy === "alertId" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="alert-id" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("alertTimestamp");
                }}
                data-e2e="timestamp"
              >
                Timestamp{" "}
                {props.sortBy === "alertTimestamp" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="timestamp" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("cryptonPayWalletAddress");
                }}
                data-e2e="cryptonPayWalletAddress"
              >
                Wallet Address{" "}
                {props.sortBy === "cryptonPayWalletAddress" && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="cryptonPayWalletAddress"
                  />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("blockchain");
                }}
                data-e2e="blockchain"
              >
                Blockchain{" "}
                {props.sortBy === "blockchain" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="blockchain" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("crypto");
                }}
                data-e2e="crypto"
              >
                Symbol{" "}
                {props.sortBy === "crypto" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="crypto" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("network");
                }}
                data-e2e="network"
              >
                NETWORK{" "}
                {props.sortBy === "network" && (
                  <SortByIcon data-e2e="network" orderBy={props.orderBy} />
                )}
              </th>

              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("cryptonpayTransactionId");
                }}
                data-e2e="cryptonpayTransactionId"
              >
                Crypto Pay Transaction ID{" "}
                {props.sortBy === "cryptonpayTransactionId" && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="transactionHash"
                  />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("blockchainTransactionId");
                }}
                data-e2e="blockchainTransactionId"
              >
                Blockchain Transaction ID{" "}
                {props.sortBy === "blockchainTransactionId" && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="blockchainTransactionId"
                  />
                )}
              </th>
            </tr>
            {props.data.map((TData, i) => {
              return <SingleTransactionTableRow data={TData} key={i} />;
            })}
          </tbody>
        </Table>
      ) : (
        <div className="No_Transactions_Found" data-e2e="No_Transactions_Found">
          No Data Found
        </div>
      )}
      {props.showMoreButton ? (
        <div className="Show_more_wrap">
          <div
            className="Show_more"
            data-e2e="showMore"
            onClick={() => {
              props.handleShowMore();
            }}
          >
            Show More
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HouseKeepingTable;

const SingleTransactionTableRow = (props) => {
  const BTC_MAINNET = useSelector((state) => state.config.btc_mainnet);
  const BTC_TESTNET = useSelector((state) => state.config.btc_testnet);
  const ETH_MAINNET = useSelector((state) => state.config.eth_mainnet);
  const ETH_SEPOLIA = useSelector((state) => state.config.eth_sepolia);

  const getCryptoLink = (network, cryptoId,transactionId) => {
    let link = network + "-" + cryptoId;
    
    switch (link) {
      case "mainnet-Bitcoin":
        return BTC_MAINNET.replace('address',`tx/${transactionId}`);
      case "testnet-Bitcoin":
        return BTC_TESTNET.replace('address',`tx/${transactionId}`);
      case "mainnet-Ethereum":
        return ETH_MAINNET.replace('address',`tx/${transactionId}`);
      case "goerli-Ethereum":
      case "testnet-Ethereum":
      case "testnet-DAI":
        return ETH_SEPOLIA.replace('address',`tx/${transactionId}`);
      default:
        return "";
    }
  };
  let { data } = props;

  return (
    <tr className="CP_TableRow housekeeping-table-row">
      <td className="transactionsTable_item" >
        <Tooltip placement="top" title={data.alertId}>
          <Link data-e2e="alert-id-address">
            {data && data.alertId && data.alertId
              ? data.alertId.substring(0, 5) +
                "....." +
                data.alertId.substring(
                  data.alertId.length - 6,
                  data.alertId.length
                )
              : "N/A"}
          </Link>
        </Tooltip>
      </td>

      <td className="transactionsTable_item" data-e2e={"time-stamp"}>
        {moment(data && data.alertTimestamp && data.alertTimestamp).format(
          "HH:mm DD/MM/YYYY"
        ) || "N/A"}
      </td>

      <td
        className="transactionsTable_item wallet_address_link"
        data-e2e="wallet-address"
      >
        <Tooltip
          title={
            data && data.cryptonPayWalletAddress && data.cryptonPayWalletAddress
          }
          placement="top"
        >
          <Link data-e2e="crypton-pay-wallet-address"
            to={`/wallets/${
              data &&
              data.cryptonPayWalletAddress &&
              data.cryptonPayWalletAddress
            }`}
          >
            {data &&
            data.cryptonPayWalletAddress &&
            data.cryptonPayWalletAddress
              ? data.cryptonPayWalletAddress.substring(0, 5) +
                "....." +
                data.cryptonPayWalletAddress.substring(
                  data.cryptonPayWalletAddress.length - 6,
                  data.cryptonPayWalletAddress.length
                )
              : "N/A"}
          </Link>
        </Tooltip>
      </td>
      <td className="transactionsTable_item">
        {(data && data.blockchain && data.blockchain) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {(data && data.symbol && data.symbol) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {(data && data.network && data.network) || "N/A"}
      </td>
      <td
        className="transactionsTable_item wallet_address_link"
        data-e2e="transaction"
      >
        <Tooltip
          title={
            data && data.cryptonpayTransactionId && data.cryptonpayTransactionId
          }
          placement="top"
        >
          <Link data-e2e="crypton-pay-transaction-id"
            to={`/transaction/${
              data &&
              data.cryptonpayTransactionId &&
              data.cryptonpayTransactionId
            }`}
          >
            {data &&
            data.cryptonpayTransactionId &&
            data.cryptonpayTransactionId
              ? data.cryptonpayTransactionId.substring(0, 5) +
                "....." +
                data.cryptonpayTransactionId.substring(
                  data.cryptonpayTransactionId.length - 6,
                  data.cryptonpayTransactionId.length
                )
              : "N/A"}
          </Link>
        </Tooltip>
      </td>
      <td
        className="transactionsTable_item wallet_address_link"
        data-e2e="transaction"
      >
        <Tooltip
          title={
            data && data.blockchainTransactionId && data.blockchainTransactionId
          }
          placement="top"
        >
          <a
          data-e2e="block-chain-transactionId"
          target="_blank"
           href={getCryptoLink(
              data && data.network && data.network,
              data && data.blockchain && data.blockchain,
              data && data.blockchainTransactionId && data.blockchainTransactionId
           )}
          
          >
            {data &&
            data.blockchainTransactionId &&
            data.blockchainTransactionId
              ? data.blockchainTransactionId.substring(0, 5) +
                "....." +
                data.blockchainTransactionId.substring(
                  data.blockchainTransactionId.length - 6,
                  data.blockchainTransactionId.length
                )
              : "N/A"}
          </a>
        </Tooltip>
      </td>
    </tr>
  );
};
