import React, { useState, useEffect } from "react";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CountryAccordion from "./CountryAccordion";
import { call } from "../../../../config/axios";
import { GET_COUNTRY_DETAILS } from "../../../../config/endpoints";
import Loading from "../../../common/Loading";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AddOrEditCountry from "./AddOrEditCountry";
import { isViewPermissionValid } from "../../../common/CommonUtils";

const CustomCountryMapping = () => {
  const [countryOption, setCountyOptions] = useState();
  const [loading, setLoading] = useState(false);
  const base_url = useSelector((state) => state.config.api_url);
  const [openEditIcon, setOpenEditIcon] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [reload, setReload] = useState(false);

  const handleCloseAddNew = () => {

    setAddNew(false);
    setOpenEditIcon(false)
  };
  const openAddDialog = () => {
    
    setOpenEditIcon(true);
    setAddNew(true);
  };
  useEffect(() => {
    getCountryData();
  }, []);

  useEffect(() => {
    getCountryData();
  }, [reload]);
  const getCountryData = async () => {
    try {
      setLoading(true);
      const { data } = await call({
        ...GET_COUNTRY_DETAILS,
        url: base_url + GET_COUNTRY_DETAILS.url,
      });
      setLoading(false);
      setCountyOptions(data);
    } catch (error) {
      setLoading(false);
  
    }
  };
  const sortDropDownData = (data, field) => {
    if (field) {
      const filteredData = data?.filter(
        (item) => item[field] !== undefined && item[field] !== null,
      );
      return filteredData?.sort((a, b) =>
        a[field].localeCompare(b[field], { sensitivity: "accent" }),
      );
    } else {
      return data?.sort((a, b) => a.localeCompare(b, { sensitivity: "accent" }));
    }
  };
  const countryOptionSorted = sortDropDownData(countryOption, "countryName")
  
  return (
    <div>
      <HeaderAndFooterLayout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1 className="my-4 text-xl">Custom Country Mapping</h1>
            </div>
            <div>
              <div className="flex justify-end">
                <Button
                  className={`${isViewPermissionValid? "disabled": ""}`}
                  variant="contained"
                  sx={{ width: "auto", maxWidth: "auto", marginBottom: "10px" }}
                  onClick={() => isViewPermissionValid? null: openAddDialog()}
                >
                  Add Country
                </Button>
              </div>
              {countryOptionSorted?.map((countryOption) => (
                <CountryAccordion
                  country_id={countryOption._id}
                  country={countryOption.countryName}
                  countryCode={countryOption.countryCode}
                  stateData={countryOption.allStates}
                  setReload={setReload}
                  reload={reload}
                />
              ))}
            </div>
          </div>
        </div>
        {openEditIcon && (
          <Dialog
            open={addNew}
            onClose={() => {
              handleCloseAddNew();
              setEditData([]);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <AddOrEditCountry
              handleCloseAddNew={ handleCloseAddNew}
              setReload={setReload}
              reload={reload}
              setOpenEditIcon={setOpenEditIcon}
              setAddNew={setAddNew}
            />
          </Dialog>
        )}
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
};

export default CustomCountryMapping;
