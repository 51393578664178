import { styled } from '@mui/material/styles';
import Dialog from "@mui/material/Dialog";
import Tooltip from '@mui/material/Tooltip';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { call, logoutAfterError } from "../../../../config/axios";
import { GET_CRYPTOS, MERCHANT_TRANSACTIONS } from "../../../../config/endpoints";
import TransactionFilter from "../Transactions/TransactionFilters";
import { SortByIcon } from '../../../common/CommonUtils';

let interval = null;

const useStyles = styled(Tooltip)({
    arrow: {
        "&:before": {
            backgroundColor: '#497cf9',
        },
    },
    tooltip: {
        fontSize: "1em",
        backgroundColor: '#497cf9',
        borderRadius: '32px',
        padding: '4px 18px',
        marginLeft: '-35px',
        marginBottom: '8px !important'
    },
});

const MerchantTransactions = ({ setLoading, merchantId, email, base_url,
    transactionFilters, setTransactionFilters, pageMode, interval1 }) => {
    const [page, setPage] = useState(1);
    const [transactionTableData, setTransactionTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const [orderBy, setOrderBy] = useState('desc');
    const [sortBy, setSortBy] = useState('createdDate');

    const [fromDate, setFromDate] = useState(
        moment().subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    );
    const [toDate, setToDate] = useState(
        moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    );
    const [dateSelected, setDateSelected] = useState(false);

    const [statusList, setStatusList] = useState(['completed', 'rejected', 'Pending']);
    const [selectedStatusList, setSelectedStatusList] = useState(['completed', 'rejected', 'Pending']);

    const [type, setType] = useState(['Autotrade', 'Withdrawal', 'Deposit', 'Swap', 'Settlement', "AdminDeposit", "AdminWithdrawal"]);
    const [selectedType, setSelectedType] = useState(['Autotrade', 'Withdrawal', 'Deposit', 'Swap', 'Settlement', "AdminDeposit", "AdminWithdrawal"]);
    const [statusGroupList, setStatusGroupList] = useState(['completed', 'rejected', 'processing']);
    const [selectedStatusGroupList, setSelectedStatusGroupList] = useState(['completed', 'rejected', 'processing']);
    const [cryptos, setCryptos] = useState([]);
    const [selectedCryptos, setSelectedCryptos] = useState([]);

    const [fiatCurrency, setFiatCurrency] = useState([
        {
            icon: '/cryptonpay/crytoicons/euro.png',
            name: 'Euro',
            symbol: 'EUR',
        },
        {
            icon: '/cryptonpay/crytoicons/usa.png',
            name: 'US Dollar',
            symbol: 'USD',
        },
        {
            icon: '/cryptonpay/crytoicons/uk.png',
            name: 'British Pound',
            symbol: 'GBP',
        },
    ]);
    const [selectedFiatCurrency, setSelectedFiatCurrency] = useState([
        'EUR',
        'USD',
        'GBP',
    ]);

    const [save, setSave] = useState(false);

    const [networkList, setNetworkList] = useState([
        'mainnet',
        'testnet',
    ]);
    const [selectedNetworkList, setSelectedNetworkList] = useState([
        'mainnet',
        'testnet',
    ]);

    const [minFiatAmount, setMinFiatAmount] = useState(0);
    const [maxFiatAmount, setMaxFiatAmount] = useState(1);

    const handleSave = () => {
        setSave(!save);
    };

    const resetFilters = () => {
        setOrderBy('desc');
        setSortBy('status.updated');
        setPage(1);
        setDateSelected(false);
        setTotalCount(0);
        setSelectedStatusList([
            'completed', 'rejected', 'Pending'
        ]);
        setFromDate([
            moment().subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        ]);
        setToDate([moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')]);
        setSelectedCryptos([]);
        setSelectedFiatCurrency([]);
        setNetworkList([
            'mainnet',
            'testnet',
        ]);
        setSelectedType([]);
        setMinFiatAmount(0);
        setMaxFiatAmount(1);
    };

    useEffect(() => {
        getCryptos();
    }, []);

    useEffect(() => {
        getMerchantTransactions(1);
    }, [orderBy, sortBy, save])

    useEffect(() => {
        if (!interval) {
            interval = setInterval(() => { setIntervalCalls() }, 30000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
                interval = null;
            }
        };
    });

    const setIntervalCalls = () => {
        if (pageMode == "transaction") {
            getMerchantTransactions(1);
        }
    };

    const getMerchantTransactions = async (pageNo) => {
        let page = 1
        if (pageNo) {
            page = pageNo;
            setPage(pageNo);
        }
        let payload = {
            componet: "AdminMerchantTransaction",
            email,
            page,
            adminMode: true,
            id: merchantId,
            sortBy: sortBy,
            orderBy: orderBy,
            CryptoCurrency:
                selectedCryptos.length === 0 ||
                    selectedCryptos.length === cryptos.length
                    ? 'All'
                    : selectedCryptos,
            network: selectedNetworkList.length === 0 ||
                selectedNetworkList.length === networkList.length
                ? 'All'
                : selectedNetworkList,
            FiatCurrency:
                selectedFiatCurrency.length === 0 ||
                    selectedFiatCurrency.length === fiatCurrency.length
                    ? 'All'
                    : selectedFiatCurrency,
            status:
                selectedStatusList.length === 0 ||
                    selectedStatusList.length === statusList.length
                    ? 'All'
                    : selectedStatusList,
            status:
                selectedStatusGroupList.length === 0 ||
                    selectedStatusGroupList.length === statusGroupList.length
                    ? "All"
                    : selectedStatusGroupList,
            type:
                selectedType.length === 0 ||
                    selectedType.length === type.length
                    ? 'All'
                    : selectedType,
            minFiatAmount: minFiatAmount === 0 ? 'All' : minFiatAmount,
            maxFiatAmount: maxFiatAmount === 1 ? 'All' : maxFiatAmount,
        }

        if (dateSelected) {
            payload = {
                ...payload,
                fromDate: fromDate,
                toDate: toDate,
            };
        }
        await call(
            {
                ...MERCHANT_TRANSACTIONS,
                url: base_url + MERCHANT_TRANSACTIONS.url,
            },
            payload
        )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        if (page == 1) {
                            setTransactionTableData(res.data.transactionsList);
                            setTotalCount(res.data.totalCount);
                        }
                        else {
                            let newData = transactionTableData.concat(res.data.transactionsList);
                            setTransactionTableData(newData);
                            setTotalCount(res.data.totalCount);
                        }
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.message, "err");
                logoutAfterError(err)
            });
    };

    const getCryptos = async () => {
        await call(
            {
                ...GET_CRYPTOS,
                url: base_url + GET_CRYPTOS.url,
            },
            { email: email }
        )
            .then((res) => {
                if (res.status === 200) {
                    setCryptos(res.data.cryptos);

                    let cryptos = res.data.cryptos.map((crypto) => {
                        return crypto.symbol;
                    });
                    setSelectedCryptos(cryptos);
                }
            })
            .catch((err) => {
                console.log(err.message, "err")
                logoutAfterError(err)
            });
    };


    const handleTransactionCloseFilters = () => {
        setTransactionFilters(false);
    };

    const changeOrder = (sort) => {
        if (sortBy !== sort) {
            setOrderBy('desc');
            setSortBy(sort);
        } else {
            setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
        }
    };

    const tableColumn = [ 
        {
            header: "DATE",
            sortKey: "createdDate"
        },
        {
            header: "TYPE",
            sortKey: "type"
        },
        {
            header: "ASSET",
            sortKey: "cryptoId"
        },
        {
            header: "ASSET AMOUNT",
            sortKey: "amount"
        },
        {
            header: "FIAT AMOUNT",
            sortKey: "USDAmount"
        },
        {
            header: "NOTE",
        },
    ]
    return (
        <div className='merchantTrans'>
            <Dialog
                open={transactionFilters}
                onClose={handleTransactionCloseFilters}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <TransactionFilter
                    minFiatAmount={minFiatAmount}
                    maxFiatAmount={maxFiatAmount}
                    setMinFiatAmount={setMinFiatAmount}
                    setMaxFiatAmount={setMaxFiatAmount}
                    networkList={networkList}
                    setNetworkList={setNetworkList}
                    selectedNetworkList={selectedNetworkList}
                    setSelectedNetworkList={setSelectedNetworkList}
                    handleTransactionFilters={handleTransactionCloseFilters}
                    statusList={statusList}
                    setStatusList={setStatusList}
                    selectedStatusList={selectedStatusList}
                    setSelectedStatusList={setSelectedStatusList}
                    fromDate={moment(fromDate)}
                    toDate={moment(toDate)}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    handleSave={handleSave}
                    setDateSelected={setDateSelected}
                    dateSelected={dateSelected}
                    resetFilters={resetFilters}
                    cryptos={cryptos}
                    selectedCryptos={selectedCryptos}
                    setSelectedCryptos={setSelectedCryptos}
                    fiatCurrency={fiatCurrency}
                    selectedFiatCurrency={selectedFiatCurrency}
                    setSelectedFiatCurrency={setSelectedFiatCurrency}
                    type={type}
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                    filter_merchant_name="merchantTransactions"
                    statusGroupList={[]}
                    setStatusGroupList={setStatusGroupList} 
                    selectedStatusGroupList={selectedStatusGroupList}
                    setSelectedStatusGroupList={setSelectedStatusGroupList}
                    filter_name="merchant_transactions"
                />
            </Dialog>
            <div className="table_content">
                <div className="table">
                    <div className="table_head">
                        {
                            tableColumn.map(({header, sortKey})=>{
                                return (
                                <div key={sortKey} className="title text-left cursor-pointer" onClick={() => {
                                    sortKey && changeOrder(sortKey);
                                }}
                                >
                                    {header} {sortBy === sortKey && (
                                        <SortByIcon orderBy={orderBy} />
                                    )}
                                </div>
                                )
                            })
                        }
                    </div>
                    {transactionTableData && transactionTableData.length > 0 ? (
                        transactionTableData.map((ele, i) => {
                            return (
                                <MerchantTransactionTableRow
                                    data={ele}
                                    key={i}
                                    index={i}
                                />
                            );
                        })
                    ) : (
                        <div className="No_Transactions_Found">No Data Found</div>
                    )}
                    {totalCount && transactionTableData && transactionTableData.length < totalCount ? <div className="Show_more_transactions_wrap" style={{ marginTop: "50px" }}><div className="Show_more_transactions s"
                        onClick={() => {
                            setPage(page + 1);
                            getMerchantTransactions(page + 1)
                        }}>Show More</div></div> : null}
                </div>
            </div>
        </div>

    )
};

const MerchantTransactionTableRow = (props) => {
    const [transactionInformation, setTransactionInformation] = useState(false);
    const [showCoppied, toggleCoppied] = useState({
        copy1: false,
        copy2: false,
        copy3: false,
        copy4: false
    });

    const getCryptoName = (symbol) => {
        switch (symbol) {
            case 'BTC':
                return 'Bitcoin';
            case 'ETH':
                return 'Ethereum';
            case 'USDT':
                return "Ethereum";
            case 'DAI':
                return 'Ethereum';
            case 'LTC':
                return 'Litecoin';
            case 'XRP':
                return 'Ripple';
            case 'TRX':
                return 'Tron';
            case 'TRC20-USDT':
                return 'TRC20-USDT';
            default:
                return 'Ethereum';
        }
    };

    const handleCopy = (e) => {
        toggleCoppied({ ...showCoppied, [e]: true });
        setTimeout(() => {
            toggleCoppied(false);
        }, 2000);
    };
    const classes = useStyles;

    const getToFixedValue = (value) => {
        if (value) {
            return parseFloat(value).toFixed(6);
        } else if (value === 0 || value === "0") {
            return 0
        } else {
            return value;
        }
    }

    return (
        <React.Fragment>
            <div className="single_Row cursor-pointer" key={props.index} onClick={() => (transactionInformation ? setTransactionInformation(false) : setTransactionInformation(true))}>
                <div className='table_data'>{
                    moment(props.data.createdDate).format("YYYY-MM-DD HH:mm:ss")
                }</div>
                <div className='table_data'>{props.data.type}</div>
                {["Swap", "Autotrade"].includes(props.data.type) ? <div className='table_data'>{props.data.fromCrypto + " - " + props.data.toCrypto}</div> : (["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? <div className='table_data'>{props.data.selected.cryptoId}</div> : <div className='table_data'>{props.data.cryptoId}</div>)}
                {["Swap", "Autotrade"].includes(props.data.type) ? <div className='table_data'>{getToFixedValue(props.data.fromAmount)} - {getToFixedValue(props.data.toAmount)}</div> : (["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? <div className='table_data'>{getToFixedValue(props.data.selected.amount)}</div> : <div className='table_data'>{getToFixedValue(props.data.amount)}</div>)}
                <div className='table_data'>
                    <p>${["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ?
                        Number(getToFixedValue(props.data && props.data.depositAmount ? props.data.depositAmount : 0)).toLocaleString() :
                        Number(getToFixedValue(props.data.USDAmount ? props.data.USDAmount : 0)).toLocaleString()}</p>
                </div>
                <div className='table_data'>
                    <div className="d-flex align-items-baseline justify-content-between">
                        <div>
                            {props && props.data && props.data.merchantBalanceUpdateNote && props.data.merchantBalanceUpdateNote || ""}
                        </div>
                        <div className="p-3">
                            <i className={`fa fa-lg fa-angle-${transactionInformation ? 'up' : 'down'} next__arrow`} aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
            {transactionInformation && (
                <div>
                    <div>
                        <div className="singleTRRowMerchant s">
                            {["Swap", "Autotrade"].includes(props.data.type) ? <div className="title">
                                {`(${props.data.fromCrypto}-${props.data.toCrypto}) / (${getCryptoName(props.data.fromCrypto)}-${getCryptoName(props.data.toCrypto)}) ${props.data.type}`}
                                <span>
                                    {` - `}
                                    {`(${props.data.fromAmount ? getToFixedValue(props.data.fromAmount) : "0"} ${props.data.fromCrypto ? props.data.fromCrypto : "N/A"} - ${props.data.toAmount ? getToFixedValue(props.data.toAmount) : "0"} ${props.data.toCrypto ? props.data.toCrypto : "N/A"})`} ( {"$ "}{props.data.USDAmount ? getToFixedValue(props.data.USDAmount) : '0'} )
                                </span>
                            </div> : <div className="title">
                                {`${["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? props.data.selected.cryptoId : props.data.cryptoId} / ${getCryptoName(["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? props.data.selected.cryptoId : props.data.cryptoId)} ${props.data.type} `}
                                <span>
                                    {` - `}
                                    {["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? getToFixedValue(props.data.selected.amount) : (props.data.amount ? getToFixedValue(props.data.amount) : "0")} {["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? props.data.selected.cryptoId : (props.data.cryptoId ? props.data.cryptoId : "N/A")} ( {"$ "}{["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? getToFixedValue(props.data.depositAmount) : (props.data.USDAmount ? getToFixedValue(props.data.USDAmount) : '0')} )
                                </span>

                            </div>}
                            <div className="TR__address">
                                {!["AdminDeposit", "AdminWithdrawal"].includes(props.data.type) &&
                                    <React.Fragment>
                                        <div className="from__address">
                                            <div className="address__title">From</div>
                                            <div className="address__data">
                                                {props.data && props.data.fromWallet ? props.data.fromWallet.substr(0, 15) + "..." + props.data.fromWallet.substr(-4) : 'N/A'}
                                                <span>
                                                    <Tooltip open={showCoppied.copy1} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                                                        <span
                                                            onClick={() => {
                                                                copy(props.data && props.data.fromWallet ? props.data.fromWallet : 'N/A');
                                                                handleCopy('copy1');
                                                            }}
                                                        >
                                                            <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                                                        </span>
                                                    </Tooltip>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="from__address">
                                            <div className="address__title">To</div>
                                            <div className="address__data">
                                                {["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? (props.data && props.data.withdrawalWalletAddress ? props.data.withdrawalWalletAddress.substr(0, 15) + "..." + props.data.withdrawalWalletAddress.substr(-4) : 'N/A') : (props.data && props.data.toWallet ? props.data.toWallet.substr(0, 15) + "..." + props.data.toWallet.substr(-4) : 'N/A')}
                                                <span>
                                                    <Tooltip open={showCoppied.copy2} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                                                        <span
                                                            onClick={() => {
                                                                copy(["Withdrawal", "AdminWithdrawal"].includes(props.data.type) ? (props.data && props.data.withdrawalWalletAddress ? props.data.withdrawalWalletAddress : "N/A") : (props.data && props.data.toWallet ? props.data.toWallet : 'N/A'));
                                                                handleCopy('copy2');
                                                            }}
                                                        >
                                                            <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                                                        </span>
                                                    </Tooltip>
                                                </span>
                                            </div>
                                        </div>

                                    </React.Fragment>
                                }


                                <div className="from__address">
                                    <div className="address__title">Txid</div>
                                    <div className="address__data">
                                        {props.data && props.data.transactionId ? props.data.transactionId.substr(0, 15) + "..." + props.data.transactionId.substr(-4) : 'N/A'}
                                        <span>
                                            <Tooltip open={showCoppied.copy3} arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} title={`copied`} placement="top" >
                                                <span
                                                    onClick={() => {
                                                        copy(props.data && props.data.transactionId ? props.data.transactionId : 'N/A');
                                                        handleCopy('copy3');
                                                    }}
                                                >
                                                    <i className="fa fa-clone" data-e2e="copy" aria-hidden="true"></i>
                                                </span>
                                            </Tooltip>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            )
            }
        </React.Fragment>
    );
};



export default MerchantTransactions;