import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import moment from "moment";
import Loading from "../../../common/Loading";
import { call, logoutAfterError } from "../../../../config/axios";
import { GET_ALL_DAILY_EXCHANGE_RATES } from "../../../../config/endpoints";

const downloadAlertIcon = "/cryptonpay/crytoicons/downloadalerticon.png";

function RealTimeExchangeRatesCsv(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "Daily Exchange Rates",
    filename: "DailyExchangesRates",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    headers: ["Column 1", "Column 2", "col 3", "col 4", "col 5"],
  };

  const csvExporter = new ExportToCsv(options);

  const downloadExchangeRates = () => {
    if (data && data.length > 0) {
      let exchangeRates = [];
      {
        data.map((tr) => {
          let exchangeRateObj = {
            CryptoCurrency: (tr.cryptoId && tr.cryptoId) || "N/A",
            Price: (tr.price && tr.price) || "N/A",
            "Date":
              moment(tr.date && tr.date).format(
                "HH:mm DD/MM/YYYY"
              ) || "N/A",
          };
          exchangeRates.push(exchangeRateObj);
        });
      }
      csvExporter.generateCsv(exchangeRates);
      props.handleCloseDownloadCsv();
    }
  };

  const getAllExchageRates = async () => {
    let payload = {
      email: props.email,
      filterParams: {},
    };

    setLoading(true);
    await call(
      {
        ...GET_ALL_DAILY_EXCHANGE_RATES,
        url: props.base_url + GET_ALL_DAILY_EXCHANGE_RATES.url,
      },
      payload
    )
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setLoading(false);
          setData(
            res &&
              res.data &&
              res.data.dailyExchangeRates &&
              res.data.dailyExchangeRates
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  useEffect(() => {
    getAllExchageRates();
  }, []);

  return (
    <div className="ClearAlertModal">
      <div className="ClearAlertModalBody">
        <div
          className="ClearAlertModalIconWrap"
          data-e2e="ClearAlertModalIconWrap"
        >
          <img
            src={`${image_base_url}${downloadAlertIcon}`}
            alt="downloadAlertIcon"
            className="ClearAlertModalIcon"
            data-e2e="ClearAlertModalIcon"
          />
        </div>
        <div className="ClearAlertModalTitle">Download in CSV</div>
        <div className="ClearAlertModalDescription">
          Are you sure you want to download CSV file with all the information?{" "}
        </div>
        <div
          className="ClearAlertModalClearBtn"
          data-e2e="ClearAlertModalClearBtn"
          onClick={() => {
            downloadExchangeRates();
          }}
        >
          Download
        </div>
        <div
          className="ClearAlertModalGoBackBtn"
          onClick={() => {
            props.handleCloseDownloadCsv();
          }}
        >
          Go Back
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default RealTimeExchangeRatesCsv;
