import React from "react";
import ProtectedRoute from "../ProtectedRoutes";
import { Navigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Merchants from "./components/Pages/Merchants/Merchants";
import Transactions from "./components/Pages/Transactions/Transactions";
import Wallets from "./components/Pages/Wallets/Wallets";
import TrafficMaskedPanWhitelist from "./components/Pages/Merchants/TrafficMaskedPanWhitelist";
import CentralisedWallets from "./components/Pages/CentralisedWallets/index";
import CentralisedWalletView from "./components/Pages/CentralisedWallets/centralisedWalletView"
import MerchantWallets from './components/Pages/MerchantWallets/Wallets'
import InduvisualTransactions from "./components/Pages/Transactions/InduvisualTransactions";
import TransactionDetails from "./components/Pages/CommonPages/TransactionDetails";
import MerchantTransactionDetails from "./components/Pages/Transactions/MerchantTransactionDetails"
import WalletView from "./components/Pages/Wallets/WalletView";
import MerchantWalletView from "./components/Pages/MerchantWallets/WalletView";
import ErrorLogs from "./components/Pages/ErrorLogs/ErrorLogs";
import SweepLogs from "./components/Pages/SweepLogs/SweepLogs";
import User from "./components/Pages/B2C/Users";
import UserManagement from "./components/Pages/B2C/UserManagement";
import UserWallets from "./components/Pages/B2C/UserWalletsList";
import UserWalletView from "./components/Pages/B2C/UserWalletView";
import UserTransactions from "./components/Pages/B2C/UserTransaction";
import UserTransactionsView from "./components/Pages/B2C/UserTransactionDetails";
import RealTimeExchangeRates from "./components/Pages/ExchangeRates/RealTimeExchangeRates";
import DailyExchangeRates from "./components/Pages/DailyExchangeRates/DailyExchangeRates";
import HouseKeepingTransactions from "./components/Pages/HouseKeeping/HouseKeeping";
import HouseKeepingWalletBalances from "./components/Pages/HouseKeeping/HouseKeepingWalletBalances";
import MerchantSetting from "./components/Pages/GlobalSettings/GlobalSetting";
import AnitiFraudService from "./components/Pages/GlobalSettings/CardAquireService/index";
import UserSettings from "./components/Pages/B2C/GlobalSettings";
import SendReceiveFees from "./components/Pages/Fees/sendReceiveFees";
import MerchantSendReceiveFees from "./components/Pages/Fees/merchantSendReceiveFees";
import RiskLevelLimits from "./components/Pages/RiskLevelLimits/RiskLevelLimit";
import Notfound from "./components/Pages/CommonPages/Notfound";
// import ApprovedWithdrawalAddress from "./components/Pages/ApprovedWithdrawalAddress/ApprovedWithdrawalAddress";
import BlockchainSettings from "./components/Pages/BlockchainSetting/BlockchainSettings";
import PriceManagement from "./components/Pages/PriceManagement/PriceManagement";
import TransactionsMerchant from "./components/Pages/Transactions/TransactionsMerchant"
import MinimumConversionAmount from "./components/Pages/MinimumConversionAmount/minimumConversionAmount";
import SpreadLevels from "./components/Pages/B2cSpreadLevels/b2cSpreadLevels";
import HouseKeepingAlerts from "./components/Pages/HouseKeepingAlerts/HouseKeepingAlerts";
import PaymentProvider from "./components/Pages/PaymentProvider/paymentProvider";
import Dashboard from "./components/Pages/B2C/Dashboard";
import DepositHash from "./components/Pages/DepositHash/DepositHash";
import CardAquirerDetails from "./components/Pages/GlobalSettings/CardAquireService/CardAquirerDetails"
import AuditTrail from "./components/Pages/AuditTrail"
// import ClientDetails from "./components/Pages/PaymentProvider/ClientDetails";
import SettingsPage from "./components/Pages/PaymentProvider/settingsPage";
import UploadIINPage from "./components/Pages/PaymentProvider/UploadIINPage.js";
// import Clients from "./components/Pages/PaymentProvider/Clients";
// import ClientTransConfig from "./components/Pages/Transactions/ClientTransConfig.js";
// import ClientTransaction from "./components/Pages/Transactions/ClientTransaction.js";
// import ClientTransactionDetails from "./components/Pages/CommonPages/ClientTransactionDetails.js";
import AdminNewPassword from "./components/Pages/AdminNewPassword/AdminNewPassword";
import PaymentProviderSweepConfigPage from "./components/Pages/PaymentProviderSweepConfig";
// import ClientDashboard from "./components/Pages/PaymentProvider/ClientDashboard";
import PaymentProviderUserTable from "./components/Pages/PaymentProvider/PaymentProviderUserTable";
import MerchantWhiteListCards from "./components/Pages/MerchantWhiteListCards/MerchantWhiteListCards.js";
import MerchantEmailWhiteList from "./components/Pages/MerchantEmailWhitelist/MerchantEmailWhiteList.js";
import CardDashboard from "./components/Pages/CardDashboard/CardDashboard.js";
import { createBrowserHistory } from 'history';
import CustomCountryMapping from "./components/Pages/CustomCountryMapping/index.js";
import AcquirerConfig from "./components/Pages/GlobalSettings/CardAquireService/AcquirerConfig/AcquirerConfig.js";
import MerchantConfig from "./components/Pages/GlobalSettings/CardAquireService/MerchantConfig/index.js";
import CardAquireService from "./components/Pages/GlobalSettings/CardAquireService/MerchantConfig/RoutingServiceConfig/index.js";
import BlockEmailList from "./components/Pages/GlobalSettings/CardAquireService/MerchantConfig/BlockEmailList.js";
import BlockCardList from "./components/Pages/GlobalSettings/CardAquireService/MerchantConfig/BlockCardList.js";
import BlockDomainEmailList from "./components/Pages/GlobalSettings/CardAquireService/MerchantConfig/BlockDomainEmailList.js";

const history = createBrowserHistory();

const RoutesList = () => {
  return (
    <Router history={history}>
      <Routes>
        <Route
          exact
          path="/merchants"
          element={<ProtectedRoute component={Merchants} />}
        />
        <Route
          exact
          path="/transactions"
          element={<ProtectedRoute component={Transactions} />}
        />
        <Route
          exact
          path="/merchant-transactions"
          element={<ProtectedRoute component={TransactionsMerchant} />}
        />
        <Route
          exact
          path="/wallets"
          element={<ProtectedRoute component={Wallets} />}
        />
        <Route
          exact
          path="/custom-country-mapping"
          element={<ProtectedRoute component={CustomCountryMapping} />}
        />
        <Route
          exact
          path="/traffic-maskedpan-whitelist"
          element={<ProtectedRoute component={TrafficMaskedPanWhitelist} />}
        />
        <Route
          exact
          path="/centralised-wallets"
          element={<ProtectedRoute component={CentralisedWallets} />}
        />
        <Route
          exact
          path="/centralised-wallets/:id"
          element={<ProtectedRoute component={CentralisedWalletView} />}
        />
        <Route
          exact
          path="/merchant-wallets"
          element={<ProtectedRoute component={MerchantWallets} />}
        />
        <Route
          exact
          path="/merchants/:id"
          element={<ProtectedRoute component={InduvisualTransactions} />}
        />
        <Route
          exact
          path="/transaction/:id"
          element={<ProtectedRoute component={TransactionDetails} />}
        />
        <Route
          exact
          path="/merchant-transaction/:id"
          element={<ProtectedRoute component={MerchantTransactionDetails} />}
        />
        <Route
          exact
          path="/wallets/:id"
          element={<ProtectedRoute component={WalletView} />}
        />
        <Route
          exact
          path="/merchant-wallets/:id"
          element={<ProtectedRoute component={MerchantWalletView} />}
        />
        <Route
          exact
          path="/error-logs"
          element={<ProtectedRoute component={ErrorLogs} />}
        />
        <Route
          exact
          path="/sweep-logs"
          element={<ProtectedRoute component={SweepLogs} />}
        />
        <Route
          exact
          path="/deposit-hash"
          element={<ProtectedRoute component={DepositHash} />}
        />
        <Route
          exact
          path="/b2c-users"
          element={<ProtectedRoute component={User} />}
        />
        <Route
          exact
          path="/admin-dashboard"
          element={<ProtectedRoute component={Dashboard} />}
        />
        <Route
          exact
          path="/b2c-users/:id"
          element={<ProtectedRoute component={UserManagement} />}
        />
        <Route
          exact
          path="/b2c-user-wallets"
          element={<ProtectedRoute component={UserWallets} />}
        />
        <Route
          exact
          path="/b2c-user-wallet/:id"
          element={<ProtectedRoute component={UserWalletView} />}
        />
        <Route
          exact
          path="/b2c-user-transactions"
          element={<ProtectedRoute component={UserTransactions} />}
        />
        <Route
          exact
          path="/b2c-user-transactions/:id"
          element={<ProtectedRoute component={UserTransactionsView} />}
        />
        <Route
          exact
          path="/house-keeping-transactions"
          element={<ProtectedRoute component={HouseKeepingTransactions} />}
        />
        <Route
          exact
          path="/house-keeping-alerts"
          element={<ProtectedRoute component={HouseKeepingAlerts} />}
        />
        <Route
          exact
          path="/house-keeping-wallet-balances"
          element={<ProtectedRoute component={HouseKeepingWalletBalances} />}
        />
        <Route
          exact
          path="/real-time-exchange-rates"
          element={<ProtectedRoute component={RealTimeExchangeRates} />}
        />
        <Route
          exact
          path="/daily-exchange-rates"
          element={<ProtectedRoute component={DailyExchangeRates} />}
        />
        <Route
          exact
          path="/merchant-settings"
          element={<ProtectedRoute component={MerchantSetting} />}
        />
        <Route
          exact
          path="/card-acquirer-routing"
          element={<ProtectedRoute component={AnitiFraudService} />}
        />

        <Route
          exact
          path="/acquirer-config"
          element={<ProtectedRoute component={AcquirerConfig} />}
        />
        <Route
          exact
          path="/card-acquirer-routing-new"
          element={<ProtectedRoute component={CardAquireService} />}
        />
        <Route
          exact
          path="/card-acquirer-routing-new/:id"
          element={<ProtectedRoute component={MerchantConfig} />}
        />
        <Route
          exact
          path="/blockedEmails"
          element={<ProtectedRoute component={BlockEmailList} />}
        />
        <Route
          exact
          path="/blockedDomainEmails"
          element={<ProtectedRoute component={BlockDomainEmailList} />}
        />
        <Route
          exact
          path="/blockedCards"
          element={<ProtectedRoute component={BlockCardList} />}
        />
        <Route
          exact
          path="/card-acquirer-routing/:id"
          element={<ProtectedRoute component={CardAquirerDetails} />}
        />
        <Route
          exact
          path="/user-settings"
          element={<ProtectedRoute component={UserSettings} />}
        />
        <Route
          exact
          path="/send-receive-fees"
          element={<ProtectedRoute component={SendReceiveFees} />}
        />
        <Route
          exact
          path="/merchant-send-receive-fees"
          element={<ProtectedRoute component={MerchantSendReceiveFees} />}
        />
        <Route
          exact
          path="/risk-level-limits"
          element={<ProtectedRoute component={RiskLevelLimits} />}
        />
        <Route
          exact
          path="/blockchain-settings"
          element={<ProtectedRoute component={BlockchainSettings} />}
        />
        <Route
          exact
          path="/price-management"
          element={<ProtectedRoute component={PriceManagement} />}
        />
        <Route
          exact
          path="/b2c-user-spread-level"
          element={<ProtectedRoute component={SpreadLevels} />}
        />
        <Route
          exact
          path="/minimum-conversion-amount"
          element={<ProtectedRoute component={MinimumConversionAmount} />}
        />
        <Route
          exact
          path="/all-payment-providers"
          element={<ProtectedRoute component={PaymentProvider} />}
        />
        <Route
          exact
          path="/all-payment-providers/:id"
          element={<ProtectedRoute component={SettingsPage} />}
        />
        <Route
          exact
          path="/all-payment-providers/UploadIINPage/:id"
          element={<ProtectedRoute component={UploadIINPage} />}
        />
        <Route
          exact
          path="/payment-providers-sweep-config"
          element={
            <ProtectedRoute component={PaymentProviderSweepConfigPage} />
          }
        />
        <Route
          exact
          path="/audit-trail"
          element={<ProtectedRoute component={AuditTrail} />}
        />
        {/* <Route
          exact
          path="/client-details/:id"
          element={<ProtectedRoute component={ClientDetails} />}
        />
        <Route
          exact
          path="/clients"
          element={<ProtectedRoute component={Clients} />}
        />
        <Route
          exact
          path="/client-trans-config"
          element={<ProtectedRoute component={ClientTransConfig} />}
        />
        <Route
          exact
          path="/client-transactions"
          element={<ProtectedRoute component={ClientTransaction} />}
        />
        <Route
          exact
          path="/client-transactions/:id"
          element={<ProtectedRoute component={ClientTransactionDetails} />}
        /> 
        <Route
          exact
          path="/client-dashboard"
          element={<ProtectedRoute component={ClientDashboard} />}
        /> */}
        <Route
          exact
          path="/not-found"
          element={<ProtectedRoute component={Notfound} />}
        />
        <Route
          exact
          path="/admin-forgot-password/:token"
          element={<AdminNewPassword />}
        />

        <Route
          exact
          path="/payment-provider-users"
          element={<ProtectedRoute component={PaymentProviderUserTable} />}
        />
        <Route
          exact
          path="/merchant-whitelist-cards"
          element={<ProtectedRoute component={MerchantWhiteListCards} />}
        />
        <Route
          exact
          path="/merchant-email-whitelist"
          element={<ProtectedRoute component={MerchantEmailWhiteList} />}
        />
        <Route
          exact
          path="/card-dashboard"
          element={<ProtectedRoute component={CardDashboard} />}
        />
        <Route path="*" element={<Navigate to="/merchants" replace={true} />} />
      </Routes>
    </Router>
  );
}

export default RoutesList
