import React, { useState, useRef } from "react";
import TableComponent from "../../global/TableComponent";
import Dialog from '@mui/material/Dialog';
import { useSelector } from "react-redux";
import { format, isWithinInterval, isAfter } from 'date-fns';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/SearchTwoTone';
import moment from 'moment';
import DateRangePicker2 from 'react-bootstrap-daterangepicker';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import { toFixedTrunc } from "../../global/helpers";
import { NavLink } from "react-router-dom";

const downloadIcon = "/cryptonpay/download_icon.svg";

export default function BalanceHistoryDialog(props) {
	const { data, balanceHistory, open, onClose, title } = props;
  	const image_base_url = useSelector((state) => state.config.image_base_url);
	const [searchValue, setSearchValue] = useState("");
    const [dateSelected, setDateSelected] = useState(false);
	const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

	
	
	const filteredData = () => {
		let res = [...balanceHistory];
		if (dateSelected) {
			res = res.filter(x => {
				const isDateMatch = isWithinInterval(new Date(x.updated), {
					start: new Date(fromDate),
					end: new Date(toDate)
				})
				if (isDateMatch) {
					return x;
				}
			})
		}
		if (searchValue) {
			res = res.filter(item => {
				return item.relatedTransactionId.toLowerCase().includes(searchValue.trim().toLowerCase())
			})
		}
		return res;
	};


	const handleCallback = async (start, end, label) => {
		setFromDate(moment(start).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
		setToDate(moment(end).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
		setDateSelected(true);
	  };
	
	const columns = [
		{
			name: 'Updated',
			selector: row => row.updated,
			cell: row =>
				<div>
					{format(new Date(row.updated), 'dd/MM/yyyy')}
					<br/>
					{format(new Date(row.updated), 'HH:mm:ss')}
				</div>,
			sortable: true,
		},
		{
			name: 'Transaction type', 
			selector: row => row.relatedTransactionType,
			cell: row => <p className="balance-history-type">{row.relatedTransactionType}</p>,
			sortable: true
		},
		{
			name: <p>Previous <br/>balance</p>, 
			selector: row => row.previousBalance,
			cell: row => toFixedTrunc(row.previousBalance, 6),
			sortable: true,
		},
		{
			name: <p>Balance <br/>adjustment</p>, 
			selector: row => toFixedTrunc(row.balanceAdjustment, 6),
			sortable: true,
		},
		{
			name: 'Transaction id (link)', 
			selector: row => row.relatedTransactionId,
			cell: row => <NavLink className="balance-history-link" to={`/transaction/${row.relatedTransactionId}`}>{row.relatedTransactionId}</NavLink>
		},
	];
	
	function onCloseHandler() {
		onClose();
		setTimeout(() => {
			setSearchValue("");
			setDateTimeRange(null);
		}, 300);
	}
	
	function getMockData() {
		let res = [];
		filteredData().forEach((x) => {
			const obj = {
				"Updated": format(new Date(x.updated), 'dd/MM/yyyy HH:mm:ss'),
				"Transaction type": x.relatedTransactionType,
				"Previous balance": toFixedTrunc(x.previousBalance, 6),
				"Balance adjustment": toFixedTrunc(x.balanceAdjustment, 6),
				"Transaction Id": x.relatedTransactionId
			}
			res.push(obj);
		})
		return res;
	}  
	
  const csvExporter = new ExportToCsv({ 
    useKeysAsHeaders: true,
		filename: data.crypName + title,
 	});
	
	function exportCsv() {
		if (filteredData().length > 0) {
			csvExporter.generateCsv(getMockData());
		}
	}
	
	return (
		<Dialog open={open} onClose={onCloseHandler} fullScreen className="balance-history">
			<div className="balance-history-body">
				<div className="balance-history-heading">
					<p className="balance-history-title">
						<img src={`${image_base_url}${data.icon}`} alt="" />
						{data.crypName} - {title}
					</p>
					<button onClick={onCloseHandler} type="button" className="balance-history-close">
						<ClearIcon  />
					</button>
				</div>
				{balanceHistory && balanceHistory.length > 0 && 
					<div>
						<div className="balance-history-filters">
							<div className="datepicker">
							<DateRangePicker2
							onCallback={handleCallback}
						>
							<div
								style={{marginTop: '5px'}}
								data-e2e=""
							>
								<div className="FilterModalInputDropdownDateTextWrap">
								<span data-e2e="start-date">{`${dateSelected
									? moment(fromDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
										'D MMMM'
									)
									: 'Start Date'
								}`}</span>
									<span style={{ color: 'black' }}>{` To `}</span>
									<span style={{ marginRight: '15%' }} data-e2e="end-date">{`${dateSelected
										? moment(toDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('D MMMM')
										: 'End Date'
									}`}</span>
								</div>
							</div>
						</DateRangePicker2>
							</div>

							<div className="balance-history-input-block">
								<input 
									className="balance-history-input"
									type="text" 
									placeholder="Search by transaction id..." 
									value={searchValue} 
									onChange={(e) => setSearchValue(e.target.value)}
								/>
								<SearchIcon className="balance-history-input-icon" />
							</div>
							
							<button disabled={filteredData().length < 1} type="button" className="balance-history-export" onClick={exportCsv}>
								<img src={`${image_base_url}${downloadIcon}`} alt="downloadIcon" />
								Export to CSV
							</button>
						
						</div>
						<TableComponent 
							columns={columns}
							items={filteredData().reverse()}
						/>
					</div>
				}
			</div>
		</Dialog>
	)
}