import * as actionTypes from '../actions/actionTypes'

const initialState = {
    header: 'All Payment Providers',
    loading: false
};

const setHeader = (state, action) => {
    return {
        ...state,
        header: action.data
    }
}

const setLoading = (state, action) => {
    return {
        ...state,
        loading: action.data
    }
}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LOADING: return setLoading(state, action);
        case actionTypes.SET_HEADER: return setHeader(state, action);
        default:
            return state;
    }
};

export default commonReducer;