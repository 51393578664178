import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IOSSwitch from "../IOSSwitch";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";

const AcquirerTab = ({
  setRoutingid,
  setSelectedAcquirer,
  setEditFormState,
  editFormState,
  handletoggleActiveAquirerService,
  setshowEditModel,
  setDeleteAquirerService,
  setDeleteAquirerServiceModel,
  acquireService,
  setShowAddNewAntiFraudModal,
}) => {
  const onClickSwitch = (e, item) => {
    const payload = {
      isAcquireServiceActive: !e.target.checked ? false : true,
      codeid: item._id,
      uniqueId: acquireService.uniqueId,
    };
    handletoggleActiveAquirerService(payload);
  };
  return (
    <div className="TabContainer">
      <div className="bg-[#F9FAFB] h-full">
        <h2 className="heading">Acquirers</h2>
        <div>
          {acquireService.routingService &&
            acquireService.routingService.map((item, i) => {
              return (
                <div key={i} className=" border-b ml-[10px]">
                  <div className="w-full px-2 max-w-[600px] py-4  flex  ">
                    <div className="flex-1 ">{item.acquirerName}</div>
                    <div className="flex-1">
                      <IOSSwitch
                      data-e2e={`${item?.acquirerName}-${item?.isAcquireServiceActive}`}
                        defaultChecked={item.isAcquireServiceActive}
                        className={`${isViewPermissionValid? "disabled": ""}`}
                        onClick={(e) => isViewPermissionValid? null: (
                          onClickSwitch(e, item)
                       )}
                      />
                    </div>
                    <div className="flex-1 flex gap-2">
                      <div
                        onClick={() => isViewPermissionValid? null:(
                          setRoutingid(item?._id),
                          setSelectedAcquirer(
                            JSON.stringify(item?.integrationType)
                          ),
                          setEditFormState({
                            ...editFormState,
                            acquirerName: item.acquirerName,
                            tokenType: item.tokenType,
                            siteReferenceName: item.siteReferenceName,
                            username: item.username,
                            pwd: item.pwd,
                            jwtUsername: item.jwtUsername,
                            jwtPassword: item.jwtPassword,
                            livestatus: item.livestatus,
                            notes: item.notes,
                          }),
                          setshowEditModel(true)
                      )}
                      >
                        <EditIcon
                          fontSize="large"
                          color="action"
                          className={`cursor-pointer pr-3 ${isViewPermissionValid? "disabled": ""}`}
                          data-e2e="ip_edit_icon"
                        />
                      </div>

                      <div
                        onClick={() => isViewPermissionValid? null: (
                          setDeleteAquirerServiceModel(true),
                          setDeleteAquirerService(item?._id)
                      )}
                      >
                        <DeleteIcon
                          fontSize="large"
                          color="warning"
                          className={`cursor-pointer pr-3 ${isViewPermissionValid? "disabled": ""}`}
                          data-e2e="ip_edit_icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          <button
            onClick={() => isViewPermissionValid? null: setShowAddNewAntiFraudModal(true)}
            className={`btnAddService ${isViewPermissionValid? "disabled": ""}`}
          >
            Add Acquirer
          </button>
        </div>
      </div>
    </div>
  );
};

export default AcquirerTab;
