import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { connect } from 'react-redux';
import { call, logoutAfterError } from '../../../../config/axios';
import {
  GET_CRYPTOS,
  UPDATE_GLOBAL_SETTING,
} from '../../../../config/endpoints';
import localDb from '../../../../localDb';
import SavingAlert from '../CommonPages/SavingAlert';

class EditAddGlobalSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: this.props.editData,
      cryptos: [],
      showSavingModal: false,
      showBlockChainDropDown: false,
      showCryptoDropDown: false,
      cryptoName: 'Select',
      blockchainData: [
        {
          name: 'Bitcoin',
          value: 'Bitcoin',
        },
        {
          name: 'Ethereum',
          value: 'Ethereum',
        },
      ],
      loading: false,
    };
  }

  componentDidMount() {
    this.getAllCryptos();
  }

  getAllCryptos = async () => {
    let payload = {
      email: localDb.getVal('email'),
    };
    this.setState({loading: true})
    await call(
      {
        ...GET_CRYPTOS,
        url: this.props.base_url + GET_CRYPTOS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({ cryptos: res.data.cryptos, loading: false });
        }
      })
      .catch((err) => {
        this.setState({loading: false})
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };

  updateGlobalSetting = async () => {
    const {
      blockchain,
      network,
      cryptoId,
      minCapacityThreshold,
      maxCapacityThreshold,
      depositTimer,
      minimunWallets,
      maximumWallets,
      _id,
    } = this.state.editData;
    if (!network) {
      return;
    } else if (!cryptoId) {
      return;
    } else if (!minCapacityThreshold) {
      return;
    } else if (!maxCapacityThreshold) {
      return;
    } else if (!depositTimer) {
      return;
    } else if (!minimunWallets) {
      return;
    } else if (!maximumWallets) {
      return;
    } else if (!blockchain) {
      return;
    }
    let payload = {
      email: localDb.getVal('email'),
      id: _id,
      network,
      cryptoId,
      minCapacityThreshold,
      maxCapacityThreshold,
      depositTimer,
      minimunWallets,
      maximumWallets,
      blockchain,
    };
    this.setState({loading: true})
    await call(
      {
        ...UPDATE_GLOBAL_SETTING,
        url: this.props.base_url + UPDATE_GLOBAL_SETTING.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({ showSavingModal: true, loading: false });
          this.props.getGlobalSetting()
        }
      })
      .catch((err) => {
        this.setState({loading: false})
        console.log(err.message, 'err');
        alert('Update Failed!');
        logoutAfterError(err)
      });
  };

  handleChange = (e) => {
    this.setState({
      editData: {
        ...this.state.editData,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    return (
      <div>
        <div className="myAccoutDetailsMain">
          <div className="myAccoutDetailsCard">
            <div className="d-flex justify-content-between">
              <h5 className="myAccoutOptModalHeading card-title text-center">
                Edit{' '}
              </h5>
              <div onClick={this.props.handleCloseEditGlobalSettings}>
                <ArrowBackIcon size={24} />
              </div>
            </div>
            <form>
              <div className="FilterModalInputGroup">
                <div className="FilterModalInputLabel">Blockchain</div>
                <div
                  className="FilterModalInputDropdown"
                  data-e2e="blockChainDropDown"
                  onClick={() => {
                    this.setState({
                      showBlockChainDropDown: true,
                      showCryptoDropDown: false,
                    });
                  }}
                >
                  <div
                    className="FilterModalInputText"
                    data-e2e="FilterModalInputText"
                  >
                    {this.state.editData.blockchain === 'Select'
                      ? 'Select Network'
                      : this.state.editData.blockchain}
                  </div>
                  <i
                    className="fa fa-chevron-down FilterModalInputDropIcon"
                    data-e2e="blockChainDropDown"
                  />
                </div>
                {this.state.showBlockChainDropDown && (
                  <div
                    className="FilterModalCurrencyDropdownlist"
                    data-e2e="FilterModalCurrencyDropdownlist"
                  >
                    <div
                      className="FM_currencyDrop_All"
                      data-e2e="FM_currencyDrop_All"
                      onClick={() => {
                        this.setState({
                          showBlockChainDropDown: false,
                        });
                      }}
                    >
                      <span
                        className="FM_currencyDrop_AllSelector"
                        data-e2e="FM_currencyDrop_AllSelector"
                      >
                        {this.state.editData.blockchain === 'Select'
                          ? 'Select Network'
                          : this.state.editData.blockchain}
                      </span>
                      <span className="FilterModalInputDropUpIconWrap">
                        <i
                          data-e2e="allowableCryptoDropDownIcon"
                          className="fa fa-chevron-up FilterModalInputDropUpIcon"
                        />
                      </span>
                    </div>
                    {this.state.blockchainData &&
                      this.state.blockchainData.map((ele, index) => {
                        return (
                          <div
                            key={index}
                            className="currencyListItemWrap"
                            name="blockchain"
                            data-e2e={ele.name}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                showBlockChainDropDown: false,
                                editData: {
                                  ...this.state.editData,
                                  ['blockchain']: ele.value,
                                },
                                errorMsg: '',
                                errorField: '',
                              });
                            }}
                          >
                            <div className="currencyListIconNameWrap">
                              <div
                                className="currencyList_Name"
                                name="blockchain"
                                data-e2e={ele.name}
                              >
                                {ele.name}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>

              <div
                className="FilterModalInputGroup"
                style={{ marginBottom: '20px' }}
              >
                <div className="FilterModalInputLabel">Crypto</div>
                <div
                  className="FilterModalInputDropdown"
                  data-e2e="cryptoBlockChainDropDown"
                  onClick={() => {
                    this.setState({
                      showBlockChainDropDown: false,
                      showCryptoDropDown: true,
                    });
                  }}
                >
                  <div
                    className="FilterModalInputText"
                    data-e2e="FilterModalInputText"
                  >
                    {this.state.cryptoName === 'Select'
                      ? this.state.editData.cryptoName
                      : this.state.cryptoName}
                  </div>
                  <i
                    className="fa fa-chevron-down FilterModalInputDropIcon"
                    data-e2e="cryptoBlockChainDropDown"
                  />
                </div>
                {this.state.showCryptoDropDown && (
                  <div
                    className="FilterModalCurrencyDropdownlist"
                    data-e2e="FilterModalCurrencyDropdownlist"
                  >
                    <div
                      className="FM_currencyDrop_All"
                      data-e2e="FM_currencyDrop_All"
                      onClick={() => {
                        this.setState({
                          showCryptoDropDown: false,
                        });
                      }}
                    >
                      <span
                        className="FM_currencyDrop_AllSelector"
                        data-e2e="FM_currencyDrop_AllSelector"
                      >
                        {this.state.cryptoName === 'Select'
                          ? this.state.editData.cryptoName
                          : this.state.cryptoName}
                      </span>
                      <span className="FilterModalInputDropUpIconWrap">
                        <i
                          data-e2e="allowableCryptoDropDownIcon"
                          className="fa fa-chevron-up FilterModalInputDropUpIcon"
                        />
                      </span>
                    </div>
                    {this.state.cryptos &&
                      this.state.cryptos.map((ele, index) => {
                        return (
                          <div
                            key={index}
                            className="currencyListItemWrap"
                            name="cryptoId"
                            data-e2e={ele.name}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                showCryptoDropDown: false,
                                cryptoName: `${ele.name} (${ele.symbol})`,
                                editData: {
                                  ...this.state.editData,
                                  ['cryptoId']: ele._id,
                                },
                                errorMsg: '',
                                errorField: '',
                              });
                            }}
                          >
                            <div className="currencyListIconNameWrap">
                              <div
                                className="currencyList_Name"
                                name="cryptoId"
                                data-e2e={ele.symbol}
                              >
                                {ele.name} ({ele.symbol})
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect3"
                >
                  Network
                </label>
                <input
                  type="text"
                  value={this.state.editData.network}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect3"
                  placeholder="Network"
                  name="network"
                  data-e2e="network"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect3"
                >
                  Min Capacity Threshold
                </label>
                <input
                  type="text"
                  value={this.state.editData.minCapacityThreshold}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect3"
                  placeholder="Min Threshold"
                  data-e2e="min_capacity_threshould"
                  name="minCapacityThreshold"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>
              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect3333"
                >
                  Max Capacity Threshold
                </label>
                <input
                  type="text"
                  value={this.state.editData.maxCapacityThreshold}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect333"
                  placeholder="Max Threshold"
                  name="maxCapacityThreshold"
                  data-e2e="max_capacity_threshould"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect4"
                >
                  Deposit Timer
                </label>
                <input
                  type="text"
                  value={this.state.editData.depositTimer}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect4"
                  placeholder="Time in minutes"
                  name="depositTimer"
                  data-e2e="deposit_timer"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect5"
                >
                  Minimum Wallets
                </label>
                <input
                  type="text"
                  value={this.state.editData.minimunWallets}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect5"
                  placeholder="Enter value 0-1000"
                  name="minimunWallets"
                  data-e2e="min_wallets"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect6"
                >
                  Maximum Wallets
                </label>
                <input
                  type="text"
                  value={this.state.editData.maximumWallets}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect6"
                  placeholder="Enter value 0-1000"
                  name="maximumWallets"
                  data-e2e="max_wallets"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="d-flex justify-content-center">
                <div
                  className="submit"
                  data-e2e="submit"
                  onClick={() => {
                    this.updateGlobalSetting();
                  }}
                >
                  Save Changes
                </div>
              </div>
            </form>
            <SavingAlert
              msg="All Transactions Details has been saved successfuly."
              header="Details Saved"
              btnName="Close"
              showModal={this.state.showSavingModal}
              onCloseClickHandler = {()=>{
                this.setState({showSavingModal: false})
                this.props.handleCloseEditGlobalSettings();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    base_url: state.config.api_url,
  };
};


export default connect(
  mapStateToProps,
  null
)(EditAddGlobalSettings);
