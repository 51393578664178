import React, { useState, useEffect } from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../common/Loading';
import localDb from '../../../../localDb';
import { GET_SWEEP_LOGS } from '../../../../config/endpoints';
import { call, logoutAfterError } from '../../../../config/axios';
import SweepLogTable from './SweepLogTable';



const SweepLogs = () => {
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const email = localDb.getVal('email');
    const paymentProviderId = localDb.getVal('paymentProviderId');
    const base_url = useSelector((state) => state.config.api_url);

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState('desc');
    const [sortBy, setSortBy] = useState('creationDate');

    const getSweepLogs = async (page) => {
        let pageNo = 1;
        if (page) {
            pageNo = page;
        }
        let payload = {
            email: email,
            orderBy: orderBy,
            sortBy: sortBy,
            page: pageNo,
            paymentProviderId,
        };

        setLoading(true);
        await call(
            {
                ...GET_SWEEP_LOGS,
                url: base_url + GET_SWEEP_LOGS.url,
            },
            payload
        )
            .then((res) => {
                if (res.status === 200) {
                    if (pageNo === 1) {
                        setData(res.data.sweeplogs);
                        setTotalCount(res.data.totalCount);
                    } else {
                        let list = data.concat(res.data.sweeplogs);
                        setData(list);
                        setTotalCount(res.data.totalCount);
                    }
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.message, 'err');
                logoutAfterError(err)
            });
    };
    useEffect(() => {
        // dispatch(setHeader('ErrorLogs'));
        getSweepLogs();
    }, [orderBy, sortBy]);
    const name = 'Sweep Logs';
    return (
        <div className="pageContainer">
            <HeaderAndFooterLayout>
                <CommonHeader name={name} />
                <div>
                    <SweepLogTable
                        data={data}
                        sortBy={sortBy}
                        orderBy={orderBy}
                        getSweepLogs={() => {
                            getSweepLogs(1)
                        }}
                        changeOrder={(sort) => {
                            if (sortBy !== sort) {
                                setOrderBy('desc');
                                setSortBy(sort);
                            } else {
                                setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
                            }
                        }}
                        handleShowMore={() => {
                            let newPage = page + 1;
                            setPage(newPage);
                            getSweepLogs(newPage);
                        }}
                        showMoreButton={totalCount && data && data.length < totalCount}
                    />
                </div>
                {loading && <Loading />}
            </HeaderAndFooterLayout>
        </div>
    )
}

export default SweepLogs