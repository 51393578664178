import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import Loading from "../../../common/Loading";
import localDb from "../../../../localDb";
import { call, logoutAfterError } from "../../../../config/axios";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import { GET_ALL_DAILY_EXCHANGE_RATES } from "../../../../config/endpoints";
import { setHeader } from "../../../Redux/actions/commonAction";
import DailyExchangeRatesCsv from "./DailyExchangeRatesCsv";
import DailyExchangeRatesFilters from "./DailyExchangeRatesFilters";
import DailyExchangeRatesTable from "./DailyExchangeRatesTable";
import useDebounce from "../../global/useDebounce";

const searchicon = "/cryptonpay/crytoicons/searchicon1.png";
const filterIcon = "/cryptonpay/filter_icon.svg";
const downloadIcon = "/cryptonpay/download_icon.svg";

function DailyExchangeRates() {
  const name = "Daily Exchange Rates";
  const dispatch = useDispatch();
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal("email");
  const base_url = useSelector((state) => state.config.api_url);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearhText] = useState("");
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("date");
  const [fromDate, setFromDate] = useState(
    moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [toDate, setToDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [dateSelected, setDateSelected] = useState(false);

  const [exchangeRateFilter, setExchangeRateFilter] = useState(false);
  const [downloadCsv, setDownloadCsv] = useState(false);

  const [save, setSave] = useState(false);

  const handleSave = () => {
    setSave(!save);
  };
  const handleExchangeRateFilters = () => {
    setExchangeRateFilter(false);
  };

  const resetFilters = () => {
    setSearhText("");
    setOrderBy("desc");
    setSortBy("date");
    setPage(1);
    setDateSelected(false);
    setTotalCount(0);
    setFromDate([
      moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    ]);
    setToDate([moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")]);
  };
  const handleCloseDownloadCsv = () => {
    setDownloadCsv(false);
  };

  const getAllExchageRates = async (page) => {
    let pageNo = 1;

    if (page) {
      pageNo = page;
    }
    let filterParams = {
      cryptoId: searchText,
      page: pageNo,
      sortBy: sortBy,
      orderBy: orderBy,
    };
    if (dateSelected) {
      filterParams = {
        ...filterParams,
        fromDate: fromDate,
        toDate: toDate,
      };
    }
    let payload = {
      email: email,
      filterParams: filterParams,
    };

    setLoading(true);
    await call(
      {
        ...GET_ALL_DAILY_EXCHANGE_RATES,
        url: base_url + GET_ALL_DAILY_EXCHANGE_RATES.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          if (pageNo === 1) {
            setData( res &&
                  res.data &&
                  res.data.dailyExchangeRates &&
                  res.data.dailyExchangeRates);
            setTotalCount(res && res.data && res.data.totalCount && res.data.totalCount);
          } else {
            let list = data.concat( res &&
              res.data &&
              res.data.dailyExchangeRates &&
              res.data.dailyExchangeRates);
            setData(list);
            setTotalCount(res && res.data && res.data.totalCount && res.data.totalCount);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };
  const debouncedSearchText = useDebounce(searchText);
  useEffect(() => {
    dispatch(setHeader("Daily Exchage Rates"));
    getAllExchageRates()
  }, [debouncedSearchText, orderBy, sortBy, save]);

  return (
    <div className="pageContainer merchant_page">
      <HeaderAndFooterLayout>
        <CommonHeader name={name} />
        <div style={{height: "100vh" }}>
          <div className="transaction_filter">
            <div className="transactionsFiltersWrap">
              <div className="transactionsFilters">
                <div className="transactionsFiltersItem transactionsSearchFilter">
                  <div className="transactionsFilterTextWrap">
                    <input
                      type="text"
                      className="transactionsFilterInput"
                      value={searchText}
                      name="searchText"
                      id="searchText"
                      data-e2e="search-textbox"
                      onChange={(e) => {
                        setSearhText(e.target.value);
                        setPage(1);
                      }}
                      placeholder="Search cryptoId"
                    />
                  </div>
                  <div className="transactionsFilterSingleIconWrap">
                    <img
                      src={`${image_base_url}${searchicon}`}
                      alt="searchicon"
                      className="transactionsFilterSingleIcon"
                      data-e2e="searchIcon"
                      onClick={() => {
                        getAllExchageRates();
                      }}
                    />
                  </div>
                </div>

                <div
                  className="filterBtn"
                  onClick={() => {
                    setExchangeRateFilter(true);
                  }}
                >
                  <img
                    data-e2e="filterIcon"
                    src={`${image_base_url}${filterIcon}`}
                    alt="filterIcon"
                    className="filterBtnIcon"
                  />
                </div>
                <Dialog
                  open={exchangeRateFilter}
                  onClose={handleExchangeRateFilters}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DailyExchangeRatesFilters
                    handleExchangeRateFilters={handleExchangeRateFilters}
                    fromDate={moment(fromDate)}
                    toDate={moment(toDate)}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    handleSave={handleSave}
                    setDateSelected={setDateSelected}
                    dateSelected={dateSelected}
                    resetFilters={resetFilters}
                  />
                </Dialog>
              </div>
            </div>
            <div
              className="filterBtn"
              style={{ marginLeft: "10px" }}
              onClick={() => setDownloadCsv(true)}
            >
              <img
                data-e2e="downloadIcon"
                src={`${image_base_url}${downloadIcon}`}
                alt="filterIcon"
                className="downloadBtnIcon"
              />
            </div>
            <Dialog
              open={downloadCsv}
              onClose={handleCloseDownloadCsv}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DailyExchangeRatesCsv
                handleCloseDownloadCsv={handleCloseDownloadCsv}
                data={data}
                email={email}
                base_url={base_url}
                resetFilters={resetFilters}
                name="merchants"
                data-e2e="merchants"
              />
            </Dialog>
          </div>
          <div>
            <DailyExchangeRatesTable
              data={data}
              sortBy={sortBy}
              orderBy={orderBy}
              changeOrder={(sort) => {
                if (sortBy !== sort) {
                  setOrderBy("desc");
                  setSortBy(sort);
                } else {
                  setOrderBy(orderBy === "desc" ? "asc" : "desc");
                }
              }}
              handleShowMore={() => {
                let newPage = page + 1;
                setPage(newPage);
                getAllExchageRates(newPage);
              }}
              showMoreButton={totalCount && data && data.length < totalCount}
            />
          </div>
        </div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
}

export default DailyExchangeRates;
