import {call, logoutAfterError} from "../../../../../config/axios";
import {GET_CRYPTO_AMOUNT_EXCHANGE_RATE} from "../../../../../config/endpoints";
import localDb from "../../../../../localDb";

export const getCryptoIcon = (symbol) => {
  switch (symbol) {
    case "BTC":
      return "/cryptonpay/crytoicons/bitcoin1.png";
    case "ETH":
      return "/cryptonpay/crytoicons/eth1.png";
    case "USDT":
      return "/cryptonpay/usdt.png";
    case "DAI":
      return "/cryptonpay/crytoicons/dai.png";
    case "BCC":
      return "/cryptonpay/crytoicons/btt_cash1.png";
    case "USDC":
      return "/cryptonpay/crytoicons/usd.png";
    case "EOS":
      return "/cryptonpay/crytoicons/eos.png";
    case "XRP":
      return "/cryptonpay/crytoicons/xrp.png";
    default:
      return "/cryptonpay/crytoicons/usd.png";
  }
};

export const getExchangeRate = async (
  fromCurrency,
  toCurrency,
  currencyAmount,
  viewTransactionUrl,
  setError
) => {
  if (fromCurrency === "USDC" || fromCurrency === "DAI") {
    fromCurrency = "USDT";
  }
  const email = localDb.getVal("email");
  try {
    const res = await call(
      {
        ...GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
        url: viewTransactionUrl + GET_CRYPTO_AMOUNT_EXCHANGE_RATE.url,
      },
      {
        email: email,
        fromCurrency: fromCurrency,
        toCurrency: toCurrency,
        currencyAmount: currencyAmount,
      }
    );
    if (res.data) {
      return parseFloat(res.data).toFixed(8);
    } else {
      throw new Error('No data returned from exchange rate service');
    }
  } catch (err) {
    setError("Something went wrong while fetching exchange values.");
    console.log(err);
    return null;
  }
};

export const mapHeaderToKey = (header) => {
  switch (header) {
    case "DATE":
      return "updated";
    case "TRANSACTION TYPE":
      return "transactionType";
    case "TRANSACTION ID":
      return "transactionId";
    case "ASSET":
      return "cryptoId";
    case "AMOUNT":
      return "amount";
    case "PREVIOUS BALANCE":
      return "previousBalance";
    case "CURRENT BALANCE":
      return "currentBalance";
    default:
      return null;
  }
}
