import moment from 'moment';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    searchText: "",
    minCryptoAmount: "",
    maxCryptoAmount: "",
    rangeError: "",
    orderBy: "desc",
    sortBy: "status.updated",
    fromDate: moment().subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    toDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    dateSelected: false,
    selectedStatusList: [
        'In Use',
        'Available',
        'Locked',
        'Archived',
        'Full'
    ],
    selectedStatusIconList: "All",
    selectedBlockchainList: [],
    selectedNetworkList: [
        "mainnet",
        "testnet",
    ],
    selectedCryptos: [],
    selectedPaymentProvider: [],
    cryptoAmount: ""
};

const setValue = (state, action, field) => {
    return {
        ...state,
        [field]: action.data
    }
}

const transactionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SEARCH_TEXT_WALLET: return setValue(state, action, "searchText");
        case actionTypes.SET_MIN_CRYPTO_AMOUNT: return setValue(state, action, "minCryptoAmount");
        case actionTypes.SET_MAX_CRYPTO_AMOUNT: return setValue(state, action, "maxCryptoAmount");
        case actionTypes.SET_RANGE_ERROR: return setValue(state, action, "rangeError");
        case actionTypes.SET_ORDER_BY_WALLET: return setValue(state, action, "orderBy");
        case actionTypes.SET_SORT_BY_WALLET: return setValue(state, action, "sortBy");
        case actionTypes.SET_FROM_DATE_WALLET: return setValue(state, action, "fromDate");
        case actionTypes.SET_TO_DATE_WALLET: return setValue(state, action, "toDate");
        case actionTypes.SET_DATE_SELECTED_WALLET: return setValue(state, action, "dateSelected");
        case actionTypes.SET_SELECTED_STATUS_LIST_WALLET: return setValue(state, action, "selectedStatusList");
        case actionTypes.SET_SELECTED_STATUS_ICON_LIST: return setValue(state, action, "selectedStatusIconList");
        case actionTypes.SET_SELECTED_BLOCKCHAIN_LIST: return setValue(state, action, "selectedBlockchainList");
        case actionTypes.SET_SELECTED_NETWORK_LIST_WALLET: return setValue(state, action, "selectedNetworkList");
        case actionTypes.SET_SELECTED_CRYPTOS_WALLET: return setValue(state, action, "selectedCryptos");
        case actionTypes.SET_SELECTED_PAYMENT_PROVIDER_WALLET: return setValue(state, action, "selectedPaymentProvider");
        case actionTypes.SET_CRYPTO_AMOUNT: return setValue(state,action,"cryptoAmount")
        default:
            return state;
    }
};

export default transactionReducer;