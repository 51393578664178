import React, { useState } from 'react';
import Dialog from "@mui/material/Dialog";
import { styled } from '@mui/material/styles';

const useStyles = styled(Dialog)({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "white"
        }
    }
});

const EditWalletBalance = (props) => {
    const classes = useStyles;
    const [error, setError] = useState("");
    return (
        <div>
            <Dialog open={true} className={`${classes.root} change_status_modal mt-4`} style={{ height: 'fit-content' }}>
                <div className="change_status_body">
                    <img
                        src={`${props.image_base_url}/homeb2c/change_status_logo.svg`}
                        alt="image"
                        className="d-block m-auto"
                    />
                    <div className="change_trans_header mt-4 ">
                        Edit balances
                    </div>
                    <div className="FilterModalInputGroup">
                        <div className="FilterModalInputLabel">Current balance:</div>
                        <input disabled type="text" value={props.value} className="change_trans_note" style={{ height: 'auto', padding: "5px" }} />
                    </div>
                    <div className="FilterModalInputGroup">
                        <div className="FilterModalInputLabel">Current balance:</div>
                        <input type="text" value={props.newBalanceInCrypto} className="change_trans_note" style={{ height: 'auto', padding: "5px" }}
                            onChange={(e) => {
                                props.changeNewCryptoBalance(e.target.value);
                            }} />
                    </div>
                    <textarea
                        placeholder="Add note.."
                        data-e2e="add-note"
                        value={props.balanceUpdateNote}
                        name="balanceUpdateNote"
                        className="change_trans_note mt-4"
                        cols="40"
                        rows="5"
                        onChange={(e) => {
                            props.changeBalanceUpdateNote(e.target.value);
                        }}
                    />
                    {error && (
                        <div className="add_note_error">
                            {"Please Add something "}
                        </div>
                    )}
                    <div className="notify_btn_wrap">
                        <div
                            className="change_trans_button mt-4"
                            onClick={() => {
                                if (props.newBalanceInCrypto && props.balanceUpdateNote &&
                                    props.newBalanceInCrypto != "" &&
                                    props.balanceUpdateNote != "" &&
                                    error === "") {
                                    props.saveNewBalanceValues(props.editWalletBalanceData.cryptoId, props.editWalletBalanceData.cryptoName)
                                }
                            }}
                        >
                            Save
                        </div>
                        <div
                            className="change_trans_go_back mt-4"
                            onClick={() => props.onClose()}
                        >
                            Go Back
                        </div>
                    </div>

                </div>
            </Dialog>
        </div>
    )
}

export default EditWalletBalance;