import React from 'react'
import { Link } from 'react-router-dom'

export const MerchantsAccountTr = (props) => {
	const { link, name, value, showCustomValue, userCount, showErrorByGroup, showErrorDetails, setShowErrorDetails, datae2e } = props;
	return (
		<div className='d-flex align-items-center justify-content-between'>
		<div className={`dashboard__account_tr ${link ? 'has-hover' : ''}`}>
			{link && <Link data-e2e={datae2e} to={link}></Link>}
			<p>{name}</p>

			{!showCustomValue && <p>
				{props.totalAmount && <span>({numberFormator(props.totalAmount.numbers, 2) + "$"}) </span>}
				{props.dollarSign && <span>$ </span>}{!props.spreadValue && (props.numbers ? Number(props.numbers).toLocaleString() : 0)}
				{props.spreadValue && <span>{numberFormator(props.spreadValue.numbers, 2) + " $"}</span>}
				{userCount && <span>{userCount  && <span>{userCount && ` (${userCount})` || ""}</span>}</span>}
			</p>}
			{showCustomValue && <p><span>{value}</span></p>}
		</div >
		{showErrorByGroup && <i onClick={() => setShowErrorDetails(!showErrorDetails)} className={`rejection-list-dropdwon-icon cursor-pointer fa fa-chevron-${showErrorDetails ? "up" : "down"} ml-2`}></i>}
		</div>
	)
}

function numberFormator(num, digits) {
	var si = [
		{ value: 1, symbol: "" },
		{ value: 1E3, symbol: "K" },
		{ value: 1E6, symbol: "M" },
		{ value: 1E9, symbol: "G" },
		{ value: 1E12, symbol: "T" },
		{ value: 1E15, symbol: "P" },
		{ value: 1E18, symbol: "E" }
	];
	var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var i;
	// for negative value is work
	for (i = si.length - 1; i > 0; i--) {
		if (Math.abs(num) >= si[i].value) {
			break;
		}
	}
	return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}