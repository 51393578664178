import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';


const ResetModal=(props)=>{
     const onReset=()=>{
        props.reset()
        props.close()
     }
    const image_base_url = useSelector((state) => state.config.image_base_url);
    return (
        <Dialog open={props.open}  onClose={props.close}>
            <div className='reset-container'>
                <div>
                <img
                    src={`${image_base_url}/homeb2c/reset-icon.svg`}
                    alt="reset-icon"
                    data-e2e="reset-icon"
                    width="50px"
                    height="50px"
                  />
                </div>
                <div className='reset-filters'>Reset Filters</div>
                <div className='confirm-reset-message'>Are you sure you want to reset applied filters? This action cannot be undone.</div>
                <div className='reset-modal-button' onClick={onReset}>
                    <span>Clear</span>
                </div>
                <div className='go-back-btn' onClick={props.close}>
                    <span>Go Back</span>
                </div>
            </div>
        </Dialog>
    )
}


export default ResetModal