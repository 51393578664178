import React, { useState } from "react";
import { useSelector } from "react-redux";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  UPDATE_CUSTOMCITY,
  DELETE_CUSTOMCITY,
} from "../../../../config/endpoints";
import Loading from "../../../common/Loading";

import localDb from "../../../../localDb";

const closeImage = "/cryptonpay/crytoicons/close.png";

function AddOrEditCity({
  handleCloseAddNew,
  editCity,
  currentCityName,
  stateName,
  country_id,
  setReload,
  reload,
  setCityNew,
}) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const [loading, setLoading] = useState(false);
  const [addCity, setAddCity] = useState(currentCityName || "");
  const [errorMessage, setErrorMessage] = useState(false);

  const handleSaveDetails = async () => {
    if (!addCity) {
      setErrorMessage(true);
      return;
    } else {
      var payloadEditCity = {
        country_id: country_id,
        editCity: {
          stateName: stateName,
          currentCityName: currentCityName,
          editCity: addCity,
        },
      };

      var payloadAddCity = {
        country_id: country_id,
        addCity: {
          stateName: stateName,
          addCity: addCity,
        },
      };

      setLoading(true);
      let URL;
      if (editCity) {
        URL = {
          ...UPDATE_CUSTOMCITY,
          url: base_url + UPDATE_CUSTOMCITY.url,
        };
        var payload = payloadEditCity;
      } else {
        URL = {
          ...UPDATE_CUSTOMCITY,
          url: base_url + UPDATE_CUSTOMCITY.url,
        };
        var payload = payloadAddCity;
      }
      await call(URL, payload)
        .then((res) => {
          setLoading(false);
          setCityNew(false);
          setAddCity("");
          if (res.status === 200) {
            setEditData([]);
            setLoading(false);
            handleCloseAddNew();
            setReload(!reload);
          }
        })
        .catch((err) => {
          setLoading(false);
          setReload(!reload);

          logoutAfterError(err);
        });
    }
  };

  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <div className="font-large font-weight-bold text-lg">{`${
          editCity ? "Edit" : "Add"
        } City`}</div>
        <div
          className="filter_close_button"
          onClick={() => {
            handleCloseAddNew();
          }}
        >
          <img
            src={`${image_base_url}${closeImage}`}
            alt="closeImage"
          />
        </div>
      </div>
      <div className="upload__merchantId">
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">City Name: </div>
          <input
            type="text"
            placeholder="City"
            value={addCity}
            onChange={(e) => setAddCity(e.target.value)}
          />
        </div>
      </div>
      {errorMessage && (
        <div className="my-2 w-full text-left text-red-600">
          {"Please fill City Name"}
        </div>
      )}
      <div className="d-flex justify-content-center pt-4">
        <button
          type="button"
          onClick={() => {
            handleSaveDetails();
          }}
          className="btn btn-primary btn-md rounded-pill add-btn w-100"
        >
          {editCity ? "Update" : "Add"}
        </button>
      </div>
      {loading && <Loading />}
      
    </div>
  );
}

export default AddOrEditCity;
