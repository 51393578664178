import React, { useState } from "react";
import { useSelector } from "react-redux";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  UPDATE_CUSTOMCITY,
  DELETE_CUSTOMCITY,
} from "../../../../config/endpoints";

import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";

const closeImage = "/cryptonpay/crytoicons/close.png";

function AddOrEditState({
  handleCloseAddNew,
  editState,
  country_id,
  currentStateName,
  nativeStateName,
  setReload,
  reload,
  stateCode,
  setOpenEditStateIcon,
  setAddNew
}) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const [loading, setLoading] = useState(false);
  const [addState, setAddState] = useState(currentStateName|| "");
  const [addStateCode, setAddStateCode] = useState(stateCode || "");
  const [addNativeStateName, setAddNativeStateName] = useState(nativeStateName || "");
  const [errorMessage, setErrorMessage] = useState(false);
  

  const handleSaveDetails = async () => {
    if(!addState || !addStateCode || !addNativeStateName){
      setErrorMessage(true)
      return
    }
    else{
      var payloadEditState = {
        country_id: country_id,
        editState: {
          currentStateName: currentStateName,
          editStateName: addState,
          editNativeStateName: addNativeStateName,
          editStateCode: addStateCode,
        },
      };
  
      var payloadAddState = {
        country_id: country_id,
        addState: {
          stateName: addState,
          nativeStateName: addNativeStateName,
          stateCode: addStateCode,
        },
      };
  
      setLoading(true);
      let URL;
      if (editState) {
        URL = {
          ...UPDATE_CUSTOMCITY,
          url: base_url + UPDATE_CUSTOMCITY.url,
        };
        var payload = payloadEditState;
      } else {
        URL = {
          ...UPDATE_CUSTOMCITY,
          url: base_url + UPDATE_CUSTOMCITY.url,
        };
        var payload = payloadAddState;
        
      }
      await call(URL, payload)
        .then((res) => {
          setLoading(false);
          setOpenEditStateIcon(false)
            setAddNew(false)
            
          if (res.status === 200) {
            setEditData([]);
            
            setReload(!reload);
            
            setAddNativeStateName("")
  
            handleCloseAddNew();
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setReload(!reload);
  
          logoutAfterError(err);
        });
    }
  };

  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <div className="font-large font-weight-bold text-lg">{`${
          editState ? "Edit" : "Add"
        } State`}</div>
        <div
          className="filter_close_button"
          data-e2e="filter-close-button"
          onClick={() => {
            handleCloseAddNew();
          }}
        >
          <img
            src={`${image_base_url}${closeImage}`}
            alt="closeImage"
            data-e2e="close-icon"
          />
        </div>
      </div>
      <div className="upload__merchantId">
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">State Name: </div>
          <input
            type="text"
            placeholder="State Name"
            value={addState}
            onChange={(e) => setAddState(e.target.value)}
          />
        </div>
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">State Code: </div>
          <input
            type="text"
            placeholder="State Code"
            value={addStateCode}
            onChange={(e) => setAddStateCode(e.target.value)}
          />
        </div>
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Native State Name: </div>
          <input
            type="text"
            placeholder="Native State Name"
            value={addNativeStateName}
            onChange={(e) => setAddNativeStateName(e.target.value)}
          />
        </div>
      </div>
      {errorMessage && (
        <div className="my-2 w-full text-left text-red-600">
          {"Please fill all State details"}
        </div>
      )}
      <div className="d-flex justify-content-center pt-4">
        <button
          type="button"
          onClick={() => {
            handleSaveDetails();
          }}
          className="btn btn-primary btn-md rounded-pill add-btn w-100"
        >
          {editState ? "Update" : "Add"}
        </button>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default AddOrEditState;
