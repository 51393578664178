import React from "react";
import {TransactionBtn} from "../CommonPages/TransactionView";
import {getCryptoIcon} from "./utils/helperFunctions";
import moment from "moment/moment";

export const BalanceTableMobile = ({
 walletDetailBtn,
 setWalletDetailBtn,
 data,
 imageBaseUrl,
 handleEditBalance
}) => {
  return (
    <React.Fragment>
      <div className="TransactionBtn_container pt-5">
        <TransactionBtn
          detailBtn={walletDetailBtn}
          view={"Merchant User Wallets"}
          setDetailBtn={setWalletDetailBtn}
          btn={["Wallets", "Event log"]}
        />
      </div>
      <div className="wallets_detail_content">
        {(
          <div className="wallets_balanceWrap">
            <div className="totalUsdBalance_row">
              <div>{`Approx USD balance`}</div>
              <div className="approx_balance">
                {data ? data.balanceAmountUSDApprox : "N/A"}
              </div>
            </div>
            {data &&
              data.balance &&
              data.balance.length > 0 &&
              data.balance.map((item, index) => {
                return (
                  <div className="balance_content_row" key={item._id}>
                    <div className="d-flex align-items-center w-40">
                      <img
                        src={`${imageBaseUrl}${getCryptoIcon(
                          item.cryptoId
                        )}`}
                        alt={item.cryptoId}
                        className="currencyList_Icon"
                        data-e2e="currencyList_Icon"
                        width="25px"
                        height="25px"
                      />
                      <div className="pl-2 pr-1">
                        {item.cryptoName || "N/A"}
                      </div>
                      <div>
                        ({item.cryptoId})
                      </div>
                    </div>
                    <div
                      className="text-primary"
                      onClick={() => {
                        handleEditBalance(item);
                      }}
                    >
                      {item.balanceAmount
                        ? item.balanceAmount
                        : item.balanceAmount === 0
                          ? 0
                          : "-"}
                    </div>
                    <div>
                      {item.updated
                        ? moment(item.updated).format("DD/MM/YY")
                        : "N/A"}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}