import React, { useState, useEffect } from "react";
import CommonHeader from "../CommonPages/CommonHeader";
import { Table } from "reactstrap";
import Loading from "../../../common/Loading";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
import { CREATE_FIAT_CONFIG_CARD_DEPOSIT, DELETE_FIAT_CONFIG_CARD_DEPOSIT, 
  GET_FIAT_CONFIGS_CARD_DEPOSIT, UPDATE_FIAT_CONFIG_CARD_DEPOSIT } from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { call } from "../../../../config/axios";
import Select from "react-select";
import DeletingAlert from "../CommonPages/DeletingAlert";
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TableCell, TableRow } from "@mui/material";

const fiatOptions = [
    {
      label: "EUR",
      value: "EUR"
    },
    {
      label: "USD",
      value: "USD"
    },
    {
      label: "GBP",
      value: "GBP"
    },
    {
      label: "AED",
      value: "AED"
    },
    {
      label: "CAD",
      value: "CAD"
    },
    {
      label: "AUD",
      value: "AUD"
    },
    {
      label: "CHF",
      value: "CHF"
    },
    {
      label: "TRY",
      value: "TRY"
    },
    {
      label: "HKD",
      value: "HKD"
    },
    {
      label: "SGD",
      value: "SGD"
    },
    {
      label: "JPY",
      value: "JPY"
    },
    {
      label: "CNY",
      value: "CNY"
    },
    {
      label: "MXN",
      value: "MXN"
    },
    {
      label: "THB",
      value: "THB"
    },
    {
      label: "NOK",
      value: "NOK"
    }
  ]
const fiatOptionsCard = [
    {
      label: "USD",
      value: "USD"
    },
    {
        label: "EUR",
        value: "EUR"
      },
    {
      label: "GBP",
      value: "GBP"
    },
  ]
const ToggleSwitch = styled(Switch)(({ theme }) => ({
  width: 46,
  height: 28,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 30,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(19px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(19px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 24,
    height: 24,
    borderRadius: 12,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 32 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const FiatConfigCard = ({ merchantId }) => {
  const email = localDb.getVal("email");
  const baseUrl = useSelector((state) => state.config.api_url);
  const [fiatConfigData, setFiatConfigData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedFiatCurrency, setSelectedFiatCurrency] = useState("");
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const closeImage = "/cryptonpay/crytoicons/close.png";
  const [deletingId, setDeletingId] = useState(null);
  const [editData, setEditData] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [error, setError] = useState('')

  const onClose = () => {
    setOpen(false);
    setEditData(false);
    setSelectedCurrency('');
    setSelectedFiatCurrency('');
    setError('');
  };

  const getFiatConfigCardList = async () => {
    setLoading(true);
    try {
      const result = await call(
        {
          ...GET_FIAT_CONFIGS_CARD_DEPOSIT,
          url: baseUrl + GET_FIAT_CONFIGS_CARD_DEPOSIT.url,
        },
        { email: email, merchantId: merchantId },
      );
      if (result.status === 200) {
        setFiatConfigData(result?.data);
      }
      setLoading(false);
    } catch (err) {
      console.error(err, "in FiatConfigCardList");
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getFiatConfigCardList();
  }, []);
  
  const createOrUpdateFiatConfigCard = async () => {
    setLoading(true);
    let URL;
    let payload = {
      email: email,
    };
    if (editData) {
      URL = {
        ...UPDATE_FIAT_CONFIG_CARD_DEPOSIT,
        url: baseUrl + UPDATE_FIAT_CONFIG_CARD_DEPOSIT.url,
      };
      payload={...payload,
        id:selectedData?._id,
        updateData:{
          ...selectedData,
          currency: selectedCurrency,
          exchangeTo: selectedFiatCurrency,
        }
       }
    } else {
      payload = {...payload, 
        merchantId: merchantId,
        currency: selectedCurrency,
        exchangeTo: selectedFiatCurrency,
        isEnabled: true}
      URL = {
        ...CREATE_FIAT_CONFIG_CARD_DEPOSIT,
        url: baseUrl + CREATE_FIAT_CONFIG_CARD_DEPOSIT.url,
      };
    }
    try {
      const res = await call(URL, payload);
      if (res.status === 200) {
        if (editData) {
          setOpen(false);
        setLoading(false);
        getFiatConfigCardList();
        setSelectedData(null);
        setEditData(false);
        }
        setSelectedCurrency(null)
        setSelectedFiatCurrency(null)
        setOpen(false);
        setLoading(false);
        getFiatConfigCardList();
      }
    } catch (err) {
      setError(err?.response?.data?.err)
      setLoading(false);
      console.log(err.message, 'err');
    }
  };
  const updateFiatConfigCard = async (fiatConfigData) => {
    setLoading(true);
    try {
      const res = await call({
        ...UPDATE_FIAT_CONFIG_CARD_DEPOSIT,
        url: baseUrl + UPDATE_FIAT_CONFIG_CARD_DEPOSIT.url,
      }, {
        email: email,
        id: fiatConfigData?._id,
        updateData:{
          ...fiatConfigData,
          isEnabled:!fiatConfigData.isEnabled
        }
      });
      setLoading(false);
      if (res.status === 200) {
        setEditData(false);
        setSelectedData(false);
        setSelectedCurrency('');
        setSelectedFiatCurrency('');
        getFiatConfigCardList();
      }
    } catch (err) {
      setLoading(false);
      console.error(err?.message, 'err');
    }
  };

  const setEditFiatConfigData = async(fiatConfigData) => {
    setSelectedCurrency(fiatConfigData.currency);
    setSelectedFiatCurrency(fiatConfigData.exchangeTo);
    setSelectedData(fiatConfigData)
  }

  const deleteFiatConfigCard = async () => {
    setLoading(true);
    try {
      const res = await call({
        ...DELETE_FIAT_CONFIG_CARD_DEPOSIT,
        url: baseUrl + DELETE_FIAT_CONFIG_CARD_DEPOSIT.url,
      }, {
        email: email,
        id: deletingId,
      });
      setLoading(false);
      
      if (res.status === 200) {
        setDeletingId(null);
        getFiatConfigCardList();
      }
    } catch (err) {
      setLoading(false);
      console.log(err.message, 'err');
    }
  };
    return (
        <div className="pageContainer merchant_page">
          <CommonHeader name={"Fiat Currency Configuration"} />
          <div className="payment-provider-add-btn">
            <button
              className={`theme-table-action add-country-btn `}
              onClick={() => 
                setOpen(true)}
            >
              Add Fiat Config
            </button>
          </div>
          <div className="TransactionTableMain">
            {fiatConfigData.length >= 0 ? (
              <Table>
                <tbody className="TransactionTable_BODY merchant_table_text_align">
                  <tr className="TransactionTable_BODY_Header">
                    <th className="TransactionTable_heading">From Currency </th>
                    <th className="TransactionTable_heading">To Currency </th>
                    <th className="TransactionTable_heading">Actions </th>
                  </tr>
                  {fiatConfigData.map((fiatConfigData) => {
                    return (
                      <FiatConfigCardTableRow
                        fiatConfigData={fiatConfigData}
                        key={fiatConfigData._id}
                        setDeletingId={setDeletingId}
                        updateFiatConfigCard={updateFiatConfigCard}
                        setEditData={()=>{setEditData(true);
                        setEditFiatConfigData(fiatConfigData)
                        setOpen(true)}}
                      />
                    );
                  })}
                </tbody>
                <Dialog
                  open={open}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth={"lg"}
                  disableEnforceFocus={true}
                  onClose={onClose}
                >
                  <div className="filter_model_container">
                    <div className="filter_model">
                      <div className="filter_model_heading_wrap">
                        <div className="filter_heading"> {`${editData?'Edit':'Add'} Currency Configuration`}</div>
                      </div>
                      <div className="filter_close_button" onClick={onClose}>
                        <img
                          src={`${imageBaseUrl}${closeImage}`}
                          alt="closeImage"
                          data-e2e="close-icon"
                        />
                      </div>
                    </div>
                    <div className="form-group myAccoutOptModal_form_group transferModalGroup mt-40">
                        <div className="FilterModalInputLabel f-bold">From Currency</div>
                          <Select
                            maxMenuHeight={200}
                            placeholder={"Select Currency..."}
                            options={fiatOptions}
                            value = {fiatOptions.find(option => option.value === selectedCurrency)}
                            onChange={(value) => {setSelectedCurrency(value.value)}}
                          />
                    </div>
                    <div className="form-group myAccoutOptModal_form_group transferModalGroup mt-40">
                        <div className="FilterModalInputLabel f-bold">To Currency</div>
                          <Select
                           maxMenuHeight={114}
                            placeholder={"Select Currency..."}
                            options={fiatOptionsCard}
                            value = {fiatOptionsCard.find(option => option.value === selectedFiatCurrency)}                        
                            onChange={(value) => {setSelectedFiatCurrency(value.value)}}
                          />
                    </div>
                    {error && <div className="text-red-500 mt-10 f-bold">{error}</div>}
                    <div className="FilterModalSave mt-40">
                      <button onClick={createOrUpdateFiatConfigCard}>{`${editData?'Update':'Add'} `}Fiat Configuration</button>
                    </div>
                  </div>
                </Dialog>
                <DeletingAlert
                    deletingId={deletingId ? deletingId : null}
                    onHide={() => {
                      setDeletingId(null);
                    }}
                    msg="Are you sure you want to delete this Fiat Config?"
                    onDeleteClickHandler={() => {
                      deleteFiatConfigCard();
                      setDeletingId(false)
                    }}
                />
              </Table>
            ) : (
              <div>No Data</div>
            )}
          </div>
          {loading && <Loading />}
        </div>
      );
    };  
export default FiatConfigCard;

const FiatConfigCardTableRow = ({
  fiatConfigData,
  setDeletingId,
  updateFiatConfigCard,
  setEditData
}) => {
  return (
    <TableRow className="housekeeping-table-row">
      <TableCell>
        {fiatConfigData.currency || "N/A"}
      </TableCell>
      <TableCell>
        {fiatConfigData.exchangeTo || "N/A"}
      </TableCell>
      <TableCell className="w-40">
        <div className="flex justify-between">
          <IconButton 
            onClick={() => setEditData(fiatConfigData)} 
            data-e2e="edit-button"
          >
            <EditIcon fontSize="medium" color="action" />
          </IconButton>
          <IconButton
            onClick={() => setDeletingId(fiatConfigData?._id)} 
            aria-label="delete"
          > 
            <i className={`fa fa-trash deleteB2cUserIcon cursor-pointer pr-3`} ></i>
          </IconButton>
          <Stack direction="row" spacing={1} alignItems="center">
            <ToggleSwitch 
              onChange={() => updateFiatConfigCard(fiatConfigData)} 
              checked={fiatConfigData?.isEnabled}
              aria-label="Enable or disable fiat config"
            />
          </Stack>
        </div>
      </TableCell>
    </TableRow>
  );
};
