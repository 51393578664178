import React, { useState, useEffect } from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Loading from '../../../common/Loading';
import localDb from '../../../../localDb';
import { GET_B2C_USER_WALLETS, GET_CRYPTOS } from '../../../../config/endpoints';
import { call, logoutAfterError } from '../../../../config/axios';
import moment from 'moment';
import { setHeader } from '../../../Redux/actions/commonAction';
import UserWalletTable from './UserWalletTable';
import TransactionFilters from '../Transactions/TransactionFilters';
import DownloadCsv from '../CommonPages/DownloadCsv';
import SettingsIcon from '@mui/icons-material/Settings';
import useDebounce from '../../global/useDebounce';
const searchicon = '/cryptonpay/crytoicons/searchicon1.png';
const filterIcon = "/cryptonpay/filter_icon.svg";
const downloadIcon = "/cryptonpay/download_icon.svg";

function UserWalletsList() {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal('email');
  const base_url = useSelector((state) => state.config.api_url);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearhText] = useState('');
  const [orderBy, setOrderBy] = useState('desc');
  const [sortBy, setSortBy] = useState('status.updated');
  const [fromDate, setFromDate] = useState(
    moment().subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  );
  const [toDate, setToDate] = useState(
    moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  );
  const [dateSelected, setDateSelected] = useState(false);
  const [transactionFitlers, setTransactionFilters] = useState(false);

  const [statusList, setStatusList] = useState([
    'In Use',
    'Available',
    'Locked',
  ]);
  const [selectedStatusList, setSelectedStatusList] = useState([
    'In Use',
    'Available',
    'Locked',
  ]);
  const [blockchainList, setBlockchainList] = useState([
    'Ethereum',
    'Bitcoin',
  ]);

  const [selectedBlockchainList, SetSelectedBlockchainList] = useState([
    'Ethereum',
    'Bitcoin',
  ]);

  const [networkList, setNetworkList] = useState([
    'mainnet',
    'testnet',
  ]);

  const [selectedNetworkList, setSelectedNetworkList] = useState([
    'mainnet',
    'testnet',
  ]);

  const [cryptos, setCryptos] = useState([]);
  const [selectedCryptos, setSelectedCryptos] = useState([]);
  const [save, setSave] = useState(false);

  const handleTransactionFilters = () => {
    setTransactionFilters(false);
  };
  const [downloadCsv, setDownloadCsv] = useState(false);

  const handleCloseDownloadCsv = () => {
    setDownloadCsv(false);
  };

  const handleSave = () => {
    setSave(!save);
  };
  const resetFilters = () => {
    setSearhText('');
    setOrderBy('desc');
    setSortBy('status.updated');
    setPage(1);
    setDateSelected(false);
    setTotalCount(0);
    setSelectedStatusList(['In Use', 'Available', 'Locked']);
    setBlockchainList([
      'Ethereum',
      'Bitcoin',
    ]);
    setNetworkList([
      'mainnet',
      'testnet',
    ]);
    setFromDate([
      moment().subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    ]);
    setToDate([moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')]);
    setSelectedCryptos([]);
  };
  
  const getCryptos = async () => {
    await call(
      {
        ...GET_CRYPTOS,
        url: base_url + GET_CRYPTOS.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setCryptos(res.data.cryptos);

          let cryptos = res.data.cryptos.map((crypto) => {
            return crypto._id;
          });
          setSelectedCryptos(cryptos);
        }
      })
      .catch((err) => {
        console.log(err.message, 'UserWalletsList > getCryptos > err')
        logoutAfterError(err)
    });
  };

  useEffect(() => {
    getCryptos();
  }, []);

  const getB2CUserWallets = async (page) => {
    let pageNo = 1;
    if (page) {
      pageNo = page;
    }
    let filterParams = {
      email: email,
      wallet_id: searchText.toString(),
      orderBy: orderBy,
      sortBy: sortBy,
      page: pageNo,
      blockchain: selectedBlockchainList.length === 0 ||
        selectedBlockchainList.length === blockchainList.length
        ? 'All'
        : selectedBlockchainList,
      network: selectedNetworkList.length === 0 ||
        selectedNetworkList.length === networkList.length
          ? 'All'
          : selectedNetworkList,
      status:
        selectedStatusList.length === 0 ||
        selectedStatusList.length === statusList.length
          ? 'All'
          : selectedStatusList,
      CryptoCurrency:
        selectedCryptos.length === 0 ||
        selectedCryptos.length === cryptos.length
          ? 'All'
          : selectedCryptos,
    };

    if (dateSelected) {
      filterParams = {
        ...filterParams,
        fromDate: fromDate,
        toDate: toDate,
      };
    }
    let payload = {
      email: email,
      filterParams: filterParams,
    };
    setLoading(true);
    await call(
      {
        ...GET_B2C_USER_WALLETS,
        url: base_url + GET_B2C_USER_WALLETS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          if (pageNo === 1) {
            setData(res.data.userWallet);
            setTotalCount(res.data.totalCount);
          } else {
            let list = data.concat(res.data.userWallet);
            setData(list);
            setTotalCount(res.data.totalCount);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, 'UserWalletsList > getB2CUserWallets > err');
        logoutAfterError(err)
      });
  };
  const debouncedSearchText = useDebounce(searchText);
  useEffect(() => {
    dispatch(setHeader('User Wallets'));
    getB2CUserWallets()
  }, [debouncedSearchText, orderBy, sortBy, save]);

const name = 'B2C User Wallets';

  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
        <CommonHeader name={name} />
        <div>
          <div className="transaction_filter">
            <div className="transactionsFiltersWrap">
              <div className="transactionsFilters">
                <div className="transactionsFiltersItem transactionsSearchFilter">
                  <div className="transactionsFilterTextWrap">
                    <input
                      type="text"
                      className="transactionsFilterInput"
                      value={searchText}
                      name="searchText"
                      data-e2e="search-textbox"
                      onChange={(e) => {
                        setSearhText(e.target.value);
                        setPage(1);
                      }}
                      placeholder="Search Wallets Type, User wallets address"
                    />
                  </div>
                  <div className="transactionsFilterSingleIconWrap">
                    <img
                      src={`${image_base_url}${searchicon}`}
                      alt="searchicon"
                      className="transactionsFilterSingleIcon"
                      data-e2e="searchIcon"
                      onClick={() => {
                        getB2CUserWallets();
                      }}
                    />
                  </div>
                </div>

                <div
                  className="filterBtn"
                  data-e2e="filterBtn"
                  onClick={() => {
                    setTransactionFilters(true);
                  }}
                >
                  <img
                    data-e2e="filterIcon"
                    src={`${image_base_url}${filterIcon}`}
                    alt="filterIcon"
                    className="filterBtnIcon"
                  />
                </div>
                <Dialog
                  open={transactionFitlers}
                  onClose={handleTransactionFilters}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <TransactionFilters
                    filter_name="wallet"
                    handleTransactionFilters={handleTransactionFilters}
                    statusList={statusList}
                    setStatusList={setStatusList}
                    selectedStatusList={selectedStatusList}
                    setSelectedStatusList={setSelectedStatusList}
                    blockchainList={blockchainList}
                    setBlockchainList={setBlockchainList}
                    selectedBlockchainList ={selectedBlockchainList}
                    SetSelectedBlockchainList={SetSelectedBlockchainList}
                    networkList={networkList}
                    setNetworkList={setNetworkList}
                    statusGroupList={[]}
                    selectedStatusGroupList={[]}
                    selectedNetworkList ={selectedNetworkList}
                    setSelectedNetworkList={setSelectedNetworkList}
                    fromDate={moment(fromDate)}
                    toDate={moment(toDate)}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    handleSave={handleSave}
                    setDateSelected={setDateSelected}
                    dateSelected={dateSelected}
                    resetFilters={resetFilters}
                    cryptos={cryptos}
                    selectedCryptos={selectedCryptos}
                    setSelectedCryptos={setSelectedCryptos}
                  />
                </Dialog>
              </div>
            </div>
            <div
              className="filterBtn"
              data-e2e="filterBtn"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setDownloadCsv(true);
              }}
            >
              <img
                data-e2e="downloadIcon"
                src={`${image_base_url}${downloadIcon}`}
                alt="filterIcon"
                className="downloadBtnIcon"
              />
            </div>
            <Dialog
              open={downloadCsv}
              onClose={handleCloseDownloadCsv}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DownloadCsv
                handleCloseDownloadCsv={handleCloseDownloadCsv}
                data={data}
                resetFilters={resetFilters}
                name="wallets"
                data-e2e="wallets"
              />
            </Dialog>
          </div>
          <div>
            <UserWalletTable
              table_name="Induvisual_transactions"
              data={data}
              sortBy={sortBy}
              orderBy={orderBy}
              getB2CUserWallets={()=> { 
                getB2CUserWallets(1)
              }}
              changeOrder={(sort) => {
                if (sortBy !== sort) {
                  setOrderBy('desc');
                  setSortBy(sort);
                } else {
                  setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
                }
              }}
              handleShowMore={() => {
                let newPage = page + 1;
                setPage(newPage);
                getB2CUserWallets(newPage);
              }}
              showMoreButton={totalCount && data && data.length < totalCount}
            />
          </div>
        </div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
}

export default UserWalletsList;
