import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const AddCardAquirerService = ({
  handleClose,
  handleChange,
  handleAddNewFraudService,
  fraudServiceName,
  errorField,
  selectedPaymentProvider,
  errorMessage,
  setSelectedPaymentProvider,
  paymentProviderList,
}) => {
  return (
    <div>
      <div className="myAccoutDetailsMain">
        <div className="myAccoutDetailsCard">
          <div className="d-flex justify-content-between">
            <h5 className="myAccoutOptModalHeading card-title text-center">
              Add New{" "}
            </h5>
            <div onClick={handleClose}>
              <ArrowBackIcon size={24} />
            </div>
          </div>
          <form>
            <div className="form-group myAccoutOptModal_form_group">
              <label
                className="myAccoutOptModalLable"
                htmlFor="exampleFormControlSelect1"
              >
                Payment Provider Name
              </label>
              <Select
                displayEmpty
                labelId="demo-simple-select-helper-label"
                className="from-control inputdropdown addRoutingServiceCofig"
                value={selectedPaymentProvider}
                id="exampleFormControlSelect1"
                name="transactionType"
                onChange={(e) => setSelectedPaymentProvider(e.target.value)}
                data-e2e="Select transaction type"
              >
                {selectedPaymentProvider === "" && (
                  <MenuItem value="" disabled>
                    Select Payment Provider
                  </MenuItem>
                )}
                {paymentProviderList?.map((item, index) => (
                  <MenuItem key={index} value={item?._id} data-e2e={item?._id}>
                    {item?.firstName} {item?.lastName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="form-group myAccoutOptModal_form_group">
              <label
                className="myAccoutOptModalLable"
                htmlFor="exampleFormControlSelect3"
              >
                Service Name
              </label>
              <input
                type="text"
                value={fraudServiceName}
                className="form-control inputdropdown"
                id="exampleFormControlSelect3"
                placeholder="Fraud Service Name"
                name="fraudServiceName"
                onChange={(e) => {
                  e.preventDefault();
                  handleChange(e);
                }}
              />
            </div>
            {errorField !== null && errorMessage !== null ? (
              <div className="pt-8 text-red-700">
                {"Error :  " + errorMessage}
              </div>
            ) : null}
            <div className="d-flex justify-content-center">
              <div
                className={`submit ${errorMessage !== null ? "inactive" : ""}`}
                onClick={() => {
                  handleAddNewFraudService();
                }}
              >
                Add
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCardAquirerService;
