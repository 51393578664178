import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import Dialog from "@mui/material/Dialog";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { call, logoutAfterError } from "../../../../config/axios";
import { GET_ALL_PAYMENT_PROVIDERS, GET_MERCHANTS } from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import DownloadCsv from "../CommonPages/DownloadCsv";
import UploadCSV from "../CommonPages/UploadCSV";
import GlobalSetting from "../GlobalSettings/GlobalSetting";
import MerchantFilter from "./MerchantFilter";
import MerchantTable from "./MerchantTable";
import WhiteLabel from "./WhiteLabel";
import CreateMerchant from "../Merchants/CreateMerchant";
import useDebounce from "../../global/useDebounce";
const searchicon = "/cryptonpay/crytoicons/searchicon1.png";
const filterIcon = "/cryptonpay/filter_icon.svg";
const downloadIcon = "/cryptonpay/download_icon.svg";

function Merchants() {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal("email");
  const base_url = useSelector((state) => state.config.api_url);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearhText] = useState("");
  const [orderBy, setOrderBy] = useState("asc");
  const [sortBy, setSortBy] = useState("name");
  const [fromDate, setFromDate] = useState(
    moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [toDate, setToDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [dateSelected, setDateSelected] = useState(false);

  const [editGlobalSetting, setEditGlobalSetting] = useState(false);
  const [editData, setEditData] = useState(null);
  const [globalSetting, setGlobalSetting] = useState(false);
  const [merchantFilters, setMerchantFilters] = useState(false);
  const [downloadCsv, setDownloadCsv] = useState(false);
  const [whiteLabel, setWhiteLabel] = useState(false);
  const [addNewMerchant, setAddNewMerchant] = useState(null);
  const [statusList, setStatusList] = useState(["Active", "Inactive"]);
  const [selectedStatusList, setSelectedStatusList] = useState([
    "Active",
    "Inactive",
  ]);

  const [save, setSave] = useState(false);
  const [showUploadCsvModal, setUploadCsvModal] = useState(false);

  const handleEditGlobalSettings = (data) => {
    setEditGlobalSetting(true);
    setEditData(data);
    setGlobalSetting(false);
  };

  const handleCloseGlobalSetting = () => {
    setGlobalSetting(false);
  };

  const handleMerchantFilters = () => {
    setMerchantFilters(false);
  };

  const handleCloseDownloadCsv = () => {
    setDownloadCsv(false);
    getMerchants("all");
  };

  const handleSave = () => {
    setSave(!save);
  };

  const handleUploadModal = () => {
    setUploadCsvModal(true);
  };

  const handleAddMerchantModal = () => {
    setAddNewMerchant(true);
  };

  const handleWhiteLabel = () => {
    setWhiteLabel(true);
  };

  const handleCloseWhiteLabel = () => {
    setWhiteLabel(false);
  };
  const name = "All Merchants";

  const buttons = [
    {
      name: "White Label",
      onClick: handleWhiteLabel,
      icon: <LabelImportantIcon />,
    },
    {
      name: "Merchant Vip Link",
      onClick: handleUploadModal,
      icon: <AddCircleOutlineIcon />,
    }
  ];
  const adminRole = localStorage.getItem("adminRole");
  const providerIdForMerchant = localStorage.getItem("paymentProviderId");
  providerIdForMerchant ? buttons.push(
    {
      name: "Add New Merchant",
      onClick: handleAddMerchantModal,
      icon: <AddCircleOutlineIcon />,
    }): buttons;
    
  const getMerchants = async (page) => {
    let pageNo = 1;
    let allMode = false;

    if (page) {
      pageNo = page;
    }
    let filterParams = {
      merchantId: searchText,
      paymentProvider: localStorage.getItem("paymentProviderId"),
      page: pageNo,
      sortBy: sortBy,
      orderBy: orderBy,

      status:
        statusList.length === selectedStatusList.length
          ? "All"
          : selectedStatusList.includes("Active")
            ? true
            : false,
      ...(adminRole == "SuperAdmin" && {
        providers:
          selectedPaymentProvider.length === 0 ||
            selectedPaymentProvider.length === paymentProviderList.length
            ? 'All'
            : selectedPaymentProvider
      })
    };

    if (dateSelected) {
      filterParams = {
        ...filterParams,
        fromDate: fromDate,
        toDate: toDate,
      };
    }
    if (allMode) {
      delete filterParams.page;
      filterParams = {
        ...filterParams,
        findAll: true,
      };
    }

    let payload = {
      email: email,
      filterParams: filterParams,
    };

    setLoading(true);
    await call(
      {
        ...GET_MERCHANTS,
        url: base_url + GET_MERCHANTS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          if (pageNo === 1) {
            setData(res.data.merchants);
            setTotalCount(res.data.totalCount);
          } else {
            let list = data.concat(res.data.merchants);
            setData(list);
            setTotalCount(res.data.totalCount);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const resetFilters = () => {
    setSearhText("");
    setOrderBy("asc");
    setSortBy("name");
    setPage(1);
    setDateSelected(false);
    setTotalCount(0);
    setSelectedStatusList(["Active", "Inactive"]);
    setFromDate([
      moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    ]);
    setToDate([moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")]);
  };
  const debouncedSearchText = useDebounce(searchText);
  useEffect(() => {
    getMerchants()
  }, [debouncedSearchText, orderBy, sortBy, save]);


  useEffect(() => {
    if (adminRole == "SuperAdmin") {
      getProviders();
    }
  }, []);

  const [paymentProviderList, setPaymentProviderList] = useState([]);
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState([]);
  const getProviders = async () => {
    let payload = {
      email,
      firstName: searchText,
    };
    setLoading(true);
    await call(
      {
        ...GET_ALL_PAYMENT_PROVIDERS,
        url: base_url + GET_ALL_PAYMENT_PROVIDERS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setPaymentProviderList(res.data.providerList);
          if (adminRole == "SuperAdmin" && localStorage.getItem("paymentProviderId")) {
            setSelectedPaymentProvider([localStorage.getItem("paymentProviderId")])
          } else {
            let providerId = [];
            if (res.data.providerList.length > 0) {
              providerId = res.data.providerList.map(e => e._id);
            }
            setSelectedPaymentProvider(providerId);
          }

        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };
  return (
    <div className="pageContainer merchant_page">
      <HeaderAndFooterLayout>
        <UploadCSV
          showModal={showUploadCsvModal}
          onCloseClickHandler={() => setUploadCsvModal(false)}
        />
        <Dialog
          open={globalSetting}
          onClose={handleCloseGlobalSetting}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <GlobalSetting
            handleCloseGlobalSetting={handleCloseGlobalSetting}
            handleEditGlobalSettings={handleEditGlobalSettings}
          />
        </Dialog>
        <Dialog
          open={whiteLabel}
          onClose={handleCloseWhiteLabel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <WhiteLabel handleClose={handleCloseWhiteLabel} />
        </Dialog>
        <Dialog
          open={addNewMerchant ? true : false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CreateMerchant
            paymentProviderId={providerIdForMerchant}
            handleCloseAddNew={() => {
              setAddNewMerchant(null)
            }}
             setAlertMsg="Merchant added successfully"/>
        </Dialog>

				<div className="">
					<div className="pageHeader__blockMobile">
	       		<CommonHeader name={name} buttons={buttons} />
					</div>
					<div className="transactionFilter__allMerchants">
						<div className="transactionFilter">
							<input
								type="text"
								className="transactionFilter__input"
								value={searchText}
								name="searchText"
								id="searchText"
								data-e2e="search-textbox"
								onChange={(e) => {
									setSearhText(e.target.value);
									setPage(1);
								}}
								placeholder="Search Merchant or Merchant ID…"
							/>
							<img
								src={`${image_base_url}${searchicon}`}
								alt="searchicon"
								className="transactionFilter__icon"
								data-e2e="searchIcon"
								onClick={() => {
									getMerchants();
								}}
							/>
						</div>
						<button type="button" className="transactionFilter__btn" onClick={() => {setMerchantFilters(true);}}>
							<img data-e2e="filterIcon" src={`${image_base_url}${filterIcon}`} alt="filterIcon" />
						</button>
            <button
              type="button"
              className={`transactionFilter__btn`}
              onClick={() => {
                    setDownloadCsv(true);
                  }
              }
            >
              <img
                data-e2e="downloadIcon"
                src={`${image_base_url}${downloadIcon}`}
                alt="filterIcon"
              />
            </button>
					</div>
					<div>
						<MerchantTable
							data={data}
							sortBy={sortBy}
							orderBy={orderBy}
							changeOrder={(sort) => {
								if (sortBy !== sort) {
									setOrderBy("desc");
									setSortBy(sort);
								} else {
									setOrderBy(orderBy === "desc" ? "asc" : "desc");
								}
							}}
							handleShowMore={() => {
								let newPage = page + 1;
								setPage(newPage);
								getMerchants(newPage);
							}}
							showMoreButton={totalCount && data && data.length < totalCount}
						/>
					</div>

					<Dialog
						open={merchantFilters}
						onClose={handleMerchantFilters}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
            scroll="body"
					>
						<MerchantFilter
							handleMerchantFilters={handleMerchantFilters}
							statusList={statusList}
							setStatusList={setStatusList}
							selectedStatusList={selectedStatusList}
							setSelectedStatusList={setSelectedStatusList}
							fromDate={moment(fromDate)}
							toDate={moment(toDate)}
							setFromDate={setFromDate}
							setToDate={setToDate}
							handleSave={handleSave}
							setDateSelected={setDateSelected}
							dateSelected={dateSelected}
							resetFilters={resetFilters}
							paymentProviderList={paymentProviderList}
							setPaymentProviderList={setPaymentProviderList}
							selectedPaymentProvider={selectedPaymentProvider}
							setSelectedPaymentProvider={setSelectedPaymentProvider}
							adminRole={adminRole}
						/>
					</Dialog>

					<Dialog
						open={downloadCsv}
						onClose={handleCloseDownloadCsv}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DownloadCsv
							handleCloseDownloadCsv={handleCloseDownloadCsv}
							email={email}
							base_url={base_url}
							resetFilters={resetFilters}
							name="merchants"
							data-e2e="merchants"
						/>
					</Dialog>
				</div>

				

      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
}

export default Merchants;
