import React from "react";
import { Table } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { SortByIcon } from "../../../common/CommonUtils";
const blockchainExternalLink = "/cryptonpay/blockchain_external_link.png";
const Units = require("cryptocurrency-unit-convert");

const HouseKeepingWalletBalanceTable = (props) => {
  return (
    <div className="TransactionTableMain">
      {props && props.data && props.data.length > 0 ? (
        <Table responsive style={{ border: "none" }}>
          <tbody className="TransactionTable_BODY merchant_table_text_align">
            <tr className="TransactionTable_BODY_Header">
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("blockchain");
                }}
                data-e2e="blockchain"
              >
                Blockchain{" "}
                {props.sortBy === "blockchain" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="blockchain" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("crypto");
                }}
                data-e2e="crypto"
              >
                Symbol{" "}
                {props.sortBy === "crypto" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="crypto" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("network");
                }}
                data-e2e="network"
              >
                NETWORK{" "}
                {props.sortBy === "network" && (
                  <SortByIcon data-e2e="network" orderBy={props.orderBy} />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("walletAddress");
                }}
                data-e2e="walletAddress"
              >
                Wallet Address{" "}
                {props.sortBy === "walletAddress" && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="walletAddress"
                  />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("walletBalance");
                }}
                data-e2e="walletBalance"
              >
                Balance{" "}
                {props.sortBy === "walletBalance" && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="walletBalance"
                  />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                onClick={() => {
                  props.changeOrder("blockchainBalance");
                }}
                data-e2e="blockchainBalance"
              >
                Blockchain Balance{" "}
                {props.sortBy === "blockchainBalance" && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="blockchainBalance"
                  />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                data-e2e="network"
              >
                +/- Difference{" "}
              </th>
              <th className="TransactionTable_heading" data-e2e="toWallet">
                View on Chain{" "}
              </th>
              <th className="TransactionTable_heading" data-e2e="Status">
                Status{" "}
                {props.sortBy === "Status" && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="Status" />
                )}
              </th>
            </tr>
            {props.data.map((TData, i) => {
              return <SingleTransactionTableRow data={TData} key={i} />;
            })}
          </tbody>
        </Table>
      ) : (
        <div className="No_Transactions_Found" data-e2e="No_Transactions_Found">
          No Data Found
        </div>
      )}
      {props.showMoreButton ? (
        <div className="Show_more_wrap">
          <div
            className="Show_more"
            data-e2e="showMore"
            onClick={() => {
              props.handleShowMore();
            }}
          >
            Show More
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HouseKeepingWalletBalanceTable;

const SingleTransactionTableRow = (props) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const BTC_MAINNET = useSelector((state) => state.config.btc_mainnet);
  const BTC_TESTNET = useSelector((state) => state.config.btc_testnet);
  const ETH_MAINNET = useSelector((state) => state.config.eth_mainnet);
  const ETH_SEPOLIA = useSelector((state) => state.config.eth_sepolia);
  const TRX_MAINNET = useSelector((state) => state.config.trx_mainnet);
  const TRX_TESTNET = useSelector((state) => state.config.trx_testnet);

  const getCryptoLink = (network, cryptoId) => {
    let link = network + "-" + cryptoId;
    switch (link) {
      case "mainnet-BTC":
        return BTC_MAINNET;
      case "testnet-BTC":
        return BTC_TESTNET;
      case "mainnet-ETH":
        return ETH_MAINNET;
      case "goerli-ETH":
      case "testnet-ETH":
      case "testnet-DAI":
      case "testnet-USDC":
      case "goerli-USDC":
      case "testnet-USDT":
        return ETH_SEPOLIA;
      case "mainnet-TRX":
      case "mainnet-TRC20-USDT":
          return TRX_MAINNET;
      case "testnet-TRX":
      case "testnet-TRC20-USDT":
        return TRX_TESTNET;
      default:
        return "";
    }
  };
  let { data } = props;
  let balanceDiff = 0
  if(data && data.blockchainBalance || data.walletBalance){
    balanceDiff = data.walletBalance - data.blockchainBalance;
  }
  const getStatusComponent = (status) => {
    switch (status) {
      case "Unmatched":
        return (
          <div className="Mstatus_inactive" style={{ width: "120px" }}>
            Unmatched
          </div>
        );
      case "Matched":
        return (
          <div className="Mstatus_active" style={{ width: "120px" }}>
            Matched
          </div>
        );
      default:
        return (
          <div className="Mstatus_inactive" style={{ width: "120px" }}>
            Unmatched
          </div>
        );
    }
  };

  const convertedETH = (amount) => {
    return Units.convertETH(amount, "wei", "eth");
  };

  return (
    <tr className="CP_TableRow housekeeping-table-row">
      <td className="transactionsTable_item">
        {(data && data.blockchain && data.blockchain) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {(data && data.crypto && data.crypto) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {(data && data.network && data.network) || "N/A"}
      </td>
      <td
        className="transactionsTable_item wallet_address_link"
        data-e2e="wallet_address"
      >
        <Tooltip
          title={data && data.walletAddress && data.walletAddress}
          placement="top"
        >
          <Link
            to={`/wallets/${data && data.walletAddress && data.walletAddress}`}
          >
            {data && data.walletAddress && data.walletAddress
              ? data.walletAddress.substring(0, 5) +
                "....." +
                data.walletAddress.substring(
                  data.walletAddress.length - 6,
                  data.walletAddress.length
                )
              : "N/A"}
          </Link>
        </Tooltip>
      </td>
      <td
        className="transactionsTable_item wallet_address_link"
        data-e2e="transaction"
      >
        {(data && data.walletBalance && parseFloat(data.walletBalance).toFixed(8)) || "N/A"}
      </td>
      <td
        className="transactionsTable_item wallet_address_link"
        data-e2e="transaction"
      >
        {(data && data.blockchainBalance && parseFloat(data.blockchainBalance).toFixed(8)) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {(balanceDiff &&parseFloat(balanceDiff).toFixed(8)) || "N/A"}
      </td>
      <td className="transactionsTable_item" data-e2e={"view-on-blockchain"}>
        <a
          href={`${getCryptoLink(
            data && data.network && data.network,
            data && data.crypto && data.crypto
          )}/${data && data.walletAddress && data.walletAddress}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="view-link-housekeeping">
            Open{" "}
            <img
              className="view-on-blockchain-btn-link housekeeping-blockchain-link"
              src={`${image_base_url}${blockchainExternalLink}`}
              alt={"external-link"}
              data-e2e={"external-link"}
              width="15px"
              height="15px"
            />
          </div>
        </a>
      </td>
      <td className="transactionsTable_item">
        {data && data.status && data.status
          ? getStatusComponent(data.status)
          : "N/A"}
      </td>
    </tr>
  );
};
