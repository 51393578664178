import React, { useState } from "react"
import Dialog from "@mui/material/Dialog";
const closeImage = '/cryptonpay/crytoicons/close.png';

export const AddModal = (props) => {
    const [sixDigits, setSixDigits] = useState("")
    const [comment, setComment] = useState("")
    return (<Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
        disableEnforceFocus={true}
        open={props?.openAddModal}
        onClose={props?.onClose}
    >
        <div className="filter_model_container">
            <div className="filter_model mb-10">
                <div className="filter_model_heading_wrap ">
                    <div className="filter_heading mx-10 ">
                        Add BIN To Blocked List
                    </div>
                </div>
                <div className="filter_close_button" onClick={props?.onClose}>
                    <img
                        src={`${props?.imageBaseUrl}${closeImage}`}
                        alt="closeImage"
                        data-e2e="close-icon"
                    />
                </div>
            </div>
            <div className="description descriptionLeft pb-3"><div>BIN Number:</div><input type="text" className="form-control editCommentTextArea" placeholder="First 6-Digits" value={sixDigits} onChange={(e) => {
                e.preventDefault()
                setSixDigits((e?.target?.value.replace(/\s/g, '')).replace(/\D[^\.]/g, "").substring(0, 6))
            }} /></div>
            <div className="description descriptionLeft pb-5"><div>Comment:</div><textarea className="form-control editCommentTextArea" id="exampleFormControlTextarea3" rows="4"
                type="textarea" value={comment} onChange={(e) => {
                    e.preventDefault()
                    setComment(e?.target?.value)
                }}
            /></div>
            <button disabled={sixDigits.length === 6 && comment.length > 3 ? false : true} className="FilterModalButton " onClick={() => props?.addBinHandle({ sixDigits: sixDigits, comment: comment })}>
                Block BIN
            </button>
        </div>
    </Dialog>)
}

export const EditModal = (props) => {
    const [comment, setComment] = useState(props?.comment)
    return (<Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
        disableEnforceFocus={true}
        open={props?.openEditModal}
        onClose={props?.onClose}
    >
        <div className="filter_model_container">
            <div className="filter_model mb-10">
                <div className="filter_model_heading_wrap ">
                    <div className="filter_heading mx-10 ">
                        Update Comment For BIN
                    </div>
                </div>
                <div className="filter_close_button" onClick={props?.onClose}>
                    <img
                        src={`${props?.imageBaseUrl}${closeImage}`}
                        alt="closeImage"
                        data-e2e="close-icon"
                    />
                </div>
            </div>
            <div className="description descriptionLeft pb-3">BIN Number: {props?.sixDigits}</div>
            <div className="description descriptionLeft pb-5">comment:{" "}<textarea className="form-control editCommentTextArea" id="exampleFormControlTextarea3" rows="4"

                type="textarea" value={comment} onChange={(e) => {
                    e.preventDefault()
                    setComment(e.target.value)
                }}
            /></div>
            <button className="FilterModalButton " onClick={() => props?.editBinHandle({ sixDigits: props?.sixDigits, comment: comment })}>
                Update BIN Comment
            </button>
        </div>
    </Dialog>)
}

export const DeleteModal = (props) => {
    return (<Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
        disableEnforceFocus={true}
        open={props?.openDeleteModal}
        onClose={props?.onClose}
    >
        <div className="filter_model_container">
            <div className="filter_model mb-10">
                <div className="filter_model_heading_wrap ">
                    <div className="filter_heading mx-10 ">
                        Are you sure you want to delete this BIN from blocked list?
                    </div>
                </div>
                <div className="filter_close_button" onClick={props?.onClose}>
                    <img
                        src={`${props?.imageBaseUrl}${closeImage}`}
                        alt="closeImage"
                        data-e2e="close-icon"
                    />
                </div>
            </div>
            <div className="description pb-5">BIN Number: {props?.sixDigits}</div>
            <button className="FilterModalDelete " onClick={() => props?.deleteBinHandle({ sixDigits: props?.sixDigits })}>
                Yes
            </button>
        </div>
    </Dialog>)
}

export const MessageModal = (props) => {
    return (<Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
        disableEnforceFocus={true}
        open={props?.openMessageModal}
        onClose={props?.onClose}
    >
        <div className="filter_model_container">
            <div className="filter_model mb-10">
                <div className="filter_model_heading_wrap ">
                    <div className="filter_heading mx-10 ">
                        {props?.message}
                    </div>
                </div>
                <div className="filter_close_button" onClick={props?.onClose}>
                    <img
                        src={`${props?.imageBaseUrl}${closeImage}`}
                        alt="closeImage"
                        data-e2e="close-icon"
                    />
                </div>
            </div>
            <button className="FilterModalButton" onClick={() => {
                props?.onClose()
            }}>
                Ok
            </button>
        </div>
    </Dialog>)
}
