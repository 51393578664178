import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loading from "../../../../../common/Loading";
import localDb from "../../../../../../localDb";
import { useSelector } from "react-redux";
import { GET_SUCCESS_ROUTING_BY_ACQUIRER } from "../../../../../../config/endpoints";
import TabButton from "../../../../../common/TabButton";
import { call, logoutAfterError } from "../../../../../../config/axios";
const searchicon = "/cryptonpay/crytoicons/searchicon1.png";
import Pagination from '@mui/material/Pagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SuccessRoutingTab = ({ acquireService }) => {
  const [tabs, setTabs] = useState(0);
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const baseURL = useSelector((state) => state.config.api_url);
  const adminRole = localStorage.getItem("adminRole");
  const emailFromLocal = localDb.getVal("email");
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const limit = 10;

  useEffect(() => {
    getSuccessRoutingByAcquirer((page - 1) * limit);
  }, [tabs]);

  useEffect(() => {
      const timeoutId = setTimeout(() => {
        getSuccessRoutingByAcquirer((page - 1) * limit);
      }, 1500);
      return () => clearTimeout(timeoutId);
  }, [searchedText]);

  const getSuccessRoutingByAcquirer = async (offsetFromParams) => {
    try {
      setLoading(true);
      const payload = {
        email: emailFromLocal,
        adminRole: adminRole,
        search: searchedText,
        limit,
        offset: offsetFromParams,
        acquirerName:
          acquireService &&
          acquireService.routingService &&
          acquireService.routingService.length > 0 &&
          acquireService.routingService[tabs] &&
          acquireService.routingService[tabs].acquirerName,
        routingId: acquireService && acquireService.uniqueId,
      };
      const response = await call(
        {
          ...GET_SUCCESS_ROUTING_BY_ACQUIRER,
          url: baseURL + GET_SUCCESS_ROUTING_BY_ACQUIRER.url,
        },
        payload,
      );

      if (response.status === 200) {
        setValues(response.data.successRoutingResult || []);
        setTotal(response.data.totalCount || 0)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleTabSwitch = (index) => {
    setTabs(index);
    setSearchedText("");
    setPage(1);
    setTotal(0);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getSuccessRoutingByAcquirer((value - 1) * limit);
  };

  const totalPages = Math.ceil(total / limit);

  return (
    <div className="TabContainer">
      {loading && <Loading />}
      {acquireService ? (
        <div>
          <div className="acquireServiceScroll">
            {acquireService.routingService.map((tab, index) => (
              <TabButton
                key={index}
                activeTab={tabs}
                handleTabSwitch={handleTabSwitch}
                tab={tab}
                index={index}
              />
            ))}
          </div>

          {acquireService.routingService.map((items, index) => {
            return (
              <div key={index}>
                {tabs === index && (
                  <div className="pt-3">
                    <div className="transactionFilter">
                      <input
                        type="text"
                        className="transactionFilter__input"
                        value={searchedText}
                        name="searchText"
                        id="searchText"
                        data-e2e="search-textbox"
                        onChange={(e) => {
                          setSearchedText(e.target.value);
                          // setPage(1);
                        }}
                        placeholder="Search BIN & Billing Country, Card Type, Transaction Range or Masked PAN"
                      />
                      <img
                        src={`${image_base_url}${searchicon}`}
                        alt="searchicon"
                        className="transactionFilter__icon"
                        data-e2e="searchIcon"
                        onClick={() => {
                          // getMerchants();
                        }}
                      />
                    </div>
                    <TableContainer component={Paper} className="mt-3">
                      <Table
                        sx={{ width: "fit-content" }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">
                              BIN Country
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Billing Country
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Card type
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Transaction Range
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              IIN Number
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Transaction Count
                            </StyledTableCell>
                            <StyledTableCell align="left">Rate</StyledTableCell>
                            <StyledTableCell align="left">
                              Status
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values &&
                            values.status &&
                            values.status.length > 0 &&
                            values.status.map((row, index) => (
                              <StyledTableRow key={index}>
                                <StyledTableCell align="left">
                                  {row.binCountry || "NA"}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.billingCountry || "NA"}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.cardType || "NA"}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.transactionRange || "NA"}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.maskedPan || "NA"}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.transactionCount || "NA"}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.rate || 0} %
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.status || "NA"}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                      {values?.length == 0 && (
                        <div className="flex items-center justify-center">
                          <p className="text-xl font-medium">No Data</p>
                        </div>
                      )}
                    </TableContainer>
                    <div className="flex justify-center items-center mt-4">
                    <Pagination count={totalPages} variant="outlined" shape="rounded" onChange={handlePageChange}/>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="w-full text-center">No Data</div>
      )}
    </div>
  );
};

export default SuccessRoutingTab;
