import React, { useState } from 'react'
import { Table } from 'reactstrap';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { SortByIcon } from '../../../common/CommonUtils';

const DepositHashTable = (props) => {
    const [loading, setLoading] = useState(false);
    return (
        <div className="TransactionTableMain">
            {props && props.data && props.data.length > 0 ? (
                <Table responsive style={{ border: 'none' }}>
                    <tbody className="TransactionTable_BODY">
                        <tr className="TransactionTable_BODY_Header">
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                onClick={() => {
                                    props.changeOrder('crypto');
                                }}
                                data-e2e="crypto"
                            >
                                CRYPTO
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                onClick={() => {
                                    props.changeOrder('crypto');
                                }}
                                data-e2e="crypto"
                            >
                                METHOD
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                onClick={() => {
                                    props.changeOrder('crypto');
                                }}
                                data-e2e="crypto"
                            >
                                USERTYPE
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                onClick={() => {
                                    props.changeOrder('crypto');
                                }}
                                data-e2e="crypto"
                            >
                                TRANSACTION
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                onClick={() => {
                                    props.changeOrder('crypto');
                                }}
                                data-e2e="crypto"
                            >
                                DEPOSITHASH
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                onClick={() => {
                                    props.changeOrder('crypto');
                                }}
                                data-e2e="crypto"
                            >
                                TYPE
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                onClick={() => {
                                    props.changeOrder('crypto');
                                }}
                                data-e2e="crypto"
                            >
                                BLOCKCHAIN
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                onClick={() => {
                                    props.changeOrder('crypto');
                                }}
                                data-e2e="crypto"
                            >
                                CRYPTO AMOUNT
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                onClick={() => {
                                    props.changeOrder('crypto');
                                }}
                                data-e2e="crypto"
                            >
                                NETWORK
                            </th>
                            <th
                                className="TransactionTable_heading"
                                width="16%"
                                onClick={() => {
                                    props.changeOrder('createdAt');
                                }}
                                data-e2e="createDate"
                            >
                                CREATED DATE{' '}
                                {props.sortBy === 'creationDate' && (
                                    <SortByIcon orderBy={props.orderBy} />
                                )}
                            </th>
                        </tr>
                        {props.data.map((TData, i) => {
                            return <SingleTransactionTableRow data={TData} key={i} loading={loading} setLoading={setLoading} getSweepLogs={() => { props.getSweepLogs() }} />;
                        })}
                    </tbody>
                </Table>
            ) : (
                <div className="No_Transactions_Found">No Data Found</div>
            )}
            {props.showMoreButton ? (
                <div className="Show_more_wrap">
                    <div
                        className="Show_more"
                        onClick={() => {
                            props.handleShowMore();
                        }}
                        data-e2e="showMore"
                    >
                        Show More
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default DepositHashTable

const SingleTransactionTableRow = (props) => {
    let { data } = props;
    return (
        <tr className="CP_TableRow">
            <td className="transactionsTable_item">
                {data.crypto}
            </td>
            <td className="transactionsTable_item">
                {data.method ? data.method : "N/A"}
            </td>
            <td className="transactionsTable_item">
                {data.transactionUserType ? data.transactionUserType : "N/A"}
            </td>
            <td className="transactionsTable_item">
                {data.transactionUserType ? <div>
                    {data.transactionUserType && data.transactionUserType.includes("B2C User") ?
                        <Link to={"/b2c-user-transactions/" + data.transactionId}>
                            <Tooltip title={data.transactionId ? data.transactionId : "N/A"} placement="top" >
                                <div style={{ color: "#3763FE" }}>
                                    {data.transactionId
                                        ? data.transactionId.substring(0, 5) +
                                        "...." +
                                        data.transactionId.substring(
                                            data.transactionId.length - 6,
                                            data.transactionId.length
                                        )
                                        : "N/A"}
                                </div>
                            </Tooltip>
                        </Link>
                        :
                        <Link to={"/transaction/" + data.transactionId}>
                            <Tooltip title={data.transactionId ? data.transactionId : "N/A"} placement="top" >
                                <div style={{ color: "#3763FE" }}>
                                    {data.transactionId
                                        ? data.transactionId.substring(0, 5) +
                                        "...." +
                                        data.transactionId.substring(
                                            data.transactionId.length - 6,
                                            data.transactionId.length
                                        )
                                        : "N/A"}
                                </div>
                            </Tooltip>
                        </Link>
                    }
                </div> :
                    <Tooltip title={data.transactionId ? data.transactionId : "N/A"} placement="top" >
                        <div style={{ color: "#3763FE" }}>
                            {data.transactionId
                                ? data.transactionId.substring(0, 5) +
                                "...." +
                                data.transactionId.substring(
                                    data.transactionId.length - 6,
                                    data.transactionId.length
                                )
                                : "N/A"}
                        </div>
                    </Tooltip>
                }


            </td>
            <td className="transactionsTable_item">
                <Tooltip title={data.depositHash ? data.depositHash : "N/A"} placement="top">
                    <div>
                        {data.depositHash
                            ? data.depositHash.substring(0, 5) +
                            "...." +
                            data.depositHash.substring(
                                data.depositHash.length - 6,
                                data.depositHash.length
                            )
                            : "N/A"}
                    </div>
                </Tooltip>
            </td>
            <td className="transactionsTable_item">
                {data.type ? data.type : "N/A"}
            </td>
            <td className="transactionsTable_item">
                {data.blockchain}
            </td>
            <td className="transactionsTable_item">
                {data.crytoAmount ? data.crytoAmount : "N/A"}
            </td>
            <td className="transactionsTable_item">
                {data.network}
            </td>
            <td className="transactionsTable_item">
                {data.createdAt && data.createdAt
                    ? moment(data.createdAt).format('DD/MM/YYYY - HH:mm')
                    : 'N/A'}
            </td>
        </tr>
    );
};
