import React from 'react'
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import MerchantSendAndReceiveTable from "../Merchants/MerchantSendAndReceiveTable";
import CommonHeader from '../CommonPages/CommonHeader';

const merchantendReceiveFees = () => {
  return (
    <div className='fees'>
        <HeaderAndFooterLayout>
					<div className="">
						<CommonHeader name="Merchant Send & Receive Fees"  />
						<MerchantSendAndReceiveTable />
					</div>
        </HeaderAndFooterLayout>
    </div>
  )
}

export default merchantendReceiveFees