import React from 'react';
import localDb from './localDb';
import Login from './client/components/Pages/Login/Login';
class ProtectedRoute extends React.Component {
  render() {
    const token = localDb.getVal('token');
    const { component: Component, ...rest } = this.props;
    if (typeof window === 'undefined') {
      // return <HeaderAndFooterLayout />;
      return '';
    }
    // console.log('token && token.length > 0 : ', token && token.length > 0);
    return token && token.length > 0 ? <Component {...rest} /> : <Login />;
  }
}

export default ProtectedRoute;
