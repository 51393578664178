import React, { useState, useEffect } from "react";
import EditIcon from '@mui/icons-material/Edit';import { Table } from "reactstrap";
import { useSelector } from "react-redux";
import {
    GET_B2C_WALLET_BALANCES,
    UPDATE_B2C_USER_WALLET_BALANCE,
    GET_CRYPTO_AMOUNT_EXCHANGE_RATE
} from "../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../config/axios";
import localDb from "../../../../localDb";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { isViewPermissionValid } from "../../../common/CommonUtils";


const balanceHeader = [
    {
        name: "CURRENCY",
        width: "10%",
    },
    {
        name: "BALANCE",
        width: "10%",
    },
    {
        name: "EUR VALUE",
        width: "10%",
    },
    {
        name: "WALLET ADDRESS",
        width: "10%",
    },
    {
        name: "",
        width: "10%",
    }
];

const SingleUserBalance = ({ user_email, setLoading, setSuccesEditBalances, domainNameUrl }) => {
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const base_url = useSelector((state) => state.config.api_url);
    const email = localDb.getVal("email");
    const [userWalletBalances, setUserWalletBalances] = useState("");
    const [editCryptoBalance, setEditCryptoBalance] = useState(false);
    const [editWalletBalanceData, setEditWalletBalanceData] = useState([]);
    const [newBalanceInCrypto, setNewBalanceInCrypto] = useState([]);
    const [error, setError] = useState("");
    const BTC_MAINNET = useSelector((state) => state.config.btc_mainnet);
    const BTC_TESTNET = useSelector((state) => state.config.btc_testnet);
    const ETH_MAINNET = useSelector((state) => state.config.eth_mainnet);
    const ETH_SEPOLIA = useSelector((state) => state.config.eth_sepolia);

    useEffect(() => {
        getUserBalances();
    }, [])

    const getCryptoName = (symbol) => {
        switch (symbol) {
            case 'BTC':
                return 'Bitcoin';
            case 'ETH':
                return 'Ethereum';
            case 'USDT':
                return 'USDT';
            case 'DAI':
                return 'DAI';
            case 'USDC':
                return 'USDC';
            case 'LTC':
                return 'Litecoin';
            case 'XRP':
                return 'Ripple';
            case 'TRX':
                return 'Tron';
            case 'TRC20-USDT':
                return 'TRC20-USDT';
            default:
                return '';
        }
    };

    const getCryptoLink = (network, cryptoId) => {
        let link = network + "-" + cryptoId;
        switch (link) {
            case "mainnet-BTC":
                return BTC_MAINNET;
            case "testnet-BTC":
                return BTC_TESTNET;
            case "mainnet-ETH":
                return ETH_MAINNET;
            case "goerli-ETH":
            case "testnet-ETH":
            case "testnet-DAI":
            case "testnet-USDC":
            case "goerli-USDC":
            case "testnet-USDT":
                return ETH_SEPOLIA;
            default:
                return "";
        }
    };

    const handleCloseEditCryptoBalances = () => {
        setEditCryptoBalance(false);
    };


    const getUserBalances = async () => {
        let payload = {
            user_email: user_email,
            email: email,
            token: localStorage.getItem("token"),
            domainNameUrl,
        };
        setLoading(true);
        await call(
            {
                ...GET_B2C_WALLET_BALANCES,
                url: base_url + GET_B2C_WALLET_BALANCES.url,
            },
            payload
        )
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    setUserWalletBalances(res.data.balanceList);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.message, "err");
                logoutAfterError(err)
            });
    };

    const changeNewCryptoBalance = async (value) => {
        if (!isNaN(value)) {
            setError("");
            setNewBalanceInCrypto(value);
        }
    };
    const getExchangeRate = (fromCurrency, toCurrency, currencyAmount) =>
        new Promise(async (resolve, reject) => {
            await call(
                {
                    ...GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
                    url: base_url + GET_CRYPTO_AMOUNT_EXCHANGE_RATE.url,
                },
                {
                    email: email,
                    fromCurrency: fromCurrency,
                    toCurrency: toCurrency,
                    currencyAmount: currencyAmount,
                }
            )
                .then((res) => {
                    if (res.data) {
                        resolve(parseFloat(res.data).toFixed(8));
                    }
                })
                .catch((err) => {
                    setError("Something went wrong while fetching exchange values.");
                    console.log(err);
                    reject(null);
                    logoutAfterError(err)
                });
        });

    const saveNewBalanceValues = async (cryptoCurrency) => {
        setSuccesEditBalances(false);
        if (newBalanceInCrypto === "" || newBalanceInCrypto.length === 0) {
            setError("Please enter new balance");
            return;
        }
        setLoading(true);
        if (newBalanceInCrypto != "") {
            const exchangeValue = await getExchangeRate(
                cryptoCurrency,
                "USD",
                newBalanceInCrypto
            );             
            await call(
                {
                    ...UPDATE_B2C_USER_WALLET_BALANCE,
                    url: base_url + UPDATE_B2C_USER_WALLET_BALANCE.url,
                },
                {
                    email: email,
                    balanceAmount: editWalletBalanceData?.amount,
                    newBalanceAmountInCrypto: newBalanceInCrypto,
                    newBalanceAmountInUSD: exchangeValue,
                    balanceId: editWalletBalanceData?.id,
                    userId: window?.location?.pathname.split("/")[2],
                    coin: editWalletBalanceData?.coin,
                    cryptoName: editWalletBalanceData?.cryptoName,
                }
            )
                .then((res) => {
                    setLoading(false);
                    if (res.data) {
                        getUserBalances();
                        setNewBalanceInCrypto("");
                        setSuccesEditBalances(true);
                        setEditCryptoBalance(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setError("Something went wrong while updating the wallet balance.");
                    console.log(err);
                    logoutAfterError(err)
                });
        } else {
            setLoading(false);
            setError("Something went wrong while fetching exchange rate.");
        }
    };
    return (
        <div>
            <Dialog
                open={editCryptoBalance}
                onClose={handleCloseEditCryptoBalances}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="myAccoutDetailsMain">
                    <div
                        className="myAccoutDetailsCard"
                        style={{ maxWidth: "540px" }}
                    >
                        <div className="d-flex justify-content-between">
                            <h5
                                className="myAccoutOptModalHeading card-title text-center"
                                data-e2e="add-new-btn"
                            >
                                Edit Balance{" "}
                            </h5>
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={handleCloseEditCryptoBalances}
                            >
                                <CloseIcon size={24} />
                            </div>
                        </div>
                        <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                            <label
                                className="myAccoutOptModalLable"
                                htmlFor="exampleFormControlSelect1"
                            >
                                Current Balance
                            </label>
                            <input
                                type="text"
                                disabled
                                value={editWalletBalanceData.amount}
                                className="form-control inputdropdown"
                                id="balanceAmount"
                                placeholder="Current Balance Amount"
                                name="balanceAmount"
                            />
                        </div>
                        <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                            <label
                                className="myAccoutOptModalLable"
                                htmlFor="exampleFormControlSelect1"
                            >
                                New Balance
                            </label>
                            <input
                                type="text"
                                value={newBalanceInCrypto}
                                className="form-control inputdropdown"
                                id="newBalanceInCrypto"
                                placeholder="Enter New Balance"
                                name="newBalanceInCrypto"
                                onChange={(e) => {
                                    changeNewCryptoBalance(e.target.value);
                                }}
                            />
                        </div>
                        {error !== "" && <div className="change1h">{error}</div>}
                        <div
                            className="FilterModalSave"
                            onClick={() => {
                                saveNewBalanceValues(editWalletBalanceData.coin);
                            }}
                        >
                            Save
                        </div>
                    </div>
                </div>
            </Dialog>
            <Table responsive style={{ border: "none" }}>
                <tbody className="TransactionTable_BODY">
                    <tr>
                        {balanceHeader.map((item, index) => (
                            <th
                                className="GBtableHeading"
                                style={{ width: item.width }}
                                key={index}
                                data-e2e={item.name}
                            >
                                {item.name}
                            </th>
                        ))}
                    </tr>

                    {userWalletBalances.length > 0 ? (
                        userWalletBalances.map((data, i) => (
                            <tr key={i} className="user_transaction_row" >
                                <td className="user_transaction_items pl-0">
                                    <div className="d-flex align-items-center">
                                        <img src={`${image_base_url}${data.icon}`} alt={data.coin} width={20} height={20} />
                                        <div className="pl-2">{getCryptoName(data.coin)}</div>
                                    </div>
                                </td>
                                <td className="user_transaction_items pl-0">
                                    {data && data.amount ? parseFloat(data.amount).toFixed(8) : '0'}
                                </td>
                                <td className="user_transaction_items pl-0">
                                    {data && data.USDAmount ? parseFloat(data.USDAmount).toFixed(8) : '0'}
                                </td>
                                <td className={data.address ? "user_transaction_items text-primary text-decoration-underline pl-0" : "user_transaction_items pl-0"}>

                                    {data.address ? <a href={`${getCryptoLink(
                                        data && data.network && data.network,
                                        data &&
                                        data.coin &&
                                        data.coin
                                    )}/${data.address && data.address}`} target="_blank"
                                        rel="noopener noreferrer">
                                        {data.address.substr(0, 15) + "..." + data.address.substr(-4, data.address.length)}
                                    </a>
                                        : "N/A"}
                                </td>
                                <td className="user_transaction_items transactionsTable_item text-center pl-0">
                                    <span
                                        className={`pl-1 ${isViewPermissionValid ? 'disabled' : ''}`}
                                        onClick={isViewPermissionValid ? null: () => {
                                            setEditWalletBalanceData(data);
                                            setEditCryptoBalance(true);
                                        }}
                                    >
                                        <EditIcon
                                            fontSize="large"
                                            color="action"
                                            data-e2e="edit_BTC"
                                            className={`cursor-pointer text-primary ${isViewPermissionValid ? 'disabled' : ''}`}
                                            />
                                    </span>
                                </td>
                            </tr>

                        ))
                    ) : (
                        <div className="No_Transactions_Found">No Data Found</div>
                    )}
                </tbody>
            </Table>
        </div>
    );
}

export default SingleUserBalance