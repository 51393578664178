import React, { useEffect } from 'react'

const Notfound = () => {
    useEffect(() => {
        alert("under development...");
        window.location='/merchants';
    },[]);
  return (
    <div>
    </div>
  )
}

export default Notfound