import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const dateImage = '/cryptonpay/crytoicons/date.png';
const closeImage = '/cryptonpay/crytoicons/close.png';
const selectedCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_filled.png';
const emptyCheckBoxIcon = '/cryptonpay/crytoicons/checkbox_empty.png';
export const CPMultiSelect = ({ dataE2eLabel, items, selectedItems, ItemComponent, setShowDropDown,
    setSelectedItems, smallName, containerStyle = {}, handleDropdownClick }) => {
    const image_base_url = useSelector((state) => state.config.image_base_url);

    const [selectAll, setSelectAll] = useState(true)

    useEffect(() => {
        if (selectedItems.length === items.length) {
            setSelectAll(true)
        } else {
            setSelectAll(false)
        }
    }, [items, selectedItems])
    return (
        <div
            style={containerStyle}
            className="FilterModalCurrencyDropdownlist">
            <div
                className="FM_currencyDrop_All"
                onClick={() => {
                    handleDropdownClick(setShowDropDown, true)
                }}
            >
                <span
                    className="FM_currencyDrop_AllSelector"
                >
                    {selectedItems.length === items.length ||
                        selectedItems.length === 0
                        ? 'All'
                        : `${selectedItems.length} Selected`}
                </span>
                <span
                    className="FilterModalInputDropUpIconWrap"
                >
                    <i
                        className="fa fa-chevron-up FilterModalInputDropUpIcon" data-e2e={`${dataE2eLabel}contract-icon`}
                    />
                </span>
            </div>
            <div
                onClick={() => {

                    if (selectAll) {
                        setSelectedItems([])
                        setSelectAll(false)
                    }
                    else {
                        setSelectedItems(items)
                        setSelectAll(true)
                    }

                }}
                className="currencyListItemWrap"

            >
                <div className="currencyListIconNameWrap">

                    <div className={`currencyList_Name ${smallName}`}>
                        All
                    </div>
                </div>
                <img
                    data-e2e="checkBox"
                    src={`${image_base_url}${selectedItems.length === items.length
                        ? selectedCheckBoxIcon
                        : emptyCheckBoxIcon
                        }`}
                    alt="checkbox"
                    className="currencyListCheckBox"
                />
            </div>
            {items.map((item, index) => {
                return <ItemComponent
                    item={item}
                    key={index}
                />
            })}
        </div>
    )
}