import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import { call, logoutAfterError } from "../../../../config/axios";
import { GET_CRYPTOS } from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import CommonHeader from '../CommonPages/CommonHeader';

const SendReceiveFees = () => {
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getCryptos();
  }, []);

  const getCryptos = async () => {
    setLoading(true);

    await call(
      {
        ...GET_CRYPTOS,
        url: base_url + GET_CRYPTOS.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.cryptos);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  return (
    <div className='fees'>
        <HeaderAndFooterLayout>
        <CommonHeader name={`Send & receive fees`}  />

          <div  className='fees_table'>
            <FeesTable heading={'Bespoke'}  data ={data} />
          </div>

          <div  className='fees_table'>
            <FeesTable heading={'Super VIP'} data ={data}  />
          </div>

          <div  className='fees_table'>
            <FeesTable heading={'VIP'} data ={data}  />
          </div>

          <div  className='fees_table'>
            <FeesTable heading={'Standard'} data ={data}  />
          </div>
         
        </HeaderAndFooterLayout>
    </div>
  )
}

export default SendReceiveFees



const FeesTable = ({heading, data}) => {
  return (
      <div className='table_content'>
        <div className='table_heading mb-3'>{heading}{` %`}</div>
        <div className='table'>
          <div className='table_head mt-0'>
            <div className="title" >{`Currency`}</div>
            <div className='title' >{`Receive fee (units)`}</div>
            <div className='title' >{`Send fee (units)`}</div>
            <div className='title' >{`Withdrawal min amount (Units)`}</div>
          </div>
          {data && data.length >0 && data.map((ele, i) => {
            return <SingleRowData data ={ele} key= {i} />
          })}
        </div>
      </div>
  );
};

const SingleRowData = ({data, key}) =>{
  const image_base_url = useSelector((state) => state.config.image_base_url);
  return(
    <div className='single_Row' key={key}>
      <div className='table_data d-flex align-items-center'>
      <div>
        <img src={`${image_base_url}${data.icon}`} width={20} height={20} alt={data.name} className="" />
      </div> <div className="p-1">{data.name}</div></div>
      <div className='table_data text-center'>{`0`}</div>
      <div className='table_data text-center'>{`-----.020`}</div>
      <div className='table_data text-center'>{`00.020`}</div>
    </div>
  );
};