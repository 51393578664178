import React, { useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../../../common/Loading";
import LabelInput from "../../../../../common/LabelInput";

import { call } from "../../../../../../config/axios";
import localDb from "../../../../../../localDb";
import {
  ADD_INTEGRATION_TYPE,
  EDIT_INTEGRATION_TYPE,
} from "../../../../../../config/endpoints";
const closeImage = "/cryptonpay/crytoicons/close.png";

function AddOrEditIntegration({
  handleCloseAddNew,
  currentAcquirerName,
  setCurrentAcquirerName,
  editOpted,
  currentAcquirerId,
  setCurrentAcquirerId,
  getIntegrationNames,
}) {
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const baseUrl = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const [loading, setLoading] = useState(false);
  const [addAcquirer, setAddAcquirer] = useState(currentAcquirerName || "");
  const [errorMessage, setErrorMessage] = useState(false);

  const handleSaveDetails = async () => {
    setLoading(true);
    if (!addAcquirer) {
      setLoading(false);
      setErrorMessage(true);
      return;
    }
    try {
      const payload = {
        email: email,
        acquirerName: addAcquirer,
      };
      const res = await call(
        {
          ...ADD_INTEGRATION_TYPE,
          url: baseUrl + ADD_INTEGRATION_TYPE.url,
        },
        payload,
      );
      if (res?.status === 200) {
        handleCloseAddNew();
        setLoading(false);
        setCurrentAcquirerName("");
        setCurrentAcquirerId("");
        await getIntegrationNames();
      }
      handleCloseAddNew();
      setLoading(false);
      setCurrentAcquirerName("");
      setCurrentAcquirerId("");
    } catch (err) {
      console.error(err, " in handleSaveDetails");
      handleCloseAddNew();
      setLoading(false);
      setCurrentAcquirerName("");
      setCurrentAcquirerId("");
    }
  };

  const handleEditDetails = async () => {
    setLoading(true);
    if (!addAcquirer) {
      setLoading(false);
      setErrorMessage(true);
      return;
    }
    try {
      const payload = {
        email: email,
        acquirerId: currentAcquirerId,
        acquirerName: addAcquirer,
      };

      const res = await call(
        {
          ...EDIT_INTEGRATION_TYPE,
          url: baseUrl + EDIT_INTEGRATION_TYPE.url,
        },
        payload,
      );
      if (res.status === 200) {
        handleCloseAddNew();
        setLoading(false);
        setCurrentAcquirerName("");
        setCurrentAcquirerId("");
        await getIntegrationNames();
      }
      handleCloseAddNew();
      setLoading(false);
      setCurrentAcquirerName("");
      setCurrentAcquirerId("");
    } catch (err) {
      console.error(err, " in handleEditDetails");
      handleCloseAddNew();
      setLoading(false);
      setCurrentAcquirerName("");
      setCurrentAcquirerId("");
    }
  };

  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <div className="font-large font-weight-bold text-lg">{`${
          editOpted ? "Edit" : "Add"
        } State`}</div>
        <div
          className="filter_close_button"
          data-e2e="filter-close-button"
          onClick={() => {
            handleCloseAddNew();
          }}
        >
          <img
            src={`${imageBaseUrl}${closeImage}`}
            alt="closeImage"
            data-e2e="close-icon"
          />
        </div>
      </div>
      <LabelInput
        label="Integration Name"
        placeholder="Integration Type Name"
        value={editOpted ? addAcquirer : ""}
        setterValue={setAddAcquirer}
      />
      {errorMessage && (
        <div className="my-2 w-full text-left text-red-600">
          {"Please fill all details"}
        </div>
      )}
      <div className="d-flex justify-content-center pt-4">
        <button
          type="button"
          onClick={
            editOpted
              ? () => {
                  handleEditDetails();
                }
              : () => {
                  handleSaveDetails();
                }
          }
          className="btn btn-primary btn-md rounded-pill add-btn w-100"
        >
          {editOpted ? "Update" : "Add"}
        </button>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default AddOrEditIntegration;
