import React, { useState } from "react";
import { useSelector } from "react-redux";
import { call, logoutAfterError } from "../../../../config/axios";
import { CREATE_CLIENT } from "../../../../config/endpoints";
import Loading from "../../../common/Loading";
import CreateClientAccount from "./CreateClientAccount";
import localDb from "../../../../localDb";
import moment from "moment";

function CreateClient(props) {
  const { handleCloseAddNew, setAlertMsg, setUpdateSuccessAlert } = props;
  const base_url = useSelector((state) => state.config.api_url);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");


  const handleCreateAccount = async (data) => {
    const email = localDb.getVal("email");
    const payload = {
      paymentProvider: props.paymentProviderId,
      name: data.name,
      accountNumber: data.accountNumber,
      companyName: data.companyName,
      address: data.address,
      clientEmail: data.clientEmail,
      adminRole: data.adminRole,
      email: email,
      password: data.password,
      postCode: data.postCode,
      country: data.country,
      twoFA: data.twoFA,
      phoneNumber: data.phoneNumber,
      dob: data.dob && moment(data.dob).format(),
      active: data.active,
    };
    setLoading(true);
    await call(
      { ...CREATE_CLIENT, url: base_url + CREATE_CLIENT.url },
      payload
    )
      .then((res) => {
        setLoading(false);
        handleCloseAddNew()
        setAlertMsg("Client added successfully")
        setUpdateSuccessAlert(true)
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.err, "err");
        setError(err.response.data.err);
        logoutAfterError(err);
      });
  };

  const createClientFieldsData = [
    {
      input_type: "input_box",
      id: "account number",
      type: "text",
      name: "accountNumber",
      label: "Account Number",
      placeholder: "Enter account number",
    },
    {
      input_type: "input_box",
      id: "client name",
      type: "text",
      name: "name",
      label: "Name",
      placeholder: "Enter Name",
    },
    {
      input_type: "input_box",
      id: "company name",
      type: "text",
      name: "companyName",
      label: "Company Name",
      placeholder: "Enter Company Name",
    },
    {
      input_type: "input_box",
      id: "address",
      type: "text",
      name: "address",
      label: "Address",
      placeholder: "Enter Address",
      autocomplete: "on"
    },
    {
      input_type: "input_box",
      id: "email",
      type: "email",
      name: "clientEmail",
      label: "Email",
      placeholder: "Enter Email",
      autocomplete: "on"
    },
    {
      input_type: "input_box",
      id: "country",
      type: "text",
      name: "country",
      label: "Country",
      placeholder: "Enter country",
      autocomplete: "on"
    },
    {
      input_type: "input_box",
      id: "postCode",
      type: "text",
      name: "postCode",
      label: "Post Code",
      placeholder: "Enter Post Code",
      autocomplete: "on"
    },
    {
      input_type: "input_box",
      id: "create password",
      type: "password",
      name: "password",
      label: "Password",
      placeholder: "Create Password",
      autocomplete: "new-password"
    },
    {
      input_type: "input_box",
      id: "confirm password",
      type: "password",
      name: "confirm_password",
      label: "Confirm Password",
      placeholder: "Confirm Password",
      autocomplete: "new-password"
    },
    {
      input_type: "input_box",
      id: "dob",
      type: "date",
      name: "dob",
      label: "Date of Birth",
      placeholder: "Enter date of birth",
      autocomplete: "on"
  },
  {
      input_type: "input_box",
      id: "phoneNumber",
      type: "text",
      name: "phoneNumber",
      label: "Phone Number",
      placeholder: "Enter phone number",
      autocomplete: "on"
  },
    {
      input_type: "input_select",
      id: "twoFA",
      type: "text",
      name: "twoFA",
      label: "2FA",
      placeholder: "Two Factor Authenication",
      autocomplete: "on",
      options: [{ label: "Yes", value: "true" }, { label: "No", value: "false" }]
    },
    {
      input_type: "input_select",
      id: "active",
      type: "text",
      name: "active",
      label: "STATUS",
      placeholder: "Account Status",
      options: [{ label: "Active", value: "true" }, { label: "Inactive", value: "false" }]
    },
    {
      input_type: "input_button",
      id: "create_account_stage_3",
      type: "button",
      name: "Create Client",
      onClick: handleCreateAccount,
    },
  ];

  return (
    <div>
      <div>
        <CreateClientAccount
          feildsData={createClientFieldsData}
          handleCloseAddNew={handleCloseAddNew}
          error={error}
        />
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default CreateClient;
