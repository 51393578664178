import { combineReducers } from 'redux';
import commonReducer from './commonReducer';
import transactionReducer from './transactionReducer';
import walletReducer from './walletReducer';
import configReducer from './configReducer';

const rootReducer = combineReducers({
    config: configReducer,
    common: commonReducer,
    transaction: transactionReducer,
    wallet: walletReducer,
});

export default rootReducer;
