import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import localDb from '../../../../localDb';
import {
  GET_ALL_MERCHANTS_LIST,
  UPDATE_WHITELIST_IP,
} from '../../../../config/endpoints';
import { connect } from 'react-redux';
import { call, logoutAfterError } from '../../../../config/axios';
import SavingAlert from '../CommonPages/SavingAlert';
let timeout = null;
class EditWhiteListIP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: {
        ...this.props.editData,
        merchant: this.props.editData.merchant
          ? this.props.editData.merchant._id
          : 'Select',
      },
      merchants: [],
      showSavingModal: false,
      showStatusDropDown: false,
      showMerchantDropDown: false,
      merchantName: this.props.editData.merchant
        ? this.props.editData.merchant.name
        : 'Select',
      statusData: [
        {
          name: 'ENABLED',
          value: true,
        },
        {
          name: 'DISABLED',
          value: false,
        },
      ],
      loading: false,
    };
  }

  componentDidMount() {
    this.getAllMerchantsList();
  }

  componentWillUnmount() {
    if (timeout) {
      clearTimeout(timeout);
    }
  }

  getAllMerchantsList = async () => {
    this.setState({loading: true})
    let payload = {
      email: localDb.getVal('email'),
    };
    await call(
      {
        ...GET_ALL_MERCHANTS_LIST,
        url: this.props.base_url + GET_ALL_MERCHANTS_LIST.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({loading: false})
          this.setState({ merchants: res.data.merchants });
        }
      })
      .catch((err) => {
        this.setState({loading: false})
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };

  validate = () => {
    const { merchant, ipAddress } = this.state.editData;
    let ipFormat =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (!merchant || merchant === '' || merchant === 'Select') {
      this.setState({
        errorMsg: 'Merchant is required.',
        errorField: 'merchant',
      });
    } else {
      if (!ipAddress || ipAddress === '') {
        this.setState({
          errorMsg: 'IP Address is required.',
          errorField: 'ipAddress',
        });
      } else if (!ipFormat.test(ipAddress)) {
        this.setState({
          errorMsg: 'IP Address should be in a proper format.',
          errorField: 'ipAddress',
        });
      }
    }
  };

  updateWhiteListIP = () => {
    this.setState({ addClicked: true });
    const { _id, merchant, ipAddress, status } = this.state.editData;
    this.validate();
    timeout = setTimeout(async () => {
      if (this.state.errorField === '' && this.state.errorMsg === '') {
        let payload = {
          email: localDb.getVal('email'),
          merchantId: merchant,
          ipAddress,
          status,
          id: _id,
        };
        this.setState({loading: true})
        await call(
          {
            ...UPDATE_WHITELIST_IP,
            url: this.props.base_url + UPDATE_WHITELIST_IP.url,
          },
          payload
        )
          .then((res) => {
            if (res.status === 200) {
              this.setState({ showSavingModal: true , loading: false});
            }
          })
          .catch((err) => {
          this.setState({loading: false})
            console.log(err.message, 'err');
            alert('Failed to Add!');
            logoutAfterError(err)
          });
      }
    });
  };

  handleChange = (e) => {
    this.setState(
      {
        editData: {
          ...this.state.editData,
          [e.target.name]: e.target.value,
        },
        errorMsg: '',
        errorField: '',
      },
      () => {
        if (this.state.addClicked) {
          this.validate();
        }
      }
    );
  };

  render() {
    return (
      <div>
        <div className="myAccoutDetailsMain">
          <div className="myAccoutDetailsCard">
            <div className="d-flex justify-content-between">
              <h5 className="myAccoutOptModalHeading card-title text-center">
                Edit
              </h5>
              <div onClick={this.props.handleClose}>
                <ArrowBackIcon size={24} data-e2e="back_arrow"/>
              </div>
            </div>
            <form>
              <div
                className="FilterModalInputGroup"
                style={{ marginBottom: '20px' }}
              >
                <div className="FilterModalInputLabel">Merchant</div>
                <div
                  className="FilterModalInputDropdown"
                  data-e2e="merchant"
                  onClick={() => {
                    this.setState({
                      showStatusDropDown: false,
                      showMerchantDropDown: true,
                    });
                  }}
                >
                  <div
                    className="FilterModalInputText"
                    data-e2e="merchant"
                  >
                    {this.state.merchantName === 'Select'
                      ? 'Select Merchant'
                      : this.state.merchantName}
                  </div>
                  <i
                    className="fa fa-chevron-down FilterModalInputDropIcon"
                    data-e2e="FilterModalInputDropIcon"
                  />
                </div>
                {this.state.showMerchantDropDown && (
                  <div
                    className="FilterModalCurrencyDropdownlist"
                    data-e2e="FilterModalCurrencyDropdownlist"
                  >
                    <div
                      className="FM_currencyDrop_All"
                      data-e2e="merchant"
                      onClick={() => {
                        this.setState({
                          showMerchantDropDown: false,
                        });
                      }}
                    >
                      <span
                        className="FM_currencyDrop_AllSelector"
                        data-e2e="merchant"
                      >
                        {this.state.merchantName === 'Select'
                          ? 'Select Merchant'
                          : this.state.merchantName}
                      </span>
                      <span className="FilterModalInputDropUpIconWrap">
                        <i
                          data-e2e="allowableCryptoDropDownIcon"
                          className="fa fa-chevron-up FilterModalInputDropUpIcon"
                        />
                      </span>
                    </div>
                    {this.state.merchants &&
                      this.state.merchants.map((ele, index) => {
                        return (
                          <div
                            key={index}
                            className="currencyListItemWrap"
                            name="merchant"
                            data-e2e={ele.name}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  showMerchantDropDown: false,
                                  merchantName: ele.name,
                                  editData: {
                                    ...this.state.editData,
                                    ['merchant']: ele._id,
                                  },
                                  errorMsg: '',
                                  errorField: '',
                                },
                                () => {
                                  if (this.state.addClicked) {
                                    this.validate();
                                  }
                                }
                              );
                            }}
                          >
                            <div className="currencyListIconNameWrap">
                              <div
                                className="currencyList_Name"
                                name="merchant"
                                data-e2e={ele.name}
                              >
                                {ele.name}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect3"
                >
                  IP Address
                </label>
                <input
                  type="text"
                  value={this.state.editData.ipAddress}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect3"
                  placeholder="Enter IP Address"
                  name="ipAddress"
                  data-e2e="ip_address"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>
              <div
                className="FilterModalInputGroup"
                style={{ marginBottom: '20px' }}
              >
                <div className="FilterModalInputLabel">Status</div>
                <div
                  className="FilterModalInputDropdown"
                  data-e2e="status"
                  onClick={() => {
                    this.setState({
                      showStatusDropDown: true,
                      showMerchantDropDown: false,
                    });
                  }}
                >
                  <div
                    className="FilterModalInputText"
                    data-e2e={this.state.editData.status ? 'ENABLED' : 'DISABLED'}
                  >
                    {this.state.editData.status ? 'ENABLED' : 'DISABLED'}
                  </div>
                  <i
                    className="fa fa-chevron-down FilterModalInputDropIcon"
                    data-e2e="statusDropDown"
                  />
                </div>
                {this.state.showStatusDropDown && (
                  <div
                    className="FilterModalCurrencyDropdownlist"
                    data-e2e={this.state.editData.status ? 'ENABLED' : 'DISABLED'}
                  >
                    <div
                      className="FM_currencyDrop_All"
                      onClick={() => {
                        this.setState({
                          showStatusDropDown: false,
                        });
                      }}
                    >
                      <span
                        className="FM_currencyDrop_AllSelector"
                      >
                        {this.state.editData.status ? 'ENABLED' : 'DISABLED'}
                      </span>
                      <span className="FilterModalInputDropUpIconWrap">
                        <i
                          data-e2e={this.state.editData.status ? 'ENABLED' : 'DISABLED'}
                          className="fa fa-chevron-up FilterModalInputDropUpIcon"
                          data-e2ee="up"
                        />
                      </span>
                    </div>
                    {this.state.statusData &&
                      this.state.statusData.map((ele, index) => {
                        return (
                          <div
                            key={index}
                            className="currencyListItemWrap"
                            name="blockchain"
                            data-e2e={ele.name}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  showStatusDropDown: false,
                                  editData: {
                                    ...this.state.editData,
                                    ['status']: ele.value,
                                  },
                                  errorMsg: '',
                                  errorField: '',
                                },
                                () => {
                                  if (this.state.addClicked) {
                                    this.validate();
                                  }
                                }
                              );
                            }}
                          >
                            <div className="currencyListIconNameWrap">
                              <div
                                className="currencyList_Name"
                                name="blockchain"
                                data-e2e={ele.name}
                              >
                                {ele.name}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>

              {this.state.errorMsg !== '' && (
                <div style={{ color: 'red' }}>{this.state.errorMsg}</div>
              )}

              <div className="d-flex justify-content-center">
                <div
                  data-e2e="save"
                  className={`submit ${
                    this.state.errorMsg !== '' ? 'inactive' : ''
                  }`}
                  onClick={() => {
                    this.updateWhiteListIP();
                  }}
                >
                  Save
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div
                  data-e2e="delete"
                  className={`submit ${
                    this.state.errorMsg !== '' ? 'inactive' : ''
                  } GSdeleteBtn`}
                  onClick={() => {
                    this.props.handleDelete(this.state.editData._id);
                  }}
                >
                  Delete
                </div>
              </div>
            </form>
            <SavingAlert
              msg="All Transactions Details has been saved successfuly."
              header="Details Saved"
              btnName="Close"
              showModal={this.state.showSavingModal}
              onCloseClickHandler={() => {
                this.setState({ showSavingModal: false });
                this.props.getWhiteListIPs();
                this.props.handleClose();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    base_url: state.config.api_url,
  };
};

export default connect(mapStateToProps, null)(EditWhiteListIP);
