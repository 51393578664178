import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Chip,
} from "@mui/material";
import {
  CheckCircleOutline,
  ErrorOutline,
  HourglassEmpty,
} from "@mui/icons-material";
import { getTransactionStatus } from "../Transactions/statusMapping";

const statusStyles = {
  success: { color: "#027948", icon: <CheckCircleOutline /> },
  failed: { color: "#DB4040", icon: <ErrorOutline /> },
  processing: { color: "#175CD3", icon: <HourglassEmpty /> },
  rejected: { color: "#FF8944", icon: <ErrorOutline /> },
};

const TransactionTable = ({
  data,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalCount,
  setTotalCount,
}) => {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const goToTransactionPage = (transactionId) => {
    window.open(`/transaction/${transactionId}`, '_blank');
	};
  return (
    <Paper className="p-4">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Routing service</TableCell>
              <TableCell>Merchant</TableCell>
              <TableCell>Acquirer</TableCell>
              <TableCell>Card scheme</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length &&
              data.map((transaction) => (
                <TableRow className="cursor-pointer" key={transaction._id} onClick={()=>goToTransactionPage(transaction?.transactionId)}>
                  <TableCell>{transaction.routingService || "N/A"}</TableCell>
                  <TableCell>{transaction.merchant}</TableCell>
                  <TableCell>{transaction.acquirer}</TableCell>
                  <TableCell>{transaction.cardScheme}</TableCell>
                  <TableCell>{transaction.billingCountry}</TableCell>
                  <TableCell>
                    <Chip
                      label={getTransactionStatus(transaction.status.code)}
                      style={{
                        backgroundColor: "white",
                        border: `1px solid ${
                          statusStyles[
                            getTransactionStatus(transaction.status.code)
                          ]?.color
                        } `,
                        color:
                          statusStyles[
                            getTransactionStatus(transaction.status.code)
                          ]?.color,
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TransactionTable;
