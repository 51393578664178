import * as actionTypes from './actionTypes'

export const setSearchTextWallet = (data) => {
    return {
        type: actionTypes.SET_SEARCH_TEXT_WALLET,
        data
    }
}
export const setMinCryptoAmount = (data) => {
    return {
        type: actionTypes.SET_MIN_CRYPTO_AMOUNT,
        data
    }
}
export const setMaxCryptoAmount = (data) => {
    return {
        type: actionTypes.SET_MAX_CRYPTO_AMOUNT,
        data
    }
}
export const setRangeError = (data) => {
    return {
        type: actionTypes.SET_RANGE_ERROR,
        data
    }
}
export const setOrderByWallet = (data) => {
    return {
        type: actionTypes.SET_ORDER_BY_WALLET,
        data
    }
}
export const setSortByWallet = (data) => {
    return {
        type: actionTypes.SET_SORT_BY_WALLET,
        data
    }
}
export const setFromDateWallet = (data) => {
    return {
        type: actionTypes.SET_FROM_DATE_WALLET,
        data
    }
}
export const setToDateWallet = (data) => {
    return {
        type: actionTypes.SET_TO_DATE_WALLET,
        data
    }
}
export const setDateSelectedWallet = (data) => {
    return {
        type: actionTypes.SET_DATE_SELECTED_WALLET,
        data
    }
}
export const setSelectedStatusListWallet = (data) => {
    return {
        type: actionTypes.SET_SELECTED_STATUS_LIST_WALLET,
        data
    }
}
export const setSelectedStatusIconList = (data) => {
    return {
        type: actionTypes.SET_SELECTED_STATUS_ICON_LIST,
        data
    }
}
export const setSelectedBlockchainList = (data) => {
    return {
        type: actionTypes.SET_SELECTED_BLOCKCHAIN_LIST,
        data
    }
}
export const setSelectedNetworkListWallet = (data) => {
    return {
        type: actionTypes.SET_SELECTED_NETWORK_LIST_WALLET,
        data
    }
}
export const setSelectedCryptosWallet = (data) => {
    return {
        type: actionTypes.SET_SELECTED_CRYPTOS_WALLET,
        data
    }
}
export const setSelectedPaymentProviderWallet = (data) => {
    return {
        type: actionTypes.SET_SELECTED_PAYMENT_PROVIDER_WALLET,
        data
    }
}

export const setCryptoAmount = (data) => {
    return {
        type: actionTypes.SET_CRYPTO_AMOUNT,
        data
    }
}