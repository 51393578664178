import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import FormInput from "../../../../../common/FormInput";
import { call, logoutAfterError } from "../../../../../../config/axios";
import {
  CREATE_NEW_ACQUIRER,
  UPDATE_SINGLE_ACQUIRER,
} from "../../../../../../config/endpoints";
import localDb from "../../../../../../localDb";
import Loading from "../../../../../common/Loading";
function AddOrEditAcquirer({
  handleCloseAddNew,
  currentAcquirer,
  editOpted,
  integrationTypeData,
  setReload,
  reload,
}) {
  const baseUrl = useSelector((state) => state?.config?.api_url);
  const [loading, setLoading] = useState(false);
  const [acquirerName, setAcquirerName] = useState(
    currentAcquirer?.acquirerSetting?.acquirerName || "",
  );
  const [tokenType, setTokenType] = useState(
    currentAcquirer?.acquirerSetting?.tokenType || "",
  );
  const [siteReferenceName, setSiteReferenceName] = useState(
    currentAcquirer?.acquirerSetting?.siteReferenceName || "",
  );
  const [username, setUsername] = useState(
    currentAcquirer?.acquirerSetting?.username || "",
  );
  const [jwtUsername, setJwtUsername] = useState(
    currentAcquirer?.acquirerSetting?.jwtUsername || "",
  );
  const [jwtPassword, setJwtPassword] = useState(
    currentAcquirer?.acquirerSetting?.jwtPassword || "",
  );
  const [livestatus, setLivestatus] = useState(
    currentAcquirer?.acquirerSetting?.livestatus || "",
  );
  const [notes, setNotes] = useState(
    currentAcquirer?.acquirerSetting?.notes || "",
  );
  const [pwd, setPwd] = useState(currentAcquirer?.acquirerSetting?.pwd || "");
  const [selectedIntegrationType, setSelectedIntegrationType] = useState("");

  const [integrationTypeId, setIntegrationTypeId] = useState("");
  const [formErrors, setFormErrors] = useState(false);
  const [error, setError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const filterNameData = () => {
    const newArr = integrationTypeData?.filter((type) => {
      return type?._id == currentAcquirer?.integrationType;
    });
    setIntegrationTypeId(newArr[0]?._id);
    const name = JSON.stringify(newArr[0]);
    setSelectedIntegrationType(name);
  };
  useEffect(() => {
    filterNameData();
  }, []);
  const handleChange = (event) => {
    const data = JSON.parse(event.target.value);
    setIntegrationTypeId(data?._id);
    setSelectedIntegrationType(event.target.value);
  };

  const handleAquirer = async (e) => {
    setLoading(true);
    if (!acquirerName) {
      setFormErrors(true);
      setLoading(false);
      return;
    }
    let payload;
    if (editOpted) {
      payload = {
        email: localDb.getVal("email"),
        acquirerUniqueId: currentAcquirer?.acquirerUniqueId,
        integrationTypeId: integrationTypeId,
        query: {
          integrationTypeId: integrationTypeId,
          acquirerName: acquirerName,
          tokenType: tokenType,
          siteReferenceName: siteReferenceName,
          username: username,
          pwd: pwd,
          jwtUsername: jwtUsername,
          jwtPassword: jwtPassword,
          livestatus: livestatus,
          notes: notes,
        },
      };
      try {
        const res = await call(
          {
            ...UPDATE_SINGLE_ACQUIRER,
            url: baseUrl + UPDATE_SINGLE_ACQUIRER.url,
          },
          payload,
        );

        if (res?.status === 200) {
          handleCloseAddNew();
          setReload(!reload);
          setLoading(false);
        }
      } catch (error) {
        setReload(!reload);
        setError(true);
        setErrorMsg(error?.response?.data?.error);
        setLoading(false);
        logoutAfterError(error);
        console.error("Error:", error);
      }
    } else {
      payload = {
        email: localDb.getVal("email"),
        integrationTypeId: integrationTypeId,
        acquirerName: acquirerName,
        tokenType: tokenType,
        siteReferenceName: siteReferenceName,
        username: username,
        pwd: pwd,
        jwtUsername: jwtUsername,
        jwtPassword: jwtPassword,
        livestatus: livestatus,
        notes: notes,
      };
      try {
        const res = await call(
          {
            ...CREATE_NEW_ACQUIRER,
            url: baseUrl + CREATE_NEW_ACQUIRER.url,
          },
          payload,
        );

        if (res?.status === 200) {
          handleCloseAddNew();
          setReload(!reload);
        }
      } catch (error) {
        setError(true);
        setErrorMsg(error?.response?.data?.error);
        setReload(!reload);
        setLoading(false);
        console.error("Error:", error);
      }
    }
  };
  return (
    <div className="acquirerConfigDialog">
      <div className="myAccoutDetailsMain">
        <div className="myAccoutDetailsCard">
          <div className="d-flex justify-content-between">
            <h5 className="myAccoutOptModalHeading card-title text-center">
              {`${editOpted ? "Edit" : "Add"} Acquirer`}
            </h5>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleCloseAddNew();
              }}
            >
              <ArrowBackIcon size={24} />
            </div>
          </div>
          <form>
            <FormInput
              label="Acquirer Name"
              name={acquirerName}
              value={acquirerName}
              placeholder="Acquirer Name"
              setterValue={setAcquirerName}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <div className="form-group myAccoutOptModal_form_group">
              <label
                className="myAccoutOptModalLable"
                htmlFor="exampleFormControlSelect3"
              >
                Integration Type
              </label>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedIntegrationType || ""}
                displayEmpty
                onChange={(event) => {
                  handleChange(event);
                }}
                className="from-control inputdropdown"
              >
                <MenuItem disabled value="">
                  <em>Select Integration Type</em>
                </MenuItem>
                {integrationTypeData?.map((item, i) => (
                  <MenuItem
                    key={i + item?.acquireName}
                    value={JSON.stringify(item)}
                    name={item?.acquireName}
                  >
                    {item?.acquireName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <FormInput
              label="Token Type"
              name={tokenType}
              value={tokenType}
              placeholder="Token Type"
              setterValue={setTokenType}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="Site Reference Name"
              name={siteReferenceName}
              value={siteReferenceName}
              placeholder="Site Reference Name"
              setterValue={setSiteReferenceName}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="User Name"
              name={username}
              value={username}
              placeholder="User Name"
              setterValue={setUsername}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="Pwd"
              name={pwd}
              value={pwd}
              placeholder="pwd"
              setterValue={setPwd}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="Jwt Username"
              name={jwtUsername}
              value={jwtUsername}
              placeholder="Jwt Username"
              setterValue={setJwtUsername}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="Jwt Password"
              name={jwtUsername}
              value={jwtPassword}
              placeholder="Jwt Password"
              setterValue={setJwtPassword}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="Live Status"
              name={livestatus}
              value={livestatus}
              placeholder="Live Status"
              setterValue={setLivestatus}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="Notes"
              name={notes}
              value={notes}
              placeholder="Notes"
              setterValue={setNotes}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            {formErrors && (
              <div className="my-2 w-full text-left text-red-600">
                {"Please fill Acquirer Name "}
              </div>
            )}
            {error && (
              <div className="my-2 w-full text-left text-red-600">
                {errorMsg}
              </div>
            )}
            <div className="d-flex justify-content-center">
              <div
                className="submit"
                onClick={() => {
                  handleAquirer();
                }}
              >
                {editOpted ? "Edit" : "Add"}
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default AddOrEditAcquirer;
