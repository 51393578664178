import React from 'react'
import { Modal } from 'react-bootstrap';
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useSelector } from 'react-redux';
import Select from "react-select";


const CentralisedTrasferModel = (props) => {
    const {
      showModal,
      toggleShowModal,
      transferWallets,
      singleTransferWallets,
      choosenWallet,
      cryptoAmount,
      cryptoEquUSDAmount,
      onCryptoEquUSDAmountChange,
      limitError,
      selectedTransferWallet,
      selectedToken,
      getSingleTransferWallet,
      selectedWalletForTransfer,
      handleChangeCryptoCurrency,
      loadingExchange,
      toggleAlertModal,
      handleChoosenWallet,
      merchantWallets,
      getSingleTransferMerchantWallet,
      onCryptoAmountChange,
    } = props;
    const imageBaseUrl = useSelector((state) => state.config.image_base_url);
    const closeImage = "/cryptonpay/crytoicons/close.png";
    return (
        <Modal
          show={true}
          className="ClearAlertModal transferModal transferFundsModal"
          onHide={() => {
            toggleShowModal(false);
          }}
        >
          <Modal.Body className="ClearAlertModalBody transferModalBody transaferModalBodyOverflow min-h-400">
            <div className="transferModalTitleWrap">
              <div className="transferModalTitle" data-e2e="TRANSFER FUNDS">
                Transfer Funds
              </div>
              <img
                className="closeIcon"
                alt="closeIcon"
                src={`${imageBaseUrl}${closeImage}`}
                onClick={() => {
                  toggleShowModal(false);
                }}
              />
            </div>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Choose Wallets
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <div className="flex gap-2">
                  <FormControlLabel
                    control={
                      <Radio
                        checked={choosenWallet === "Transfer"}
                        onChange={() => {
                          handleChoosenWallet("Transfer");
                        }}
                      />
                    }
                    label="Merchant Transfer"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={choosenWallet === "Available"}
                        onChange={() => {
                          handleChoosenWallet("Available");
                        }}
                      />
                    }
                    label="Saved Wallets"
                  />
                </div>
              </RadioGroup>
            </FormControl>
            {choosenWallet !== "Transfer" && (
              <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect1"
                >
                  Saved Transfer Wallets
                </label>
                <Select
                  options={transferWallets.map(({ name, _id }) => ({
                    label: name,
                    value: _id,
                  }))}
                  isDisabled={choosenWallet === "Transfer"}
                  onChange={(event) => {
                    getSingleTransferWallet(event);
                  }}
                />
              </div>
            )}
            {choosenWallet === "Transfer" && (
              <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect1"
                >
                  Merchant User Wallets
                </label>
                <Select
                  isDisabled={choosenWallet !== "Transfer"}
                  isClearable
                  options={merchantWallets.map((item) => ({
                    label: item.address.key,
                    value: item._id,
                  }))}
                  onChange={(event) => getSingleTransferMerchantWallet(event)}
                />
              </div>
            )}
            {singleTransferWallets && (
              <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect1"
                >
                  Destination Wallet Address:
                </label>
                <div className="WalletAddress">
                  {singleTransferWallets.address}
                </div>
              </div>
            )}
            {singleTransferWallets && (
              <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect1"
                >
                  CryptoCurrency
                </label>
                <Select
                  options={selectedWalletForTransfer}
                  onChange={(event) => {
                    handleChangeCryptoCurrency(event);
                  }}
                />
              </div>
            )}
            {selectedToken && (
              <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect1"
                >
                  <span>Amount</span>
                  {loadingExchange ? (
                    <span className='loading-span'>
                      Loading Exchange Value...
                    </span>
                  ) : null}
                </label>
                <div className="amountExchageWrap">
                  <input
                    type="number"
                    value={cryptoAmount}
                    className="form-control inputdropdown"
                    id="cryptoAmount"
                    data-e2e="crypto-amount"
                    placeholder="amount in crypto"
                    name="cryptoAmount"
                    onChange={(e) => {
                      onCryptoAmountChange(e.target.value);
                    }}
                  />
                  <div className="exchangeIcon">
                    <i className="fa fa-exchange"></i>
                  </div>
                  <input
                    type="number"
                    value={cryptoEquUSDAmount}
                    className="form-control inputdropdown"
                    id="cryptoEquUSDAmount"
                    data-e2e="cryptoEquUSDAmount"
                    data-e2e-covert={cryptoEquUSDAmount}
                    placeholder="amount in USD"
                    name="cryptoEquUSDAmount"
                    onChange={(e) => {
                      onCryptoEquUSDAmountChange(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}

            {limitError !== "" && (
              <div className="limitErrorText">{limitError}</div>
            )}
            <div
              className={`ClearAlertModalClearBtn transferFundsBtn ${
                selectedTransferWallet !== "" &&
                limitError === "" &&
                selectedToken !== ""
                  ? ""
                  : "transferFundsBtnDisable"
              }`}
              onClick={() => {
                if (
                  selectedTransferWallet !== "" &&
                  limitError === "" &&
                  selectedToken !== ""
                ) {
                  toggleAlertModal(true);
                }
              }}
              data-e2e="transfer-fund-btn"
            >
              TRANSFER FUNDS
            </div>
          </Modal.Body>
        </Modal>
    );
}

export default CentralisedTrasferModel