import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";

const customStylesTable = {
  headCells: {
    style: {
      fontSize: "12px",
      color: "#000",
      fontWeight: 700,
      justifyContent: "center",
      textAlign: "center",
    },
  },
  cells: {
    style: {
      paddingTop: "3px",
      paddingBottom: "3px",
      fontSize: "14px",
      justifyContent: "center",
      textAlign: "center",
    },
  },
};

const sortingValues = {
  "Merchant ID": "merchantId",
  "Masked PAN": "maskedPan",
  "Success Transaction Count": "successTransactionCount",
  Type: "type",
  "Created At": "createdAt",
};

const TrafficMaskedPanTable = (props) => {
  const columns = [
    {
      name: <p data-e2e="name">{Object.keys(sortingValues)[0]}</p>,
      selector: (row) => row.merchantId || "N/A",
      sortable: true,
      grow: 1,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row.merchantId || "N/A"}</div>
      ),
    },
    {
      name: <p data-e2e="name">{Object.keys(sortingValues)[1]}</p>,
      selector: (row) => row.maskedPan || "N/A",
      sortable: true,
      grow: 1,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row.maskedPan || "N/A"}</div>
      ),
    },
    {
      name: <p data-e2e="name">{Object.keys(sortingValues)[2]}</p>,
      selector: (row) =>
        row.successTransactionCount != null
          ? row.successTransactionCount
          : "N/A",
      sortable: true,
      grow: 1,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {row.successTransactionCount != null
            ? row.successTransactionCount
            : "N/A"}
        </div>
      ),
    },
    {
      name: <p data-e2e="name">{Object.keys(sortingValues)[3]}</p>,
      selector: (row) => row.type || "N/A",
      sortable: true,
      grow: 1,
      cell: (row) => (
        <div data-tag="allowRowEvents" className="text-capitalize">
          {row.type || "N/A"}
        </div>
      ),
    },
    {
      name: <p data-e2e="name">Created Date</p>,
      selector: (row) =>
        row.createdAt
          ? moment(row.createdAt).format("DD/MM/YYYY - HH:mm")
          : "N/A",
      sortable: true,
      grow: 1,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {row.createdAt
            ? moment(row.createdAt).format("DD/MM/YYYY - HH:mm")
            : "N/A"}
        </div>
      ),
    },
  ];

  const onChangePage = (page) => {
    props.setPage(page);
  };

  const customSort = (column, sortDirection) => {
    props.changeOrder(sortingValues[column.name.props.children]);
  };

  return (
    <DataTable
      onChangePage={onChangePage}
      paginationTotalRows={props.totalCount}
      paginationPerPage={10}
      paginationComponentOptions={{
        noRowsPerPage: true,
      }}
      paginationServer
      pagination
      sortServer={true}
      columns={columns}
      data={props.data}
      customStyles={customStylesTable}
      onSort={customSort}
      highlightOnHover={true}
      pointerOnHover={true}
      noDataComponent={
        <div style={{ padding: "24px", fontSize: "14px" }}>
          There are no data to display
        </div>
      }
    />
  );
};

export default TrafficMaskedPanTable;
