import React from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/actions';

const MerchantTable = (props) => {
	const { orderBy, sortBy } = props;

	function SortIconElement(props) {
		const isActive = props.isActive;
		return (
			<i className={`fa fa-chevron-${isActive && orderBy === "desc" ? 'up' : 'down'} ${isActive ? 'active' : ''}`} />
		);
	};

	return (
		<div>
			<div className="theme-table">
				<Table responsive>
					<tbody>
						<tr>
							<th className="theme-table-hover" onClick={() => { props.changeOrder('name') }} data-e2e="name">
								<p className="theme-table-text">
									Merchant
									<SortIconElement data-e2e="name" isActive={sortBy === 'name'} />
								</p>
							</th>
							<th className="theme-table-hover" onClick={() => { props.changeOrder('merchantId') }} data-e2e="merchantId">
								<p className="theme-table-text">
									ID
									<SortIconElement data-e2e="merchantId" isActive={sortBy === 'merchantId'} />
								</p>
							</th>
							<th className="theme-table-hover" onClick={() => { props.changeOrder('creationDate') }} data-e2e="creationDate">
								<p className="theme-table-text">
									Created Date
									<SortIconElement data-e2e="creationDate" isActive={sortBy === 'creationDate'} />
								</p>
							</th>
							<th className="theme-table-hover" onClick={() => { props.changeOrder('activitation') }} data-e2e="activitation">
								<p className="theme-table-text">
									Status
									<SortIconElement data-e2e="activitation" isActive={sortBy === 'activitation'} />
								</p>
							</th>
							<th className="theme-table-hover" style={{ cursor: "default" }} data-e2e="email">
								<p className="theme-table-text">
									Merchant Contact Information
								</p>
							</th>
							<th>
								<p className="theme-table-text">Actions</p>
							</th>
						</tr>
						{props && props.data && props.data.length > 0 ?
							props.data.map((TData, i) => {
								return <SingleTransactionTableRow data={TData} key={i} setMerchantNameForHeader={props?.setMerchantNameForHeader} />;
							}) :
							(
								<tr data-e2e="No_Transactions_Found">
									<td colSpan="7">
										<p className="theme-table-empty">No Data Found</p>
									</td>
								</tr>
							)
						}
					</tbody>
				</Table>
			</div>
			{props.showMoreButton &&
				<div className="theme-btn-block center show-btn">
					<div className="theme-btn" data-e2e="showMore" onClick={() => { props.handleShowMore(); }}>
						<p>Show more</p>
					</div>
				</div>
			}
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		setMerchantNameForHeader: (data) => dispatch(actions.setMerchantNameForHeader(data)),
	}
};

export default connect(null, mapDispatchToProps)(MerchantTable);

const SingleTransactionTableRow = (props) => {
	let { data } = props;

	const setUser = (data) => {
		props.setMerchantNameForHeader(data.name)
		localStorage.setItem('userName', data.name);
		localStorage.setItem('merchantPaymentProvider', data.paymentProvider);
	}

	const getStatusComponent = (status) => {
		switch (status) {
			case 'DepositMonitor:DepositPendingManualApproval':
				return <div className="theme-table-status witheld">Witheld</div>;
			case 'DepositMonitor:MonitoringMemPool':
			case 'DepositFlow:AwaitingDeposit':
			case 'DepositFlow:Launched':
			case 'CreatePayment':
			case 'DepositMonitor:WalletStatusAvailable':
			case 'DepositMonitor:WalletStatusLocked':
			case 'DepositMonitor:UpdateWalletStatus':
			case 'DepositMonitor:NotfiyMerchant':
			case 'DepositMonitor:DepositManualApprove':
			case 'DepositMonitor:KYTCheckFail':
			case 'DepositMonitor:KYTCheckPass':
			case 'DepositMonitor:KYTCheck':
			case 'DepositMonitor:DepositConfirmed':
			case 'DepositMonitor:GasTooLow':
			case 'DepositMonitor:ExcessTransactionExecuted':
			case 'DepositMonitor:TransactionExecuted':
			case 'DepositMonitor:TransactionExecuting':
			case 'DepositMonitor:DepositReceivedToMemPool':
			case 'DepositMonitor:TransactionComplete':
				return <div className="theme-table-status pending">Pending</div>;
			case 'DepositMonitor:DepositManuallyCompleted':
			case 'DepositMonitor:DepositCompleted':
			case 'MerchantWallet:BalanceUpdated':
			case 'WalletManagementService:WithdrawalCompleted':
			case 'WalletManagementService:TransferCompleted':
			case 'DepositFlowViaWallet:DepositCompleted':
				return <div className="theme-table-status active">Success</div>;
			case 'Active':
				return <div className="theme-table-status active">Active</div>;
			case 'Available':
				return <div className="theme-table-status active">Available</div>;
			case 'Inactive':
				return <div className="theme-table-status inactive">Inactive</div>;
			case 'Pending':
				return <div className="theme-table-status pending">Pending</div>;
			case 'Locked':
				return <div className="theme-table-status witheld">Locked</div>;
			case 'In Use':
				return <div className="theme-table-status inactive">In use</div>;
			case 'Failed':
				return <div className="theme-table-status inactive">Failed</div>;
			case 'Inuse':
				return <div className="theme-table-status failed">INUSE</div>;
			default:
				return <div className="theme-table-status inactive">Failed</div>;
		}
	};

	return (
		<tr className="theme-table-row">
			<td>
				<div className="theme-table-row-td">
					<p className="theme-table-text">{(data?.name) || 'N/A'}</p>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td">
					<p className="theme-table-text">{(data?.merchantId) || 'N/A'}</p>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td">
					<p className="theme-table-text">{data?.creationDate ? moment(data?.creationDate).format('DD/MM/YYYY - HH:mm') : 'N/A'}</p>
				</div>
			</td>
			<td data-e2e={`${data?.merchantId} ${data?.activitation}`}>
				{data?.activitation ? getStatusComponent('Active') : getStatusComponent('Inactive')}
			</td>
			<td>
				<div className="theme-table-row-td">
					<p className="theme-table-text">{(data?.contacts && data?.contacts?.length > 0 && data?.contacts[0].email) || 'N/A'}</p>
				</div>
			</td>
			<td>
				<Link to={`/merchants/${data?.merchantId}`} onClick={() => setUser(data)} className="theme-table-action">
					<span data-e2e={`${(data?.name)}`}>More Details</span>
				</Link>
			</td>
		</tr>
	);
};
