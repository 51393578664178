import React, { useState } from "react";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import Loading from "../../../common/Loading";
import BlockchainSettingsTable from "./BlockchainSettingsTable";
import { useSelector } from "react-redux";

function BlockchainSettings() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const name = "Blockchain settings";

  return (
    <div className="pageContainer merchant_page">
      <HeaderAndFooterLayout>
        <CommonHeader name={name} />
        <div>
          <div>
            <BlockchainSettingsTable data={data} />
          </div>
        </div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
}

export default BlockchainSettings;
