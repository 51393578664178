import {TableBody, TableContainer} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import React, {useState} from 'react';
import TransactionsTableRow from "./TransactionsTableRow";
import MediaQuery from "react-responsive";
import {MerchantUserTransactionsTableMobile} from "./MerchantUserTransactionsTableMobile";
import {TransactionBtn} from "../CommonPages/TransactionView";
import {mapHeaderToKey} from "./utils/helperFunctions";
import {TableHeader} from "./TableHeader";
import {generateRandomListKey} from "../../global/helpers";




const MerchantUserTransactionsTable = ({
  balances,
  setWalletDetailBtn,
  walletDetailBtn
}) => {
  const tableHeaders = [
    'DATE',
    'TRANSACTION TYPE',
    'TRANSACTION ID',
    'ASSET',
    'AMOUNT',
    'PREVIOUS BALANCE',
    'CURRENT BALANCE'
  ];
  const [isSorted, setIsSorted] = useState(false);
  const [sortedBalances, setSortedBalances] = useState(balances);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const sortBalances = (header) => {
    let direction = 'ascending';
    const key = mapHeaderToKey(header)
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sorted = [...sortedBalances].sort((a, b) => {

      if (typeof a[key] === 'number' && typeof b[key] === 'number') {
        return direction === 'ascending' ? a[key] - b[key] : b[key] - a[key];
      }

      if (typeof a[key] === 'string' && typeof b[key] === 'string') {
        return direction === 'ascending' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
      }

      return 0;
    });

    setSortedBalances(sorted);
    
    setSortConfig({ key, direction });
  };

  return (
    <React.Fragment>
      <div className="wallet_container pt-0">
        <MediaQuery minWidth={768}>
          <div className="wallet_container pt-0">
            <TableContainer component={Paper} >
              <Table>
                <TableHead>
                  <TableRow>
                    {tableHeaders.map(header =>(
                      <TableHeader
                        key={generateRandomListKey()}
                        header={header}
                        sortBalances={sortBalances}
                        isSorted={isSorted}
                        setIsSorted={setIsSorted}
                        currentSortConfig={sortConfig}
                        setSortConfig={setSortConfig}
                        sortKey={mapHeaderToKey(header)}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedBalances && sortedBalances.map((balance,index) => (
                    <TransactionsTableRow key={index} balance={balance} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          {
            walletDetailBtn === "Event log"
            &&(
              <React.Fragment>
                <div className="TransactionBtn_container pt-5">
                  <TransactionBtn
                    detailBtn={walletDetailBtn}
                    view={"Merchant User Wallets"}
                    setDetailBtn={setWalletDetailBtn}
                    btn={["Wallets", "Event log"]}
                  />
                </div>
                <MerchantUserTransactionsTableMobile
                  balances={balances}
                  setWalletDetailBtn={setWalletDetailBtn}
                  walletDetailBtn={walletDetailBtn}
                />
              </React.Fragment>
            )
          }
        </MediaQuery>
      </div>
    </React.Fragment>

  )
}

export default MerchantUserTransactionsTable;
