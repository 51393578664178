import Tooltip from '@mui/material/Tooltip';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { call, logoutAfterError } from "../../../../config/axios";
import { MERCHANT_IMAGE_UPLOAD_SIGNED_URL,SAVE_PAYMENT_PROVIDER_DOMAIN } from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";

const closeImage = '/cryptonpay/crytoicons/close.png';
const allThemes = ["Dark Theme", "Light Theme"];
const allB2CThemes = ["Disabled", ...allThemes];
const themeOption = ["Disabled", "Enabled"];

const EditDomain = (props) => {
    const base_url = useSelector((state) => state.config.api_url);
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const email = localDb.getVal("email");
    const [loading, setLoading] = useState(false);
    const [logoImage, setLogoImage] = useState(null);
    const [logoDarkImage, setLogoDarkImage] = useState(null);
    const [showLogoImage, setShowLogoImage] = useState(true);
    const [showFaviconImage, setShowFaviconImage] = useState(true);
    const [showLogoDarkImage, setShowLogoDarkImage] = useState(true);
    const [showBGImage, setShowBGImage] = useState(true);
    const [showBGDarkImage, setShowBGDarkImage] = useState(true);
    const [bgImage, setBGImage] = useState(null);
    const [bgDarkImage, setBGDarkImage] = useState(null);
    const [themeDropDown, setThemeDropDown] = useState(null);
    const [b2cThemeDropDown, setB2CThemeDropDown] = useState(null);
    const [themeDisabledDropDown, setThemeDisabledDropDown] = useState(null);
    const [landingPageDropDown, setLandingPageDropDown] = useState(null);
    const [homeB2CDropDown, setHomeB2CDropDown] = useState(null);
    const [homeMerchantDropDown, setHomeMerchantDropDown] = useState(null);
    const [homeClientDropDown, setHomeClientDropDown] = useState(null);
    const [faviconImage, setFaviconImage] = useState(null);
    const [data, setData] = useState({
        domainNameUrl: props.domainData !== null && props.domainData.domainNameUrl ? props.domainData.domainNameUrl : "",
        paymentProviderName: props.domainData !== null && props.domainData.paymentProviderName ? props.domainData.paymentProviderName : "",
        redirectUrl: props.domainData !== null && props.domainData.redirectUrl ? props.domainData.redirectUrl : "",
        font: props.domainData !== null && props.domainData.font ? props.domainData.font : "",
        defaultTheme: props.domainData !== null && allThemes.includes(props.domainData.defaultTheme) ? props.domainData.defaultTheme : "Dark Theme",
        defaultB2CTheme: props.domainData !== null && allB2CThemes.includes(props.domainData.defaultB2CTheme) ? props.domainData.defaultB2CTheme : "Disabled",
        themeToggleButton: props.domainData !== null && themeOption.includes(props.domainData.themeToggleButton) ? props.domainData.themeToggleButton : "Enabled",
        defaultLandingPage: props.domainData !== null && props.domainData.defaultLandingPage ? props.domainData.defaultLandingPage : "home-merchant",
        homeB2C: props.domainData !== null && themeOption.includes(props.domainData.homeB2C) ? props.domainData.homeB2C : "Enabled",
        homeMerchant: props.domainData !== null && themeOption.includes(props.domainData.homeMerchant) ? props.domainData.homeMerchant : "Enabled",
        homeClient: props.domainData !== null && themeOption.includes(props.domainData.homeClient) ? props.domainData.homeClient : "Enabled",
        headerLightBgColour: props.domainData !== null && props.domainData.headerLightBgColour ? props.domainData.headerLightBgColour : "",
        headerDarkBgColour: props.domainData !== null && props.domainData.headerDarkBgColour ? props.domainData.headerDarkBgColour : "",
        headerButtonLightBgColour: props.domainData !== null && props.domainData.headerButtonLightBgColour ? props.domainData.headerButtonLightBgColour : "",
        headerButtonDarkBgColour: props.domainData !== null && props.domainData.headerButtonDarkBgColour ? props.domainData.headerButtonDarkBgColour : "",
        headerLightTextColour: props.domainData !== null && props.domainData.headerLightTextColour ? props.domainData.headerLightTextColour : "",
        headerDarkTextColour: props.domainData !== null && props.domainData.headerDarkTextColour ? props.domainData.headerDarkTextColour : "",
        headerLightLinkColour: props.domainData !== null && props.domainData.headerLightLinkColour ? props.domainData.headerLightLinkColour : "",
        headerDarkLinkColour: props.domainData !== null && props.domainData.headerDarkLinkColour ? props.domainData.headerDarkLinkColour : "",
        bodyLightTextColour: props.domainData !== null && props.domainData.bodyLightTextColour ? props.domainData.bodyLightTextColour : "",
        bodyDarkTextColour: props.domainData !== null && props.domainData.bodyDarkTextColour ? props.domainData.bodyDarkTextColour : "",
        homeB2CPath: props.domainData !== null && props.domainData.homeB2CPath ? props.domainData.homeB2CPath : "",
        homeMerchantPath: props.domainData !== null && props.domainData.homeMerchantPath ? props.domainData.homeMerchantPath : "",
        homeClientPath: props.domainData !== null && props.domainData.homeClientPath ? props.domainData.homeClientPath : "",
        logoWidth:props.domainData !==null && props.domainData.logoWidth ? props.domainData.logoWidth : "",
        bannerTextDark: props.domainData !== null && props.domainData.bannerTextDark ? props.domainData.bannerTextDark : '',
        bannerText: props.domainData !== null && props.domainData.bannerText ? props.domainData.bannerText : ''
    });

    const handleSubmit = (type) => {
        let payload = {
            adminRole: localDb.getVal("adminRole"),
            paymentProviderId: props.paymentProviderId,
            email: email,
            domainNameUrl: data.domainNameUrl,
            paymentProviderName: data.paymentProviderName,
            redirectUrl: data.redirectUrl,
            font: data.font,
            defaultTheme: data.defaultTheme,
            defaultB2CTheme: data.defaultB2CTheme,
            themeToggleButton: data.themeToggleButton,
            headerLightBgColour: data.headerLightBgColour,
            headerDarkBgColour: data.headerDarkBgColour,
            headerButtonLightBgColour: data.headerButtonLightBgColour,
            headerButtonDarkBgColour: data.headerButtonDarkBgColour,
            headerLightTextColour: data.headerLightTextColour,
            headerDarkTextColour: data.headerDarkTextColour,
            headerLightLinkColour: data.headerLightLinkColour,
            headerDarkLinkColour: data.headerDarkLinkColour,
            bodyLightTextColour: data.bodyLightTextColour,
            bodyDarkTextColour: data.bodyDarkTextColour,
            homeB2CPath: data.homeB2CPath,
            homeMerchantPath: data.homeMerchantPath,
            homeClientPath: data.homeClientPath,
            defaultLandingPage: data.defaultLandingPage,
            homeB2C: data.homeB2C,
            homeMerchant: data.homeMerchant,
            homeClient: data.homeClient,
            logoWidth:data.logoWidth,
            bannerTextDark: data.bannerTextDark,
            bannerText: data.bannerText
        }
        if (type === 'Edit Domain') {
            payload.domainId = props.domainData._id;
            savePaymentProviderDomain(payload)
        } else {
            savePaymentProviderDomain(payload)
        }
    }

    const handleImage = async (e, id) => {
        console.log("handleImage e", e)
        console.log("handleImage id", id)
        var fileInput = document.getElementById("domain" + id);
        console.log("fileInput", fileInput)
        var filePath = fileInput.value;

        var allowedExtensions = /(\.png)$/i;

        console.log("filePath", filePath)
        if (!allowedExtensions.exec(filePath)) {
            alert("Invalid file type");
            fileInput.value = "";
            if (id === "paymentProviderLogoDark") {
                setLogoDarkImage(null)
            } else if (id === "paymentProviderLogo") {
                setLogoImage(null)
            } else if (id === "paymentProviderBGDark") {
                setBGDarkImage(null)
            } 
            else if (id === "paymentProviderFavicon") {
                setFaviconImage(null)
            } 
            else {
                setBGImage(null)
            }
            return false;
        } else {
            if (id === "paymentProviderLogoDark") {
                setLogoDarkImage({ image: e.target.files[0], id })
            } else if (id === "paymentProviderLogo") {
                setLogoImage({ image: e.target.files[0], id })
            } else if (id === "paymentProviderBGDark") {
                setBGDarkImage({ image: e.target.files[0], id })
            } 
            else if (id === "paymentProviderFavicon") {
                setFaviconImage({ image: e.target.files[0], id })
            } 
            else {
                setBGImage({ image: e.target.files[0], id })
            }
        }
    };

    const getSignedUrl = async (image, id, domainId) => {
        setLoading(true);
        const payload = {
            adminRole: localDb.getVal("adminRole"),
            email: email,
            paymentProviderId: props.paymentProviderId,
            imageType: id,
            domainId: domainId ? domainId : props.domainData && props.domainData._id ? props.domainData._id : null,
        };
        await call(
            {
                ...MERCHANT_IMAGE_UPLOAD_SIGNED_URL,
                url: base_url + MERCHANT_IMAGE_UPLOAD_SIGNED_URL.url,
            },
            payload
        )
            .then(async (res) => {
                if (res.status === 200) {
                    await uploadImage(res.data.data, image, "png", res.data.key);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.message, "err");
                logoutAfterError(err)
            });
    };
    const uploadImage = async (url, img, imageType, key) => {
        setLoading(true);
        await fetch(url,{
            method: "PUT",
            body: img,
            headers: { "Content-Type": `image/${imageType ? imageType : "jpeg"}`, "cache-control": "public, max-age=0" },
        })
            .then(async (res) => {
                props.setAlertMsg("Image updated successfuly");
                props.setUpdateSuccessAlert(true);
                setLoading(false);
                setTimeout(() => {
                    props.setUpdateSuccessAlert(false);
                }, 3000);
            })
            .catch((err) => {
                setLoading(false);
                console.log("err: ", err);
            });
    };

    const savePaymentProviderDomain = async (payload) => {
        setLoading(true);
        await call(
            {
                ...SAVE_PAYMENT_PROVIDER_DOMAIN,
                url: base_url + SAVE_PAYMENT_PROVIDER_DOMAIN.url,
            },
            payload
        )
            .then(async (res) => {
                if (res.status === 200) {
                    if (logoImage) {
                        await getSignedUrl(logoImage.image, logoImage.id, res.data.domainId);
                    }
                    if (logoDarkImage) {
                        await getSignedUrl(logoDarkImage.image, logoDarkImage.id, res.data.domainId);
                    }
                    if (bgImage) {
                        await getSignedUrl(bgImage.image, bgImage.id, res.data.domainId);
                    }
                    if (bgDarkImage) {
                        await getSignedUrl(bgDarkImage.image, bgDarkImage.id, res.data.domainId);
                    }
                    if (faviconImage) {
                        await getSignedUrl(faviconImage.image, faviconImage.id, res.data.domainId);
                    }
                    props.handleClose();
                    if (res.data.domains && res.data.domains.length > 0) {
                        props.setDomains(res.data.domains);
                    }
                    props.setAlertMsg("Payment Provider Domain Saved Successfully.");
                    props.setUpdateSuccessAlert(true);
                    setLoading(false);
                    setTimeout(() => {
                        props.setUpdateSuccessAlert(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.message, "err");
                logoutAfterError(err)
            });
    };

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleThemeChange = (value) => {
        setData({ ...data, defaultTheme: value });
    };
    const handleB2CThemeChange = (value) => {
        setData({ ...data, defaultB2CTheme: value });
    };
    const handleThemeToggleButtonChange = (value) => {
        setData({ ...data, themeToggleButton: value });
    };

    const handleDefaultLandingPage = (value) => {
        setData({ ...data, defaultLandingPage: value })
    }
    const handleHomeB2C = (value) => {
        setData({ ...data, homeB2C: value })
    }
    const handleHomeMerchant = (value) => {
        setData({ ...data, homeMerchant: value })
    }
    const handleHomeClient = (value) => {
        setData({ ...data, homeClient: value })
    }
    let disabled = false;
    return (
        <div>
            <div className="create_account_container">
                <div className="create_account_header">
                    <div className="create_account_heading">{props.dialogtitle}</div>
                    <div
                        className="filter_close_button"
                        onClick={() => {
                            props.handleClose();
                        }}
                    >
                        <img src={`${image_base_url}${closeImage}`} alt="closeImage" />
                    </div>
                </div>

                <div className="create_account_form">
                    <div className="mt-4">
                        <label>
                            <b>{"Payment Provider Name"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="paymentProviderName"
                            value={data["paymentProviderName"]}
                            placeholder="Payment Provider Name"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Domain Name Url"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="domainNameUrl"
                            value={data["domainNameUrl"]}
                            placeholder="Domain Name Url"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>

                    <div className="mt-4">
                        <label>
                            <b>{"Redirect Url For Deposit"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="redirectUrl"
                            value={data["redirectUrl"]}
                            placeholder="Redirect Url For Deposit"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>

                    <br/><hr />

                    <div className="mt-4">
                        <label>
                            <b>{"Font"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="font"
                            value={data["font"]}
                            placeholder="font"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="FilterModalInputGroup mt-4">
                        <label className="w-100">
                            <b>{"Default Theme"}</b>
                        </label>
                        <div
                            className="FilterModalInputDropdown"
                            onClick={() => {
                                setThemeDropDown(true);
                            }}
                        >
                            <div className="FilterModalInputText">
                                {data.defaultTheme}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {themeDropDown && (
                            <div
                                className="FilterModalCurrencyDropdownlist"
                                data-e2e="FilterModalCurrencyDropdownlist"
                            >
                                <div
                                    className="FM_currencyDrop_All"
                                    onClick={() => {
                                        setThemeDropDown(false);
                                    }}
                                >
                                    <span
                                        className="FM_currencyDrop_AllSelector"
                                        data-e2e="status"
                                    >
                                        {data.defaultTheme}
                                    </span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i
                                            data-e2e="dropDown"
                                            className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                        />
                                    </span>
                                </div>
                                {allThemes.map((theme, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="currencyListItemWrap"
                                            name="blockchain"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleThemeChange(theme);
                                                setThemeDropDown(false)
                                            }}
                                        >
                                            <div className="currencyListIconNameWrap">
                                                <div
                                                    className="currencyList_Name"
                                                    name="acquireRoutingServiceName"
                                                >
                                                    {theme}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <div className="FilterModalInputGroup mt-4">
                        <label className="w-100">
                            <b>{"Default B2C Theme"}</b>
                        </label>
                        <div
                            className="FilterModalInputDropdown"
                            onClick={() => {
                                setB2CThemeDropDown(true);
                            }}
                        >
                            <div className="FilterModalInputText">
                                {data.defaultB2CTheme}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {b2cThemeDropDown && (
                            <div
                                className="FilterModalCurrencyDropdownlist"
                                data-e2e="FilterModalCurrencyDropdownlist"
                            >
                                <div
                                    className="FM_currencyDrop_All"
                                    onClick={() => {
                                        setB2CThemeDropDown(false);
                                    }}
                                >
                                    <span
                                        className="FM_currencyDrop_AllSelector"
                                        data-e2e="status"
                                    >
                                        {data.defaultB2CTheme}
                                    </span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i
                                            data-e2e="dropDown"
                                            className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                        />
                                    </span>
                                </div>
                                {allB2CThemes.map((theme, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="currencyListItemWrap"
                                            name="blockchain"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleB2CThemeChange(theme);
                                                setB2CThemeDropDown(false)
                                            }}
                                        >
                                            <div className="currencyListIconNameWrap">
                                                <div
                                                    className="currencyList_Name"
                                                    name="acquireRoutingServiceName"
                                                >
                                                    {theme}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Header Light BG Colour"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="headerLightBgColour"
                            value={data["headerLightBgColour"]}
                            placeholder="Header Bg Colour"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Header Dark BG Colour"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="headerDarkBgColour"
                            value={data["headerDarkBgColour"]}
                            placeholder="Header Dark Bg Colour"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Header Button Light BG Colour"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="headerButtonLightBgColour"
                            value={data["headerButtonLightBgColour"]}
                            placeholder="Header Button Light BG Colour"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Header Button Dark BG Colour"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="headerButtonDarkBgColour"
                            value={data["headerButtonDarkBgColour"]}
                            placeholder="Header Button Dark BG Colour"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Header Light Text Colour"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="headerLightTextColour"
                            value={data["headerLightTextColour"]}
                            placeholder="Header Light Text Colour"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Header Dark Text Colour"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="headerDarkTextColour"
                            value={data["headerDarkTextColour"]}
                            placeholder="Header Dark Text Colour"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Header Light Link Colour"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="headerLightLinkColour"
                            value={data["headerLightLinkColour"]}
                            placeholder="Header Light Link Colour"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Header Dark Link Colour"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="headerDarkLinkColour"
                            value={data["headerDarkLinkColour"]}
                            placeholder="Header Dark Link Colour"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Merchant Banner text"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="bannerText"
                            value={data["bannerText"]}
                            placeholder="Merchant Banner text"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Merchant Banner text dark"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="bannerTextDark"
                            value={data["bannerTextDark"]}
                            placeholder="Banner text dark"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Body Light Text Colour"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="bodyLightTextColour"
                            value={data["bodyLightTextColour"]}
                            placeholder="Body Light Text Colour"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Body Dark Text Colour"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="bodyDarkTextColour"
                            value={data["bodyDarkTextColour"]}
                            placeholder="Body Dark Text Colour"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>

                    <br/><hr />

                    <div className="FilterModalInputGroup mt-4">
                        <label className="w-100">
                            <b>{"Home B2C"}</b>
                        </label>
                        <div
                            className="FilterModalInputDropdown"
                            onClick={() => {
                                setHomeB2CDropDown(true);
                            }}
                        >
                            <div className="FilterModalInputText">
                                {data.homeB2C}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {homeB2CDropDown && (
                            <div
                                className="FilterModalCurrencyDropdownlist"
                                data-e2e="FilterModalCurrencyDropdownlist"
                            >
                                <div
                                    className="FM_currencyDrop_All"
                                    onClick={() => {
                                        setHomeB2CDropDown(false);
                                    }}
                                >
                                    <span
                                        className="FM_currencyDrop_AllSelector"
                                        data-e2e="status"
                                    >
                                        {data.homeB2C}
                                    </span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i
                                            data-e2e="dropDown"
                                            className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                        />
                                    </span>
                                </div>
                                {themeOption.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="currencyListItemWrap"
                                            name="homeB2C"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleHomeB2C(item);
                                                setHomeB2CDropDown(false)
                                            }}
                                        >
                                            <div className="currencyListIconNameWrap">
                                                <div
                                                    className="currencyList_Name"
                                                    name="acquireRoutingServiceName"
                                                >
                                                    {item}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>

                    <div className="FilterModalInputGroup mt-4">
                        <label className="w-100">
                            <b>{"Home Merchant"}</b>
                        </label>
                        <div
                            className="FilterModalInputDropdown"
                            onClick={() => {
                                setHomeMerchantDropDown(true);
                            }}
                        >
                            <div className="FilterModalInputText">
                                {data.homeMerchant}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {homeMerchantDropDown && (
                            <div
                                className="FilterModalCurrencyDropdownlist"
                                data-e2e="FilterModalCurrencyDropdownlist"
                            >
                                <div
                                    className="FM_currencyDrop_All"
                                    onClick={() => {
                                        setHomeMerchantDropDown(false);
                                    }}
                                >
                                    <span
                                        className="FM_currencyDrop_AllSelector"
                                        data-e2e="status"
                                    >
                                        {data.homeMerchant}
                                    </span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i
                                            data-e2e="dropDown"
                                            className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                        />
                                    </span>
                                </div>
                                {themeOption.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="currencyListItemWrap"
                                            name="homeMerchant"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleHomeMerchant(item);
                                                setHomeMerchantDropDown(false)
                                            }}
                                        >
                                            <div className="currencyListIconNameWrap">
                                                <div
                                                    className="currencyList_Name"
                                                    name="acquireRoutingServiceName"
                                                >
                                                    {item}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>

                    <div className="FilterModalInputGroup mt-4">
                        <label className="w-100">
                            <b>{"Home Client"}</b>
                        </label>
                        <div
                            className="FilterModalInputDropdown"
                            onClick={() => {
                                setHomeClientDropDown(true);
                            }}
                        >
                            <div className="FilterModalInputText">
                                {data.homeClient}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {homeClientDropDown && (
                            <div
                                className="FilterModalCurrencyDropdownlist"
                                data-e2e="FilterModalCurrencyDropdownlist"
                            >
                                <div
                                    className="FM_currencyDrop_All"
                                    onClick={() => {
                                        setHomeClientDropDown(false);
                                    }}
                                >
                                    <span
                                        className="FM_currencyDrop_AllSelector"
                                        data-e2e="status"
                                    >
                                        {data.homeClient}
                                    </span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i
                                            data-e2e="dropDown"
                                            className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                        />
                                    </span>
                                </div>
                                {themeOption.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="currencyListItemWrap"
                                            name="homeClient"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleHomeClient(item);
                                                setHomeClientDropDown(false)
                                            }}
                                        >
                                            <div className="currencyListIconNameWrap">
                                                <div
                                                    className="currencyList_Name"
                                                    name="acquireRoutingServiceName"
                                                >
                                                    {item}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>

                    <div className="mt-4">
                        <label>
                            <b>{"Bespoke B2C Path"}</b>
                        </label>
                        <input
                            className={`create_account_input`}
                            type="text"
                            name="homeB2CPath"
                            disabled={data.homeB2C === "Disabled" ? true : false}
                            value={data["homeB2CPath"]}
                            placeholder="Bespoke B2C Path"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px", cursor: data.homeB2C === "Disabled" ? "not-allowed" : "allowed"}}
                        />
                    </div>

                    <div className="mt-4">
                        <label>
                            <b>{"Bespoke Merchant Path"}</b>
                        </label>
                        <input
                            className={`create_account_input`}
                            type="text"
                            name="homeMerchantPath"
                            disabled={data.homeMerchant === "Disabled" ? true : false}
                            value={data["homeMerchantPath"]}
                            placeholder="Bespoke Merchant Path"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px", cursor: data.homeMerchant === "Disabled" ? "not-allowed" : "allowed"}}
                        />
                    </div>

                    <div className="mt-4">
                        <label>
                            <b>{"Bespoke Client Path"}</b>
                        </label>
                        <input
                            className={`create_account_input`}
                            type="text"
                            name="homeClientPath"
                            disabled={data.homeClient === "Disabled" ? true : false}
                            value={data["homeClientPath"]}
                            placeholder="Bespoke Client Path"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px", cursor: data.homeClient === "Disabled" ? "not-allowed" : "allowed"}}
                        />
                    </div>

                    <div className="FilterModalInputGroup mt-4">
                        <label className="w-100">
                            <b>{"Default Landing Page"}</b>
                        </label>
                        <div
                            className="FilterModalInputDropdown"
                            onClick={() => {
                                setLandingPageDropDown(true);
                            }}
                        >
                            <div className="FilterModalInputText">
                                {data.defaultLandingPage}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {landingPageDropDown && (
                            <div
                                className="FilterModalCurrencyDropdownlist"
                                data-e2e="FilterModalCurrencyDropdownlist"
                            >
                                <div
                                    className="FM_currencyDrop_All"
                                    onClick={() => {
                                        setLandingPageDropDown(false);
                                    }}
                                >
                                    <span
                                        className="FM_currencyDrop_AllSelector"
                                        data-e2e="status"
                                    >
                                        {data.defaultLandingPage}
                                    </span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i
                                            data-e2e="dropDown"
                                            className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                        />
                                    </span>
                                </div>
                                {data.homeB2C === "Enabled" && <SingleDefaultLandingPageOption handleDefaultLandingPage={handleDefaultLandingPage} setLandingPageDropDown={setLandingPageDropDown} value="home-b2c" />}
                                {data.homeMerchant === "Enabled" && <SingleDefaultLandingPageOption handleDefaultLandingPage={handleDefaultLandingPage} setLandingPageDropDown={setLandingPageDropDown} value="home-merchant" />}
                                {data.homeClient === "Enabled" && <SingleDefaultLandingPageOption handleDefaultLandingPage={handleDefaultLandingPage} setLandingPageDropDown={setLandingPageDropDown} value="home-client" />}
                                {data.homeB2C === "Enabled" && data.homeB2CPath && data.homeB2CPath != "" && <SingleDefaultLandingPageOption handleDefaultLandingPage={handleDefaultLandingPage} setLandingPageDropDown={setLandingPageDropDown} value={data.homeB2CPath} />}
                                {data.homeMerchant === "Enabled" && data.homeMerchantPath && data.homeMerchantPath != "" && <SingleDefaultLandingPageOption handleDefaultLandingPage={handleDefaultLandingPage} setLandingPageDropDown={setLandingPageDropDown} value={data.homeMerchantPath} />}
                                {data.homeClient === "Enabled" && data.homeClientPath && data.homeClientPath != "" && <SingleDefaultLandingPageOption handleDefaultLandingPage={handleDefaultLandingPage} setLandingPageDropDown={setLandingPageDropDown} value={data.homeClientPath} />}
                            </div>
                        )}
                    </div>

                    <div className="FilterModalInputGroup mt-4">
                        <label className="w-100">
                            <b>{"Theme Toggle Button"}</b>
                        </label>
                        <div
                            className="FilterModalInputDropdown"
                            onClick={() => {
                                setThemeDisabledDropDown(true);
                            }}
                        >
                            <div className="FilterModalInputText">
                                {data.themeToggleButton}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                            />
                        </div>
                        {themeDisabledDropDown && (
                            <div
                                className="FilterModalCurrencyDropdownlist"
                                data-e2e="FilterModalCurrencyDropdownlist"
                            >
                                <div
                                    className="FM_currencyDrop_All"
                                    onClick={() => {
                                        setThemeDisabledDropDown(false);
                                    }}
                                >
                                    <span
                                        className="FM_currencyDrop_AllSelector"
                                        data-e2e="status"
                                    >
                                        {data.themeToggleButton}
                                    </span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i
                                            data-e2e="dropDown"
                                            className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                        />
                                    </span>
                                </div>
                                {themeOption.map((theme, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="currencyListItemWrap"
                                            name="blockchain"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleThemeToggleButtonChange(theme);
                                                setThemeDisabledDropDown(false)
                                            }}
                                        >
                                            <div className="currencyListIconNameWrap">
                                                <div
                                                    className="currencyList_Name"
                                                    name="acquireRoutingServiceName"
                                                >
                                                    {theme}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>

                    <br/><hr />

                    <div className="upload__merchantId">
                        <div className="FilterModalInputGroup">
                            <div className="FilterModalInputLabel update-merchant-label">
                                Upload Logo (only *png){" "}
                                <Tooltip title={"Ideal logo size\n(width: 250px, height: 50px)"} placement="top">
                                    <span>
                                        &nbsp;<i className="fa fa-info-circle" />
                                    </span>
                                </Tooltip>
                            </div>
                            {showLogoImage && <img className="ImagePreview" src={`${image_base_url}/payment-provider/${props.paymentProviderId}/${props.domainData !== null ? props.domainData._id : ""}/logo.png`} onError={() => {
                                console.log("image error")
                                setShowLogoImage(false)
                            }} alt="" />}
                            <div className="w-100" data-text="Choose File">
                                <input
                                    name="file-upload-field"
                                    type="file"
                                    data-max-size="5000000"
                                    accept="image/png"
                                    id="domainpaymentProviderLogo"
                                    value={logoImage ? logoImage.name : ""}
                                    onChange={(e) => handleImage(e, "paymentProviderLogo")}
                                    className="file-upload-field"
                                    style={{ borderRadius: "25px" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="upload__merchantId">
                        <div className="FilterModalInputGroup">
                            <div className="FilterModalInputLabel update-merchant-label">
                                Upload Favicon (only *png){" "}
                                <Tooltip title={"Ideal favicon size\n(width: 32px, height: 32px)"} placement="top">
                                    <span>
                                        &nbsp;<i className="fa fa-info-circle" />
                                    </span>
                                </Tooltip>
                            </div>
                            {showFaviconImage && <img className="ImagePreview" src={`${image_base_url}/payment-provider/${props.paymentProviderId}/${props.domainData !== null ? props.domainData._id : ""}/favicon.png`} onError={() => {
                                console.log("image error")
                                setShowFaviconImage(false)
                            }} alt="" />}
                            <div className="w-100" data-text="Choose File">
                                <input
                                    name="file-upload-field"
                                    type="file"
                                    data-max-size="5000000"
                                    accept="image/png"
                                    id="domainpaymentProviderFavicon"
                                    value={faviconImage ? faviconImage.name : ""}
                                    onChange={(e) => handleImage(e, "paymentProviderFavicon")}
                                    className="file-upload-field"
                                    style={{ borderRadius: "25px" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="upload__merchantId">
                        <div className="FilterModalInputGroup">
                            <div className="FilterModalInputLabel update-merchant-label">
                                Upload Logo Dark (only *png){" "}
                                <Tooltip title={"Ideal logo size\n(width: 250px, height: 50px)"} placement="top">
                                    <span>
                                        &nbsp;<i className="fa fa-info-circle" />
                                    </span>
                                </Tooltip>
                            </div>
                            {showLogoDarkImage && <img className="ImagePreview" src={`${image_base_url}/payment-provider/${props.paymentProviderId}/${props.domainData !== null ? props.domainData._id : ""}/logodark.png`} onError={() => {
                                console.log("image error")
                                setShowLogoDarkImage(false)
                            }} alt="" />}
                            <div className="w-100" data-text="Choose File">
                                <input
                                    name="file-upload-field"
                                    type="file"
                                    data-max-size="5000000"
                                    accept="image/png"
                                    id="domainpaymentProviderLogoDark"
                                    value={logoDarkImage ? logoDarkImage.name : ""}
                                    onChange={(e) => handleImage(e, "paymentProviderLogoDark")}
                                    className="file-upload-field"
                                    style={{ borderRadius: "25px" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <label>
                            <b>{"Logo Width (in px)"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="logoWidth"
                            value={data["logoWidth"]}
                            placeholder="Logo width"
                            onChange={(e) => handleChange(e)}
                            style={{ borderRadius: "25px" }}
                        />
                    </div>
                    <div className="upload__merchantId">
                        <div className="FilterModalInputGroup">
                            <div className="FilterModalInputLabel update-merchant-label">
                                Upload Background Image (only *png){" "}
                                <Tooltip title={"Ideal background image size\n(width: 1600px, height: 560px)"} placement="top">
                                    <span>
                                        &nbsp;<i className="fa fa-info-circle" />
                                    </span>
                                </Tooltip>
                            </div>
                            {showBGImage && <img className="ImagePreview" src={`${image_base_url}/payment-provider/${props.paymentProviderId}/${props.domainData !== null ? props.domainData._id : ""}/bg.png`} onError={() => {
                                console.log("image error")
                                setShowBGImage(false)
                            }} alt=""
                                style={{
                                    height: "100px",
                                    width: "180px"
                                }}
                            />}
                            <div className="w-100" data-text="Choose File">
                                <input
                                    name="file-upload-field"
                                    type="file"
                                    data-max-size="5000000"
                                    accept="image/png"
                                    id="domainpaymentProviderBG"
                                    value={bgImage ? bgImage.name : ""}
                                    onChange={(e) => handleImage(e, "paymentProviderBG")}
                                    className="file-upload-field"
                                    style={{ borderRadius: "25px" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="upload__merchantId">
                        <div className="FilterModalInputGroup">
                            <div className="FilterModalInputLabel update-merchant-label">
                                Upload Background Image Dark (only *png){" "}
                                <Tooltip title={"Ideal background image size\n(width: 1600px, height: 560px)"} placement="top">
                                    <span>
                                        &nbsp;<i className="fa fa-info-circle" />
                                    </span>
                                </Tooltip>
                            </div>
                            {showBGDarkImage && <img className="ImagePreview" src={`${image_base_url}/payment-provider/${props.paymentProviderId}/${props.domainData !== null ? props.domainData._id : ""}/bgdark.png`} onError={() => {
                                console.log("image error")
                                setShowBGDarkImage(false)
                            }} alt=""
                                style={{
                                    height: "100px",
                                    width: "180px"
                                }}
                            />}
                            <div className="w-100" data-text="Choose File">
                                <input
                                    name="file-upload-field"
                                    type="file"
                                    data-max-size="5000000"
                                    accept="image/png"
                                    id="domainpaymentProviderBGDark"
                                    value={bgDarkImage ? bgDarkImage.name : ""}
                                    onChange={(e) => handleImage(e, "paymentProviderBGDark")}
                                    className="file-upload-field"
                                    style={{ borderRadius: "25px" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="login_button_get_started">
                        <button className={disabled ? "bg-secondary" : ""} disabled={disabled} onClick={() => {
                            if (!disabled) {
                                handleSubmit(props.dialogtitle)
                            }
                        }
                        }>
                            {props.dialogtitle}
                        </button>
                    </div>
                </div>
            </div>
            {loading && <Loading />}
        </div>

    )
}

export default EditDomain;

const SingleDefaultLandingPageOption = (props) => {
    return (<div
        className="currencyListItemWrap"
        name="blockchain"
        onClick={(e) => {
            e.preventDefault();
            props.handleDefaultLandingPage(props.value);
            props.setLandingPageDropDown(false)
        }}
    >
        <div className="currencyListIconNameWrap">
            <div
                className="currencyList_Name"
                name="acquireRoutingServiceName"
            >
                {props.value}
            </div>
        </div>
    </div>)
}