import React, {useState} from 'react';
import {useSelector} from "react-redux";
import MediaQuery from "react-responsive";
import {getExchangeRate} from "./utils/helperFunctions";
import {UpdateWalletBalanceModal} from "./UpdateWalletBalanceModal";
import {UPDATE_WALLET_BALANCE} from "../../../../config/endpoints";
import {call} from "../../../../config/axios";
import localDb from "../../../../localDb";
import {useParams} from "react-router-dom";
import {BalanceTableDesktop} from "./BalanceTableDesktop";
import {BalanceTableMobile} from "./BalanceTableMobile";
import Loading from "../../../common/Loading";

const BalanceTable = ({
  data,
  setWalletDetailBtn,
  walletDetailBtn,
  setData,
}) => {
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const viewTransactionUrl = useSelector((state) => state.config.api_url);
  const [showEditBalanceModal, setShowEditBalanceModal] = useState(false);
  const [balanceToEdit, setBalanceToEdit] = useState(null);
  const [newBalance, setNewBalance] = useState("");
  const [balanceUpdateNote, setBalanceUpdateNote] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const email = localDb.getVal("email");
  const { id } = useParams();

  const handleEditBalance = (balance) => {
    setShowEditBalanceModal(!showEditBalanceModal);
    setBalanceToEdit(balance);
  }
  const saveNewBalanceValue = async (cryptoCurrency) => {
    try {
      setLoading(true)
      if (newBalance !== "") {
        const exchangeValue = await getExchangeRate(
          cryptoCurrency,
          "USD",
          newBalance,
          viewTransactionUrl
        );
        if (exchangeValue !== undefined) {
          const callObj = {
            ...UPDATE_WALLET_BALANCE,
            url: viewTransactionUrl + UPDATE_WALLET_BALANCE.url,
          };

          const res = await call(callObj, {
            email: email,
            newBalanceAmountInCrypto: Number(newBalance),
            oldBalanceAmountInCrypto: balanceToEdit.balanceAmount,
            newBalanceAmountInUSD: exchangeValue,
            balanceUpdateNote,
            balanceId: balanceToEdit._id,
            walletId: id,
          });

          if (res && res.data) {
            setLoading(false);
            setData(res.data.wallets);
            setBalanceUpdateNote("");
            setNewBalance("")
            setShowEditBalanceModal(false);
          }
        } else {
          setLoading(false);
          throw new Error("Invalid exchange value");
        }
      } else {
        setLoading(false);
        throw new Error("New balance in crypto is empty");
      }
    } catch (err) {
      setLoading(false);
      setError("Something went wrong while updating the wallet balance.");
    }
  };

  return (
    <div className="wallet_container pt-0">
      <div className="wallet_listing_page">
        <MediaQuery minWidth={768}>
          <BalanceTableDesktop
            data={data}
            imageBaseUrl={imageBaseUrl}
            handleEditBalance={handleEditBalance}
          />
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          {
            walletDetailBtn === "Wallets"
            && <BalanceTableMobile
              data={data}
              imageBaseUrl={imageBaseUrl}
              walletDetailBtn={walletDetailBtn}
              setWalletDetailBtn={setWalletDetailBtn}
              handleEditBalance={handleEditBalance}
            />
          }
        </MediaQuery>
        {
          showEditBalanceModal
          && <UpdateWalletBalanceModal
            balanceToEdit={balanceToEdit}
            setBalanceToEdit={setBalanceToEdit}
            setShowEditBalanceModal={setShowEditBalanceModal}
            newBalance={newBalance}
            setNewBalance={setNewBalance}
            balanceUpdateNote={balanceUpdateNote}
            setBalanceUpdateNote={setBalanceUpdateNote}
            error={error}
            saveNewBalanceValues={saveNewBalanceValue}
            imageBaseUrl={imageBaseUrl}

          />
        }
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default BalanceTable;
