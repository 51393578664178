import { Dialog, Tab, Tabs } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { call, logoutAfterError } from "../../../../../config/axios";
import Sidebar from "../../../../common/Sidebar";
import {
  ADD_INTEGRATION_TYPE,
  DELETE_CODE,
  DELETE_INTEGRATION_TYPE,
  EDIT_INTEGRATION_TYPE,
  GET_INTEGRATION_TYPE,
  GET_SINGLE_AQUIRER_SERVICE,
  TOGGLE_AQUIRER_SERVICE,
  TOGGLE_LOAD_BALANCING,
  UPDATE_AQUIRE_NAME,
  UPDATE_CODE,
  UPDATE_FRAUD_SERVICE,
} from "../../../../../config/endpoints";
import localDb from "../../../../../localDb";
import HeaderAndFooterLayout from "../../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../../CommonPages/CommonHeader";
import { getCountryCodesFromDb } from "../../../global/helpers";
import CardAcquirerTabs from "./CardAcquirerTabs";
const selectedCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_filled.png";
const emptyCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_empty.png";
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";

const acquirersIcon = "/cryptonpay/crytoicons/acquirers.png";
const amlIcon = "/cryptonpay/crytoicons/aml.png";
const integrationIcon = "/cryptonpay/crytoicons/integration.png";
const loadIcon = "/cryptonpay/crytoicons/load.png";
const routingIcon = "/cryptonpay/crytoicons/routing.png";
const rulesIcon = "/cryptonpay/crytoicons/rules.png";
const statusIcon = "/cryptonpay/crytoicons/status.png";
const trafficIcon = "/cryptonpay/crytoicons/traffic.png";
const splitTrafficIcon = "/cryptonpay/crytoicons/status.png";

import AcquirerTab from "./CardAquirerTabs/AcquirerTab";
import LoadBalance from "./CardAquirerTabs/LoadBalance";
import RulesTab from "./CardAquirerTabs/RulesTab";
import AMLRulesTab from "./CardAquirerTabs/AMLRulesTab";
import IntegrationType from "./CardAquirerTabs/IntegrationType";
import MaxTrafficTab from "./CardAquirerTabs/MaxTrafficTab";
import StatusTab from "./CardAquirerTabs/StatusTab";
import SuccessRoutingTab from "./CardAquirerTabs/SuccessRoutingTab";
import LeastCostRoutingTab from "./CardAquirerTabs/LeastCostRoutingTab";
import WeightingTab from "./CardAquirerTabs/WeightingTab";
import SplitTrafficTab from "./CardAquirerTabs/SplitTrafficTab";
import BlockEmailList from "./BlockEmailList";
import BlockCardList from "./BlockCardList";

let countryCodesData = [];
let merchentCodesData = [];
const cardSchemeData = [
  { name: "Visa" },
  { name: "Mastercard" },
  { name: "American Express" },
  { name: "UnionPay" },
  { name: "JCB" },
  { name: "Diners" },
];
let AllCurrenciesData = [{ name: "USD" }, { name: "EUR" }, { name: "GBP" }];
let AllMandatoryFieldsData = [
  { name: "ipAddress" },
  { name: "payerAddress" },
  { name: "payerState" },
  { name: "payerCountry" },
  { name: "payerCity" },
  { name: "payerZip" },
  { name: "payerPhone" },
  { name: "merchantUserEmail" },
  { name: "merchantTransactionId" },
  { name: "browser_timezone" },
  { name: "browser_color_depth" },
  { name: "browser_language" },
  { name: "browser_screen_height" },
  { name: "browser_screen_width" },
  { name: "os" },
  { name: "browserUserAgent" },
];
let aquireNameData = [];
const CardAquirerDetails = (props) => {
  const [showAddNewAntiFraudModal, setShowAddNewAntiFraudModal] =
    useState(false);
  const [showIntergartionModel, setShowIntergartionModel] = useState(false);
  const [showEditModel, setshowEditModel] = useState(false);
  const [editAquireService, setEditAquireService] = useState({});
  const [cardAquirer, setCardAquirer] = useState({});
  const [acquirerName, setAcquirerName] = useState("");
  const [deleteAquirerService, setDeleteAquirerService] = useState("");
  const [deleteAquirerServiceModel, setDeleteAquirerServiceModel] =
    useState(false);
  const [integrationName, setIntegrationName] = useState("");
  const [integrationType, setIntegrationType] = useState([]);
  const [deleteIntegrationType, setDeleteIntegrationType] = useState(false);
  const [deleteIntegrationTypeId, setDeleteIntegrationTypeId] = useState("");
  const [editIntegrationTypes, setEditIntegrationTypes] = useState("");
  const [editIntegrationTypesName, setEditIntegrationTypesName] = useState("");
  const [showEditModelIntegration, setShowEditModelIntegration] =
    useState(false);
  const [integrationTypeid, setIntegrationTypeid] = useState("");
  const [updatedData, setUpdatedData] = useState({});
  const [selectedAcquirer, setSelectedAcquirer] = useState("");
  const [editAquirerName, setEditAquirerName] = useState({});
  const [routingid, setRoutingid] = useState("");
  const [loading, setLoading] = useState(false);
  const [formStates, setFormStates] = useState({
    acquirerName: "",
    tokenType: "",
    siteReferenceName: "",
    username: "",
    pwd: "",
    jwtUsername: "",
    jwtPassword: "",
    livestatus: "",
    notes: "",
    errors: {},
  });
  const [editFormState, setEditFormState] = useState({
    acquirerName: "",
    tokenType: "",
    siteReferenceName: "",
    username: "",
    pwd: "",
    jwtUsername: "",
    jwtPassword: "",
    livestatus: "",
    notes: "",
    errors: {},
  });
  const [error, setError] = useState("");
  const { id } = useParams();
  const imageBaseURL = useSelector((state) => state.config.image_base_url);
  const baseURL = useSelector((state) => state.config.api_url);
  const [value, setValue] = useState(4);

  // Validation for adding
  const validateForm = () => {
    let errors = {};
    if (!acquirerName) {
      errors.aquiredName = "integrationType is required";
    }
    if (!formStates.acquirerName) {
      errors.acquirerName = "acquirerName is required";
    }
    if (!formStates.tokenType.trim()) {
      errors.tokenType = "tokenTypee is required";
    }
    if (!formStates.siteReferenceName.trim()) {
      errors.siteReferenceName = "siteReferenceName is required";
    }
    if (!formStates.username.trim()) {
      errors.username = "username is required";
    }
    if (!formStates.pwd.trim()) {
      errors.pwd = "pwd is Required";
    }
    if (!formStates.jwtUsername.trim()) {
      errors.jwtUsername = "jwtUsername is required";
    }
    if (!formStates.jwtPassword.trim()) {
      errors.jwtPassword = "jwtPassword is required";
    }
    if (!formStates.livestatus.trim()) {
      errors.livestatus = "livestatus is Required";
    }
    return errors;
  };
  useEffect(() => {
    async function fetchData() {
      const countryCode = await getCountryCodesFromDb(baseURL);
      countryCodesData = countryCode;
      merchentCodesData = countryCode;
    }
    fetchData();
  }, []);

  const updateCardRoutingService = async (amlRulesData) => {
    const payload = {
      email: localDb.getVal("email"),
      id: id,
      dailyTransactionsLimitForAUser:
        amlRulesData.dailyTransactionsLimitForAUser
          ? amlRulesData.dailyTransactionsLimitForAUser
          : null,
      weeklyTransactionsLimitForAUser:
        amlRulesData.weeklyTransactionsLimitForAUser
          ? amlRulesData.weeklyTransactionsLimitForAUser
          : null,
      monthlyTransactionsLimitForAUser:
        amlRulesData.monthlyTransactionsLimitForAUser
          ? amlRulesData.monthlyTransactionsLimitForAUser
          : null,
      maximumUSDAmountLimitForATransaction:
        amlRulesData.maximumUSDAmountLimitForATransaction
          ? amlRulesData.maximumUSDAmountLimitForATransaction
          : null,
      minimumUSDAmountLimitForATransaction:
        amlRulesData.minimumUSDAmountLimitForATransaction
          ? amlRulesData.minimumUSDAmountLimitForATransaction
          : null,
      totalDailyUSDAmountLimitForAUser:
        amlRulesData.totalDailyUSDAmountLimitForAUser
          ? amlRulesData.totalDailyUSDAmountLimitForAUser
          : null,
      totalDailyUSDAmountLimitForPlatform:
        amlRulesData.totalDailyUSDAmountLimitForPlatform
          ? amlRulesData.totalDailyUSDAmountLimitForPlatform
          : null,
      totalWeeklyUSDAmountLimitForAUser:
        amlRulesData.totalWeeklyUSDAmountLimitForAUser
          ? amlRulesData.totalWeeklyUSDAmountLimitForAUser
          : null,
      totalMonthlyUSDAmountLimitForAUser:
        amlRulesData.totalMonthlyUSDAmountLimitForAUser
          ? amlRulesData.totalMonthlyUSDAmountLimitForAUser
          : null,
      totalUniqueCardsForUser: amlRulesData.totalUniqueCardsForUser
        ? amlRulesData.totalUniqueCardsForUser
        : null,
      limitUserCardAfterTransectionFailed:
        amlRulesData.limitUserCardAfterTransectionFailed
          ? amlRulesData.limitUserCardAfterTransectionFailed
          : null,
      removeUnusedCardsAfterDays: amlRulesData.removeUnusedCardsAfterDays
        ? amlRulesData.removeUnusedCardsAfterDays
        : null,
    };
    try {
      const res = await call(
        {
          ...UPDATE_FRAUD_SERVICE,
          url: baseURL + UPDATE_FRAUD_SERVICE.url,
        },
        payload,
      );

      if (res.status === 200) {
        setUpdatedData(res.data);
      }
    } catch (err) {
      if (err.response && err.response.status === 406) {
        setError(err.response.data.msg);
      } else {
        setError("Something went wrong. Please check.");
      }
      logoutAfterError(err);
      console.log(err);
    }
  };

  const handleAddNewAquirer = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const payload = {
        email: localDb.getVal("email"),
        id: id,
        acquireByName: formStates.acquirerName ? formStates.acquirerName : null,
        integrationType: integrationTypeid,
        tokenType: formStates.tokenType ? formStates.tokenType : null,
        siteReferenceName: formStates.siteReferenceName
          ? formStates.siteReferenceName
          : null,
        username: formStates.username ? formStates.username : null,
        pwd: formStates.pwd ? formStates.pwd : null,
        jwtUsername: formStates.jwtUsername ? formStates.jwtUsername : null,
        jwtPassword: formStates.jwtPassword ? formStates.jwtPassword : null,
        livestatus: formStates.livestatus ? formStates.livestatus : null,
        notes: formStates.notes ? formStates.notes : null,
      };
      try {
        const res = await call(
          {
            ...UPDATE_FRAUD_SERVICE,
            url: baseURL + UPDATE_FRAUD_SERVICE.url,
          },
          payload,
        );

        if (res.status === 200) {
          setUpdatedData(res.data);
          setFormStates({
            acquirerName: "",
            tokenType: "",
            siteReferenceName: "",
            username: "",
            pwd: "",
            jwtUsername: "",
            jwtPassword: "",
            livestatus: "",
            notes: "",
            errors: {},
          });
          setShowAddNewAntiFraudModal(false);
        }
      } catch (err) {
        if (err.response && err.response.status === 406) {
          setError(err.response.data.msg);
        } else {
          setError("Something went wrong. Please check.");
        }
        logoutAfterError(err);
        console.log(err);
      }
    } else {
      setFormStates({ ...formStates, errors: errors });
    }
  };
  const handleChange = (event) => {
    const data = JSON.parse(event.target.value);
    setAcquirerName(data.acquireName);
    setIntegrationTypeid(data._id);
    setSelectedAcquirer(event.target.value);
  };
  const handleChangeIntegration = (event) => {
    setIntegrationName(event.target.value);
  };
  useEffect(() => {
    getSingleAquireService();
  }, [deleteAquirerService, updatedData, value, editAquireService]);
  useEffect(() => {
    getAllIntegration();
  }, [deleteIntegrationTypeId]);

  const getSingleAquireService = async () => {
    try {
      const payload = {
        email: localDb.getVal("email"),
        id: id,
      };
      setLoading(true);
      const response = await call(
        {
          ...GET_SINGLE_AQUIRER_SERVICE,
          url: baseURL + GET_SINGLE_AQUIRER_SERVICE.url,
        },
        payload,
      );
      if (response.status === 200) {
        setCardAquirer(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      logoutAfterError(error);
      console.error(error);
    }
  };

  const handleDeleteAquireService = async () => {
    try {
      const payload = {
        email: localDb.getVal("email"),
        id: id,
        codeid: deleteAquirerService,
      };
      const response = await call(
        {
          ...DELETE_CODE,
          url: baseURL + DELETE_CODE.url,
        },
        payload,
      );

      if (response.status === 200) {
        setDeleteAquirerService("");
      }
    } catch (error) {
      logoutAfterError(error);
      console.error(error);
    }
  };

  // handle Edit Aquirer Service
  const validateEditForm = () => {
    let errors = {};
    if (!editFormState.tokenType.trim()) {
      errors.tokenType = "tokenTypee is required";
    }
    if (!editFormState.acquirerName) {
      errors.acquirerName = "acquirerName is required";
    }
    if (!editFormState.siteReferenceName.trim()) {
      errors.siteReferenceName = "siteReferenceName is required";
    }
    if (!editFormState.username.trim()) {
      errors.username = "username is required";
    }
    if (!editFormState.pwd.trim()) {
      errors.pwd = "pwd is Required";
    }
    if (!editFormState.jwtUsername.trim()) {
      errors.jwtUsername = "jwtUsername is required";
    }
    if (!editFormState.jwtPassword.trim()) {
      errors.jwtPassword = "jwtPassword is required";
    }
    if (!editFormState.livestatus.trim()) {
      errors.livestatus = "livestatus is Required";
    }
    return errors;
  };

  const handleEditAquirer = async () => {
    const errors = validateEditForm();
    if (Object.keys(errors).length === 0) {
      const payload = {
        email: localDb.getVal("email"),
        id: id,
        codeid: routingid,
        integrationType: editAquireService._id
          ? editAquireService._id
          : JSON.parse(selectedAcquirer)._id,
        aquiredName: editFormState.acquirerName
          ? editFormState.acquirerName
          : null,
        tokenType: editFormState.tokenType ? editFormState.tokenType : null,
        siteReferenceName: editFormState.siteReferenceName
          ? editFormState.siteReferenceName
          : null,
        username: editFormState.username ? editFormState.username : null,
        pwd: editFormState.pwd ? editFormState.pwd : null,
        jwtUsername: editFormState.jwtUsername
          ? editFormState.jwtUsername
          : null,
        jwtPassword: editFormState.jwtPassword
          ? editFormState.jwtPassword
          : null,
        livestatus: editFormState.livestatus ? editFormState.livestatus : null,
        notes: editFormState.notes ? editFormState.notes : null,
      };
      try {
        const res = await call(
          {
            ...UPDATE_AQUIRE_NAME,
            url: baseURL + UPDATE_AQUIRE_NAME.url,
          },
          payload,
        );

        if (res.status === 200) {
          setEditAquireService({});
          setEditFormState({});
          setshowEditModel(false);
        }
      } catch (err) {
        logoutAfterError(err);
        console.log(err);
      }
    } else {
      setEditFormState({ ...editFormState, errors: errors });
    }
  };
  const handletoggleActiveAquirerService = async (payload) => {
    let data = payload;
    data = {
      ...data,
      email: localDb.getVal("email"),
    };
    try {
      await call(
        {
          ...TOGGLE_AQUIRER_SERVICE,
          url: baseURL + TOGGLE_AQUIRER_SERVICE.url,
        },
        data,
      );
    } catch (error) {
      logoutAfterError(error);
      console.error("Error:", error);
    }
  };
  const handletoggleLoadBalancing = async (payload) => {
    let data = payload;
    data = {
      ...data,
      email: localDb.getVal("email"),
    };
    try {
      await call(
        {
          ...TOGGLE_LOAD_BALANCING,
          url: baseURL + TOGGLE_LOAD_BALANCING.url,
        },
        data,
      );
    } catch (error) {
      logoutAfterError(error);
      console.error("Error:", error);
    }
  };
  const handletoggleActiveCountryCode = async (payload) => {
    let data = {
      ...payload,
      email: localDb.getVal("email"),
    };

    try {
      await call(
        {
          ...TOGGLE_AQUIRER_SERVICE,
          url: baseURL + TOGGLE_AQUIRER_SERVICE.url,
        },
        data,
      );
    } catch (error) {
      logoutAfterError(error);
      console.error("Error:", error);
    }
  };

  //Get all Integrations Types
  const getAllIntegration = async () => {
    try {
      const res = await call(
        {
          ...GET_INTEGRATION_TYPE,
          url: baseURL + GET_INTEGRATION_TYPE.url,
        },
        {
          email: localDb.getVal("email"),
        },
      );

      if (res.status === 200) {
        setIntegrationType(res.data);
        aquireNameData = res.data;
      }
    } catch (error) {
      logoutAfterError(error);
      console.error("Error:", error);
    }
  };

  // handle Add Integation Type
  const handleAddIntegartionType = async () => {
    try {
      const payload = {
        email: localDb.getVal("email"),
        acquirerName: integrationName,
      };

      const res = await call(
        {
          ...ADD_INTEGRATION_TYPE,
          url: baseURL + ADD_INTEGRATION_TYPE.url,
        },
        payload,
      );

      if (res.status === 200) {
        setShowIntergartionModel(false);
        setIntegrationName("");
        await getAllIntegration();
      }
    } catch (error) {
      logoutAfterError(error);
      console.error("Error:", error);
    }
  };

  //Delete one integration type
  const deleteOneIntegration = async () => {
    try {
      const payload = {
        email: localDb.getVal("email"),
        acquirerId: deleteIntegrationTypeId,
        acquirerName: editIntegrationTypesName,
      };

      const res = await call(
        {
          ...DELETE_INTEGRATION_TYPE,
          url: baseURL + DELETE_INTEGRATION_TYPE.url,
        },
        payload,
      );

      if (res.status === 200) {
        setDeleteIntegrationTypeId("");
        await getAllIntegration();
      }
    } catch (error) {
      logoutAfterError(error);
      console.error("Error:", error);
    }
  };

  //Edit one integration type

  const editOneIntegration = async () => {
    try {
      const payload = {
        email: localDb.getVal("email"),
        acquirerId: editIntegrationTypes,
        acquirerName: editIntegrationTypesName,
      };

      const res = await call(
        {
          ...EDIT_INTEGRATION_TYPE,
          url: baseURL + EDIT_INTEGRATION_TYPE.url,
        },
        payload,
      );

      if (res.status === 200) {
        setEditIntegrationTypes("");
        setEditIntegrationTypesName("");
        await getAllIntegration();
        setShowEditModelIntegration(false);
      }
    } catch (error) {
      logoutAfterError(error);
      console.error("Error:", error);
    }
  };

  const onClickAcquirerName = (e) => {
    e.preventDefault();
    setError("");
    setFormStates({
      ...formStates,
      acquirerName: e.target.value,
      errors: {},
    });
  };
  const onClickTokenType = (e) => {
    e.preventDefault();
    setFormStates({
      ...formStates,
      tokenType: e.target.value,
      errors: {},
    });
  };
  const onClickSiteReferenceName = (e) => {
    e.preventDefault();
    setFormStates({
      ...formStates,
      siteReferenceName: e.target.value,
      errors: {},
    });
  };
  const onClickUsername = (e) => {
    e.preventDefault();
    setFormStates({
      ...formStates,
      username: e.target.value,
      errors: {},
    });
  };
  const onClickPwd = (e) => {
    e.preventDefault();
    setFormStates({
      ...formStates,
      pwd: e.target.value,
      errors: {},
    });
  };
  const onClickJwtUsername = (e) => {
    e.preventDefault();
    setFormStates({
      ...formStates,
      jwtUsername: e.target.value,
      errors: {},
    });
  };
  const onClickJwtPassword = (e) => {
    e.preventDefault();
    setFormStates({
      ...formStates,
      jwtPassword: e.target.value,
      errors: {},
    });
  };
  const onClickLiveStatus = (e) => {
    e.preventDefault();
    setFormStates({
      ...formStates,
      livestatus: e.target.value,
      errors: {},
    });
  };
  const onClickNotes = (e) => {
    e.preventDefault();
    setFormStates({
      ...formStates,
      notes: e.target.value,
    });
  };
  const onClickAcquirerNameEdit = (e) => {
    e.preventDefault();
    setEditFormState({
      ...editFormState,
      acquirerName: e.target.value,
      errors: {},
    });
  };
  const onClickTokenTypeEdit = (e) => {
    e.preventDefault();
    setEditFormState({
      ...editFormState,
      tokenType: e.target.value,
      errors: {},
    });
  };
  const onClickSiteReferenceNameEdit = (e) => {
    e.preventDefault();
    setEditFormState({
      ...editFormState,
      siteReferenceName: e.target.value,
      errors: {},
    });
  };
  const onClickUsernameEdit = (e) => {
    e.preventDefault();
    setEditFormState({
      ...editFormState,
      username: e.target.value,
      errors: {},
    });
  };
  const onClickPwdEdit = (e) => {
    e.preventDefault();
    setEditFormState({
      ...editFormState,
      pwd: e.target.value,
      errors: {},
    });
  };
  const onClickJwtUsernameEdit = (e) => {
    e.preventDefault();
    setEditFormState({
      ...editFormState,
      jwtUsername: e.target.value,
      errors: {},
    });
  };
  const onClickJwtPasswordEdit = (e) => {
    e.preventDefault();
    setEditFormState({
      ...editFormState,
      jwtPassword: e.target.value,
      errors: {},
    });
  };
  const onClickLiveStatusEdit = (e) => {
    e.preventDefault();
    setEditFormState({
      ...editFormState,
      livestatus: e.target.value,
      errors: {},
    });
  };
  const onClickNotesEdit = (e) => {
    e.preventDefault();
    setEditFormState({
      ...editFormState,
      notes: e.target.value,
      errors: {},
    });
  };

  const tabNames = [
    {
      name: "Integration",
      image: imageBaseURL + integrationIcon,
      value: 4,
    },
    {
      name: "Acquirers",
      image: imageBaseURL + acquirersIcon,
      value: 1,
    },
    {
      name: "Rules",
      image: imageBaseURL + rulesIcon,
      value: 2,
    },
    {
      name: "Split Traffic",
      image: imageBaseURL + splitTrafficIcon,
      value: 11,
    },
  ];
  const tabWithSubTabsNames = [
    {
      name: "AML Rules",
      image: imageBaseURL + amlIcon,
      subTabs: [
        {
          name: "Blocked Email List",
          image: imageBaseURL + loadIcon,
          value: 12,
        },
        {
          name: "Rules",
          image: imageBaseURL + trafficIcon,
          value: 5,
        },
        {
          name: "Blocked Card List",
          image: imageBaseURL + statusIcon,
          value: 13,
        },
      ],
    },
    {
      name: "Balance",
      image: imageBaseURL + loadIcon,
      subTabs: [
        {
          name: "Load Balancing",
          image: imageBaseURL + loadIcon,
          value: 3,
        },
        {
          name: "Maximum Traffic",
          image: imageBaseURL + trafficIcon,
          value: 6,
        },
        {
          name: "Status",
          image: imageBaseURL + statusIcon,
          value: 7,
        },
        {
          name: "Success Routing",
          image: imageBaseURL + routingIcon,
          value: 8,
        },
        {
          name: "LeastCost Routing",
          image: imageBaseURL + routingIcon,
          value: 9,
        },
        {
          name: "Weighting",
          image: imageBaseURL + routingIcon,
          value: 10,
        },
      ],
    },
  ];

  const onSelectTab = (val) => {
    setValue(val);
  };

  return (
    <HeaderAndFooterLayout>
      <CommonHeader
        name={cardAquirer.acquireRoutingServiceName}
        backButton={true}
      />
      {cardAquirer ? (
        <div className="cardAcquirerRouting container">
          <div className="flex flex-col md:flex-row">
            <div className="mb-4 mt-12 md:mb-0 md:mt-0">
              <Sidebar
                tabNames={tabNames}
                tabWithSubTabsNames={tabWithSubTabsNames}
                onSelectTab={onSelectTab}
              />
            </div>
            {value === 1 ? (
              <AcquirerTab
                setRoutingid={setRoutingid}
                setSelectedAcquirer={setSelectedAcquirer}
                setEditAquirerName={setEditAquirerName}
                editFormState={editFormState}
                setEditFormState={setEditFormState}
                handletoggleActiveAquirerService={
                  handletoggleActiveAquirerService
                }
                setAcquirerName={setAcquirerName}
                setEditAquireService={setEditAquireService}
                setshowEditModel={setshowEditModel}
                setDeleteAquirerServiceModel={setDeleteAquirerServiceModel}
                setDeleteAquirerService={setDeleteAquirerService}
                acquireService={cardAquirer}
                setShowAddNewAntiFraudModal={setShowAddNewAntiFraudModal}
              />
            ) : null}
            {value === 2 ? (
              <RulesTab
                handletoggleActiveCountryCode={handletoggleActiveCountryCode}
                acquireService={cardAquirer}
                countryCodesData={countryCodesData}
                merchentCodesData={merchentCodesData}
                cardSchemeData={cardSchemeData}
                AllCurrenciesData={AllCurrenciesData}
                AllMandatoryFieldsData={AllMandatoryFieldsData}
                getSingleAquireService={getSingleAquireService}
                loading={loading}
              />
            ) : null}
            {value === 3 ? (
              <LoadBalance
                acquireService={cardAquirer}
                handletoggleLoadBalancing={handletoggleLoadBalancing}
              />
            ) : null}
            {value === 4 ? (
              <IntegrationType
                setEditIntegrationTypes={setEditIntegrationTypes}
                setEditIntegrationTypesName={setEditIntegrationTypesName}
                setShowEditModelIntegration={setShowEditModelIntegration}
                setDeleteIntegrationType={setDeleteIntegrationType}
                setDeleteIntegrationTypeId={setDeleteIntegrationTypeId}
                integrationType={integrationType}
                setShowIntergartionModel={setShowIntergartionModel}
                showIntergartionModel={showIntergartionModel}
                handleClose={() => setShowIntergartionModel(false)}
              />
            ) : null}
            {value === 5 ? (
              <AMLRulesTab
                acquirerServiceData={cardAquirer}
                updateCardRoutingService={updateCardRoutingService}
              />
            ) : null}
            {value === 6 ? (
              <MaxTrafficTab
                acquireService={cardAquirer}
                handletoggleActiveCountryCode={handletoggleActiveCountryCode}
                acquirerName={acquirerName}
                routingId={routingid}
              />
            ) : null}
            {value === 11 ? (
              <SplitTrafficTab
                acquireService={cardAquirer}
                handletoggleActiveCountryCode={handletoggleActiveCountryCode}
                acquirerName={acquirerName}
                routingId={routingid}
              />
            ) : null}
            {value === 7 ? <StatusTab acquireService={cardAquirer} /> : null}
            {value === 8 ? (
              <SuccessRoutingTab acquireService={cardAquirer} />
            ) : null}
            {value === 9 ? (
              <LeastCostRoutingTab
                acquireService={cardAquirer}
                handletoggleActiveCountryCode={handletoggleActiveCountryCode}
                acquirerName={acquirerName}
                routingId={routingid}
              />
            ) : null}
            {value === 10 ? (
              <WeightingTab
                acquireService={cardAquirer}
                handletoggleActiveCountryCode={handletoggleActiveCountryCode}
                acquirerName={acquirerName}
                routingId={routingid}
              />
            ) : null}
            {value === 12 ? (
              <BlockEmailList acquireService={cardAquirer} />
            ) : null}
            {value === 13 ? (
              <BlockCardList acquireService={cardAquirer} />
            ) : null}
          </div>
          {/* Add New Fraud Service */}
          <Dialog
            disableEnforceFocus
            open={showAddNewAntiFraudModal}
            onClose={() => {
              setShowAddNewAntiFraudModal(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
            scroll="body"
            disableScrollLock
          >
            <div>
              <div>
                <div className="myAccoutDetailsMain">
                  <div className="myAccoutDetailsCard">
                    <div className="d-flex justify-content-between">
                      <h5 className="myAccoutOptModalHeading card-title text-center">
                        Add Acquirer{" "}
                      </h5>
                      <div
                        className="cursor-pointer"
                        onClick={() => setShowAddNewAntiFraudModal(false)}
                      >
                        <ArrowBackIcon size={24} />
                      </div>
                    </div>
                    <form>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Acquirer Name
                        </label>
                        <input
                          type="text"
                          value={formStates.acquirerName}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Acquirer Name"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickAcquirerName(e);
                          }}
                        />
                        {formStates.errors && formStates.errors.acquirerName ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {formStates.errors.acquirerName}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Integration Type
                        </label>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={selectedAcquirer}
                          displayEmpty
                          onChange={(event) => {
                            handleChange(event);
                          }}
                          style={{
                            width: "385px",
                            boxSizing: "border-box",
                            height: "40px",
                            border: "1px solid #8A8A8A",
                            borderRadius: "20px",
                            cursor: "pointer",
                            backgroundColor: "#FFFFFF",
                          }}
                          className="from-control inputdropdown"
                        >
                          <MenuItem disabled value="">
                            <em>Select Integration Type</em>
                          </MenuItem>
                          {aquireNameData.map((item, i) => (
                            <MenuItem
                              key={i + item.acquireName}
                              value={JSON.stringify(item)}
                              name={item.acquireName}
                            >
                              {item.acquireName}
                            </MenuItem>
                          ))}
                        </Select>
                        {formStates.errors && formStates.errors.aquiredName ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {formStates.errors.aquiredName}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Token Type
                        </label>
                        <input
                          type="text"
                          value={formStates.tokenType}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Token Type"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickTokenType(e);
                          }}
                        />
                        {formStates.errors && formStates.errors.tokenType ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {formStates.errors.tokenType}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Site Reference Name
                        </label>
                        <input
                          type="text"
                          value={formStates.siteReferenceName}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Site Reference Name"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickSiteReferenceName(e);
                          }}
                        />
                        {formStates.errors &&
                        formStates.errors.siteReferenceName ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {formStates.errors.siteReferenceName}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Username
                        </label>
                        <input
                          type="text"
                          value={formStates.username}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Username"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickUsername(e);
                          }}
                        />
                        {formStates.errors && formStates.errors.username ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {formStates.errors.username}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Pwd
                        </label>
                        <input
                          type="text"
                          value={formStates.pwd}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Pwd"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickPwd(e);
                          }}
                        />
                        {formStates.errors && formStates.errors.pwd ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {formStates.errors.pwd}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Jwt Username
                        </label>
                        <input
                          type="text"
                          value={formStates.jwtUsername}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Jwt Username"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickJwtUsername(e);
                          }}
                        />
                        {formStates.errors && formStates.errors.jwtUsername ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {formStates.errors.jwtUsername}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Jwt Password
                        </label>
                        <input
                          type="text"
                          value={formStates.jwtPassword}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Jwt Password"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickJwtPassword(e);
                          }}
                        />
                        {formStates.errors && formStates.errors.jwtPassword ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {formStates.errors.jwtPassword}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Live Status
                        </label>
                        <input
                          type="text"
                          value={formStates.livestatus}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Live Status"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickLiveStatus(e);
                          }}
                        />
                        {formStates.errors && formStates.errors.livestatus ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {formStates.errors.livestatus}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Notes
                        </label>
                        <input
                          type="text"
                          value={formStates.notes}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Notes"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickNotes(e);
                          }}
                        />
                      </div>
                      <div className="my-3 text-red-500">{error}</div>

                      <div className="d-flex justify-content-center">
                        <div
                          className="submit"
                          onClick={() => {
                            handleAddNewAquirer();
                          }}
                        >
                          Add
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          {/* Delete Model Service */}
          <DeletingAlert
            deletingId={deleteAquirerService}
            onHide={() => {
              setDeleteAquirerService("");
            }}
            image_base_url={imageBaseURL}
            msg="Are you sure you want to delete selected setting? This action cannot be undone."
            onDeleteClickHandler={() =>
              handleDeleteAquireService(deleteAquirerService)
            }
          />
          {/* Edit Model Service */}
          <Dialog
            disableEnforceFocus
            open={showEditModel}
            onClose={() => {
              setAcquirerName("");
              setSelectedAcquirer("");
              setEditFormState({});
              setshowEditModel(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
            scroll="body"
            disableScrollLock
          >
            <div>
              <div>
                <div className="myAccoutDetailsMain">
                  <div className="myAccoutDetailsCard">
                    <div className="d-flex justify-content-between">
                      <h5 className="myAccoutOptModalHeading card-title text-center">
                        Edit Acquirer{" "}
                      </h5>
                      <div
                        onClick={() => {
                          setAcquirerName("");
                          setshowEditModel(false);
                        }}
                      >
                        <ArrowBackIcon size={24} />
                      </div>
                    </div>
                    <form>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Acquirer Name
                        </label>
                        <input
                          type="text"
                          value={
                            editFormState.acquirerName
                              ? editFormState.acquirerName
                              : ""
                          }
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Aquirer Name"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickAcquirerNameEdit(e);
                          }}
                        />
                        {editFormState.errors &&
                        editFormState.errors.acquirerName ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {editFormState.errors.acquirerName}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Integration Type
                        </label>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={selectedAcquirer}
                          onChange={(event) => {
                            let data = JSON.parse(event.target.value);
                            setSelectedAcquirer(event.target.value);
                            setAcquirerName(data.acquireName);
                            setEditAquireService(data);
                          }}
                          style={{
                            width: "385px",
                            boxSizing: "border-box",
                            height: "40px",
                            border: "1px solid #8A8A8A",
                            borderRadius: "20px",
                            cursor: "pointer",
                            backgroundColor: "#FFFFFF",
                          }}
                          className="from-control inputdropdown"
                          defaultValue={editAquireService.acquireName}
                        >
                          <MenuItem disabled value="">
                            <em>Select Aquirer Name</em>
                          </MenuItem>
                          {aquireNameData.map((item, i) => (
                            <MenuItem
                              key={i + item.acquireName}
                              value={JSON.stringify(item)}
                              name={item.acquireName}
                            >
                              {item.acquireName}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Token Type
                        </label>
                        <input
                          type="text"
                          value={editFormState.tokenType}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Fraud Service Name"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickTokenTypeEdit(e);
                          }}
                        />
                        {editFormState.errors &&
                        editFormState.errors.tokenType ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {editFormState.errors.tokenType}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Site Reference Name
                        </label>
                        <input
                          type="text"
                          value={editFormState.siteReferenceName}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Fraud Service Name"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickSiteReferenceNameEdit(e);
                          }}
                        />
                        {editFormState.errors &&
                        editFormState.errors.siteReferenceName ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {editFormState.errors.siteReferenceName}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Username
                        </label>
                        <input
                          type="text"
                          value={editFormState.username}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Fraud Service Name"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickUsernameEdit(e);
                          }}
                        />
                        {editFormState.errors &&
                        editFormState.errors.username ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {editFormState.errors.username}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Pwd
                        </label>
                        <input
                          type="text"
                          value={editFormState.pwd}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Fraud Service Name"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickPwdEdit(e);
                          }}
                        />
                        {editFormState.errors && editFormState.errors.pwd ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {editFormState.errors.pwd}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Jwt Username
                        </label>
                        <input
                          type="text"
                          value={editFormState.jwtUsername}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Fraud Service Name"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickJwtUsernameEdit(e);
                          }}
                        />
                        {editFormState.errors &&
                        editFormState.errors.jwtUsername ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {editFormState.errors.jwtUsername}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Jwt Password
                        </label>
                        <input
                          type="text"
                          value={editFormState.jwtPassword}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Fraud Service Name"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickJwtPasswordEdit(e);
                          }}
                        />
                        {editFormState.errors &&
                        editFormState.errors.jwtPassword ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {editFormState.errors.jwtPassword}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Live Status
                        </label>
                        <input
                          type="text"
                          value={editFormState.livestatus}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Fraud Service Name"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickLiveStatusEdit(e);
                          }}
                        />
                        {editFormState.errors &&
                        editFormState.errors.livestatus ? (
                          <div className="my-2 w-full text-left text-red-600">
                            {editFormState.errors.livestatus}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Notes
                        </label>
                        <input
                          type="text"
                          value={editFormState.notes}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Fraud Service Name"
                          name="fraudServiceName"
                          onChange={(e) => {
                            onClickNotesEdit(e);
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center">
                        <div
                          className="submit"
                          onClick={() => {
                            handleEditAquirer();
                          }}
                        >
                          Edit
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          {/* Add Integration Model */}
          <Dialog
            open={showIntergartionModel}
            onClose={() => {
              setShowIntergartionModel(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
          >
            <div>
              <div>
                <div className="myAccoutDetailsMain">
                  <div className="myAccoutDetailsCard">
                    <div className="d-flex justify-content-between">
                      <h5 className="myAccoutOptModalHeading card-title text-center">
                        Add Integration{" "}
                      </h5>
                      <div
                        className="cursor-pointer"
                        onClick={() => setShowIntergartionModel(false)}
                      >
                        <ArrowBackIcon size={24} />
                      </div>
                    </div>
                    <form>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Integration Name
                        </label>
                        {/* <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={integrationName}
                                                displayEmpty
                                                onChange={(event) => {
                                                    // handleChange(event)
                                                    setIntegrationName(event.target.value);
                                                }}
                                                style={{ width: "385px", boxSizing: "border-box", height: "40px", border: "1px solid #8A8A8A", borderRadius: "20px", cursor: "pointer", backgroundColor: "#FFFFFF" }}
                                                className="from-control inputdropdown"
                                            >
                                                <MenuItem disabled value="">
                                                    <em>Select Integration Type</em>
                                                </MenuItem>
                                                {IntegartionTypeNameSelect.map((item) => (
                                                    <MenuItem value={item} name={item} >{item}</MenuItem>
                                                ))}

                                            </Select> */}
                        <input
                          type="text"
                          value={integrationName}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Integration Type Name"
                          name="fraudServiceName"
                          onChange={(e) => {
                            e.preventDefault();
                            handleChangeIntegration(e);
                          }}
                        />
                      </div>

                      <div className="d-flex justify-content-center">
                        <div
                          className="submit"
                          onClick={() => {
                            handleAddIntegartionType();
                          }}
                        >
                          Add
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          {/* edit Integration model  */}
          <Dialog
            open={showEditModelIntegration}
            onClose={() => {
              setEditIntegrationTypesName("");
              setShowEditModelIntegration(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
          >
            <div>
              <div>
                <div className="myAccoutDetailsMain">
                  <div className="myAccoutDetailsCard">
                    <div className="d-flex justify-content-between">
                      <h5 className="myAccoutOptModalHeading card-title text-center">
                        Edit Integration{" "}
                      </h5>
                      <div
                        onClick={() => {
                          setEditIntegrationTypesName("");
                          setShowEditModelIntegration(false);
                        }}
                      >
                        <ArrowBackIcon size={24} />
                      </div>
                    </div>
                    <form>
                      <div className="form-group myAccoutOptModal_form_group">
                        <label
                          className="myAccoutOptModalLable"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Integration type Name
                        </label>
                        {/* <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={editIntegrationTypesName}
                                                displayEmpty
                                                onChange={(event) => {
                                                    // handleChange(event)
                                                    setEditIntegrationTypesName(event.target.value);
                                                }}
                                                style={{ width: "385px", boxSizing: "border-box", height: "40px", border: "1px solid #8A8A8A", borderRadius: "20px", cursor: "pointer", backgroundColor: "#FFFFFF" }}
                                                className="from-control inputdropdown"
                                            >
                                                <MenuItem disabled value="">
                                                    <em>Select Integration Type</em>
                                                </MenuItem>
                                                {IntegartionTypeNameSelect.map((item) => (
                                                    <MenuItem value={item} name={item} >{item}</MenuItem>
                                                ))}

                                            </Select> */}
                        <input
                          type="text"
                          value={editIntegrationTypesName}
                          className="form-control inputdropdown"
                          id="exampleFormControlSelect3"
                          placeholder="Integartion Type Name"
                          name="fraudServiceName"
                          onChange={(e) => {
                            e.preventDefault();
                            setEditIntegrationTypesName(e.target.value);
                          }}
                        />
                      </div>

                      <div className="d-flex justify-content-center">
                        <div
                          className="submit"
                          onClick={() => {
                            editOneIntegration();
                          }}
                        >
                          Edit
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          {/* delete integration Model */}
          <DeletingAlert
            deletingId={deleteIntegrationTypeId}
            onHide={() => {
              setDeleteIntegrationTypeId("");
            }}
            image_base_url={imageBaseURL}
            msg="Are you sure you want to delete selected setting? This action cannot be undone."
            onDeleteClickHandler={() =>
              deleteOneIntegration(deleteAquirerService)
            }
          />
        </div>
      ) : (
        <div className="m-4 text-center ">No ID is found</div>
      )}
    </HeaderAndFooterLayout>
  );
};

export default CardAquirerDetails;

const DeletingAlert = (props) => {
  return (
    <div className="merchant_dailog_screen">
      <Dialog
        open={props.deletingId ? true : false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
      >
        <div>
          <Modal
            show={true}
            className="ClearAlertModal deleteAlertModal ClearAlertModals"
            onHide={() => {
              props.onHide();
            }}
          >
            <Modal.Body className="ClearAlertModalBody">
              <div className="ClearAlertModalIconWrap">
                <img
                  src={`${props.imageBaseURL}${deleteIcon}`}
                  alt="resetIcon"
                  className="ClearAlertModalIcon"
                />
              </div>
              <div className="ClearAlertModalTitle">Are you sure?</div>
              <div className="ClearAlertModalDescription">{props.msg}</div>
              <div
                className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
                style={{ background: "#E02020" }}
                onClick={() => {
                  props.onDeleteClickHandler();
                }}
                data-e2e="clear"
              >
                Delete
              </div>
              <div
                className="ClearAlertModalGoBackBtn"
                onClick={() => {
                  props.onHide();
                }}
              >
                Go Back
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Dialog>
    </div>
  );
};
