import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import JSONPretty from "react-json-pretty";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from 'react-responsive';
import { Link, useParams } from "react-router-dom";
import { Table } from "reactstrap";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
  UPDATE_MERCHANT_TRANSACTION,
  UPDATE_WALLET_BALANCE,
  VIEW_B2C_SINGLE_USER_WALLET,
  VIEW_SINGLE_MERCHANT_TRANSACTION,
  VIEW_SINGLE_WALLET
} from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import { setHeader } from "../../../Redux/actions/commonAction";
import Loading from "../../../common/Loading";
import { merchantTransactionStatuses, withdrawalStatuses } from "../../global/constant";
import { isViewPermissionValid } from '../../../common/CommonUtils';
const blockchainExternalLink = "/cryptonpay/blockchain_external_link.png";
const closeImage = '/cryptonpay/crytoicons/close.png';
const change_transaction = '/cryptonpay/crytoicons/change_transaction.png';

function TransactionView(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const BTC_MAINNET = useSelector((state) => state.config.btc_mainnet);
  const BTC_TESTNET = useSelector((state) => state.config.btc_testnet);
  const ETH_MAINNET = useSelector((state) => state.config.eth_mainnet);
  const ETH_SEPOLIA = useSelector((state) => state.config.eth_sepolia);
  const LTC_MAINNET = useSelector((state) => state.config.ltc_mainnet);
  const TRX_MAINNET = useSelector((state) => state.config.trx_mainnet);
  const TRX_TESTNET = useSelector((state) => state.config.trx_testnet);

  const [openData, setOpenData] = useState([])

  const [data, setData] = useState([]);
  const [memoPoolReceiptData, setMemoPoolReceiptData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [balanceUpdateNote, changeBalanceUpdateNote] = useState("");
  const [editWalletBalanceData, setEditWalletBalanceData] = useState(null);
  const [newBalanceInCrypto, setNewBalanceInCrypto] = useState(null);
  const email = localDb.getVal("email");
  const [indexOfOpenTab, setIndexOpenTab] = useState(null);
  const [sortDataDetails, setSortDetails] = useState({
    walletDetails: 'asc',
    transactionType: 'asc',
    transactionStatus: 'asc',
    fiatAmount: 'asc',
    cryptoAmount: 'asc',
    crypto: 'asc',
    message: 'asc'
  })
  const [transactionType, setTranasactionType] = useState('')
  const [toggleDropDown, setToggleDropDown] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [modalFlag, setModalFlag] = useState(false);
  const [statusChangeMsg, setStatusChangeMsg] = useState("")
  const [statusChangeError, setStatusChangeError] = useState('');
  const [statusList, setStatusList] = useState([])

  const withdrawalStatusList = [
    withdrawalStatuses.WITHDRAWAL_REJECTED_DUE_TO_BALANCE,
    withdrawalStatuses.WITHDRAWAL_ERROR,
    withdrawalStatuses.WITHDRAWAL_UNABLE_TO_SOURCE_WALLET,
    withdrawalStatuses.WITHDRAWAL_COMPLETED,
    withdrawalStatuses.WITHDRAWAL_REJECTION_COMPLETE,
    withdrawalStatuses.WITHDRAWAL_INSUFFICIENT_FUNDS_REJECT,
		withdrawalStatuses.WITHDRAWAL_PROCESSING,
		withdrawalStatuses.WITHDRAWAL_CONFIRMED,
    withdrawalStatuses.WITHDRAWAL_APPROVE,
    withdrawalStatuses.WITHDRAWAL_REJECT,
    withdrawalStatuses.WITHDRAWAL_SUBMITTED
  ]
  const depositStatusList = [
    "MerchantWalletMonitor:ReceivedToMemPool",
    "MerchantWalletMonitor:AwaitingReceiveConfirmation",
    "MerchantWalletMonitor:ReceiveConfirmed"
  ]

  const view_transaction_url = useSelector((state) => state.config.api_url);
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const getFiatCurrencySymbol = (fiatCurrency) => {
    switch (fiatCurrency) {
      case "USD":
        return "$";
      case "GBP":
        return "£";
      case "EUR":
        return "€";
      default:
        return "";
    }
  };

  const getCryptoLink = (network, cryptoId) => {
    let link = network + "-" + cryptoId;
    switch (link) {
      case "mainnet-BTC":
        return BTC_MAINNET;
      case "testnet-BTC":
        return BTC_TESTNET;
      case "mainnet-ETH":
      case "mainnet-USDT":
      case "mainnet-DAI":
      case "mainnet-USDC":
        return ETH_MAINNET;
      case "goerli-ETH":
      case "testnet-ETH":
      case "testnet-DAI":
      case "testnet-USDC":
      case "testnet-USDT":
        return ETH_SEPOLIA;
      case "mainnet-LTC":
        return LTC_MAINNET;
      case "mainnet-TRX":
      case "mainnet-TRC20-USDT":
        return TRX_MAINNET;
      case "testnet-TRX":
      case "testnet-TRC20-USDT":
        return TRX_TESTNET;
      default:
        return "";
    }
  };

  const getCryptoIcon = (symbol) => {
    switch (symbol) {
      case 'BTC':
        return '/cryptonpay/crytoicons/bitcoin1.png';
      case 'ETH':
        return '/cryptonpay/crytoicons/eth1.png';
      case 'USDT':
        return "/cryptonpay/usdt.png";
      case 'DAI':
        return '/cryptonpay/crytoicons/dai.png';
      case "BCC":
        return "/cryptonpay/crytoicons/btt_cash1.png";
      case "USDC":
        return "/cryptonpay/crytoicons/usd.png";
      case "EOS":
        return "/cryptonpay/crytoicons/eos.png";
      default:
        return "/cryptonpay/crytoicons/usd.png";
    }
  };

  const updateTransactionStatus = async () => {
    if (statusChangeMsg == "") {
      setStatusChangeError("Comment should be required");
      return
    } else {
      setLoading(true);
      await call(
        {
          ...UPDATE_MERCHANT_TRANSACTION,
          url: view_transaction_url + UPDATE_MERCHANT_TRANSACTION.url,
        },
        { email: email, transactionId: id, message: statusChangeMsg, status: transactionStatus }
      )
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            setLoading(false);
            setModalFlag(false);
            setTransactionStatus("");
            localStorage.setItem("setMsg", true);
            window.location.reload();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message, 'TransactionDetails > updateMerchantTransactionStatus > err');
          logoutAfterError(err)
        });
    }
  };

  const viewSingleTransactionDetails = async () => {
    await call(
      {
        ...VIEW_SINGLE_MERCHANT_TRANSACTION,
        url: view_transaction_url + VIEW_SINGLE_MERCHANT_TRANSACTION.url,
      },
      { email: email, transactionId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
            if(res.data && res.data.type) setTranasactionType(res.data.type)
            if(res.data && res.data.status && res.data.status.code && res.data.status.code) setTransactionStatus(res.data.status.code)
            if(res.data.type === "Deposit" || res.data.type === "deposit") {
              setStatusList(depositStatusList)
            }
            else {
              setStatusList(withdrawalStatusList)
            }
          if (res.data.memoPoolReceipt && res.data.memoPoolReceipt.length > 1) {
            setMemoPoolReceiptData([
              res.data.memoPoolReceipt[0].receipt,
              res.data.memoPoolReceipt[1].receipt,
            ]);
          } else if (
            res.data.memoPoolReceipt &&
            res.data.memoPoolReceipt.length > 0
          ) {
            setMemoPoolReceiptData([res.data.memoPoolReceipt[0].receipt]);
          }


          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const viewSingleWalletDetails = async () => {
    await call(
      {
        ...VIEW_SINGLE_WALLET,
        url: view_transaction_url + VIEW_SINGLE_WALLET.url,
      },
      { email: email, walletId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          if (["Merchant Wallets", "Merchant User Wallets"].includes(props.view)) {
            setData(res.data && res.data.wallets);
            setLoading(false);
          } else {
            setData(res.data && res.data.transaction);
            if (res.data && res.data.transaction && res.data.transaction.status) {
              props.setStatus(res.data.transaction.status.code);
              props.setTransId(res.data.transaction.transactionId)
            }
            setLoading(false);
            props.handleId(res.data && res.data.transaction && res.data.transaction.merchantUserId && res.data.transaction.merchantUserId);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const viewSingleWalletAddress = async () => {
    await call(
      {
        ...VIEW_B2C_SINGLE_USER_WALLET,
        url: view_transaction_url + VIEW_B2C_SINGLE_USER_WALLET.url,
      },
      { email: email, walletId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          if (props.view === "b2c-transactions") {
            setData(res.data.userWallets);
            setLoading(false);
          } else {
            setData(res.data && res.data.transaction);
            if (res.data && res.data.transaction && res.data.transaction.status) {
              props.setStatus(res.data.transaction.status.code);
              props.setTransId(res.data.transaction.transactionId)
            }
            setLoading(false);
            props.handleId(res.data && res.data.transaction && res.data.transaction.merchantUserId && res.data.transaction.merchantUserId);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const getStatusComponent = (status) => {
    switch (status) {
      case "DepositMonitor:DepositPendingManualApproval":
      case "WithdrawalFlow:PendingApproval":
        return 'Required Approval'
      case "DepositMonitor:TimedOutMonitoringMemPool":
        return 'Timed Out'
      case "DepositMonitor:DepositRejected":
      case "DepositMonitor:DepositFailed":
      case "DepositMonitor:RefundFailed":
      case "WalletManagementService:WithdrawalRejectionCompleted":
        return 'Rejected'
      case "DepositMonitor:WalletStatusAvailable":
      case "DepositMonitor:WalletStatusLocked":
      case "DepositMonitor:UpdateWalletStatus":
      case "DepositMonitor:NotfiyMerchant":
      case "DepositMonitor:DepositManualApprove":
      case "DepositMonitor:KYTCheckFail":
      case "DepositMonitor:KYTCheckPass":
      case "DepositMonitor:KYTCheck":
      case "DepositMonitor:DepositConfirmed":
      case "DepositMonitor:GasTooLow":
      case "DepositMonitor:ExcessTransactionExecuted":
      case "DepositMonitor:TransactionExecuted":
      case "DepositMonitor:TransactionExecuting":
      case "DepositMonitor:DepositReceivedToMemPool":
      case "DepositMonitor:TransactionComplete":
        return 'Processing'
      case "DepositMonitor:DepositManuallyCompleted":
      case "DepositMonitor:DepositCompleted":
      case "MerchantWallet:BalanceUpdated":
      case "WalletManagementService:WithdrawalCompleted":
      case "WalletManagementService:TransferCompleted":
      case "DepositMonitor:DepositRefunded":
        return 'Completed'
      case "CreatePayment":
      case "DepositFlow:Launched":
      case "DepositFlow:AwaitingDeposit":
      case "DepositMonitor:MonitoringMemPool":
      case "DepositMonitor:DepositPendingManualApproval":
        return 'Pending'
      case merchantTransactionStatuses?.DEPOSIT_CANCELLED:
        return 'Cancelled'
      case merchantTransactionStatuses?.DEPOSIT_ABANDONED:
        return 'Abandoned'
      case "N/A":
        return "N/A"
      default:
        return 'Processing'

    }
  };
  const getStatus = (status) => {
    switch (status) {
      case "DepositMonitor:DepositPendingManualApproval":
        return true;
      default:
        return false;
    }
  };

  const changeNewCryptoBalance = async (value) => {
    if (!isNaN(value)) {
      setError("")
      setNewBalanceInCrypto(value)
    }
  }

  const handleEditBalance = (walletData) => {
    setEditWalletBalanceData({ ...walletData });
    setNewBalanceInCrypto("");
  };

  const changeTransactionStatus = (status) => {
    setToggleDropDown(false)
    setTransactionStatus(status);
    setModalFlag(true)
  }

  const getExchangeRate = (fromCurrency, toCurrency, currencyAmount) => new Promise(async (resolve, reject) => {
    await call(
      {
        ...GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
        url: view_transaction_url + GET_CRYPTO_AMOUNT_EXCHANGE_RATE.url,
      },
      {
        email: email,
        fromCurrency: fromCurrency,
        toCurrency: toCurrency,
        currencyAmount: currencyAmount,
      }
    ).then((res) => {
      if (res.data) {
        resolve(parseFloat(res.data).toFixed(8))
      }
    })
      .catch((err) => {
        setError("Something went wrong while fetching exchange values.")
        console.log(err);
        reject(null)
        logoutAfterError(err)
      })
  });

  const saveNewBalanceValues = async (cryptoCurrency) => {
    setLoading(true);
    if (newBalanceInCrypto != "") {
      const exchangeValue = await getExchangeRate(cryptoCurrency, "USD", newBalanceInCrypto);
      if (exchangeValue || exchangeValue === 0) {
        await call(
          {
            ...UPDATE_WALLET_BALANCE,
            url: view_transaction_url + UPDATE_WALLET_BALANCE.url,
          },
          {
            email: email,
            newBalanceAmountInCrypto: newBalanceInCrypto,
            newBalanceAmountInUSD: exchangeValue,
            balanceUpdateNote,
            balanceId: editWalletBalanceData._id,
            walletId: id,
          }
        ).then((res) => {
          setLoading(false);
          if (res.data) {
            setData(res.data && res.data.wallets);
            setEditWalletBalanceData(null)
          }
        })
          .catch((err) => {
            setError("Something went wrong while updating the wallet balance.")
            setLoading(false);
            console.log(err);
            logoutAfterError(err)
          })
      }
    } else {
      setLoading(false);
      setError("Something went wrong while fetching exchange rate.")
    }
  };

  useEffect(() => {

    let temp =
      id.substring(0, 10) +
      "...." +
      id.substring(id.length - 6, id.length);

    if (props.handleFullId) {
      props.handleFullId(id)
    }
    props.handleId(temp);
    ["Merchant Wallets", "Merchant User Wallets"].includes(props.view)
      ? viewSingleWalletDetails()
      : viewSingleTransactionDetails();

    if (["Merchant Wallets", "Merchant User Wallets"].includes(props.view)) {
      dispatch(setHeader(props.view));
    }
    if (props.view == "b2c-transactions") {
      viewSingleWalletAddress()
    }
    window.scrollTo(0, 0)
  }, [id]);


  const sortData = (itemToSort) => {
    let sortBy = null
    let newSortBy = sortDataDetails[itemToSort] == 'asc' ? 'desc' : 'asc'
    setSortDetails({
      ...sortDataDetails,
      [itemToSort]: newSortBy
    })
    sortBy = newSortBy
    const sortAlpha = (a, b) => {
      let nameA, nameB
      if (itemToSort == 'walletStatus') {
        nameA = a.code.toLowerCase();
        nameB = b.code.toLowerCase();
      } else if (itemToSort == 'transactionType') {
        nameA = a.transactionDetails.type.toLowerCase()
        nameB = b.transactionDetails.type.toLowerCase()
      } else if (itemToSort == 'transactionStatus') {
        nameA = a.transactionDetails.status.code.toLowerCase()
        nameB = b.transactionDetails.status.code.toLowerCase()
      } else if (itemToSort == 'crypto') {
        return
      } else if (itemToSort == 'message') {
        nameA = a.transactionDetails.status.message.toLowerCase()
        nameB = b.transactionDetails.status.message.toLowerCase()
      }
      if (sortBy == 'asc') {
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        if (nameB < nameA) return -1;
        if (nameB > nameA) return 1;
        return 0;
      }
    }
    const sortDate = (a, b) => {
      if (sortBy == 'asc') {
        if (a.updated > b.updated) return -1
        if (b.updated > a.updated) return 1
      } else {
        if (a.updated > b.updated) return 1
        if (b.updated > a.updated) return -1
      }

    }
    const sortNumbers = (a, b) => {
      let num1
      let num2
      if (itemToSort == 'fiatAmount') {
        num1 = a.transactionDetails.depositAmount
        num2 = b.transactionDetails.depositAmount
      }
      if (itemToSort == 'cryptoAmount') {
        num1 = a.transactionDetails.selected.amount
        num2 = b.transactionDetails.selected.amount
      }
      if (sortBy == 'asc') {
        if (typeof num1 === 'number' && typeof num2 === 'number') {
          return num1 - num2;
        } else if (typeof a === 'number') {
          return -1;
        } else if (typeof num2 === 'number') {
          return 1;
        } else {
          return num1 > num2 ? 1 : -1;
        }
      } else {
        if (typeof num1 === 'number' && typeof num2 === 'number') {
          return num2 - num1;
        } else if (typeof a === 'number') {
          return 1;
        } else if (typeof num2 === 'number') {
          return -1;
        } else {
          return num1 > num2 ? -1 : 1;
        }
      }

    }
    let temp = { ...data }
    if (itemToSort == 'walletStatus' || itemToSort == 'transactionStatus' || itemToSort == 'transactionType' || itemToSort == 'message' || itemToSort == 'crypto') {
      temp.newStatus.sort(sortAlpha)
    } else if (itemToSort == 'fiatAmount' || itemToSort == 'cryptoAmount') {
      temp.newStatus.sort(sortNumbers)
    } else if (itemToSort == 'timeStamp') {
      temp.newStatus.sort(sortDate)
    }
    setData(temp)
  }

  function returnIcon(name) {
    if (name === 'asc') {
      return <i className="fa fa-chevron-up TransactionTable_heading_sortIcon sort_wallet_icon"></i>
    } else {
      return <i className="fa fa-chevron-down TransactionTable_heading_sortIcon sort_wallet_icon "></i>
    }

  }
  const [detailBtn, setDetailBtn] = useState("Details");
  return (
    <div className="wallet_container pt-0">
      {["deposit", "Deposit", "Withdrawal", "withdrawal"].includes(transactionType) &&
						<div className='status_drop_down'>
							<div className={`status_drop_down_box ${isViewPermissionValid? "disabled": ""}`} onClick={() => isViewPermissionValid? null: setToggleDropDown(toggleDropDown ? false : true)}>
								<div className='status_drop_down_content'>
									{transactionStatus}
								</div>
								<div className='status_drop_down_arrow_box'>
									<i className="fa fa-chevron-down status_arrow" data-e2e="status-icon" />
								</div>
							</div>
							{toggleDropDown && <div className='status_drop_down_list'>
								{statusList.map((stats, index) => {
									return <div data-e2e={stats} className="status_drop_down_text" key={index} onClick={() => changeTransactionStatus(stats)}>{stats}</div>
								})}
							</div>}
						</div>}
            {modalFlag && <div>
						<Modal show={true} className="change_status_modal">
							<Modal.Body className="change_status_body" >
								<img src={`${image_base_url}${change_transaction}`} alt="image" className="" />
								<div className='change_trans_header mt-4'>Change Transaction Status</div>
								<div className='change_trans_sure_want mt-4'>Are you sure you want to change transaction status? </div>
								<div className='change_trans_status_lable mt-4'>Status</div>
								<div className='change_trans_status_value mt-2'>{transactionStatus}</div>
								<textarea
									data-e2e="add-note..."
									placeholder='Add note..'
									value={statusChangeMsg}
									name='message'
									className='change_trans_note mt-4'
									cols="40"
									rows="5"
									onChange={(e) => setStatusChangeMsg(e.target.value)} 
                  />
								{statusChangeError != '' && <div className='add_note_error'>{statusChangeError}</div>}
								<div className='change_trans_button mt-4' data-e2e="change-btn" onClick={()=>updateTransactionStatus()}>Change</div>
								<div className='change_trans_go_back mt-4' onClick={() => setModalFlag(false)} data-e2e="go-back-btn">Go Back</div>
							</Modal.Body>
						</Modal>
						{loading && <Loading />}
					</div>}
      {editWalletBalanceData &&
        <div className="merchant_dailog_screen">
          <Modal
            show={true}
            className="ClearAlertModal transferModal transferFundsModal"
            onHide={() => {
              setEditWalletBalanceData(null);
            }}
          >
            <Modal.Body className="ClearAlertModalBody transferModalBody">
              <div className="transferModalTitleWrap">
                <div className="transferModalTitle" >Edit Balance</div>
                <img
                  className="closeIcon"
                  alt="closeIcon"
                  src={`${image_base_url}${closeImage}`}
                  onClick={() => {
                    setEditWalletBalanceData(null);
                  }}
                />
              </div>
              <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect1"
                >
                  Current Balance
                </label>
                <input
                  type="text"
                  data-e2e="current-balance"
                  disabled
                  value={editWalletBalanceData.balanceAmount}
                  className="form-control inputdropdown"
                  id="balanceAmount"
                  placeholder="Current Balance Amount"
                  name="balanceAmount"
                />
              </div>
              <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect1"
                >
                  New Balance
                </label>
                <input
                  type="text"
                  data-e2e="new-balance"
                  value={newBalanceInCrypto}
                  className="form-control inputdropdown"
                  id="newBalanceInCrypto"
                  placeholder="Enter New Balance"
                  name="newBalanceInCrypto"
                  onChange={(e) => {
                    changeNewCryptoBalance(e.target.value);
                  }}
                />
              </div>
              <div className="form-group myAccoutOptModal_form_group transferModalGroup">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect1"
                >
                  Note
                </label>
                <textarea rows="3" cols="50"
                  value={balanceUpdateNote}
                  className="form-control inputdropdown"
                  id="balanceUpdateNote"
                  placeholder="Enter Note"
                  data-e2e="enter-note-text-box"
                  name="balanceUpdateNote"
                  style={{ minHeight: "100px" }}
                  onChange={(e) => {
                    changeBalanceUpdateNote(e.target.value);
                  }}
                />
              </div>
              {error !== "" && <div className="limitErrorText">{error}</div>}
              <div
                className={`ClearAlertModalClearBtn transferFundsBtn ${newBalanceInCrypto && balanceUpdateNote &&
                  newBalanceInCrypto != "" &&
                  balanceUpdateNote != "" &&
                  error === ""
                  ? ''
                  : 'transferFundsBtnDisable'
                  }`}
                onClick={() => {
                  if (newBalanceInCrypto && balanceUpdateNote &&
                    newBalanceInCrypto != "" &&
                    balanceUpdateNote != "" &&
                    error === "") {
                    saveNewBalanceValues(editWalletBalanceData.cryptoId)
                  }
                }}
                data-e2e="save-btn"
              >
                Save
              </div>
            </Modal.Body>
          </Modal>
        </div>}
      {["Merchant Wallets", "Merchant User Wallets"].includes(props.view) || props.view == "b2c-transactions" ? (
        <div className="wallet_listing_page">
          <div
            className="TransactionTableMain transaction_table_link table-full-width table_padding"
            style={{ flex: 1, marginTop: "40px" }}
          >
            {data && data.balance && data.balance.length > 0 && (
              <Table responsive style={{ border: "none" }}>
                <div className="scrollit up">
                  <tbody
                    className="TransactionTable_BODY transaction_view_sub transaction_view_sub_Scroll make-table-flex up"
                    style={{ border: "none" }}
                  >
                    <tr className="tr-flex">
                      <th className="TransactionTable_heading transaction_table remove crypto-width">
                        Token
                      </th>
                      <th className="TransactionTable_heading transaction_table remove">
                        Balance
                      </th>
                      <th className="TransactionTable_heading transaction_table table-centered remove">
                        Last Updated
                      </th>
                    </tr>
                    {data &&
                      data.balance &&
                      data.balance.length > 0 &&
                      data.balance.map((item, index) => {
                        return (
                          <tr className="CP_TableRow tr-flex" key={index}>
                            <td className="transactionsTable_item transactionsTable_item_fonts crypto-width">
                              <img
                                src={`${image_base_url}${getCryptoIcon(
                                  item.cryptoId
                                )}`}
                                alt={item.cryptoId}
                                className="currencyList_Icon"
                                data-e2e="currencyList_Icon"
                                width="25px"
                                height="25px"
                              />{" "}
                              <span>&nbsp;{item.cryptoName || "N/A"}</span>{" "}
                              <span style={{ color: "#afb4be" }}>
                                ({item.cryptoId})
                              </span>
                            </td>
                            <td className="transactionsTable_item transactionsTable_item_fonts balance-parent ">
                              <div className="balanceAndEditWrap balance-child">
                                <span>
                                  {item.balanceAmount ? item.balanceAmount : item.balanceAmount === 0 ? 0 : "-"}
                                </span>
                                <EditIcon data-e2e={`${item.cryptoName ? item.cryptoName.toLowerCase().replaceAll(' ', '-') : ""}-eidt-icon`} fontSize="medium" color='action' className={`cursor-pointer balanceEditButton ${isViewPermissionValid? "disabled": ""}`} onClick={isViewPermissionValid? null: () => {
                                  handleEditBalance(item)
                                }} />
                              </div>
                            </td>
                            <td className="transactionsTable_item transactionsTable_item_fonts table-centered">
                              {item.updated
                                ? moment(item.updated).format(
                                  "DD/MM/YYYY - HH:mm"
                                )
                                : "N/A"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </div>
                {
                  data && data.balance && data.balance.length > 0 && (
                    <div
                      className="walletsTotalBalance"
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div className="approx-left">Approx USD Balance</div>


                      <div className="approx-middle" data-e2e-value="amount" data-e2e={data.balanceAmountUSDApprox ? data.balanceAmountUSDApprox : data.balanceAmountUSDApprox === 0 ? 0 : "N/A"}>
                        {data.balanceAmountUSDApprox ? data.balanceAmountUSDApprox : data.balanceAmountUSDApprox === 0 ? 0 : "N/A"}
                      </div>
                      <div className="approx-right">

                      </div>
                    </div>
                  )
                }
              </Table>
            )}
          </div>
          {
            data && data.newStatus &&
            data.newStatus.length > 0 &&
            <div
              className="TransactionTableMain transaction_table_link table-full-width table_padding transaction-h"
              style={{ flex: 1, marginTop: "10.5px" }}
            >
              <Table height="457" responsive style={{ border: "none" }}>
                <div className="scrollit">
                  <tbody
                    className="TransactionTable_BODY transaction_view_sub transaction_view_sub_Scroll down"
                    style={{ border: "none" }}
                  >
                    <tr className="TransactionTable_BODY_Header">
                      <th className="TransactionTable_heading transaction_table remove"
                        onClick={() => sortData('crypto')}
                      >
                        CRYPTO
                        {returnIcon(sortDataDetails.crypto)}
                      </th>
                      <th className="TransactionTable_heading transaction_table remove"
                        onClick={() => sortData('walletStatus')}
                      >
                        WALLET STATUS
                        {returnIcon(sortDataDetails.walletStatus)}
                      </th>
                      <th className="TransactionTable_heading transaction_table remove"
                        onClick={() => sortData('transactionType')}
                      >
                        TRANSACTION
                        {returnIcon(sortDataDetails.transactionType)}
                      </th>
                      <th className="TransactionTable_heading transaction_table remove"
                        onClick={() => sortData('timeStamp')}
                      >
                        TIME STAMP
                        {returnIcon(sortDataDetails.timeStamp)}
                      </th>
                      <th className="TransactionTable_heading transaction_table remove"
                        onClick={() => sortData('fiatAmount')}
                      >
                        FIAT AMOUNT
                        {returnIcon(sortDataDetails.fiatAmount)}
                      </th>
                      <th className="TransactionTable_heading transaction_table remove"
                        onClick={() => sortData('cryptoAmount')}
                      >
                        CRYPTO AMOUNT
                        {returnIcon(sortDataDetails.cryptoAmount)}
                      </th>
                      <th className="TransactionTable_heading transaction_table remove"
                        onClick={() => sortData('transactionStatus')}
                      >
                        TRANSACTION STATUS
                        {returnIcon(sortDataDetails.transactionStatus)}
                      </th>
                      <th className="TransactionTable_heading transaction_table remove"
                        onClick={() => sortData('message')}
                      >
                        STATUS MESSAGE
                        {returnIcon(sortDataDetails.message)}

                      </th>
                    </tr>
                    {
                      data && data.newStatus &&
                      data.newStatus.length > 0 &&
                      data.newStatus.map((ele, index) => {
                        return (
                          <React.Fragment>
                            <tr>
                              <td className="transactionsTable_item transactionsTable_item_fonts s">
                                <img
                                  src={`${image_base_url}${data.cryptoId.icon}`}
                                  alt={data.cryptoId.icon}
                                  className="currencyList_Icon"
                                  data-e2e="currencyList_Icon"
                                  width="25px"
                                  height="25px"
                                />{" "}
                                <span>&nbsp;{data.cryptoId.name || "N/A"}</span>{" "}
                                <span style={{ color: "#afb4be" }}>
                                  ({data.cryptoId.cryptoId})
                                </span>
                              </td>
                              <td className="transactionsTable_item transactionsTable_item_fonts s">
                                {ele.code}
                              </td>
                              <td className="transactionsTable_item transactionsTable_item_fonts s">
                                {ele.transactionDetails ? ele.transactionDetails.type : "N/A"}
                              </td>
                              <td className="transactionsTable_item transactionsTable_item_fonts s">
                                {ele.updated.length > 4
                                  ? moment(ele.updated).format("DD/MM/YYYY - HH:mm")
                                  : "N/A"}
                              </td>
                              <td className="transactionsTable_item transactionsTable_item_fonts s">
                                {ele.transactionDetails ? `${getFiatCurrencySymbol(ele.transactionDetails.depositFiatCurrency)}${ele.transactionDetails.depositAmount}` : "N/A"}
                              </td>
                              <td className="transactionsTable_item transactionsTable_item_fonts s">
                                {ele.transactionDetails ? `${ele.transactionDetails.selected.amount}` : "N/A"}
                                <span className='grey-span'>{` ${ele.transactionDetails.selected.cryptoId != 'N/A' ? '(' + ele.transactionDetails.selected.cryptoId + ')' : ''}`}</span>
                              </td>
                              <td className="transactionsTable_item transactionsTable_item_fonts s">
                                {ele.transactionDetails ? <span style={getStatusComponent(ele.transactionDetails.status.code) == 'Completed' ? { color: 'green' } : { color: 'red' }}>{getStatusComponent(ele.transactionDetails.status.code)}</span> : "N/A"}
                              </td>
                              <td className="transactionsTable_item transactionsTable_item_fonts s">
                                Message <img
                                  data-e2e="expand-icon"
                                  className="closeIcon"
                                  alt="closeIcon"
                                  src={`${image_base_url}/homeb2c/transaction-plus.png`}
                                  onClick={() => {
                                    if (openData.includes(ele._id)) return
                                    setOpenData([...openData, ele._id]);
                                  }}
                                />
                              </td>

                            </tr>
                            {
                              openData.includes(ele._id) ?
                                <tr className='status-message'>
                                  <td colSpan="8" className="special-td">
                                    <div className="status-message-container">
                                      <div >
                                        <div className="status-msg"><span className="status-msg-text">Status Message</span>{ele.transactionDetails && ele.transactionDetails.status && moment(ele.transactionDetails.status.updated).format("DD/MM/YYYY - HH:mm")}</div>
                                        <div>
                                          {ele.code == "In Use" && ele.message.length > 0 ?
                                            <a
                                              className="transaction-link"
                                              href={`${getCryptoLink(
                                                data && data.network && data.network,
                                                data &&
                                                data.selected &&
                                                data.selected.cryptoId &&
                                                data.selected.cryptoId
                                              )}/transaction/${ele.message}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              data-e2e={ele.message}
                                            >{ele.message}</a>
                                            : null}
                                        </div>
                                        <div className="status-text-desc" data-e2e={ele && ele.message && ele.message}>
                                          {ele.transactionDetails && ele.transactionDetails.status && ele.transactionDetails.status.message}


                                        </div>

                                      </div>
                                      <img
                                        className="closeIcon minus-image "
                                        alt="closeIcon"
                                        src={`${image_base_url}/homeb2c/transaction-minus.png`}
                                        onClick={() => {
                                          setOpenData(openData.filter((item) => item != ele._id));
                                        }}
                                      />

                                    </div>
                                  </td>
                                </tr>
                                :
                                null
                            }
                          </React.Fragment>
                        )
                      })
                    }
                  </tbody>
                </div>
              </Table>
            </div>
          }
        </div>
      ) : (
        <div>
          {data && (
            <React.Fragment>
              <MediaQuery minWidth={768} >
                <div>
                  <div className="transaction_view" style={{ marginBottom: "0px", marginTop: "0px" }}>
                    <div
                      className="transaction_view_sub transaction_view_sub_Scroll"
                      style={{ overflowX: "hidden" }}
                    >
                      <div className="transaction_head">Details</div>
                      {
                        <div>
                          {" "}
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">Merchant</div>
                            <div className="transaction_items">
                              {(data.merchant && data.merchant.name) || "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Merchant User Email
                            </div>
                            <div className="transaction_items">
                              {(data.merchant && data.merchant.supportEmail &&
                                data.merchant.supportEmail) ||
                                "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">Type</div>
                            <div className="transaction_items text-capiltalize">
                              {(data && data.type && data.type) || "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">Network</div>
                            <div className="transaction_items">
                              {(data && data.network && data.network) || "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row MerchantDtls_cards_value">
                            <div className="transaction_subHeading">Status</div>
                            <div
                              className="transaction_items"
                              style={{ color: "#0091FF" }}
                            >
                              {(data && data.status && data.status.code && data.status.code) || "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row MerchantDtls_cards_value">
                            <div className="transaction_subHeading">
                              Status.Message
                            </div>
                            <div
                              className="transaction_items"
                              style={{ color: "#0091FF" }}
                            >
                              {(data && data.status && data.status.message && data.status.message) || "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">User ID</div>
                            <div className="transaction_items">
                              {data && data.merchant && data.merchant.merchantUserId || "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Date Created
                            </div>
                            <div className="transaction_items">
                              {data && data.createdDate && data.createdDate
                                ? moment(data.createdDate).format("DD/MM/YYYY")
                                : "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Last Updated
                            </div>
                            <div className="transaction_items">
                              {data && data.status && data.status.updated
                                ? moment(data.status.updated).format("DD/MM/YYYY")
                                : "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              From Address
                            </div>
                            <div className="transaction_items">
                              {data.type == "withdrawal" ? (
                                <a href={`/wallets/${data && data.fromWallet}`}>{(data && data.fromWallet && data.fromWallet.address)}</a>
                              ) : (
                                data?.fromWallet?.address || data?.fromWallet || "N/A"
                              )}

                            </div>
                            {(data?.fromWallet?.address || data?.fromWallet) && (
                              <a
                              href={`${getCryptoLink(
                                data && data.network && data.network,
                                data &&
                                  data.cryptoId &&
                                  data.cryptoId
                              )}/${data?.fromWallet?.address || data?.fromWallet}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="view-on-blockchain-btn-link"
                                  src={`${image_base_url}${blockchainExternalLink}`}
                                  alt={"external-link"}
                                  data-e2e={"external-link"}
                                  width="15px"
                                  height="15px"
                                />
                              </a>
                            )}
                          </div>
                          <div className="transaction_items_row">
                            {data &&
                              data.type !== "deposit" &&
                              data.type !== "Admin-Deposit" && (
                                <div className="transaction_items_row">
                                  <div className="transaction_subHeading">
                                    To Address
                                  </div>
                                  <div className="transaction_items">
                                    {data?.withdrawalWalletAddress || data?.destinationWallet?.address || data?.toWallet || "N/A"}
                                  </div>
                                </div>
                              )}
                            {data && (data?.withdrawalWalletAddress || data?.destinationWallet?.address || data?.toWallet) && (
                              <a
                              href={`${getCryptoLink(
                                data && data.network && data.network,
                                data?.cryptoId ? data?.cryptoId : data?.selected?.cryptoId 
                              )}/${data?.withdrawalWalletAddress || data?.destinationWallet?.address || data.toWallet}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="view-on-blockchain-btn-link"
                                  src={`${image_base_url}${blockchainExternalLink}`}
                                  alt={"external-link"}
                                  data-e2e={"external-link"}
                                  width="15px"
                                  height="15px"
                                />
                              </a>
                            )}
                          </div>
                          {data &&
                            (data.type === "deposit" || data.type === "MerchantWalletBalanceUpdate" ||
                              data.type === "Admin-Deposit") && (
                              <div className="transaction_items_row">
                                <div className="transaction_subHeading">
                                  Deposit Address
                                </div>
                                <Link
                                  to={`/wallets/${data &&
                                    data.depositWallet &&
                                    data.depositWallet.walletId
                                    }`}
                                >
                                  <div
                                    className="transaction_items"
                                    style={{
                                      color: "#0091FF",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {data && data.depositWallet &&
                                      data.depositWallet.address
                                      ? data.depositWallet.address.substring(0, 10) +
                                      "....." +
                                      data.depositWallet.address.substring(
                                        data.depositWallet.address.length - 6,
                                        data.depositWallet.address.length
                                      )
                                      : "N/A"}
                                  </div>
                                </Link>
                                {data && data.depositWallet && data.depositWallet.address && data.depositWallet.address && (
                                  <a
                                    href={`${getCryptoLink(
                                      data && data.network && data.network,
                                      data &&
                                      data.selected &&
                                      data.selected.cryptoId &&
                                      data.selected.cryptoId
                                    )}/${data && data.depositWallet &&
                                    data.depositWallet.address && data.depositWallet.address
                                      }`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      className="view-on-blockchain-btn-link"
                                      src={`${image_base_url}${blockchainExternalLink}`}
                                      alt={"external-link"}
                                      data-e2e={"external-link"}
                                      width="15px"
                                      height="15px"
                                    />
                                  </a>
                                )}
                              </div>
                            )}
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Crypto Value
                            </div>
                            <div className="transaction_items">
                              {["Autotrade"].includes(data.type) ?
                                ("(" + data.fromCrypto + " - " + data.toCrypto + ") -" + " (" + parseFloat(data.fromAmount).toFixed(6) + " - " + parseFloat(data.toAmount).toFixed(6) + ")") :
                                (data.selected && data.selected.cryptoId ? data.selected.cryptoId : data.cryptoId + " - " + (data && data.amount ? parseFloat(data.amount).toFixed(6) : "N/A"))}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              {" "}
                              Fiat Value
                            </div>
                            <div className="transaction_items">
                              {["Autotrade", "Deposit"].includes(data.type) ? getFiatCurrencySymbol("USD") + parseFloat(data.USDAmount).toFixed(6)
                                : data && data.depositFiatCurrency && data.depositFiatCurrency &&
                                getFiatCurrencySymbol(data.depositFiatCurrency && data.depositFiatCurrency) +
                                data && parseFloat(data.depositAmount).toFixed(6)}
                            </div>
                          </div>

                          {data.originalDepositAmount !== data.depositAmount &&
                            <div className="transaction_items_row">
                              <div className="transaction_subHeading">
                                {`Original Deposit Amount`}
                              </div>
                              <div className="transaction_items">
                                {data.originalDepositAmount}
                              </div>
                            </div>
                          }

                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              {" "}
                              Amount Sent
                            </div>
                            <div className="transaction_items">
                              {props.view === "MerchantTransactions" ? (data && data.selected && data.selected.actualAmountSent ? data.selected.actualAmountSent : "N/A") : (data && data.selected && data.selected.actualAmountDeposited ? data.selected.actualAmountDeposited : "N/A")}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading"> Currency</div>
                            <div className="transaction_items">
                              {data && data.depositFiatCurrency && data.depositFiatCurrency &&
                                getFiatCurrencySymbol((data.depositFiatCurrency && data.depositFiatCurrency) || "USD")}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading"> FX Rate</div>
                            <div className="transaction_items">{(data && data.selected && data.selected.exchangeRate) ||
                              "N/A"}</div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">Transaction Fee</div>
                            <div className="transaction_items">
                              {(data && data.transactionFee && data.transactionFee) ||
                                "N/A"}
                            </div>
                          </div>
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">Gas Fee</div>
                            <div className="transaction_items">
                              {(data && data.gasFee && data.gasFee) ||
                                "N/A"}
                            </div>
                          </div>

                          {data && data.transactionRefLink &&
                            <div className="transaction_items_row">
                              <div className="transaction_subHeading">
                                {`Transaction Link`}
                              </div>
                              <Link
                                to={`/${data.refTransactionType === "merchantWalletTransaction" ? "merchant-transaction" : "transaction"}/${data && data.transactionRefLink}`}
                              >
                                <div
                                  className="transaction_items"
                                  style={{
                                    color: "#0091FF",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {data && data.transactionRefLink &&
                                    data.transactionRefLink
                                    ? data.transactionRefLink.substring(0, 10) +
                                    "....." +
                                    data.transactionRefLink.substring(
                                      data.transactionRefLink.length - 6,
                                      data.transactionRefLink.length
                                    )
                                    : "N/A"}
                                </div>
                              </Link>
                            </div>
                          }
                        </div>
                      }
                    </div>
                    <div className="transaction_view_sub transaction_view_sub_Scroll">
                      <div className="transaction_head">Event Log </div>
                      {
                        <div>
                          {data && data.status &&
                            data.status.history &&
                            data.status.history.map((ele, index) => {
                              return (
                                <div
                                  className="transaction_subHeading"
                                  style={{
                                    display: "inline-block",
                                    marginBottom: "20px",
                                  }}
                                  key={index}
                                >
                                  {ele.updated
                                    ? moment(ele.updated).format(
                                      'DD/MM/YYYY - HH:mm:ss.SSS'
                                    )
                                    : "N/A"}{" "}
                                  -{" "}
                                  <div
                                    className="transaction_items eventLogItemWrap"
                                    style={{ textAlign: "left" }}
                                  >
                                    <span>{ele.code}</span>
                                    {ele.message && (
                                      <span>
                                        {indexOfOpenTab === index ? (
                                          <i
                                            className="fa fa-minus eventLogItemExpander"
                                            data-e2e={`${ele.code.toLowerCase().replaceAll(':', '-')}-contraction`}
                                            onClick={() => {
                                              setIndexOpenTab(null);
                                            }}
                                          />
                                        ) : (
                                          <i
                                            data-e2e={`${ele.code.toLowerCase().replaceAll(':', '-')}-expand`}
                                            className="fa fa-plus eventLogItemExpander"
                                            onClick={() => {
                                              setIndexOpenTab(index);
                                            }}
                                          />
                                        )}
                                      </span>
                                    )}
                                  </div>
                                  {ele.message && indexOfOpenTab === index && (
                                    <div className="eventLogItemMsg">
                                      {ele.message && (
                                        <div>
                                          {ele && ele.message && ele.message.toString().includes("{") &&
                                            ele && ele.message && ele.message.toString().charAt(0) ===
                                            "{" ? (
                                            <JSONPretty
                                              id="json-pretty"
                                              data={ele && ele.message}
                                            ></JSONPretty>
                                          ) : <div style={{ whiteSpace: "pre-wrap" }}>{(
                                            ele && ele.message
                                          )}</div>}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="transaction_view" style={{ marginTop: "0px" }}>
                    <div
                      className="transaction_view_sub transaction_view_sub_Scroll"
                      style={{ height: "617px" }}
                    >
                      <div className="transaction_head absolute_transaction_head">
                        Memopool Receipt{" "}
                      </div>
                      <div>
                        {memoPoolReceiptData && memoPoolReceiptData.length > 1 ? (
                          <div>
                            <div className="transactionMemoPoolReceiptWrap">
                              <JSONPretty
                                id="json-pretty"
                                data={memoPoolReceiptData[0]}
                              ></JSONPretty>
                            </div>
                            <div className="transactionMemoPoolReceiptWrap">
                              <JSONPretty
                                id="json-pretty"
                                data={memoPoolReceiptData[1]}
                              ></JSONPretty>
                            </div>
                          </div>
                        ) : memoPoolReceiptData &&
                          memoPoolReceiptData.length > 0 ? (
                          <div className="transactionMemoPoolReceiptWrap">
                            <JSONPretty
                              id="json-pretty"
                              data={memoPoolReceiptData[0]}
                            ></JSONPretty>
                          </div>
                        ) : (
                          <div className="NoDataFound">
                            MemoPoolReceipt data not found!
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </MediaQuery>

              <MediaQuery maxWidth={767}>
                <div className="TransactionBtn_container mb-3">
                  <TransactionBtn detailBtn={detailBtn} setDetailBtn={setDetailBtn} btn={["Details", "Event log", "Mempool receipt"]} />
                </div>

                <div className="transaction_detail_content">
                  {detailBtn === "Details" &&
                    <div>
                      {" "}
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">Merchant</div>
                        <div className="transaction_items">
                          {(data.merchant && data.merchant.name) || "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">
                          Merchant User Email
                        </div>
                        <div className="transaction_items">
                          {(data.merchant && data.merchant.supportEmail &&
                            data.merchant.supportEmail) ||
                            "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">Type</div>
                        <div className="transaction_items text-capiltalize">
                          {(data && data.type && data.type) || "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">Network</div>
                        <div className="transaction_items">
                          {(data && data.network && data.network) || "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row MerchantDtls_cards_value">
                        <div className="transaction_subHeading">Status</div>
                        <div
                          className="transaction_items"
                          style={{ color: "#0091FF" }}
                        >
                          {(data && data.status && data.status.code && data.status.code) || "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row MerchantDtls_cards_value">
                        <div className="transaction_subHeading">
                          Status.Message
                        </div>
                        <div
                          className="transaction_items"
                          style={{ color: "#0091FF" }}
                        >
                          {(data && data.status && data.status.message && data.status.message) || "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">User ID</div>
                        <div className="transaction_items">
                          {data && data.merchant && data.merchant.merchantUserId || "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">
                          Date Created
                        </div>
                        <div className="transaction_items">
                          {data && data.createdDate && data.createdDate
                            ? moment(data.createdDate).format("DD/MM/YYYY")
                            : "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">
                          Last Updated
                        </div>
                        <div className="transaction_items">
                          {data && data.status && data.status.updated
                            ? moment(data.status.updated).format("DD/MM/YYYY")
                            : "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">
                          From Address
                        </div>
                        <div className="transaction_items">
                          {data.type == "withdrawal" ? (
                            <a href={`/wallets/${data?.fromWallet?.address || data?.fromWallet}`}>{data?.fromWallet?.address || data?.fromWallet}</a>
                          ) : (
                            data?.fromWallet?.address || data?.fromWallet || "N/A"
                          )}

                        </div>
                        {data?.fromWallet?.address || data?.fromWallet && (
                          <a
                            href={`${getCryptoLink(
                              data && data.network && data.network,
                              data &&
                              data.selected &&
                              data.selected.cryptoId &&
                              data.selected.cryptoId
                            )}/${data?.fromWallet?.address || data?.fromWallet}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="view-on-blockchain-btn-link"
                              src={`${image_base_url}${blockchainExternalLink}`}
                              alt={"external-link"}
                              data-e2e={"external-link"}
                              width="15px"
                              height="15px"
                            />
                          </a>
                        )}
                      </div>
                      <div className="transaction_items_row">
                        {data &&
                          data.type !== "deposit" &&
                          data.type !== "Admin-Deposit" && (
                            <div className="transaction_items_row">
                              <div className="transaction_subHeading">
                                To Address
                              </div>
                              <div className="transaction_items">
                                {data?.destinationWallet?.address ||  "N/A"}
                              </div>
                            </div>
                          )}
                        {data && (
                          <a
                            href={`${getCryptoLink(
                              data && data.network && data.network,
                              data &&
                              data.selected &&
                              data.selected.cryptoId &&
                              data.selected.cryptoId
                            )}/${data?.withdrawalWalletAddress || data?.destinationWallet?.address}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="view-on-blockchain-btn-link"
                              src={`${image_base_url}${blockchainExternalLink}`}
                              alt={"external-link"}
                              data-e2e={"external-link"}
                              width="15px"
                              height="15px"
                            />
                          </a>
                        )}
                      </div>
                      {data &&
                        (data.type === "deposit" || data.type === "MerchantWalletBalanceUpdate" ||
                          data.type === "Admin-Deposit") && (
                          <div className="transaction_items_row">
                            <div className="transaction_subHeading">
                              Deposit Address
                            </div>
                            <Link
                              to={`/wallets/${data &&
                                data.depositWallet &&
                                data.depositWallet.walletId
                                }`}
                            >
                              <div
                                className="transaction_items"
                                style={{
                                  color: "#0091FF",
                                  textDecoration: "underline",
                                }}
                              >
                                {data && data.depositWallet &&
                                  data.depositWallet.address
                                  ? data.depositWallet.address.substring(0, 10) +
                                  "....." +
                                  data.depositWallet.address.substring(
                                    data.depositWallet.address.length - 6,
                                    data.depositWallet.address.length
                                  )
                                  : "N/A"}
                              </div>
                            </Link>
                            {data && data.depositWallet && data.depositWallet.address && data.depositWallet.address && (
                              <a
                                href={`${getCryptoLink(
                                  data && data.network && data.network,
                                  data &&
                                  data.selected &&
                                  data.selected.cryptoId &&
                                  data.selected.cryptoId
                                )}/${data && data.depositWallet &&
                                data.depositWallet.address && data.depositWallet.address
                                  }`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="view-on-blockchain-btn-link"
                                  src={`${image_base_url}${blockchainExternalLink}`}
                                  alt={"external-link"}
                                  data-e2e={"external-link"}
                                  width="15px"
                                  height="15px"
                                />
                              </a>
                            )}
                          </div>
                        )}
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">
                          Crypto Value
                        </div>
                        <div className="transaction_items">
                          {["Autotrade"].includes(data.type) ?
                            ("(" + data.fromCrypto + " - " + data.toCrypto + ") -" + " (" + parseFloat(data.fromAmount).toFixed(6) + " - " + parseFloat(data.toAmount).toFixed(6) + ")") :
                            (data.selected && data.selected.cryptoId ? data.selected.cryptoId : data.cryptoId + " - " + (data && data.amount ? parseFloat(data.amount).toFixed(6) : "N/A"))}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">
                          {" "}
                          Fiat Value
                        </div>
                        <div className="transaction_items">
                          {["Autotrade", "Deposit"].includes(data.type) ? getFiatCurrencySymbol("USD") + parseFloat(data.USDAmount).toFixed(6)
                            : data && data.depositFiatCurrency && data.depositFiatCurrency &&
                            getFiatCurrencySymbol(data.depositFiatCurrency && data.depositFiatCurrency) +
                            data && parseFloat(data.depositAmount).toFixed(6)}
                        </div>
                      </div>

                      {data.originalDepositAmount !== data.depositAmount &&
                        <div className="transaction_items_row">
                          <div className="transaction_subHeading">
                            {`Original Deposit Amount`}
                          </div>
                          <div className="transaction_items">
                            {data.originalDepositAmount}
                          </div>
                        </div>
                      }

                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">
                          {" "}
                          Amount Sent
                        </div>
                        <div className="transaction_items">
                          {data && data.selected && data.selected.actualAmountDeposited ? data.selected.actualAmountDeposited : "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading"> Currency</div>
                        <div className="transaction_items">
                          {data && data.depositFiatCurrency && data.depositFiatCurrency &&
                            getFiatCurrencySymbol((data.depositFiatCurrency && data.depositFiatCurrency) || "USD")}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading"> FX Rate</div>
                        <div className="transaction_items">{(data && data.selected && data.selected.exchangeRate) ||
                          "N/A"}</div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">Transaction Fee</div>
                        <div className="transaction_items">
                          {(data && data.transactionFee && data.transactionFee) ||
                            "N/A"}
                        </div>
                      </div>
                      <div className="transaction_items_row">
                        <div className="transaction_subHeading">Gas Fee</div>
                        <div className="transaction_items">
                          {(data && data.gasFee && data.gasFee) ||
                            "N/A"}
                        </div>
                      </div>

                      {data && data.transactionRefLink &&
                        <div className="transaction_items_row">
                          <div className="transaction_subHeading">
                            {`Transaction Link`}
                          </div>
                          <Link
                            to={`/${data.refTransactionType === "merchantWalletTransaction" ? "merchant-transaction" : "transaction"}/${data && data.transactionRefLink}`}
                          >
                            <div
                              className="transaction_items"
                              style={{
                                color: "#0091FF",
                                textDecoration: "underline",
                              }}
                            >
                              {data && data.transactionRefLink &&
                                data.transactionRefLink
                                ? data.transactionRefLink.substring(0, 10) +
                                "....." +
                                data.transactionRefLink.substring(
                                  data.transactionRefLink.length - 6,
                                  data.transactionRefLink.length
                                )
                                : "N/A"}
                            </div>
                          </Link>
                        </div>
                      }
                    </div>
                  }

                  {detailBtn === "Event log" &&
                    <div className="transaction_eventLog">
                      {data && data.status &&
                        data.status.history &&
                        data.status.history.map((ele, index) => {
                          return (
                            <div
                              className="transaction_subHeading"
                              style={{
                                display: "inline-block",
                                marginBottom: "20px",
                              }}
                              key={index}
                            >
                              {ele.updated
                                ? moment(ele.updated).format(
                                  'DD/MM/YYYY - HH:mm:ss.SSS'
                                )
                                : "N/A"}{" "}
                              -{" "}
                              <div
                                className="transaction_items wrap_word eventLogItemWrap"
                                style={{ textAlign: "left" }}
                              >
                                <span className="wrap_word overflow-hidden">{ele.code}</span>
                                {ele.message && (
                                  <span>
                                    {indexOfOpenTab === index ? (
                                      <i
                                        className="pl-3 fa fa-chevron-up text-secondary  eventLogItemExpander"
                                        data-e2e={`${ele.code.toLowerCase().replaceAll(':', '-')}-contraction`}
                                        onClick={() => {
                                          setIndexOpenTab(null);
                                        }}
                                      />
                                    ) : (
                                      <i
                                        data-e2e={`${ele.code.toLowerCase().replaceAll(':', '-')}-expand`}
                                        className="pl-3 fa fa-chevron-down text-secondary eventLogItemExpander"
                                        onClick={() => {
                                          setIndexOpenTab(index);
                                        }}
                                      />
                                    )}
                                  </span>
                                )}
                              </div>
                              {ele.message && indexOfOpenTab === index && (
                                <div className="eventLogItemMsg">
                                  {ele.message && (
                                    <div>
                                      {ele && ele.message && ele.message.toString().includes("{") &&
                                        ele && ele.message && ele.message.toString().charAt(0) ===
                                        "{" ? (
                                        <JSONPretty
                                          id="json-pretty"
                                          data={ele && ele.message}
                                        ></JSONPretty>
                                      ) : <div style={{ whiteSpace: "pre-wrap" }}>{(
                                        ele && ele.message
                                      )}</div>}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  }

                  {detailBtn === "Mempool receipt" &&
                    <div className="transaction_eventLog">
                      {memoPoolReceiptData && memoPoolReceiptData.length > 1 ? (
                        <div>
                          <div className="transactionMemoPoolReceiptWrap">
                            <JSONPretty
                              id="json-pretty"
                              data={memoPoolReceiptData[0]}
                            ></JSONPretty>
                          </div>
                          <div className="transactionMemoPoolReceiptWrap">
                            <JSONPretty
                              id="json-pretty"
                              data={memoPoolReceiptData[1]}
                            ></JSONPretty>
                          </div>
                        </div>
                      ) : memoPoolReceiptData &&
                        memoPoolReceiptData.length > 0 ? (
                        <div className="transactionMemoPoolReceiptWrap">
                          <JSONPretty
                            id="json-pretty"
                            data={memoPoolReceiptData[0]}
                          ></JSONPretty>
                        </div>
                      ) : (
                        <div className="NoDataFound">
                          MemoPoolReceipt data not found!
                        </div>
                      )}
                    </div>
                  }
                </div>
              </MediaQuery>
            </React.Fragment>
          )}
        </div>
      )}
      {loading && <Loading />}
    </div>
  );
}

export default TransactionView;

const TransactionBtn = (props) => {
  return (
    <div className="detail_btn mt-10">
      {props.btn.length > 0 && props.btn.map((btn, i) =>
        <button onClick={() => props.setDetailBtn(btn)} key={i} type="button" className={`btn ${props.detailBtn == btn ? "btn-primary" : "btn btn-light border"} detail_btn_content`}>{btn}</button>
      )}
    </div>
  )
};
