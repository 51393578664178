import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Dialog from "@mui/material/Dialog";

import AddBlockedEmail from "./AddBlockedEmail";
import DeletingAlert from "./DeletingAlert";
import Loading from "../../../../../common/Loading";
import { call } from "../../../../../../config/axios";
import {
  DELETE_BLOCKED_EMAIL_NEW,
  GET_BLOCKED_EMAIL_LIST,
} from "../../../../../../config/endpoints";
import localDb from "../../../../../../localDb";
import useDebounce from "../../../../global/useDebounce";
import { useSelector } from "react-redux";
import HeaderAndFooterLayout from "../../../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../../../CommonPages/CommonHeader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#434373",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BlockEmailList = () => {
  const baseURL = useSelector((state) => state.config.api_url);
  const [blockedEmails, setBlockedEmails] = useState([]);
  const [deleteEmail, setDeleteEmail] = useState(null);
  const [total, setTotal] = useState(0);
  const limit = 10;
  const [page, setPage] = useState(1);
  const [searchedText, setSearchedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [flag, setFlag] = useState(false)
  const debouncedSearchText = useDebounce(searchedText);

  useEffect(() => {
    fetchBlockedEmails();
  }, [debouncedSearchText, page, flag]);

  const fetchBlockedEmails = async () => {
    setLoading(true);
    try {
      const res = await call(
        {
          ...GET_BLOCKED_EMAIL_LIST,
          url: baseURL + GET_BLOCKED_EMAIL_LIST.url,
        },
        {
          email: localDb.getVal("email"),
          searchEmail: debouncedSearchText,
          page,
          limit,
          paymentProviderId: localDb?.getVal("adminRole") === "SuperAdmin" ? localDb?.getVal("userId") : localDb?.getVal('paymentProviderId'),
        }
      );
      if (res.status === 200) {
        setBlockedEmails(res?.data?.results);
        setTotal(res?.data?.totalCount);
      }
    } catch (error) {
      console.error("Error fetching blocked emails:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEmail = async () => {
    setLoading(true);
    try {
      const res = await call(
        {
          ...DELETE_BLOCKED_EMAIL_NEW,
          url: baseURL + DELETE_BLOCKED_EMAIL_NEW.url,
        },
        {
          email: localDb.getVal("email"),
          existingEmail: deleteEmail,
          paymentProviderId: localDb?.getVal("adminRole") === "SuperAdmin" ? localDb?.getVal("userId") : localDb?.getVal('paymentProviderId'),
        }
      );
      if (res.status === 200) {
        fetchBlockedEmails();
      }
    } catch (error) {
      console.error("Error deleting email:", error);
    } finally {
      setLoading(false);
      setOpenDeleteDialog(false);
    }
  };

  const handlePageChange = (event, value) => setPage(value);

  const totalPages = Math.ceil(total / limit);

  return (
    <HeaderAndFooterLayout>
       <CommonHeader
        name="Blocked Emails"
      />
    <div className="TabContainer">
      {loading && <Loading />}
      <div className="pt-3">
        <div className="transactionFilter">
          <input
            type="text"
            className="transactionFilter__input"
            value={searchedText}
            onChange={(e) => setSearchedText(e.target.value)}
            placeholder="Search Email"
          />
        </div>
        <TableContainer component={Paper} className="mt-3">
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Blocked Email List</StyledTableCell>
                <StyledTableCell>
                  <button onClick={() => setOpenAddDialog(true)} className="icon">
                    <AddIcon />
                  </button>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blockedEmails?.length > 0 ? (
                blockedEmails?.map(({blockedEmail}, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{blockedEmail || "N/A"}</StyledTableCell>
                    <StyledTableCell>
                      <DeleteIcon
                        className="text-danger cursor-pointer"
                        onClick={() => {
                          setOpenDeleteDialog(true);
                          setDeleteEmail(blockedEmail);
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={2} align="center">
                    No Data Available
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="mt-4 flex justify-center">
          <Pagination count={totalPages} onChange={handlePageChange} />
        </div>
      </div>

      {openAddDialog && (
        <Dialog
          open={openAddDialog}
          onClose={() => setOpenAddDialog(false)}
        >
          <AddBlockedEmail
            onClose={() => setOpenAddDialog(false)}
            onSuccessful = {()=> setFlag((prev) => !prev)}
          />
        </Dialog>
      )}

      {openDeleteDialog && (
        <DeletingAlert
          onHide={() => setOpenDeleteDialog(false)}
          onDelete={handleDeleteEmail}
          show={openDeleteDialog}
        />
      )}
    </div>
    </HeaderAndFooterLayout>
  );
};

export default BlockEmailList;
