import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loading from "../../../../../common/Loading";
import TabButton from "../../../../../common/TabButton";
import { call, logoutAfterError } from "../../../../../../config/axios";
import localDb from "../../../../../../localDb";
import { GET_GLOBAL_SUCCESS_ROUTING_BY_ACQUIRER } from "../../../../../../config/endpoints";

const searchicon = "/cryptonpay/crytoicons/searchicon1.png";
import Pagination from "@mui/material/Pagination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0000ff",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SuccessRoutingTab = ({ acquirerServiceData }) => {
  const baseURL = useSelector((state) => state.config.api_url);
  const adminRole = localStorage.getItem("adminRole");
  const emailFromLocal = localDb.getVal("email");
  const [acquirerId, setAcquirerId] = useState(
    acquirerServiceData[0]?.acquirerSetting?.acquirerName || "",
  );
  const [tabs, setTabs] = useState(0);
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const limit = 10;

  useEffect(() => {
    getSuccessRoutingByAcquirer((page - 1) * limit);
  }, [tabs]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getSuccessRoutingByAcquirer((page - 1) * limit);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [searchedText]);

  const getSuccessRoutingByAcquirer = async (offsetFromParams) => {
    try {
      setLoading(true);
      const payload = {
        email: emailFromLocal,
        adminRole: adminRole,
        search: searchedText,
        limit,
        offset: offsetFromParams,
        acquirerName: acquirerId,
      };
      const response = await call(
        {
          ...GET_GLOBAL_SUCCESS_ROUTING_BY_ACQUIRER,
          url: baseURL + GET_GLOBAL_SUCCESS_ROUTING_BY_ACQUIRER.url,
        },
        payload,
      );

      if (response?.status === 200) {
        setValues(response?.data?.successRoutingResult || []);
        setTotal(response?.data?.totalCount || 0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleTabSwitch = (index, itemId) => {
    setAcquirerId(itemId?.acquirerSetting?.acquirerName);
    setTabs(index);
    setSearchedText("");
    setPage(1);
    setTotal(0);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getSuccessRoutingByAcquirer((value - 1) * limit);
  };

  const totalPages = Math.ceil(total / limit);

  return (
    <div className="TabContainer">
      {loading && <Loading />}

      <div className="acquireServiceScroll">
        {acquirerServiceData?.map((tab, index) => (
          <TabButton
            key={index}
            activeTab={tabs}
            handleTabSwitch={handleTabSwitch}
            tab={tab?.acquirerSetting}
            index={index}
            itemId={tab}
          />
        ))}
      </div>

      {acquirerServiceData?.map((items, index) => {
        return (
          <div key={index}>
            {tabs === index && (
              <div className="pt-3">
                <div className="transactionFilter__allMerchants successRoutingFilter">
                  <div className="transactionFilter">
                    <input
                      type="text"
                      className="transactionFilter__input successRoutingFilter"
                      value={searchedText}
                      name="searchText"
                      onChange={(e) => {
                        setSearchedText(e?.target?.value);
                        // setPage(1);
                      }}
                      placeholder="Search BIN & Billing Country, Card Type, Transaction Range or Masked PAN"
                    />
                    <img
                      src={`${imageBaseUrl}${searchicon}`}
                      alt="searchicon"
                      className="transactionFilter__icon"
                    />
                  </div>
                </div>
                <TableContainer component={Paper} className="mt-3">
                  <Table sx={{ width: "100%" }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">
                          BIN Country
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Billing Country
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Card type
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Transaction Range
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          IIN Number
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Transaction Count
                        </StyledTableCell>
                        <StyledTableCell align="left">Rate</StyledTableCell>
                        <StyledTableCell align="left">Status</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values?.status?.map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="left">
                            {row.binCountry || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.billingCountry || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.cardType || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.transactionRange || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.maskedPan || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.transactionCount || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.rate || 0} %
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.status || "NA"}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {values?.length == 0 && (
                    <div className="flex items-center justify-center py-4">
                      <p className="font-small text-xl">No Data available</p>
                    </div>
                  )}
                </TableContainer>
                <div className="mt-4 flex items-center justify-center">
                  <Pagination
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SuccessRoutingTab;
