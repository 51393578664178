import React, { useEffect, useState } from "react";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import Select from "react-select";
import localDb from "../../../../localDb";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  SAVE_MERCHANT_EMAIL_WHITELIST,
  GET_ALL_MERCHANTS_LIST,
  GET_MERCHANT_EMAIL_WHITELIST,
} from "../../../../config/endpoints";
import { useSelector } from "react-redux";
import Loading from "../../../common/Loading";
import { isViewPermissionValid } from "../../../common/CommonUtils";
import MerchantEmailWhiteListTable from "./MerchantEmailWhiteListTable";
import moment from "moment";
import useDebounce from "../../global/useDebounce";
const searchicon = "/cryptonpay/crytoicons/searchicon1.png";

const title = "Merchant Email Whitelist";

const MerchantEmailWhiteList = () => {
  const email = localDb.getVal("email");
  const token = localDb.getVal("token");
  const adminRole = localDb.getVal("adminRole");

  const baseUrl = useSelector((state) => state.config.api_url);
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);

  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("upload csv file");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [allMerchants, setAllMerchants] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [orderBy, setOrderBy] = useState("asc");
  const [sortBy, setSortBy] = useState("createdAt");
  const [searchText, setSearchText] = useState("");

  const disabled = selectedMerchant && file;

  const getOptions = (merchants) => {
    if (merchants && merchants.length > 0) {
      return merchants.map((merchant) => ({
        label: merchant?.name,
        value: merchant?.merchantId,
      }));
    }
  };

  const handleFileChange = async (event) => {
    setError("");
    const selectedFile = await event.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFileName(selectedFile.name);
      setFile(selectedFile);
    } else {
      alert("Please upload a CSV file.");
      event.target.value = null;
    }
    event.target.value = null;
  };

  const getMerchantList = async () => {
    setLoading(true);
    await call(
      {
        ...GET_ALL_MERCHANTS_LIST,
        url: baseUrl + GET_ALL_MERCHANTS_LIST.url,
      },
      { email: email },
    )
      .then(async (res) => {
        if (res.status === 200) {
          const result = await getOptions(res?.data?.merchants);
          setAllMerchants(result);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        logoutAfterError();
      });
  };

  const handleSubmit = async () => {
    setError("");
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("email", email);
    formData.append("token", token);
    formData.append("adminRole", adminRole);
    formData?.append("merchantId", selectedMerchant?.value);
    const url = baseUrl + SAVE_MERCHANT_EMAIL_WHITELIST.url;
    const viewPermission = localDb.getVal('viewPermission');
    await fetch(url,{
      method: SAVE_MERCHANT_EMAIL_WHITELIST.method,
      body: formData,
      headers: {
        'x-access-token': token || '',
        'x-view-permission': viewPermission || ''
      }
    })
      .then(async (res) => {
        if (res?.status == 406) {
          setLoading(false);
          setError(`something went wrong`);
        } else {
          setLoading(false);
          setSelectedMerchant("");
          setFile(null);
          setFileName("upload csv file");
        }
        await getMerchantsEmailWhiteList();
      })
      .catch((err) => {
        setLoading(false);
        setError(`something wen't wrong`);
      });
  };

  const getMerchantsEmailWhiteList = async (page) => {
    const pageNo = page || 1;

    const filterParams = {
      page: pageNo,
      sortBy: sortBy,
      orderBy: orderBy,
      merchantId: searchText,
      email: searchText,
    };
    const payload = {
      email,
      filterParams
    };
    setLoading(true);
    await call(
      {
        ...GET_MERCHANT_EMAIL_WHITELIST,
        url: baseUrl + GET_MERCHANT_EMAIL_WHITELIST.url,
      },
      payload,
    )
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setData(res.data?.emailWhitelist);
          setTotalCount(res.data?.totalCount);
        }
      })
      .catch((err) => {
        setLoading(false);
        logoutAfterError(err);
      });
  };
  const debouncedSearchText = useDebounce(searchText);
  useEffect(() => {
    getMerchantList();
  }, []);
  useEffect(() => {
    getMerchantsEmailWhiteList(1);
  }, [sortBy, orderBy, debouncedSearchText]);
  return (
    <HeaderAndFooterLayout>
      <CommonHeader name={title} />
      <div className="merchan-whitelist-card-container">
        <div className="select-merchant">
          <div className="select-merchant-label">Select Merchant</div>
          <Select
            className="select-dropdown"
            options={allMerchants}
            defaultValue={selectedMerchant}
            onChange={(event) => {
              setSelectedMerchant(event);
            }}
          />
        </div>
        <div className="select-merchant">
          <div className="select-merchant-label">Upload csv file</div>
          <input
            className="select__file"
            type="file"
            accept=".csv"
            id="csvFileUpload"
            onChange={handleFileChange}
          ></input>
          <label
            className={`input__lable ${isViewPermissionValid ? "disabled" : ""}`}
            htmlFor="csvFileUpload"
          >
            <div data-e2e="fileUpload">{fileName}</div>
            <div>
              <i className="fa fa-upload" aria-hidden="true"></i>
            </div>
          </label>
          {error != "" && <div className="uploadCSV_error">{error}</div>}
        </div>
        <button
          disabled={!disabled}
          className={
            !disabled
              ? "opacity_sumbit upload_submit select-merchant-submit"
              : "upload_submit select-merchant-submit"
          }
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      {/* table */}
      <div className="transactionFilter MerchantWhiteListCardsTable">
        <input
          type="text"
          className="transactionFilter__input"
          value={searchText}
          name="searchText"
          data-e2e="search-textbox"
          onChange={(e) => {
            e.target.value.trim() !== ""
              ? setSearchText(e?.target?.value)
              : setSearchText("");
          }}
          placeholder="Search MerchantId and Email..."
        />
        <img
          src={`${imageBaseUrl}${searchicon}`}
          alt="searchicon"
          className="transactionFilter__icon"
          onClick={() => {
            getMerchantList();
          }}
        />
      </div>
      <div className="MerchantWhiteListCardsTable">
        <MerchantEmailWhiteListTable
          data={data}
          changeOrder={(sort) => {
            if (sortBy !== sort) {
              setOrderBy("desc");
              setSortBy(sort);
            } else {
              setOrderBy(orderBy === "desc" ? "asc" : "desc");
            }
          }}
          setPage={(page) => {
            setPage(page);
            getMerchantsEmailWhiteList(page);
          }}
          page={page}
          totalCount={totalCount}
        />
      </div>
      {loading && <Loading />}
    </HeaderAndFooterLayout>
  );
};

export default MerchantEmailWhiteList;
