import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCountryCodesFromDb } from "../../../../global/helpers";
import IOSSwitch from "./IOSSwitch.js";
import TabButton from "../../../../../common/TabButton";
import Loading from "../../../../../common/Loading";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";
import constant from "../../../../global/constant.js";
import CPMultiCountryCode from "./CPMultiCountryCode.js";
import ItemComponent from "./ItemComponent.js";
import {
  TOGGLE_BLOCKED_BIN_COUNTRIES_ACTIVATION,
  TOGGLE_BLOCKED_USER_COUNTRIES_ACTIVATION,
  TOGGLE_BLOCKED_CARD_SCHEMES_ACTIVATION,
  TOGGLE_ALLOWED_CURRENCIES_ACTIVATION,
  TOGGLE_MANDATORY_FIELDS_ACTIVATION,
  TOGGLE_AUTO_TRADE,
} from "../../../../../../config/endpoints.js";
import localDb from "../../../../../../localDb";
import { call, logoutAfterError } from "../../../../../../config/axios";
import BlockedBINListTable from "../BlockedBINListTable.js";

function RulesTab(props) {
  const { acquirerServiceData, setReload, reload } = props;
  const cardSchemeData = [
    { name: "Visa" },
    { name: "Mastercard" },
    { name: "American Express" },
    { name: "UnionPay" },
    { name: "JCB" },
    { name: "Diners" },
  ];
  let AllCurrenciesData = [{ name: "USD" }, { name: "EUR" }, { name: "GBP" }];
  let AllMandatoryFieldsData = [
    { name: "ipAddress" },
    { name: "payerAddress" },
    { name: "payerState" },
    { name: "payerCountry" },
    { name: "payerCity" },
    { name: "payerZip" },
    { name: "payerPhone" },
    { name: "merchantUserEmail" },
    { name: "merchantTransactionId" },
    { name: "browser_timezone" },
    { name: "browser_color_depth" },
    { name: "browser_language" },
    { name: "browser_screen_height" },
    { name: "browser_screen_width" },
    { name: "os" },
    { name: "browserUserAgent" },
  ];
  const baseURL = useSelector((state) => state.config.api_url);
  const imageBaseURL = useSelector((state) => state.config.image_base_url);
  const [tabs, setTabs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [blockedCountryDropDown, setBlockedCountryDropDown] = useState(false);
  const [blockedCardSchemeDropDown, setBlockedCardSchemeDropDown] =
    useState(false);
  const [allowedCurrenciesDropDown, setAllowedCurrenciesDropDown] =
    useState(false);
  const [ipAddressTypesDropDown, setIpAddressTypesDropDown] = useState(false);
  const [mandatoryFieldsDropDown, setMandatoryFieldsDropDown] = useState(false);
  const [blockedMerchentCodeDropDown, setBlockedMerchentCodeDropDown] =
    useState(false);

  const [selectedCardCountryCode, setSelectedCardCountryCode] = useState([]);
  const [selectedMerchantCode, setSelectedMerchantCode] = useState([]);
  const [selectedCardScheme, setSelectedCardScheme] = useState([]);
  const [selectedAllowedCurrencies, setSelectedAllowedCurrencies] = useState(
    [],
  );
  const [selectedIpAddressTypes, setSelectedIpAddressTypes] = useState([]);
  const [selectedMandatoryFields, setSelectedMandatoryFields] = useState([]);
  const [countryCodesData, setCountryCodesData] = useState([]);
  const [merchentCodesData, setMerchentCodesData] = useState([]);

  const handleTabSwitch = (index) => {
    setTabs(index);
  };

  const onClickSwitch = (e, item) => {
    const payload = {
      isActive: !e?.target?.checked ? false : true,
      acquirerUniqueId: item?.acquirerUniqueId,
    };
    handletoggleActiveCountryCode(payload);
  };
  const handletoggleActiveCountryCode = async (payload) => {
    const data = {
      ...payload,
      email: localDb.getVal("email"),
    };
    setLoading(true);
    try {
      const res = await call(
        {
          ...TOGGLE_BLOCKED_BIN_COUNTRIES_ACTIVATION,
          url: baseURL + TOGGLE_BLOCKED_BIN_COUNTRIES_ACTIVATION.url,
        },
        data,
      );

      if (res?.status === 200) {
        setReload(!reload);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const onClickSwitchBlockedUser = (e, item) => {
    const payload = {
      isActive: !e?.target?.checked ? false : true,
      acquirerUniqueId: item?.acquirerUniqueId,
    };
    handletoggleActiveBlockedUserCountryCode(payload);
  };
  const handletoggleActiveBlockedUserCountryCode = async (payload) => {
    const data = {
      ...payload,
      email: localDb.getVal("email"),
    };
    setLoading(true);
    try {
      const res = await call(
        {
          ...TOGGLE_BLOCKED_USER_COUNTRIES_ACTIVATION,
          url: baseURL + TOGGLE_BLOCKED_USER_COUNTRIES_ACTIVATION.url,
        },
        data,
      );

      if (res?.status === 200) {
        setReload(!reload);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const onClickSwitchBlockedSchema = (e, item) => {
    const payload = {
      isActive: !e?.target?.checked ? false : true,
      acquirerUniqueId: item?.acquirerUniqueId,
    };
    handletoggleActiveBlockedCardSchemaCode(payload);
  };
  const onClickSwitchCurrencies = (e, item) => {
    const payload = {
      isActive: !e?.target?.checked ? false : true,
      acquirerUniqueId: item?.acquirerUniqueId,
    };
    handletoggleActiveCurrencies(payload);
  };
  const handletoggleActiveCurrencies = async (payload) => {
    const data = {
      ...payload,
      email: localDb.getVal("email"),
    };
    setLoading(true);
    try {
      const res = await call(
        {
          ...TOGGLE_ALLOWED_CURRENCIES_ACTIVATION,
          url: baseURL + TOGGLE_ALLOWED_CURRENCIES_ACTIVATION.url,
        },
        data,
      );

      if (res?.status === 200) {
        setReload(!reload);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  
  const handleSwitchAutoTrade = (e, item) => {
    const payload = {
      isActive: !e?.target?.checked ? false : true,
      acquirerUniqueId: item?.acquirerUniqueId
    }
    handletoggleActiveAutoTrade(payload)
  }
  const handletoggleActiveAutoTrade = async (payload) => {
    const data = {
      ...payload,
      email: localDb.getVal("email")
    }
    setLoading(true)
    try {
      const res = await call(
        {
          ...TOGGLE_AUTO_TRADE,
          url: baseURL + TOGGLE_AUTO_TRADE.url
        },
        data   
      )
      if (res?.status === 200) {
        setReload(!reload)
      }
    } catch (error) {
      console.log("handletoggleActiveAutoTrade function : ", error)
    } finally {
      setLoading(false)
    }
  }

  const handleSwitchMandatoryFields = (e, item) => {
    const payload = {
      isActive: !e?.target?.checked ? false : true,
      acquirerUniqueId: item?.acquirerUniqueId,
    };
    handletoggleActiveMandatoryFields(payload);
  };
  const handletoggleActiveMandatoryFields = async (payload) => {
    const data = {
      ...payload,
      email: localDb.getVal("email"),
    };
    setLoading(true);
    try {
      const res = await call(
        {
          ...TOGGLE_MANDATORY_FIELDS_ACTIVATION,
          url: baseURL + TOGGLE_MANDATORY_FIELDS_ACTIVATION.url,
        },
        data,
      );

      if (res?.status === 200) {
        setReload(!reload);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const handletoggleActiveBlockedCardSchemaCode = async (payload) => {
    const data = {
      ...payload,
      email: localDb.getVal("email"),
    };
    setLoading(true);
    try {
      const res = await call(
        {
          ...TOGGLE_BLOCKED_CARD_SCHEMES_ACTIVATION,
          url: baseURL + TOGGLE_BLOCKED_CARD_SCHEMES_ACTIVATION.url,
        },
        data,
      );

      if (res?.status === 200) {
        setReload(!reload);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const fetchData = async () => {
    const countryCode = await getCountryCodesFromDb(baseURL);
    setCountryCodesData(countryCode);
    setMerchentCodesData(countryCode);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const toggleHandler = (currentArray, item, newArray, filterName) => {
    if (
      currentArray?.some((code) => code?.[filterName] === item?.[filterName])
    ) {
      let newList = currentArray?.filter(
        (code) => code?.[filterName] !== item?.[filterName],
      );
      newArray(newList);
    } else {
      let newList = [...currentArray];
      newList.push(item);
      newArray(newList);
    }
  };
  const toggleCardCountry = (item) => {
    return toggleHandler(
      selectedCardCountryCode,
      item,
      setSelectedCardCountryCode,
      "code",
    );
  };
  const toggleCardScheme = (item) => {
    return toggleHandler(
      selectedCardScheme,
      item,
      setSelectedCardScheme,
      "name",
    );
  };
  const toggleAllowedCurrency = (item) => {
    return toggleHandler(
      selectedAllowedCurrencies,
      item,
      setSelectedAllowedCurrencies,
      "name",
    );
  };
  const toggleMerchantCode = (item) => {
    return toggleHandler(
      selectedMerchantCode,
      item,
      setSelectedMerchantCode,
      "code",
    );
  };
  const toggleMandatoryFields = (item) => {
    return toggleHandler(
      selectedMandatoryFields,
      item,
      setSelectedMandatoryFields,
      "name",
    );
  };

  const toggleIpAddressTypes = (item) => {
    if (selectedIpAddressTypes?.some((type) => type === item)) {
      const newList = selectedIpAddressTypes?.filter((type) => type !== item);
      setSelectedIpAddressTypes(newList);
    } else {
      const newList = [...selectedIpAddressTypes];
      newList.push(item);
      setSelectedIpAddressTypes(newList);
    }
  };
  useEffect(() => {
    const item = acquirerServiceData[tabs];
    setSelectedCardCountryCode(item?.rules?.blockedBinCountries);
    setSelectedMerchantCode(item?.rules?.blockedUserCountries);
    setSelectedCardScheme(item?.rules?.blockedCardSchemes);
    setSelectedAllowedCurrencies(item?.rules?.allowedCurrencies);
    setSelectedIpAddressTypes(item?.rules?.ipAddressTypes);
    setSelectedMandatoryFields(item?.rules?.mandatoryFields);
    props?.setReload(!props?.reload);
  }, [tabs]);
  return (
    <div className="TabContainer d-flex flex-column">
      {loading && <Loading />}
      <>
        {acquirerServiceData ? (
          <div className="acquireServiceScroll">
            {acquirerServiceData?.map((tab, index) => (
              <TabButton
                key={index}
                activeTab={tabs}
                handleTabSwitch={handleTabSwitch}
                tab={tab?.acquirerSetting}
                index={index}
              />
            ))}
          </div>
        ) : (
          <div className="w-full text-center">
            No Acquirers found ... Something went wrong
          </div>
        )}
      </>
      <>
        {acquirerServiceData?.length !== 0 ? (
          <>
            {acquirerServiceData?.map((item, index) => {
              return (
                <div key={index} className="w-full">
                  {tabs === index ? (
                    <>
                      <div className="border-b">
                        <h3 className="px-2 py-4 text-lg">
                          {item?.acquirerSetting?.acquireName}
                        </h3>
                      </div>
                      <div className="rulesTab">
                        <div className="w-80">
                          <div className="text-[16px] font-medium">
                            Blocked BIN countries
                          </div>
                          <div className="text-[12px] text-gray-700">
                            Select the countries that should be blocked from
                            BINN deposit
                          </div>
                        </div>
                        <div
                          className={`mx-6 ${isViewPermissionValid ? "disabled" : ""}`}
                        >
                          <IOSSwitch
                            defaultChecked={
                              item?.rules?.blockedBinCountriesActive
                            }
                            onClick={(e) =>
                              isViewPermissionValid
                                ? null
                                : onClickSwitch(e, item)
                            }
                          />
                        </div>
                        <div className="ml-8 w-2/5">
                          <div className="mb-2 text-[14px] ">Location</div>
                          <div
                            className={`FilterModalInputDropdown ${isViewPermissionValid ? "disabled" : ""}`}
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setBlockedCountryDropDown(true)
                            }
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="crypto-currency-expand"
                            >
                              {countryCodesData?.length ===
                                selectedCardCountryCode?.length ||
                              selectedCardCountryCode?.length === 0
                                ? "Select options"
                                : `${selectedCardCountryCode?.length} Selected`}
                            </div>
                            <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                          </div>

                          {blockedCountryDropDown ? (
                            <CPMultiCountryCode
                              codeId={item}
                              dataE2eLabel="crypto-urrency-"
                              items={countryCodesData}
                              field="cardCountryCode"
                              selectedItems={selectedCardCountryCode}
                              setShowDropDown={setBlockedCountryDropDown}
                              setSelectedItems={(items) => {
                                setSelectedCardCountryCode(items || []);
                              }}
                              ItemComponent={({ item: code }) => {
                                return (
                                  <ItemComponent
                                    toggleAllowed={toggleCardCountry}
                                    item={code}
                                    selectedArray={selectedCardCountryCode}
                                  />
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="rulesTab">
                        <div className="w-80">
                          <div className="text-[16px] ">
                            Blocked user countries
                          </div>
                          <div className="text-[12px] text-gray-700">
                            Select the countries that should be blocked from
                            BINN deposit
                          </div>
                        </div>
                        <div
                          className={`mx-6 ${isViewPermissionValid ? "disabled" : ""}`}
                        >
                          <IOSSwitch
                            defaultChecked={
                              item?.rules?.blockedUserCountriesActive
                            }
                            onClick={(e) =>
                              isViewPermissionValid
                                ? null
                                : onClickSwitchBlockedUser(e, item)
                            }
                          />
                        </div>
                        <div className="ml-8 w-2/5">
                          <div className="mb-2 text-[14px] ">Location</div>
                          <div
                            className={`FilterModalInputDropdown ${isViewPermissionValid ? "disabled" : ""}`}
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setBlockedMerchentCodeDropDown(true)
                            }
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="crypto-currency-expand"
                            >
                              {countryCodesData?.length ===
                                selectedMerchantCode?.length ||
                              selectedMerchantCode?.length === 0
                                ? "Select options"
                                : `${selectedMerchantCode?.length} Selected`}
                            </div>
                            <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                          </div>
                          {blockedMerchentCodeDropDown ? (
                            <CPMultiCountryCode
                              codeId={item}
                              dataE2eLabel="crypto-urrency-"
                              items={merchentCodesData}
                              field="userCardCountryCode"
                              selectedItems={selectedMerchantCode}
                              setShowDropDown={setBlockedMerchentCodeDropDown}
                              setSelectedItems={(items) => {
                                setSelectedMerchantCode(items || []);
                              }}
                              ItemComponent={({ item: code }) => {
                                return (
                                  <ItemComponent
                                    toggleAllowed={toggleMerchantCode}
                                    item={code}
                                    selectedArray={selectedMerchantCode}
                                  />
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>

                      <div className="rulesTab">
                        <div className="w-80">
                          <div className="text-[16px] font-medium">
                            Blocked Card scheme
                          </div>
                          <div className="text-[12px] text-gray-700">
                            Select the Card scheme that should be blocked from
                            BINN deposit
                          </div>
                        </div>
                        <div
                          className={`mx-6 ${isViewPermissionValid ? "disabled" : ""}`}
                        >
                          <IOSSwitch
                            defaultChecked={
                              item?.rules?.blockedCardSchemesActive
                            }
                            onClick={(e) =>
                              isViewPermissionValid
                                ? null
                                : onClickSwitchBlockedSchema(e, item)
                            }
                          />
                        </div>
                        <div className="ml-8 w-2/5">
                          <div className="mb-2 text-[14px] ">Card scheme</div>
                          <div
                            className={`FilterModalInputDropdown ${isViewPermissionValid ? "disabled" : ""}`}
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setBlockedCardSchemeDropDown(true)
                            }
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="crypto-currency-expand"
                            >
                              {cardSchemeData?.length ===
                              selectedCardScheme?.length
                                ? "All Selected"
                                : selectedCardScheme?.length === 0
                                  ? "Select options"
                                  : `${selectedCardScheme?.length} Selected`}
                            </div>
                            <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                          </div>

                          {blockedCardSchemeDropDown ? (
                            <CPMultiCountryCode
                              codeId={item}
                              dataE2eLabel="crypto-urrency-"
                              items={cardSchemeData}
                              field="cardSchemeData"
                              selectedItems={selectedCardScheme}
                              setShowDropDown={setBlockedCardSchemeDropDown}
                              setSelectedItems={(items) => {
                                setSelectedCardScheme(items || []);
                              }}
                              ItemComponent={({ item }) => {
                                return (
                                  <ItemComponent
                                    toggleAllowed={toggleCardScheme}
                                    item={item}
                                    selectedArray={selectedCardScheme}
                                  />
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>

                      <div className="rulesTab">
                        <div className="w-80">
                          <div className="text-[16px] font-medium">
                            Allowed Currencies
                          </div>
                          <div className="text-[12px] text-gray-700">
                            Select the allowed currencies for the acquirer.
                          </div>
                        </div>
                        <div
                          className={`mx-6 ${isViewPermissionValid ? "disabled" : ""}`}
                        >
                          <IOSSwitch
                            defaultChecked={
                              item?.rules?.allowedCurrencyListActive
                            }
                            onClick={(e) =>
                              isViewPermissionValid
                                ? null
                                : onClickSwitchCurrencies(e, item)
                            }
                          />
                        </div>
                        <div className="ml-8 w-2/5">
                          <div className="mb-2 text-[14px] ">Currencies</div>
                          <div
                            className={`FilterModalInputDropdown ${isViewPermissionValid ? "disabled" : ""}`}
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setAllowedCurrenciesDropDown(true)
                            }
                          >
                            <div
                              className="FilterModalInputText"
                              // data-e2e="crypto-currency-expand"
                            >
                              {AllCurrenciesData?.length ===
                              selectedAllowedCurrencies?.length
                                ? "All Selected"
                                : selectedAllowedCurrencies?.length === 0
                                  ? "Select options"
                                  : `${selectedAllowedCurrencies?.length} Selected`}
                            </div>
                            <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                          </div>

                          {allowedCurrenciesDropDown ? (
                            <CPMultiCountryCode
                              codeId={item}
                              dataE2eLabel="crypto-urrency-"
                              items={AllCurrenciesData}
                              field="AllCurrenciesData"
                              selectedItems={selectedAllowedCurrencies}
                              setShowDropDown={setAllowedCurrenciesDropDown}
                              setSelectedItems={(items) => {
                                setSelectedAllowedCurrencies(items || []);
                              }}
                              ItemComponent={({ item: item }) => {
                                return (
                                  <ItemComponent
                                    toggleAllowed={toggleAllowedCurrency}
                                    item={item}
                                    selectedArray={selectedAllowedCurrencies}
                                  />
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="rulesTab">
                        <div className="w-80">
                          <div className="text-[16px] font-medium">
                            Ip Address Types
                          </div>
                          <div className="text-[12px] text-gray-700">
                            Select the IP Address types for the acquirer. (By
                            default both types will be checked)
                          </div>
                        </div>
                        <div className={`mx-6 ${"disabled"}`}>
                          <IOSSwitch
                            defaultChecked={true}
                            onClick={(e) => null}
                          />
                        </div>
                        <div className="ml-8 w-2/5">
                          <div className="mb-2 text-[14px] ">
                            Ip Address Types
                          </div>
                          <div
                            className={`FilterModalInputDropdown ${isViewPermissionValid ? "disabled" : ""}`}
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setIpAddressTypesDropDown(true)
                            }
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="crypto-currency-expand"
                            >
                              {constant?.AllIpAddressTypesData?.length ===
                              selectedIpAddressTypes?.length
                                ? "All Selected"
                                : selectedIpAddressTypes?.length === 0
                                  ? "Select options"
                                  : `${selectedIpAddressTypes?.length} Selected`}
                            </div>
                            <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                          </div>

                          {ipAddressTypesDropDown ? (
                            <CPMultiCountryCode
                              codeId={item}
                              dataE2eLabel="crypto-urrency-"
                              items={constant.AllIpAddressTypesData}
                              field="AllIpAddressTypesData"
                              selectedItems={selectedIpAddressTypes}
                              setShowDropDown={setIpAddressTypesDropDown}
                              setSelectedItems={(items) => {
                                setSelectedIpAddressTypes(items || []);
                              }}
                              ItemComponent={({ item: item }) => {
                                return (
                                  <ItemComponent
                                    toggleAllowed={toggleIpAddressTypes}
                                    item={item}
                                    selectedArray={selectedIpAddressTypes}
                                  />
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="rulesTab">
                        <div className="namcColumn w-80">
                          <div className="text-[16px] font-medium">
                            Auto-Trade Setting
                          </div>
                          <div className="text-[12px] text-gray-700">
                            Configure the required auto-trade setting
                          </div>
                        </div>
                        <div
                          className={`switchColumn mx-6 ${isViewPermissionValid ? "disabled" : ""}`}
                        >
                          <IOSSwitch
                            defaultChecked={
                              item?.rules?.autoTradeActive ? item?.rules?.autoTradeActive : false
                            }
                            onClick={(e) => {
                              console.warn(item)
                              handleSwitchAutoTrade(e, item)
                            }
                            }
                          />
                        </div>
                        {/* This empty - "div" below is purposeful for alignment of the ios-switch */}
                        <div className="ml-8 w-2/5" ></div>
                      </div>
                      <div className="rulesTab">
                        <div className="namcColumn w-80">
                          <div className="text-[16px] font-medium">
                            Mandatory Fields
                          </div>
                          <div className="text-[12px] text-gray-700">
                            Select the all mandatory fields for the acquirer.
                          </div>
                        </div>
                        <div
                          className={`switchColumn mx-6 ${isViewPermissionValid ? "disabled" : ""}`}
                        >
                          <IOSSwitch
                            defaultChecked={
                              item?.rules?.selectMandatoryFieldsActive
                            }
                            onClick={(e) =>
                              handleSwitchMandatoryFields(e, item)
                            }
                          />
                        </div>
                        <div className="ml-8 w-2/5">
                          <div className="mb-2 text-[14px]">Fields</div>
                          <div
                            className={`FilterModalInputDropdown ${isViewPermissionValid ? "disabled" : ""}`}
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setMandatoryFieldsDropDown(true)
                            }
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="crypto-currency-expand"
                            >
                              {AllMandatoryFieldsData?.length ===
                                selectedMandatoryFields?.length ||
                              selectedMandatoryFields?.length === 0
                                ? "Select options"
                                : `${selectedMandatoryFields?.length} Selected`}
                            </div>
                            <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                          </div>

                          {mandatoryFieldsDropDown ? (
                            <CPMultiCountryCode
                              codeId={item}
                              items={AllMandatoryFieldsData}
                              field="AllMandatoryFieldsData"
                              selectedItems={selectedMandatoryFields}
                              setShowDropDown={setMandatoryFieldsDropDown}
                              setSelectedItems={(items) => {
                                setSelectedMandatoryFields(items || []);
                              }}
                              ItemComponent={({ item: item }) => {
                                return (
                                  <ItemComponent
                                    toggleAllowed={toggleMandatoryFields}
                                    item={item}
                                    selectedArray={selectedMandatoryFields}
                                  />
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              );
            })}
            <BlockedBINListTable
              tab={tabs}
              imageBaseURL={imageBaseURL}
              globalAcquirerData={props?.acquirerServiceData[tabs]}
              acquirerUniqueId={props?.acquirerServiceData[tabs]?.acquirerUniqueId}
              baseURL={baseURL}
            />
          </>
        ) : (
          <div className="p-4 text-center">No Data</div>
        )}
      </>
    </div>
  );
}

export default RulesTab;
