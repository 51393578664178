import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { CHANGE_ADMIN_PASSWORD} from "../../../../config/endpoints";
import { call } from "../../../../config/axios";
import { useSelector } from 'react-redux';

import { Modal } from 'react-bootstrap';
import { isViewPermissionValid } from '../../../common/CommonUtils';


function ChangePassword(props) {
  const base_url = useSelector((state) => state.config.api_url);
  const [visibility, setVisibility] = useState(true);
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState('')
  const [data, setData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError('');
  };

  let disabled = Object.values(data).some((values) => values.trim() === '' || values.length === 0) || error !='';

  const handleChangePassword = async() => {
    if (data.confirmPassword !== data.newPassword) {
      setError(`Password doesn't matched.`);
    }else if(data.confirmPassword === data.oldPassword || data.newPassword === data.oldPassword ){
        setError(`current password and new password are same.`);
    } else{
        let payload ={
            email: localStorage.getItem('email'),
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
        }
        setLoading(true);
        await call(
            {
              ...CHANGE_ADMIN_PASSWORD,
              url: base_url + CHANGE_ADMIN_PASSWORD.url,
            },
            payload
            )
            .then((res) => {
                setLoading(false);
                if(res.data.msg == 'Password Changed Successfully'){                
                    props.onClose();
                    props.handleLogout();
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(`Current password is incorrect!`);
                console.log(err.message, "err");
            });

    }
    
     };

  return (
      <span className='change_password'>
        <Modal onHide={()=>props.onClose()} show={props.open} className="change-model">
            <div className="change-password-modal-header">
            <div className="d-flex justify-content-between ">
                <div className="change-password-heading">Change Password</div>
                <div className="signup-close-icon" data-e2e="close-icon">
                <ClearIcon onClick={()=>props.onClose()} className="icon-close cursor-pointer" />
                </div>
            </div>
            </div>
            <div className="p-4">
            <div>
                <div className="change-Password-input-label">{`Current Password`}</div>
                <div className="mt-3">
                <input
                    type="password"
                    className={`change-password-input ${isViewPermissionValid? "disabled": ""}`}
                    placeholder="Enter current password..."
                    data-e2e="current-password"
                    name="oldPassword"
                    onChange={isViewPermissionValid ?()=>{} : (e) => handleChange(e)}
                />
                </div>
            </div>

            <div>
                <div className="change-Password-input-label mt-4">{`New Password`}</div>
                <div className="mt-3">
                <input
                    type={visibility ? 'password' : 'text'}
                    className={`change-password-input ${isViewPermissionValid? "disabled": ""} `}
                    placeholder="Enter new password..."
                    name="newPassword"
                    onChange={isViewPermissionValid ?()=>{} : (e) => handleChange(e)}
                    data-e2e="new-password"
                />
                <div className="visibility-icon" onClick={() => setVisibility(!visibility)}>
                    {visibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </div>
                </div>
            </div>

            <div>
                <div className="change-Password-input-label mt-4">{`Confirm New Password`}</div>
                <div className="mt-3">
                <input
                    type="password"
                    className={`change-password-input ${isViewPermissionValid? "disabled": ""}`}
                    placeholder=" Confirm new password..."
                    name="confirmPassword"
                    onChange={ isViewPermissionValid ?()=>{} : (e) => handleChange(e)}
                    data-e2e="confirm-password"
                />
                </div>
            </div>
            
            {error !== '' && 
                <div className="mt-4 h6 d-flex text-danger justify-content-center">
                    {error}
                </div>
            }
            <div className="mt-4">
                <button
                disabled={disabled}
                className={`${disabled ? 'change-password-disabled-button' : 'change-password-enabled-button'} mt-2  ${isViewPermissionValid? "disabled": ""}`}
                onClick={() => isViewPermissionValid? null: handleChangePassword()}
                data-e2e="change-btn"
                >
                {`CHANGE`}
                </button>
            </div>
            </div>
        </Modal>
      </span>

  );
}


export default ChangePassword;