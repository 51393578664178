import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Button from '@mui/material/Button';  // Make sure this import is added
import FilterListTwoToneIcon from '@mui/icons-material/FilterListTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const CardDashboardDatePicker = ({ fromDate, toDate, setFromDate, setToDate, setDateSelected }) => {
  const handleCallback = (start, end) => {
    setFromDate(moment(start).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
    setToDate(moment(end).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
    setDateSelected(true);
  };

  return (
    <DateRangePicker
      onCallback={handleCallback}
      initialSettings={{
        startDate: moment(fromDate),
        endDate: moment(toDate),
        timePicker: true,
        timePicker24Hour: true,
        timePickerIncrement: 5,
        locale: { format: "YYYY-MM-DD HH:mm:ss" }
      }}
    >
      <Button
        variant="contained"
        startIcon={<CalendarMonthIcon />}
        endIcon={<KeyboardArrowDownIcon />}  // Adjusted for consistency
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          border: '1px solid #d0d5dd',
          borderRadius: '8px',
          padding: '8px 16px',
          textTransform: "none",
          fontWeight: '600',
          fontSize: "14px",
          color: "#333",
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        {`${moment(fromDate).format('ddd, D MMM HH:mm')} - ${moment(toDate).format('ddd, D MMM HH:mm')}`}
      </Button>
    </DateRangePicker>
  );
};

export default CardDashboardDatePicker;
