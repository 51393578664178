import React from "react";
import { useSelector } from "react-redux";
const selectedCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_filled.png";
const emptyCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_empty.png";

function ItemComponent({ toggleAllowed, item, selectedArray }) {
  const imageBaseURL = useSelector((state) => state.config.image_base_url);
  return (
    <div
      className="currencyListItemWrap"
      onClick={() => {
        toggleAllowed(item);
      }}
    >
      <div className="currencyListIconNameWrap">
        <div
          className="currencyList_Name"
          style={{
            textTransform: "capitalize",
          }}
        >
          {item?.name ? item?.name : item}
        </div>
      </div>
      <img
        src={`${imageBaseURL}${
          selectedArray?.some(
            (value) =>
              (value?.name ? value?.name : value) ===
              (item?.name ? item?.name : item),
          )
            ? selectedCheckBoxIcon
            : emptyCheckBoxIcon
        }`}
        alt="checkbox"
        className="currencyListCheckBox"
      />
    </div>
  );
}

export default ItemComponent;
