import React from "react";

function FormInput({
  label,
  name,
  value,
  placeholder,
  setterValue,
}) {
  const handleChange = (e) => {
    setterValue(e.target.value);
  };

  return (
    <div className="form-group myAccoutOptModal_form_group">
      <label
        className="myAccoutOptModalLable"
        htmlFor="exampleFormControlSelect3"
      >
        {label}
      </label>
      <input
        type="text"
        value={value}
        className="form-control inputdropdown"
        placeholder={placeholder}
        name={name}
        onChange={(e) => {
          handleChange(e);
        }}
      />
    </div>
  );
}

export default FormInput;
