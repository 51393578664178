import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dialog from "@mui/material/Dialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { withStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import localDb from "../../../../localDb";
import WalletStatusChangeAlert from "../Wallets/WalletStatusChangeAlert";
import WarningIcon from '@mui/icons-material/Warning';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import GppBad from "@mui/icons-material/GppBad";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Switch from "@mui/material/Switch";
import copy from "copy-to-clipboard";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  MAKE_WEBHOOKS_CALL,
  SEND_MERCHANT_WEBHOOK_NOTIFICATION_FROM_ADMIN,
  UPDATE_WALLET_STATUS,
  VIEW_B2C_SINGLE_USER_WALLET,
  VIEW_SINGLE_MERCHANT_WALLET,
  VIEW_SINGLE_TRANSACTION,
  VIEW_SINGLE_WALLET,
  VIEW_SINGLE_CENTRALISED_WALLET,
  UPDATE_CENTRALISED_WALLET_STATUS,
  GET_CLIENT_PRESIGNED_URL,
  MERCHANT_WALLET_STATUS_UPDATE
} from "../../../../config/endpoints";
import Loading from "../../../common/Loading";
import ChangeTransactionStatus from "../MobileViewCommonComponent/ChangeTransactionStatus";
import TransactionViewEllipsis from "../MobileViewCommonComponent/TransactionViewEllipsis";
import { isViewPermissionValid } from "../../../common/CommonUtils";

const useStyles = styled(Dialog)({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: "white",
    },
  },
});

const StyledMenu = styled((props) => (
  <Menu
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        top: 0,
        mt: 10,
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          width: 10,
          height: 10,
          right: "50%",
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
    }}
    transformOrigin={{ horizontal: "center", vertical: "top" }}
    anchorOrigin={{ horizontal: "right", vertical: "top" }}
    {...props}
  />
))(({ theme }) => ({}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const blockchainExternalLink = "/cryptonpay/external_link_black.png";

function CommonHeader(props) {
  const navigate = useNavigate();

  const { id } = useParams();
  const email = localDb.getVal("email");
  
  const base_url = useSelector((state) => state.config.api_url);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const merchantNameForHeader = useSelector((state) => state.transaction.merchantNameForHeader)

  const [flag, setFlag] = useState(false);

  const BTC_MAINNET = useSelector((state) => state.config.btc_mainnet);
  const BTC_TESTNET = useSelector((state) => state.config.btc_testnet);
  const ETH_MAINNET = useSelector((state) => state.config.eth_mainnet);
  const ETH_SEPOLIA = useSelector((state) => state.config.eth_sepolia);
  const LTC_MAINNET = useSelector((state) => state.config.ltc_mainnet);
  const XRP_MAINNET = useSelector((state) => state.config.xrp_mainnet);
  const TRX_MAINNET = useSelector((state) => state.config.trx_mainnet);
  const TRX_TESTNET = useSelector((state) => state.config.trx_testnet);

  const [currentWalletStatus, setCurrentWalletStatus] = useState("");
  const [updatedWalletStatus, setUpdatedWalletStatus] = useState("");
  const [viewOnBlockChain, setViewOnBlockChain] = useState("");

  const [notificationMsgErr, setNotificationMsgErr] = useState(false);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState("");
  const [isUpdateBalance, setUpdateBalance] = useState(false);
  const [isAutoTrade, setIsAutoTrade] = useState(false);
  const [confirmNotify, setConfirmNotify] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState("");
  const [isHashMode, setIsHashMode] = useState(true);
  const [isTransactionMenu, setIsTransactionMenu] = useState(false);
  const failedTransactionStatus = [
    "WithdrawalFlow:PendingApproval",
    "WalletManagementService:ProcessingWithdrawal",
    "WalletManagementService:WithdrawalCompleted",
    "DepositMonitor:MonitoringMemPool",
  ];
  const statusList = ["Success", "Failed", "Timed Out"];
  const [selectedStatus, setSelectedStatus] = useState(statusList[0]);
  const [selectedCryptoId, setSelectedCryptoId] = useState("");

  const [loading, setLoading] = useState(false);

  const [accept, setAccept] = useState(false);

  const handleAccpetCloseForm = () => {
    setAccept(false);
    setConfirmNotify(false);
    setAlertPopUp(false);
    setSelectedWalletStatus(currentWalletStatus);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [alertPop, setAlertPopUp] = useState(false);
  const [errorMessageStatusUpdate, setErrorMessageStatusUpdate] = useState(null);
  const [alertErrorPop, setErrorAlertPopUp] = useState(false);
  const [notifyMerchant, setNotifyMerchant] = useState(false);
  const [showCoppied, toggleCoppied] = useState(false);
  const [showExecuteModal, setshowExecuteModal] = useState(false);
  const [transHash, setTransHash] = useState("");
  const [webhookApiResponse, setWebHookApiResponse] = useState({
    status: "",
    message: "",
  });
  const [pendingAllowed, setPendingAllowed] = useState(false);
  const [confirmAllowed, setconfirmAllowed] = useState(false);
  const activatedMenuList = useSelector((state) => state.common.header);
  const [timeDropDown, setTimeDropDown] = useState(null)
  const [accountAnchor, setAccountDropDown] = useState(false);
  const [usersMenuList, setUserMenuList] = useState([]);
  const [confirmBlockchainError, setConfirmBlockchainError] = useState("");
  const [historyStatus, setHistoryStatus] = useState(false);
  const open = Boolean(anchorEl || false);
  const [transactionType, setTransactionType] = useState("");
  const [showEllipsisModal, setShowEllipsisModal] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [showEllipisButton, setShowEllipisButton] = useState(false);

  const handleTimeOpenDropdown = (event) => {
    setAccountDropDown((prev) => !prev)
    setTimeDropDown(event.currentTarget);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotifactionStatus = (status) => {
    setSelectedStatus(status);
    setShowStatusDropdown(false);
  };

  const handleStatusChange = (status) => {
    setUpdatedWalletStatus(status);
    setAccept(true);
    handleClose();
  };

  const callUpdateWalletStatus = async () => {
    if(props?.view === "Merchantwallets"){
      await updateMerchantWalletStatus(updatedWalletStatus);
      setFlag(!flag);
    }
    else if (props.view === "centralised wallets") {
        await updateCentralisedWalletStatus(updatedWalletStatus);
        setFlag(!flag)
    } else {
      await updateWalletStatus(updatedWalletStatus);
      setFlag(!flag);
    }
  };

  const callUpdateNofityStatus = () => {
    handleSetAlert();
    viewSingleTransactionDetails();
  };

  const handleSetAlert = () => {
    setTimeout(() => {
      setAlertPopUp(false);
      setNotifyMerchant(false);
    }, 3000);
  };
  const handleSetErrorAlert = () => {
    setTimeout(() => {
      setErrorAlertPopUp(false);
    }, 3000);
  };
  const status_array = [
    {
      status: "Available",
    },
    {
      status: "In Use",
    },
    {
      status: "Locked",
    },
    {
      status: "Full",
    },
  ];
  const getCryptoLink = (network, cryptoId) => {
    let link = network + "-" + cryptoId;
    switch (link) {
      case "mainnet-BTC":
        return BTC_MAINNET;
      case "testnet-BTC":
        return BTC_TESTNET;
      case "mainnet-ETH":
        return ETH_MAINNET;
      case "rinkeby-ETH":
      case "testnet-ETH":
      case "testnet-DAI":
      case "testnet-USDC":
        return ETH_SEPOLIA;
      case "mainnet-LTC":
        return LTC_MAINNET;
      case "mainnet-XRP":
        return XRP_MAINNET;
      case "mainnet-TRX":
      case "mainnet-TRC20-USDT":
        return TRX_MAINNET;
      case "testnet-TRX":
      case "testnet-TRC20-USDT":
        return TRX_TESTNET;
      default:
        return "";
    }
  };

  const sendMerchantNotificationFromAdmin = async () => {
    setSendNotification(false);
    setLoading(true);
    await call(
      {
        ...SEND_MERCHANT_WEBHOOK_NOTIFICATION_FROM_ADMIN,
        url: base_url + SEND_MERCHANT_WEBHOOK_NOTIFICATION_FROM_ADMIN.url,
      },
      {
        email: email,
        transactionId: id,
        statusType: selectedStatus,
        userName: email,
        comment: notificationMsg,
        updateBalance: isUpdateBalance,
        autoTrade: isAutoTrade
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setNotifyMerchant(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };
	

  const updateWalletStatus = async (status) => {
    setLoading(true);
    await call(
      {
        ...UPDATE_WALLET_STATUS,
        url: base_url + UPDATE_WALLET_STATUS.url,
      },
      { email: email, walletId: id, status: status }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          if (res.data.success) {
            setAlertPopUp(true);
            setErrorMessageStatusUpdate(null)
            handleSetAlert();
            props.handleFlag();
          } else {
            setErrorAlertPopUp(true);
            if (res.data.msg) {
              setErrorMessageStatusUpdate(res.data.msg)
            }
            handleSetErrorAlert();
            console.log(res.data.msg, "err");
          }
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const updateMerchantWalletStatus = async (status) => {
    setLoading(true);
    await call(
      {
        ...MERCHANT_WALLET_STATUS_UPDATE,
        url: base_url + MERCHANT_WALLET_STATUS_UPDATE?.url,
      },
      { email: email, walletId: id, status: status }
    )
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          if (res?.data?.success) {
            setAlertPopUp(true);
            setErrorMessageStatusUpdate(null)
            handleSetAlert();
            props.handleFlag();
          } else {
            setErrorAlertPopUp(true);
            if (res?.data?.msg) {
              setErrorMessageStatusUpdate(res?.data?.msg)
            }
            handleSetErrorAlert();
            console.error(res?.data?.msg, "err");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err?.message, "err");
        logoutAfterError(err)
      });
  };

  const updateCentralisedWalletStatus = async (status) => {
    setLoading(true);
    await call(
      {
        ...UPDATE_CENTRALISED_WALLET_STATUS,
        url: base_url + UPDATE_CENTRALISED_WALLET_STATUS.url,
      },
      { email: email, walletId: id, status: status }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          props.handleFlag();
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };


  const makeWebhooksCall = async (status) => {
    if (selectedCryptoId === "LTC" || selectedCryptoId === "XRP" || selectedCryptoId === "BTC") {
    } else {
      if (isHashMode) {
        if (!transHash.length) {
          setConfirmBlockchainError("Please enter hash");
          return;
        }
        if (!pendingAllowed && !confirmAllowed) {
          setConfirmBlockchainError(
            "Please choose pending or confirm to continue"
          );
          return;
        }
        if (
          (historyStatus ||
            transactionStatus === "DepositMonitor:DepositReceivedToMemPool") &&
          !confirmAllowed
        ) {
          setConfirmBlockchainError("Please choose confirm to continue");
          return;
        }
      } else if (!pendingAllowed && !confirmAllowed) {
        setConfirmBlockchainError(
          "Please choose pending or confirm to continue"
        );
        return;
      }
      if (
        (historyStatus ||
          transactionStatus === "DepositMonitor:DepositReceivedToMemPool") &&
        !confirmAllowed
      ) {
        setConfirmBlockchainError("Please choose confirm to continue");
        return;
      }
    }
    let res;
    try {
      setLoading(true);
      res = await call(
        {
          ...MAKE_WEBHOOKS_CALL,
          url: base_url + MAKE_WEBHOOKS_CALL.url,
        },
        {
          txHash: transHash,
          transactionId: props.fullId,
          mode: isHashMode ? "use-hash" : "no-hash",
          runPending:
            historyStatus ||
              transactionStatus === "DepositMonitor:DepositReceivedToMemPool"
              ? false
              : pendingAllowed,
          runConfirmed: confirmAllowed,
          email: email,
        }
      );
      setWebHookApiResponse(res.data);
      setTimeout(() => {
        setWebHookApiResponse({
          status: "",
          message: "",
        });
      }, 5000);
      if (res.status == 200) {
        window.location.reload('/merchants')
      }
    } catch (err) {
      setLoading(false);
      logoutAfterError(err)
    }
  };

  const viewSingleWalletDetails = async () => {
    setLoading(true);
    await call(
      {
        ...VIEW_SINGLE_WALLET,
        url: base_url + VIEW_SINGLE_WALLET.url,
      },
      { email: email, walletId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setViewOnBlockChain(res.data && res.data.wallets);
          setCurrentWalletStatus(
            res.data &&
            res.data.wallets &&
            res.data.wallets.status &&
            res.data.wallets.status.code
          );
          setSelectedWalletStatus(
            res.data &&
            res.data.wallets &&
            res.data.wallets.status &&
            res.data.wallets.status.code
          );
          setLoading(false);
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };
  const viewSingleCentralisedWalletDetails = async () => {
    // setLoading(true);
    await call(
      {
        ...VIEW_SINGLE_CENTRALISED_WALLET,
        url: base_url + VIEW_SINGLE_CENTRALISED_WALLET.url,
      },
      { email: email, walletId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setViewOnBlockChain(res.data && res.data.wallets);
          setCurrentWalletStatus(
            res.data &&
            res.data.wallets &&
            res.data.wallets.status &&
            res.data.wallets.status.code
          );
          setSelectedWalletStatus(
            res.data &&
            res.data.wallets &&
            res.data.wallets.status &&
            res.data.wallets.status.code
          );
          setLoading(false);
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const viewSingleMerchantWalletDetails = async () => {
    setLoading(true);
    await call(
      {
        ...VIEW_SINGLE_MERCHANT_WALLET,
        url: base_url + VIEW_SINGLE_MERCHANT_WALLET.url,
      },
      { email: email, walletId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setViewOnBlockChain(res.data && res.data.wallets);
          setCurrentWalletStatus(
            res.data &&
            res.data.wallets &&
            res.data.wallets.status &&
            res.data.wallets.status.code
          );
          setSelectedWalletStatus(
            res.data &&
            res.data.wallets &&
            res.data.wallets.status &&
            res.data.wallets.status.code
          );

          setLoading(false);
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const viewSingleTransactionDetails = async () => {
    setLoading(true);
    await call(
      {
        ...VIEW_SINGLE_TRANSACTION,
        url: base_url + VIEW_SINGLE_TRANSACTION.url,
      },
      { email: email, transactionId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          if (
            res.data &&
            res.data.transaction &&
            res.data.transaction.status &&
            res.data.transaction.status
          ) {
            let statusHistory = res.data.transaction.status.history;
            let historyStatus =
              statusHistory &&
              statusHistory.length &&
              statusHistory.some(
                (status) =>
                  status.code === "DepositMonitor:DepositReceivedToMemPool"
              );
            if (historyStatus) setHistoryStatus(true);
            setTransactionStatus(res.data.transaction.status.code);
            setTransactionType(res.data.transaction.type);
            setSelectedCryptoId(
              res.data.transaction &&
              res.data.transaction.selected &&
              res.data.transaction.selected.cryptoId &&
              res.data.transaction.selected.cryptoId
            );
          }
          setLoading(false);
          setIsTransactionMenu((prev) => !prev);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const viewSingleB2CWalletDetails = async () => {
    setLoading(true);
    await call(
      {
        ...VIEW_B2C_SINGLE_USER_WALLET,
        url: base_url + VIEW_B2C_SINGLE_USER_WALLET.url,
      },
      { email: email, walletId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          setViewOnBlockChain(res.data && res.data.userWallets);
          setCurrentWalletStatus(
            res.data &&
            res.data.userWallets &&
            res.data.userWallets.status &&
            res.data.userWallets.status.code
          );

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const handleSendNotification = () => {
    if (notificationMsg === "" || notificationMsg.length === 0) {
      setNotificationMsg(true);
      return;
    }
    setConfirmNotify(true);
  };

  const handleSendNotifyForm = () => {
    setSendNotification(true);
    setNotificationMsg("");
    setSelectedStatus(statusList[0]);
  };
  const handleBackButton = () => {
    if (props.view === "b2c-users") {
      navigate("/b2c-users");
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (
      historyStatus ||
      transactionStatus === "DepositMonitor:DepositReceivedToMemPool"
    ) {
      setPendingAllowed(true);
    }
    setUserMenuList([
      ...(props.view === "transactions"
        ? [
          {
            path: "/b2c-user-wallets",
            name: "Add notes",
            func: () => {
              if (props.setAddNote) {
                props.setAddNote(!props.addNote)
              } else {
                return null
              }
            },
          },
        ]
        : []),
      ...(transactionStatus !== "DepositMonitor:DepositCompleted" && transactionType === "deposit"
        ? [
          {
            path: "/b2c-user-wallets",
            name: "Generate receipt",
            func: () => {
              setshowExecuteModal(true);
            },
          },
        ]
        : []),
      ...(
        // !failedTransactionStatus.includes(transactionStatus)
        // ? 
        [
          {
            path: "/b2c-user-wallets",
            name: "Send notification",
            func: () => {
              handleSendNotifyForm();
            },
          },
        ]
     ),
    ]);
  }, [isTransactionMenu]);

  useEffect(() => {
    setUserMenuList([
      ...(props.view === "transactions"
        ? [
          {
            path: "/b2c-user-wallets",
            name: "Add notes",
            func: () => {
              if (props.setAddNote) {
                props.setAddNote(!props.addNote)
              } else {
                return null
              };
            },
          },
        ]
        : []),
    ]);
    // props && props.view === "wallets"
    //   ? viewSingleWalletDetails()
    //   : props.view === "user-wallet"
    //     ? viewSingleB2CWalletDetails()
    //     : props.view === "centralised wallets" ? viewSingleCentralisedWalletDetails() : null;


    if (props && props.view === "wallets") {
      viewSingleWalletDetails();
      setShowEllipisButton(true);
    } else if (props && props.view === "transactions") {
      viewSingleTransactionDetails();
      setShowEllipisButton(true);
    } else if (props.view === "user-wallet") {
      viewSingleB2CWalletDetails();
    } else if (props.view == "Merchantwallets") {
      viewSingleMerchantWalletDetails();
      setShowEllipisButton(true);
    } else if (props.view === "centralised wallets") {
      viewSingleCentralisedWalletDetails();
      setShowEllipisButton(true);
    }
  }, []);

  const handalModal = (index) => {
    if (index === 1) {
      handleSendNotifyForm();
    } else if (index === 2) {
      props.setAddNote(true);
    } else if (index === 3) {
      setShowChangeStatusModal(true);
    } else if (index === 4) {
      setshowExecuteModal(true);
    } else if (index === 5) {
      let url = getCryptoLink(
        viewOnBlockChain && viewOnBlockChain && viewOnBlockChain.network,
        viewOnBlockChain &&
        viewOnBlockChain.cryptoId  && viewOnBlockChain.cryptoId.cryptoId && viewOnBlockChain.cryptoId.cryptoId
      );
      url = `${url}/${viewOnBlockChain &&
        viewOnBlockChain.address &&
        viewOnBlockChain.address.key &&
        viewOnBlockChain.address.key
        }`;
        
      window.open(url, "_blank");
    } else if (index === 6) {
      setChangeWalletStatusModal(true);
    } else if (index === 7) {
      props.handleUpdateSettings();
    }
    setShowEllipsisModal(false);
  };

  const [changeWalletStatusModal, setChangeWalletStatusModal] = useState(false);
  const [changeWalletStatusDropdown, setChangeWalletStatusDropdown] =
    useState(false);
  const [selectedWalletStatus, setSelectedWalletStatus] = useState("");

  let ellipsisBtn = [
    {
      name: "Send notification",
      icon: "/homeb2c/send-notification.svg",
      onClick: handalModal,
      indexComp: 1,
    },
    {
      name: "Add note",
      icon: "/homeb2c/add_note.svg",
      onClick: handalModal,
      indexComp: 2,
    },
    {
      name: "Change Deposit Status",
      icon: "/homeb2c/deposit_img.svg",
      onClick: handalModal,
      indexComp: 3,
    },
    {
      name: "Confirm Blockchain Receipt",
      icon: "/homeb2c/change_status.svg",
      onClick: handalModal,
      indexComp: 4,
    },
    {
      name: "View on blockchain",
      icon: "/homeb2c/view_blockchain.svg",
      onClick: handalModal,
      indexComp: 5,
    },
    {
      name: "Wallet status",
      icon: "/homeb2c/deposit_img.svg",
      onClick: handalModal,
      indexComp: 6,
    },
    {
      name: "Deposit",
      icon: "/homeb2c/change_status.svg",
      onClick: handalModal,
      indexComp: 7,
    },
  ];

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const sendIcon = isMobile
    ? "/homeb2c/send_notification_logo.svg"
    : "/homeb2c/send_icon.png";
  const classes = useStyles;

  const handleCloseExecuteModal = () => {
    setshowExecuteModal(false);
    setIsHashMode(true);
    setConfirmBlockchainError("");
    setTransHash("")
  };
  const getTitleName = (merchantNameForHeader, input) => {
    switch (input) {
      case "merchantName":
        return merchantNameForHeader ? merchantNameForHeader : "";
      default: 
        return input;
   }}

  const defaultMerchantUserImage = `/merchant-profile-image/${window?.location?.pathname?.split("/")[2]}/merchantProfileImage`;
  const [profileImage,setProfileImage] = useState(image_base_url + defaultMerchantUserImage)

  const setMerchantImage = async (img) => {
    if (img === null) {
      alert("please attach image");
      return;
    }
    const payload = {
      adminRole: localDb.getVal("adminRole"),
      email: email,
      merchantId: window.location.pathname.split("/")[2],
      imageType: "merchantProfileImage",
    };
    try {
      setLoading(true)
      const res = await call(
        {
          ...GET_CLIENT_PRESIGNED_URL,
          url: base_url + GET_CLIENT_PRESIGNED_URL.url,
        },
        payload,
      );
      if (res) {
        const signedUrl = res?.data?.data[0];
        const imageType = "jpeg";
        const result = await fetch(signedUrl, {
          method: "PUT",
          body: img,
          headers: {
            "Content-Type": `image/${imageType ? imageType : "jpeg"}`,
            "cache-control": "public, max-age=0",
          },
        });
        if (result) {
          setLoading(false);
          window.location.reload();
        } else {
          setLoading(false);
          console.error("err: ", err);
        }
      } else {
        setLoading(false);
        console.error("err: ", err);
      }
    } catch (err) {
      setLoading(false);
      console.error(err.message, "err");
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className="pageHeader">
			<div className="pageHeader__row">
				<div className="pageHeader__left">
					{props.backButton && (
						<button className="pageHeader__back" type="button" onClick={handleBackButton} data-e2e="back-arrow">
							<ArrowBackIcon />
						</button>
					)}
					<div className="pageHeader__left_title">
						{props.name && <p className="pageHeader__title">{getTitleName(merchantNameForHeader, props?.name)}</p>}
            {props?.MerchantDetailsPage && 
              <div className="myAccDetailsWrap">
                {true ? (
                  <div style={{ position: 'relative' }}>
                    <img className="ml-2 rounded-circle merchantTableProfilePic" src ={profileImage}/>
                    <div
                      className="myAccMerchantIcon"
                      style={{
                        position: 'absolute',
                        backgroundColor: 'transparent',
                        bottom: '0px',
                        right:'0px',
                      }}
                    >
                      <div className="myAccMerchantIconEdit">
                        <label className="m-0" for="changeImg" >
                        <i style={{fontSize:"20px"}} className="bg-light p-1 border border-dark rounded-circle fa fa-camera" />
                        </label>
                      </div>
                      <input
                        type="file"
                        className="myAccMerchantIconEdit myAccMerchantIconInput"
                        accept="image/x-png,image/jpeg,image/jpg,image/png"
                        data-max-size="5000000"
                        id='changeImg'
                        hidden
                        onChange={(e) => setMerchantImage(e.target.files[0])
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div className="myAccMerchantIcon">
                    <div className="myAccMerchantIconEdit">
                      <i className="fa fa-camera" />
                    </div>
                    <input
                      type="file"
                      className="myAccMerchantIconEdit myAccMerchantIconInput"
                      data-max-size="5000000"
                      accept="image/x-png,image/jpeg,image/jpg,image/png"
                      onChange={(e) => {}}
                    />
                  </div>
                )}
              </div>
            }
						{props.copyButton && !showCoppied ? (
							<img
								data-e2e-adress={props && props.name}
								data-e2e="back-arrow-img"
								src={`${image_base_url}/homeb2c/transaction-cpy.png`}
								alt="image"
								className="cursor-pointer"
								style={{ marginLeft: "5px" }}
								onClick={() => {
									props.view === "wallets" || props.view === "centralised wallets"
										? copy(
											viewOnBlockChain &&
											viewOnBlockChain.address &&
											viewOnBlockChain.address.key &&
											viewOnBlockChain.address.key
										)
										: copy(props.fullId);
									toggleCoppied(true);
									setTimeout(() => {
										toggleCoppied(false);
									}, 3000);
								}}
							/>
						) : (
							props.copyButton && (
								<DoneAllIcon
									style={{ color: "#6fbf73", marginLeft: "5px" }}
								/>
							)
						)}
						{/* HouseKeeping status */}
						<div className="ml-2 mr-2 ">
							{
								props.data && props.data.housekeeper ? (props.data.housekeeper.filter(housekeeperobj => props.data.balance.some(balanceobj => housekeeperobj.crypto === balanceobj.cryptoId && housekeeperobj.status === "Unmatched" && (Math.abs(balanceobj.balanceAmount - housekeeperobj.value) / balanceobj.balanceAmount) * 100 <= 5)).length > 0 ? (
									<HtmlTooltip title={
										<ToolTipBalance data={props.data} />
									} placement="bottom">
										<WarningIcon style={{ color: 'orange', fontSize: '18px', }} />
									</HtmlTooltip>
								)
									: (props.data.housekeeper.filter(obj => obj.status === 'Unmatched').length > 0 ?
										(
											<HtmlTooltip title={
												<ToolTipBalance data={props.data} />
											} placement="bottom">
												<WarningIcon style={{ color: 'red', fontSize: '18px', }} />
											</HtmlTooltip>
										)
										:
										(
											<HtmlTooltip title={
												<ToolTipBalance data={props.data} />
											} placement="bottom">
												<CheckBoxIcon style={{ color: 'green', fontSize: '18px', }} />
											</HtmlTooltip>
										)
									)
								) : ''
							}
						</div>
					</div>
					{props.view === "Dashboard" && 
						<div className="pageHeader__buttons pageHeader__dashboard">
							{props.nameTab.length > 0 && props.nameTab.map((ele, i) => {
								return (
									<button key={i} onClick={() => props.onClick(ele.id)} className="pageHeader__select">
										{ele.name}
										<i className="fa fa-angle-down cursor-pointer" aria-hidden="true"></i>
									</button>
								)
							})}
						</div>
					}
				</div>
				<div className="pageHeader__actions">
					{props.buttons &&
						props.buttons.map((item, index) => {
							return (
								<button className={`pageHeader__actions_btn ${isViewPermissionValid ? 'disabled' : ''}`} type="button" key={index} onClick={isViewPermissionValid? null:() => item.onClick()}>
									<p data-e2e={`${item.name.toLowerCase().replaceAll(" ", "-")}`}>{item.name}</p>
									{item.icon}
								</button>
							);
						})}

					{props.time && (
						<div className="pageHeader__time">
							<button id="demo-customized-button" 
								aria-controls={timeDropDown ? "demo-customized-menu" : undefined }
								aria-haspopup="true"
								aria-expanded={timeDropDown ? "true" : undefined}
								variant="contained"
								disableelevation="true"
								data-e2e="b2c-user-tab"
								onClick={handleTimeOpenDropdown}
								className="pageHeader__select"
							>
								Time {props.selectedTime}
								<i className={`fa fa-angle-${accountAnchor ? "up" : "down"}`}/>
							</button>

							<StyledMenu
								id="demo-customized-menu"
								MenuListProps={{"aria-labelledby": "demo-customized-button"}}
								anchorEl={accountAnchor}
								open={timeDropDown || false}
								onClose={() => {
									setAccountDropDown((prev) => !prev)
									setTimeDropDown(null);
								}}
							>
								{props.time.map((menu, index) => {
									return (
										<MenuItem
											onClick={() => {
												setTimeDropDown(null);
												props.setIsSelectedTime(true)
												props.setSelectedTime(menu.name)
												setAccountDropDown((prev) => !prev)
											}}
											disableRipple
											key={index}
											className={`${activatedMenuList &&
												menu.name
													.toLowerCase()
													.includes(activatedMenuList.toLowerCase())
												? "active-menu-link"
												: ""
												}`}
											data-e2e={`${menu.name.toLowerCase().replace(" ", "-")}`}
										>
											{menu.name}
										</MenuItem>
									);
								})}
							</StyledMenu>
						</div>
					)}
					{!props.displayMenuList && usersMenuList && usersMenuList.length > 0 && ( 
						<div className="pageHeader__buttons transactionId-page">
							{usersMenuList.map((menu, index) => {
								return (
									<button key={index} type="button" className={`pageHeader__actions_btn ${isViewPermissionValid? "disabled": ""}`} data-e2e={menu.name}
										onClick={isViewPermissionValid? null: () => {
										menu.func();}}
									>
										<p>{menu.name}</p>
									</button>
								)
							})}
						</div>
					)}
					{showEllipisButton  && (
						<button type="button" className="pageHeader__moreBtn" onClick={() => setShowEllipsisModal(true)}>
							<i className="fa fa-ellipsis-v" aria-hidden="true"></i>
						</button>
					)}
          <div  style={{display:"flex", gap:"4px"}}>
              {props.paymentProvider && <div style={{color:"#222", fontSize:"16px", fontWeight:"700" }}>{props.paymentProvider}</div>}
              {(props.view === "wallets" || props.view === "user-wallet" || props.view === "Merchantwallets" || props.view === "centralised wallets") && (
            <a
							className="pageHeader__actions_btn"
							href={`${getCryptoLink(
                viewOnBlockChain &&
                viewOnBlockChain &&
                viewOnBlockChain.network,
                viewOnBlockChain &&
                viewOnBlockChain.cryptoId &&  viewOnBlockChain.cryptoId.cryptoId && viewOnBlockChain.cryptoId.cryptoId
              )}/${viewOnBlockChain &&
							viewOnBlockChain.address &&
							viewOnBlockChain.address.key
								}`}
							target="_blank"
							rel="noopener noreferrer"
							data-e2e="view-on-block-chain-btn"
						>
							<p>View On Blockchain</p>
							<img
								src={`${image_base_url}${blockchainExternalLink}`}
								alt="external-link"
								data-e2e={"external-link"}
							/>
						</a>
					)}
          </div>
				</div>	
			</div>
			<br />
      <MediaQuery minWidth={768}>
				<div className="wallet-header-view">
					{alertPop || notifyMerchant ? (
						<div
							className="wallet_status_alert_model"
							style={{ position: "absolute", right: "12px" }}
						>
							<div className="wallet_status_alert_model_icon">
								<DoneIcon style={{ color: "white" }} />
							</div>
							<div className="wallet_status_alert_model_text">
								{alertPop
									? "Wallet Status has been changed successfully!"
									: notifyMerchant
										? "Transaction Notification has been sent!"
										: null}
							</div>
							<div onClick={() => setAlertPopUp(false)}>
								<ClearIcon />
							</div>
						</div>
					) : null}
          {alertErrorPop ? (
						<div
							className="wallet_status_alert_model"
							style={{ position: "absolute", right: "12px" }}
						>
							<div className="wallet_status_alert_model_icon" style={{ backgroundColor: "red" }}>
								<GppBad style={{ color: "white" }} />
							</div>
							<div className="wallet_status_alert_model_text">
                {errorMessageStatusUpdate ? errorMessageStatusUpdate : "Wallet Status change Failed!"}
							</div>
							<div onClick={() => setErrorAlertPopUp(false)}>
								<ClearIcon />
							</div>
						</div>
					) : null}

					{!alertPop && props && (props.view === "wallets" || props.view === "centralised wallets" || props.view === "Merchantwallets") && (
						<div
							className={`header_wallet_status ${currentWalletStatus === "Available"
								? "header_wallet_status_available"
								: currentWalletStatus === "In Use"
									? "header_wallet_status_inuse"
									: "header_wallet_status_locked"
								}`}
						>
							<div>{currentWalletStatus && currentWalletStatus} </div>
							{currentWalletStatus && (
								<div
									className={`header_wallet_status_icon cursor-pointer ${isViewPermissionValid? "disabled": ""}`}
									id="basic-button"
									aria-controls="basic-menu"
									aria-haspopup="true"
									aria-expanded={open ? "true" : undefined}
									onClick={isViewPermissionValid? null: handleClick}
									data-e2e={currentWalletStatus
										.toLowerCase()
										.replaceAll(" ", "-")}
								>
									<KeyboardArrowDownIcon />
								</div>
							)}
						</div>
					)}

					{!alertPop &&
						props &&
						(props.view === "wallets" || props.view === "centralised wallets") &&
						(currentWalletStatus === "Available" ||
							currentWalletStatus === "Locked") && (
							<div
								onClick={() => isViewPermissionValid? null: props.handleUpdateSettings()}
								style={{ cursor: "pointer", padding: "12px", width: "fit-content" }}
								className={`header_wallet_status deposit_button ${isViewPermissionValid? "disabled": ""}`}
							>
								<div data-e2e="deposit-btn">DEPOSIT</div>
							</div>
						)}
				</div>
				<div className="wallet_status_menu">
					<Menu
						className="wallet_status_menu"
						id="basic-menu"
						anchorEl={anchorEl}
            open={open ? true : false}
						onClose={handleClose}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
					>
						{status_array
							.filter((ele) => ele.status !== currentWalletStatus)
							.map((item, index) => {
								return (
									<div key={index}>
										<MenuItem
											onClick={() => handleStatusChange(item.status)}
											data-e2e={item.status
												.toLowerCase()
												.replaceAll(" ", "-")}
										>
											{item.status}
										</MenuItem>
									</div>
								);
							})}
					</Menu>
				</div>
      </MediaQuery>

      <Dialog
        open={accept || confirmNotify || false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <WalletStatusChangeAlert
          type={props.view}
          handleAccpetCloseForm={handleAccpetCloseForm}
          callUpdateWalletStatus={callUpdateWalletStatus}
          callUpdateNofityStatus={callUpdateNofityStatus}
          sendMerchantNotificationFromAdmin={sendMerchantNotificationFromAdmin}
        />
      </Dialog>

      {props.view === "transactions" && showExecuteModal && (
				<Dialog
					open={showExecuteModal || false}
					onClose={() => {
						handleCloseExecuteModal();
					}}
					className={`${classes.root} change_status_modal executeModal`}
				>
					<div className="change_status_body">
						<div className="d-flex justify-content-center">
							<img
								src={`${image_base_url}${sendIcon}`}
								alt="image"
								className=""
							/>
						</div>
						<p className="ex-header">Confirm Blockchain Receipt</p>
						<p className="ex-text">
							To confirm receipt enter the transaction hash from the
							blockchain
						</p>
						<div className="center-generate-recipt">
							<div className="custom-control custom-radio">
								<input
									type="radio"
									id="customRadio1"
									name="customRadio"
									className="form-check-input"
									onChange={() => setIsHashMode(true)}
									defaultChecked
									data-e2e="enter-hash"
								/>
								<label
                  className="form-check-label"
									htmlFor="customRadio1"
								>
									Enter Hash
								</label>
							</div>
							<div className="custom-control custom-radio">
								<input
									type="radio"
									id="customRadio1"
									name="customRadio"
									data-e2e="use-database-info"
									className="form-check-input"
									onChange={() => setIsHashMode(false)}
								/>
								<label
                  className="form-check-label"
									htmlFor="customRadio1"
								>
									Use Database Info
								</label>
							</div>
						</div>
						{isHashMode ? (
							<div className="execute-input-div">
								<input
									className="execute-input"
									data-e2e="Transaction Hash"
									value={transHash}
									onChange={(e) => setTransHash(e.target.value)}
									placeholder="Transaction Hash"
								/>
							</div>
						) : null}
						{selectedCryptoId === "LTC" ||
							selectedCryptoId === "XRP" || selectedCryptoId === "BTC" ? null : (
							<div>
								<div className="execute-options-container">
									<p className="exec-switch-text">PENDING RECEIPT</p>
									<AntSwitch
										className="toggle-execute"
										inputProps={{ "aria-label": "ant design" }}
										onChange={() => {
											setPendingAllowed(!pendingAllowed);
											if (!pendingAllowed) setconfirmAllowed(false);
										}}
										data-e2e="PENDING RECEIPTs"
										disabled={
											historyStatus ||
											transactionStatus ===
											"DepositMonitor:DepositReceivedToMemPool"
										}
										checked={pendingAllowed}
									/>
								</div>

								<div className="execute-options-container">
									<p className="exec-switch-text">CONFIRMATION RECEIPT </p>
									<AntSwitch
										data-e2e="CONFIRMATION RECEIPT"
										className="toggle-execute"
										inputProps={{ "aria-label": "ant design" }}
										onChange={() => {
											setconfirmAllowed(!confirmAllowed);
										}}
										disabled={!pendingAllowed}
										checked={pendingAllowed ? confirmAllowed : false}
									/>
								</div>
							</div>
						)}
						{confirmBlockchainError !== "" && (
							<div className="change1h mt-3">{confirmBlockchainError}</div>
						)}
						<div className="notify_btn_wrap">
							<div
								className="change_trans_button mt-4"
								onClick={() => {
									makeWebhooksCall();
								}}
								data-e2e="send-btn"
							>
								Confirm
							</div>
							<div
								className="change_trans_go_back mt-4"
								onClick={() => setshowExecuteModal(false)}
								data-e2e="go-back-btn"
							>
								Go Back
							</div>
						</div>
						<div className="execute-response-div">
							<p>{webhookApiResponse.message}</p>
						</div>
					</div>
				</Dialog>
      )}

      {sendNotification && (
        <Dialog
          open={true}
          className={`${classes.root} change_status_modal mt-4`}
          style={{ height: "fit-content" }}
        >
          <div className="change_status_body">
            <img
              src={`${image_base_url}${sendIcon}`}
              alt="image"
              className="d-block m-auto"
            />
            <div className="change_trans_header mt-4 ">Send notification</div>
            <div className="change_trans_sure_want mt-4">
              Send notification about the transaction{" "}
            </div>
            <div className="FilterModalInputGroup">
              <div className="FilterModalInputLabel">Status</div>
              <div
                className="FilterModalInputDropdown"
                data-e2e="status-drop-down"
                onClick={() => {
                  setShowStatusDropdown(true);
                }}
              >
                <div
                  className="FilterModalInputText"
                  style={{ flexGrow: 0 }}
                  data-e2e={selectedStatus}
                >
                  {selectedStatus}
                </div>
                <i className="fa fa-chevron-down FilterModalInputDropIcon" />
              </div>
              {showStatusDropdown && (
                <div className="FilterModalCurrencyDropdownlist">
                  <div
                    className="FM_currencyDrop_All"
                    onClick={() => {
                      setShowStatusDropdown(false);
                    }}
                  >
                    <span className="FM_currencyDrop_AllSelector">
                      {selectedStatus}
                    </span>
                    <span className="FilterModalInputDropUpIconWrap">
                      <i
                        data-e2e="icon-up"
                        className="fa fa-chevron-up FilterModalInputDropUpIcon"
                      />
                    </span>
                  </div>
                  {statusList.map((status, index) => {
                    return (
                      <div
                        key={index}
                        className="currencyListItemWrap"
                        onClick={() => handleNotifactionStatus(status)}
                      >
                        <div className="currencyListIconNameWrap">
                          <div className="currencyList_Name" data-e2e={status}>
                            {status}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <textarea
              placeholder="Add note.."
              value={notificationMsg}
              data-e2e="add-note-box"
              name="message"
              className="change_trans_note mt-4"
              cols="40"
              rows="5"
              onChange={(e) => setNotificationMsg(e.target.value)}
            />
            <div className="flex gap-2">
              <input type="checkbox" data-e2e="update-merchant-balance-check-box" disabled={selectedStatus !== "Success"} name="archive" placeholder="archive" value={isUpdateBalance} onChange={() => setUpdateBalance(!isUpdateBalance)}/>
              <span className="pageHeader__checkbox_label">Update Merchant Balance ?</span>
            </div>
            <div className="flex gap-2">
              <input type="checkbox" data-e2e="execute-auto-trade-check-box" disabled={selectedStatus !== "Success"} name="archive" placeholder="archive" value={isAutoTrade} onChange={() => setIsAutoTrade(!isAutoTrade)}/>
              <span className="pageHeader__checkbox_label">Execute Auto Trade</span>
            </div>
            {notificationMsgErr && (
              <div className="add_note_error">{"Please Add something "}</div>
            )}
            <div className="notify_btn_wrap">
              <div
                className="change_trans_button mt-4"
                onClick={() => handleSendNotification()}
                data-e2e="send-btn"
              >
                Send
              </div>
              <div
                className="change_trans_go_back mt-4"
                onClick={() => setSendNotification(false)}
                data-e2e="go-back-btn"
              >
                Go Back
              </div>
            </div>
          </div>
        </Dialog>
      )}
      <MediaQuery maxWidth={767}>
        {changeWalletStatusModal && props.view === "wallets" && (
          <div>
            <Dialog
              open={changeWalletStatusModal || false}
              className={`${classes.root} change_status_modal mt-4`}
              style={{ height: "fit-content" }}
            >
              <div className="change_status_body" style={{ width: "90vw" }}>
                <img
                  src={`${image_base_url}/homeb2c/change_status_logo.svg`}
                  alt="image"
                  className="d-block m-auto"
                />
                <div className="change_trans_header mt-4 ">Wallet status</div>
                <div className="FilterModalInputGroup">
                  <div className="FilterModalInputLabel">Select status</div>
                  <div
                    className="FilterModalInputDropdown"
                    onClick={() => {
                      setChangeWalletStatusDropdown(true);
                    }}
                  >
                    <div
                      className="FilterModalInputText wrap_word"
                      style={{ flexGrow: 0 }}
                    >
                      {selectedWalletStatus}
                    </div>
                    <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                  </div>
                  {changeWalletStatusDropdown && (
                    <div className="FilterModalCurrencyDropdownlist">
                      <div
                        className="FM_currencyDrop_All"
                        onClick={() => {
                          setChangeWalletStatusDropdown(false);
                        }}
                      >
                        <span className="FM_currencyDrop_AllSelector wrap_word">
                          {selectedWalletStatus}
                        </span>
                        <span className="FilterModalInputDropUpIconWrap">
                          <i className="fa fa-chevron-up FilterModalInputDropUpIcon" />
                        </span>
                      </div>
                      <div className="statusList_wrap">
                        {status_array
                          .filter((ele) => ele.status !== selectedWalletStatus)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="currencyListItemWrap wrap_word"
                                onClick={() => {
                                  setSelectedWalletStatus(item.status);
                                  setChangeWalletStatusDropdown(false);
                                }}
                              >
                                <div className="currencyListIconNameWrap">
                                  <div className="currencyList_Name wrap_word">
                                    {item.status}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
                <div className="notify_btn_wrap">
                  <div
                    className="change_trans_button mt-4"
                    onClick={() => {
                      handleStatusChange(selectedWalletStatus);
                      setChangeWalletStatusModal(false);
                    }}
                  >
                    Save
                  </div>
                  <div
                    className="change_trans_go_back mt-4"
                    onClick={() => {
                      setChangeWalletStatusModal(false);
                      setSelectedWalletStatus(currentWalletStatus);
                    }}
                  >
                    Go Back
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
        )}
        {showChangeStatusModal && (
          <ChangeTransactionStatus
            transactionType={transactionType}
            setLoading={setLoading}
            onClose={() => setShowChangeStatusModal(false)}
            status={transactionStatus}
          />
        )}

        {showEllipsisModal && (
          <TransactionViewEllipsis
            view={props.view}
            status={
              props.view === "wallets" ? currentWalletStatus : transactionStatus
            }
            transactionType={transactionType}
            ellipsisBtn={
              props.view === "wallets" || props.view === "centralised wallets"
                ? ellipsisBtn.slice(4)
                : props.view === "Merchantwallets"
                  ? ellipsisBtn.slice(4, 5)
                  : ellipsisBtn.slice(0, 4)
            }
            show={showEllipsisModal}
            onClose={() => setShowEllipsisModal(false)}
          />
        )}
      </MediaQuery>

      {loading && <Loading />}
    </div>
  );
}

export default CommonHeader;

const HtmlTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    backgroundColor: '#fafafa',
    color: '#000000',
    maxWidth: 800,
    border: '1px solid #dadde9',
    boxShadow: '10px'

  },
}));


//content of tooltip
const ToolTipBalance = ({ data }) => {
  return (
    <div className="wallettooltipcontainer">
      <div className="wallettooltipheading">Wallet Balance </div>
      {data.balance && data.balance.map((balance, i) => {
        return (
          <div className='wallettooltiptabletext' key={i}>
            <div className='wallettooltippadding'>{balance.cryptoId}</div>
            <div className='wallettooltippadding'>{balance.balanceAmount.toFixed(6)}  (${balance.balanceAmountUSDApprox} USD) </div>
          </div>
        )
      })}

      <div className="wallettooltipheading"> Blockchain balance </div>
      {data.housekeeper && data.housekeeper.map((balance, i) => {
        return (
          <div className='wallettooltiptabletext' key={i}>
            <div className='wallettooltippadding'>{balance.crypto}</div>
            <div className='wallettooltippadding'>{balance.value.toFixed(6)}  </div>
          </div>
        )
      })}

    </div>
  )
}
