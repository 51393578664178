import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { call } from "../../../../config/axios";
import { SAVE_PAYMENT_PROVIDER_WHITELIST_CARDS, GET_PAYMENT_PROVIDER_WHITELIST } from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";
import { isViewPermissionValid } from "../../../common/CommonUtils";
import { useParams } from "react-router-dom";

const UploadIINPage = () => {
  const [data, setData] = useState([]);
  const param = useParams();
  const[selectedFileName,setSelectedFileName]=useState(null)
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const baseUrl = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const token = localDb.getVal("token");
  const adminRole = localDb.getVal("adminRole");
  const [loading, setLoading] = useState(false);
  const getIINData = async () => {
    setLoading(true);
    const payload = {
      email: email,
      adminRole: adminRole,
      paymentProviderId: param?.id,
      token: token,
    };
    const url = baseUrl + GET_PAYMENT_PROVIDER_WHITELIST.url;
    try {
      const res = await call(
        {
          ...GET_PAYMENT_PROVIDER_WHITELIST,
          url: url,
        },
        payload
      );
      if (res?.status === 500) {
        setError("Something went wrong");
        setLoading(false);
      } else {
        setData(res?.data);
        setLoading(false);
        setError("");
      }
    } catch(err) {
      setLoading(false);
      setError("Something went wrong");
      console.error(err.message);
    }
    
  };
  const handleFileChange = (event)=>{
    setError("");
    const file = event.target.files[0]
    if (file?.type === "text/csv"){
      setSelectedFileName(file?.name)
      setSelectedFile(file)
      event.target.value = null;
    }else {
      setError("Wrong file")
      event.target.value = null;
    }
  }
  useEffect(() => {
    getIINData();
  }, []);      
  const handleSubmit = async () => {
    setError("");
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("email", email);
    formData.append("token", token);
    formData.append("adminRole", adminRole);
    formData.append("paymentProviderId", param?.id);
    const url = baseUrl + SAVE_PAYMENT_PROVIDER_WHITELIST_CARDS.url;
    const viewPermission = localDb.getVal('viewPermission');
    await fetch(url,{
      method: SAVE_PAYMENT_PROVIDER_WHITELIST_CARDS.method,
      body: formData,
      headers: {
        'x-access-token': token || '',
        'x-view-permission': viewPermission || ''
      }
    })
      .then((res) => {
        if(res?.status===500){
          setError("Incorrect File Content!")
        }else{
          setError("");
          getIINData();
        }
        setLoading(false);
        setSelectedFile(null);
        setSelectedFileName(null)
      })
      .catch((err) => {
        setLoading(false);
        setSelectedFile(null);
        setSelectedFileName(null)
        setError(`Something went wrong`);
        console.error(err.message,"err")
      });
  };
  return (
    <div>
      <div className="create_account_container">
        <div className="create_account_header create_account_header_align">
          <div className="create_account_heading">Upload Whitelist IIN</div>
        </div>
        <div className="upload-input">
          <input
            className="select__file"
            type="file"
            accept=".csv"
            id="csvFileUpload"
            onChange={handleFileChange}
          ></input>
          <label
            className={`input__lable ${isViewPermissionValid ? "disabled" : ""}`}
            htmlFor="csvFileUpload"
          >
            <div data-e2e="fileUpload">{selectedFileName}</div>
            <div>
              <i className="fa fa-upload" aria-hidden="true"></i>
            </div>
          </label>
          {error != "" && <div className="uploadCSV_error">{error}</div>}
        </div>
        <div className="create_account_form">
          <div className="login_button_get_started">
            <button
              className={selectedFile ? "bg-secondary" : ""}
              disabled={selectedFile === null}
              onClick={()=>handleSubmit()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="transaction_table_link table-full-width table_padding transaction-h">
        <div className="theme-table">
          <Table responsive>
              <thead>
                <tr>
                  <th className="theme-table-hover">
                    <p className="theme-table-text">Masked Pan</p>
                  </th>
                  <th className="theme-table-hover">
                    <p className="theme-table-text">Card Scheme</p>
                  </th>
                </tr>
              </thead>
            <tbody>
              {data.length> 0 ? 
              data && data.map((PaymentProviderIIN, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr className="theme-table-row theme-table-hover">
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {PaymentProviderIIN.maskedPAN}
                            </p>
                          </div>
                        </td>

                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {PaymentProviderIIN.cardScheme}
                            </p>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                }) :
                <React.Fragment>
                        <tr className="theme-table-row theme-table-hover">
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              No Data Yet
                            </p>
                          </div>
                        </td>
                        </tr>
                </React.Fragment>
                }
            </tbody>
          </Table>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default UploadIINPage;
