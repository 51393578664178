import React, { useState, useEffect } from "react";
import Loading from "../../../../../common/Loading";
import { useSelector } from "react-redux";
import TabButton from "../../../../../common/TabButton";
import CustomTextFields from "../../../../../common/CustomTextFields";
import { Grid } from "@mui/material";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";
import {
  UPDATE_LEAST_COST_ROUTING,
  GET_LEAST_COST_ROUTING,
} from "../../../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../../../config/axios";
import localDb from "../../../../../../localDb";

const LeastCostRoutingTab = ({ acquirerServiceData }) => {
  const baseURL = useSelector((state) => state.config.api_url);
  const [tabs, setTabs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [acquirerId, setAcquirerId] = useState(
    acquirerServiceData[0]?.acquirerUniqueId || "",
  );
  const fieldNames = {
    fixedCost: "Fixed Cost",
    percentCost: "Percent Cost",
  };
  const initialValues = {
    fixedCost: 0,
    percentCost: 0,
  };
  const initialEditValue = {
    fixedCost: false,
    percentCost: false,
  };
  const [values, setValues] = useState(initialValues);
  const [isEditable, setIsEditable] = useState(initialEditValue);

  useEffect(() => {
    getMaxtrafficByAcquirer();
    setValues(initialValues);
  }, [tabs]);

  const handleChange = (field, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    const data = {
      email: localDb.getVal("email"),
      acquirerUniqueId: acquirerId,
      ...values,
    };
    setLoading(true);
    try {
      const res = await call(
        {
          ...UPDATE_LEAST_COST_ROUTING,
          url: baseURL + UPDATE_LEAST_COST_ROUTING.url,
        },
        data,
      );

      if (res?.status === 200) {
        setLoading(false);
        setIsEditable(initialEditValue);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const getMaxtrafficByAcquirer = async () => {
    const data = {
      email: localDb.getVal("email"),
      acquirerUniqueId: acquirerId,
    };
    setLoading(true);
    try {
      const res = await call(
        {
          ...GET_LEAST_COST_ROUTING,
          url: baseURL + GET_LEAST_COST_ROUTING.url,
        },
        data,
      );

      if (res?.status === 200) {
        setValues((prevValues) => ({
          ...prevValues,
          ...res.data,
        }));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const toggleEditable = (field) => {
    setIsEditable((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleTabSwitch = (index, itemId) => {
    setAcquirerId(itemId?.acquirerUniqueId);
    setTabs(index);
    setIsEditable(initialEditValue);
  };

  return (
    <div className="TabContainer">
      <div className="acquireServiceScroll">
        {acquirerServiceData?.map((tab, index) => (
          <TabButton
            key={index}
            activeTab={tabs}
            handleTabSwitch={handleTabSwitch}
            tab={tab?.acquirerSetting}
            index={index}
            itemId={tab}
          />
        ))}
      </div>
      <div className="ml-3 flex pt-10">
        <Grid container spacing={2}>
          {acquirerServiceData?.map((items, index) => {
            return (
              <div key={index}>
                {tabs === index && (
                  <CustomTextFields
                    initialValues={initialValues}
                    values={values}
                    isEditable={isEditable}
                    handleChange={handleChange}
                    toggleEditable={toggleEditable}
                    fieldNames={fieldNames}
                  />
                )}
              </div>
            );
          })}
        </Grid>
      </div>
      <div className="mt-4">
        <button
          className={`${isViewPermissionValid ? "disabled" : ""} rounded bg-blue-500 px-4 py-2 text-base font-bold text-white hover:bg-blue-700`}
          onClick={isViewPermissionValid ? null : handleSave}
        >
          Save
        </button>
      </div>

      {loading && <Loading />}
    </div>
  );
};

export default LeastCostRoutingTab;
