import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import {
  GET_TRANSFER_WALLETS,
  GET_SINGLE_CRYPTO_EXCHANGE_RATE,
} from '../../../../config/endpoints';
import { call, logoutAfterError } from '../../../../config/axios';
import localDb from '../../../../localDb';
import { SortByIcon } from '../../../common/CommonUtils';
const email = localDb.getVal('email');

const UserTransactionTable = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="TransactionTableMain">
      {props && props.data && props.data.length > 0 ? (
        <Table responsive style={{ border: 'none' }}>
          <tbody className="TransactionTable_BODY">
            <tr className="TransactionTable_BODY_Header">
              <th
                className="TransactionTable_heading"
                width="10%"
                data-e2e="b2cUser"
              >
                {`USER`}
              </th>
              <th
                className="TransactionTable_heading"
                width="10%"
                onClick={() => {
                  props.changeOrder('type');
                }}
                data-e2e="type"
              >
                {`TYPE`}
                {props.sortBy === 'type' && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="type" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="10%"
                onClick={() => {
                  props.changeOrder('transactionId');
                }}
                data-e2e="transactionId"
              >
                {`TRANSACTION ID`}
                {props.sortBy === 'transactionId' && (
                  <SortByIcon orderBy={props.orderBy} data-e2e="transactionId" />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="10%"
                onClick={() => {
                  props.changeOrder('createdDate');
                }}
                data-e2e="createdDate"
              >
                {`DATE & TIME`}
                {props.sortBy === 'createdDate' && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="createdDate"
                  />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="12%"
                onClick={() => {
                  props.changeOrder('lastupdatedDate');
                }}
                data-e2e="lastupdatedDate"
              >
                {`LAST USED`}
                {props.sortBy === 'lastupdatedDate' && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="lastupdatedDate"
                  />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="12%"
                onClick={() => {
                  props.changeOrder('amount');
                }}
                data-e2e="amount"
              >
                {`CRYPTO AMOUNT`}
                {props.sortBy === 'amount' && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="amount"
                  />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="8%"
                onClick={() => {
                  props.changeOrder('gasFee');
                }}
                data-e2e="gasFee"
              >
                {`FEE`}
                {props.sortBy === 'gasFee' && (
                  <SortByIcon
                    orderBy={props.orderBy}
                    data-e2e="gasFee"
                  />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="10%"
                data-e2e="status.code"
              >
                {`Status`}
              </th>
            </tr>
            {props.data.map((TData, i) => {
              if(TData && TData.b2cUser && TData.b2cUser){
                TData.name = TData.b2cUser.firstName+TData.b2cUser.lastName;
              }
              return (
                <SingleTransactionTableRow
                  data={TData}
                  key={i}
                  loading={loading}
                  setLoading={setLoading} 
                />
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="No_Transactions_Found">No Data Found</div>
      )}
      {props.showMoreButton ? (
        <div className="Show_more_wrap">
          <div
            className="Show_more"
            onClick={() => {
              props.handleShowMore();
            }}
          >
            Show More
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UserTransactionTable;

const SingleTransactionTableRow = (props) => {
  let { data } = props;
  const api_url = useSelector((state) => state.config.api_url);
  const [loadingExchange, setLodingExchange] = useState(false);
  const [selectedToken, setSelectedToken] = useState("Select");
  const [selectedTokenDetails, setSelectedTokenDetails] = useState(null);
  const [cryptoAmount, setCryptoAmount] = useState(0);
  const [cryptoEquUSDAmount, setCryptoEquUSDAmount] = useState(0);
  const [limitError, setLimitError] = useState("");
  const [transferWallets, setTransferWallets] = useState([]);
  const view_transaction_url = useSelector((state) => state.config.api_url);
  const payment_api_url = useSelector((state) => state.config.payment_api_url);


  const getStatusComponent = (status) => {
    switch (status) {
      case 'B2CWalletMonitor:ReceiveCompleted':
      case 'B2CSendFlow:SendCompleted':
      case 'B2CWalletMonitor:ReceiveManuallyCompleted':
      case 'CardBuyFlow:DepositCompleted':
        return <div className="Mstatus_active" data-e2e="success">{`SUCCESS`}</div>;
      case 'B2CWalletMonitor:ErrorEncountered':
      case 'B2CSendFlow:ErrorEncountered':
      case 'CardBuyFlow:DepositRejected':
      case 'CardBuyFlow:DepositFailed':
        return <div className="Mstatus_inactive">{`REJECTED`}</div>;
      default:
        return (
          <div className="Mstatus_pending" data-e2e="pending">{`PENDING`}</div>
        );
    }
  };

  const getTransferWallets = async (blockchain, network) => {
    props.setLoading(true);
    await call(
      {
        ...GET_TRANSFER_WALLETS,
        url: api_url + GET_TRANSFER_WALLETS.url,
      },
      { email: localStorage.getItem('email'), blockchain, network}
    )
      .then((res) => {
        props.setLoading(false);
        if (res.status === 200) {
          setTransferWallets(res.data.transferWallets);
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err.message, 'UserTransactionTable > getTransferWallets > err');
        logoutAfterError(err)
      });
  };




  const getSingleCryptoExchangedRate = (amount, cryptoCurrency, fiatCurrency) => {
    return new Promise(async (resolve, reject) => {
      const baseUrl = window.location.hostname.includes("localhost") ? payment_api_url : view_transaction_url
      setLodingExchange(true)
      await call(
        {
          ...GET_SINGLE_CRYPTO_EXCHANGE_RATE,
          url: baseUrl + GET_SINGLE_CRYPTO_EXCHANGE_RATE.url,
        },
        { email: email, amount, cryptoCurrency, fiatCurrency }
      )
        .then((res) => {
          if (res.status === 200) {
            props.setLoading(false);
            setLodingExchange(false)
            resolve(res.data);
          }
        })
        .catch((err) => {
          props.setLoading(false);
          setLodingExchange(false)
          console.log(err.message, 'UserTransactionTable > getSingleCryptoExchangedRate > err');
          logoutAfterError(err)
        });
    })
  };

  useEffect(() => {
    if (selectedTokenDetails) {
      onCryptoAmountChange(selectedTokenDetails.balanceAmount);
    }
  }, [selectedTokenDetails])


  const validateAmount = (value) => {
    if (value > selectedTokenDetails.balanceAmount) {
      setLimitError("Amount must be less than equal to " + selectedTokenDetails.balanceAmount + " " + selectedToken);
    } else if (value <= 0) {
      setLimitError("Amount must be greater than zero");
    } else {
      setLimitError("");
    }
  }

  const onCryptoAmountChange = async (value) => {
    if (value) {
      setCryptoAmount(value);
    } else if (value === 0) {
      setCryptoAmount(0);
    } else {
      setCryptoAmount(null);
    }
    const result = await getSingleCryptoExchangedRate(1, selectedToken, "USD");
    if (value && result && result.cryptoAmounts) {
      const usdEquAmt = parseFloat(value / result.cryptoAmounts[selectedToken]).toFixed(8)
      validateAmount(value)
      setCryptoEquUSDAmount(usdEquAmt);
    } else {
      validateAmount(0)
      setCryptoEquUSDAmount(0);
    }
  };
 
  return (
    <tr className="CP_TableRow">
      <td className="transactionsTable_item allet_address_link" data-e2e={data && data.name && data.name && data.name}>
      <Tooltip title={data.name && data.name} placement="top">
      <Link to={`/b2c-users/${data.b2cUser && data.b2cUser._id}`}>
            {data.name
              ? data.name.substring(0, 6) +
              '...' +
              data.name.substring(
                data.name.length - 4,
                data.name.length
              )
              : 'N/A'}
          </Link>
        </Tooltip>
      </td>
      <td className="transactionsTable_item">
        {data.transactionRefLink && data.transactionRefLink ? <div className="transactionsTable_item_valueWrap">
          <span style={{textTransform:'capitalize'}}>{(data.type && data.type) || 'N/A'}</span>
          <span style={{marginTop:'-10px', whiteSpace:'nowrap'}}>({"Merchant Deposit"})</span>
        </div>
        : 
        <span style={{textTransform:'capitalize'}}>{(data.type && data.type) || 'N/A'}</span>
        }
      </td>
      <td className="transactionsTable_item wallet_address_link">
        <Tooltip title={data.transactionId && data.transactionId} placement="top">
          <Link to={`/b2c-user-transactions/${data.transactionId}`}>
            {data.transactionId
              ? data.transactionId.substring(0, 5) +
              '.....' +
              data.transactionId.substring(
                data.transactionId.length - 6,
                data.transactionId.length
              )
              : 'N/A'}
          </Link>
        </Tooltip>
      </td>
      <td className="transactionsTable_item" data-e2e="date">
        {data.createdDate 
          ? moment(data.createdDate).format('DD/MM/YYYY - HH:mm')
          : 'N/A'}
      </td>
      <td className="transactionsTable_item" data-e2e="date2">
      {data.lastupdatedDate 
          ? moment(data.lastupdatedDate).format('DD/MM/YYYY - HH:mm')
          : 'N/A'}
      </td>
      {/* <td className="transactionsTable_item">
        {(data.fiatAmount && "$ "+data.fiatAmount) || 'N/A'}
      </td> */}
      <td className="transactionsTable_item">
        {(data.amount && data.cryptoId && data.cryptoId+" "+data.amount) || 'N/A'}
      </td>
      <td className="transactionsTable_item">
        {(data.gasFee && data.gasFee.toFixed(6)) || 'N/A'}
      </td>
      <td className="transactionsTable_item" data-e2e={data.status && data.status.code
          ? getStatusComponent(data.status.code)
          : 'N/A'}>
        {data.status && data.status.code
          ? getStatusComponent(data.status.code)
          : 'N/A'}
      </td>
 
    </tr>
  );
};
