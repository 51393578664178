import React, { useState, useEffect } from 'react';
import { call, logoutAfterError } from '../../../../../config/axios';
import { MerchantsAccountTr } from '../Components/MerchantsAccountTr';
import localDb from '../../../../../localDb';
import { useSelector } from 'react-redux';
import { GET_DASHBOARD_HEADER, GET_SPREAD_RATE_CRYPTO, GET_MERCHANT_TRANSACTIONS_COUNT_DASHBOARD, GET_HOUSEKEEPING_ALERT, GET_TRANSACTION_STATUS_CODES, GET_DASHBOARD_CARD_HEADER, GET_DASHBOARD_CARD_HEADER_USER, GET_BROWSER, GET_LANGUAGE, GET_DEVICE, GET_DEPOSIT_WITHDRAWAL_STATISTICS, GET_DASHBOARD_USER_COUNT, GET_UNIQUE_MERCHANT, GET_KYT, GET_GETCOUNT_TABLE } from '../../../../../config/endpoints';
import { CategoryScale, Chart as ChartJS, Filler, LineElement, LinearScale, PointElement } from 'chart.js';
import {
    Line,
} from 'react-chartjs-2';
import { useMediaQuery } from "react-responsive";
import moment from 'moment';
import CountTable from '../Components/CountTable';
import NavTabs from '../../../../common/NavTabs';
import ErrorDetailsComponent from './ErrorDetailsComponent';

ChartJS.register(
    CategoryScale, LinearScale, PointElement,
    LineElement, Filler);

export default function MerchantTab(props) {
    const email = localDb.getVal('email');
    const adminRole = localDb.getVal("adminRole");
    const base_url = useSelector((state) => state.config.api_url);
    const [dataList, setDataList] = useState("");

    const [uniqMerchants, setUniqMerchants] = useState({})
    const [uniqUsers, setUniqUsers] = useState({})
    const [uniqCardMerchants, setUniqCardMerchants] = useState({})
    const [uniqCardUsers, setUniqCardUsers] = useState({})
    const [cryptoFee, setCryptoFee] = useState({})
    const [cardTxnFee, setCardTxnFee] = useState({})
    const [depositInitiated, setDepositInitiated] = useState({})
    const [depositPending, setDepositPending] = useState({})
    const [depositProcessing, setDepositProcessing] = useState({})
    const [cardDepositInitiated, setCardDepositInitiated] = useState({})
    const [cardDepositPending, setCardDepositPending] = useState({})
    const [cardDepositProcessing, setCardDepositProcessing] = useState({})
    const [completedTransaction, setCompletedTransaction] = useState({})
    const [cardCompletedTransaction, setCardCompletedTransaction] = useState({})
    const [rejectedTransaction, setRejectedTransaction] = useState({})
    const [cardRejectedTransaction, setCardRejectedTransaction] = useState({})
    const [depositTimedout, setDepositTimedout] = useState({})
    const [cardDepositTimedout, setCardDepositTimedout] = useState({})
    const [withdrawalRequest, setWithdrawalRequest] = useState({})
    const [withdrawalProcessing, setWithdrawalProcessing] = useState({})
    const [withdrawalRejected, setWithdrawalRejected] = useState({})
    const [cardWithdrawalRequest, setCardWithdrawalRequest] = useState({})
    const [withdrawalComplete, setWithdrawalComplete] = useState({})
    const [cardWithdrawalComplete, setCardWithdrawalComplete] = useState({})
    const [cardWithdrawalProcessing, setCardWithdrawalProcessing] = useState({})
    const [cardWithdrawalRejected, setcardWithdrawalRejected] = useState({})
    const [countTable, setCountTable] = useState([])
    const [completedTransactionAmount, setCompletedTransactionAmount] = useState({})
    const [rejectedTransactionAmount, setRejectedTransactionAmount] = useState({})
    const [withdrawalRequestAmount, setWithdrawalRequestAmount] = useState({})
    const [withdrawalCompleteAmount, setWithdrawalCompleteAmount] = useState({})
    const [cardCompletedTransactionAmount, setCardCompletedTransactionAmount] = useState({})
    const [cardRejectedTransactionAmount, setCardRejectedTransactionAmount] = useState({})
    const [cardWithdrawalRequestAmount, setCardWithdrawalRequestAmount] = useState({})
    const [cardWithdrawalCompleteAmount, setCardWithdrawalCompleteAmount] = useState({})
    const [alertHouseKeeping, setAlertHouseKeeping] = useState({})
    const [depositSpread, setdepositSpread] = useState({})
    const [withdrawSpread, setwithdrawSpread] = useState({})
    const [merchantTransectionCount, setMerchantTransectionCount] = useState({})
    const [userCountTransaction,setUserCountTransaction] = useState({})
    const [orderBy, setOrderBy] = useState("desc");
    const [sortBy, setSortBy] = useState("count");
    const [rejectedTransactionByGroup, setRejectedTransactionByGroup] = useState([]);
    const [showErrorDetails, setShowErrorDetails] = useState(false);

    const tabs = [
        {
            name: "Crypto",
            id: 1,
        },
        {
            name: "Card",
            id: 2,
        },
    ];
    const [selectedTabs, setSelectedTabs] = useState(tabs[0])
    const [statusList, setStatusList] = useState([]);
    const [selectedStatusList, setSelectedStatusList] = useState([]);
    const [card, setCard] = useState(true);
    const [wallet, setWallet] = useState(true);
    const getDashboardHeader = async () => {
        let payload = {
            email,
            providers:
                props.selectedPaymentProvider.length === 0 ||
                    props.selectedPaymentProvider.length === props.paymentProviderList.length
                    ? 'All'
                    : props.selectedPaymentProvider,
            ...(adminRole === "PaymentProviderUser" && {
                providers:[{_id:localDb.getVal("paymentProviderId")}]
                }),
            merchnants: props.selectedMerchantName.length === 0 || props.selectedMerchantName.length === props.merchantName.length
                ? 'All'
                : props.selectedMerchantName,
            time: props.selectedTime ? props.selectedTime : "Last 24 Hour",
        };
        props.setLoading(true);
        await call(
            {
                ...GET_DASHBOARD_HEADER,
                url: base_url + GET_DASHBOARD_HEADER.url,
            },
            payload
        )
            .then((res) => {
                props.setLoading(false);
                if (res.status === 200) {
                    setDataList(res.data);
                    setDepositInitiated(res.data["DepositInitiated"]);
                    setDepositPending(res.data["DepositPending"]);
                    setDepositProcessing(res.data["DepositProcessing"]);
                    setCompletedTransaction(res.data["CompletedTransaction"]);
                    setCryptoFee(res.data["txnFee"]);
                    setCompletedTransactionAmount(res.data["USDAmountCompletedTransaction"])
                    setRejectedTransaction(res.data["RejectedTransaction"]);
                    setRejectedTransactionAmount(res.data["USDAmountRejectedTransaction"])
                    setDepositTimedout(res.data["DepositTimedout"]);
                    setWithdrawalRequest(res.data["WithdrawalRequest"]);
                    setWithdrawalProcessing(res.data["WithdrawalProcessing"]);
                    setWithdrawalRejected(res.data["WithdrawalRejected"]);
                    setWithdrawalRequestAmount(res.data["USDAmountWithdrawalRequest"]);
                    setWithdrawalComplete(res.data["WithdrawalComplete"]);
                    setWithdrawalCompleteAmount(res.data["USDAmountWithdrawalComplete"])
                }
            })
            .catch((err) => {
                props.setLoading(false);
                console.log(err.message, 'err')
                logoutAfterError(err)
            });
    };
    const getDashboardCardHeader = async () => {
        let payload = {
            email,
            providers:
                props.selectedPaymentProvider.length === 0 ||
                    props.selectedPaymentProvider.length === props.paymentProviderList.length
                    ? 'All'
                    : props.selectedPaymentProvider,
            ...(adminRole === "PaymentProviderUser" && {
                providers:[{_id:localDb.getVal("paymentProviderId")}]
                }),
            merchnants: props.selectedMerchantName.length === 0 || props.selectedMerchantName.length === props.merchantName.length
                ? 'All'
                : props.selectedMerchantName,
            time: props.selectedTime ? props.selectedTime : "Last 24 Hour",

        };
        props.setLoading(true);
        await call(
            {
                ...GET_DASHBOARD_CARD_HEADER,
                url: base_url + GET_DASHBOARD_CARD_HEADER.url,
            },
            payload
        )
            .then((res) => {
                props.setLoading(false);
                if (res.status === 200) {
                    setDataList(res.data);
                    setCardDepositInitiated(res.data["DepositInitiated"]);
                    setCardDepositPending(res.data["DepositPending"]);
                    setCardDepositProcessing(res.data["DepositProcessing"]);
                    setCardCompletedTransaction(res.data["CompletedTransaction"]);
                    setCardTxnFee(res.data["txnCardFee"]);
                    setCardRejectedTransaction(res.data["RejectedTransaction"]);
                    setCardDepositTimedout(res.data["DepositTimedout"]);
                    setCardWithdrawalRequest(res.data["WithdrawalRequest"]);
                    setCardWithdrawalComplete(res.data["WithdrawalComplete"]);
                    setCardWithdrawalProcessing(res.data["WithdrawalProcessing"]);
                    setcardWithdrawalRejected(res.data["WithdrawalRejected"]);
                    setCardCompletedTransactionAmount(res.data["USDAmountCompletedTransaction"])
                    setCardRejectedTransactionAmount(res.data["USDAmountRejectedTransaction"])
                    setCardWithdrawalRequestAmount(res.data["USDAmountWithdrawalRequest"]);
                    setCardWithdrawalCompleteAmount(res.data["USDAmountWithdrawalComplete"])
                    setRejectedTransactionByGroup(res?.data["errorDetailsData"])

                }
            })
            .catch((err) => {
                props.setLoading(false);
                console.log(err.message, 'err')
                logoutAfterError(err)
            });
    };
    const getDashboardCardUniqueUser = async () => {
        const payload = {
            email,
            providers:
                props.selectedPaymentProvider.length === 0 ||
                    props.selectedPaymentProvider.length === props.paymentProviderList.length
                    ? 'All'
                    : props.selectedPaymentProvider,
            ...(adminRole === "PaymentProviderUser" && {
                providers:[{_id:localDb.getVal("paymentProviderId")}]
                }),
            merchnants: props.selectedMerchantName.length === 0 || props.selectedMerchantName.length === props.merchantName.length
                ? 'All'
                : props.selectedMerchantName,
            time: props.selectedTime ? props.selectedTime : "Last 24 Hour",

        };
        props.setLoading(true);
        await call(
            {
                ...GET_DASHBOARD_CARD_HEADER_USER,
                url: base_url + GET_DASHBOARD_CARD_HEADER_USER.url,
            },
            payload
        )
            .then((res) => {
                props.setLoading(false);
                setUserCountTransaction(res.data);
            })
            .catch((err) => {
                props.setLoading(false);
                console.error(err.message, 'err')
                logoutAfterError(err)
            });
    };
    const getUserCount = async ()=>{
        let payload = {
            email,
            providers:
                props.selectedPaymentProvider.length === 0 ||
                    props.selectedPaymentProvider.length === props.paymentProviderList.length
                    ? 'All'
                    : props.selectedPaymentProvider,
            ...(adminRole === "PaymentProviderUser" && {
                providers:[{_id:localDb.getVal("paymentProviderId")}]
                }),
            merchnants: props.selectedMerchantName.length === 0 || props.selectedMerchantName.length === props.merchantName.length
                ? 'All'
                : props.selectedMerchantName,
            time: props.selectedTime ? props.selectedTime : "Last 24 Hour",

        };
        props.setLoading(true);
        await call(
            {
                ...GET_DASHBOARD_USER_COUNT,
                url: base_url + GET_DASHBOARD_USER_COUNT.url,
            },
            payload
        )
            .then((res) => {
                props.setLoading(false);
                if (res.status === 200) {
                    setUniqCardMerchants(res.data["UniqCardMerchants"]);
                    setUniqCardUsers(res.data["UniqCardUsers"]);
                    setUniqMerchants(res.data["UniqMerchants"]);
                    setUniqUsers(res.data["UniqUsers"]);
                }
            })
            .catch((err) => {
                props.setLoading(false);
                console.log(err.message, 'err')
                logoutAfterError(err)
            });
    }
    const getSpreadRate = async () => {
        let payload = {
            email,
            providers:
                props.selectedPaymentProvider.length === 0 ||
                    props.selectedPaymentProvider.length === props.paymentProviderList.length
                    ? 'All'
                    : props.selectedPaymentProvider,
            ...(adminRole === "PaymentProviderUser" && {
                providers:[{_id:localDb.getVal("paymentProviderId")}]
                }),
            merchnants: props.selectedMerchantName.length === 0 || props.selectedMerchantName.length === props.merchantName.length
                ? 'All'
                : props.selectedMerchantName,
            time: props.selectedTime ? props.selectedTime : "Last 24 Hour",

        };
        props.setLoading(true);
        await call(
            {
                ...GET_SPREAD_RATE_CRYPTO,
                url: base_url + GET_SPREAD_RATE_CRYPTO.url,
            },
            payload
        )
            .then((res) => {
                props.setLoading(false);
                if (res.status === 200) {
                    setdepositSpread(res.data["depositSpread"])
                    setwithdrawSpread(res.data["withdrawSpread"])
                }
            })
            .catch((err) => {
                props.setLoading(false);
                console.log(err.message, 'err')
                logoutAfterError(err)
            });
    }
    const getMerchantTransection = async () => {
        props.setLoading(true)
        let payload = {
            email,
            providers:
                props.selectedPaymentProvider.length === 0 ||
                    props.selectedPaymentProvider.length === props.paymentProviderList.length
                    ? 'All'
                    : props.selectedPaymentProvider,
            ...(adminRole === "PaymentProviderUser" && {
                providers:[{_id:localDb.getVal("paymentProviderId")}]
                }),
            merchnants: props.selectedMerchantName.length === 0 || props.selectedMerchantName.length === props.merchantName.length
                ? 'All'
                : props.selectedMerchantName,
            time: props.selectedTime ? props.selectedTime : "Last 24 Hour",

        };
        await call(
            {
                ...GET_MERCHANT_TRANSACTIONS_COUNT_DASHBOARD,
                url: base_url + GET_MERCHANT_TRANSACTIONS_COUNT_DASHBOARD.url,
            },
            payload
        ).then(response => {
            setMerchantTransectionCount(response.data.merchantCryptoWithdrawalInit)
            props.setLoading(false)
        }).catch(err => {
            console.log(err)
            props.setLoading(false)
        })
    }

    useEffect(() => {
        getDashboardHeader();
        getDashboardCardHeader();
        getDashboardCardUniqueUser();
        getUserCount();
        getSpreadRate();
        getMerchantTransection();
        getTransactionStatusCodes()
    }, [card, wallet]);

    useEffect(() => {
        if (props.isChangeProvider || props.isMerchantChange || props.isSelectedTime) {
            getUniqueMerchant();
            getDashboardHeader();
            getSpreadRate();
            getDashboardCardHeader();
            getDashboardCardUniqueUser();
            getUserCount();
            getMerchantTransection()
            props.setIsChangeProvider(false);
            props.setIsMerchantChange(false);
            props.setIsSelectedTime(false);
            getCountTable();
        }
    }, [props.isChangeProvider, props.isMerchantChange, props.isSelectedTime])

    useEffect(() => {
        getCountTable();
    }, [selectedTabs, orderBy])
    useEffect(() => {
        getHouseKeepingAlert()
    }, [props.isSelectedTime])

    const [uniqueMerchantData, setUniqueMerchantData] = useState({});
    const [fromDate, setFromDate] = useState(moment().subtract(12, 'months').format('MMMM Do YYYY, 00:00:00 a'));
    const [toDate, setToDate] = useState(moment().format('MMMM Do YYYY, h:mm:ss a'));
    const getUniqueMerchant = async () => {
        let payload = {
            email, fromDate, toDate,
            dayWise: filterBtn !== "12 months" && filterBtn !== "3 months" && filterBtn,
            providers:
                props.selectedPaymentProvider.length === 0 ||
                    props.selectedPaymentProvider.length === props.paymentProviderList.length
                    ? 'All'
                    : props.selectedPaymentProvider,
            ...(adminRole === "PaymentProviderUser" && {
                providers:[{_id:localDb.getVal("paymentProviderId")}]
                }),
            merchnants: !props.isMerchantChange
                ? 'All'
                : props.selectedMerchantName,

        }
        props.setLoading(true);
        await call(
            {
                ...GET_UNIQUE_MERCHANT,
                url: base_url + GET_UNIQUE_MERCHANT.url,
            },
            payload
        )
            .then((res) => {
                props.setLoading(false);
                if (res.status === 200) {
                    setUniqueMerchantData(res.data)
                }
            })
            .catch((err) => {
                console.log(err.message, 'err');
                props.setLoading(false);
                logoutAfterError(err)
            });
    };

    const [filterBtn, setFilterBtn] = useState("12 months");
    const isMobile = useMediaQuery({
        query: '(max-width: 767px)'
    });

    const handleLineChartDateFilter = (data) => {
        setFilterBtn(data);
        switch (data) {
            case "12 months": return setFromDate(
                moment().subtract(12, 'months').format('MMMM Do YYYY, 00:00:00 a')
            );
            case "3 months": return setFromDate(
                moment().subtract(3, 'months').format('MMMM Do YYYY, 00:00:00 a')
            );
            case "30 days": return setFromDate(
                moment().subtract(30, 'days').format('MMMM Do YYYY, 00:00:00 a')
            );
            case "7 days": return setFromDate(
                moment().subtract(7, 'days').format('MMMM Do YYYY, 00:00:00 a')

            );
            case "24 hours": return setFromDate(
                moment().subtract(1, 'days').format('MMMM Do YYYY, h:mm:ss a')
            );
        }
    };

    useEffect(() => {
        getUniqueMerchant();
    }, [fromDate]);

    const getTransactionStatusCodes = async () => {
        await call(
            {
                ...GET_TRANSACTION_STATUS_CODES,
                url: base_url + GET_TRANSACTION_STATUS_CODES.url,
            },
            { email: email, type: ["deposit", "withdrawal"], ...(adminRole === "PaymentProviderUser" && {
                providers:[{_id:localDb.getVal("paymentProviderId")}]
              }) }
        )
            .then((res) => {
                if (res.status === 200) {
                    let allStatus = {}
                    res.data.transactionStatusCodes.map(Obj => {
                        Obj.allStatusCodes.map(sts => {
                            allStatus[sts] = sts
                        })
                    })
                    setStatusList(Object.values(allStatus))
                    setSelectedStatusList(Object.values(allStatus));
                }
            })
            .catch((err) => {
                console.log(err.message, "Transactions > getTransactionStatusCodes > err", err);
                logoutAfterError(err)
            });
    };
    const getCountTable = async () => {
        props.setLoading(true)
        await call(
            {
                ...GET_GETCOUNT_TABLE,
                url: base_url + GET_GETCOUNT_TABLE.url,
            },
            {
                email: email,
                time: props.selectedTime ? props.selectedTime : "All",
                method: selectedTabs.id === 1 ? "crypto" : "card",
                orderBy: orderBy,
                sortBy: sortBy,
                ...(adminRole === "PaymentProviderUser" && {
                    providers:[{_id:localDb.getVal("paymentProviderId")}]
                  }),
                merchnants: props.selectedMerchantName.length === 0 || props.selectedMerchantName.length === props.merchantName.length
                    ? 'All'
                    : props.selectedMerchantName,
            }
        ).then(response => {
            setCountTable(response.data)
            props.setLoading(false)
        }).catch(err => {
            console.log(err)
            props.setLoading(false)
        })
    }
    const getHouseKeepingAlert = async () => {
        props.setLoading(true)
        await call(
            {
                ...GET_HOUSEKEEPING_ALERT,
                url: base_url + GET_HOUSEKEEPING_ALERT.url,
            },
            {
                email: email,
                time: props.selectedTime ? props.selectedTime : "All",
                ...(adminRole === "PaymentProviderUser" && {
                    providers:[{_id:localDb.getVal("paymentProviderId")}]
                  }),
            }
        ).then(response => {
            setAlertHouseKeeping(response.data)
            props.setLoading(false)
        }).catch(err => {
            console.log(err)
            props.setLoading(false)
        })
    }

    let QueryParam = `${props.selectedPaymentProvider.length > 0 && props.selectedPaymentProvider.length !== props.paymentProviderList.length ? ("&paymentProvider=" + JSON.stringify(props.selectedPaymentProvider.map(obj => obj._id))) : ''}${props.merchantName.length > 0 && props.selectedMerchantName.length !== props.merchantName.length ? ("&merchantName=" + JSON.stringify(props.selectedMerchantName)) : ''}`
    
		const [showCryptoInfo, toggleCryptoInfo] = useState(false);
		const [showCardInfo, toggleCardInfo] = useState(false);


    return (
        <div className="dashboard">
            <div className="dashboard__accounts">
                <div className="dashboard__account dashboard__card">
                    <div className="dashboard__account_block">
                        <button onClick={() => toggleCryptoInfo(prev => !prev)} type="button" className={`dashboard__account_title border-left-blue ${showCryptoInfo ? 'active' : ''}`}>
                            Crypto
                            <i className="fa fa-chevron-down account-arrow-icon" />
                        </button>
                        {uniqMerchants && <MerchantsAccountTr key={uniqMerchants.name} {...uniqMerchants} />}
                        {merchantTransectionCount && <MerchantsAccountTr key={merchantTransectionCount.name} {...merchantTransectionCount} link={props.selectedTime ? `/merchant-transactions?type=Withdrawal&time=${props.selectedTime}${QueryParam}` : "/transactions?type=Withdrawal"} />}
                        {uniqUsers && <MerchantsAccountTr key={uniqUsers.name} {...uniqUsers} />}
                        {cryptoFee && <MerchantsAccountTr dollarSign={true} key={cryptoFee.name} {...cryptoFee} />}
                    </div>
                    <div className={`dashboard__account_block ${showCryptoInfo ? '' : 'hidden-on-toggle'}`}>
                        <p className="dashboard__account_title">Deposit</p>
                        {depositInitiated && <MerchantsAccountTr key={depositInitiated.name} {...depositInitiated} link={props.selectedTime ? `/transactions?type=deposit&method=crypto&time=${props.selectedTime}${QueryParam}` : "/transactions?type=deposit"} />}
                        {depositPending && <MerchantsAccountTr key={depositPending.name} {...depositPending} link={props.selectedTime ? `/transactions?statusgroup=Pending&method=crypto&type=deposit&time=${props.selectedTime}${QueryParam}` : "/transactions?statusgroup=Success&type=deposit"} />}
                        {depositProcessing && <MerchantsAccountTr key={depositProcessing.name} {...depositProcessing} link={props.selectedTime ? `/transactions?statusgroup=Processing&method=crypto&type=deposit&time=${props.selectedTime}${QueryParam}` : "/transactions?statusgroup=Success&type=deposit"} />}
                        {alertHouseKeeping && <MerchantsAccountTr key={alertHouseKeeping.name} {...alertHouseKeeping} link={props.selectedTime ? `/house-keeping-alerts?time=${props.selectedTime}` : "/house-keeping-alerts"} />}
                        {completedTransaction && <MerchantsAccountTr key={completedTransaction.name} {...completedTransaction} totalAmount={completedTransactionAmount} link={props.selectedTime ? `/transactions?statusgroup=Success&method=crypto&type=deposit&time=${props.selectedTime}${QueryParam}` : "/transactions?statusgroup=Success&type=deposit"} />}
                        {rejectedTransaction && <MerchantsAccountTr key={rejectedTransaction.name} {...rejectedTransaction} totalAmount={rejectedTransactionAmount} link={props.selectedTime ? `/transactions?statusgroup=Rejected&type=deposit&method=crypto&time=${props.selectedTime}${QueryParam}` : "/transactions?statusgroup=Rejected&type=deposit"} />}
                        {depositTimedout && <MerchantsAccountTr key={depositTimedout.name} {...depositTimedout} link={props.selectedTime ? `/transactions?statusgroup=Timed Out&type=deposit&method=crypto&time=${props.selectedTime}${QueryParam}` : "/transactions?status=Timed Out&type=deposit"} />}
                        {depositSpread && <MerchantsAccountTr key={depositSpread.name} spreadValue={depositSpread} {...depositSpread} />}

						</div>

						<div className={`dashboard__account_block ${showCryptoInfo ? '' : 'hidden-on-toggle'}`}>
							<p className="dashboard__account_title">Withdrawal</p>
							{withdrawalRequest && <MerchantsAccountTr key={withdrawalRequest.name} {...withdrawalRequest} totalAmount={withdrawalRequestAmount} link={props.selectedTime ? `/transactions?status=Require Approval&type=withdrawal&time=${props.selectedTime}${QueryParam}` : "/transactions?exceptList=Success&type=withdrawal"} />}
							{withdrawalProcessing && <MerchantsAccountTr key={withdrawalProcessing.name} {...withdrawalProcessing} link={props.selectedTime ? `/transactions?statusgroup=Processing&type=withdrawal&time=${props.selectedTime}${QueryParam}` : "/transactions?status=Processing&type=withdrawal"} />}
							{withdrawalRejected && <MerchantsAccountTr key={withdrawalRejected.name} {...withdrawalRejected} link={props.selectedTime ? `/transactions?statusgroup=Rejected&type=withdrawal&time=${props.selectedTime}${QueryParam}` : "/transactions?status=Success&type=withdrawal"} />}
							{withdrawalComplete && <MerchantsAccountTr key={withdrawalComplete.name} {...withdrawalComplete} totalAmount={withdrawalCompleteAmount} link={props.selectedTime ? `/transactions?statusgroup=Success&type=withdrawal&time=${props.selectedTime}${QueryParam}` : "/transactions?status=Success&type=withdrawal"} />}
                            {withdrawSpread && <MerchantsAccountTr key={withdrawSpread.name} spreadValue={withdrawSpread} {...withdrawSpread} />}

						</div>
					</div>
					<div className="dashboard__account dashboard__card">
						<div className="dashboard__account_block">
							<button type="button" onClick={() => toggleCardInfo(prev => !prev)} className={`dashboard__account_title border-left-blue ${showCardInfo ? 'active' : ''}`}>
								Card
								<i className="fa fa-chevron-down account-arrow-icon" />
							</button>
							{uniqMerchants && <MerchantsAccountTr key={uniqCardMerchants.name} {...uniqCardMerchants} />}
							{uniqUsers && <MerchantsAccountTr key={uniqCardUsers.name} {...uniqCardUsers} />}
                            {cardTxnFee && <MerchantsAccountTr dollarSign={true} key={cardTxnFee.name}{...cardTxnFee}/>}
						</div>
						<div className={`dashboard__account_block ${showCardInfo ? '' : 'hidden-on-toggle'}`}>
							<p className="dashboard__account_title">Deposit</p>
							{depositInitiated && <MerchantsAccountTr userCount={userCountTransaction &&  userCountTransaction.userCountDepositInitiate && userCountTransaction.userCountDepositInitiate || null} key={cardDepositInitiated.name} {...cardDepositInitiated} link={props.selectedTime ? `/transactions?type=deposit&method=card&time=${props.selectedTime}${QueryParam}` : "/transactions?type=deposit"} />}
							{depositInitiated && <MerchantsAccountTr userCount={userCountTransaction && userCountTransaction.userCountDepositPending && userCountTransaction.userCountDepositPending || null} key={cardDepositPending.name} {...cardDepositPending} link={props.selectedTime ? `/transactions?statusgroup=Pending&method=card&type=deposit&time=${props.selectedTime}${QueryParam}` : "/transactions?statusgroup=Success&type=deposit"} />}
							{depositInitiated && <MerchantsAccountTr userCount={userCountTransaction && userCountTransaction.userCountDepositProcessing && userCountTransaction.userCountDepositProcessing || null} key={cardDepositProcessing.name} {...cardDepositProcessing} link={props.selectedTime ? `/transactions?statusgroup=Processing&method=card&type=deposit&time=${props.selectedTime}${QueryParam}` : "/transactions?statusgroup=Success&type=deposit"} />}
							{completedTransaction && <MerchantsAccountTr datae2e='complted-transaction' userCount={userCountTransaction && userCountTransaction.userCountDepositSuccess && userCountTransaction.userCountDepositSuccess || null} key={cardCompletedTransaction.name} {...cardCompletedTransaction} totalAmount={cardCompletedTransactionAmount} link={props.selectedTime ? `/transactions?statusgroup=Success&method=card&type=deposit&time=${props.selectedTime}${QueryParam}` : "/transactions?statusgroup=Success&type=deposit"} />}
							{rejectedTransaction && 
                                <MerchantsAccountTr 
                                    showErrorDetails={showErrorDetails}
                                    setShowErrorDetails={setShowErrorDetails}
                                    showErrorByGroup
                                    userCount={userCountTransaction && userCountTransaction.userCountDepositRejected && userCountTransaction.userCountDepositRejected || null}
                                    key={cardRejectedTransaction.name}
                                    {...cardRejectedTransaction}
                                    totalAmount={cardRejectedTransactionAmount}
                                    link={props.selectedTime ? `/transactions?statusgroup=Rejected&method=card&type=deposit&time=${props.selectedTime}${QueryParam}` : "/transactions?statusgroup=Rejected&type=deposit"} 
                                />
                            }
                            {rejectedTransactionByGroup?.length > 0 && showErrorDetails &&
                                <ErrorDetailsComponent 
                                    data={rejectedTransactionByGroup} 
                                    showErrorDetails={showErrorDetails} 
                                    setShowErrorDetails={setShowErrorDetails} 
                                    selectedTime={props?.selectedTime} 
                                    QueryParam={QueryParam} 
                                />
                            }
                            {depositTimedout &&
                                <MerchantsAccountTr 
                                    userCount={userCountTransaction && userCountTransaction.userCountDepositTimedout && userCountTransaction.userCountDepositTimedout || null}
                                    key={cardDepositTimedout.name}
                                    {...cardDepositTimedout}
                                    link={props.selectedTime ? `/transactions?statusgroup=Timed Out&type=deposit&method=card&time=${props.selectedTime}${QueryParam}` : "/transactions?statusgroup=Timed Out&type=deposit"}
                                    />
                            }
							<div className="empty-tr"></div>
						</div>
						<div className={`dashboard__account_block ${showCardInfo ? '' : 'hidden-on-toggle'}`}>
							<p className="dashboard__account_title">Withdrawal</p>
							{cardWithdrawalRequest && <MerchantsAccountTr userCount={userCountTransaction && userCountTransaction.userCountWithdrawalRequest && userCountTransaction.userCountWithdrawalRequest || null} key={cardWithdrawalRequest.name} {...cardWithdrawalRequest} totalAmount={cardWithdrawalRequestAmount} link={props.selectedTime ? `/transactions?status=Require Approval&type=withdrawal&method=card&time=${props.selectedTime}${QueryParam}` : "/transactions?exceptList=Success&type=withdrawal"} />}
							{cardWithdrawalProcessing && <MerchantsAccountTr userCount={userCountTransaction && userCountTransaction.userCountWithdrawalProcessing && userCountTransaction.userCountWithdrawalProcessing || null} key={cardWithdrawalProcessing.name} {...cardWithdrawalProcessing} totalAmount={cardWithdrawalProcessing.sum} link={props.selectedTime ? `/transactions?statusarry=${JSON.stringify(cardWithdrawalProcessing.status)}&type=withdrawal&time=${props.selectedTime}${QueryParam}` : "/transactions?status=Success&type=withdrawal"} />}
							{cardWithdrawalRejected && <MerchantsAccountTr userCount={userCountTransaction && userCountTransaction.userCountWithdrawalRejected && userCountTransaction.userCountWithdrawalRejected || null} key={cardWithdrawalRejected.name} {...cardWithdrawalRejected} totalAmount={cardWithdrawalRejected} link={props.selectedTime ? `/transactions?status=Processing&type=withdrawal&method=card&time=${props.selectedTime}${QueryParam}` : "/transactions?status=Success&type=withdrawal"} />}
							{cardWithdrawalComplete && <MerchantsAccountTr userCount={userCountTransaction && userCountTransaction.userCountWithdrawalSuccess && userCountTransaction.userCountWithdrawalSuccess || null}  key={cardWithdrawalComplete.name} {...cardWithdrawalComplete} totalAmount={cardWithdrawalCompleteAmount} link={props.selectedTime ? `/transactions?status=Success&type=withdrawal&method=card&time=${props.selectedTime}${QueryParam}` : "/transactions?status=Success&type=withdrawal"} />}
						</div>
					</div>
				</div>

				<div className="dashboard__table_block">
					<div className="dashboard__card">
						<p className="dashboard__account_title border-left-blue">Transactions Count</p>
						<div className="dashboard__tabs">
							<button onClick={() => setSelectedTabs(tabs[0])} type="button" className={`dashboard__tabs_btn ${tabs[0].id === selectedTabs.id ? 'active' : ''}`}>{tabs[0].name}</button>
							<button onClick={() => setSelectedTabs(tabs[1])} type="button" className={`dashboard__tabs_btn ${tabs[1].id === selectedTabs.id ? 'active' : ''}`}>{tabs[1].name}</button>
						</div>
						<div className="dashboard__table">
							{selectedTabs.id === 1 &&
								<CountTable
									time={props.selectedTime ? props.selectedTime : "All"}
									method="crypto"
									countTable={countTable}
									setCountTable={setCountTable}
									sortBy={sortBy}
									orderBy={orderBy}
									changeOrder={(sort) => {
										if (sortBy !== sort) {
											setOrderBy("desc");
											setSortBy(sort);
										} else {
											setOrderBy(orderBy === "desc" ? "asc" : "desc");
										}
									}}
								/>
							}
							{selectedTabs.id === 2 &&
								<CountTable
									time={props.selectedTime ? props.selectedTime : "All"}
									method="card"
									countTable={countTable}
									setCountTable={setCountTable}
									sortBy={sortBy}
									orderBy={orderBy}
									changeOrder={(sort) => {
										if (sortBy !== sort) {
											setOrderBy("desc");
											setSortBy(sort);
										} else {
											setOrderBy(orderBy === "desc" ? "asc" : "desc");
										}
									}}
								/>
							}
						</div>
					</div>
				</div>

				<div className="dashboard__chart dashboard__card">
					<p className="dashboard__account_title border-left-blue">Recent Activity</p>
					<MerchantsAccountTr key={cardDepositInitiated.name} name={'Unique Users'} showCustomValue={true} value={Object.keys(uniqueMerchantData).length > 0 ? uniqueMerchantData.totalMerchant : 0} />
					{Object.keys(uniqueMerchantData).length > 0 && uniqueMerchantData.totalMerchant > 0 ?
						<div className="graph">
							<LineChart labels={uniqueMerchantData.dataWiseMonths} dataSet={uniqueMerchantData.data} totalMerchant={uniqueMerchantData.totalMerchant} />
						</div> : <div className="center-div mt-5 ">{`Data not found.`}</div>
					}
				</div>


				{false && <div className="dashboard_container">
					<div className="recent_activity_container">
						<div className="recent_user_activity_rights">
							<div className="heading">Transactions Count</div>
							<div className='dashboardTabs' style={{ height: "600px" }}>
									<NavTabs
											tabs={tabs}
											selectedTab={selectedTabs}
											changeTab={(tab) => {
													setSelectedTabs(tab);
											}}
									/>
									{selectedTabs.id === 1 &&
											<CountTable
													time={props.selectedTime ? props.selectedTime : "All"}
													method="crypto"
													countTable={countTable}
													setCountTable={setCountTable}
													sortBy={sortBy}
													orderBy={orderBy}
													changeOrder={(sort) => {
															if (sortBy !== sort) {
																	setOrderBy("desc");
																	setSortBy(sort);
															} else {
																	setOrderBy(orderBy === "desc" ? "asc" : "desc");
															}
													}}
											/>
									}
									{selectedTabs.id === 2 &&
											<CountTable
													time={props.selectedTime ? props.selectedTime : "All"}
													method="card"
													countTable={countTable}
													setCountTable={setCountTable}
													sortBy={sortBy}
													orderBy={orderBy}
													changeOrder={(sort) => {
															if (sortBy !== sort) {
																	setOrderBy("desc");
																	setSortBy(sort);
															} else {
																	setOrderBy(orderBy === "desc" ? "asc" : "desc");
															}
													}}
											/>
									}
							</div>
						</div>
						<div className="recent_activity_left">
								<div className="heading">{`Recent Activity`}</div>
								<div className="graph_wrap">
										<div className="graph_title">
												<div className="title">{`Unique Users`}</div>
												<div className="value">{Object.keys(uniqueMerchantData).length > 0 ? uniqueMerchantData.totalMerchant : 0}</div>
										</div>
										<div className="TransactionBtn_container">
												<FilterBtn filterBtn={filterBtn} setFilterBtn={handleLineChartDateFilter} btn={["12 months", "3 months", "30 days", "7 days", "24 hours"]} />
										</div>
										{Object.keys(uniqueMerchantData).length > 0 && uniqueMerchantData.totalMerchant > 0 ?
												<div className="graph">
														<LineChart labels={uniqueMerchantData.dataWiseMonths} dataSet={uniqueMerchantData.data} totalMerchant={uniqueMerchantData.totalMerchant} />
												</div> : <div className="center-div mt-5 ">{`Data not found.`}</div>
										}

								</div>
						</div>
					</div>

				</div>}
			</div>
    )
};


const LineChart = ({ totalMerchant, labels, dataSet }) => {
    const data = {
        labels,
        datasets: [
            {
                fill: true,
                data: dataSet,
                borderColor: '#0091FF',
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 160);
                    gradient.addColorStop(0, "rgba(46, 144, 250, 0.4)");
                    gradient.addColorStop(1, "rgba(46, 144, 250, 0)");
                    return gradient;
                },
            },
        ],
    };

    let options = {
        responsive: true,
        datasetStrokeWidth: 3,
        pointDotStrokeWidth: 4,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    drawBorder: false
                },

            },
            y: {
                display: false,
            }
        },
    };

    return (
        <div className="line-chart">
            <Line
                height={160}
                data={data}
                options={options}
            />
        </div>
    )
};

const FilterBtn = (props) => {
    let btnArray = props.btn;

    return (
        <div className="detail_btn">
            {btnArray.length > 0 && btnArray.map((btn, i) =>
                <button onClick={() => props.setFilterBtn(btn)} key={i} type="button" className={`btn ${props.filterBtn == btn ? "btn-primary text-white" : "btn btn-light border"} detail_btn_content`}>{btn}</button>
            )}
        </div>
    )
};