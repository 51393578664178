import React, { useState } from 'react';
import localDb from '../../../../localDb';
import copy from 'copy-to-clipboard';
import {
  GET_CRYPTOS,
  GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
  CREATE_DEPOSIT_TO_WALLET_ADMIN,
  VIEW_SINGLE_MERCHANT_WALLET
} from '../../../../config/endpoints';
import { connect } from 'react-redux';
import { call, logoutAfterError } from '../../../../config/axios';
import CloseIcon from '@mui/icons-material/Close';
import { getAvailableCryptos } from '../../../common/CommonUtils';

class Deposit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addNewData: {
        cryptoId: 'Select',
        errorField: '',
        errorMsg: '',
        addClicked: false,
      },
      cryptoValue:'',
      fiatValue:'',
      fromCurrency:'',
      toCurrency:'',
      currencyAmount:'',
      
      cryptos: [],
      showSavingModal: false,
      showCryptoDropDown: false,
      cryptoName: 'Select',
      cryptoImg:'',
      loading: false,
      showSendDepositModal: false,
      listOfCopyProperties: [],
      walletId:'',
      walletAddress:'',
      status:'',
      wallet:"",
      filteredList:[],
      searchMode:false,
      selectedBlockchain: []
      
    };
  }


  componentDidMount =  async () => {
    const id = window.location.pathname.split("/")[2]
    this.setState({ walletId:  id})
    await this.viewSingleWalletDetails(id);
    await this.getAllCryptos();
  }

  exchangeRate = (amt,fromCurrency,toCurrency) => {
    this.getExchangeRate(amt,fromCurrency,toCurrency);
  }

  getExchangeRate = async (amt,fromCurrency,toCurrency) => {

    if(fromCurrency==="USD"){
      this.setState({fiatValue:amt})
    }else if(fromCurrency!=="USD"){
      this.setState({cryptoValue:amt})
    }

    let payload = {
      fromCurrency:fromCurrency,
      toCurrency:toCurrency,
      currencyAmount:amt,
      email: localDb.getVal('email'),
    };
    this.setState({loading: true})
    await call(
      {
        ...GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
        url: this.props.base_url + GET_CRYPTO_AMOUNT_EXCHANGE_RATE.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          if(fromCurrency==="USD"){
            this.setState({ cryptoValue: res.data, loading: false })
          }else if(fromCurrency!=="USD"){
            this.setState({ fiatValue:res.data, loading: false })
          }
        }
      })
      .catch((err) => {
        this.setState({loading: false})
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };

  createTransaction = async () => {

    if(this.state.cryptoValue !== ''){
    let list = this.state.listOfCopyProperties;
    let payload = {
      amount:this.state.cryptoValue,
      walletId:this.state.walletId,
      fiatValue: this.state.fiatValue,
      email: localDb.getVal('email'),
    };
    list[0] = {
      crypto: this.state.addNewData.cryptoId,
      name: 'Amount',
      value: this.state.cryptoValue,
  };
    this.setState({showSendDepositModal:true,loading: true})
    await call(
      {
        ...CREATE_DEPOSIT_TO_WALLET_ADMIN,
        url: this.props.base_url + CREATE_DEPOSIT_TO_WALLET_ADMIN.url,
      },
      payload
    )
      .then((res) => {
        if (res && res.data) {
          list[1] = {
            crypto: this.state.addNewData.cryptoId,
            name: 'Address',
            value: res.data.address,
        };
          this.setState({walletAddress:res.data.address,
          status:res.data.status,
          listOfCopyProperties: list
        })
        }
      })
      .catch((err) => {
        this.setState({loading: false})
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  }
}
 viewSingleWalletDetails = async (id) => {
  await call(
    {
      ...VIEW_SINGLE_MERCHANT_WALLET,
      url: this.props.base_url + VIEW_SINGLE_MERCHANT_WALLET.url,
    },
    { email: localDb.getVal('email'), walletId: id }
  )
    .then((res) => {
      if (res.status === 200) {
        this.setState({wallet:res.data.wallets})
      }
    })
    .catch((err) => {
      this.props.setLoading(false);
      console.log(err.message, 'err');
      logoutAfterError(err)
    });
};
  getAllCryptos = async () => {
    let payload = {
      email: localDb.getVal('email'),

    };
    this.setState({loading: true})
    await call(
      {
        ...GET_CRYPTOS,
        url: this.props.base_url + GET_CRYPTOS.url,
      },
      payload
    )
      .then(async (res) => {
        if (res.status === 200) {
          this.setState({ cryptos: res.data.cryptos, loading: false });
          const results = await getAvailableCryptos(this.state.wallet.blockchain, res.data.cryptos);
          this.setState({selectedBlockchain: results})
        }
      })
      .catch((err) => {
        this.setState({loading: false})
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };

  validate = () => {
    const { cryptoId } = this.state.addNewData;
    if (!cryptoId || cryptoId === '' || cryptoId === 'Select') {
      this.setState({
        errorMsg: 'Crypto is required.',
        errorField: 'cryptoId',
      });
    } 
  };

  getStatusColor = (status) => {
    switch (status) {
        case "DepositMonitor:DepositManuallyCompleted":
        case "DepositMonitor:DepositCompleted":
        case "WalletManagementService:WithdrawalCompleted":
            return "#13FF34";
        case "DepositFlow:AwaitingDeposit":
        case "DepositFlow:Launched":
        case "CreatePayment":
        case "DepositMonitor:MonitoringMemPool":
            return "#FFA900";
        case "DepositMonitor:WalletStatusAvailable":
        case "DepositMonitor:WalletStatusLocked":
        case "DepositMonitor:UpdateWalletStatus":
        case "DepositMonitor:NotfiyMerchant":
        case "DepositMonitor:DepositManualApprove":
        case "DepositMonitor:DepositPendingManualApproval":
        case "WithdrawalFlow:PendingApproval":
        case "DepositMonitor:KYTCheckPass":
        case "DepositMonitor:KYTCheckFail":
        case "DepositMonitor:KYTCheck":
        case "DepositMonitor:DepositConfirmed":
        case "DepositMonitor:TransactionComplete":
        case "DepositMonitor:GasTooLow":
        case "DepositMonitor:ExcessTransactionExecuted":
        case "DepositMonitor:TransactionExecuted":
        case "DepositMonitor:TransactionExecuting":
        case "DepositMonitor:DepositReceivedToMemPool":
            return "#FFBE3E"
        default:
            return "#FFBE3E"
    }
}

getStatusText = (status) => {
    switch (status) {
        case "DepositMonitor:DepositManuallyCompleted":
        case "DepositMonitor:DepositCompleted":
        case "WalletManagementService:WithdrawalCompleted":
            return "Deposit Successful";
        case "DepositFlow:AwaitingDeposit":
        case "DepositFlow:Launched":
        case "CreatePayment":
        case "DepositMonitor:MonitoringMemPool":
            return "Awaiting Deposit";
        case "DepositMonitor:WalletStatusAvailable":
        case "DepositMonitor:WalletStatusLocked":
        case "DepositMonitor:UpdateWalletStatus":
        case "DepositMonitor:NotfiyMerchant":
        case "DepositMonitor:DepositManualApprove":
        case "DepositMonitor:KYTCheckPass":
        case "DepositMonitor:KYTCheckFail":
        case "DepositMonitor:KYTCheck":
        case "DepositMonitor:DepositConfirmed":
        case "DepositMonitor:TransactionComplete":
        case "DepositMonitor:GasTooLow":
        case "DepositMonitor:ExcessTransactionExecuted":
        case "DepositMonitor:TransactionExecuted":
        case "DepositMonitor:TransactionExecuting":
        case "DepositMonitor:DepositReceivedToMemPool":
            return "Processing your deposit..."
        case "DepositFlow:Cancelled":
            return "Cancelled"
        case "DepositFlow:Abandoned":
            return "Abandoned"
        case "DepositMonitor:DepositPendingManualApproval":
        case "WithdrawalFlow:PendingApproval":
            return "Require Approval"
        default:
            return "Processing your deposit..."
    }
}

  handleChange = (e) => {
    this.setState(
      {
        addNewData: {
          ...this.state.addNewData,
          [e.target.name]: e.target.value,
        },
        errorMsg: '',
        errorField: '',
      },
      () => {
        if (this.state.addClicked) {
          this.validate();
        }
      }
    );
  };

  searchCrypto=(val)=>{
    if(!val){
      this.setState({filteredList:[],searchMode:false})
    }
    let cpy=this.state.cryptos || []
    let res=cpy.filter((item)=>{
      return item.name.toLowerCase().includes(val.toLowerCase())
    })
    this.setState({filteredList:res,searchMode:true})
  }
  
  render() {
    return (
      <div>
{!this.state.showSendDepositModal ?
        <div className="myAccoutDetailsMain">
          <div className="myAccoutDetailsCard">
            <div className="d-flex justify-content-between">
              <h5
                className="myAccoutOptModalHeading card-title text-center"
                data-e2e="deposit-btn"
              >
                Deposit{' '}
              </h5>
              <div style={{ cursor: 'pointer' }} onClick={this.props.handleClose}>
                <CloseIcon size={24} />
              </div>
            </div>
            <form>

              <div
                className="FilterModalInputGroup"
                style={{ marginBottom: '20px' }}
              >
                <div className="FilterModalInputLabel">CryptoCurrency</div>
                <div
                  className="FilterModalInputDropdown"
                  onClick={() => {
                    this.setState({
                      showCryptoDropDown: true,
                    });
                  }}
                >
                  <div
                    className="FilterModalInputText" data-e2e="crypto-block-chain-drop-down"
                  >
                    {this.state.cryptoName === 'Select'
                      ? 'Select CryptoCurrency'
                      : (
                        <div className="currencyListIconNameWrap">
                              { this.state.cryptoImg ?
                            <img            
                                src={`${this.props.image_base_url}${this.state.cryptoImg}`}
                                width="31"
                                height="31"
                                alt="icon"
                                className="text-center position-absolute"
                                style={{ left: '12px' }}
                              />
                              : null
                              }
                              <div
                                style={{paddingLeft:'35px'}}
                                className="currencyList_Name"
                                name="cryptoId"
                                data-e2e={this.state.cryptoName}
                              >
                                {this.state.cryptoName}
                              </div>
                             
                            </div>
                      )}
                  </div>
                  <i
                    className="fa fa-chevron-down FilterModalInputDropIcon"
                  />
                </div>
                {this.state.showCryptoDropDown && (
                  <div
                    className="FilterModalCurrencyDropdownlist"
                    data-e2e="FilterModalCurrencyDropdownlist"
                  >
                    <div
                      className="FM_currencyDrop_All"
                      data-e2e="FM_currencyDrop_All"
                      onClick={() => {
                        this.setState({
                          showCryptoDropDown: false,
                        });
                      }}
                    >
                      <span
                        className="FM_currencyDrop_AllSelector"
                        data-e2e="FM_currencyDrop_AllSelector"
                      >
                        {this.state.cryptoName === 'Select'
                          ? 'Select Crypto'
                          : (
                            <span>{this.state.cryptoName}</span>
                          )
                          }
                      </span>
                      <span className="FilterModalInputDropUpIconWrap">
                        <i
                          data-e2e="allowable-crypto-dropdown-up"
                          className="fa fa-chevron-up FilterModalInputDropUpIcon"
                        />
                      </span>
                    </div>
                    <DropdownSearch searchCrypto={this.searchCrypto} image_base_url={this.props.image_base_url} />
                    {this.state.filteredList.length==0 && !this.state.searchMode &&
                      this.state.selectedBlockchain && this.state.selectedBlockchain.length && this.state.selectedBlockchain.map((ele, index) => {
                        return (
                          <div
                            key={index}
                            className="currencyListItemWrap"
                            name="cryptoId"
                            data-e2e={ele.name.toLowerCase().replaceAll(' ', '-')}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  showCryptoDropDown: false,
                                  cryptoImg:ele.icon,
                                  cryptoName: `${ele.name}`,
                                  addNewData: {
                                    ...this.state.addNewData,
                                    ['cryptoId']: ele.symbol,
                                  },
                                  errorMsg: '',
                                  errorField: '',
                                },
                                () => {
                                  if (this.state.addClicked) {
                                    this.validate();
                                  }
                                }
                              );
                            }}
                          >
                            <div className="currencyListIconNameWrap">
                            <img            
                                src={`${this.props.image_base_url}${ele.icon}`}
                                width="31"
                                height="31"
                                alt="icon"
                                data-e2e="icon"
                                className="text-center position-absolute"
                                style={{ left: '12px' }}
                              />
                              <div
                                style={{paddingLeft:'35px'}}
                                className="currencyList_Name"
                                name="cryptoId"
                                data-e2e={ele.symbol}
                              >
                                {ele.name}
                              </div>
                             
                            </div>
                          </div>
                        );
                      })}
                    {
                      this.state.filteredList.length>0 && this.state.searchMode &&
                      this.state.filteredList.map((ele, index) => {
                        return (
                          <div
                            key={index}
                            className="currencyListItemWrap"
                            name="cryptoId"
                            data-e2e={ele.name}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  showCryptoDropDown: false,
                                  cryptoImg:ele.icon,
                                  cryptoName: `${ele.name}`,
                                  addNewData: {
                                    ...this.state.addNewData,
                                    ['cryptoId']: ele.symbol,
                                  },
                                  errorMsg: '',
                                  errorField: '',
                                },
                                () => {
                                  if (this.state.addClicked) {
                                    this.validate();
                                  }
                                }
                              );
                            }}
                          >
                            <div className="currencyListIconNameWrap">
                            <img            
                                src={`${this.props.image_base_url}${ele.icon}`}
                                width="31"
                                height="31"
                                alt="icon"
                                data-e2e="icon"
                                className="text-center position-absolute"
                                style={{ left: '12px' }}
                              />
                              <div
                                style={{paddingLeft:'35px'}}
                                className="currencyList_Name"
                                name="cryptoId"
                                data-e2e={ele.symbol}
                              >
                                {ele.name}
                              </div>
                             
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                )}
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect34"
                >
                  Amount
                </label>
                <div style={{display:'flex'}}>
                <input
                  type="number"
                  value={this.state.cryptoValue}
                  disabled={this.state.addNewData.cryptoId === 'Select'}
                  className="form-control inputdropdown w-50"
                  id="exampleFormControlSelect34"
                  placeholder="0.00000000"
                  data-e2e="amount"
                  onChange={(e) => {
                    e.preventDefault();
                    this.exchangeRate(e.target.value,this.state.addNewData.cryptoId,"USD");
                  }}
                />

                <div style={{display:'flex',alignItems:'center',padding:'0 5px'}}>
                  <img src={`${this.props.image_base_url}/homeb2c/swap-curr.svg`}
                    width="24px"
                    height="24px"
                  />
                  </div>
                 <input
                  type="number"
                  value={this.state.fiatValue}
                  disabled={this.state.addNewData.cryptoId === 'Select'}
                  className="form-control inputdropdown w-50"
                  id="exampleFormControlSelect34"
                  placeholder="0.000"
                  onChange={(e) => {
                    e.preventDefault();
                    this.exchangeRate(e.target.value,"USD",this.state.addNewData.cryptoId);
                  }}
                />
                <div className="dollar_sign">$</div>
                </div>
              </div>                         

              <div className="d-flex justify-content-center">
                <div
                  className={`submit ${
                     this.state.cryptoValue === '' ? 'inactive' : ''
                  }`}
                  onClick={() => {
                    this.createTransaction();
                  }}
                  data-e2e="add-new-btn"
                >
                  SEND
                </div>
              </div>
              {this.state.errorMsg !== '' && (
                <div style={{ color: 'red' }}>{this.state.errorMsg}</div>
              )}
            </form>
          </div>
        </div>
          :
          <div className="myAccoutDetailsMain">
          <div className="myAccoutDetailsCard">
            <div className="d-flex justify-content-between">
              <h5
                className="myAccoutOptModalHeading card-title text-center"
                data-e2e="send-depost-headerq"
              >
                Send Deposit{' '}
              </h5>
              <div style={{ cursor: 'pointer' }} onClick={this.props.handleClose}>
                <CloseIcon size={24} />
              </div>
            </div>
            <form>
              <div>
                    <div className='row sendDeposit make-top-space' >
                      <div className='col-4 text-left p-0'>Currency:</div>
                      <div className='col-8 text-right font-weight-bold pr-3 '>{this.state.addNewData.cryptoId}</div>
                    </div>
                    {this.state.listOfCopyProperties.map((data, i) => {
                            return <CopyPropertyItem data={data} key={i} fiatValue={this.state.fiatValue}/>
                        })}
                    <div className='row sendDeposit make-top-space' >
                      <div className='col-4 text-left p-0'>Status:</div>
                      <div className='col-8 text-right font-weight-bold pr-3' style={{ color: this.getStatusColor(this.state.status) }} data-e2e="status" data-e2e-status={this.getStatusText(this.state.status).toLowerCase().replaceAll(' ', '-')}>{this.getStatusText(this.state.status)}</div>
                    </div>


              <div className="d-flex justify-content-center">
                <div
                  className={`submit ${
                    this.state.errorMsg !== '' ? 'inactive' : ''
                  }`}
                  onClick={this.props.handleClose}
                  data-e2e="continue-btn"
                >
                  Continue
                </div>
              </div>
              {this.state.errorMsg !== '' && (
                <div style={{ color: 'red' }}>{this.state.errorMsg}</div>
              )}
              </div>
            </form>
          </div>
        </div>
  }
      </div>
    );
  }
}



const DropdownSearch=(props)=>{
  
  return <div className='dropdown-search-container'>
    <input 
        placeholder='Search CryptoCyrrency' 
        className='dropdown-search-inp'
        onChange={(e)=>props.searchCrypto(e.target.value)} />
    <div className='dropdown-search-icon-container'>
      <img            
          src={`${props.image_base_url}/cryptonpay/crytoicons/searchicon1.png`}
          alt="icon"
          data-e2e="icon"
          className="dropdown-search-icon"
      />
    </div>
  </div>
}
const mapStateToProps = (state) => {
  return {
    base_url: state.config.api_url,
    image_base_url: state.config.image_base_url,
  };
};


export default connect(
  mapStateToProps,
  null
)(Deposit);

const CopyPropertyItem = (props) => {
  const { crypto, name, value } = props.data;
  const [showCoppied, toggleCoppied] = useState(false);
  return (
    <div className='row sendDeposit make-top-space' >
                      <div className='col-3 text-left p-0'>{name}:</div>
                      {showCoppied ? null : <div className="col-7 text-right font-weight-bold pr-3" data-e2e="amount" data-e2e-amount={name === "Address" ?
                  value ?
                      (value.substring(0, 7) + "..." + value.substring(value.length - 3, value.length))
                      : "N/A"
                  : value}>{name === "Address" ?
                  value ?
                      (value.substring(0, 7) + "..." + value.substring(value.length - 3, value.length))
                      : "N/A"
                  : value+" "+crypto+ " ("+props.fiatValue+"$)"}</div>}
                  {showCoppied ? <div className="copyProperty_copy" style={{ marginRight: "30px" }}>COPIED TO CLIPBOARD</div>
                :
                <div className="copyProperty_copy"
                    data-e2e="copyBtn"
                    onClick={() => {
                        copy(value)
                        toggleCoppied(true)
                        setTimeout(() => {
                            toggleCoppied(false)
                        }, 3000)
                    }}>COPY</div>
            }
                    </div>
  )
}
