import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import { call, logoutAfterError } from "../../../../config/axios";
import { GET_RISK_LIVEL_LIMITS, UPDATE_RISK_LIVEL_LIMITS } from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import CommonHeader from '../CommonPages/CommonHeader';
import Loading from '../../../common/Loading';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from "@mui/material/styles";
import { isViewPermissionValid } from '../../../common/CommonUtils';
const StyledMenu = styled((props) => (
  <Menu
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          width: 10,
          height: 10,
          right: "50%",
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,

        },
      },
    }}
    transformOrigin={{ horizontal: "center", vertical: "top" }}
    anchorOrigin={{ horizontal: "center", vertical: "top" }}
    {...props}
  />
))(({ theme }) => ({}));


const riskLevelLimitHeader = ["RISK LEVEL", "CURRENCY", "DAILY CONVERSION LIMIT", "DAILY WITHDRAWAL LIMIT",
  "MONTHLY CONVERSION LIMIT", "MONTHLY WITHDRAWL LIMIT"];

const SendReceiveFees = () => {
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getRiskLimits();
  }, []);


  const getRiskLimits = async () => {
    setLoading(true);
    await call(
      {
        ...GET_RISK_LIVEL_LIMITS,
        url: base_url + GET_RISK_LIVEL_LIMITS.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setData(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  }

  return (
    <div className='fees'>
      <HeaderAndFooterLayout>
        <CommonHeader name={`Risk Level Limits`} />

        <div className='risk-lvl-table fees_table'>
          <FeesTable data={data} getRiskLimits={getRiskLimits} />
        </div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  )
}

export default SendReceiveFees



const FeesTable = ({ data, getRiskLimits }) => {
  return (
    <div className='table_content'>
      <div className='table'>
        <div className='table_head'>
          {riskLevelLimitHeader.map((ele, i) => (
            <div className="title" key={i} >{ele}</div>
          ))}
        </div>
        {data.map((ele, i) => {
          return <SingleRowData key={i} data={ele.limits} pos={i} getRiskLimits={getRiskLimits} />
        })}
      </div>
    </div>
  );
};

const SingleRowData = ({ data, pos, getRiskLimits }) => {
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const [updatedCurrency, setUpdatedCurrency] = useState("");
  const [toUpdateField, setToUpdateField] = useState("");
  const [currAnchorEl, setCurrAnchorEl] = useState(null);
  const openEur = Boolean(currAnchorEl);
  const [updatedVal, setUpdatedVal] = useState(0);
  const [riskLevelId, setRiskLevelId] = useState("");

  const handleClick = (event, currency, field, val) => {
    setUpdatedVal(val);
    setCurrAnchorEl(event.currentTarget);
    setUpdatedCurrency(currency);
    setToUpdateField(field);
  };

  const handleClose = () => {
    setCurrAnchorEl(null);
    setUpdatedCurrency("");
    setToUpdateField("");
    setUpdatedVal(0);
    setRiskLevelId("");
  };

  const handleOnChange = (e, riskId) => {
    if (e < 0) {
      setUpdatedVal(0);
    } else {
      setUpdatedVal(e);
    }
    setRiskLevelId(riskId);
  }

  const handleOnSumbit = async () => {
    if (updatedVal != undefined && riskLevelId !== "" && updatedCurrency !== "") {
      let payload = {
        eamil: email,
        riskLevelSetting: riskLevelId,
        currency: updatedCurrency,
      }
      payload[toUpdateField] = updatedVal;
      await call(
        {
          ...UPDATE_RISK_LIVEL_LIMITS,
          url: base_url + UPDATE_RISK_LIVEL_LIMITS.url,
        },
        { payload, email: email }
      )
        .then((res) => {
          if (res.status === 200) {
            handleClose();
            getRiskLimits();
          }
        })
        .catch((err) => {
          console.log(err.message, "err");
          logoutAfterError(err)
        });

    }
  }

  return (
    <span key={pos}>
      {data.map((ele, i) => {
        return (
          <div className='single_Row' key={i} >
            <div className='table_data'>{ele.riskLevel && ele.riskLevel ? ele.riskLevel : "N/A"}</div>
            <div style={{ display: "contents" }}>
              <div className='table_data text-center'>{ele.currency ? ele.currency : "N/A"}</div>

              <div className='table_data text-decoration-underline cursor-pointer text-center' id="demo-positioned-button"
                aria-controls={openEur ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openEur ? 'true' : undefined}
                onClick={(event) => isViewPermissionValid? null: handleClick(event, ele?.currency, "dailyConversionlimit", ele?.dailyConversionlimit)}> {ele?.dailyConversionlimit ? ele?.dailyConversionlimit : 0}
              </div>

              <div className='table_data text-decoration-underline cursor-pointer text-center' id="demo-positioned-button"
                aria-controls={openEur ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openEur ? 'true' : undefined}
                onClick={(event) => isViewPermissionValid? null: handleClick(event, ele?.currency, "dailyWithdrawLimit", ele?.dailyWithdrawLimit)}> {ele?.dailyWithdrawLimit ? ele?.dailyWithdrawLimit : 0}
              </div>

              <div className='table_data text-decoration-underline cursor-pointer text-center' id="demo-positioned-button"
                aria-controls={openEur ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openEur ? 'true' : undefined}
                onClick={(event) => isViewPermissionValid? null: handleClick(event, ele?.currency, "monthlyConversionLimit", ele?.monthlyConversionLimit)}> {ele?.monthlyConversionLimit ? ele?.monthlyConversionLimit : 0}
              </div>

              <div className='table_data cursor-pointer text-center' id="demo-positioned-button"
                aria-controls={openEur ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openEur ? 'true' : undefined}
                onClick={(event) => isViewPermissionValid? null: handleClick(event, ele?.currency, "monthlyWithdrawLimit", ele?.monthlyWithdrawLimit)}> {ele?.monthlyWithdrawLimit ? ele?.monthlyWithdrawLimit : 0}
              </div>

              <StyledMenu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={currAnchorEl}
                open={openEur}
                onClose={handleClose}
              >
                <MenuItem className="p-0 m-0 spread_menuItem">
                  <input type="number" min="0"
                    onChange={(e) => handleOnChange(e.target.value, ele.id,)}
                    Value={updatedVal} />
                  <span className="spreadPopUp_button"><i
                    onClick={() => handleClose()} className="fa fa-times" aria-hidden="true"></i></span>
                  <span className="spreadPopUp_button mr-3" onClick={() => handleOnSumbit()} ><i className="fa fa-check" aria-hidden="true"></i></span>
                </MenuItem>
              </StyledMenu>
            </div>
          </div>
        )
      })}
    </span>

  );
};
