import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { GET_MERCHANT_AUTOMATION } from "../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../config/axios";
import { useParams } from "react-router-dom";
import localDb from "../../../../localDb";

function Automation() {
    
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const base_url = useSelector((state) => state.config.api_url);
    const { id } = useParams();
    const email = localDb.getVal("email");
    
    useEffect(() => {
        getMerchantAutomation();
    }, [])

    const [automationData, setAutomationData] = useState([]);

    const getMerchantAutomation = async () => {
        
        await call(
            {
                ...GET_MERCHANT_AUTOMATION,
                url: base_url + GET_MERCHANT_AUTOMATION.url,
            },
            {
                email: email,
                merchantId: id,
            }
        )
            .then((res) => {
                if (res.status === 200) {
                    setAutomationData(res.data.cryptos);
                }
            })
            .catch((err) => {
              console.log(err.message, "err")
              logoutAfterError(err)
            });
    };

    return (
        <div className="fees">
            <div className="fees_table">
            <SingleAutomationTableRow
            image_base_url={image_base_url}                                                
            data={automationData}                                                                       
            />
            </div>
        </div>
    )
}

export default Automation;


const SingleAutomationTableRow = (props) => {
    let { data} = props;
        
    const headers = [
        {
            name: "ASSET",
        },
        {
          name: "AUTOMATION",
        },
        {
          name: "RECURRING PERIOD",
        },
        {
          name: "CRYPTOCURRENCY",
        },
        {
          name: "PAYOUT FROM",
        },
        {
          name: "Wallet Address",
        },
      ];

    return (
        <div className="table_content">
          <div className="table">
            <div className="table_head">
              {headers.map(({ name }, i) => {
                return (
                  <div className="title text-left" key={i}>
                    {name}
                  </div>
                );
              })}
            </div>
            {data && data.length > 0 ? (
              data.map((ele, i) => {
                return (
                  <SingleRowData
                    data={ele}
                    key={i}                    
                  />
                );
              })
            ) : (
              <div className="No_Transactions_Found">No Data Found</div>
            )}
          </div>
        </div>
      );
};

const SingleRowData = ({ data, index }) => {
    const image_base_url = useSelector((state) => state.config.image_base_url);
    
    const [selectedSettlement, setSettlementSelected] =
        useState(data.automation && data.automation.automation ? data.automation.automation : "settlement");
    const [autotradeData, setAutotradeData] =
        useState((data.automation && data.automation.autotradeTo) ? data.automation.autotradeTo.symbol : "USDT");
    const [payoutFromData, setPayoutFromData] =
        useState((data.automation && data.automation.payoutFrom) ? data.automation.payoutFrom : "Balance");
    const [selectedRecurringPeriod, setRecurringPeriod] =
        useState(data.automation && data.automation.recurringPeriod ? data.automation.recurringPeriod : "ASAP");
  
    return (
      <div className="single_Row" key={index}>
        
        <div className="table_data">
          <span>
            <img
              src={`${image_base_url}${data.crypto.icon}`}
              width={20}
              height={20}
              alt={data.crypto.name}
              className=""
            />
          </span>{" "}
          <span className="p-1">{data.crypto.symbol ? data.crypto.symbol : 'N/A'}  / {data.crypto.name ? data.crypto.name : 'N/A'}</span>
        </div>
        <div className="table_data">
            {selectedSettlement}  
        </div>
        <div className="table_data">
            {
                selectedSettlement === "autotrade" || selectedSettlement === "disabled" ? null :
                <span className="d-flex align-items-center">
                {selectedRecurringPeriod} 
                </span>
            } 
        </div>
        <div className="table_data">
            {
                selectedSettlement === "settlement" || selectedSettlement === "disabled" ? null :
                <span className="d-flex align-items-center">
                {`Autotrade to ${autotradeData}`} 
                </span>
            }
        </div>
        <div className="table_data">
            {
                selectedSettlement === "disabled" ? null :
                <span className="d-flex align-items-center">
                {`Payout From ${payoutFromData}`} 
                </span>
            }
        </div>
        <div className="table_data">
            {
                selectedSettlement === "autotrade" || selectedSettlement === "disabled" ? null :
                <span>
                    {data.automation && data.automation.walletAddress ? data.automation.walletAddress : "N/A"}
                </span>
            }
        </div>
      </div>
    );
  };