import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Dialog from "@mui/material/Dialog";
import { styled } from '@mui/material/styles';

const useStyles = styled(Dialog)({
    root: {
        "& .MuiPaper-root": {
            position: 'fixed',
            top: 'auto',
            bottom: 0,
            width:'100vw',
            margin:0,
            backgroundColor: "white"
        }
    }
});


const TransactionViewEllipsis = (props) => {
    console.log(props.ellipsisBtn)
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const [btnDetails, setBtnDetails] = useState([]);
    const classes = useStyles;
    useEffect(() => {
        let statusList;
        if (props.view !== "wallets" &&props.transactionType !== "deposit" && props.transactionType !== "withdrawal") {
            statusList = props.ellipsisBtn.filter(ele => ele.indexComp !== 3);           
        } else if (props.transactionType && props.transactionType.toUpperCase() === "withdrawal" && props.status && [
            'WalletManagementService:WithdrawalError',
            'WalletManagementService:ProcessingWithdrawal',
            'WalletManagementService:WithdrawalRejectedDueToMerchantBalance',
            'WalletManagementService:WithdrawalRejectedDueToInsufficientFunds',
            'WalletManagementService:CalculateWithdrawalAmount',
            'WalletManagementService:UnableToSourceWithdrawalWallet']
            .includes(props.status)) {
            statusList = props.ellipsisBtn;
        }else if(props.view ==="wallets"){
            if(!["Available", "Locked"].includes(props.status)){
                statusList = props.ellipsisBtn.filter(ele => ele.indexComp !== 7); 
            }else{
                statusList = props.ellipsisBtn;  
            }
        } else {
            statusList = props.ellipsisBtn;
        }
        setBtnDetails(statusList);
    }, [])

    return (
        <div className="transaction_ellipsis ">
            <Dialog
                open={props.show}
                className={`transaction_ellipsis_modal ${classes.root}`}
            >
                <div className="content_wrap">
                    <div className="close_img">
                        <img
                            onClick={() => props.onClose()}
                            className="main-logo-dimensions"
                            src={`${image_base_url}/cryptonpay/crytoicons/close.png`}
                            alt="close"
                        />
                    </div>
                    {btnDetails && btnDetails.length > 0 && btnDetails.map((btn, i) =>
                        <div className="button_row" key={i} onClick={() => btn.onClick(btn.indexComp)}>
                            <img
                                className="btn_img"
                                src={`${image_base_url}${btn.icon}`}
                                alt={btn.name}
                            />
                            <div className="btn_title">{btn.name}</div>
                        </div>
                    )}
                </div>
            </Dialog>
        </div>
    )
}

export default TransactionViewEllipsis;