import React from 'react';

const TabButton = ({ activeTab, handleTabSwitch, tab, index, itemId }) => {
  return (
    <button
      key={index}
      className={`${
        activeTab === index
          ? "border-red-500 text-blue-600 bg-gray-200 mb-2"
          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
      } whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium h-5`}
      onClick={() => handleTabSwitch(index, itemId)}
      data-e2e={tab?.acquirerName}
    >
      {tab.acquirerName}
    </button>
  );
};

export default TabButton;
