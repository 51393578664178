import React from "react";
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

function DeletingAlert(props) {
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);

  return (
    <>
      <Modal
        show={props?.show}
        className="ClearAlertModal deleteAlertModal ClearAlertModals"
        onHide={() => {
          props?.onHide();
        }}
      >
        <Modal.Body className="ClearAlertModalBody">
          <div className="ClearAlertModalIconWrap">
            <img
              src={`${imageBaseUrl}${deleteIcon}`}
              onClick={() => {
                props?.onHide();
              }}
              alt="resetIcon"
              className="ClearAlertModalIcon"
            />
          </div>
          <div className="ClearAlertModalTitle">Are you sure?</div>
          <div className="ClearAlertModalDescription">{`Are you sure you want to delete. This action cannot be undone.`}</div>
          <div
            className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
            style={{ background: "#E02020" }}
            onClick={() => {
              props?.onDelete();
            }}
            data-e2e="clear"
          >
            Delete
          </div>
          <div
            className="ClearAlertModalGoBackBtn"
            onClick={() => {
              props?.onHide();
            }}
          >
            Go Back
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default DeletingAlert;
