import React from "react";
import moment from "moment";
import { Table } from "reactstrap";
import EditIcon from '@mui/icons-material/Edit';
import { isViewPermissionValid } from "../../../common/CommonUtils";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 46,
  height: 28,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 30,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(19px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(19px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 24,
    height: 24,
    borderRadius: 12,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 32 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const PaymentProviderUserTable = ({
  data,
  setDeletingId,
  handleShowMore,
  showMoreButton,
  handleEditUser,
  setEditData,
  setDisableData,
  disable,
}) => {
  return (
    <div className="TransactionTableMain">
      {data.length > 0 ? (
        <Table responsive>
          <tbody className="TransactionTable_BODY merchant_table_text_align">
            <tr className="TransactionTable_BODY_Header">
            <th className="TransactionTable_heading">
                Providers{" "}
              </th>
              <th className="TransactionTable_heading">
                Full Name{" "}
              </th>
              <th className="TransactionTable_heading">
                Email{" "}
              </th>
              <th className="TransactionTable_heading">
              Provider Contact Information{" "}
              </th>
              <th className="TransactionTable_heading">
                Created Date{" "}
              </th>
              <th
                className="TransactionTable_heading"
              >Actions{" "}</th>
            </tr>
            {data.map((TData, i) => {
              return (
                <SingleTransactionTableRow
                  data={TData}
                  key={i}
                  setDeletingId={setDeletingId}
                  handleEditUser={handleEditUser}
                  setEditData={setEditData}
                  setDisableData = {setDisableData}
                  disable={disable}
                />
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="No_Transactions_Found" data-e2e="No_Transactions_Found">
          No Data Found
        </div>
      )}
      {showMoreButton ? (
        <div className="Show_more_wrap">
          <div
            className="Show_more"
            data-e2e="showMore"
            onClick={() => {
              handleShowMore();
            }}
          >
            Show More
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PaymentProviderUserTable;

const SingleTransactionTableRow = ({
  data,
  setDeletingId,
  handleEditUser,
  setEditData,
  setDisableData,
}) => {
  return (
    <tr className="CP_TableRow housekeeping-table-row">
      <td className="transactionsTable_item">
        {(`${data && data.parentUser && data.parentUser.firstName} ${data && data.parentUser && data.parentUser.lastName}`) || "N/A"}
      </td>
      <td className="transactionsTable_item">
      {(`${data && data.firstName} ${data && data.lastName}`) || "N/A"}
      </td>
      <td className="transactionsTable_item">
      {(data && data.email) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {(data && data.parentUser && data.parentUser.email) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {data && data.createdAt
        ? moment(data.createdAt).format("DD/MM/YYYY - HH:mm")
        : "N/A"}
      </td>
      <td className="transactionsTable_item w-40" >
        <div className="flex justify-between">
        <EditIcon
          fontSize="large"
          color="action"
          data-e2e="edit-button"
          className={`${isViewPermissionValid? "disabled": ""} cursor-pointer pr-3`}
          onClick={() => isViewPermissionValid? null: (
            setEditData(data),
            handleEditUser()
          )}
        />
        <i
          className={`${isViewPermissionValid? "disabled": ""} fa fa-trash deleteB2cUserIcon cursor-pointer pr-4 pt-2`}
          aria-hidden="true"
          onClick={() => isViewPermissionValid? null: setDeletingId(data?._id)}
        ></i>
        <div className={`cursor-pointer ${isViewPermissionValid ? 'disabled' : ''}`}>
        <Stack direction="row" spacing={1} alignItems="center">
          <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
              onChange={() => isViewPermissionValid? null: setDisableData(data)}
              checked={data?.isUserEnabled}
            />
        </Stack>
        </div>
        </div>
      </td>
    </tr>
  );
};
