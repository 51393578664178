import React, { useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../../../common/Loading";
import { Dialog } from "@mui/material";
import { Modal } from "react-bootstrap";
import { call } from "../../../../../../config/axios";
import localDb from "../../../../../../localDb";
import {
  DELETE_INTEGRATION_TYPE,
  DELETE_SINGLE_ACQUIRER,
} from "../../../../../../config/endpoints";
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";

const DeleteIntegration = (props) => {
  const baseURL = useSelector((state) => state.config.api_url);
  const [loading, setLoading] = useState(false);
  const email = localDb.getVal("email");

  const onDeleteClickHandler = async () => {
    setLoading(true);
    if (!props?.deletingId) {
      props?.onHide();
      setLoading(false);
      props?.setReload(!props?.reload);
    }
    try {
      if (props?.acquirerId) {
        const payload = {
          email: email,
          acquirerUniqueId: props?.deletingId,
        };
        const res = await call(
          {
            ...DELETE_SINGLE_ACQUIRER,
            url: baseURL + DELETE_SINGLE_ACQUIRER.url,
          },
          payload,
        );

        if (res?.status === 200) {
          props?.onHide();
          setLoading(false);
          props?.setReload(!props?.reload);
        } else {
          alert("Something went wrong");
          props?.onHide();
          setLoading(false);
        }
      } else {
        const payload = {
          email: email,
          acquirerId: props?.deletingId,
        };
        const res = await call(
          {
            ...DELETE_INTEGRATION_TYPE,
            url: baseURL + DELETE_INTEGRATION_TYPE.url,
          },
          payload,
        );

        if (res?.status === 200) {
          props?.onHide();
          await props?.getIntegrationNames();
          setLoading(false);
          props?.setReload(!props?.reload);
        } else {
          alert("Something went wrong");
          props?.onHide();
          setLoading(false);
        }
      }
    } catch (error) {
      props?.onHide();
      setLoading(false);
      console.error("Error:", error);
    }
    return;
  };
  return (
    <>
      <Modal
        show={props?.deletingId ? true : false}
        className="ClearAlertModal deleteAlertModal ClearAlertModals"
        onHide={() => {
          props?.onHide();
          props?.setOpenDeleteIcon(false);
          props?.setShowModel(false);
          props?.setDeletingId("");
        }}
      >
        <Modal.Body className="ClearAlertModalBody">
          <div className="ClearAlertModalIconWrap">
            <img
              src={`${props?.imageBaseURL}${deleteIcon}`}
              alt="resetIcon"
              className="ClearAlertModalIcon"
              onClick={() => {
                props?.onHide();
              }}
            />
          </div>
          <div className="ClearAlertModalTitle">Are you sure?</div>
          <div className="ClearAlertModalDescription">
            {`Are you sure you want to delete `}
            <b>{props?.deletingId} ?</b>
            {` This action cannot be undone.`}
          </div>
          <div
            className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
            style={{ background: "#E02020" }}
            onClick={() => {
              onDeleteClickHandler();
            }}
            data-e2e="clear"
          >
            Delete
          </div>
          <div
            className="ClearAlertModalGoBackBtn"
            onClick={() => {
              props?.onHide();
            }}
          >
            Go Back
          </div>
        </Modal.Body>
      </Modal>
      {loading && <Loading />}
    </>
  );
};
export default DeleteIntegration;
