import React from "react";
import {MerchantUserWalletTransactionsTableRowMobile} from "./MerchantUserWalletTransactionsTableRowMobile";

export const MerchantUserTransactionsTableMobile = ({
  balances,
}) => {
  return(
    <React.Fragment>
        <div className="wallets_balanceWrap">
          {balances && balances.map((balance) => {
            return (
              <MerchantUserWalletTransactionsTableRowMobile
                key={balance._id}
                balance={balance}
              />
            );
          })}
        </div>
    </React.Fragment>
  )
}
