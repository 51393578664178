import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { ADD_BIN_TO_BLOCKED_LIST, DELETE_BIN_FROM_BLOCKED_LIST, UPDATE_COMMENT_FOR_BLOCKED_BIN } from "../../../../../config/endpoints";
import Loading from "../../../../common/Loading";
import { call } from "../../../../../config/axios";
import LocalDB from "../../../../../localDb";
import moment from 'moment';
import { AddModal, EditModal, DeleteModal, MessageModal } from "./AllBlockedBINModals";

const BlockedBINListTable = ({imageBaseUrl, tab, baseURL, globalAcquirerData, acquirerUniqueId}) => {
    const [blockedBinList, setBlockedBinList] = useState([])
    const [blockedBinListToDisplay, setBlockedBinListToDisplay] = useState([])
    const [showBlockedBinList, setShowBlockedBinList] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openMessageModal, setOpenMessageModal] = useState(false);
    const [message, setMessage] = useState("");
    const [editBin, setEditBin] = useState(null);
    const [deleteBin, setDeleteBin] = useState(null);
    const [searchBinText, setSearchBinText] = useState("");
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const limit = 10;
    let totalPages = Math.ceil(total / limit);

    useEffect(() => {
        getBlockedBinList("", 1)
    }, [])

    useEffect(()=>{
        setBlockedBinList([])
        setBlockedBinListToDisplay([])
        setPage(1)
        setTotal(0)
        setShowBlockedBinList(false)
        getBlockedBinList("", 1, true)
    }, [tab, globalAcquirerData])

    const handlePageChange = (event, value) => {
        setPage(value);
        getBlockedBinList(searchBinText, value)
    };

    useEffect(() => {
        setTotal(blockedBinList?.length);
        totalPages = Math.ceil(blockedBinList?.length / limit);
    }, [blockedBinList]);

    const paginate = (records = [], pageNumber = 1, pageSize = 10) => {
        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        return records?.slice(startIndex, endIndex);
    }

    const getBlockedBinList = async (searchBinText, page, isReset) => {
        let totalBlockeBinRecords = blockedBinList.length && !isReset ? blockedBinList : globalAcquirerData?.blockedBinList
        if (searchBinText) {
            totalBlockeBinRecords = totalBlockeBinRecords ? totalBlockeBinRecords.filter(bin => bin?.sixDigits?.includes(searchBinText)) : []
        }
        setBlockedBinList(totalBlockeBinRecords)
        setBlockedBinListToDisplay(paginate(totalBlockeBinRecords, page, limit))
        setTotal(totalBlockeBinRecords?.length);
        totalPages = Math.ceil(totalBlockeBinRecords?.length / limit);
    }

    const searchForBin = async (searchBinText) => {
        await getBlockedBinList(searchBinText, 1, true)
    }

    const addBinHandle = async (data) => {
        if (data) {
            data = { ...data,
                acquirerUniqueId: acquirerUniqueId,
                email: LocalDB.getVal("email"),
             }
            setLoading(true);
            try {
                const res = await call(
                    {
                        ...ADD_BIN_TO_BLOCKED_LIST,
                        url: baseURL + ADD_BIN_TO_BLOCKED_LIST.url,
                    },
                    data,
                );

                if (res?.status === 200) {
                    setLoading(false);
                    let totalBlockeBinRecords = blockedBinList?.length ? blockedBinList : []
                    totalBlockeBinRecords.push({ ...data, createdDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ") })
                    setBlockedBinList(totalBlockeBinRecords)
                    setTotal(totalBlockeBinRecords?.length)
                    totalPages = Math.ceil(totalBlockeBinRecords?.length / limit);
                    setPage(totalPages)
                    const normalPaginationRecords = paginate(totalBlockeBinRecords, totalPages, limit)
                    setBlockedBinListToDisplay(normalPaginationRecords)
                    setOpenAddModal(false)
                    setMessage(res?.data?.message)
                    setOpenMessageModal(true)
                }
            } catch (error) {
                setOpenAddModal(false)
                setMessage(error?.response?.data?.message)
                setOpenMessageModal(true)
                setLoading(false);
                console.error("Error:", error);
            }
        }
    }

    const deleteBinHandle = async (data) => {
        if (data) {
            data = { ...data,
                acquirerUniqueId: acquirerUniqueId,
                email: LocalDB.getVal("email"),
             }
            setLoading(true);
            try {
                const res = await call(
                    {
                        ...DELETE_BIN_FROM_BLOCKED_LIST,
                        url: baseURL + DELETE_BIN_FROM_BLOCKED_LIST.url,
                    },
                    data,
                );

                if (res?.status === 200) {
                    setLoading(false);
                    let totalBlockeBinRecords = blockedBinList
                    totalBlockeBinRecords = totalBlockeBinRecords.filter(bin => bin?.sixDigits !== data?.sixDigits)
                    setBlockedBinList(totalBlockeBinRecords)
                    const normalPaginationRecords = paginate(totalBlockeBinRecords, page, limit)
                    if (normalPaginationRecords.length <= 0) {
                        const updatedPage = page - 1
                        setBlockedBinListToDisplay(paginate(totalBlockeBinRecords, updatedPage ? updatedPage : 1, limit))
                        setPage(updatedPage ? updatedPage : 1)
                    } else {
                        setBlockedBinListToDisplay(normalPaginationRecords)
                    }
                    setTotal(totalBlockeBinRecords?.length)
                    totalPages = Math.ceil(totalBlockeBinRecords?.length / limit);
                    setOpenDeleteModal(false)
                    setMessage(res?.data?.message)
                    setOpenMessageModal(true)
                }
            } catch (error) {
                setOpenDeleteModal(false)
                setMessage(error?.response?.data?.message)
                setOpenMessageModal(true)
                setLoading(false);
                console.error("Error:", error);
            }
        }
    }

    const editBinHandle = async (data) => {
        if (data) {
            data = { ...data,
                acquirerUniqueId: acquirerUniqueId,
                email: LocalDB.getVal("email"),
             }
            setLoading(true);
            try {
                const res = await call(
                    {
                        ...UPDATE_COMMENT_FOR_BLOCKED_BIN,
                        url: baseURL + UPDATE_COMMENT_FOR_BLOCKED_BIN.url,
                    },
                    data,
                );

                if (res?.status === 200) {
                    setLoading(false);
                    let totalBlockeBinRecords = blockedBinList
                    totalBlockeBinRecords = totalBlockeBinRecords.map(bin => {
                        if (bin?.sixDigits === data?.sixDigits) {
                            bin.comment = data?.comment ? data.comment : ""
                        }
                        return bin
                    })
                    setBlockedBinList(totalBlockeBinRecords)
                    setBlockedBinListToDisplay(paginate(totalBlockeBinRecords, page, limit))
                    setOpenEditModal(false)
                    setEditBin(null)
                    setMessage(res?.data?.message)
                    setOpenMessageModal(true)
                }
            } catch (error) {
                setOpenEditModal(false)
                setMessage(error?.response?.data?.message)
                setOpenMessageModal(true)
                setEditBin(null)
                setLoading(false);
                console.error("Error:", error);
            }
        }
    }
    return (<div className="blockedBINListTableMain">
        {loading && <Loading />}
        <div id="accordion">
            <div className="card">
                <div className="card-header" id="headingOne"
                    onClick={() => {
                        setShowBlockedBinList(!showBlockedBinList);
                    }}
                >
                    <h5 className="mb-0">
                        <button className="btn showBlockedBINListCollapsable" data-toggle="collapse" data-target="#collapseExample" aria-expanded="true" aria-controls="collapseOne">
                            <div className="showBlockedBINListButton">{showBlockedBinList ? "Hide" : "Show"} Blocked BIN List</div>
                            <i className={`fa fa-angle-${showBlockedBinList ? "up" : "down"}`} />
                        </button>
                    </h5>
                </div>
                {showBlockedBinList && <div className="showBlockedBINListTable" >
                    <div className="card card-body">
                        <div className="showBlockedBINListSearchAndAddWrap pb-4">
                            <div>{blockedBinList.length > 0 || searchBinText ? <div className="form-inline">
                                <input className="form-control mr-sm-2" type="search" value={searchBinText} placeholder="Search 6 digit BIN range" onChange={(e) => {
                                    if (e?.target?.value?.length <= 6) {
                                        setSearchBinText((e.target.value.replace(/\s/g, '')).replace(/\D[^\.]/g, "").substring(0, 6))
                                    }
                                }} aria-label="Search" />
                                <button className="btn btn-outline-success my-2 my-sm-0" onClick={() => {
                                    searchForBin(searchBinText)
                                }}>Search</button>
                            </div> : <div>
                                No Data Found
                            </div>}</div>
                            <div>
                                <button type="button" className="btn btn-primary showBlockedBINListAddButton"
                                    onClick={() => {
                                        setOpenAddModal(!openAddModal)
                                    }}
                                >Add New BIN To Blocked List</button>
                            </div>
                        </div>

                        {blockedBinList.length > 0 && <div className="tableWrapForBlcoedBin"><table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">First 6-Digits</th>
                                    <th scope="col">Comment</th>
                                    <th scope="col">Created Date</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {blockedBinListToDisplay?.length ? blockedBinListToDisplay.map((binData, i) => {
                                    return <tr key={i}>
                                        <th>{binData?.sixDigits}</th>
                                        <th>{binData?.comment} <button className="commentEditButton" onClick={() => {
                                            setEditBin(binData)
                                            setOpenEditModal(true)
                                        }}><i className="fa fa-pencil" /></button></th>
                                        <th>{moment(binData?.createdDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ")}</th>
                                        <th><button onClick={() => {
                                            setDeleteBin(binData)
                                            setOpenDeleteModal(true)
                                        }}><i className="fa fa-trash" /></button></th>
                                    </tr>
                                })
                                    : <div>No records matched</div>}
                            </tbody>
                        </table>
                            <div className="mt-4 flex items-center justify-center">
                                <Pagination
                                    count={totalPages}
                                    variant="outlined"
                                    shape="rounded"
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>}
                    </div>
                </div>}
            </div>
        </div>
        {openAddModal && <AddModal
            imageBaseUrl={imageBaseUrl}
            openAddModal={openAddModal}
            onClose={() => {
                setOpenAddModal(!openAddModal)
            }}
            addBinHandle={(data) => {
                addBinHandle(data)
            }}
        />}
        {openMessageModal && <MessageModal
            imageBaseUrl={imageBaseUrl}
            openMessageModal={openMessageModal}
            message={message}
            onClose={() => {
                setOpenMessageModal(!openMessageModal)
                setMessage("")
            }}
        />}
        {openEditModal && <EditModal
            imageBaseUrl={imageBaseUrl}
            sixDigits={editBin?.sixDigits}
            comment={editBin?.comment}
            openEditModal={openEditModal}
            onClose={() => {
                setOpenEditModal(!openEditModal)
            }}
            editBinHandle={(data) => {
                editBinHandle(data)
            }}
        />}
        {openDeleteModal && <DeleteModal
            imageBaseUrl={imageBaseUrl}
            sixDigits={deleteBin?.sixDigits}
            openDeleteModal={openDeleteModal}
            deleteBinId={deleteBin?.sixDigits}
            onClose={() => {
                setOpenDeleteModal(!openDeleteModal)
            }}
            deleteBinHandle={deleteBinHandle}
        />}
    </div>)
}
export default BlockedBINListTable