import WarningIcon from "@mui/icons-material/Warning";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link,  } from "react-router-dom";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
  GET_TRANSFER_WALLETS,
  GET_WALLETS,
  GET_CENTRALISED_WALLET,
  GET_WALLET_BY_ADDRESS,
  SAVE_TRANSACTION_FROM_ADMIN,
  UPDATE_WALLET_STATUS,
  VIEW_SINGLE_CENTRALISED_WALLET,
  UPDATE_CENTRALISED_WALLET_STATUS,
} from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";
import CentralisedTrasferModel from "./CentralisedTrasferModel";
import CheckBox from "@mui/icons-material/CheckBox";
import { Dialog } from "@mui/material";
import {withStyles} from "tss-react/mui"
import Tooltip from '@mui/material/Tooltip';
import { isViewPermissionValid } from "../../../common/CommonUtils";

const closeImage = "/cryptonpay/crytoicons/close.png";
const successIcon = "/cryptonpay/crytoicons/success_changes.png";
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";
const email = localDb.getVal("email");

const SingleTransactionTableRow = (props) => {
    const { data } = props;
    const imageBaseUrl = useSelector((state) => state.config.image_base_url);
    const apiUrl = useSelector((state) => state.config.api_url);
    const [selectedTransferWallet, setSelectedTransferWallet] = useState("");
    const [walletIdForTransferFunds, setWalletIdForTransferFunds] = useState(null);
    const [showModal, toggleShowModal] = useState(false);
    const [alertModal, toggleAlertModal] = useState(false);
    const [loadingExchange, setLodingExchange] = useState(false);
    const [showSuccess, toggleShowSuccess] = useState(false);
    const [selectedWalletForTransfer, setSelectedWalletForTransfer] = useState(
      []
    );
    const [selectedWalletTransfer, setSelectedWalletTransfer] = useState([]);
    const [selectedToken, setSelectedToken] = useState("");
    const [selectedTokenDetails, setSelectedTokenDetails] = useState(null);
    const [cryptoAmount, setCryptoAmount] = useState(0);
    const [cryptoEquUSDAmount, setCryptoEquUSDAmount] = useState(0);
    const [showError, toggleShowError] = useState(false);
    const [limitError, setLimitError] = useState("");
    const [transferWallets, setTransferWallets] = useState([]);
    const [singleTransferWallets, setSingleTransferWallets] = useState(null);
    const view_transaction_url = useSelector((state) => state.config.api_url);
    const adminEmail = localDb.getVal("email");
    const [choosenWallet, setChoosenWallet] = useState('Transfer');
    const [allWalletsData, setAllWalletsData] = useState([]);
    const [merchantWallets, setMerchantWallets] = useState([])
    const [selectedWalletType, setSelectedWalletType] = useState("transferWallet");
    const [selectedNewtwork, setSelectedNetwork] = useState('');
    const [selectedBlockchain, setSelectedBlockchain] = useState('')
    const getStatusComponent = (status, blockchain) => {
      switch (status) {
        case "DepositMonitor:DepositPendingManualApproval":
          return (
            <div className="Mstatus_witheld" data-e2e={`WITH HELD-${blockchain}`}>
              WITH HELD
            </div>
          );
        case "DepositMonitor:MonitoringMemPool":
        case "DepositFlow:AwaitingDeposit":
        case "DepositFlow:Launched":
        case "CreatePayment":
        case "DepositMonitor:WalletStatusAvailable":
        case "DepositMonitor:WalletStatusLocked":
        case "DepositMonitor:UpdateWalletStatus":
        case "DepositMonitor:NotfiyMerchant":
        case "DepositMonitor:DepositManualApprove":
        case "DepositMonitor:KYTCheckFail":
        case "DepositMonitor:KYTCheckPass":
        case "DepositMonitor:KYTCheck":
        case "DepositMonitor:DepositConfirmed":
        case "DepositMonitor:GasTooLow":
        case "DepositMonitor:ExcessTransactionExecuted":
        case "DepositMonitor:TransactionExecuted":
        case "DepositMonitor:TransactionExecuting":
        case "DepositMonitor:DepositReceivedToMemPool":
        case "DepositMonitor:TransactionComplete":
          return (
            <div className="Mstatus_pending" data-e2e={`pending-${blockchain}`}>
              PENDING
            </div>
          );
        case "DepositMonitor:DepositManuallyCompleted":
        case "DepositMonitor:DepositCompleted":
        case "MerchantWallet:BalanceUpdated":
        case "WalletManagementService:WithdrawalCompleted":
        case "WalletManagementService:TransferCompleted":
        case "DepositFlowViaWallet:DepositCompleted":
          return (
            <div className="Mstatus_active" data-e2e={`success-${blockchain}`}>
              SUCCESS
            </div>
          );
        case "Active":
          return (
            <div className="Mstatus_active" data-e2e={`active-${blockchain}`}>
              ACTIVE
            </div>
          );
        case "Available":
          return (
            <div
              className="Mstatus_active"
              data-e2e={`Available-${blockchain}`}
            >
              AVAILABLE
            </div>
          );
        case "Inactive":
          return (
            <div className="Mstatus_inactive" data-e2e={`Inactive-${blockchain}`}>
              INACTIVE
            </div>
          );
        case "Pending":
          return (
            <div className="Mstatus_pending" data-e2e={`pending-${blockchain}`}>
              PENDING
            </div>
          );
        case "Inactive":
          return (
            <div className="Mstatus_active" data-e2e="success">
              SUCCESS
            </div>
          );
        case "Locked":
          return (
            <div
              className="Mstatus_witheld"
              data-e2e={`locked-${blockchain}`}
            >
              LOCKED
            </div>
          );
        case "Transfer Requested":
          return (
            <div className="Mstatus_witheld" data-e2e="TRANSFER RQUESTED">
              TRANSFER RQUESTED
            </div>
          );
        case "In Use":
          return (
            <div
              className="Mstatus_inactive"
              data-e2e={`in-use-${blockchain}`}
            >
              IN USE
            </div>
          );
        case "Failed":
          return (
            <div
              className="Mstatus_inactive"
              data-e2e={`failed-${blockchain}`}
            >
              FAILED
            </div>
          );
        case "Inuse":
          return (
            <div
              className="Tstatus_failed"
              data-e2e={`in-use-${blockchain}`}
            >
              INUSE
            </div>
          );
        default:
          return (
            <div
              className="Mstatus_inactive"
              data-e2e={`in-use-${blockchain}`}
            >
              IN USE
            </div>
          );
      }
    };
  
    const handleChoosenWallet = (value) => {
        setSingleTransferWallets(null);
        setChoosenWallet(value);
      };

    const getTransferWallets = async (blockchain, network) => {
      try {
        const res = await call(
          {
            ...GET_TRANSFER_WALLETS,
            url: apiUrl + GET_TRANSFER_WALLETS.url,
          },
          { email: adminEmail, blockchain, network }
        );
        let results =
          res &&
          res.data &&
          res.data.transferWallets &&
          res.data.transferWallets;
        setTransferWallets(results);
      } catch (err) {
        console.log(err.message, "err");
        logoutAfterError(err);
      }
    };
  
    const createTransaction = async (data) => {
      try {
        const payload = {
          email: adminEmail,
          type: "Transfer",
          network: data.network,
          createdBy: adminEmail,
          fromWalletId: data._id,
          centralisedWallet: true,
          fromWalletaddress: data.address.key,
          toWalletId: singleTransferWallets._id,
          toWalletAddress: singleTransferWallets.address,
          toWalletName: singleTransferWallets.name,
          cryptoId: selectedToken,
          cryptoAmount: cryptoAmount,
          usdAmount: cryptoEquUSDAmount,
          fromWalletTypeForTransfer: "centralisedWallet",
          transferTransactionType: "merchantUserTransaction",
          status: "WalletTransferFlow:TransferRequested",
          message: "Transfer created by: " + adminEmail,
          walletTypeForTransfer: selectedWalletType,
        };
        props.setLoading(true);
        await call(
          {
            ...SAVE_TRANSACTION_FROM_ADMIN,
            url: apiUrl + SAVE_TRANSACTION_FROM_ADMIN.url,
          },
          payload
        )
            props.setLoading(false);
      } catch (err) {
        props.setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      }
    };
  
    const transferFunds = async () => {
      if (walletIdForTransferFunds && walletIdForTransferFunds !== "") {
        props.setLoading(true);

        try {
          const res = await call(
            {
              ...UPDATE_CENTRALISED_WALLET_STATUS,
              url: apiUrl + UPDATE_CENTRALISED_WALLET_STATUS.url,
            },
            {
              email: adminEmail,
              status: "Transfer Requested",
              message: `${adminEmail}`,
              walletId: walletIdForTransferFunds,
            }
          );
          props.setLoading(false);
          if (res.status === 200) {
            toggleShowSuccess(true);
            setTimeout(() => {
              toggleShowSuccess(false);
            }, 2000);
            props.getWallets();
          }
        } catch (err) {
          toggleShowError(true);
          setTimeout(() => {
            toggleShowError(false);
          }, 2000);
          props.setLoading(false);
          console.log(err.message, "err");
          logoutAfterError(err);
        }
      } else {
        toggleShowError(true);
        setTimeout(() => {
          toggleShowError(false);
        }, 2000);
      }
    };
  
    const getSingleTransferWallet = async (wallet) => {
      setLimitError('')
      const data = transferWallets.filter((item) => item._id === wallet.value);
      setSingleTransferWallets(data[0]);
      setSelectedTransferWallet(wallet.label);
      setSelectedWalletType("transferWallet");
    };
  
    const getSingleTransferMerchantWallet = async (wallet) => {
      setLimitError('')
      const results = merchantWallets.filter((item) => item._id === wallet.value);
      setSingleTransferWallets({
        address: results[0].address.key,
        blockchain: results[0].blockchain,
        name: "",
        network: results[0].network,
        _id: results[0]._id,
      });
      setSelectedTransferWallet(wallet.label);
      setSelectedWalletType("merchantUserWallet");
    };
  
    const viewSingleWalletDetails = async (id) => {
      try {
        const res = await call(
          {
            ...VIEW_SINGLE_CENTRALISED_WALLET,
            url: view_transaction_url + VIEW_SINGLE_CENTRALISED_WALLET.url,
          },
          { email: email, walletId: id }
        );
          const results = res && res.data && res.data.wallets && res.data.wallets;
          setSelectedWalletTransfer(results ? results : []);
          const temp =
            results &&
            results.balance &&
            results.balance.length > 0 &&
            results.balance.map(({ cryptoId }) => ({
              value: cryptoId,
              label: cryptoId,
            }));
          setSelectedWalletForTransfer(temp);
          props.setLoading(false);
      } catch (err) {
        props.setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err);
      }
    };
    const getSingleCryptoExchangedRate = async (amount, cryptoCurrency, fiatCurrency) => {
      try {
        const baseUrl = window.location.hostname.includes("localhost")
          ? apiUrl
          : view_transaction_url;
    
        setLodingExchange(true);
    
        const response = await call(
          {
            ...GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
            url: baseUrl + GET_CRYPTO_AMOUNT_EXCHANGE_RATE.url,
          },
          {
            email: email,
            currencyAmount: amount,
            fromCurrency: cryptoCurrency,
            toCurrency: fiatCurrency,
          }
        );
    
        if (response.status === 200) {
          props.setLoading(false);
          setLodingExchange(false);
          return response.data;
        }
      } catch (err) {
        props.setLoading(false);
        setLodingExchange(false);
        console.error(err.message, "err");
        logoutAfterError(err);
        throw err; // Re-throw the error to propagate it further if needed
      }
    };
    
  
    useEffect(() => {
      if (selectedTokenDetails) {
        if (selectedToken === "XRP") {
          let newValue = selectedTokenDetails.balanceAmount - 10;
          newValue = newValue > 0 ? newValue : 0;
          onCryptoAmountChange(newValue);
        } else {
          onCryptoAmountChange(selectedTokenDetails.balanceAmount);
        }
      }
    }, [selectedTokenDetails]);
  
    const handleTransfer = async (id, blockchain, network) => {
      setSingleTransferWallets(null);
      setSelectedTransferWallet("");
      setSelectedToken("");
      setSelectedTokenDetails(null)  // setting the default values before opening the modal to fix CRYP-3644
      setLimitError("");
      setSelectedNetwork(network)
      setSelectedBlockchain(blockchain)
      toggleShowModal(true);
      setWalletIdForTransferFunds(id);
      await getTransferWallets(blockchain, network);
      await viewSingleWalletDetails(id);
      await getWallets(blockchain, network);
      await getMerchantWallet(blockchain, network)
    };
  
    const getWallets = async (blockchain, network) => {
      try {
        const res = await call(
          {
            ...GET_CENTRALISED_WALLET,
            url: view_transaction_url + GET_CENTRALISED_WALLET.url,
          },
          {
            email: email,
            filterParams: {
              adminRole: localDb.getVal("adminRole"),
              allWallets: "true",
              blockchain,
              network,
            },
          }
        );
        setAllWalletsData(
          res && res.data && res.data.wallets ? res.data.wallets : []
        );
      } catch (err) {
        console.log(err.message, "err");
        logoutAfterError(err);
      }
    };
    const getMerchantWallet = async (blockchain, network) => {
      try {
        const res = await call(
          {
            ...GET_WALLETS,
            url: view_transaction_url + GET_WALLETS.url,
          },
          {
            email: email,
            filterParams: {
              adminRole: localDb.getVal("adminRole"),
              allWallets: "true",
              blockchain,
              network,
            },
          }
        );

        setMerchantWallets(
          res && res.data && res.data.wallets ? res.data.wallets : []
        );
      } catch (err) {
        console.log(err.message, "err");
        logoutAfterError(err);
      }
    };
    const validateAmount = (value) => {
      if (value <= 0) {
        setLimitError("Amount must be greater than zero");
      } else if (
        selectedToken === "XRP" &&
        value > selectedTokenDetails.balanceAmount - 10
      ) {
        setLimitError(
          "Amount must be less than equal to " +
          (selectedTokenDetails.balanceAmount - 10) +
          " " +
          selectedToken
        );
      } else if (value > selectedTokenDetails.balanceAmount) {
        setLimitError(
          "Amount must be less than equal to " +
          selectedTokenDetails.balanceAmount +
          " " +
          selectedToken
        );
      } else {
        setLimitError("");
      }
    };
  
    const onCryptoAmountChange = async (value) => {
      if (value) {
        setCryptoAmount(value);
      } else if (value === 0) {
        setCryptoAmount(0);
      } else {
        setCryptoAmount(null);
      }
      const result = await getSingleCryptoExchangedRate(
        value,
        selectedToken,
        "USD"
      );
      if (value && ReadableStreamDefaultController) {
        // const usdEquAmt = parseFloat(value / result.cryptoAmounts[selectedToken]).toFixed(8)
        const usdEquAmt = parseFloat(result).toFixed(8);
        validateAmount(value);
        setCryptoEquUSDAmount(usdEquAmt);
      } else {
        validateAmount(0);
        setCryptoEquUSDAmount(0);
      }
    };
    const onCryptoEquUSDAmountChange = async (value) => {
      if (value) {
        setCryptoEquUSDAmount(value);
      } else if (value === 0) {
        setCryptoEquUSDAmount(0);
      } else {
        setCryptoEquUSDAmount(undefined);
      }
      const result = await getSingleCryptoExchangedRate(
        value,
        "USD",
        selectedToken
      );
      if (value && result) {
        setCryptoAmount(parseFloat(result).toFixed(8));
        // validateAmount(result.cryptoAmounts[selectedToken])
      } else {
        setCryptoAmount(0);
        // validateAmount(0)
      }
    };
    const handleChangeCryptoCurrency = (event) => {
      setSelectedToken(event.value);
      let cryptoObj = {};
      selectedWalletTransfer.balance.map((data) => {
        if (event.value === data.cryptoId) {
          cryptoObj = data;
        }
      });
      setSelectedTokenDetails(cryptoObj);
      setLimitError("");
    };
  
    const HtmlTooltip = withStyles(Tooltip, (theme) => ({
      tooltip: {
        backgroundColor: '#fafafa',
        color: '#000000',
        maxWidth: 800,
        border: '1px solid #dadde9',
        boxShadow: '10px'
    
      },
    }));
  
    const ToolTipBalance = ({ data }) => {
      return (
        <div className="wallettooltipcontainer">
          <div className="wallettooltipheading">Wallet Balance </div>
          {data.balance.map((balance, i) => {
            return (
              <div className="wallettooltiptabletext" key={i}>
                <div className="wallettooltippadding">{balance.cryptoId}</div>
                <div className="wallettooltippadding">
                  {balance.balanceAmount.toFixed(6)} ($
                  {balance.balanceAmountUSDApprox} USD){" "}
                </div>
              </div>
            );
          })}
  
          <div className="wallettooltipheading"> Blockchain balance </div>
          {data.housekeeper.map((balance, i) => {
            return (
              <div className="wallettooltiptabletext" key={i}>
                <div className="wallettooltippadding">{balance.crypto}</div>
                <div className="wallettooltippadding">
                  {balance.value.toFixed(6)}{" "}
                </div>
              </div>
            );
          })}
        </div>
      );
    };
  
    return (
      <tr className="CP_TableRow">
        <td className="transactionsTable_item">
          {(data.blockchain && data.blockchain) || "N/A"}
        </td>
        <td className="transactionsTable_item">
          {(data.cryptoId.cryptoId && data.cryptoId.cryptoId) || "N/A"}
        </td>
        <td className="transactionsTable_item">
          {(data.network && data.network) || "N/A"}
        </td>
        <td
          className="transactionsTable_item wallet_address_link"
          data-e2e-wallet={
            data &&
            data.address &&
            data.address.key &&
            data.address.key &&
            data.address.key.substring(0, 5)
          }
        >
          <div className="flex">
            {data.housekeeper ? (
              data.housekeeper.filter((housekeeperobj) =>
                data.balance.some(
                  (balanceobj) =>
                    housekeeperobj.crypto === balanceobj.cryptoId &&
                    housekeeperobj.status === "Unmatched" &&
                    (Math.abs(balanceobj.balanceAmount - housekeeperobj.value) /
                      balanceobj.balanceAmount) *
                    100 <=
                    5
                )
              ).length > 0 ? (
                <HtmlTooltip
                  title={<ToolTipBalance data={data} />}
                  placement="top"
                >
                  <WarningIcon className="warning-icon-wallet warning-orange-icon-wallet"/>
                </HtmlTooltip>
              ) : data.housekeeper.filter((obj) => obj.status === "Unmatched")
                .length > 0 ? (
                <HtmlTooltip
                  title={<ToolTipBalance data={data} />}
                  placement="top"
                >
                  <WarningIcon className="warning-icon-wallet warning-red-icon-wallet"
                  />
                </HtmlTooltip>
              ) : (
                <HtmlTooltip
                  title={<ToolTipBalance data={data} />}
                  placement="top"
                >
                  <CheckBox className="warning-icon-wallet warning-green-icon-wallet"
                  />
                </HtmlTooltip>
              )
            ) : (
              " "
            )}
  
            <Tooltip title={data.address.key && data.address.key} placement="top">
              <Link to={`/centralised-wallets/${data?._id}`}> 
                {data.address.key
                  ? data.address.key.substring(0, 5) +
                  "....." +
                  data.address.key.substring(
                    data.address.key.length - 6,
                    data.address.key.length
                  )
                  : "N/A"}
              </Link>
            </Tooltip>
          </div>
        </td>
        {/* CURRENT/LAST TRANSACTION */}
        <td className="transactionsTable_item">
          <div>
            {(data.address && data.address.seedIndex) || "0"}
          </div>
        </td>
        <td>
          <div className="text-black">
            {(data.balanceAmountUSDApprox && Number(data.balanceAmountUSDApprox).toFixed(4)) || "0"}
          </div>
        </td>
        <td className="transactionsTable_item">
          <div >
            {(data && data.paymentProvider[0] && data.paymentProvider[0].firstName && data.paymentProvider[0].firstName) + " " + (data && data.paymentProvider[0] && data.paymentProvider[0].lastName && data.paymentProvider[0].lastName) || "N/A"}
          </div>
        </td>
        <td className="transactionsTable_item">
          {data.status && data.status.updated
            ? moment(data.status.updated).format("DD/MM/YYYY - HH:mm")
            : "N/A"}
        </td>
        <td className="transactionsTable_item">
          {data.status && data.status.code
            ? getStatusComponent(data.status.code, data.blockchain)
            : "N/A"}
        </td>
        <td className="transactionsTable_item">
          {props.loading && <Loading />}
        </td>
        <td className="transactionsTable_item">
          <div>
            {data.status && data.status.code === "Locked" ? (
              <div
                className={`${isViewPermissionValid? "disabled": ""} transactionsTable_transferButton`}
                onClick={() =>
                  isViewPermissionValid? null: handleTransfer(data?._id, data?.blockchain, data?.network)
                }
                data-e2e={`${data.blockchain.toLowerCase().replaceAll(" ", "-")}`}
                data-e2e-wallet-address={
                  (data && data.address && data.address.key) || null
                }
              >
                TRANSFER
              </div>
            ) : null}
            {showModal && (
              <CentralisedTrasferModel
                showModal={showModal}
                toggleShowModal={toggleShowModal}
                transferWallets={transferWallets}
                singleTransferWallets={singleTransferWallets}
                choosenWallet={choosenWallet}
                cryptoAmount={cryptoAmount}
                cryptoEquUSDAmount={cryptoEquUSDAmount}
                onCryptoEquUSDAmountChange={onCryptoEquUSDAmountChange}
                onCryptoAmountChange={onCryptoAmountChange}
                limitError={limitError}
                selectedTransferWallet={selectedTransferWallet}
                selectedToken={selectedToken}
                getSingleTransferWallet={getSingleTransferWallet}
                selectedWalletForTransfer={selectedWalletForTransfer}
                handleChangeCryptoCurrency={handleChangeCryptoCurrency}
                loadingExchange={loadingExchange}
                toggleAlertModal={toggleAlertModal}
                handleChoosenWallet={handleChoosenWallet}
                merchantWallets={merchantWallets}
                getSingleTransferMerchantWallet={getSingleTransferMerchantWallet}
              />
            )}
            {
              <div className="merchant_dailog_screen">
                <Dialog
                  open={alertModal}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth={"lg"}
                >
                  <div>
                    <Modal
                      show={true}
                      className="ClearAlertModal transferModal"
                      onHide={() => {
                        toggleAlertModal(false);
                      }}
                    >
                      <Modal.Body className="ClearAlertModalBody transferModalBody">
                        <div className="ClearAlertModalTitle">Are you sure?</div>
                        <div className="ClearAlertModalDescription">
                          Are you sure you want to transfer funds to selected
                          wallet?
                        </div>
                        <div
                          className={`ClearAlertModalClearBtn transferFundsBtn`}
                          onClick={() => {
                            if (selectedTransferWallet) {
                              transferFunds();
                              createTransaction(data);
                              toggleAlertModal(false);
                              toggleShowModal(false);
                            }
                          }}
                          data-e2e="transfer-btn"
                        >
                          Transfer
                        </div>
                        <div
                          className="ClearAlertModalGoBackBtn"
                          onClick={() => {
                            toggleAlertModal(false);
                          }}
                          data-e2e="go-back-btn"
                        >
                          Go Back
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Dialog>
              </div>
            }
            {showSuccess && (
              <div className="successModal">
                <div className="successModalWrap">
                  <img
                    className="successModalIcon"
                    alt="successIcon"
                    src={`${imageBaseUrl}${successIcon}`}
                  />
                  <div className="successModalMsg">
                    Transfer request has been raised successfully!
                  </div>
                  <img
                    data-e2e="close-icon"
                    className="closeIcon"
                    alt="closeIcon"
                    src={`${imageBaseUrl}${closeImage}`}
                    onClick={() => {
                      toggleShowSuccess(false);
                    }}
                  />
                </div>
              </div>
            )}
            {showError && (
              <div className="successModal">
                <div className="successModalWrap">
                  <img
                    className="successModalIcon"
                    alt="errorIcon"
                    src={`${imageBaseUrl}${deleteIcon}`}
                  />
                  <div className="successModalMsg">
                    Something when wrong, transfer failed!{" "}
                  </div>
                  <img
                    className="closeIcon"
                    alt="closeIcon"
                    src={`${imageBaseUrl}${closeImage}`}
                    onClick={() => {
                      toggleShowError(false);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          {props.loading && <Loading />}
        </td>
      </tr>
    );
  };


  export default SingleTransactionTableRow


  