import React from 'react'
import PaymentProviderSweepConfig from '../../Pages/PaymentProvider/PaymentProviderSweepConfig'
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout'
import CommonHeader from '../CommonPages/CommonHeader'

const PaymentProviderSweepConfigPage = () => {
    return (
        <HeaderAndFooterLayout>
            <CommonHeader name="Payment Provider Sweep Configration" />
            <PaymentProviderSweepConfig />
        </HeaderAndFooterLayout>
    )
}

export default PaymentProviderSweepConfigPage