import { call } from "../../../config/axios"
import { GET_COUNTRY_CODE_FORM_DB } from "../../../config/endpoints"

export function toFixedTrunc(x, n = 2) {
	let xFixed = x || 0
	if (xFixed.toString().includes('e')) {
        xFixed = xFixed.toLocaleString('fullwide', { useGrouping: false })
    }
	if (xFixed === 0) { return xFixed }

	const v = (typeof xFixed === "string" ? xFixed : xFixed.toString()).split(".")
	if (n <= 0) { return v[0] }
	let f = v[1] || ""
	let formatV = v[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	if (f.length > n) {
		return `${formatV}.${f.substr(0, n)}`;
	}
	if (f === "" && (n === 2 || n === 4)) {
		return `${formatV}.${'0'.repeat(n)}`;
	}
	if (f.length === 1) {
		f = f + "0";
	}
	if (f.length === 0) {
		return `${formatV}`;
	}
	else {
		return `${formatV}.${f}`;
	}
}

export const getBalanceSign = (data) => {
	const value = data.type;
	switch (value) {
		case 'Deposit':
			return "+ ";
		case 'Withdrawal':
		case 'Payment Out':
			return "- ";
		case 'Transfer':
			return data.transferInOut === "In" ? "+ " : "- ";
		default:
			return "";
	}
};

export const getCountryCodesFromDb = async (base_url) => {
	const response = await call(
		{
			...GET_COUNTRY_CODE_FORM_DB,
			url: base_url + GET_COUNTRY_CODE_FORM_DB.url,
		},
	)
	if (response && response.data) {
		return response.data
	}
	return []
}

export const getCurrencySymbol = (shortName) => {
	switch (shortName) {
		case 'EUR':
			return '€'
		case 'USD':
			return '$'
		case 'GBP':
			return '£'
		case 'AUD':
			return 'A$'
		case 'CAD':
			return 'C$'
		case 'AED':
			return 'د.إ'
		case 'CHF':
			return '₣'
		case 'TRY':
			return '₺'
		case 'HKD':
			return 'HK$'
		case 'SGD':
			return 'S$'
		case 'mxn':
			return 'MX$'
		case 'jpy':
			return '¥'
		case 'cny':
			return 'CN¥'
		case 'thb':
			return '฿'
		default:
			return ''
	}
}

export const generateRandomListKey = () =>  {
  return Math.random().toString(36).substring(2, 9);
}
