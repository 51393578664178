import React from 'react';

function Loading() {
   let Logo = window.location.origin + "/img/loading.gif"

   return (
      <div className="app-content-loading">
         <span className="loader"></span>
      </div>
   );
}

export default Loading
