import React from 'react';
import Header from './Header';

const HeaderAndFooterLayout = (props) => {
  return (
    <div>
      <Header {...props} />
      <div className="layout-container">
				{props.children}
			</div>
    </div>
  );
};

export default HeaderAndFooterLayout;
