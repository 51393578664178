import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import Dialog from "@mui/material/Dialog";
import Loading from "../../../common/Loading";
import localDb from "../../../../localDb";
import { GET_TRANSACTIONS, GET_TRANSACTION_STATUS_CODES, GET_CRYPTOS } from "../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../config/axios";
import moment from "moment";
import TransactionFilters from "./TransactionFilters";
import TransactionTable from "./TransactionTable";
import { useParams } from "react-router-dom";
import DownloadCsv from "../CommonPages/DownloadCsv";
import NavTabs from "../../../common/NavTabs";
import BalancesTab from "../Merchants/BalancesTab";
import UpdateMerchant from "../Merchants/UpdateMerchant";
import SpreadRates from "../B2cSpreadLevels/b2cSpreadLevels";
import Automation from "../Merchants/Automation";
import MerchantCountryFees from "../Merchants/MerchantCountryFees";
import { NUMBER_RECENT_DAYS_FILTER } from "./helpers";
import MerchantUsers from "../Merchants/MerchantUsers";
import FiatConfigCard from "../Merchants/FiatConfigCard";

const searchicon = "/cryptonpay/crytoicons/searchicon1.png";
const filterIcon = "/cryptonpay/filter_icon.svg";
const downloadIcon = "/cryptonpay/download_icon.svg";

function InduvisualTransactions() {
  const { id } = useParams();
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal("email");
  const username = "merchantName";
  const base_url = useSelector((state) => state.config.api_url);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearhText] = useState("");
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("status.updated");
  const [fromDate, setFromDate] = useState(
    moment().subtract(NUMBER_RECENT_DAYS_FILTER, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [toDate, setToDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [selectedMethod, setSelectedMethod] = useState([])
  const [dateSelected, setDateSelected] = useState(false);
  const [transactionFitlers, setTransactionFilters] = useState(false);
  const [downloadCsv, setDownloadCsv] = useState(false);

  const [statusList, setStatusList] = useState([]);
  const statusGroupList = [
    "Success",
    "Pending",
    "Require Approval",
    "Withdraw Request",
    "Rejected",
    "Cancelled",
    "Abandoned",
    "Processing",
    "Timed Out",];
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [selectedStatusGroupList, setSelectedStatusGroupList] = useState(["Success",
    "Pending",
    "Require Approval",
    "Withdraw Request",
    "Rejected",
    "Cancelled",
    "Abandoned",
    "Processing",
    "Timed Out",]);
  const [transactionStatusCodes, setTransactionStatusCodes] = useState([]);
  const type = [
    "deposit",
    "withdrawal",
  ];
  const [selectedType, setSelectedType] = useState([
    "deposit",
    "withdrawal",
  ]);
  const methods = [
    "crypto",
    "card",
    "wallet",
  ]
  const [cryptos, setCryptos] = useState([]);
  const [selectedCryptos, setSelectedCryptos] = useState([]);
  const tabs = [
    {
      name: "BALANCES",
      id: 1,
    },
    {
      name: "TRANSACTIONS",
      id: 2,
    },
    {
      name: "SPREAD RATES",
      id: 3,
    },
    {
      name: "MERCHANT SETTINGS",
      id: 4,
    },
    {
      name: "AUTOMATIONS",
      id: 5,
    },
    {
      name: "COUNTRY FEES",
      id: 6,
    },
    {
      name: "MERCHANT USERS",
      id: 7,
    },
    {
      name: "FIAT CONFIG(CARD)",
      id: 8,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const fiatCurrency = [
    {
      icon: "/cryptonpay/crytoicons/euro.png",
      name: "Euro",
      symbol: "EUR",
    },
    {
      icon: "/cryptonpay/crytoicons/usa.png",
      name: "US Dollar",
      symbol: "USD",
    },
    {
      icon: "/cryptonpay/crytoicons/uk.png",
      name: "British Pound",
      symbol: "GBP",
    },
  ];
  const [selectedFiatCurrency, setSelectedFiatCurrency] = useState([
    "EUR",
    "USD",
    "GBP",
  ]);

  const [save, setSave] = useState(false);

  const handleTransactionFilters = () => {
    setTransactionFilters(false);
  };

  const handleSave = () => {
    setSave(!save);
  };
  const handleDownloadCsv = () => {
    setDownloadCsv(true);
  };
  const handleCloseDownloadCsv = () => {
    setDownloadCsv(false);
  };
  const resetFilters = () => {
    setSearhText("");
    setOrderBy("desc");
    setSortBy("status.updated");
    setPage(1);
    setDateSelected(false);
    setTotalCount(0);
    setSelectedStatusList([]);
    setSelectedStatusGroupList([]);
    setFromDate([
      moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    ]);
    setToDate([moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")]);
    setSelectedCryptos([]);
    setSelectedFiatCurrency([]);
    setSelectedType([]);
  };
	
  const getInduvisualTransactions = async (page) => {
    let pageNo = 1;
    if (page) {
      pageNo = page;
    }

    let payload = {
      email: email,
      transactionId: searchText.toString(),
      merchantUserId: searchText.toString(),
      walletAddress: searchText.toString(),
      merchantId: id,
      orderBy: orderBy,
      sortBy: sortBy,
			fromDate: moment().subtract(NUMBER_RECENT_DAYS_FILTER, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
			toDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      page: pageNo,
      CryptoCurrency:
        selectedCryptos.length === 0 ||
          selectedCryptos.length === cryptos.length
          ? "All"
          : selectedCryptos,
      FiatCurrency:
        selectedFiatCurrency.length === 0 ||
          selectedFiatCurrency.length === fiatCurrency.length
          ? "All"
          : selectedFiatCurrency,
      status:
        selectedStatusList.length === 0 ||
          selectedStatusList.length === statusList.length
          ? "All"
          : selectedStatusList,
      statusGroup:
        selectedStatusGroupList.length === 0 ||
          selectedStatusGroupList.length === statusGroupList.length
          ? "All"
          : selectedStatusGroupList,
      type:
        selectedType.length === type.length
          ? "All"
          : selectedType,
      method:
        selectedMethod.length === methods.length
          ? "All"
          : selectedMethod,
    };

    if (dateSelected) {
      payload = {
        ...payload,
        fromDate: fromDate,
        toDate: toDate,
      };
    }

    setLoading(true);
    await call(
      {
        ...GET_TRANSACTIONS,
        url: base_url + GET_TRANSACTIONS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          if (
            res.data.transactionsList[0] &&
            res.data.transactionsList[0].merchant &&
            res.data.transactionsList[0].merchant.length > 0
          ) {
            localDb.setVal(
              "sub_name",
              res.data.transactionsList[0].merchant[0].name
            );
          }
          if (pageNo === 1) {
            setData(res.data.transactionsList);
            setTotalCount(res.data.totalCount);
          } else {
            setData(res.data.transactionsList);
            setTotalCount(res.data.totalCount);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const getCryptos = async () => {
    await call(
      {
        ...GET_CRYPTOS,
        url: base_url + GET_CRYPTOS.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          setCryptos(res.data.cryptos);

          let cryptos = res.data.cryptos.map((crypto) => {
            return crypto.symbol;
          });
          setSelectedCryptos(cryptos);
        }
      })
      .catch((err) => { 
        console.log(err.message, "err") 
        logoutAfterError(err)
      });
  };

  const getTransactionStatusCodes = async () => {
    setLoading(true);
    await call(
      {
        ...GET_TRANSACTION_STATUS_CODES,
        url: base_url + GET_TRANSACTION_STATUS_CODES.url,
      },
      { email: email, type: ["deposit", "withdrawal"] }
    )
      .then((res) => {
        if (res.status === 200) {
          setTransactionStatusCodes(res.data.transactionStatusCodes);
          let allStatus = {}
          res.data.transactionStatusCodes.map(Obj => {
            Obj.allStatusCodes.map(sts => {
              allStatus[sts] = sts
            })
          })
          setStatusList(Object.values(allStatus))
          setSelectedStatusList(Object.values(allStatus));
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message, "Transactions > getTransactionStatusCodes > err", err);
        setLoading(false);
        logoutAfterError(err)
      });
  };

  useEffect(() => {
    getCryptos();
    getTransactionStatusCodes();
  }, []);

  useEffect(() => {
    getInduvisualTransactions();
  }, [searchText, orderBy, sortBy, save]);

  const defaultUserImage = "/homeb2c/user-default-pic.svg";
  const profileImage = image_base_url + defaultUserImage
  const resetSelectedStatus = (newTypeList) => {
    let allStatus = {}
    transactionStatusCodes.map(Obj => {
      if (newTypeList.includes(Obj.type)) {
        Obj.allStatusCodes.map(sts => {
          allStatus[sts] = sts
        })
      }
    })
    setStatusList(Object.values(allStatus))
    setSelectedStatusList(Object.values(allStatus));
  }

  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
				<div className="">
					<CommonHeader
						name={username ? username : "N/A"}
            MerchantDetailsPage={true}
						backButton={true}
          />
					<NavTabs
						tabs={tabs}
						selectedTab={selectedTab}
						changeTab={(tab) => {
							setSelectedTab(tab);
						}}
						fontSize={true}
					/>
					{selectedTab.id === 1 && <BalancesTab />}
					{selectedTab.id === 2 && (
						<div>
							<div className="transaction_filter">
								<div className="transactionsFiltersWrap">
									<div className="transactionsFilters">
										<div className="transactionsFiltersItem transactionsSearchFilter">
											<div className="transactionsFilterTextWrap">
												<input
													type="text"
													className="transactionsFilterInput"
													value={searchText}
													name="searchText"
													data-e2e="search-textbox"
													onChange={(e) => {
														setSearhText(e.target.value);
														setPage(1);
													}}
													placeholder="Search Merchant, Transaction ID, Merchant Transaction ID or Merchant User ID"
												/>
											</div>
											<div className="transactionsFilterSingleIconWrap">
												<img
													src={`${image_base_url}${searchicon}`}
													alt="searchicon"
													className="transactionsFilterSingleIcon"
													data-e2e="searchIcon"
													onClick={() => {
														getInduvisualTransactions();
													}}
												/>
											</div>
										</div>

										<div
											className="filterBtn"
											data-e2e="filterBtn"
											onClick={() => {
												setTransactionFilters(true);
											}}
										>
											<img
												data-e2e="filterIcon"
												src={`${image_base_url}${filterIcon}`}
												alt="filterIcon"
												className="filterBtnIcon"
											/>
										</div>
									</div>
									<Dialog
										open={transactionFitlers}
										onClose={handleTransactionFilters}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
									>
										<TransactionFilters
											handleTransactionFilters={handleTransactionFilters}
											statusList={statusList}
											statusGroupList={statusGroupList}
											setStatusList={setStatusList}
											selectedStatusList={selectedStatusList}
											selectedStatusGroupList={selectedStatusGroupList}
											setSelectedStatusList={setSelectedStatusList}
											setSelectedStatusGroupList={setSelectedStatusGroupList}
											resetSelectedStatus={resetSelectedStatus}
											fromDate={moment(fromDate)}
											toDate={moment(toDate)}
											setFromDate={setFromDate}
											setToDate={setToDate}
											handleSave={handleSave}
											setDateSelected={setDateSelected}
											dateSelected={dateSelected}
											resetFilters={resetFilters}
											cryptos={cryptos}
											selectedCryptos={selectedCryptos}
											setSelectedCryptos={setSelectedCryptos}
											fiatCurrency={fiatCurrency}
											selectedFiatCurrency={selectedFiatCurrency}
											setSelectedFiatCurrency={setSelectedFiatCurrency}
											type={type}
											selectedType={selectedType}
											setSelectedType={setSelectedType}
											filter_merchant_name='merchantTransactions'
                      method={methods}
                      selectedMethod={selectedMethod}
                      setSelectedMethod={setSelectedMethod}
										/>
									</Dialog>
								</div>
								<div
									className="filterBtn"
									data-e2e="filterBtn"
									style={{ marginLeft: "10px" }}
									onClick={() => handleDownloadCsv()}
								>
									<img
										data-e2e="downloadIcon"
										src={`${image_base_url}${downloadIcon}`}
										alt="filterIcon"
										className="downloadBtnIcon"
									/>
								</div>
								<Dialog
									open={downloadCsv}
									onClose={setDownloadCsv}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
								>
									<DownloadCsv
										handleCloseDownloadCsv={handleCloseDownloadCsv}
										data={data}
										resetFilters={resetFilters}
										name="induvisual"
										data-e2e="induvisual"
									/>
								</Dialog>
							</div>
							<div>
								<TransactionTable
									table_name="Induvisual_transactions"
									data={data}
									totalCount={totalCount}
									sortBy={sortBy}
									orderBy={orderBy}
									setPage={(page) => {
										setPage(page)
										getInduvisualTransactions(page)
									}}
									changeOrder={(sort) => {
										if (sortBy !== sort) {
											setOrderBy("desc");
											setSortBy(sort);
										} else {
											setOrderBy(orderBy === "desc" ? "asc" : "desc");
										}
									}}
								/>
							</div>
						</div>
					)}
					{selectedTab.id === 3 && <SpreadRates comonentName={"merchant"} />}
					{selectedTab.id === 4 && <UpdateMerchant />}
					{selectedTab.id === 5 && <Automation />}
          {selectedTab.id === 6 && <MerchantCountryFees/>}
          {selectedTab?.id === 7 && <MerchantUsers merchantId={id} />}
          {selectedTab?.id === 8 && <FiatConfigCard merchantId={id} />}
				</div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
}

export default InduvisualTransactions;
