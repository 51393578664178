import React, {useState } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { SortByIcon } from '../../../common/CommonUtils';


const ErrorLogTable = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="TransactionTableMain">
      {props && props.data && props.data.length > 0 ? (
        <Table responsive style={{border:'none'}}>
          <tbody className="TransactionTable_BODY">
            <tr className="TransactionTable_BODY_Header">
              <th
                className="TransactionTable_heading"
                width="10%"
                onClick={() => {
                  props.changeOrder('merchantId');
                }}
                data-e2e="merchantId"
              >
                Merchant Id{' '}
                {props.sortBy === 'merchantId' && (
                  <SortByIcon orderBy={props.orderBy} />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="11%"
                onClick={() => {
                  props.changeOrder('transactionId');
                }}
                data-e2e="transactionID"
              >
                Transaction Id{' '}
                {props.sortBy === 'transactionId' && (
                  <SortByIcon orderBy={props.orderBy}  />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="10%"
                onClick={() => {
                  props.changeOrder('component');
                }}
                data-e2e="component"
              >
                Component{' '}
                {props.sortBy === 'component' && (
                  <SortByIcon orderBy={props.orderBy} />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="16%"
                onClick={() => {
                  props.changeOrder('createdAt');
                }}
                data-e2e="createDate"
              >
                CREATED DATE{' '}
                {props.sortBy === 'createdAt' && (
                  <SortByIcon orderBy={props.orderBy} />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="10%"
                onClick={() => {
                  props.changeOrder('severity');
                }}
                data-e2e="severity"
              >
                Severity{' '}
                {props.sortBy === 'severity' && (
                  <SortByIcon
                    orderBy={props.orderBy}
                  />
                )}
              </th>

              <th
                className="TransactionTable_heading"
                width="8%"
                onClick={() => {
                  props.changeOrder('error.code');
                }}
                data-e2e="error code"
              >
                Error Code{' '}
                {props.sortBy === 'error.code' && (
                  <SortByIcon
                    orderBy={props.orderBy}
                  />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="12%"
                onClick={() => {
                  props.changeOrder('error.message');
                }}
                data-e2e="errorMessage"
              >
                Error Message{' '}
                {props.sortBy === 'error.message' && (
                  <SortByIcon
                    orderBy={props.orderBy}
                  />
                )}
              </th>
              <th
                className="TransactionTable_heading"
                width="20%"
                onClick={() => {
                  props.changeOrder('comment');
                }}
                data-e2e="comment"
              >
                Comment{' '}
                {props.sortBy === 'comment' && (
                  <SortByIcon
                    orderBy={props.orderBy}                  />
                )}
              </th>
            </tr>
            {props.data.map((TData, i) => {
              return <SingleTransactionTableRow data={TData} key={i} loading={loading} setLoading={setLoading} getErrorLogs={() => { props.getErrorLogs() }} />;
            })}
          </tbody>
        </Table>
      ) : (
        <div className="No_Transactions_Found">No Data Found</div>
      )}
      {props.showMoreButton ? (
        <div className="Show_more_wrap">
          <div
            className="Show_more"
            onClick={() => {
              props.handleShowMore();
            }}
            data-e2e="showMore"
          >
            Show More
          </div>
        </div>
      ) : null}
    </div>
  );
};



export default ErrorLogTable

const SingleTransactionTableRow = (props) => {
  let { data } = props;
  return (
    <tr className="CP_TableRow">
      <td className="transactionsTable_item">
        {(data.merchantId && data.merchantId) || "N/A"}
      </td>
      <td className="transactionsTable_item">
      <Tooltip title={data.transactionId && data.transactionId} placement="top">
        <span>  {data.transactionId
              ? data.transactionId.substring(0, 5) +
                '.....' +
                data.transactionId.substring(
                  data.transactionId.length - 6,
                  data.transactionId.length
                )
              : 'N/A'} </span> 
      </Tooltip>       
      </td>
      <td className="transactionsTable_item">
        {(data.component && data.component) || "N/A"}
      </td>
      <td className="transactionsTable_item">
      {data.createdAt && data.createdAt
          ? moment(data.createdAt).format('DD/MM/YYYY - HH:mm')
          : 'N/A'}
      </td>
      <td className="transactionsTable_item wallet_address_link">
        {(data.severity && data.severity) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {(data.error && data.error.code) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {(data.error && data.error.message) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {(data.postMvpComment && data.postMvpComment) || "N/A"}
      </td>
    </tr>
  );
};
