import React from "react";
import { Grid, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { isViewPermissionValid } from "./CommonUtils";

const CustomTextFields = ({
  initialValues,
  values,
  isEditable,
  handleChange,
  toggleEditable,
  fieldNames,
  hideEditFor = '',
  type = "text",
}) => {
  return (
    <div>
      {Object.entries(initialValues).map(([field, value]) => (
        <Grid item xs={12} key={field}>
          <div className="flex">
            <p className="flex w-52 items-center">{fieldNames[field]}</p>
            <div className="pt-2">
              <TextField
                id={field}
                className={`${isViewPermissionValid? "disabled": ""}`}
                value={values[field]}
                variant="outlined"
                disabled={!isEditable[field]}
                onChange={(e) => isViewPermissionValid? null: handleChange(field, e?.target?.value)}
                type={type}
              />
            </div>
            {hideEditFor != fieldNames[field] && (
              <EditIcon
                data-e2e={`${fieldNames[field]}-edit`}
                fontSize="large"
                color="action"
                onClick={() => isViewPermissionValid? null: toggleEditable(field)}
                className={`${isViewPermissionValid? "disabled": ""} ml-2 cursor-pointer pt-1`}
              />
            )}
          </div>
        </Grid>
      ))}
    </div>
  );
};

export default CustomTextFields;
