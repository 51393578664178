import React, { useState } from "react";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import NavTabs from "../../../common/NavTabs";
import PaymentSpreadRate from "./PaymentSpreadRate";
import PaymentProviderSettings from "./PaymentProviderSettings";
import localDb from "../../../../localDb";
import PaymentProviderCountries from "./PaymentProviderCountries";

const SettingsPage = () => {
  const username = localDb.getVal("userName");

  const tabs = [
    {
      name: "SPREAD RATES",
      id: 1,
    },
    {
      name: "PAYMENT PROVIDER SETTINGS",
      id: 2,
    },
    {
      name: "PAYMENT PROVIDER COUNTRY FEES",
      id: 3,
    },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
        <div className="">
          <CommonHeader name={username ? username : "N/A"} backButton={true} />
          <NavTabs
            tabs={tabs}
            selectedTab={selectedTab}
            changeTab={(tab) => {
              setSelectedTab(tab);
            }}
            fontSize={true}
          />
        </div>
        {selectedTab.id === 1 && (
          <PaymentSpreadRate componentName={"merchant"} />
        )}
        {selectedTab.id === 2 && <PaymentProviderSettings />}
        {selectedTab.id === 3 && <PaymentProviderCountries />}
      </HeaderAndFooterLayout>
    </div>
  );
};

export default SettingsPage;
