import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import localDb from '../../../../localDb';
import {
  GET_CRYPTOS,
  CREATE_GLOBAL_SETTING,
} from '../../../../config/endpoints';
import { connect } from 'react-redux';
import { call, logoutAfterError } from '../../../../config/axios';
import SavingAlert from '../CommonPages/SavingAlert';

class AddNewGlobalSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addNewData: {
        blockchain: 'Select',
        cryptoId: 'Select',
        network: '',
        minCapacityThreshold: '',
        maxCapacityThreshold: '',
        depositTimer: 10,
        minimunWallets: 1,
        maximumWallets: 200,
        errorField: '',
        errorMsg: '',
        addClicked: false,
      },
      cryptos: [],
      showSavingModal: false,
      showBlockChainDropDown: false,
      showCryptoDropDown: false,
      cryptoName: 'Select',
      blockchainData: [
        {
          name: 'BTC Bchain',
          value: 'BTC Bchain',
        },
        {
          name: 'ETH Bchain',
          value: 'ETH Bchain',
        },
        {
          name: 'Tron',
          value: 'TRX',
        },
      ],
      loading: false
    };
  }

  componentDidMount() {
    this.getAllCryptos();
  }

  getAllCryptos = async () => {
    let payload = {
      email: localDb.getVal('email'),
    };
    this.setState({loading: true})
    await call(
      {
        ...GET_CRYPTOS,
        url: this.props.base_url + GET_CRYPTOS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({ cryptos: res.data.cryptos, loading: false });
        }
      })
      .catch((err) => {
        this.setState({loading: false})
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };

  validate = () => {
    const {
      blockchain,
      network,
      cryptoId,
      minCapacityThreshold,
      maxCapacityThreshold,
      depositTimer,
      minimunWallets,
      maximumWallets,
      _id,
    } = this.state.addNewData;
    let decimalExp =/^\d+$/;
    if (!network || network === '') {
      this.setState({
        errorMsg: 'Network is required.',
        errorField: 'network',
      });
    } else if (!cryptoId || cryptoId === '' || cryptoId === 'Select') {
      this.setState({
        errorMsg: 'Crypto is required.',
        errorField: 'cryptoId',
      });
    } else if (!minCapacityThreshold || minCapacityThreshold === '') {
      this.setState({
        errorMsg: 'minCapacityThreshold is required.',
        errorField: 'minCapacityThreshold',
      });
    } else if (!maxCapacityThreshold || maxCapacityThreshold === '') {
      this.setState({
        errorMsg: 'maxCapacityThreshold is required.',
        errorField: 'maxCapacityThreshold',
      });
    } else if (!depositTimer || depositTimer === '' || depositTimer <= 0) {
      this.setState({
        errorMsg: 'Deposit Timer is required.',
        errorField: 'depositTimer',
      });
    } else if (
      !minimunWallets ||
      minimunWallets === '' ||
      minimunWallets <= 0
    ) {
      this.setState({
        errorMsg: 'minimunWallets Timer is required.',
        errorField: 'minimunWallets',
      });
    } else if (
      !maximumWallets ||
      minimunWallets === '' ||
      minimunWallets <= 0
    ) {
      this.setState({
        errorMsg: 'maximumWallets Timer is required.',
        errorField: 'maximumWallets',
      });
    } else if (maximumWallets < minimunWallets) {
      this.setState({
        errorMsg: 'maximumWallets must be greter than equal to minimunWallets.',
        errorField: 'maximumWallets',
      });
    } else if (!blockchain || blockchain === '' || blockchain === 'Select') {
      this.setState({
        errorMsg: 'blockchain Timer is required.',
        errorField: 'blockchain',
      });
    }else if(!decimalExp.test(minimunWallets)){
      this.setState({
        errorMsg: "Decimal minimunWallets not allow.",
        errorField: "minimunWallets",
       });
    }else if(!decimalExp.test(maximumWallets)){
      this.setState({
        errorMsg: "Decimal maximumWallets not allow.",
        errorField: "maximumWallets",
       });
    }else if(maxCapacityThreshold < minCapacityThreshold){
      this.setState({ 
        errorMsg: "maxCapacityThreshold must be greter than equal to minCapacityThreshold.", 
        errorField: "maxCapacityThreshold", 
      })
    }

  };

  addNewGlobalSetting = async () => {
    this.setState({ addClicked: true });
    const {
      blockchain,
      network,
      cryptoId,
      minCapacityThreshold,
      maxCapacityThreshold,
      depositTimer,
      minimunWallets,
      maximumWallets,
      _id,
    } = this.state.addNewData;
    this.validate();
    if (this.state.errorField === '' && this.state.errorMsg === '') {
      let payload = {
        email: localDb.getVal('email'),
        id: _id,
        network,
        cryptoId,
        minCapacityThreshold,
        maxCapacityThreshold,
        depositTimer,
        minimunWallets,
        maximumWallets,
        blockchain,
      };
      this.setState({loading: true})
      await call(
        {
          ...CREATE_GLOBAL_SETTING,
          url: this.props.base_url + CREATE_GLOBAL_SETTING.url,
        },
        payload
      )
        .then((res) => {
          if (res.status === 200) {
            this.setState({ showSavingModal: true, loading: false });
          }
        })
        .catch((err) => {
          this.setState({loading: false})
          console.log(err.message, 'err');
          alert('Failed to Add!');
          logoutAfterError(err)
        });
    }
  };

  handleChange = (e) => {
    this.setState(
      {
        addNewData: {
          ...this.state.addNewData,
          [e.target.name]: e.target.value,
        },
        errorMsg: '',
        errorField: '',
      },
      () => {
        if (this.state.addClicked) {
          this.validate();
        }
      }
    );
  };

  render() {
    return (
      <div>
        <div className="myAccoutDetailsMain">
          <div className="myAccoutDetailsCard">
            <div className="d-flex justify-content-between">
              <h5
                className="myAccoutOptModalHeading card-title text-center"
                data-e2e="add-new-btn"
              >
                Add New{' '}
              </h5>
              <div onClick={this.props.handleClose}>
                <ArrowBackIcon size={24} />
              </div>
            </div>
            <form>
              <div className="FilterModalInputGroup">
                <div className="FilterModalInputLabel">Blockchain</div>
                <div
                  className="FilterModalInputDropdown"
                  data-e2e="blockChainDropDown"
                  onClick={() => {
                    this.setState({
                      showBlockChainDropDown: true,
                      showCryptoDropDown: false,
                    });
                  }}
                >
                  <div
                    className="FilterModalInputText"
                    data-e2e="FilterModalInputText"
                  >
                    {this.state.addNewData.blockchain === 'Select'
                      ? 'Select Network'
                      : this.state.addNewData.blockchain}
                  </div>
                  <i
                    className="fa fa-chevron-down FilterModalInputDropIcon"
                    data-e2e="blockChainDropDown"
                  />
                </div>
                {this.state.showBlockChainDropDown && (
                  <div
                    className="FilterModalCurrencyDropdownlist"
                    data-e2e="FilterModalCurrencyDropdownlist"
                  >
                    <div
                      className="FM_currencyDrop_All"
                      data-e2e="FM_currencyDrop_All"
                      onClick={() => {
                        this.setState({
                          showBlockChainDropDown: false,
                        });
                      }}
                    >
                      <span
                        className="FM_currencyDrop_AllSelector"
                        data-e2e="FM_currencyDrop_AllSelector"
                      >
                        {this.state.addNewData.blockchain === 'Select'
                          ? 'Select Network'
                          : this.state.addNewData.blockchain}
                      </span>
                      <span className="FilterModalInputDropUpIconWrap">
                        <i
                          data-e2e="allowableCryptoDropDownIcon"
                          className="fa fa-chevron-up FilterModalInputDropUpIcon"
                        />
                      </span>
                    </div>
                    {this.state.blockchainData &&
                      this.state.blockchainData.map((ele, index) => {
                        return (
                          <div
                            key={index}
                            className="currencyListItemWrap"
                            name="blockchain"
                            data-e2e={ele.name}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  showBlockChainDropDown: false,
                                  addNewData: {
                                    ...this.state.addNewData,
                                    ['blockchain']: ele.value,
                                  },
                                  errorMsg: '',
                                  errorField: '',
                                },
                                () => {
                                  if (this.state.addClicked) {
                                    this.validate();
                                  }
                                }
                              );
                            }}
                          >
                            <div className="currencyListIconNameWrap">
                              <div
                                className="currencyList_Name"
                                name="blockchain"
                                data-e2e={ele.name}
                              >
                                {ele.name}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>

              <div
                className="FilterModalInputGroup"
                style={{ marginBottom: '20px' }}
              >
                <div className="FilterModalInputLabel">Crypto</div>
                <div
                  className="FilterModalInputDropdown"
                  data-e2e="cryptoBlockChainDropDown"
                  onClick={() => {
                    this.setState({
                      showBlockChainDropDown: false,
                      showCryptoDropDown: true,
                    });
                  }}
                >
                  <div
                    className="FilterModalInputText"
                    data-e2e="FilterModalInputText"
                  >
                    {this.state.cryptoName === 'Select'
                      ? 'Select Crypto'
                      : this.state.cryptoName}
                  </div>
                  <i
                    className="fa fa-chevron-down FilterModalInputDropIcon"
                    data-e2e="FilterModalInputDropIcon"
                  />
                </div>
                {this.state.showCryptoDropDown && (
                  <div
                    className="FilterModalCurrencyDropdownlist"
                    data-e2e="FilterModalCurrencyDropdownlist"
                  >
                    <div
                      className="FM_currencyDrop_All"
                      data-e2e="FM_currencyDrop_All"
                      onClick={() => {
                        this.setState({
                          showCryptoDropDown: false,
                        });
                      }}
                    >
                      <span
                        className="FM_currencyDrop_AllSelector"
                        data-e2e="FM_currencyDrop_AllSelector"
                      >
                        {this.state.cryptoName === 'Select'
                          ? 'Select Crypto'
                          : this.state.cryptoName}
                      </span>
                      <span className="FilterModalInputDropUpIconWrap">
                        <i
                          data-e2e="allowableCryptoDropDownIcon"
                          className="fa fa-chevron-up FilterModalInputDropUpIcon"
                        />
                      </span>
                    </div>
                    {this.state.cryptos &&
                      this.state.cryptos.map((ele, index) => {
                        return (
                          <div
                            key={index}
                            className="currencyListItemWrap"
                            name="cryptoId"
                            data-e2e={ele.name}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  showCryptoDropDown: false,
                                  cryptoName: `${ele.name} (${ele.symbol})`,
                                  addNewData: {
                                    ...this.state.addNewData,
                                    ['cryptoId']: ele._id,
                                  },
                                  errorMsg: '',
                                  errorField: '',
                                },
                                () => {
                                  if (this.state.addClicked) {
                                    this.validate();
                                  }
                                }
                              );
                            }}
                          >
                            <div className="currencyListIconNameWrap">
                              <div
                                className="currencyList_Name"
                                name="cryptoId"
                                data-e2e={ele.symbol}
                              >
                                {ele.name} ({ele.symbol})
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect3"
                >
                  Network
                </label>
                <input
                  type="text"
                  value={this.state.addNewData.network}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect3"
                  placeholder="Network"
                  name="network"
                  data-e2e="network"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect33"
                >
                  Min Capacity Threshold
                </label>
                <input
                  type="text"
                  value={this.state.addNewData.minCapacityThreshold}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect33"
                  placeholder="Min Threshold"
                  name="minCapacityThreshold"
                  data-e2e="minCapacityThreshold"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect34"
                >
                  Max Capacity Threshold
                </label>
                <input
                  type="text"
                  value={this.state.addNewData.maxCapacityThreshold}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect34"
                  placeholder="Max Threshold"
                  name="maxCapacityThreshold"
                  data-e2e="maxCapacityThreshold"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>
              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect4"
                >
                  Deposit Timer
                </label>
                <input
                  type="number"
                  value={this.state.addNewData.depositTimer}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect4"
                  placeholder="Time in minutes"
                  name="depositTimer"
                  data-e2e="depositTimer"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect5"
                >
                  Minimum Wallets
                </label>
                <input
                  type="number"
                  value={this.state.addNewData.minimunWallets}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect5"
                  placeholder="Enter value 0-1000"
                  name="minimunWallets"
                  data-e2e="minimunWallets"
                  min="0"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>
              <div className="form-group myAccoutOptModal_form_group">
                <label
                  className="myAccoutOptModalLable"
                  htmlFor="exampleFormControlSelect6"
                >
                  Maximum Wallets
                </label>
                <input
                  type="number"
                  value={this.state.addNewData.maximumWallets}
                  className="form-control inputdropdown"
                  id="exampleFormControlSelect6"
                  placeholder="Enter value 0-1000"
                  name="maximumWallets"
                  data-e2e="maximumWallets"
                  min="0"
                  onChange={(e) => {
                    e.preventDefault();
                    this.handleChange(e);
                  }}
                />
              </div>

              <div className="d-flex justify-content-center">
                <div
                  className={`submit ${
                    this.state.errorMsg !== '' ? 'inactive' : ''
                  }`}
                  onClick={() => {
                    this.addNewGlobalSetting();
                  }}
                  data-e2e="add-new-btn"
                >
                  Add
                </div>
              </div>
              {this.state.errorMsg !== '' && (
                <div style={{ color: 'red' }}>{this.state.errorMsg}</div>
              )}
            </form>
            <SavingAlert
              msg="All Transactions Details has been saved successfuly."
              data-e2e="saving_alert"
              header="Details Saved"
              btnName="Close"
              showModal={this.state.showSavingModal}
              onCloseClickHandler={() => {
                this.setState({ showSavingModal: false });
                this.props.getGlobalSetting();
                this.props.handleClose();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    base_url: state.config.api_url,
  };
};


export default connect(
  mapStateToProps,
  null
)(AddNewGlobalSettings);
