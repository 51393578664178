import React, { useState } from "react";
import { useSelector } from "react-redux";
import { call, logoutAfterError } from "../../../../config/axios";
import { CREATE_PAYMENT_PROVIDER } from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";

const closeImage = '/cryptonpay/crytoicons/close.png';

const CreatePaymentProvider = (props) => {
    const base_url = useSelector((state) => state.config.api_url);
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const email = localDb.getVal("email");
    const [loading, setLoading] = useState(false);
    const [selectedCardOption,setSelectedCardOption] = useState(true);
    const [showCardOptions,setShowCardOptions] = useState(false);
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        domainNameUrl: "",
        password: "",
        confirm_password: ""
    });
    const handleCreate = () => {
        let payload = {
            adminRole: localDb.getVal("adminRole"),
            email: email,
            providerEmail: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            password: data.password,
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            state: data.state,
            country: data.country,
            postcode: data.postcode,
            phoneNo: data.phone,
            domainNameUrl: data.domainNameUrl,
            card:selectedCardOption,
        }
        createProvider(payload)
    }
    const createProvider = async (payload) => {
        setLoading(true);
        await call(
            {
                ...CREATE_PAYMENT_PROVIDER,
                url: base_url + CREATE_PAYMENT_PROVIDER.url,
            },
            payload
        )
            .then(async (res) => {
                if (res.status === 200) {
                    props.handleCloseAddNew();
                    props.getProviders();
                    props.setSuccessAlert(true);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.message, "err");
                logoutAfterError(err)
            });
    };

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const cardOptions= [
        {
          value: true,
          label: "Enable",
        },
        {
          value: false,
          label: "Disable",
        },
      ]
    let disabled = (data.email).length < 5 || (data.firstName).length < 3 || (data.firstName).length < 3
        || (data.password).length < 3 || (data.password !== data.confirm_password);
    return (
        <div>
            <div className="create_account_container">
                <div className="create_account_header">
                    <div className="create_account_heading">{`Create Provider`}</div>
                    <div
                        className="filter_close_button"
                        onClick={() => {
                            props.handleCloseAddNew();
                        }}
                    >
                        <img src={`${image_base_url}${closeImage}`} alt="closeImage" />
                    </div>
                </div>

                <div className="create_account_form">
                    <div className="d-flex justify-content-space-between">
                        <div>
                            <label>
                                <b>{"First Name"}</b>
                            </label>
                            <input
                                className="create_account_input"
                                type="text"
                                name="firstName"
                                value={data["firstName"]}
                                placeholder="First name."
                                onChange={(e) => handleChange(e)}
                                required
                            />
                        </div>

                        <div className="pl-3">
                            <label>
                                <b>{"Last Name"}</b>
                            </label>
                            <input
                                className="create_account_input"
                                type="text"
                                name="lastName"
                                value={data["lastName"]}
                                placeholder="Last name"
                                onChange={(e) => handleChange(e)}
                                required
                            />
                        </div>
                    </div>

                    <div className="mt-4">
                        <label>
                            <b>{"Email"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="email"
                            name="email"
                            value={data["email"]}
                            placeholder="Email"
                            onChange={(e) => handleChange(e)}
                            required
                        />
                    </div>

                    <div className="mt-4">
                        <label>
                            <b>{"Password"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="password"
                            name="password"
                            value={data["password"]}
                            placeholder="Password"
                            onChange={(e) => handleChange(e)}
                            required
                        />
                    </div>

                    <div className="mt-4">
                        <label>
                            <b>{"Confirm password"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="password"
                            name="confirm_password"
                            value={data["confirm_password"]}
                            placeholder="Confirm password"
                            onChange={(e) => handleChange(e)}
                            required
                        />
                    </div>

                    <div className="mt-4">
                        <label>
                            <b>{"Domain Name Url"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="domainNameUrl"
                            value={data["domainNameUrl"]}
                            placeholder="Domain Name Url"
                            onChange={(e) => handleChange(e)}
                            required
                        />
                    </div>

                    {/* <div className="mt-4">
                        <label>
                            <b>{"Redirect Url For Deposit"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="redirectUrl"
                            value={data["redirectUrl"]}
                            placeholder="Redirect Url For Deposits"
                            onChange={(e) => handleChange(e)}
                            required
                        />
                    </div> */}

                    <div
                        className="FilterModalInputGroup mt-4"
                    >
                        <label className="w-100">
                            <b>{"Card Option"}</b>
                        </label>
                        <div
                            className="FilterModalInputDropdown"
                            onClick={() => {
                                setShowCardOptions(true);
                            }}
                        >
                            <div className="FilterModalInputText">
                                {selectedCardOption ? "Enable" : "Disable"}
                            </div>
                            <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                                data-e2e="dropDownExpand"
                            />
                        </div>
                        {showCardOptions && (
                            <div
                                className="FilterModalCurrencyDropdownlist"
                                data-e2e="FilterModalCurrencyDropdownlist"
                            >
                                <div
                                    className="FM_currencyDrop_All"
                                    onClick={() => {
                                        setShowCardOptions(false);
                                    }}
                                >
                                    <span
                                        className="FM_currencyDrop_AllSelector"
                                        data-e2e="status"
                                    >
                                        {selectedCardOption ? "Enable" : "Disable"}
                                    </span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i
                                            data-e2e="dropDown"
                                            className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                        />
                                    </span>
                                </div>
                                {cardOptions && cardOptions.length > 0 &&
                                    cardOptions.map((ele, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="currencyListItemWrap"
                                                name="blockchain"
                                                data-e2e={ele.label}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowCardOptions(false);
                                                    setSelectedCardOption(ele.value);
                                                }}
                                            >
                                                <div className="currencyListIconNameWrap">
                                                    <div
                                                        className="currencyList_Name"
                                                        name="acquireRoutingServiceName"
                                                        data-e2e={ele.label}
                                                    >
                                                        {ele.label}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                   {/* <div className="mt-4">
                        <label>
                            <b>{"Font"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="text"
                            name="font"
                            value={data["font"]}
                            placeholder="font"
                            onChange={(e) => handleChange(e)}
                            required
                        />
                    </div> */}

                    <div className="mt-4">
                        <label>
                            <b>{"Phone"}</b>
                        </label>
                        <input
                            className="create_account_input"
                            type="number"
                            name="phone"
                            value={data["phone"]}
                            placeholder="Phone"
                            onChange={(e) => handleChange(e)}
                        />
                    </div>

                    <div className="d-flex justify-content-space-between mt-4">
                        <div>
                            <label>
                                <b>{"Address 1"}</b>
                            </label>
                            <input
                                className="create_account_input"
                                type="text"
                                name="address1"
                                value={data["address1"]}
                                placeholder="Address 1"
                                onChange={(e) => handleChange(e)}
                                required
                            />
                        </div>

                        <div className="pl-3">
                            <label>
                                <b>{"Address 1"}</b>
                            </label>
                            <input
                                className="create_account_input"
                                type="text"
                                name="address2"
                                value={data["address2"]}
                                placeholder="Address2"
                                onChange={(e) => handleChange(e)}
                                required
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-space-between mt-4">
                        <div>
                            <label>
                                <b>{"State"}</b>
                            </label>
                            <input
                                className="create_account_input"
                                type="text"
                                name="state"
                                value={data["state"]}
                                placeholder="State"
                                onChange={(e) => handleChange(e)}
                            />
                        </div>

                        <div className="pl-3">
                            <label>
                                <b>{"City"}</b>
                            </label>
                            <input
                                className="create_account_input"
                                type="text"
                                name="city"
                                value={data["city"]}
                                placeholder="City"
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>


                    <div className="d-flex justify-content-space-between mt-4">
                        <div>
                            <label>
                                <b>{"Country"}</b>
                            </label>
                            <input
                                className="create_account_input"
                                type="text"
                                name="country"
                                value={data["country"]}
                                placeholder="Country"
                                onChange={(e) => handleChange(e)}
                            />
                        </div>

                        <div className="pl-3">
                            <label>
                                <b>{"Post code"}</b>
                            </label>
                            <input
                                className="create_account_input"
                                type="text"
                                name="postcode"
                                value={data["postcode"]}
                                placeholder="Postcode"
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>

                    <div className="login_button_get_started">
                        <button className={disabled ? "bg-secondary" : ""} disabled={disabled} onClick={() => handleCreate()}>
                            {`CONTINUE`}
                        </button>
                    </div>
                </div>
            </div>
            {loading && <Loading />}
        </div>

    )
}

export default CreatePaymentProvider;
