import React, { useState } from "react";
import { useSelector } from "react-redux";
import localDb from "../../../../localDb";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DeletingAlert from "../CommonPages/DeletingAlert";
import { isViewPermissionValid } from "../../../common/CommonUtils";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  marginLeft: 30,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const PriceManagement = () => {
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [trading, setTrading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleTrading = () => {
    // setTrading((prev) => !prev);
    alert("here")
    setOpenDialog(true);
  };

  const handleDailog = () => {
    setOpenDialog(false);
  };

  const priceTableHeaders = [
    {
      name: "CURRENCY",
    },
    {
      name: "EUR",
    },
    {
      name: "USD",
    },
    {
      name: "GBP",
    },
    {
      name: "BTC",
    },
    {
      name: "ETH",
    },
    {
      name: "LTC",
    },
    {
      name: "LAST UPDATED",
    },
  ];

  const priceTableData = [
    {
      name: "USD Coin",
    },
    {
      name: "111.42",
    },
    {
      name: "1.00",
    },
    {
      name: "0.76",
    },
    {
      name: "0.00",
    },
    {
      name: "0.00",
    },
    {
      name: "0.01",
    },
    {
      name: "1",
    },
  ];

  const priceServeicesSeetingHeaders = [
    {
      name: "IDENTIFIER",
    },
    {
      name: "TYPE",
    },
    {
      name: "ATTRIBUTE",
    },
    {
      name: "VALUE",
    },
    {
      name: "UPDATED BY",
    },
    {
      name: "UPDATED DATE",
    },
  ];
  const priceServeicesSeetingData = [
    {
      name: "USD Coin",
    },
    {
      name: "Kraken",
    },
    {
      name: "wss://wskraken.com",
    },
    {
      name: "Enabled",
    },
    {
      name: "Operations Team",
    },
    {
      name: "11/11/2021, 01:56",
    },
  ];
  return (
    <div className="fees">
      <HeaderAndFooterLayout>
        <CommonHeader name="Price Management" />
        <DeletingAlert
          deletingId={openDialog ? true : false}
          enable={!trading ? true : false}
          saveEnable={true}
          onHide={() => {
            handleDailog();
          }}
          msg={`Are you sure you want to ${trading ? "Enable" : "Disable"
            } trading?`}
          onDeleteClickHandler={() => {
            setOpenDialog(false)
            setTrading(!trading);
           
          }}
        />
        <div className="fees_table">
          <FeesTable
            heading={"Enable / Disable Trading"}
            headers={priceTableHeaders}
            data={priceTableData}
            handleTrading={handleTrading}
            handleDailog={handleDailog}
            openDialog={openDialog}
            trading={trading}
            enable={true}
          />
        </div>

        <div className="fees_table">
          <FeesTable
            heading={"Price Services Settings"}
            headers={priceServeicesSeetingHeaders}
            data={priceServeicesSeetingData}
          />
        </div>
      </HeaderAndFooterLayout>
    </div>
  );
};

export default PriceManagement;

const FeesTable = ({
  heading,
  data,
  headers,
  enable,
  handleTrading,
  trading,
  openDialog,
  handleDailog,
}) => {
  return (
    <div className="table_content">
      <div className="table_heading">
        {enable ? (
          <Stack direction="row" spacing={1} alignItems="center" className={`table_space ${isViewPermissionValid? "disabled": ""}`}>
            {heading}{" "}
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
              onChange={() => isViewPermissionValid? null: handleTrading()}
              checked={trading}
            />
            <Typography>{trading ? "Disable" : "Enable"}</Typography>
          </Stack>
        ) : (
          <div className="table_space">{heading} </div>
        )}
      </div>
      <div className="table">
        <div className="table_head">
          {headers.map((header, i) => {
            return (
              <div key={i} className="title">
                {header.name}
              </div>
            );
          })}
        </div>
        {data &&
          data.length > 0 &&
          data.map((ele, i) => {
            return <SingleRowData data={ele} key={i} enable={enable} />;
          })}
      </div>
    </div>
  );
};

const SingleRowData = ({ data, enable }) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  return (
    <div className="single_Row">
      {/* {enable ? ( */}
      <div className="table_data">
        <div className="title">{data.name}</div>
        {/* <span>
            <img src={""} width={20} height={20} alt={"icon"} />
          </span>{" "}
          <span className="p-1">{data.name}</span>
        </div>
      ) : (
        <div className="title">{data.name}</div>
      ) */}
      </div>
    </div>
  );
};
