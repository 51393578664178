import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { call, logoutAfterError } from '../../../../config/axios';
import { GET_ALL_PAYMENT_PROVIDERS, GET_CRYPTOS, GET_WALLETS, GET_WALLETS_SUMMARY } from '../../../../config/endpoints';
import localDb from '../../../../localDb';
import * as actions from '../../../Redux/actions';
import { setHeader } from '../../../Redux/actions/commonAction';
import Loading from '../../../common/Loading';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import DownloadCsv from '../CommonPages/DownloadCsv';
import TransactionFilters from '../Transactions/TransactionFilters';
import WalletTable from './WalletTable';
import constant from "../../global/constant";
import useDebounce from '../../global/useDebounce';


const searchicon = '/cryptonpay/crytoicons/searchicon1.png';
const filterIcon = "/cryptonpay/filter_icon.svg";
const downloadIcon = "/cryptonpay/download_icon.svg";

function Wallets(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal('email');
  const base_url = useSelector((state) => state.config.api_url);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [transactionFitlers, setTransactionFilters] = useState(false);
  const [csvData,setCsvData] = useState([]);

  const statusList = [
    'In Use',
    'Available',
    'Locked',
    'Archived',
    'Full'
  ];

  const [save, setSave] = useState(false);

  const handleTransactionFilters = () => {
    setTransactionFilters(false);
  };
  const [downloadCsv, setDownloadCsv] = useState(false);

  const handleCloseDownloadCsv = () => {
    setDownloadCsv(false);
  };

  const handleDownloadCsvIconClick=async()=>{
    await getWallets("all");
    setDownloadCsv(true);
  }

  const handleSave = () => {
    setSave(!save);
  };
  const resetFilters = () => {
    props.setSearchTextWallet('');
    props.setOrderByWallet('desc');
    props.setSortByWallet('status.updated');
    setPage(1);
    props.setDateSelectedWallet(false);
    setTotalCount(0);
    props.setSelectedStatusListWallet(['In Use', 'Available', 'Locked', 'Archived', 'Full']);
    props.setSelectedStatusIconList('All')
    props.setSelectedBlockchainList([]);
    props.setSelectedNetworkListWallet([
      'mainnet',
      'testnet',
    ]);
    props.setFromDateWallet([
      moment().subtract(6, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    ]);
    props.setToDateWallet([moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')]);
    props.setMinCryptoAmount("");
    props.setMaxCryptoAmount("");
    props.setSelectedCryptosWallet([]);
    props.setSelectedPaymentProviderWallet([]);
    props.setCryptoAmount("");
    setSave(!save);
  };
  const getCryptos = async () => {
    await call(
      {
        ...GET_CRYPTOS,
        url: base_url + GET_CRYPTOS.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          props.setCryptos(res.data.cryptos);

          let cryptos = res.data.cryptos.map((crypto) => {
            return crypto?._id;
          });
          props.setSelectedCryptosWallet(cryptos);
        }
      })
      .catch((err) => {
        console.log(err.message, 'err')
        logoutAfterError(err)
      });
  };

  const adminRole = localStorage.getItem("adminRole");
  useEffect(() => {
    if (props.cryptos.length === 0) {
      getCryptos();
    }
    if (adminRole === "SuperAdmin" && props.paymentProviderList.length === 0) {
      getProviders();
    }
  }, []);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  });

  const getWallets = async (page) => {
    let filterParams = {
      email: email,
      wallet_id: props.searchText.toString(),
      paymentProvider: localStorage.getItem("paymentProviderId"),
      orderBy: props.orderBy,
      sortBy: props.sortBy,
      blockchain: props.selectedBlockchainList.length === 0 ||
        props.selectedBlockchainList.length === constant.WALLETS_FILTER_BLOCKCHAIN_LIST.length
        ? 'All'
        : props.selectedBlockchainList,
      network: props.selectedNetworkList.length === 0 ||
        props.selectedNetworkList.length === constant.WALLETS_FILTER_NETWORK_LIST.length
        ? 'All'
        : props.selectedNetworkList,
      status:
        props.selectedStatusList.length === 0 ||
          props.selectedStatusList.length === constant.WALLETS_FILTER_STATUS_LIST.length
          ? 'All'
          : props.selectedStatusList,
      statusIcon: props.selectedStatusIconList,
      minCryptoAmount:
        props.minCryptoAmount !== "" ? parseFloat(props.minCryptoAmount)
          : null,
      maxCryptoAmount:
        props.maxCryptoAmount !== "" ? parseFloat(props.maxCryptoAmount)
          : null,
      CryptoCurrency:
        props.selectedCryptos.length === 0 ||
          props.selectedCryptos.length === props.cryptos.length
          ? 'All'
          : props.selectedCryptos,
      ...(adminRole === "SuperAdmin" && {
        providers:
          props.selectedPaymentProvider.length === 0 ||
            props.selectedPaymentProvider.length === props.paymentProviderList.length
            ? 'All'
            : props.selectedPaymentProvider
      }),
      cryptoAmount: props.cryptoAmount ? parseFloat(props.cryptoAmount) : null
    };

    if (props.dateSelected) {
      filterParams = {
        ...filterParams,
        fromDate: props.fromDate,
        toDate: props.toDate,
      };
    }

    if(page === "all"){
      filterParams.findAll = true;
    } else {
      filterParams.page = page || 1;
    }

    let payload = {
      email: email,
      filterParams: filterParams,
    };
    setLoading(true);
    await call(
      {
        ...GET_WALLETS,
        url: base_url + GET_WALLETS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          if(filterParams.findAll){
            setCsvData(res.data.wallets);
          } else if (isMobile) {
            setData(res.data.wallets);
            setTotalCount(res.data.totalCount);
          } else {
            if (page === 1) {
              setData(res.data.wallets);
              setTotalCount(res.data.totalCount);
            } else {
              let list = data.concat(res.data.wallets);
              setData(list);
              setTotalCount(res.data.totalCount);
            }
          }

          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };
  const debouncedSearchText = useDebounce(props.searchText);
  useEffect(() => {
    dispatch(setHeader('Merchant User Wallets'));
    getWallets(1)
  }, [debouncedSearchText, props.orderBy, props.sortBy, save]);
  useEffect(() => {
    getWalletsSummary()
  }, [save]);

  useEffect(() => {
    setPage(1);
  },[
    props.selectedStatusList,
    props.selectedStatusIconList,
    props.selectedBlockchainList,
    props.selectedNetworkList,
    props.minCryptoAmount,
    props.maxCryptoAmount,
    props.selectedCryptos,
    props.selectedPaymentProvider,
    props.cryptoAmount,
    props.dateSelected,
    props.fromDate,
    props.toDate,
    props.sortBy
  ])

  const [totalUSDAmount, setTotalUSDAmount] = useState(0);
  const [numberOfWallets, setNumberOfWallets] = useState([]);
  const [sumOfCryptoAmount, setSumOfCryptoAmount] = useState([]);
  const getWalletsSummary = async () => {
    let filterParams = {
      email: email,
      paymentProvider: localStorage.getItem("paymentProviderId"),
      wallet_id: props.searchText.toString(),
      blockchain: props.selectedBlockchainList.length === 0 ||
        props.selectedBlockchainList.length === constant.WALLETS_FILTER_BLOCKCHAIN_LIST.length
        ? 'All'
        : props.selectedBlockchainList,
      network: props.selectedNetworkList.length === 0 ||
        props.selectedNetworkList.length === constant.WALLETS_FILTER_NETWORK_LIST.length
        ? 'All'
        : props.selectedNetworkList,
      status:
        props.selectedStatusList.length === 0 ||
          props.selectedStatusList.length === constant.WALLETS_FILTER_STATUS_LIST.length
          ? 'All'
          : props.selectedStatusList,
      statusIcon: props.selectedStatusIconList,
      minCryptoAmount:
        props.minCryptoAmount !== "" ? parseFloat(props.minCryptoAmount)
          : null,
      maxCryptoAmount:
        props.maxCryptoAmount !== "" ? parseFloat(props.maxCryptoAmount)
          : null,
      CryptoCurrency:
        props.selectedCryptos.length === 0 ||
          props.selectedCryptos.length === props.cryptos.length
          ? 'All'
          : props.selectedCryptos,
      ...(adminRole === "SuperAdmin" && {
        providers:
          props.selectedPaymentProvider.length === 0 ||
            props.selectedPaymentProvider.length === props.paymentProviderList.length
            ? 'All'
            : props.selectedPaymentProvider
      }),
      cryptoAmount: props.cryptoAmount ? parseFloat(props.cryptoAmount) : null
    };

    if (props.dateSelected) {
      filterParams = {
        ...filterParams,
        fromDate: props.fromDate,
        toDate: props.toDate,
      };
    }
    let payload = {
      email: email,
      filterParams: filterParams,
    };
    setLoading(true);
    await call(
      {
        ...GET_WALLETS_SUMMARY,
        url: base_url + GET_WALLETS_SUMMARY.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalUSDAmount(res.data.totalUSDAmount.length > 0 ? res.data.totalUSDAmount[0].totalUSD : 0);
          setNumberOfWallets(res.data.numberOfWallets);
          setSumOfCryptoAmount(res.data.sumOfCryptoAmount)
        }
        setLoading(false);
      })
      .catch((err) => { 
        setLoading(false); 
        console.log(err.message, 'err') 
        logoutAfterError(err)
      });
  };

  const getProviders = async () => {
    let payload = {
      email,
      firstName: props.searchText,
    };
    setLoading(true);
    await call(
      {
        ...GET_ALL_PAYMENT_PROVIDERS,
        url: base_url + GET_ALL_PAYMENT_PROVIDERS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          props.setPaymentProviderList(res.data.providerList);
          let providerId = [];
          if (res.data.providerList.length > 0) {
            providerId = res.data.providerList.map(e => e._id);
          }
          props.setSelectedPaymentProviderWallet(providerId);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const name = 'Wallets';

  const handleCryptoAmountChange = (value)=>{
    value = value.replace(/[^0-9.]/g, "")
    props.setCryptoAmount(value);
  }

  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
				<div className="">
					<CommonHeader name={name} />
					
					<div className="transactionFilter__allMerchants">
						<div className="transactionFilter">
							<input
								type="text"
								className="transactionFilter__input"
								value={props.searchText}
								name="searchText"
								data-e2e="search-textbox"
								onChange={(e) => {
									props.setSearchTextWallet(e.target.value);
									setPage(1);
								}}
								placeholder="Search Wallet Address..."
							/>
							<img
								src={`${image_base_url}${searchicon}`}
								alt="searchicon"
								className="transactionFilter__icon"
								data-e2e="searchIcon"
								onClick={() => {
									getWallets();
								}}
							/>
						</div>
						<button type="button" className="transactionFilter__btn" onClick={() => {setTransactionFilters(true);}}>
							<img data-e2e="filterIcon" src={`${image_base_url}${filterIcon}`} alt="filterIcon" />
						</button>

						<button data-e2e="filterBtn" type="button" className="transactionFilter__btn" onClick={handleDownloadCsvIconClick}>
							<img data-e2e="downloadIcon" src={`${image_base_url}${downloadIcon}`} alt="downloadIcon" />
						</button>

						
					</div>
									
					<Dialog
						open={transactionFitlers}
						onClose={handleTransactionFilters}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<TransactionFilters
							filter_name="wallet"
              CentralisedWallets
							handleTransactionFilters={handleTransactionFilters}
							statusList={constant.WALLETS_FILTER_STATUS_LIST}
							selectedStatusList={props.selectedStatusList}
              setSelectedStatusList={props.setSelectedStatusListWallet}
              statusIconList={constant.WALLETS_FILTER_STATUS_ICONS_LIST}
							selectedStatusIconList={props.selectedStatusIconList}
							setSelectedStatusIconList={props.setSelectedStatusIconList}
							blockchainList={constant.WALLETS_FILTER_BLOCKCHAIN_LIST}
							selectedBlockchainList={props.selectedBlockchainList}
							SetSelectedBlockchainList={props.setSelectedBlockchainList}
							networkList={constant.WALLETS_FILTER_NETWORK_LIST}
							statusGroupList={[]}
							selectedStatusGroupList={[]}
							selectedNetworkList={props.selectedNetworkList}
							setSelectedNetworkList={props.setSelectedNetworkListWallet}
							fromDate={moment(props.fromDate)}
							toDate={moment(props.toDate)}
							setFromDate={props.setFromDateWallet}
							setToDate={props.setToDateWallet}
							handleSave={handleSave}
							setDateSelected={props.setDateSelectedWallet}
							dateSelected={props.dateSelected}
							resetFilters={resetFilters}
							cryptos={props.cryptos}
							selectedCryptos={props.selectedCryptos}
							setSelectedCryptos={props.setSelectedCryptosWallet}
							paymentProviderList={props.paymentProviderList}
							setPaymentProviderList={props.setPaymentProviderList}
							selectedPaymentProvider={props.selectedPaymentProvider}
							setSelectedPaymentProvider={props.setSelectedPaymentProviderWallet}
							adminRole={adminRole}
							minCryptoAmount={props.minCryptoAmount}
							setMinCryptoAmount={(value) => {
								value = (value.replace(/\s/g, '')).replace(/[^0-9.]/g, "")
								props.setRangeError("")
								props.setMinCryptoAmount(value)
								if (props.maxCryptoAmount !== "" && value !== "" && parseFloat(value) > parseFloat(props.maxCryptoAmount)) {
									props.setRangeError("Min crypto Amount Must be less than Max Crypto Amount!")
								}
							}}
							maxCryptoAmount={props.maxCryptoAmount}
							setMaxCryptoAmount={(value) => {
								value = (value.replace(/\s/g, '')).replace(/[^0-9.]/g, "")
								props.setRangeError("")
								props.setMaxCryptoAmount(value)
								if (props.minCryptoAmount !== "" && value !== "" && parseFloat(value) < parseFloat(props.minCryptoAmount)) {
									props.setRangeError("Min crypto Amount Must be greater than min Crypto Amount!")
								}
							}}
							rangeError={props.rangeError}
              handleCryptoAmountChange={handleCryptoAmountChange}
              cryptoAmount={props.cryptoAmount}
						/>
					</Dialog>
							
					<Dialog
						open={downloadCsv}
						onClose={handleCloseDownloadCsv}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DownloadCsv
							handleCloseDownloadCsv={handleCloseDownloadCsv}
							data={csvData}
							resetFilters={resetFilters}
							name="wallets"
							data-e2e="wallets"
						/>
					</Dialog>
					
					<div className="transactionSummary">
						<p className="transactionSummary__title">Summary:</p>
						<p className="transactionSummary__text mb"> 
							Number of wallets:&nbsp;
							{
								numberOfWallets.length > 0 && numberOfWallets.map((ele, index) => (
									ele._id !== null &&
									<span key={index}>{ele._id + ": " + ele.count + "| "}</span>
								))
							}
						</p>
						<p className="transactionSummary__text mb">
							Net USD Equivalent:&nbsp;<span>{parseFloat(totalUSDAmount.toFixed(2))}</span>
						</p>
						<p className="transactionSummary__text">
							Sum by Crypto Amount:&nbsp;
							{
								sumOfCryptoAmount.length > 0 && sumOfCryptoAmount.map((ele, index) => (
									ele.cryptoId !== null &&
									<span key={index}>{ele.cryptoId + ": " + parseFloat(ele.totalCryptoAmount).toFixed(4) + "| "}</span>
								))
							}
						</p>
					</div>
						
					<div>
						<WalletTable
							table_name="Induvisual_transactions"
							data={data}
							sortBy={props.sortBy}
							orderBy={props.orderBy}
							getWallets={() => {
								getWallets(1)
							}}
							changeOrder={(sort) => {
								if (props.sortBy !== sort) {
									props.setOrderByWallet('desc');
									props.setSortByWallet(sort);
								} else {
									props.setOrderByWallet(props.orderBy === 'desc' ? 'asc' : 'desc');
								}
							}}
							page={page}
							handleShowMore={() => {
								let newPage = page + 1;
								setPage(newPage);
								getWallets(newPage);
							}}
							handleShowLess={() => {
								let newPage = page - 1;
								setPage(newPage);
								getWallets(newPage);
							}}
							totalCount={totalCount}
							showMoreButton={totalCount && data && data.length < totalCount}
						/>
					</div>

				</div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    cryptos: state.transaction.cryptos,
    paymentProviderList: state.transaction.paymentProviderList,
    searchText: state.wallet.searchText,
    minCryptoAmount: state.wallet.minCryptoAmount,
    maxCryptoAmount: state.wallet.maxCryptoAmount,
    rangeError: state.wallet.rangeError,
    orderBy: state.wallet.orderBy,
    sortBy: state.wallet.sortBy,
    fromDate: state.wallet.fromDate,
    toDate: state.wallet.toDate,
    dateSelected: state.wallet.dateSelected,
    selectedStatusList: state.wallet.selectedStatusList,
    selectedStatusIconList: state.wallet.selectedStatusIconList,
    selectedBlockchainList: state.wallet.selectedBlockchainList,
    selectedNetworkList: state.wallet.selectedNetworkList,
    selectedCryptos: state.wallet.selectedCryptos,
    selectedPaymentProvider: state.wallet.selectedPaymentProvider,
    cryptoAmount: state.wallet.cryptoAmount
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchTextWallet: (value) => dispatch(actions.setSearchTextWallet(value)),
    setMinCryptoAmount: (value) => dispatch(actions.setMinCryptoAmount(value)),
    setMaxCryptoAmount: (value) => dispatch(actions.setMaxCryptoAmount(value)),
    setRangeError: (value) => dispatch(actions.setRangeError(value)),
    setOrderByWallet: (value) => dispatch(actions.setOrderByWallet(value)),
    setSortByWallet: (value) => dispatch(actions.setSortByWallet(value)),
    setFromDateWallet: (value) => dispatch(actions.setFromDateWallet(value)),
    setToDateWallet: (value) => dispatch(actions.setToDateWallet(value)),
    setDateSelectedWallet: (value) => dispatch(actions.setDateSelectedWallet(value)),
    setSelectedStatusListWallet: (value) => dispatch(actions.setSelectedStatusListWallet(value)),
    setSelectedStatusIconList: (value) => dispatch(actions.setSelectedStatusIconList(value)),
    setSelectedBlockchainList: (value) => dispatch(actions.setSelectedBlockchainList(value)),
    setSelectedNetworkListWallet: (value) => dispatch(actions.setSelectedNetworkListWallet(value)),
    setCryptos: (value) => dispatch(actions.setCryptos(value)),
    setSelectedCryptosWallet: (value) => dispatch(actions.setSelectedCryptosWallet(value)),
    setPaymentProviderList: (value) => dispatch(actions.setPaymentProviderList(value)),
    setSelectedPaymentProviderWallet: (value) => dispatch(actions.setSelectedPaymentProviderWallet(value)),
    setCryptoAmount: (value)=>dispatch(actions.setCryptoAmount(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wallets);
