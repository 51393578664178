import { createGlobalStyle } from 'styled-components';

// Define your global styles with the retrieved color value
const GlobalStyle = createGlobalStyle`
  .btn-primary, .theme-table-action , .selected-switcharoo, .login_button_get_started  button{
    background-color: var(--headerButtonLightBgColour) !important;
    transition: background-color 0.3s ease; 
  }
  .theme-btn {
    border-color: var(--headerButtonLightBgColour) !important;
    color:var(--headerButtonLightBgColour) !important;
  }

  .theme-table-action:hover {
    background-color: var(--headerLightTextColour) !important;
  }
  
  .Show_more_wrap .Show_more{
    border-color: var(--headerButtonLightBgColour) !important;
    color:var(--headerButtonLightBgColour) !important;
  }
  .theme-btn p{
    color:var(--headerButtonLightBgColour) !important;
  }
`;

export default GlobalStyle;
