import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { call, logoutAfterError } from "../../../../config/axios";
import {
  VIEW_SINGLE_WALLET,
} from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import { setHeader } from '../../../Redux/actions/commonAction';
import Loading from '../../../common/Loading';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import Deposit from './Deposit';
import MerchantUserTransactionsTable from "./MerchantUserTransactionsTable";
import BalanceTable from "./BalanceTable";

function WalletView() {
  const dispatch = useDispatch();

  const [id, setId] = useState('');
  // const [fullId, setFullId] = useState('');
  const [depositFlow, setDepositFlow] = useState(false);
  const [data, setData] = useState([])
  const [UseWalletId, setUserWalletId] = useState(useParams().id);
  const [walletId, setWalletId] = useState(null);
  const email = localDb.getVal("email");

  const view_transaction_url = useSelector((state) => state.config.api_url);

  const [loading, setLoading] = useState(false);
  const [walletDetailBtn, setWalletDetailBtn] = useState("Wallets");



  const handleId = (id) => {
    setId(id);
  };

  // const handleFullId = (id) => {
  //   setFullId(id)
  // }

  const handleFlag = () => {
    window.location.reload()
  }

  const handleClickOpenSettings = () => {
    setDepositFlow(true);
  };

  const handleCloseSettings = () => {
    setDepositFlow(false);
    window.location.reload()
  };

  const handleUpdateSettings = () => {
    handleClickOpenSettings();
  };

  const sub_name_last = walletId
    ? "Wallet Address: " + walletId.substring(0, 8) + '.....' + walletId.substring(walletId.length - 6, walletId.length)
    : 'N/A';
  const walletCpyAdd = id ? id : ""

  useEffect(() => {
    dispatch(setHeader('Merchant User Wallets'));
    viewSingleWalletDetails();
    
  }, []);

  const viewSingleWalletDetails = async () => {
    setLoading(true)
    await call(
      {
        ...VIEW_SINGLE_WALLET,
        url: view_transaction_url + VIEW_SINGLE_WALLET.url,
      },
      { email: email, walletId: UseWalletId }
    )
      .then((res) => {

        if (res.status === 200) {
          setData(res.data.wallets ? res.data.wallets : [])
          setWalletId(res.data.wallets.address.key);
          setLoading(false)

        }
      })
      .catch((err) => {
        console.log(err.message, "err");
        setLoading(false)
        logoutAfterError(err)
      });
  };

  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>

        {
          walletId ?
            <React.Fragment>
							<div className="">
              	<CommonHeader paymentProvider={data.paymentProvider?.firstName || "N/A" + " "+data.paymentProvider?.lastName || "N/A"} data={data} copyButton={true} view="wallets" backButton={true} name={sub_name_last} id={id} handleFlag={handleFlag} handleUpdateSettings={handleUpdateSettings} fullId={walletId} walletCpyAdd={walletCpyAdd} transMode={'true'} />
							</div>
              <Dialog
                open={depositFlow}
                onClose={handleCloseSettings}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Deposit handleClose={handleCloseSettings} id={id} />
                {/* <UpdateMerchant handleCloseSettings={handleCloseSettings} id={id} /> */}
              </Dialog>
              <BalanceTable
                data={data}
                setData={setData}
                setWalletDetailBtn={setWalletDetailBtn}
                walletDetailBtn={walletDetailBtn}
              />
              <MerchantUserTransactionsTable
                balances={data.balanceHistory}
                setWalletDetailBtn={setWalletDetailBtn}
                walletDetailBtn={walletDetailBtn}
              />
            </React.Fragment> :
            <React.Fragment>
							<div className="">
								<CommonHeader view="wallets" backButton={true} name={UseWalletId} id={id} handleFlag={handleFlag} handleUpdateSettings={handleUpdateSettings} fullId={walletId} walletCpyAdd={walletCpyAdd} transMode={'true'} />
								{!walletId && !loading && <div className="d-flex justify-content-center h2 mt-5">{`Wallet not found.`}</div>}
							</div>
            </React.Fragment>
        }
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
}

export default WalletView;
