import React, { useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../../../common/Loading";
const closeImage = "/cryptonpay/crytoicons/close.png";
import { call } from "../../../../../../config/axios";
import { ADD_BLOCKED_EMAIL_DOMAIN} from "../../../../../../config/endpoints";
import localDb from "../../../../../../localDb";

function AddBlockedDomainEmail({
  onClose,
  onSuccessful
}) {
  const baseURL = useSelector((state) => state.config.api_url);
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const [loading, setLoading] = useState(false);
  const [blockedEmail, setBlockedEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    "Please enter email that need to be blocked",
  );
  const validation = (email) => {
    if (emailList && emailList?.includes(email)) {
      setError(true);
      setErrorMsg("This email has been already blocked");
      return true;
    }
  };
  const handleEmailChange = (e) => {
    setBlockedEmail(e.target.value);
    setError(false);
    setErrorMsg("");
  };
  const handleAddNewBlockedEmail = async () => {
    try {
      if (!blockedEmail) {
        setError(true);
        return;
      } else {
        setLoading(true);
        const data = {
          email: localDb?.getVal("email"),
          newEmail: blockedEmail,
          paymentProviderId: localDb.getVal("adminRole") === "SuperAdmin" ? localDb?.getVal("userId") : localDb?.getVal('paymentProviderId'),
        };
        const res = await call(
          {
            ...ADD_BLOCKED_EMAIL_DOMAIN,
            url: baseURL + ADD_BLOCKED_EMAIL_DOMAIN.url,
          },
          data,
        );
        if (res?.status === 200) {
          setLoading(false);
          onSuccessful();
          setBlockedEmail("");
          setError(false);
          onClose();
        }
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      setErrorMsg(error?.response?.data?.error);
      console.error("Error in catch:", error);
    }
  };

  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <div className="font-large font-weight-bold text-lg">Add Blocked Email Domain</div>
        <div
          className="filter_close_button"
          onClick={() => {
            onClose();
          }}
        >
          <img src={`${imageBaseUrl}${closeImage}`} alt="closeImage" />
        </div>
      </div>
      <div className="upload__merchantId">
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Blocked Email Domain: </div>
          <input
            type="text"
            placeholder="Enter Blocked Email"
            value={blockedEmail}
            onChange={(e) => handleEmailChange(e)}
          />
        </div>
      </div>
      {error && (
        <div className="my-2 w-full text-left text-red-600">{errorMsg}</div>
      )}
      <div className="d-flex justify-content-center pt-4">
        <button
          type="button"
          onClick={() => {
            handleAddNewBlockedEmail();
          }}
          className="btn btn-primary btn-md rounded-pill add-btn w-100"
        >
          {"Add"}
        </button>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default AddBlockedDomainEmail;
