import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { call } from '../../../../config/axios';
import { ADMIN_USER_VERIFY_2FA_TOKEN } from '../../../../config/endpoints';
import { getHeaderButtonStyle } from "./common"
import localDb from '../../../../localDb';

const VerificationModal = (props) => {
    const{userData} = props
    const base_url = useSelector((state) => state.config.api_url);
    const [error, setError] = useState('');
    const [verificationCode2fa, setVerificationCode2fa] = useState('');

    const [colorMode, setColorMode] = useState(null);
    const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
    const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
    const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
    const [headerLightTextColour, setheaderLightTextColour] = useState(null);

    const checkValueInSS = (value) => sessionStorage.getItem(value) !== null && sessionStorage.getItem(value) !== '' && sessionStorage.getItem(value) !== 'null';

    useEffect(() => {
        ['colorMode', 'headerButtonDarkBgColour', 'headerButtonLightBgColour', 'headerDarkTextColour', 'headerLightTextColour'].forEach((value) => {
            if (checkValueInSS(value)) {
                switch (value) {
                    case 'colorMode':
                        setColorMode(sessionStorage.getItem(value));
                        break;
                    case 'headerButtonDarkBgColour':
                        setheaderButtonDarkBgColour(sessionStorage.getItem(value));
                        break;
                    case 'headerButtonLightBgColour':
                        setheaderButtonLightBgColour(sessionStorage.getItem(value));
                        break;
                    case 'headerDarkTextColour':
                        setheaderDarkTextColour(sessionStorage.getItem(value));
                        break;
                    case 'headerLightTextColour':
                        setheaderLightTextColour(sessionStorage.getItem(value));
                        break;
                    default:
                        break;
                }
            }
        });
    }, []);

    const onVerifyCode = async () => {
        if (verificationCode2fa.length === 6) {
            props.setLoading(true);
            try {
                const payload = {
                    email: localStorage.getItem("email"),
                    authenticator: false,
                    is2faActivated: props.is2faActivated || false,
                    token: userData.token,
                    user2faToken: verificationCode2fa,
                };
                const data = await call({ ...ADMIN_USER_VERIFY_2FA_TOKEN, url: base_url + ADMIN_USER_VERIFY_2FA_TOKEN.url }, payload);
                if (data && data.data && data.data.success === true) {
                    if (["PaymentProviderAdmin", "PaymentProviderUser"].includes(userData.role)) {
                        localDb.setVal("paymentProviderId", userData.paymentProviderId);
                        localDb.setVal("font", userData.font);
                    }
                    if (userData.domains && userData.domains.length > 0) {
                        const firstDomain = userData.domains[0];
                        localDb.setVal("paymentProviderHeaderColor", firstDomain.headerButtonLightBgColour);
                        localDb.setVal("paymentProvideDomainId", firstDomain._id);
                    }
                    localDb.setVal('token', userData.token);
                    localStorage.setItem('authenticationDone', 'true');
                    if (props.is2faActivated && props.is2faActivated) {
                        localStorage.setItem('2FAStatus', false);
                        props.setIs2faActivated();
                    } else {
                        props.onVerificationSucess(userData, userData && userData.email);
                    }
                    const redirectUrl = userData.role === 'SuperAdmin' ? '/all-payment-providers' : '/merchants';
                    window.location.href = redirectUrl;
                    props.onClose();
                } else {
                    props.setLoading(false);
                    setError('Invalid verification code!');
                }
            } catch (err) {
                localStorage.clear();
                props.setLoading(false);
                console.error(err);
            }
        } else {
            setError('Invalid token! 2FA token must have 6 digits only');
        }
    };
    const onInputKeyDown = (e) => {
        // Handle Enter key press
        if (e.key === 'Enter') {
            e.preventDefault();
            // Verify code only if it's valid (length is 6)
            if (verificationCode2fa.length === 6) {
                onVerifyCode();
            }
        }
    };
    const disabledVerify = verificationCode2fa.length !== 6;
    const headerButtonStyle = getHeaderButtonStyle(colorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour);

    return (
        <Dialog open={props.open} className="theme-modal">
            <div className="theme-modal-body">
                <div className="theme-modal-close">
                    <ClearIcon onClick={props.onClose} className="icon-close" />
                </div>
                <form>
                    <p className="theme-modal-title">2FA Verification</p>
                    <p className="theme-modal-text mb t-center">Open Google Authenticator app and enter the secret key to continue</p>
                    <div className="theme-modal-field">
                        <p className="theme-modal-field-label">Verification Code</p>
                        <input
                            value={verificationCode2fa}
                            type="text"
                            className="theme-modal-field-input"
                            placeholder=""
                            onKeyDown={onInputKeyDown}
                            onChange={(e) => {
                                setError('');
                                setVerificationCode2fa(e.target.value);
                            }}
                        />
                        {error !== '' && <div className="errorMessage">{error}</div>}
                        <div className="theme-btn-block full-width theme-modal-submit">
                            <button
                                type="button"
                                className={`theme-btn ${disabledVerify ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (!disabledVerify) {
                                        onVerifyCode();
                                    }
                                }}
                                style={headerButtonStyle}
                            >
                                <p>Verify</p>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    );
};

export default VerificationModal;
