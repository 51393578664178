import React, { useState, useEffect } from "react";
import {
  UPDATE_LEAST_COST_ROUTING_BY_ACQUIRER,
  GET_LEAST_COST_ROUTING_BY_ACQUIRER,
} from "../../../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../../../config/axios";
import { useSelector } from "react-redux";
import Loading from "../../../../../common/Loading";
import TabButton from "../../../../../common/TabButton";
import localDb from "../../../../../../localDb";
import CustomTextFields from "../../../../../common/CustomTextFields";
import { Grid } from "@mui/material";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";

const LeastCostRoutingTab = ({ acquireService }) => {
  const [tabs, setTabs] = useState(0);
  const [loading, setLoading] = useState(false);
  const baseURL = useSelector((state) => state.config.api_url);
  const adminRole = localStorage.getItem("adminRole");
  const emailFromLocal = localDb.getVal("email");
  const fieldNames = {
    fixedCost: "Fixed Cost",
    percentCost: "Percent Cost",
  };
  const initialValues = {
    fixedCost: 0,
    percentCost: 0,
  };
  const initialEditValue = {
    fixedCost: false,
    percentCost: false,
  };
  const [values, setValues] = useState(initialValues);
  const [isEditable, setIsEditable] = useState(initialEditValue);

  useEffect(() => {
    getMaxtrafficByAcquirer();
    setValues(initialValues);
  }, [tabs]);

  const handleChange = (field, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    const payload = {
      email: emailFromLocal,
      adminRole: adminRole,
      acquirerName:
        acquireService &&
        acquireService.routingService &&
        acquireService.routingService.length > 0 &&
        acquireService.routingService[tabs] &&
        acquireService.routingService[tabs].acquirerName,
      routingId: acquireService && acquireService.uniqueId,
      createdDate: new Date(),
      lastUpdated: new Date(),
      config: values,
    };
    await call(
      {
        ...UPDATE_LEAST_COST_ROUTING_BY_ACQUIRER,
        url: baseURL + UPDATE_LEAST_COST_ROUTING_BY_ACQUIRER.url,
      },
      payload,
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setIsEditable(initialEditValue);
        }
      })
      .catch((err) => {
        console.log(err.message, "err");
        setLoading(false);
        logoutAfterError(err);
      });
  };

  const getMaxtrafficByAcquirer = async () => {
    try {
      setLoading(true);
      const payload = {
        email: emailFromLocal,
        adminRole: adminRole,
        acquirerName:
          acquireService &&
          acquireService.routingService &&
          acquireService.routingService.length > 0 &&
          acquireService.routingService[tabs] &&
          acquireService.routingService[tabs].acquirerName,
        routingId: acquireService && acquireService.uniqueId,
      };
      const response = await call(
        {
          ...GET_LEAST_COST_ROUTING_BY_ACQUIRER,
          url: baseURL + GET_LEAST_COST_ROUTING_BY_ACQUIRER.url,
        },
        payload,
      );

      if (response.status === 200) {
        setValues(response.data.config);
      } else {
        setValues(initialValues);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const toggleEditable = (field) => {
    setIsEditable((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleTabSwitch = (index) => {
    setTabs(index);
    setIsEditable(initialEditValue);
  };

  return (
    <div className="TabContainer">
      {acquireService ? (
        <div>
          <div className="acquireServiceScroll">
            {acquireService.routingService.map((tab, index) => (
              <TabButton
                key={index}
                activeTab={tabs}
                handleTabSwitch={handleTabSwitch}
                tab={tab}
                index={index}
              />
            ))}
          </div>
          <div className="ml-3 flex pt-10">
            <Grid container spacing={2}>
              {acquireService.routingService.map((items, index) => {
                return (
                  <div key={index}>
                    {tabs === index && (
                      <CustomTextFields
                        initialValues={initialValues}
                        values={values}
                        isEditable={isEditable}
                        handleChange={handleChange}
                        toggleEditable={toggleEditable}
                        fieldNames={fieldNames}
                      />
                    )}
                  </div>
                );
              })}
            </Grid>
          </div>
          <div className="mt-4">
            <button
              className={`${isViewPermissionValid? "disabled": ""} rounded bg-blue-500 px-4 py-2 text-base font-bold text-white hover:bg-blue-700`}
              onClick={isViewPermissionValid? null: handleSave}
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full text-center">No Data</div>
      )}
      {loading && <Loading />}
    </div>
  );
};

export default LeastCostRoutingTab;
