import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
import { call } from "../../../../config/axios";
import Select from "react-select";
import {
  GET_CRYPTOS,
  CREATE_PAYMENT_PROVIDER_SWEEP_CONFIG,
  GET_PAYMENT_PROVIDER_SWEEP_CONFIG,
  UPDATE_PAYMENT_PROVIDER_SWEEP_CONFIG,
  GET_ALL_PAYMENT_PROVIDERS
} from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";
import EditIcon from '@mui/icons-material/Edit';
import { isViewPermissionValid } from "../../../common/CommonUtils";
const closeImage = "/cryptonpay/crytoicons/close.png";

const PaymentProviderSweepConfig = () => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");
  const [open, setOpen] = useState(false);
  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [data, setData] = useState([]);
  const [cryptos, setCryptos] = useState([])
  const [editData, setEditData] = useState({});
  const [paymentProviderList, setPaymentProviderList] = useState([])
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState(null)
  const [inputField, setInputField] = useState({
    minFullWallets: editData && editData.SweepAmountThresholds && editData.SweepAmountThresholds.minFullWallets ? editData.SweepAmountThresholds.minFullWallets : 0,
    minAmountUsd: editData && editData.SweepAmountThresholds && editData.SweepAmountThresholds.minAmountUsd ? editData.SweepAmountThresholds.minAmountUsd : 0,
    maxAmountUsd: editData && editData.SweepAmountThresholds && editData.SweepAmountThresholds.maxAmountUsd ? editData.SweepAmountThresholds.maxAmountUsd : 0,
    takeAllFunds: editData && editData.SweepAmountThresholds && editData.SweepAmountThresholds.takeAllFunds ? editData.SweepAmountThresholds.takeAllFunds : false,
    archive: editData && editData.SweepAmountThresholds && editData.SweepAmountThresholds.archive ? editData.archive : false,
    maxAmount: editData && editData.FeeThresholds && editData.FeeThresholds.maxAmount ? editData.FeeThresholds.maxAmount : 0,
    maxPercent: editData && editData.FeeThresholds && editData.FeeThresholds.maxPercent ? editData.FeeThresholds.maxPercent : 0,
    sweepFlag: editData && editData.sweepFlag ? editData.sweepFlag : false,
    configFlag: editData && editData.configFlag ? editData.configFlag : false,
  });
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([])
  const [render, setRender] = useState(false);

  const [err, setErr] = useState("")
  const onClose = () => {
    setEditData({})
    setSelectedCrypto(null)
    setSelectedPaymentProvider(null)
    setOpen(false);
  };

  const getPaymentSpreadRates = async () => {
    setLoading(true);
    await call(
      {
        ...GET_CRYPTOS,
        url: base_url + GET_CRYPTOS.url,
      },
      { email: email }
    )
      .then((res) => {
        setLoading(false);
        setCryptos(
          res &&
          res.data && res.data.cryptos
        );
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getPaymentProviderSweep = async () => {
    setLoading(true);
    await call(
      {
        ...GET_PAYMENT_PROVIDER_SWEEP_CONFIG,
        url: base_url + GET_PAYMENT_PROVIDER_SWEEP_CONFIG.url,
      },
      { 
        email: email,
        paymentId: localStorage.getItem("adminRole") === "PaymentProviderAdmin" ? localStorage.getItem("paymentProviderId") : null,
      }
    )
      .then((res) => {
        setLoading(false);
        setData(
          res &&
          res.data
        );
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getPaymentProviderList = async () => {
    setLoading(true);
    await call(
      {
        ...GET_ALL_PAYMENT_PROVIDERS,
        url: base_url + GET_ALL_PAYMENT_PROVIDERS.url,
      },
      { email: email }
    )
      .then((res) => {
        setLoading(false);
        let paymentOption = [];
        res.data.providerList.map((provider) => {
          paymentOption.push({
            label: provider.firstName + " " + provider.lastName,
            value: provider._id
          })
          return provider
        })
        setPaymentProviderList(paymentOption);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  useEffect(() => {
    const newOptions = cryptos.map((obj) => ({
      label: obj.symbol,
      value: obj._id
    }));

    setOptions(newOptions);
  }, [cryptos])
  const handleSweepConfig = async () => {
    setLoading(true);
    let payload = {
      paymentId: localStorage.getItem('adminRole') === "PaymentProviderAdmin" ? localStorage.getItem('paymentProviderId') : selectedPaymentProvider.value,
      cryptoId: selectedCrypto.value,
      email: email,
      ...inputField,
    };
    if (editData && Object.keys(editData).length > 0 && editData._id) {
      await call(
        {
          ...UPDATE_PAYMENT_PROVIDER_SWEEP_CONFIG,
          url: base_url + UPDATE_PAYMENT_PROVIDER_SWEEP_CONFIG.url,
        },
        { ...payload, sweepId: editData._id }
      )
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            onClose();
            setRender(!render)
          }
        })
        .catch((err) => {
          setEditData("")
          setLoading(false);
          console.log(err.message, "err");
        });
    } else {
      await call(
        {
          ...CREATE_PAYMENT_PROVIDER_SWEEP_CONFIG,
          url: base_url + CREATE_PAYMENT_PROVIDER_SWEEP_CONFIG.url,
        },
        payload
      )
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            setRender(!render)
            onClose();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message, "err");
        });
    }

  };

  useEffect(() => {
    getPaymentSpreadRates();
    getPaymentProviderSweep();
    getPaymentProviderList()
  }, [render]);

  return (
    <div>
    {data && data.length > 0 ?  <div>
      <div className="payment-provider-add-btn">
        <button
          className={`theme-table-action add-country-btn ${isViewPermissionValid? "disabled": ""}`}
          onClick={isViewPermissionValid? null: () => {
            setEditData("")
            setOpen(true)
          }}
        >
          Add Config
        </button>
      </div>
      <div
        className="TransactionTableMain transaction_table_link table-full-width table_padding transaction-h"
        style={{ flex: 1, marginTop: "10.5px" }}
      >
        {data && data.length > 0 && <div className="theme-table">
          <Table responsive style={{ maxHeight: "400px" }}>
            <tbody>
              <tr>
                <th className="theme-table-hover">
                  <p className="theme-table-text">Payment Provider</p>
                </th>
                <th className="theme-table-hover">
                  <p className="theme-table-text">Crypto</p>
                </th>
                <th className="theme-table-hover">
                  <p className="theme-table-text">Min Full Wallets Thresholds</p>
                </th>
                <th className="theme-table-hover">
                  <p className="theme-table-text">Min USD Thresholds</p>
                </th>
                <th className="theme-table-hover">
                  <p className="theme-table-text">Max USD Thresholds</p>
                </th>
                <th className="theme-table-hover">
                  <p className="theme-table-text">Take All Funds</p>
                </th>
                <th className="theme-table-hover">
                  <p className="theme-table-text">Archive</p>
                </th>
                <th className="theme-table-hover">
                  <p className="theme-table-text">Max Fee Thresholds</p>
                </th>
                <th className="theme-table-hover">
                  <p className="theme-table-text">Max Percent Thresholds</p>
                </th>
                <th className="theme-table-hover">
                  <p className="theme-table-text">Sweep Flag</p>
                </th>
                <th className="theme-table-hover">
                  <p className="theme-table-text">Config Flag</p>
                </th>
                <th className="theme-table-hover">
                </th>
              </tr>
              {data &&
                data.map((config, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr className="theme-table-row theme-table-hover">
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {config.paymentProviderId && config.paymentProviderId.firstName && config.paymentProviderId.firstName } {+ " " + config.paymentProviderId && config.paymentProviderId.lastName && config.paymentProviderId.lastName}
                            </p>
                          </div>
                        </td>
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {config.cryptoId && config.cryptoId.symbol}
                            </p>
                          </div>
                        </td>
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {config.SweepAmountThresholds && config.SweepAmountThresholds.minFullWallets && config.SweepAmountThresholds.minFullWallets}
                            </p>
                          </div>
                        </td>
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {config.SweepAmountThresholds && config.SweepAmountThresholds.minAmountUsd && config.SweepAmountThresholds.minAmountUsd}
                            </p>
                          </div>
                        </td>
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {config.SweepAmountThresholds && config.SweepAmountThresholds.maxAmountUsd && config.SweepAmountThresholds.maxAmountUsd}
                            </p>
                          </div>
                        </td>
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {config.SweepAmountThresholds && config.SweepAmountThresholds.takeAllFunds ? "true" : "false"}
                            </p>
                          </div>
                        </td>
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {config.SweepAmountThresholds && config.SweepAmountThresholds.archive ? "true" : "false"}
                            </p>
                          </div>
                        </td>
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {config.FeeThresholds && config.FeeThresholds.maxAmount && config.FeeThresholds.maxAmount}
                            </p>
                          </div>
                        </td>
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {config.FeeThresholds && config.FeeThresholds.maxPercent && config.FeeThresholds.maxPercent}
                            </p>
                          </div>
                        </td>
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {config.sweepFlag && config.sweepFlag ? "true" : "false"}
                            </p>
                          </div>
                        </td>
                        <td className="theme-table-row">
                          <div className="theme-table-row-td">
                            <p className="theme-table-text">
                              {config.configFlag && config.configFlag ? "true" : "false"}
                            </p>
                          </div>
                        </td>
                        <td className="theme-table-row">
                          <EditIcon
                            fontSize="large"
                            color="action"
                            className={`cursor-pointer pr-3 ${isViewPermissionValid? "disabled": ""}`}
                            onClick={isViewPermissionValid? null: () => {
                              setEditData(config)
                              setSelectedCrypto({
                                "label": config.cryptoId.symbol,
                                "value": config.cryptoId._id
                              })
                              setInputField({
                                minFullWallets: config && config.SweepAmountThresholds && config.SweepAmountThresholds.minFullWallets ? config.SweepAmountThresholds.minFullWallets : "",
                                minAmountUsd: config && config.SweepAmountThresholds && config.SweepAmountThresholds.minAmountUsd ? config.SweepAmountThresholds.minAmountUsd : "",
                                maxAmountUsd: config && config.SweepAmountThresholds && config.SweepAmountThresholds.maxAmountUsd ? config.SweepAmountThresholds.maxAmountUsd : "",
                                takeAllFunds: config && config.SweepAmountThresholds && config.SweepAmountThresholds.takeAllFunds ? config.SweepAmountThresholds.takeAllFunds : false,
                                archive: config && config.SweepAmountThresholds && config.SweepAmountThresholds.archive ? config.SweepAmountThresholds.archive : false,
                                maxAmount: config && config.FeeThresholds && config.FeeThresholds.maxAmount ? config.FeeThresholds.maxAmount : "",
                                maxPercent: config && config.FeeThresholds && config.FeeThresholds.maxPercent ? config.FeeThresholds.maxPercent : "",
                                sweepFlag: config && config.sweepFlag ? config.sweepFlag : false,
                                configFlag : config && config.configFlag ? config.configFlag : false,
                              })
                              const paymentProviderValue = paymentProviderList.filter((data) => data.value === config.paymentProviderId._id)
                              setSelectedPaymentProvider(paymentProviderValue[0])
                              setOpen(true)
                            }}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          </Table>
        </div>}
      </div>
      </div>
      :
     <div className="payment-provider-add-btn">
     <button
       className={`theme-table-action add-country-btn-center ${isViewPermissionValid? "disabled": ""}`}
       onClick={isViewPermissionValid? null: () => {
         setEditData("")
         setOpen(true)
       }}
     >
       Add Config
     </button>
   </div>}
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
        disableEnforceFocus={true}
        onClose={onClose}
      >
        <div className="filter_model_container">
          <div className="filter_model">
            <div className="filter_model_heading_wrap">
              <div className="filter_heading">{editData ? "Edit" : "Add"} Sweep Log Config</div>
            </div>
            <div className="filter_close_button" onClick={onClose}>
              <img
                src={`${image_base_url}${closeImage}`}
                alt="closeImage"
                data-e2e="close-icon"
              />
            </div>
          </div>
          <div className="form-group myAccoutOptModal_form_group transferModalGroup mt-40">
            <div className="FilterModalInputLabel f-bold mb-2">Crypto</div>
            <Select
              options={options}
              defaultValue={selectedCrypto}
              onChange={(event) => {
                setSelectedCrypto(event);
                setErr("")
              }}
            />
          </div>
          {localStorage.getItem('adminRole') !== "PaymentProviderAdmin" && <div className="form-group myAccoutOptModal_form_group transferModalGroup mt-40">
            <div className="FilterModalInputLabel f-bold mb-2">Payment Provider</div>
            <Select
              isDisabled={localStorage.getItem('adminRole') === "PaymentProviderAdmin"}
              options={paymentProviderList}
              placeholder="select payment provider"
              defaultValue={selectedPaymentProvider}
              onChange={(event) => {
                setSelectedPaymentProvider(event);
                setErr("")
              }}
            />
          </div>}
          <div className="FilterModalInputGroup">
            <div className="FilterModalInputLabel">Remaining Full Wallets After Sweep</div>
            <div className="MinMaxCryptoRangeWrap">
              <input
                className="rangeInput"
                type="number"
                placeholder="Remaining Full Wallets After Sweep"
                name="minFullWallets"
                value={inputField.minFullWallets}
                onChange={(e) => {
                  if (e.target.value) {
                    setInputField({ ...inputField, minFullWallets: e.target.value })
                    setErr('')
                  } else {
                    setErr("Enter Remaining Full Wallets After Sweep")
                  }
                }}
              />
            </div>
          </div>
          <div className="FilterModalInputGroup">
            <div className="FilterModalInputLabel">Minimum Amount in Wallet To Be Swept</div>
            <div className="MinMaxCryptoRangeWrap">
              <input
                className="rangeInput"
                type="number"
                placeholder="Minimum Amount in Wallet To Be Swept"
                name="minAmountUsd"
                value={inputField.minAmountUsd}
                onChange={(e) => {
                  if (e.target.value) {
                    setInputField({ ...inputField, minAmountUsd: e.target.value })
                    setErr('')
                  } else {
                    setErr("Enter Minimum Amount in Wallet To Be Swept")
                  }

                }}
              />
            </div>
          </div>
          <div className="FilterModalInputGroup">
            <div className="FilterModalInputLabel">Maximum Amount in Wallet To Be Swept</div>
            <div className="MinMaxCryptoRangeWrap">
              <input
                className="rangeInput"
                type="number"
                placeholder="Minimum Amount in Wallet To Be Swept"
                name="maxAmountUsd"
                value={inputField.maxAmountUsd}
                onChange={(e) => {
                  if (e.target.value) {
                    setInputField({ ...inputField, maxAmountUsd: e.target.value })
                    setErr('')
                  } else {
                    setErr("Enter Minimum Amount in Wallet To Be Swept")
                  }

                }}
              />
            </div>
          </div>
          <div className=" FilterModalInputGroup" style={{ display: "flex", gap: "20px", flexDirection: "inherit", alignItems: "center", marginTop: "0px" }}>
            <div className="flex gap-2 items-center">
              <input type="checkbox" name="archive" placeholder="archive" defaultChecked={inputField.takeAllFunds} value={inputField.takeAllFunds} onChange={(e) => setInputField({ ...inputField, takeAllFunds: !inputField.takeAllFunds })} />
              <span className="FilterModalInputLabel" style={{ marginBottom: "0px" }}>Take All Funds</span>
            </div>
          </div>
          <div className=" FilterModalInputGroup" style={{ display: "flex", gap: "20px", flexDirection: "inherit", alignItems: "center", marginTop: "0px" }}>
            <div className="flex gap-2 items-center">
              <input type="checkbox" name="archive" placeholder="archive" value={inputField.archive} defaultChecked={inputField.archive} onChange={(e) => setInputField({ ...inputField, archive: !inputField.archive })} />
              <span className="FilterModalInputLabel" style={{ marginBottom: "0px" }}>Set Wallet Status to Archived After Sweep</span>
            </div>
          </div>
          
            <div className="FilterModalLabel">Gas Fee Settings</div>
          <div className="FilterModalInputGroup">
            <div className="FilterModalInputLabel">Max Acceptable Gas Fee Amount</div>
            <div className="MinMaxCryptoRangeWrap">
              <input
                className="rangeInput"
                type="number"
                placeholder="Max Acceptable Gas Fee"
                name="maxAmount"
                value={inputField.maxAmount}
                onChange={(e) => {
                  if (e.target.value) {
                    setInputField({ ...inputField, maxAmount: e.target.value })
                    setErr('')
                  } else {
                    setErr("Enter a Max Acceptable Gas Fee ")
                  }
                }}
              />
            </div>
          </div>
          <div className="FilterModalInputGroup">
            <div className="FilterModalInputLabel">Max Acceptable Gas Fee as a % of Sweep Amount</div>
            <div className="MinMaxCryptoRangeWrap">
              <input
                className="rangeInput"
                type="number"
                placeholder="Max Acceptable Gas Fee as a % of Sweep Amount"
                name="maxPercent"
                value={inputField.maxPercent}
                onChange={(e) => {
                  if (e.target.value) {
                    setInputField({ ...inputField, maxPercent: e.target.value })
                    setErr('')
                  } else {
                    setErr("Enter Max Acceptable Gas Fee as a % of Sweep Amount ")
                  }
                }}
              />
            </div>
          </div>
          <div className=" FilterModalInputGroup" style={{ display: "flex", gap: "20px", flexDirection: "inherit", alignItems: "center", marginTop: "0px" }}>
            <div className="flex gap-2 items-center">
              <input type="checkbox" name="sweepFlag" placeholder="sweepFlag" value={inputField.sweepFlag} defaultChecked={inputField.sweepFlag} onChange={(e) => {
                console.log(e.target.value)
                setInputField({ ...inputField, sweepFlag: (!inputField.sweepFlag ? true : false) })
              }} />
              <span className="FilterModalInputLabel" style={{ marginBottom: "0px" }}>Perform Actual Sweep Transaction</span>
            </div>
          </div>
          <div className=" FilterModalInputGroup" style={{ display: "flex", gap: "20px", flexDirection: "inherit", alignItems: "center", marginTop: "0px" }}>
            <div className="flex gap-2 items-center">
              <input type="checkbox" name="configFlag" placeholder="configFlag" value={inputField.configFlag} defaultChecked={inputField.configFlag} onChange={(e) => {
                console.log(e.target.value)
                setInputField({ ...inputField, configFlag: !inputField.configFlag })
              }} />
              <span className="FilterModalInputLabel" style={{ marginBottom: "0px" }}>Config Enabled/Disable</span>
            </div>
          </div>
          {err && err !== "" && (
            <div className="clr-red">Please fill the mandatory fields</div>
          )}
          <div onClick={() => handleSweepConfig()} className="FilterModalSave">
            <button >
              SAVE
            </button>
          </div>
        </div>
      </Dialog>
      {loading && <Loading />}
    </div>
  );
};

export default PaymentProviderSweepConfig;