import React, { useState, useEffect } from 'react'
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout'
import CommonHeader from '../CommonPages/CommonHeader'
import { useDispatch, useSelector } from 'react-redux'
import localDb from '../../../../localDb'
import AuditTrailTable from './AuditTrailTable'
import { GET_AUDIT_TRAILS } from '../../../../config/endpoints'
import { call } from '../../../../config/axios'
import Loading from '../../../common/Loading'
import useDebounce from '../../global/useDebounce'
const searchicon = '/cryptonpay/crytoicons/searchicon1.png';

const AuditTrail = () => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal('email');
  const base_url = useSelector((state) => state.config.api_url);
  const dispatch = useDispatch();
  const [data, setData] = useState([])
  const [searchText, setSearhText] = useState('');
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState("")
  const [orderBy, setOrderBy] = useState('desc');
  const [sortBy, setSortBy] = useState('timeStamp');
  const [page, setPage] = useState(1)
  const [save, setSave] = useState(false);
  const debouncedSearchText = useDebounce(searchText);
  useEffect(() => {
    getAuditTrail()
  }, [debouncedSearchText, orderBy, sortBy, save])
  const getAuditTrail = async (page) => {
    let pageNo = 1;
    if (page) {
      pageNo = page;
    }

    let payload = {
      email: email,
      eventType: searchText.toString(),
      eventId: searchText.toString(),
      userId: searchText,
      IpAddress: searchText,
      component: searchText,
      orderBy: orderBy,
      sortBy: sortBy,
      page: pageNo,
    };
    setLoading(true);
    await call(
      {
        ...GET_AUDIT_TRAILS,
        url: base_url + GET_AUDIT_TRAILS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          if (pageNo === 1) {
            setData(res.data.getAuditTrail);
            setTotalCount(res.data.totalCount)
          } else {
            let list = data.concat(res.data.getAuditTrail);
            setData(list);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, 'err');
      });
  };
  return (
    <HeaderAndFooterLayout>
      <CommonHeader name="AuditTrail" />
      <div>
        <div className="transaction_filter">
          <div className="transactionsFiltersWrap">
            <div className="transactionsFilters">
              <div className="transactionsFiltersItem transactionsSearchFilter">
                <div className="transactionsFilterTextWrap">
                  <input
                    type="text"
                    className="transactionsFilterInput"
                    value={searchText}
                    name="searchText"
                    data-e2e="search-textbox"
                    onChange={(e) => {
                      setSearhText(e.target.value);
                      setPage(1);
                    }}
                    placeholder="Search Error Logs..."
                  />
                </div>
                <div className="transactionsFilterSingleIconWrap">
                  <img
                    src={`${image_base_url}${searchicon}`}
                    alt="searchicon"
                    className="transactionsFilterSingleIcon"
                    data-e2e="searchIcon"
                    onClick={() => {
                      getAuditTrail();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <AuditTrailTable
            data={data}
            showMoreButton={totalCount && data && data.length < totalCount}
            handleShowMore={() => {
              let newPage = page + 1;
              setPage(newPage);
              getAuditTrail(newPage);
            }}
          />
        </div>
        {loading && <Loading />}
      </div>
    </HeaderAndFooterLayout>
  )
}

export default AuditTrail