import {useSelector} from "react-redux";
import React, {useState} from "react";
import {getCryptoIcon} from "./utils/helperFunctions";
import moment from "moment";

export const MerchantUserWalletTransactionsTableRowMobile = ({
  balance,
}) => {
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const [walletsEventLogDetails, setWalletsEventLogDetails] = useState(false);


  return (
    <div className="single_mobile_row_wrap">
      <div className="single_mobile_row">
        <div className="row_content">
          <div className="row_content_transInfo_left">
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <img
                  src={`${imageBaseUrl}${getCryptoIcon(balance.cryptoId)}`}
                  alt={balance.cryptoId}
                  className="currencyList_Icon"
                  data-e2e="currencyList_Icon"
                  width="30px"
                  height="30px"
                />
              </div>
              <div>
                <div className="merchant_name pb-2 text-capitalize">
                  {balance.cryptoId || "N/A"}
                </div>
                <div className="transaction_cryp">
                  {balance.amount !== undefined
                    ? `${balance.amount}`
                    : "N/A"}
                  <span className="grey-span">{` ${
                    balance.cryptoId && balance.amount !== undefined
                      ? "(" + balance.cryptoId + ")"
                      : ""
                  }`}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row_content_transInfo_right">
            <div className="content">
              <div className="pb-2 text-capitalize">
                {" "}
                {balance.transactionType
                  ? balance.transactionType
                  : "N/A"
                }
              </div>
            </div>
            <div
              onClick={() => setWalletsEventLogDetails(!walletsEventLogDetails)}
              className="down_icon pl-3 pr-1"
            >
              <i
                className={`fa fa-chevron-${
                  !walletsEventLogDetails ? "down" : "up"
                }`}
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      </div>

      {walletsEventLogDetails && (
        <div className="transaction_details_wrap">
          <div className="content">
            <div className="content_heading">{`Transaction ID`}</div>
            <div className="content_val">
              {balance.transactionId
                ? balance.transactionId
                : "N/A"}
            </div>
          </div>
          <div className="content">
            <div className="content_heading">{`Crypto amount: `}</div>
            <div className="content_val">
              {" "}
              {balance.amount !== undefined
                ? `${balance.amount}`
                : "N/A"}
              <span className="grey-span">{` ${
                balance.amount !== undefined && balance.cryptoId
                  ? "(" + balance.cryptoId + ")"
                  : ""
              }`}</span>
            </div>
          </div>
          <div className="content">
            <div className="content_heading">{`Previous Balance: `}</div>
            <div className="content_val">{
              balance.previousBalance !== undefined
                ? `${balance.previousBalance}`
                : "N/A"
            }</div>
          </div>
          <div className="content">
            <div className="content_heading">{`Current Balance: `}</div>
            <div className="content_val">{
              balance.currentBalance !== undefined
                ? `${balance.currentBalance}`
                : "N/A"
            }</div>
          </div>
          <div className="content">
            <div className="content_heading">{`Time stamp: `}</div>
            <div className="content_val">
              {balance.updated
                ? moment(balance.updated).format("DD/MM/YYYY - HH:mm")
                : "N/A"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
