import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Select from "react-select";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import AddOrEditCity from "./AddOrEditCity";
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";
import { useSelector } from "react-redux";
import DeletingAlert from "./DeletingAlert";
import AddOrEditState from "./AddOrEditState";
import { isViewPermissionValid } from "../../../common/CommonUtils";

export default function SingleAccordion({
  country_id,
  state,
  stateCode,
  nativeStateName,
  citiesData,
  setReload,
  reload,
}) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [addNew, setAddNew] = useState(false);
  const [editData, setEditData] = useState([]);
  const [editCity, setEditCity] = useState(false);
  const [openEditIcon, setOpenEditCityIcon] = useState(false);
  const [openDeleteIcon, setOpenDeleteIcon] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [openEditStateIcon, setOpenEditStateIcon] = useState(false);
  const [currentCityName, setCurrentCityName] = useState("");
  const [cityNew, setCityNew] = useState(false);

  const [editState, setEditState] = useState(false);
  const handleCloseAddNew = () => {
    setAddNew(false);
    setEditCity(false);
    setCityNew(false);
  };

  const openEditDialog = (item) => {
    setCurrentCityName(item);
    setCityNew(true);
    setOpenEditCityIcon(true);
    setEditCity(true);
  };

  const openEditStateDialog = () => {
    setOpenEditStateIcon(true);
    setAddNew(true);
    setEditState(true);
  };
  const openAddDialog = () => {
    setOpenEditCityIcon(true);
    setCityNew(true);
  };
  const openDeleteDialog = (item) => {
    setCurrentCityName(item);

    setOpenDeleteIcon(true);
    setShowModel(true);
  };
  const deleteUser = async () => {
    window.location.reload();
  };
  const sortDropDownData = (data, field) => {
    return data?.sort((a, b) => a.localeCompare(b, { sensitivity: "accent" }));
  };
  const cities = sortDropDownData(citiesData);
  return (
    <div>
      <Accordion
        sx={{
          width: "auto",
          maxWidth: "auto",
          marginTop: "10px",
        }}
      >
        <AccordionSummary
          sx={{
            width: "auto",
            maxWidth: "auto",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="text-lg">{`${state} (${stateCode})`}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="d-flex flex-column text-lg">
            <div>
              <div className="flex justify-end gap-2">
                <Button
                  variant="contained"
                  sx={{ width: "auto", maxWidth: "auto", marginBottom: "10px" }}
                  onClick={() => isViewPermissionValid? null: openEditStateDialog()}
                  className={`${isViewPermissionValid? "disabled": ""}`}
                >
                  Edit State
                </Button>
                <Button
                  variant="contained"
                  sx={{ width: "auto", maxWidth: "auto", marginBottom: "10px" }}
                  onClick={() => isViewPermissionValid? null: openAddDialog()}
                  className={`${isViewPermissionValid? "disabled": ""}`}
                >
                  Add City
                </Button>
              </div>
            </div>

            {cities?.map((item, index) => (
              <div
                key={index}
                className="flex items-center justify-between gap-2"
              >
                <div>{item}</div>

                <div>
                  <EditIcon
                    className={`text-blue-500 ${isViewPermissionValid? "disabled": ""}`}
                    fontSize="small"
                    onClick={() => isViewPermissionValid? null: openEditDialog(item)}
                  />

                  <DeleteIcon
                    className={`text-danger ${isViewPermissionValid? "disabled": ""}`}
                    onClick={() => isViewPermissionValid? null: openDeleteDialog(item)}
                  />
                </div>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
      {/* edit and add city */}
      {openEditIcon && (
        <Dialog
          open={cityNew}
          onClose={() => {
            handleCloseAddNew();
            setEditData([]);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AddOrEditCity
            handleCloseAddNew={() => handleCloseAddNew()}
            editCity={editCity}
            country_id={country_id}
            stateName={state}
            currentCityName={currentCityName}
            setReload={setReload}
            reload={reload}
            setOpenEditCityIcon={setOpenEditCityIcon}
            setCityNew={setCityNew}
          />
        </Dialog>
      )}
      {/* delete city */}
      {openDeleteIcon && (
        <DeletingAlert
          image_base_url={image_base_url}
          msg=""
          onHide={() => {
            setShowModel(false);
            setOpenDeleteIcon(false);
          }}
          setOpenDeleteIcon={setOpenDeleteIcon}
          showModel={showModel}
          setShowModel={setShowModel}
          country_id={country_id}
          stateName={state}
          cityName={currentCityName}
          setReload={setReload}
          reload={reload}
        />
      )}
      {/* edit state pop up */}
      {openEditStateIcon && (
        <Dialog
          open={addNew}
          onClose={() => {
            handleCloseAddNew();
            setEditData([]);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AddOrEditState
            handleCloseAddNew={() => handleCloseAddNew()}
            setOpenEditStateIcon={setOpenEditStateIcon}
            country_id={country_id}
            editState={editState}
            currentStateName={state}
            setReload={setReload}
            reload={reload}
            stateCode={stateCode}
            nativeStateName={nativeStateName}
            setAddNew={setAddNew}
          />
        </Dialog>
      )}
    </div>
  );
}
