import React from "react";
import { Table } from "reactstrap";
import { isViewPermissionValid } from "../../../common/CommonUtils";

const FeatureConfig = (props) => {
    const { featureConfigHeaders, featureConfigList, updateFeatureConfig } = props;

    const getButton = (item) => {
        switch (item.enabled) {
            case true:
                return <div onClick={() => isViewPermissionValid? null:
                    updateFeatureConfig(item.featureName, false)
                } className={`${isViewPermissionValid? "disabled": ""} DisabledStatus  p-3 cursor-pointer`} style={{width: "fit-content", backgroundColor: "#007bff", color: "white"}}>Disable Feature</div>;
            case false:
                return <div onClick={() => isViewPermissionValid? null:
                    updateFeatureConfig(item.featureName, true)
                } className={`${isViewPermissionValid? "disabled": ""} EnabledStatus p-3 cursor-pointer`} style={{width: "fit-content", backgroundColor: "#007bff", color: "white" }}>Enabled Feature</div>;
        }
    };

    return (
        <div className="ScrollBarStyle">
            {featureConfigList && featureConfigList.length > 0 ? (
                <Table responsive style={{ border: "none" }}>
                    <tbody className="TransactionTable_BODY">
                        <tr className="TransactionTable_BODY_Header">
                            {featureConfigHeaders.map((item, index) => (
                                <th
                                    className="GBtableHeading pl-0 pr-0"
                                    style={{ width: item.width }}
                                    key={index}
                                    data-e2e={item.name}
                                >
                                    {item.name}
                                </th>
                            ))}
                        </tr>
                        {featureConfigList &&
                            featureConfigList.map((item, index) => (
                                <tr key={index}>
                                    <td className="table_item merchant_table_font pl-0 pr-0 text-black">
                                        {item.featureName ? item.featureName : "N/A"}
                                    </td>
                                    <td className="table_item merchant_table_font pl-0 pr-0" style={{ color: item.enabled ? "green" : "red" }}>
                                        {item.enabled ? "Active" : "In-Active"}
                                    </td>
                                    <td className="table_item merchant_table_font pl-0 pr-0" style={{
                                        justifyContent: "center",
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        {getButton(item)}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            ) : (
                <div className="NoDataFound">No Data Found</div>
            )}
        </div>
    );
};

export default FeatureConfig;